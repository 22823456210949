import React, { useEffect, useRef, useState } from "react";
import {
    AddFileButton,
    AvatarBlock,
    ChatBlock,
    CrossWhiteIconWrapper,
    MessageBlockWrapper,
    MessageDate,
    MessageWrapperSide,
    MessagesWrapper,
    ModalWrapper,
    NameSide,
    SaveParagraph,
    SaveWrapper,
    SearchHelper,
    SearchWrapper,
    SendButton,
    SubTitle,
    // Tag,
    // TagsBlock,
    TextMessageStyle,
    Wrapper,
    WrapperLeft,
} from "./style";

// import { uploadFilesToServer } from "../../../store/slices/userSlice";
import {
    // Attachment,
    ChatType,
    IndustryActivityType,
    messageType,
    // ModifiedFileObject
} from "../../../utils/types";
import { useDispatch } from "react-redux";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { addMessage, clearData, createChatAsync, createInstructionsAsync, createMessageToThreadAndRunAsync, deleteChatAsync, getAllMessagesInThreadAsync, setChatData, setIndustryActivityValues } from "../../../store/slices/Assistants/AssistanseSlice";
import Send from "../../../assets/icons/Send";
import { RootState } from "../../../store/store";
import Clip from "../../../assets/icons/Clip";
import AllAssistantsList from "../AllAssistantsList/AllAssistantsList";
import ModalLostChanges from "../../ModalWindows/ModalLostChanges/ModalLostChanges";
import CrossWhiteIcon from "../../../assets/icons/CrossWhireIcon";


interface AssistantState {
    messages: messageType[];
    status: string;
    industryValue: IndustryActivityType;
    activityValue: IndustryActivityType;
    chats: ChatType[];
}

const Specification = () => {
    const appDispatch = useAppDispatch();
    const dispatch = useDispatch();
    const textareaRef = useRef<HTMLTextAreaElement>(null);

    const { messages, status, industryValue, activityValue, chats }: AssistantState = useAppSelector((state: RootState) => ({
        messages: state.assistants.messages,
        status: state.assistants.status,
        industryValue: state.assistants.industryValue,
        activityValue: state.assistants.activityValue,
        chats: state.assistants.chats,
    }));

    const lastMessageRef = useRef<HTMLDivElement>(null);
    const [userMessage, setUserMessage] = useState('');
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [chatIdToDelete, setChatIdToDelete] = useState<number>(-1);
    const [isCreatingNewChat, setIsCreatingNewChat] = useState(false);
    const [showSendMessage, setShowSendMessage] = useState(false);

    const addMessageToArray = async () => {
        dispatch(addMessage({ role: 'user', value: userMessage, created_at: new Date().toISOString() }));
        setUserMessage('');
        await appDispatch(createMessageToThreadAndRunAsync({ message: userMessage }));
    };

    // Automatic resizing of the text field as text is entered.
    function textAreaAdjust(element: any) {
        element.style.height = "auto";
        element.style.height = (element.scrollHeight) + "px";
    };

    const executeAsync = async () => {
        try {
            const instructionCredentials = `industry: ${industryValue.name}, activities: ${activityValue.name}`;
            setIsCreatingNewChat(true);
            // Creating instructions
            const inst = await appDispatch(createInstructionsAsync(instructionCredentials));

            await appDispatch(createChatAsync({ industryId: industryValue.id.toString(), activityId: activityValue.id.toString(), instructions: inst.payload.response }));
            setIsCreatingNewChat(false);

            dispatch(setIndustryActivityValues({
                industryValue: { id: -1, name: '' },
                activityValue: { id: -1, name: '' }
            }));
        } catch (error) {
            console.error("Error in executing async functions", error);
        }
    };


    useEffect(() => {
        if (industryValue.id !== -1 && activityValue.id !== -1) {
            executeAsync();
        }
    }, []);


    // Automatic resizing of the text field as text is entered.
    useEffect(() => {
        if (lastMessageRef.current) {
            lastMessageRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages]);

    //Sending a message by pressing Enter.
    const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (event.key === 'Enter' && !event.ctrlKey) {
            event.preventDefault(); // Prevent the default behavior of Enter key
            addMessageToArray(); // Call the function to add message
        } else if (event.key === 'Enter' && event.ctrlKey) {
            setUserMessage(userMessage + '\n'); // Add a newline character to the message
        }
    };

    useEffect(() => {
        if (status !== 'loading' && textareaRef.current) {
            textareaRef.current.focus();
        }
    }, [status]);

    const formatDate = (isoString: string): string => {
        return new Date(isoString)
            .toLocaleDateString('ru-RU', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
            });
    };

    const handleDeleteChat = async () => {
        const response: any = await appDispatch(deleteChatAsync(chatIdToDelete));
        if (response.payload === 200) {
            dispatch(clearData([]));
            dispatch(setChatData({
                assistantId: chats[0].assistantId,
                threadId: chats[0].threadId,
                chatId: chats[0].id,
            }));
            setShowDeleteModal(false);

            setShowSendMessage((prevShowSendMessage) => !prevShowSendMessage);
            setTimeout(() => {
                setShowSendMessage((prevShowSendMessage) => !prevShowSendMessage);
            }, 3000);

            await appDispatch(getAllMessagesInThreadAsync(chats[0].threadId));
        };
    };

    const handleDiscardClick = () => {
        setShowDeleteModal(false);
    };

    return (
        <>
            <Wrapper>
                <WrapperLeft>
                    {messages.length === 0 && status !== 'loading' && <ChatBlock>
                        <AvatarBlock>Hello!</AvatarBlock>
                        <AvatarBlock>I am SelfMan assystant</AvatarBlock>
                        <SubTitle>Can I help you find the product you're looking for? I'll ask just 10
                            guiding
                            questions</SubTitle>
                    </ChatBlock>}


                    <MessagesWrapper>
                        {messages.map((message, index) => {
                            return <MessageWrapperSide key={index} role={message.role} >
                                <MessageBlockWrapper>
                                    <NameSide role={message.role} >{message.role === 'user' ? 'User' : 'Assistant'}</NameSide>
                                    <TextMessageStyle key={index}>{message.value}</TextMessageStyle>
                                    <MessageDate role={message.role} >{formatDate(message.created_at)}</MessageDate>
                                </MessageBlockWrapper>
                            </MessageWrapperSide>
                        })}
                        {status === 'loading' && <MessageWrapperSide role={'assistant'} >
                            <MessageBlockWrapper>
                                <NameSide role='assistant' >Assistant</NameSide>
                                <TextMessageStyle>typing...</TextMessageStyle>
                            </MessageBlockWrapper>
                        </MessageWrapperSide>}
                        <div ref={lastMessageRef} />
                    </MessagesWrapper>


                    <SearchWrapper style={{ gap: 20 }}>

                        <SearchHelper
                            ref={textareaRef}
                            disabled={status === 'loading'}
                            value={userMessage}
                            placeholder="Enter your message"
                            onChange={(e) => setUserMessage(e.target.value)}
                            onInput={(e) => textAreaAdjust(e.target)}
                            onKeyDown={handleKeyDown}
                        >
                        </SearchHelper>
                        <SendButton disabled={status === 'loading'} onClick={() => addMessageToArray()}>
                            <Send />
                        </SendButton>

                        <AddFileButton disabled={status === 'loading'}>
                            <Clip />
                        </AddFileButton>
                    </SearchWrapper>
                </WrapperLeft>


                <AllAssistantsList
                    setShowDeleteModal={setShowDeleteModal}
                    setChatIdToDelete={setChatIdToDelete}
                    industryId={industryValue.id}
                    activityId={activityValue.id}
                    isCreatingNewChat={isCreatingNewChat}
                />

                {showDeleteModal &&
                    <ModalWrapper>
                        <ModalLostChanges
                            buttonText="Delete"
                            buttonWhitetext="Discard"
                            text="This action cannot be undone. Are you sure you want to delete the assistant?"
                            handleButtonClick={handleDeleteChat}
                            handleWhiteButtonClick={handleDiscardClick} />
                    </ModalWrapper>}

                {/* <ModalAssistent handleIsChanged={() => setMessage("")} text={"Your request is being processed, please wait a moment..."}></ModalAssistent> */}
            </Wrapper>

            {showSendMessage && (
                <SaveWrapper>
                    <SaveParagraph>
                        Chat has been successfully deleted.
                    </SaveParagraph>
                    <CrossWhiteIconWrapper
                        onClick={() => setShowSendMessage(!showSendMessage)}
                    >
                        <CrossWhiteIcon />
                    </CrossWhiteIconWrapper>
                </SaveWrapper>
            )}
        </>
    );
};

export default Specification;






// const [uploading, setUploading] = useState(false);
// const [filesToUpload, setFilesToUpload] = useState<File[]>([]);

// const handleFileChange = async (filesArray: File[]) => {
//     if (filesArray.length <= 0) {
//         return;
//     }
//     setUploading(true);
//     setFilesToUpload(filesArray);
//     const saveFiles = await dispatch(
//         uploadFilesToServer({files: filesArray}) as any
//     );

//     if (saveFiles.payload.message === "400") {
//         saveFiles.payload = [];
//     }
//     const saveFilesPayload: Attachment[] = saveFiles.payload;
//     const modifiedFilesArray: ModifiedFileObject[] = saveFilesPayload.map(
//         (obj) => {
//             const {link, name} = obj;
//             return {link, name};
//         }
//     );
//     // setConnectionData({
//     //   ...connectionData,
//     //   files: modifiedFilesArray || [],
//     // } as any);
//     setUploading(false);

//     return saveFilesPayload;
// };





/* <TagsBlock>

    <Tag>Shirt</Tag>
    <Tag>Safety belt components</Tag>
    <Tag>Tank top with drawings of various infrast...</Tag>
    <Tag>Pants</Tag>
</TagsBlock> */