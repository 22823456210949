const VectorYellowIcon: React.FC = () => {
  return (
    <svg
      width="12"
      height="8"
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="Vector 2"
        d="M1 2.71429L5.16667 7L11 1"
        stroke="url(#paint0_linear_6126_4134)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_6126_4134"
          x1="2.04167"
          y1="4.375"
          x2="9.18299"
          y2="1.80155"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDDC30" />
          <stop offset="1" stopColor="#F2F700" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default VectorYellowIcon;
