import styled from "styled-components";

export const Container = styled.div``;

export const HeaderTittle = styled.p`
  font-size: 30px;
  font-weight: 500;
  line-height: 33.6px;
  text-align: left;
  margin-bottom: 20px;
`;

export const Tittle = styled.p`
  font-size: 22px;
  font-weight: 500;
  line-height: 33.6px;
  text-align: left;
  margin-bottom: 20px;
`;

export const SecondTittle = styled.p`
  font-size: 26px;
  font-weight: 500;
  line-height: 33.6px;
  text-align: left;
  margin-bottom: 20px;
`;

export const Subtitle = styled.p`
font-size: 17px;
font-weight: 400;
line-height: 23.8px;
text-align: left;
`;

export const QuestionWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
`;



export const CustomQuestion = styled.textarea`
  border-radius: 16px;
  border: 1px solid var(--border, #bdbdbd);
  background: var(--wiht, #fff);
  display: flex;
  max-width: 765px;
  width: 100%;
  height: 200px;
  padding: 20px;
  align-items: flex-start;
  gap: 10px;
  resize: none;
  margin-bottom: 10px;
  &::placeholder {
    color: var(--txt-grey-light, #717880);
    font-size: 17px;
    font-weight: 400;
    line-height: 140%;
  }
  &:focus {
    border: 1px solid var(--batton, #0095c0);
  }
`;

export const CustomWrapper=styled.div`
margin-bottom:10px;
`;

export const ButtonWrapper = styled.div`
width: 100%;
max-width: 235px;
margin: 0 auto;
`;