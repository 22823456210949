import React, { useState } from "react";
import {
  FilterBlock,
  FilterBlockName,
  ChevronRightWrapper,
  FilterContainer,
  FilterTitle,
  IconContainer,
  SwitchButton,
  SwitchContainer,
  SwitchWrapper,
  ClearWrapper,
  SortBlock,
  SortContainer,
  ChartButton,
} from "./styles";
import ArrowUpDownIcon from "../../../assets/icons/ArrowUpDownIcon";
import ProductsInput from "../../ProductsPage/ProductsInput/ProductsInput";
import ChevronDownIcon from "../../../assets/icons/ChevronDownIcon";
import ChevronRightIcon from "../../../assets/icons/ChevronRightIcon";
import BarChart from "../../../assets/icons/BarChart";

const ProductsFilter: React.FC = () => {
  const [activeButton, setActiveButton] = useState<string>("All");
  const [showBasicInformation, setShowBasicInformation] =
    useState<boolean>(false);
  const [showReputation, setShowReputation] = useState<boolean>(false);
  const [showAdditional, setShowAdditional] = useState<boolean>(false);
  const [showSortBy, setShowSortBy] = useState<boolean>(false);

  const handleIconClick = () => {
    setActiveButton((prev) => (prev === "All" ? "Saved" : "All"));
  };
  return (
    <>
      <SwitchContainer>
        <SwitchWrapper>
          <SwitchButton
            $isActive={activeButton === "All"}
            onClick={() => setActiveButton("All")}
          >
            All
          </SwitchButton>
          <IconContainer onClick={handleIconClick}>
            <ArrowUpDownIcon />
          </IconContainer>
          <SwitchButton
            $isActive={activeButton === "Saved"}
            onClick={() => setActiveButton("Saved")}
          >
            Saved
          </SwitchButton>
        </SwitchWrapper>
        <ProductsInput placeholderValue="Search by product, industry or keywords" />
      </SwitchContainer>
      <FilterContainer>
        <FilterTitle>Filter by</FilterTitle>
        <FilterBlock
          onClick={() => setShowBasicInformation((prevState) => !prevState)}
        >
          <FilterBlockName>Basic Information</FilterBlockName>
          <ChevronRightWrapper>
            {showBasicInformation ? <ChevronDownIcon /> : <ChevronRightIcon />}
          </ChevronRightWrapper>
        </FilterBlock>

        <FilterBlock
          onClick={() => setShowReputation((prevState) => !prevState)}
        >
          <FilterBlockName>Reputation</FilterBlockName>
          <ChevronRightWrapper>
            {showReputation ? <ChevronDownIcon /> : <ChevronRightIcon />}
          </ChevronRightWrapper>
        </FilterBlock>
        <FilterBlock
          onClick={() => setShowAdditional((prevState) => !prevState)}
        >
          <FilterBlockName>Additional</FilterBlockName>
          <ChevronRightWrapper>
            {showAdditional ? <ChevronDownIcon /> : <ChevronRightIcon />}
          </ChevronRightWrapper>
        </FilterBlock>
        <ClearWrapper>Clear all</ClearWrapper>
      </FilterContainer>
      <SortContainer>
        <SortBlock onClick={() => setShowSortBy((prevState) => !prevState)}>
          <FilterBlockName>Sort by</FilterBlockName>
          <ChevronRightWrapper>
            {showSortBy ? <ChevronDownIcon /> : <ChevronRightIcon />}
          </ChevronRightWrapper>
        </SortBlock>
        <ChartButton>
          <BarChart />
        </ChartButton>
      </SortContainer>
    </>
  );
};

export default ProductsFilter;
