import styled from "styled-components";

export const Container=styled.div`
`;

export const InputBlock = styled.div`
  display: flex;
  margin: 0 0px 30px 30px;
`;

export const RequestBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-left: 30px;
  margin-bottom: 70px;
`;