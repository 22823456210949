import styled from "styled-components";

export const AvatarWrapper = styled.div`
  position: relative;
  margin-bottom: 20px;
`;

export const Avatar = styled.img`
  display: flex;

  width: 100px;
  height: 100px;

  border-radius: 100px;
  object-fit: cover;
`;

export const AvatarInput = styled.input`
  display: none;
`;

export const AvatarLabel = styled.label`
  position: absolute;
  left: 69px;
  top: 71px;
  cursor: pointer;
`;
