import React, { useEffect, useState } from "react";
import DelegateSearch from "../components/DelegateSearch/DelegateSearchContainer";
import PartnerBase from "../components/PartnerBaseContainer/PartnerBaseContainer";
import SearchInput from "../components/SearchInput/SearhInput";
import styled from "styled-components";
import { RootState } from "../store/store";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { routes } from "../routes/routes";
import CompanyItem, { Company } from "../components/CompanyItem/CompanyItem";
import { fetchCountriesAsync } from "../store/slices/userSlice";

export const Container = styled.div`
  display: flex;
  width: 100%;
  gap: 30px;
`;

const Factories: React.FC = () => {
  const user = useAppSelector((state: RootState) => state.user);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [filteredCompanies, setFilteredCompanies] =
    useState<Company[]>(CompanyItem);

  const handleFilterChange = (filteredIndustry: string) => {
    setFilteredCompanies(filteredCompanies);
  };

  useEffect(() => {
    if (!user.userAccount) {
      navigate(routes.HOME);
    }
  }, [user, navigate]);

  useEffect(() => {
    dispatch(fetchCountriesAsync() as any);
  }, [dispatch]);

  return (
    <>
      <SearchInput
        companyItems={CompanyItem}
        onFilterChange={handleFilterChange}
      />
      <Container>
        <DelegateSearch />
        <PartnerBase />
      </Container>
    </>
  );
};

export default Factories;
