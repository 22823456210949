import React, { useState } from "react";
import "react-calendar/dist/Calendar.css";
import { Container, StyledCalendar } from "./style";
import { DelegateSearchType } from "../../../utils/types";
import { newDate } from "react-datepicker/dist/date_utils";

interface CalendarProps {
  setDelegateSearch: React.Dispatch<React.SetStateAction<DelegateSearchType>>;
  fieldToSet: string;
}

const CalendarDelegate: React.FC<CalendarProps> = ({
  fieldToSet,
  setDelegateSearch,
}) => {
const [date, setDate] = useState<Date >(new Date);
  const formatDate = (date: Date): string => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
    
  };

  const handleDateClick = (date: Date) => {
    const formattedDate = formatDate(date);
    setDelegateSearch((prev: DelegateSearchType) => ({
      ...prev,
      [fieldToSet]: formattedDate,
    }));
  };

  return (
    <Container>
      <>
        <StyledCalendar
          selectRange={false}
          onClickDay={handleDateClick}
          locale="en-US"
        />
      </>
    </Container>
  );
};

export default CalendarDelegate;
