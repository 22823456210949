import React, { useState } from "react";
import {
  Input,
  Wrapper,
  Label,
  RedStar,
  TitleWrapper,
  Tooltip,
  TooltipTax,
  MainTitleWrapper,
  TooltipWrapper,
  TooltipGeneral,
  ChevronRightSettingsWrapper,
  InputWrapper,
  InputBussines,
  Item,
  ItemWrapper,
} from "./styles";
import Title from "../Title/Title";
import ShieldQuestionIcon from "../../assets/icons/ShieldQuestionIcon";
import { CompanyDetailProps, BusinessType } from "../../utils/types";
import ChevronDownIcon from "../../assets/icons/ChevronDownIcon";
import ChevronRightIcon from "../../assets/icons/ChevronRightIcon";

const CompanyDetails: React.FC<CompanyDetailProps> = ({
  companyDetails,
  updateCompany,
  onGenralChange,
}) => {
  const [showBusinessTooltip, setShowBusinessTooltip] =
    useState<boolean>(false);
  const [showTaxTooltip, setShowTaxTooltip] = useState<boolean>(false);
  const [showGeneralTooltip, setShowGeneralTooltip] = useState<boolean>(false);
  const [isBussinesTypeOpen, setIsBussinesTypeOpen] = useState<boolean>(false);

  const handleBusinessTypeSelect = (type: BusinessType) => {
    setIsBussinesTypeOpen(false);
    onGenralChange({ ...updateCompany, businessType: type });
  };

  return (
    <Wrapper>
      <MainTitleWrapper>
        <Title text={"General"} />
        <TooltipWrapper
          onMouseEnter={() => setShowGeneralTooltip(true)}
          onMouseLeave={() => setShowGeneralTooltip(false)}
        >
          <ShieldQuestionIcon />
        </TooltipWrapper>
        {showGeneralTooltip && (
          <TooltipGeneral>
            To optimize your experience on the platform and unlock its full
            potential, please complete as many fields as possible.
          </TooltipGeneral>
        )}
      </MainTitleWrapper>
      <TitleWrapper>
        <Label>Company name</Label>
        <RedStar>*</RedStar>
      </TitleWrapper>
      <Input
        placeholder="Enter your Company name"
        value={updateCompany?.companyName ?? companyDetails?.companyName}
        onChange={(e) =>
          onGenralChange({
            ...updateCompany,
            companyName: e.target.value,
          })
        }
      />
      <TitleWrapper>
        <Label>Founded</Label>
        <RedStar>*</RedStar>
      </TitleWrapper>
      <Input
        placeholder="Enter a year"
        type="number"
        value={updateCompany?.founded ?? companyDetails?.founded}
        onChange={(e) =>
          onGenralChange({
            ...updateCompany,
            founded: e.target.value,
          })
        }
      />
      <TitleWrapper>
        <Label>Business type:</Label>
      </TitleWrapper>
      <InputWrapper>
        <InputBussines
          placeholder="Choose type of business"
          value={
            updateCompany?.businessType
              ? updateCompany.businessType.charAt(0).toUpperCase() +
                updateCompany.businessType.slice(1).toLowerCase()
              : companyDetails?.businessType
              ? companyDetails.businessType.charAt(0).toUpperCase() +
                companyDetails.businessType.slice(1).toLowerCase()
              : ""
          }
          readOnly
          onClick={() => {
            setIsBussinesTypeOpen(!isBussinesTypeOpen);
          }}
        />
        <ChevronRightSettingsWrapper>
          {!isBussinesTypeOpen ? (
            <ChevronRightIcon
              onClick={() => {
                setIsBussinesTypeOpen(!isBussinesTypeOpen);
              }}
            />
          ) : (
            <ChevronDownIcon
              onClick={() => {
                setIsBussinesTypeOpen(!isBussinesTypeOpen);
              }}
            />
          )}
        </ChevronRightSettingsWrapper>

        {isBussinesTypeOpen && (
          <ItemWrapper>
            <Item
              onClick={() => handleBusinessTypeSelect(BusinessType.COMPANY)}
            >
              Company
            </Item>
            <Item
              onClick={() => handleBusinessTypeSelect(BusinessType.INDIVIDUAL)}
            >
              Individual
            </Item>
          </ItemWrapper>
        )}
      </InputWrapper>
      <TitleWrapper>
        <Label>Business registration number</Label>
        <RedStar>*</RedStar>
        <TooltipWrapper
          onMouseEnter={() => setShowBusinessTooltip(true)}
          onMouseLeave={() => setShowBusinessTooltip(false)}
        >
          <ShieldQuestionIcon />
        </TooltipWrapper>
        {showBusinessTooltip && (
          <Tooltip>
            This number is used to legally identify your business unit in your
            country.
          </Tooltip>
        )}
      </TitleWrapper>
      <Input
        placeholder="Enter registration number"
        value={
          updateCompany?.businessRegistrationId ??
          companyDetails?.businessRegistrationId
        }
        onChange={(e) =>
          onGenralChange({
            ...updateCompany,
            businessRegistrationId: e.target.value,
          })
        }
      />
      <TitleWrapper>
        <Label>Tax ID number</Label>
        <RedStar>*</RedStar>
        <TooltipWrapper
          onMouseEnter={() => setShowTaxTooltip(true)}
          onMouseLeave={() => setShowTaxTooltip(false)}
        >
          <ShieldQuestionIcon />
        </TooltipWrapper>
        {showTaxTooltip && (
          <TooltipTax>
            This number is used to identify your business unit’s tax account in
            your country.
          </TooltipTax>
        )}
      </TitleWrapper>
      <Input
        placeholder="Enter Tax ID number"
        value={updateCompany?.taxId ?? companyDetails?.taxId}
        type="number"
        onChange={(e) =>
          onGenralChange({
            ...updateCompany,
            taxId: e.target.value,
          })
        }
      />
      <TitleWrapper>
        <Label>Website</Label>
        <RedStar>*</RedStar>
      </TitleWrapper>
      <Input
        placeholder="Enter your website"
        value={updateCompany?.website ?? companyDetails?.website}
        onChange={(e) =>
          onGenralChange({
            ...updateCompany,
            website: e.target.value,
          })
        }
      />
    </Wrapper>
  );
};

export default CompanyDetails;
