import styled from "styled-components";

export const IndustryTitle = styled.p`
    position: absolute;
    top: -210px;
  font-size: 17px;
  font-weight: 500;
  line-height: 140%;
  color: var(--txt-light-blue, #f4ffff);
  margin: 0 0 10px 15px;
`;

export const ActivityTitle = styled(IndustryTitle)`
    top: -70px;
`;

export const InputWrapper = styled.div`
  position: relative;
  margin-bottom: 20px;
`;

export const SearchInputIndustryWrapper = styled.div`
  position: absolute;
  top: -160px;
  transform: translate(-50%);
  cursor: pointer;
`;

export const SearchInputActivityWrapper = styled(SearchInputIndustryWrapper)`
  top: -40px;
`;

export const SearchInput = styled.input`
  position: relative;
  width: 400px;
  font-size: 17px;
  line-height: 130%;
  border-radius: 16px;
  border: 1px solid #ebebeb;
  background: #fff;
  color: var(--txt-dark, #001731);
  padding: 14px 20px 16px 50px;
  &::placeholder {
    font-size: 17px;
    line-height: 140%;
    color: var(--txt-grey-light, #717880);
  }
  cursor: pointer;
`;

export const SearchButton = styled.div`
  position: absolute;
  left: 15px;
  top: 15px;
`;

export const ChevronRightWrapper = styled.div`
  position: absolute;
  right: 25px;
  bottom: 15px;
`;

export const SearchIndustryWrapper = styled.div`
  position: absolute;
  top: -160px;
  z-index: 20;
  transform: translate(-50%);
  padding: 0 14px 20px;
  border-radius: 16px;
  border: 1px solid var(--batton, #0095c0);
  background: var(--wiht, #fff);
  transition: all 0.3s ease;
  /* margin-left: 15px; */
  cursor: pointer;
`;


export const SearchActivityWrapper = styled(SearchIndustryWrapper)`
  top: -40px;
`;


export const IndustryInputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
  position: relative;
`;

export const SearchIcon = styled.div`
  position: relative;
  left: 0px;
  bottom: 0px;
`;

export const ChevronDown = styled.div`
  position: absolute;
  right: 10px;
  top: 15px;
`;

export const SearchInputList = styled.input`
  width: 400px;
  font-size: 17px;
  line-height: 130%;
  border-radius: 16px;
  background: #fff;
  color: var(--txt-dark, #001731);
  padding: 14px 0px 14px 0px;
  &::placeholder {
    font-size: 17px;
    line-height: 140%;
    color: var(--txt-grey-light, #dadada);
  }
  cursor: pointer;
`;

export const List = styled.ul`
  max-height: 350px;
  overflow-y: scroll;
  padding-right: 40px;
`;

export const InputsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`;

export const StartButtonWrapper = styled.div`
    position: absolute;
    transform: translate(-50%);
    top: 100px;
    width: 475px;
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
`;

export const Item = styled.li`
  padding: 8px 0px;

  font-size: 17px;
  line-height: 140%;

  color: var(--txt-dak-light, #143255);
  cursor: pointer;
  &:hover {
    color: var(--txt-blue, #0095c0);
  }
`;

export const ProductSpecificationInputs = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48%;
  height: 890px;

  background: linear-gradient(
    46deg,
    rgba(5, 119, 152, 0.8) 8.94%,
    rgba(80, 58, 214, 0.7) 46.63%,
    rgba(3, 173, 112, 0.7) 88.48%
  );
  border-radius: 16px;
`;

export const ProductSpecificationStyle = styled(ProductSpecificationInputs)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
`;

export const DescriptionStyle = styled.p`
    width: 75%; 
    text-align: center;
    font-size: 17px;
    font-weight: 500;
    line-height: 150%;
    margin-bottom: 77px;
    color: var(--txt-light-blue, #f4ffff);
`;

export const ProductSpecificationButtonsStyle = styled.div`
    height: 45%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
`;

export const ProductSpecificationInputsWrapper = styled.div`
    position: relative;
`;

export const SpecificationInputsContinueButtonWrapper = styled.div`
    width: 75%;
    margin-top: 75px;
`;

export const TitleSpecificationBlock = styled.h2`
  margin: 42px 0 120px;

  font-size: 36px;
  font-weight: 500;

  color: var(--txt-light-blue, #f4ffff);
`;

export const TitleSpecificationInputsBlock = styled.h2`
  /* margin: 42px 0 120px; */
  position: absolute;
  top: -380px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 36px;
  font-weight: 500;
  color: var(--txt-light-blue, #f4ffff);
`;