import React from "react";
import styled from "styled-components";
import { parseCapitalString } from "../Company";

interface RadioButtonWithOptionsProps {
  title: string;
  initialValue: string;
  options: string[];
  setValue: (key: string, value: string) => void;
}

const RadioWrapper = styled.div`
margin-left: 20px;
  display: flex;
`;
const Title = styled.p`
  margin-right: 20px;
  margin-bottom: 10px;
`;
const RadioLabel = styled.label`
  position: relative;
  cursor: pointer;
`;

const RadioInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;

  &:checked ~ span:after {
    display: block;
  }
`;

interface RadioCheckmarkProps {
  checked: boolean;
}
const RadioCheckmarkWrapper = styled.div`
  width: 30px;
`;
const RadioCheckmark = styled.span<RadioCheckmarkProps>`
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: ${(props) => (props.checked ? "#0095C0" : "#fff")};
  border: 1px solid #004c62;

  border-radius: 50%;

  &:after {
    content: "";
    position: absolute;
    display: none;
    top: 4px;
    left: 4px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #fafafa;
  }
`;

const RadioButtonWithOptions: React.FC<RadioButtonWithOptionsProps> = ({
  title,
  initialValue,
  options,
  setValue,
}) => {
  return (
    <RadioWrapper>
      {options.map((option, index) => (
        <React.Fragment key={index}>
          <RadioLabel>
            <RadioCheckmarkWrapper></RadioCheckmarkWrapper>
            <RadioInput
              type="radio"
              value={option}
              checked={initialValue === option}
              onChange={() => setValue(title, option)}
            />
            <RadioCheckmark checked={initialValue === option} />
          </RadioLabel>
          <Title>{parseCapitalString(option)}</Title>
        </React.Fragment>
      ))}
    </RadioWrapper>
  );
};

export default RadioButtonWithOptions;
