import { Outlet, useLocation } from "react-router-dom";
import ReturnButton from "../ReturnButton/ReturnButton";
import TitleHeader from "../TitleHeader/TitleHeader";
import ProductSpecification from './ProductSpecification/ProductSpecification';
import styled from "styled-components";
import ProductSourcingBrief from "./ProductSourcingBrief/ProductSourcingBrief";
import { routes } from "../../routes/routes";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getAllChatsAsync, getAllIndustriesAsync } from "../../store/slices/Assistants/AssistanseSlice";
import { IndustryType } from "../../utils/types";

export const TittleHeaderWrapper = styled.div`
    margin-left: 30px;
`;

export const ButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-left: 30px;
    `;


export const BlocksWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    `;

export const AssistantsPageStyle = styled.div`
        width: 1600px;
    `;

const Assistants = () => {
    // const appDispatch = useAppDispatch();

    // const [selectedButton, setSelectedButton] = useState("Assistants");


    // const routeMap: { [key: string]: routes } = {
    //     // "Matching": routes.MATCHING,
    //     "Specification": routes.SPECIFICATION,
    //     // "Something": routes.SOMETHING
    // };
    // const handleButtonClick = async (item: string) => {
    // const route = routeMap[item];
    // if (route) {
    //     if (route === routes.SPECIFICATION) {
    //         const isCreated = await appDispatch(createThreadAsync());
    //         if (isCreated.meta.requestStatus === 'fulfilled') {
    //             navigate(`/assistants${routes.SPECIFICATION}`);
    //         }
    //     } else {
    //         navigate(`/assistants${routes.MATCHING}`);
    //     }
    //     setSelectedButton(item);
    // }
    // };
    const location = useLocation();
    const isSpecificationRoute = location.pathname.includes(`${routes.SPECIFICATION}`);
    const dispatch = useAppDispatch();
    const industries: IndustryType[] = useAppSelector((state) => state.assistants.industries) || [];


    useEffect(() => {
        if(industries?.length === 0){
            dispatch(getAllIndustriesAsync());
        }
            dispatch(getAllChatsAsync());
    }, []);

    return (
        <AssistantsPageStyle>
            <ButtonWrapper>
                <ReturnButton />
                <TittleHeaderWrapper>
                    <TitleHeader text={"Assistants"} />
                </TittleHeaderWrapper>
            </ButtonWrapper>

            {!isSpecificationRoute &&
                <BlocksWrapper>
                    <ProductSourcingBrief />
                    <ProductSpecification />
                </BlocksWrapper>
            }
            <Outlet />
        </AssistantsPageStyle>
    )
}

export default Assistants