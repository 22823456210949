import React, { useRef, useState } from "react";
import PlusIcon from "../../assets/icons/PlusIcon";
import { AvatarWrapper, Avatar, AvatarInput, AvatarLabel } from "./styles";
import {
  updateCompanyAvatar,
  uploadFilesToServer,
} from "../../store/slices/userSlice";
import { useDispatch } from "react-redux";
import { CompanyType, CompanyLogoProps } from "../../utils/types";
import ModalImageErrorMessage from "../ModalImageErrorMessage/ModalImageErrorMessage";
import { useAppSelector } from "../../store/hooks";

const UserLogo: React.FC<CompanyLogoProps> = ({
  avatar,
  changeAvatar,
  newAvatar,
}) => {
  const dispatch = useDispatch();
  const [fileList, setFileList] = useState<File[]>([]);
  const [imageTypeError, setImageTypeError] = useState<string | null>(null);
  const avatarInputRef = useRef<HTMLInputElement | null>(null);
  const userAccount = useAppSelector((store) => store.user.userAccount!);

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFile = e.target.files?.[0];
    if (newFile) {
      const allowedFormats = ["image/jpeg", "image/jpg", "image/png"];
      const uploadAvatarAllowedSize = 3000000;
      if (newFile.size > uploadAvatarAllowedSize) {
        setImageTypeError(
          "Image size is too large. Please upload image less then 3MB."
        );
        return;
      }
      if (allowedFormats.includes(newFile.type)) {
        try {
          const newFileList = [...fileList, newFile];
          setFileList(newFileList);
          const uploadedAvatars = await dispatch(
            uploadFilesToServer({ files: newFileList }) as any
          );
          dispatch(
            updateCompanyAvatar({
              avatar_link: uploadedAvatars.payload[0].link,
              token: userAccount.token,
              companyType: userAccount.companyType.toLowerCase() as CompanyType,
            }) as any
          );
          changeAvatar({ avatar: uploadedAvatars.payload[0].link } as any);
          setFileList([]);
        } catch (error) {
          console.error("Error uploading file:", error);
        }
      } else {
        setImageTypeError(
          "Invalid image format. Please use valid format / .jpg, .jpeg, .png /"
        );
      }
    }
  };

  const handleModalCLose = () => {
    setImageTypeError(null);
    if (avatarInputRef.current !== null) {
      avatarInputRef.current.value = "";
    }
  };

  return (
    <>
      <AvatarWrapper >
        <Avatar src={newAvatar || avatar} alt="logo" />
        <AvatarInput
          type="file"
          ref={avatarInputRef}
          id="input_file"
          accept="image/*"
          onChange={handleFileChange}
        />
        <AvatarLabel htmlFor="input_file">
          <PlusIcon />
        </AvatarLabel>
      </AvatarWrapper>
      {imageTypeError && (
        <ModalImageErrorMessage
          errorMessage={imageTypeError}
          onClose={handleModalCLose}
        />
      )}
    </>
  );
};

export default UserLogo;
