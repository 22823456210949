import styled from "styled-components";

interface IImageProps {
  $isOpen: boolean;
}

interface IProps {
  $isCenter: boolean;
}

export const Container = styled.div``;

export const NameProduct = styled.p`
  color: var(--txt-dak-light, #143255);
  font-size: 24px;
  font-weight: 500;
  line-height: 140%;
`;

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 50px;
  margin-bottom: 60px;
`;

export const LeftWrapper = styled.div``;

export const RightWrapper = styled.div`
  border-left: 1px solid var(--border, #e8e8e8);
  padding-left: 31px;
`;

export const AboutProduct = styled.div`
  margin-bottom: 20px;
`;

export const OrderQuantity = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 10px;
`;

export const UnitPrice = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const Paragraph = styled.p`
  color: var(--txt-dark, #001731);
  font-size: 17px;
  font-weight: 500;
  line-height: 140%;
`;

export const SubParagraph = styled.p`
  color: var(--txt-dak-light, #143255);
  font-size: 17px;
  font-weight: 400;
  line-height: 140%;
`;

export const GreenParagraph = styled.p`
  color: var(--green, var(--Secon, #03b62a));
  font-size: 17px;
  font-weight: 400;
  line-height: 140%;
`;

export const SubTitle = styled.p`
  color: var(--txt-dark, #001731);
  font-size: 17px;
  font-weight: 500;
  line-height: 140%;
`;

export const ImagesContainer = styled.div`
  width: 100%;
`;

export const CompanyPhoto = styled.div`
  width: 100%;
  margin-bottom: 30px;
`;

export const ProductDetails = styled.div`
  width: 47%;
  margin-right: 20px;
`;

export const PhotoMax = styled.img`
  width: 367px;
  height: 350px;
  border-radius: 16px;
`;

export const PhotoMinContainer = styled.div<IProps>`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: ${(props) => (props.$isCenter ? "space-between" : "center")};
  align-items: center;

  margin-right: 30px;
`;

export const PhotoMini = styled.img<IImageProps>`
  width: 50px;
  height: 50px;
  border-radius: 10px;
  border: ${(props) =>
    props.$isOpen ? "3px solid var(--batton, #0095c0)" : ""};
  cursor: pointer;
`;

export const CompanyPhotoElement = styled.img`
  width: 633px;
  height: 470px;
  border-radius: 16px;
  transition: opacity 0.5s ease-in-out;
  object-fit: scale-down;
`;

export const PhotoWrapper = styled.div`
  display: flex;
  gap: 40px;
`;

export const EllipseWrapper = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;

  border-radius: 50%;
  background: radial-gradient(
    123.78% 123.78% at 21.25% 11.25%,
    #b4bbff 0%,
    rgba(255, 255, 255, 0) 100%
  );
`;

export const PhotoMinWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 10px;
`;
