import styled from "styled-components";
import DatePicker, { DatePickerProps } from "react-datepicker";

interface WeekButtonProps {
  isActive: boolean;
}

export const Container = styled.div``;

export const Title = styled.p`
  font-size: 38px;
  font-weight: 500;
  line-height: 45.6px;
  grid-column: span 3;
  margin: 0 auto;
  margin-bottom: 10px;
`;

export const SubTitle = styled.p`
  font-size: 30px;
  font-weight: 400;
  line-height: 45.6px;
  margin: 0 auto;
`;

export const SubTitleWrapper = styled.div`
display: flex;

margin-left: 30px;
`;

export const Paragraph = styled.p`
  font-size: 25px;
  font-weight: 400;
  line-height: 45.6px;
`;

export const BlueParagraph = styled.p`
  font-size: 25px;
  font-weight: 400;
  line-height: 45.6px;
  color: var(--batton, #0095c0);
`;

export const SubFinancicalTitle = styled.p`
  font-size: 30px;
  font-weight: 400;
  line-height: 45.6px;
  grid-column: span 3;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  border-bottom: 1px solid var(--batton, #0095c0);
`;

export const TopWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-bottom: 20px;
`;

export const BottomWrapper = styled.div`
  display: contents;
`;

export const GraphicsWrapper = styled.div`
  grid-column: 1 / 2;
  width: 100%;
  text-align: center;
  margin-bottom: 40px;
`;

export const FinancicalWrapper = styled.div`
  grid-column: 1;
  border: 1px solid var(--batton, #0095c0);
  border-radius: 12px;
  padding: 16px;
`;

export const BestCompaniesWrapper = styled.div`
  grid-column: 2;
  text-align: center;
  padding: 16px;
`;

export const OrdersWrapper = styled.div`
  grid-column: 3;
  border: 1px solid var(--batton, #0095c0);
  border-radius: 12px;
  padding: 16px;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SubOrdersWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const OrdersStatus = styled.div``;

export const NumberOrders = styled.div``;

export const DiagramWrapper = styled.div`
  display: grid;
  grid-column: 2 / 3;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  gap: 10px;
`;

export const TotalOrders = styled.div`
  grid-column: 1;
  grid-row: 1;
`;

export const NewCustomers = styled.div`
  grid-column: 2;
  grid-row: 1;
`;

export const TopCustomers = styled.div`
  grid-column: 1;
  grid-row: 2;
`;

export const PayingOrders = styled.div`
  grid-column: 2;
  grid-row: 2;
`;

export const NewOldCustomers= styled.div`
  grid-column: 1;
  grid-row: 3;
`;

export const OperationalEfficiency= styled.div`
  grid-column: 2;
  grid-row: 3;
`;

export const ReturnRate= styled.div`
  grid-column: 1;
  grid-row: 4;
`;

export const OrderDetails= styled.div`
  grid-column: 2;
  grid-row: 4;
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const BottomDiargamWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AverageWrapper = styled.div`
  display: flex;
  align-items: center;

  gap: 30px;
  width: 100%;
  height: 100%;
  margin-left: 60px;
`;

export const TableWrapper = styled.div`
  margin-bottom: 30px;
`;

export const MapWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const Thead = styled.thead``;

export const TableHeader = styled.th`
  border: 1px solid var(--txt-dark-light, #143255);
  padding: 12px;
  text-align: center;
  background-color: var(--batton, #0095c0);
  color: var(--white, #f4ffff);
  font-size: 20px;
`;

export const TableBody = styled.tbody``;

export const Tr = styled.tr``;

export const Td = styled.td`
  border: 1px solid var(--txt-dark-light, #143255);
  padding: 12px;
  text-align: center;
  font-size: 20px;
`;

export const TableMapWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const WhiteSquare = styled.div`
  position: absolute;
  top: 3000px;
  left: 830px;
  width: 80px;
  height: 80px;
  background-color: white;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const CalendarButton = styled.button<WeekButtonProps>`
  border-radius: 12px;
  border: 1px solid var(--border, #bdbdbd);
  /* background: var(--wiht, #fff); */
  display: flex;
  padding: 8px 16px 10px 16px;
  background: ${({ isActive }) =>
    isActive ? "var(--button, #0095c0)" : "var(--wiht, #fff)"};
  color: ${({ isActive }) =>
    isActive ? "var(--txt-light-blue, #f4ffff)" : "var(--batton, #0095c0)"};

  &:hover {
    background: var(
      --hover,
      linear-gradient(254deg, #0095c0 17.54%, rgba(3, 182, 42, 0.6) 90.58%)
    );
    /* color: var(--txt-light-blue, #f4ffff); */
    border: 1px solid transparent;
  }
`;

export const WeekButton = styled.button<WeekButtonProps>`
  border-radius: 12px;
  border: 1px solid var(--border, #bdbdbd);
  background: ${({ isActive }) =>
    isActive ? "var(--button, #0095c0)" : "var(--wiht, #fff)"};
  color: ${({ isActive }) =>
    isActive ? "var(--txt-light-blue, #f4ffff)" : "var(--batton, #0095c0)"};
  display: flex;
  padding: 15px 19px 12px 19px;
  justify-content: center;

  &:hover {
    background: var(
      --hover,
      linear-gradient(254deg, #0095c0 17.54%, rgba(3, 182, 42, 0.6) 90.58%)
    );
    color: var(--txt-light-blue, #f4ffff);
    border: 1px solid transparent;
  }

  /* height: 100%;
  max-height: 50px; */
`;

export const IconWrapper = styled.div`
  width: 30px;
  height: 30px;
`;

export const CalendarContainer = styled.div`
  /* position: relative; */
  display: flex;
  align-items: center;
  z-index: 2;
`;

export const CalendarActiveWrapper = styled.div`
  border-radius: 16px;
  border: 1px solid var(--batton, #0095c0);
  background: var(--wiht, #fff);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px 20px 16px 30px;
  flex-direction: column;

  position: absolute;
  z-index: 2;
  right: 400px;
  top: 90px;

  width: 100%;
  max-width: 400px;
`;

export const CalendarActiveButton = styled.button`
  background: var(--wiht, #fff);
  color: var(--txt-dark, #001731);
  font-size: 17px;
  font-weight: 600;
  line-height: 140%;
  position: relative;
`;

export const TimePeriodWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
`;

export const TimePeriodButton = styled.button`
  color: var(--txt-dak-light, #143255);
  font-size: 17px;
  font-weight: 400;
  line-height: 140%;
  &:hover {
    color: var(--txt-blue, #0095c0);
  }
`;

export const TimePeriodParagraph = styled.p`
  color: var(--txt-dak-light, #143255);
  font-size: 17px;
  font-weight: 400;
  line-height: 140%;
`;

export const SearchInputWrapper = styled.div`
  position: relative;
  /* margin-left: 30px; */
  /* max-width: 368px;
  width: 100%; */
`;

//  export const CustomDatePicker = styled(DatePicker)<DatePickerProps>`
//   .react-datepicker {
//     border: 2px solid #0095c0;
//     border-radius: 5px;
//     padding: 10px;
//   }

//   .react-datepicker__day--selected,
//   .react-datepicker__day--in-selecting-range,
//   .react-datepicker__day--in-range {
//     border-color: #0095c0;
//   }

//   .react-datepicker__day:hover {
//     border-color: #143255;
//   }
// `;

export const DateWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const StyledInputDate = styled.input.attrs({ type: "date" })`
  margin: 0 auto;

  /* Firefox */
  &::-moz-placeholder {
    text-align: center;
  }

  /* Chrome, Safari, Edge, Opera */
  &::-webkit-input-placeholder {
    text-align: center;
  }

  /* Microsoft Edge */
  &::-ms-input-placeholder {
    text-align: center;
  }

  &::-webkit-calendar-picker-indicator {
    cursor: pointer;
    width: 15px;
    height: 15px;
  }
`;

export const DateContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  margin-top: 10px;
`;

export const TopButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const CalendarTopWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const CrossIconWrapper = styled.div`
  position: absolute;
  right: 25px;
  cursor: pointer;
`;


export const NoneParagraph = styled.p`
  font-size: 25px;
  font-weight: 600;
  line-height: 45.6px;
  text-align: center;
`;

export const NoneParagraphWrapper=styled.div`
display: flex;
align-items: center;
justify-content: center;
margin: auto 0;
height: 100%;
`