import React from "react";
import {
  BriefContent,
  ContentWrapper,
  LabelField,
  ValueField,
  Overlay,
  Title,
  WrapperField,
  ButtonWrapper,
} from "../ModalStyle";
import { DelegateSearchType } from "../../../utils/types";
import { TitleWrapper } from "../ModalConfirmationEmailAndPhone/ModalConfirmationEmailAndPhoneStyles";
import Button from "../../Button/Button";

interface Props {
  brief?: DelegateSearchType;
  setShowModalMagnifying: React.Dispatch<React.SetStateAction<boolean>>;
}

const ModalBrief: React.FC<Props> = ({ brief, setShowModalMagnifying }) => {
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  };

  const briefFields = [
    { label: "Name", value: brief?.name },
    { label: "Overview", value: brief?.overview },
    { label: "Price", value: brief?.priceUnit },
    { label: "Currency", value: brief?.currency },
    { label: "Price unit", value: brief?.priceUnit },
    { label: "Payment terms", value: brief?.paymentTerms },
    { label: "Payment terms other", value: brief?.paymentTermsOther },
    { label: "Order quantity", value: brief?.orderQuantity },
    { label: "Order unit", value: brief?.orderUnit },
    { label: "Order frequency", value: brief?.orderFrequency?.toLowerCase() },
    { label: "Lead time", value: brief?.leadTime ? formatDate(brief?.leadTime) : "" },
    { label: "Lead time asap", value: brief?.leadTimeAsap ? "Yes" : "No" },
    { label: "Need delivery", value: brief?.needDelivery ? "Yes" : "No"},
    { label: "Delivery deadline", value: brief?.deliveryDeadline ? formatDate( brief?.deliveryDeadline) : "" },
    { label: "Delivery deadline asap", value: brief?.deliveryDeadlineAsap ? "Yes" : "No" },
    { label: "Delivery address", value: brief?.deliveryAddress },
    { label: "Quality standards", value: brief?.qualityStandards },
    { label: "Certificates", value: brief?.certificates },
    { label: "Packaging requirements", value: brief?.packagingRequirements },
    { label: "Labeling Requirements", value: brief?.labelingRequirements },
    { label: "Additional requests", value: brief?.additionalRequests },
  ];

  return (
    <>
      <Overlay />
      <BriefContent>
        <TitleWrapper>
          <Title>Delegated Search {brief?.identification}</Title>
        </TitleWrapper>
        <ContentWrapper>
          {briefFields.map(({ label, value }) => (
            <WrapperField>
              <LabelField>{label}:</LabelField>
              <ValueField>{value}</ValueField>
            </WrapperField>
          ))}
        </ContentWrapper>
        <TitleWrapper>
          <Title>Total price: {brief?.price} {brief?.currency}</Title>
        </TitleWrapper>
        <ButtonWrapper>
          <Button
            text={"Close"}
            onClick={() => setShowModalMagnifying(false)}
          ></Button>
        </ButtonWrapper>
      </BriefContent>
    </>
  );
};

export default ModalBrief;
