import React, { useEffect } from "react";
import { RootState } from "../store/store";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../store/hooks";
import { routes } from "../routes/routes";
import OrdersPage from "../components/OrdersPage/OrdersPage";

const Orders: React.FC = () => {
  const user = useAppSelector((state: RootState) => state.user);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user.userAccount) {
      navigate(routes.HOME);
    }
  }, [user, navigate]);
  return <OrdersPage/>;
};

export default Orders;
