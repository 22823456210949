import React from "react";
import SearchIcon from "../../../assets/icons/SearchIcon";
import {
  SearchInputWrapper,
  Input,
  ButtonBlock,
} from "./styles";

interface IProps {
  placeholderValue?: string;
}

const ProductsInput: React.FC<IProps> = ({placeholderValue}) => {
  return (
    <>
      <SearchInputWrapper>
        <Input type="text" placeholder={placeholderValue} />
        <ButtonBlock>
          <SearchIcon />
        </ButtonBlock>
      </SearchInputWrapper>
    </>
  );
};

export default ProductsInput;
