import React, { useState, useEffect } from "react";
import {
  AvailabilitySwitch,
  AvailabilityWrapper,
  ChevronRightWrapper,
  CountrySearchWrapper,
  IndustryKeqwordsP,
  LeftText,
  OrderQuantity,
  Paragraph,
  Quantity,
  SearchIcon,
  SearchInputCountryList,
  SearchInputWrapper,
} from "./style";
import CircleSwitch from "../../CircleSwitch/CircleSwitch";
import {
  ChevronDown,
  CountryInputWrapper,
  CountryItem,
  CountryList,
  InputWrapper,
  SearchButton,
  SearchInput,
} from "../../Filter/style";
import ChevronDownIcon from "../../../assets/icons/ChevronDownIcon";
import SearchIconBlack from "../../../assets/icons/SearchIconBlack";
import ChevronRightIcon from "../../../assets/icons/ChevronRightIcon";
import {
  AvailabilityStatus,
  PartnerBaseProvider,
  ProviderDetails,
} from "../../../utils/types";
import { ItemFail } from "../../PesonalInfo/styles";
import { ParagraphRight } from "../ReputationFilter/style";

interface ManufactoringFilterProps {
  companyItems: PartnerBaseProvider[];
  filterValues: ProviderDetails;
  setFilterValues: React.Dispatch<React.SetStateAction<ProviderDetails>>;
  onFilterChange: (filterValues: ProviderDetails) => void;
}

const ManufactoringFilter: React.FC<ManufactoringFilterProps> = ({
  companyItems,
  setFilterValues,
  filterValues,
  onFilterChange,
}) => {
  const [searchText, setSearchText] = useState("");
  const [showCountryList, setShowCountryList] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState<string[]>([]);
  // useEffect(() => {
  //   updateFilter();
  // }, [filterManufactoringProviderText]);

  const filterShippingTo = Array.from(
    new Set(
      companyItems.flatMap((company) =>
        company.shippingTo.map((shippingTo) => shippingTo.trim())
      )
    )
  ).filter(
    (shippingTo) =>
      shippingTo.toLowerCase().includes(searchText.toLowerCase()) &&
      !filterValues.shippingTo?.includes(shippingTo) &&
      !selectedCountry.includes(shippingTo)
  );

  const handleCountryClick = (shippingTo: string) => {
    setFilterValues((prevState) => {
      const updatedCountry = [...(prevState?.shippingTo || []), shippingTo];
      const updatedSelectedCountry = selectedCountry.filter(
        (item) => item !== shippingTo
      );
      setSelectedCountry(updatedSelectedCountry);
      return {
        ...prevState,
        shippingTo: updatedCountry,
      };
    });
  };

  // const updateFilter = () => {
  //   const filterValues: ProviderDetails = {
  //     availability: filterManufactoringProviderText?.availability || [],
  //     minimumOrderQuantity:
  //       filterManufactoringProviderText?.minimumOrderQuantity || false,
  //     shippingTo: filterManufactoringProviderText?.shippingTo || [],
  //   };
  //   onFilterChange(filterValues);
  // };

  return (
    <div>
      <div>
        <IndustryKeqwordsP>Availability</IndustryKeqwordsP>
        <AvailabilityWrapper>
          <AvailabilitySwitch>
            <CircleSwitch
              isActive={
                filterValues?.availability?.includes(AvailabilityStatus.NOW) ||
                false
              }
              onToggle={() =>
                setFilterValues({
                  ...filterValues,
                  availability: [AvailabilityStatus.NOW],
                })
              }
            />

            <Paragraph>Now</Paragraph>
          </AvailabilitySwitch>
          <AvailabilitySwitch>
            <CircleSwitch
              isActive={
                filterValues?.availability?.includes(
                  AvailabilityStatus.NEXT_WEEK
                ) || false
              }
              onToggle={() =>
                setFilterValues({
                  ...filterValues,
                  availability: [AvailabilityStatus.NEXT_WEEK],
                })
              }
            />
            <Paragraph>Next week</Paragraph>
          </AvailabilitySwitch>
          <AvailabilitySwitch>
            <CircleSwitch
              isActive={
                filterValues?.availability?.includes(
                  AvailabilityStatus.NEXT_MONTH
                ) || false
              }
              onToggle={() =>
                setFilterValues({
                  ...filterValues,
                  availability: [AvailabilityStatus.NEXT_MONTH],
                })
              }
            />
            <Paragraph>Next month</Paragraph>
          </AvailabilitySwitch>
        </AvailabilityWrapper>
        <OrderQuantity>
          <LeftText>
          <Quantity>Minimum order quantity:</Quantity>
          </LeftText>
          <CircleSwitch
            isActive={filterValues?.minimumOrderQuantity || false}
            onToggle={(e) =>
              setFilterValues({
                ...filterValues,
                minimumOrderQuantity: !filterValues?.minimumOrderQuantity,
              })
            }
          />
        <ParagraphRight>No</ParagraphRight>
        </OrderQuantity>
        <IndustryKeqwordsP>Shipping to</IndustryKeqwordsP>
        <InputWrapper>
          {!showCountryList ? (
            <SearchInputWrapper>
              <SearchInput
                type="text"
                name="country"
                placeholder="Enter country"
                onClick={() => setShowCountryList(!showCountryList)}
                value={filterValues?.shippingTo?.join(" ") || []}
              />
              <SearchButton
                onClick={() => setShowCountryList(!showCountryList)}
              >
                <SearchIconBlack />
              </SearchButton>
              <ChevronRightWrapper
                onClick={() => {
                  setShowCountryList(!showCountryList);
                }}
              >
                <ChevronRightIcon />
              </ChevronRightWrapper>
            </SearchInputWrapper>
          ) : (
            <CountrySearchWrapper>
              <CountryInputWrapper onClick={() => setShowCountryList(false)}>
                <SearchIcon type="button">
                  <SearchIconBlack />
                </SearchIcon>
                <ChevronDown
                  onClick={() => {
                    setShowCountryList(false);
                  }}
                >
                  <ChevronDownIcon />
                </ChevronDown>
                <SearchInputCountryList
                  placeholder="Enter country"
                  type="text"
                  autoFocus
                  value={
                    filterValues.shippingTo?.length &&
                      !filterValues.shippingTo.includes(searchText)
                      ? filterValues.shippingTo?.join(" ")
                      : searchText
                  }
                  onChange={(e) => {
                    const newValue = e.target.value;
                    setFilterValues({
                      ...filterValues,
                      shippingTo: newValue
                        .split("")
                        .map((item) => item.trim()),
                    });
                    setSearchText(newValue);
                  }}
                />
              </CountryInputWrapper>
              <CountryList>
                {filterShippingTo &&
                  filterShippingTo.length > 0 &&
                  filterShippingTo.map((country: string) => {
                    if (country && country.trim() !== "") {
                      return (
                        <CountryItem
                          key={country}
                          onClick={() => handleCountryClick(country)}
                        >
                          {country}
                        </CountryItem>
                      );
                    } else {
                      return null;
                    }
                  })}
                {filterShippingTo && filterShippingTo.length === 0 && (
                  <ItemFail>Choose from existing country</ItemFail>
                )}
              </CountryList>
            </CountrySearchWrapper>
          )}
        </InputWrapper>
      </div>
    </div>
  );
};

export default ManufactoringFilter;
