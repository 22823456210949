import React, { ChangeEvent, useRef, useState } from "react";
import CrossIcon from "../../assets/icons/CrossIcon";
import {
  UploadFile,
  UploadButton,
  FileTitle,
  VectorWrapper,
  PhotoWrapper,
  PhotoId,
  CloseWrapper,
  Span,
  Input,
} from "./styles";
import VectorIcon from "../../assets/icons/VectorIcon";
import ModalImageErrorMessage from "../ModalImageErrorMessage/ModalImageErrorMessage";

interface MediaUploadProps {
  selectedFiles: File[];
  onFilesChange: (files: File[]) => void;
  onlyPhoto?: boolean;
}

const MediaUpload: React.FC<MediaUploadProps> = ({
  selectedFiles,
  onFilesChange,
  onlyPhoto,
}) => {
  const filesInputRef = useRef<HTMLInputElement | null>(null);
  const [imageTypeError, setImageTypeError] = useState<string | null>(null);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    if (event.target.files) {
      const files: FileList = event.target.files;

      const allowedFormatsPhoto = [
        "image/jpeg",
        "image/jpg",
        "image/png",
      ];

      const allowedFormatsFull = allowedFormatsPhoto.concat([
        "application/pdf",
        "application/msword",
      ]);

      const allowedFormats = onlyPhoto ? allowedFormatsPhoto : allowedFormatsFull;

      const invalidFiles = Array.from(files).filter(
        (file) => !allowedFormats.includes(file.type)
      );
      if (invalidFiles.length > 0) {
        setImageTypeError(
          "Invalid files format. Please use valid format / .jpg, .jpeg, .png, .pdf, .doc /"
        );
        if(onlyPhoto){
          setImageTypeError(
            "Invalid files format. Please use valid format / .jpg, .jpeg, .png /"
          );
        }
        return;
      }; 
      
      const maxSizeBytes = 3 * 1024 * 1024;
      const oversizedFiles = Array.from(files).filter(
        (file) => file.size > maxSizeBytes
      );
      if (oversizedFiles.length > 0) {
        setImageTypeError(
          "Files size exceeds the limit of 3 MB. Please choose a smaller file."
        );
        return;
      }
      
        const newFiles = [...selectedFiles, ...Array.from(files)];
        onFilesChange(newFiles);
        
        if (filesInputRef.current) {
          filesInputRef.current.value = "";
        }
    }
  };

  const handleRemoveFile = (index: number) => {
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);
    onFilesChange(updatedFiles);
  };

  const handleModalClose = () => {
    setImageTypeError(null);
    if (filesInputRef.current !== null) {
      filesInputRef.current.value = "";
    }
  };

  return (
    <>
      <UploadFile>
        <UploadButton
          onClick={() =>
            document.getElementById("fileInputAttachments")?.click()
          }
        >
          Upload
        </UploadButton>
        <Span>file</Span>
        <Input
          type="file"
          ref={filesInputRef}
          id="fileInputAttachments"
          onChange={handleFileChange}
          multiple
        />
      </UploadFile>
      {selectedFiles.length > 0 && (
        <FileTitle>
          <PhotoWrapper>
            {selectedFiles.map((file, index) => (
              <PhotoId key={index}>
                <VectorWrapper>
                  <VectorIcon />
                </VectorWrapper>
                {file.name}
                <CloseWrapper onClick={() => handleRemoveFile(index)}>
                  <CrossIcon />
                </CloseWrapper>
              </PhotoId>
            ))}
          </PhotoWrapper>
        </FileTitle>
      )}
      {imageTypeError && (
        <ModalImageErrorMessage
          errorMessage={imageTypeError}
          onClose={handleModalClose}
        />
      )}
    </>
  );
};

export default MediaUpload;
