import styled from "styled-components";

interface IProps {
  $isActive?: boolean;
}

export const SwitchContainer = styled.div`
  display: flex;
  gap: 30px;
  margin-bottom: 30px;
`;

export const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const IconContainer = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(90deg);
`;

export const SwitchButton = styled.button<IProps>`
  max-width: 151px;
  width: 100%;
  display: flex;
  padding: 14px 30px 16px 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 16px;
  border: 1px solid var(--border, #bdbdbd);
  background: var(--wiht, #fff);

  color: var(--txt-dark, #001731);
  font-size: 17px;
  font-weight: 400;
  line-height: 140%;

  background-color: ${({ $isActive }) =>
    $isActive ? "var(--batton, #0095C0)" : "var(--wiht, #FFF)"};
  color: ${({ $isActive }) =>
    $isActive ? " var(--wiht, #FFF)" : "var(--txt-dark, #001731)"};
`;

export const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  margin-bottom: 30px;
`;

export const FilterTitle = styled.h3`
  max-width: 99px;
  width: 100%;

  font-size: 24px;
  font-weight: 500;
  line-height: 140%;

  color: var(--txt-dark, #001731);
`;

export const FilterBlock = styled.div`
  max-width: 335px;
  width: 100%;

  display: flex;
  padding: 14px 20px 16px 30px;
  align-items: center;
  justify-content: space-between;

  border-radius: 16px;
  border: 1px solid var(--border, #bdbdbd);
  background: var(--wiht, #fff);

  cursor: pointer;
`;

export const FilterBlockName = styled.h4`
  font-size: 17px;
  font-weight: 500;
  line-height: 140%;

  color: var(--txt-dark, #001731);
`;

export const ChevronRightWrapper = styled.div``;

export const ClearWrapper = styled.button`
  max-width: 335px;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 14px 30px 16px 30px;

  font-size: 17px;
  font-weight: 500;
  line-height: 140%;

  border-radius: 16px;
  border: 1px solid var(--border, #bdbdbd);
  background: var(--wiht, #fff);
  cursor: pointer;
  color: var(--txt-dark, #001731);

  &:hover {
    background: var(
      --hover,
      linear-gradient(254deg, #0095c0 17.54%, rgba(3, 182, 42, 0.6) 90.58%)
    );
    color: var(--txt-light-blue, #f4ffff);

    &:active {
      background: var(--green, #03b62a);
      color: var(--txt-light-blue, #f4ffff);
    }
  }
`;

export const SortBlock = styled.div`
  max-width: 150px;
  width: 100%;

  display: flex;
  padding: 14px 20px 16px 30px;
  align-items: center;
  justify-content: space-between;

  border-radius: 16px;
  border: 1px solid var(--border, #bdbdbd);
  background: var(--wiht, #fff);

  cursor: pointer;
`;

export const SortContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 30px;
  margin-bottom: 20px;
`;

export const ChartButton = styled.div`
  display: flex;
  padding: 14px;
  align-items: center;

  border-radius: 12px;
  border: 1px solid var(--border, #e8e8e8);
  background: var(--wiht, #fff);

  cursor: pointer;
`;
