import { useState, useEffect } from "react";
import {
  ButtonSave,
  ButtonWrapperLink,
  CompanyWrapperLink,
  CrossWhiteIconWrapper,
  DeleteWrapper,
  ImageWrapper,
  IndustryParagraph,
  IndustryWrapper,
  KeywordsBlock,
  KeywordsContainer,
  KeywordsParagraph,
  KeywordsWrapper,
  LocationWrapper,
  LogoButtonWrapper,
  NameWrapper,
  Paragraph,
  ParagraphLocation,
  RatingReviewsWrapper,
  SaveParagraph,
  SaveWrapper,
  Up,
  VectorWrapper,
  Wrapper,
  WrapperProvider,
} from "../PartnerBaseList/style";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import UnionIcon from "../../../assets/icons/UnionIcon";
import VectorYellowIcon from "../../../assets/icons/VectorYellowIcon";
import { renderStars } from "../../CompanyItem/CompanyItem";
import Button from "../../Button/Button";
import { PartnerBaseProvider, Roles } from "../../../utils/types";
import { defaultAvatar } from "../../../assets/img";
import {
  addDeleteFavoriteAsync,
  getFavoriteProvidersAsync,
} from "../../../store/slices/userSlice";
import CrossWhiteIcon from "../../../assets/icons/CrossWhireIcon";
import Close from "../../Close/Close";

interface ProviderCardProps {
  provider: PartnerBaseProvider;
  handleOpenDialog: (provider: PartnerBaseProvider) => void;
}

const PartnerBaseCardToList = ({
  provider,
  handleOpenDialog,
}: ProviderCardProps) => {
  const user = useAppSelector((state) => state.user);
  const token = useAppSelector((store) => store.user.userAccount?.token!);
  const dispatch = useAppDispatch();
  const [showSaveMessage, setShowSaveMessage] = useState(false);
  const [textShowSaveMessage, setTextShowSaveMessage] = useState("");
  const favoriteProviders = useAppSelector(
    (state) => state.user.favoriteProviders?.content
  );
  const [savedStates, setSavedStates] = useState<{ [key: number]: boolean }>(
    {}
  );
  const roles: string[] = useAppSelector((store) =>
    store.user.userAccount!.roles.map((role: Roles) => role.name)
  );
  const id = useAppSelector((store) => store.user.userAccount?.id!);
  const handleSaveDeleteButtonClick = async (
    id: number,
    method: string,
    typeFavorite: string,
    token: string
  ) => {
    await dispatch(
      addDeleteFavoriteAsync({ entityId: id, method, typeFavorite })
    );
    method.toLowerCase() === "post"
      ? setTextShowSaveMessage("saved")
      : setTextShowSaveMessage("deleted");
    setShowSaveMessage(true);
    await dispatch(getFavoriteProvidersAsync());
    setTimeout(async () => {
      setShowSaveMessage(false);
    }, 3000);
    setSavedStates((prevState) => ({
      ...prevState,
      [id]: method.toLowerCase() === "post" ? true : false,
    }));
  };

  // Fill in savedStates based on favoriteProviders
  useEffect(() => {
    if (favoriteProviders) {
      const newSavedStates = { ...savedStates };
      favoriteProviders.forEach((favProvider: { id: string | number }) => {
        // @ts-ignore
        newSavedStates[favProvider.id] = true;
      });
      setSavedStates(newSavedStates);
    }
  }, [favoriteProviders]);

  // Retrieve the isSaved state for the current provider
  const isSaved = savedStates[provider.id] || false;

  const handleLinkClick = (providerId: number) => {
    const interestedCompany = {
      id: id, 
      providerId: providerId,
    };
  };


  return (
    <>
      <WrapperProvider>
        {user.userAccount!.companyType === "CUSTOMER" &&
          (isSaved ? (
            <DeleteWrapper
              onClick={() =>
                handleSaveDeleteButtonClick(
                  provider.id,
                  "delete",
                  "PROVIDER",
                  user.userAccount!.token
                )
              }
            >
              <Close />
            </DeleteWrapper>
          ) : (
            <ButtonSave
              onClick={() => {
                handleSaveDeleteButtonClick(
                  provider.id,
                  "post",
                  "PROVIDER",
                  user.userAccount!.token
                );
              }}
            >
              {"Save"}
            </ButtonSave>
          ))}
        <CompanyWrapperLink
          to={`/partner_base-provider/${provider.id}`}
          key={provider.id}
          onClick={() => handleLinkClick(provider.id)}
        >
          <LogoButtonWrapper>
            <ImageWrapper>
              <img
                src={provider.avatar || defaultAvatar}
                alt={provider.companyName}
              />
            </ImageWrapper>
          </LogoButtonWrapper>
          <NameWrapper>
            <Paragraph>{provider.companyName}</Paragraph>
            {provider.confirmed && (
              <>
                <UnionIcon />
                <VectorWrapper>
                  <VectorYellowIcon />
                </VectorWrapper>
              </>
            )}
          </NameWrapper>
          <RatingReviewsWrapper>
            {renderStars(Math.min(provider.rating, 5))}({provider.reviews})
          </RatingReviewsWrapper>
          {provider.successRate !== undefined && (
            <Wrapper>
              <Paragraph>Success rate:</Paragraph>
              {provider.successRate}
              {provider.successRate > 0 && <Up>& Up</Up>}
              {/*{provider.successRate.split("& Up").map((part, index, array) => (*/}
              {/*  <React.Fragment key={index}>*/}
              {/*    {part}*/}
              {/*    {index !== array.length - 1 && <Up>& Up</Up>}*/}
              {/*  </React.Fragment>*/}
              {/*))}*/}
            </Wrapper>
          )}
          {provider.availability && (
            <Wrapper>
              <Paragraph>Availability:</Paragraph>{" "}
              {typeof provider.availability === "string"
                ? (provider.availability as string).charAt(0).toUpperCase() +
                  (provider.availability as string)
                    .slice(1)
                    .toLowerCase()
                    .replace(/_/g, " ")
                : "Unknown Availability"}
            </Wrapper>
          )}
          <LocationWrapper>
            <Paragraph>Location:</Paragraph>{" "}
            <ParagraphLocation>
              {provider.registrationInfo &&
                `${provider.registrationInfo.country || ""}, ${
                  provider.registrationInfo.city || ""
                }`}
            </ParagraphLocation>
          </LocationWrapper>
          <IndustryWrapper>
            <Paragraph>Industry:</Paragraph>
            <IndustryParagraph>
              {provider.industry && provider.industry.join(", ")}
            </IndustryParagraph>
          </IndustryWrapper>

          <KeywordsBlock>
            <KeywordsParagraph>Keywords:</KeywordsParagraph>
            {provider.keywords && (
              <KeywordsContainer>
                {provider.keywords.slice(0, 5).map((keyword, index) => (
                  <KeywordsWrapper key={index}>{keyword}</KeywordsWrapper>
                ))}
              </KeywordsContainer>
            )}
          </KeywordsBlock>
        </CompanyWrapperLink>
        <ButtonWrapperLink>
          <Button
            text={"Connect"}
            disabled={roles.includes("ROLE_PROVIDER")}
            onClick={() => handleOpenDialog(provider)}
          />
        </ButtonWrapperLink>
      </WrapperProvider>

      {showSaveMessage && (
        <SaveWrapper>
          <SaveParagraph>
            {`The selected factory has been successfully ${textShowSaveMessage}.`}
          </SaveParagraph>
          <CrossWhiteIconWrapper onClick={() => setShowSaveMessage(false)}>
            <CrossWhiteIcon />
          </CrossWhiteIconWrapper>
        </SaveWrapper>
      )}
    </>
  );
};

export default PartnerBaseCardToList;
