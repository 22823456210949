import { useState } from "react";
import { StyledSwitch } from "./Styles";
import SunIcon from "../../assets/icons/SunIcon";
import MoonIcon from "../../assets/icons/MoonIcon";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { changeThemesStatus } from "../../store/slices/sliceData";

const Switch = () => {
  const isLight = useSelector((state: RootState) => state.themes.themesLight);
  const dispatch = useDispatch();
  const [isLightTheme, setIsLightTheme] = useState<boolean>(isLight);

  const handleToggle = () => {
    setIsLightTheme(!isLightTheme);
    dispatch(changeThemesStatus(!isLightTheme));

    const htmlElement = document.querySelector("html");
    if (htmlElement) {
      htmlElement.dataset.theme = isLightTheme ? "dark" : "light";
    }
  };

  return (
    <StyledSwitch>
      <SunIcon onClick={handleToggle} isLightTheme={isLightTheme} />
      <MoonIcon onClick={handleToggle} isLightTheme={isLightTheme} />
    </StyledSwitch>
  );
};

export default Switch;
