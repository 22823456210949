import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { routes } from '../../routes/routes';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { getFetchUrl } from '../../api/internetSearch';
import { AdressStyle, BlockDescriptionStyle, BlockWrapperStyle, CompanyNameStyle, ContactsWrapper, PhoneEmailLinkStyle, PhonesEmailsBlockWrapper, PhonesEmailsListStyle, PhonesEmailsTitle, PhonesEmailsWrapper, WebsiteLinkStyle, Wrapper } from './styles';

const phones = [
    '+972111111111',
    '+972222222222',
    '+972333333333',
    '+972444444444',
    '+972555555555',
];

const emails = [
    '111@gmail.com',
    '222@gmail.com',
    '333@gmail.com',
    // '444@gmail.com',
    // '555@gmail.com',
];

const InternetSearchFactoryInfo = () => {

    const location = useLocation();
    const dispatch = useAppDispatch();
    const token = useAppSelector((state) => state.user.userAccount?.token);
    const queryParams = new URLSearchParams(location.search);
    const companyName = queryParams.get('companyName') || '';
    const website = queryParams.get('website') || '';
    const [isLoading, setIsLoading] = useState(false);
    const [showAll, setShowAll] = useState(false);

    const handleNavigateClick = async (
        companyName: string,
        link: string | null,
    ) => {
        if (!link) {
            //   setIsModalOpen(true);
            //   setTimeout(() => {
            // setIsModalOpen(false);
            //   }, 5000);

            return;
        }

        // setIframeUrl(link);
        if (companyName) {
            const url = routes.COMPANYNETWORK.replace(
                ":name",
                companyName.toString()
            );
            const searchParams = new URLSearchParams();
            searchParams.append("url", link);
            searchParams.append("url", link);

            try {
                setIsLoading(true);
                await dispatch(getFetchUrl(link, token as string));
                window.open(`${url}?${searchParams.toString()}`, "_blank");
            } finally {
                setIsLoading(false);
            }
        }
    };

    useEffect(() => {
        if (isLoading) {
            document.body.style.cursor = "wait";
        } else {
            document.body.style.cursor = "default";
        }

        return () => {
            document.body.style.cursor = "default";
        };
    }, [isLoading]);


    // const formatUrl = (url: string): string => {
    //     const parsedUrl = new URL(url);
    //     let hostname = parsedUrl.hostname;

    //     if (!hostname.startsWith('www.')) {
    //         hostname = `www.${hostname}`;
    //     }
    //     return hostname;
    // };

    const toggleShowAll = () => {
        setShowAll((prevShowAll) => !prevShowAll);
    };


    return (
        <Wrapper>
            <CompanyNameStyle
                onClick={() => handleNavigateClick(companyName, website)}
            >
                {companyName}
            </CompanyNameStyle>

            <BlockWrapperStyle>
                <BlockDescriptionStyle>Address:</BlockDescriptionStyle>
                <AdressStyle>Herzl 229, Rehovot, 7610001</AdressStyle>
            </BlockWrapperStyle>


            {/* <BlockWrapperStyle>
                <BlockDescriptionStyle>Company's website:</BlockDescriptionStyle>
                <WebsiteLinkStyle
                    onClick={() => handleNavigateClick(companyName, website)}
                >
                    {formatUrl(website)}
                </WebsiteLinkStyle>
            </BlockWrapperStyle> */}

            <ContactsWrapper>
                <BlockDescriptionStyle>Contacts</BlockDescriptionStyle>

                <PhonesEmailsWrapper>

                    <PhonesEmailsBlockWrapper>
                        <PhonesEmailsTitle>Phones:</PhonesEmailsTitle>
                        <PhonesEmailsListStyle>
                            {phones.slice(0, showAll ? phones.length : 1).map((number) => (
                                <PhoneEmailLinkStyle href={`tel:${number}`} key={number}>{number}</PhoneEmailLinkStyle>
                            ))}
                        </PhonesEmailsListStyle>
                    </PhonesEmailsBlockWrapper>

                    <PhonesEmailsBlockWrapper>
                        <PhonesEmailsTitle>E-mails:</PhonesEmailsTitle>
                        <PhonesEmailsListStyle>
                            {emails.slice(0, showAll ? emails.length : 1).map((mail) => (
                                <PhoneEmailLinkStyle href={`mailto:${mail}`} key={mail} >{mail}</PhoneEmailLinkStyle>
                            ))}
                        </PhonesEmailsListStyle>
                    </PhonesEmailsBlockWrapper>


                </PhonesEmailsWrapper>

                <button style={{ color: 'blue' }} onClick={toggleShowAll}>
                    {showAll ? 'Hide' : 'Show all contacts...'}
                </button>

            </ContactsWrapper>

        </Wrapper>
    )
}

export default InternetSearchFactoryInfo