import styled from "styled-components";

export const LabelStyle = styled.p`
  margin: 20px 0 10px;
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
`;

export const DialogContent = styled.div<{ modal?: boolean }>`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  border-radius: 16px;
  background: var(--wiht, #fff);
  padding: 30px 30px 40px;
  z-index: 2;

  max-width: ${({ modal }) => (modal ? "637px" : "510px")};
  width: 100%;
`;

export const Title = styled.h3`
  text-align: center;

  font-size: 24px;
  font-weight: 500;
  line-height: 140%;

  color: var(--txt-dark, #001731);
`;

export const CloseWrapper = styled.div`
  position: absolute;
  right: 20.5px;

  cursor: pointer;
`;

export const ErrorWrapper = styled.div`
  /* height: 17px; */
  margin-top: 10px;
  text-align: center;
`;

export const TextError = styled.h6`
  font-size: 14px;
  line-height: 140%;

  color: var(--txt-red, #f00);
`;

export const ContentWrapper = styled.div`
  display: flex;
  padding: 0 20px 20px 20px;
  flex-direction: column;
  gap: 20px;

  border-radius: 16px;
  background: var(--wiht, #fff);
`;

export const InputContainer = styled.div`
  font-size: 17px;
  font-weight: 400;
  color: rgb(160, 183, 209);
`;

export const InputWrapper = styled.div`
  position: relative;
`;

export const Input = styled.input`
  max-width: 390px;
  width: 100%;
  padding: 8px 10px;
  font-size: 17px;
  line-height: 140%;
  border: 1px solid var(--border, #bdbdbd);
  border-radius: 12px;

  &::placeholder {
    color: var(--txt-grey-light, #717880);
  }
  &:focus {
    border-bottom: 1px solid var(--batton, #0095c0);
  }
`;

export const TogglePasswordButton = styled.button`
  position: absolute;
  top: 6px;
  right: 0;
`;

export const Text = styled.h3`
  text-align: center;
  margin-top: 30px;

  font-size: 24px;
  font-weight: 500;
  line-height: 140%;

  color: var(--txt-dark, #001731);
`;

export const ButtonsBlockStyle = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
`;

export const BriefContent = styled.div`
  max-width: 800px;
  max-height: 1000px;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: auto;

  border-radius: 16px;
  background: var(--wiht, #fff);
  padding: 30px 30px 40px;
  z-index: 2;
  width: 100%;
`;

export const WrapperField = styled.div`
  display: flex;
  margin-bottom: 5px;
  border-bottom: 1px solid var(--border, #bdbdbd);
`;

export const LabelField = styled.h5`
  max-width: 200x;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  line-height: 140%;

  color: var(--txt-dark, #001731);
`;

export const ValueField = styled.h5`
  max-width: 600px;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  line-height: 140%;

  color: var(--txt-dark, #001731);
`;

export const ButtonWrapper = styled.div`
  max-width: 390px;
  width: 100%;

  margin: 0 auto;
`;
