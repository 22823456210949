import React from "react";
import "react-slideshow-image/dist/styles.css";
import { Slide } from "react-slideshow-image";
import { SlideContainer, SlideWrapper, Image } from "./styles";
import "./slideshow.css";

import { Photo } from "../../utils/types";
import { defaultImg } from "../../assets/img";

interface SlidePhotoProps {
  photos: Photo[];
}

const SlidePhoto: React.FC<SlidePhotoProps> = ({ photos }) => {
  if (photos?.length === 0) {
    return null;
  }
  const properties = {
    duration: 5000,
    transitionDuration: 500,
    infinite: true,
    indicators: true,
    arrows: true,
  };
  
  return (
    <SlideContainer>
      <Slide {...properties}>
        {photos?.map(({ photo_url, id }) => (
          <SlideWrapper key={id} className="each-slide">
            <Image
              src={photo_url.length > 0 ? photo_url[0] : defaultImg}
              alt={`Slide ${id + 1}`}
            />
          </SlideWrapper>
        ))}
      </Slide>
    </SlideContainer>
  );
};

export default SlidePhoto;
