import styled from "styled-components";

interface InputProps {
  color?: string;
}

export const Container = styled.div`
  position: relative;

  max-width: 533px;
  padding: 36px 30px 30px;
  margin: 0 auto 30px;

  border-radius: 16px;
  border: 1px solid var(--dark, #001731);
  background: var(--wiht, #fff);
`;

export const CloseWrapper = styled.div`
  position: absolute;
  right: 19.5px;

  cursor: pointer;
`;

export const TitleWrapper = styled.div`
  margin-top: 30px;
  margin-bottom: 20px;
`;

export const Subtitle = styled.h4`
  margin-bottom: 20px;
  padding: 0 20px;

  font-size: 17px;
  font-weight: 500;
  line-height: 140%;

  color: var(--txt-dark, #001731);
`;

export const SubtitleErrorWrapper = styled.div`
  height: 20px;
  margin-bottom: 10px;
`;

export const SubtitleError = styled.h6`
  padding: 0 20px;

  font-size: 14px;
  line-height: 140%;

  color: var(--txt-red, #f00);
`;

export const CodeWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  padding: 0 20px;
  margin-bottom: 20px;
`;

export const CodeTitle = styled.h6`
  margin-bottom: 10px;
  text-align: center;

  font-size: 14px;
  line-height: 140%;

  color: var(--txt-light-blue-dont-active, #a0b7d1);
`;

export const InputWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-bottom: 20px;
`;

export const CodeInput = styled.input<InputProps>`
  width: 12px;
  height: 24px;

  padding: 4px 10px;

  font-size: 17px;
  line-height: 140%;

  border-radius: 4px;
  border: 1px solid
    ${(props) =>
      props.color ? "var(--txt-red, #f00)" : "var(--border, #bdbdbd)"};
  color: ${(props) =>
    props.color ? "var(--txt-red, #f00)" : "var(--txt-dark, #001731)"};
  background: var(--white, #fff);

  &[type="number"] {
    -moz-appearance: textfield;
  };
`;

export const Text = styled.h5`
  margin-bottom: 20px;
  font-size: 17px;
  line-height: 140%;

  color: var(--txt-dak-light, #143255);
`;

export const ButtonWrapper = styled.div`
  margin-bottom: 20px;
`;

export const TextRepeatCode = styled.h6`
  font-size: 14px;
  line-height: 140%;

  color: var(--txt-dak-light, #143255);
`;

export const StyledLinkText = styled.span`
  color: var(--txt-blue, #0095c0);

  font-size: 14px;
  line-height: 140%;
  text-decoration-line: underline;
  cursor: pointer;
`;
