import PersonalInformation from "../components/PersonalInformation/PersonalInformation";


const Settings: React.FC = () => {
  return (
    <>
      <PersonalInformation />
    </>
  );
};

export default Settings;
