import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface SideBarState {
  themesLight: boolean;
  isOpen: boolean;
  partnerBaseFavorites: string;
  agentFavorites: string;
}

const initialState: SideBarState = {
  isOpen: true,
  themesLight: true,
  partnerBaseFavorites: 'All',
  agentFavorites: 'All',
};

export const SideBarSlice = createSlice({
  name: "sideBar",
  initialState,
  reducers: {
    changeThemesStatus: (state, action: PayloadAction<boolean>) => {
      state.themesLight = action.payload;
    },
    changeOpenMenu: (state, action: PayloadAction<boolean>) => {
      state.isOpen = action.payload;
    },
    changePartnerBase: (state, action: PayloadAction<string>) => {
      state.partnerBaseFavorites = action.payload;
    },
    changeAgent: (state, action: PayloadAction<string>) => {
      state.agentFavorites = action.payload;
    },
  },
});

export const { changeThemesStatus, changeOpenMenu, changePartnerBase, changeAgent } = SideBarSlice.actions;
export default SideBarSlice.reducer;
