import styled from "styled-components";

export const UploadFile = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 18px 30px 20px 40px;
  margin-top: 10px;
  font-size: 17px;
  font-weight: 400;
  line-height: 140%;

  border-radius: 16px;
  border: 1px dashed var(--green, #03b62a);
  background: var(--wiht, #fff);
`;

export const UploadButton = styled.button`
  padding: 0 3px 0 0;
  text-align: center;

  font-size: 17px;
  font-weight: 400;
  line-height: 140%;

  color: var(--green, #03b62a);
  text-decoration-line: underline;
`;

export const FileTitle = styled.div`
  display: flex;
`;

export const VectorWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 18px;
  padding: 5px 4px;
  margin-right: 8px;

  border-radius: 16px;
  background-color: var(--green, #03b62a);
`;

export const PhotoWrapper = styled.ul`
  display: flex;
  flex-direction: column;
`;

export const PhotoId = styled.li`
  display: flex;
  align-items: center;

  margin-bottom: 20px;
`;

export const CloseWrapper = styled.div`
  display: flex;
  margin-left: 8px;
  align-items: center;
`;

export const Span = styled.span`
  font-size: 17px;
  line-height: 140%;

  color: var(--txt-dak-light, #143255);
`;

export const Input = styled.input`
  display: none;
`;
