import React, { useEffect } from "react";
import styled from "styled-components";
import { RootState } from "../store/store";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../store/hooks";
import { routes } from "../routes/routes";
import ProviderToConnect from "../components/PartnerBasePage/ProviderToConnect/ProviderToConnect";
import ReturnButton from "../components/ReturnButton/ReturnButton";

const ButtonWrapper = styled.div`
  margin-bottom: 30px;
`;

const PatnerBaseProvider: React.FC = () => {
  const user = useAppSelector((state: RootState) => state.user);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user.userAccount) {
      navigate(routes.HOME);
    }
  }, [user, navigate]);
  return (
    <>
      <ButtonWrapper>
        <ReturnButton />
      </ButtonWrapper>
      <ProviderToConnect />
    </>
  );
};

export default PatnerBaseProvider;
