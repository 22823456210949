import React, { useState } from 'react';
import styled from 'styled-components';
import SearchIconBlack from '../../../assets/icons/SearchIconBlack';
import ChevronDownIcon from '../../../assets/icons/ChevronDownIcon';
import VectorIcon from '../../../assets/icons/VectorIcon';

interface DropdownProps {
    title: string,
    initialValue: string[]
    itemList: string[],
    setItemList: (items: string[]) => void,
    setCompanyStateValue: (key: string, value: any) => void;
    setShowDropdown: (flag: boolean) => void
}

const DropdownContainer = styled.div`
    position: relative;
    width: 838px;
    max-height: 265px;
    border: 1px solid var(--batton, #0095c0);
    background: var(--wiht, #fff);
    transition: all 0.3s ease;
    border-radius: 12px;
    padding: 0 20px;
    background-color: #fff;
    
&::-webkit-scrollbar {
     width: 10px; 
  }
`;

const DropdownOption = styled.li`
    cursor: pointer;
    padding: 8px 0 9px 6px;
    font-size: 17px;
    line-height: 140%;
    color: var(--txt-dak-light, #143255);

&:hover {
    color: var(--txt-blue, #0095c0);
    }
`;

const SearchSection = styled.div`
    display: flex;
    align-items: center;
    padding-top: 12px;
`;

const SearchInput = styled.input`
    width: 95%;
    margin: 5px 10px 10px 40px;
    font-size: 17px;
  
&::placeholder {
    color: #DADADA;
    }
`;

const SearchIcon = styled.div`
    position: absolute;
    padding-left: 5px;
`;

const ListItems = styled.ul`
    max-height: 215px;
    overflow-y: scroll;
    margin-right: 15px;
`;

const FilterSwitchBlock = styled.div<{ $isActive: boolean }>`
  border-radius: 4px;
  border: 1px solid var(--battn-border, #004c62);
  height: 16px;
  cursor: pointer;
  background-color: ${({ $isActive }) =>
        $isActive ? "var(--batton, #0095C0)" : "transparent"};
  display: inline-flex;
  align-items: center;
  margin: 0 8px 0 8px;
`;

const FilterWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const IconWrapper = styled.div<{fill: string}>`
  svg {
    fill: ${props=>props.fill};
    padding: 1px;
  }
`;

const DropdownWithCheckbox: React.FC<DropdownProps> = ({
    title,
    initialValue,
    itemList,
    setItemList,
    setCompanyStateValue,
    setShowDropdown
}) => {
    const [searchTerm, setSearchTerm] = useState('');

    const handleItemSelect = (item: string) => {
        const newSelectedItems = [...itemList];
        const index = newSelectedItems.indexOf(item);
        (index === -1) ? newSelectedItems.push(item) : newSelectedItems.splice(index, 1);
        setItemList(newSelectedItems);
        setCompanyStateValue("paymentTerms", [...newSelectedItems]);
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    const filteredItems = initialValue.filter((item) =>
        item.toLowerCase().includes(searchTerm.toLowerCase().trim())
    );

    return (
        <DropdownContainer>
            <SearchSection>
                <SearchIcon>
                    <SearchIconBlack />
                </SearchIcon>
                <SearchInput
                    type="text"
                    placeholder={`Enter ${title}`}
                    value={searchTerm}
                    onChange={handleSearchChange}
                />
                <ChevronDownIcon onClick={() => setShowDropdown(false)} />
            </SearchSection>
            <ListItems>
                {filteredItems.map((item, index) => {
                    return (
                        <FilterWrapper key={index + "pt"} onClick={() => handleItemSelect(item)}>
                            <FilterSwitchBlock $isActive={itemList.includes(item) || false}>
                                <IconWrapper fill={itemList.includes(item) ? "var(--batton, #0095C0)" : "transparent"}>
                                    <VectorIcon />
                                </IconWrapper>
                            </FilterSwitchBlock>
                            <DropdownOption >{item}</DropdownOption>
                        </FilterWrapper>
                    )
                })}
            </ListItems>
        </DropdownContainer>
    );
};

export default DropdownWithCheckbox