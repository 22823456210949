import React, { useState, ChangeEvent, KeyboardEvent } from "react";
import {
  ButtonContainer,
  ButtonWrapper,
  Chips,
  ChipsList,
  CloseUploadWrapper,
  Container,
  CrossWrapper,
  Description,
  ImageHover,
  ImageHoverWrapper,
  ImageTooltip,
  Input,
  List,
  MediaUploadWrapper,
  ProductItem,
  SaveWrapper,
  Subtitle,
  TextareaDescription,
  TittleHeaderWrapper,
  TooltipKeyword,
  TooltipPhoto,
  TooltipWrapper,
  TopWrapper,
  UploadDialogWrapper,
  UploadWrapper,
  VectorWrapper,
  Verify,
  VerifyContainer,
  VerifyInput,
  Wrapper,
} from "./style";
import ReturnButton from "../../ReturnButton/ReturnButton";
import TitleHeader from "../../TitleHeader/TitleHeader";
import ShieldQuestionIcon from "../../../assets/icons/ShieldQuestionIcon";
import CrossIcon from "../../../assets/icons/CrossIcon";
import MediaUpload from "../../MediaUpload/MediaUpload";
import VectorIcon from "../../../assets/icons/VectorIcon";
import { useDispatch } from "react-redux";
import Button from "../../Button/Button";
import ButtonWhite from "../../ButtonWhite/ButtonWhite";
import { useBlocker, useNavigate } from "react-router-dom";
import { AddProduct, UserAccount } from "../../../utils/types";
import {
  createProductAsync,
  getAllProduct,
  uploadFilesToServer,
} from "../../../store/slices/userSlice";
import { useAppSelector } from "../../../store/hooks";
import ProductPreview from "../ProductPreview/ProductPreview";
import ModalLostChanges from "../../ModalWindows/ModalLostChanges/ModalLostChanges";

const EditProduct: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const status = useAppSelector((state) => state.user.status);
  const userAccount: UserAccount = useAppSelector(
    (store) => store.user.userAccount!
  );
  const [showKeywordTooltip, setShowKeywordTooltip] = useState(false);
  const [showPhotoTooltip, setShowPhotoTooltip] = useState(false);
  const [keywordInput, setKeywordInput] = useState<string>("");
  const [filesToUpload, setFilesToUpload] = useState<File[]>([]);
  const [previewMode, setPreviewMode] = useState<boolean>(false);
  const [isSaveClicked, setIsSaveClicked] = useState(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [nextLocation, setNextLocation] = useState<string>("");
  const [productInfo, setProductInfo] = useState<AddProduct>({});
  const [showUploadTooltip, setShowUploadTooltip] = useState<boolean>(false);
  const [tooltipLink, setTooltipLink] = useState<string>("");

  const handlePreviewProductClick = () => {
    setPreviewMode(true);
  };

  const handleKeywordInput = (e: ChangeEvent<HTMLInputElement>) => {
    setKeywordInput(e.target.value);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && keywordInput.trim() !== "") {
      setProductInfo({
        ...productInfo,
        keywords: [...(productInfo?.keywords ?? []), keywordInput.trim()],
      });
      setKeywordInput("");
    }
  };

  const handleDeleteKeyword = (index: number) => {
    const updatedKeywords = productInfo?.keywords
      ?.filter((_, i) => i !== index)
      .filter(Boolean) as string[];
    setProductInfo((prevState) => ({
      ...prevState,
      keywords: updatedKeywords,
    }));
  };

  const handleAttachmentFilesChange = async (files: File[]) => {
    const saveFiles = await dispatch(
      uploadFilesToServer({ files: files }) as any
    );
    const updatedImages = [...(productInfo.images ?? []), ...saveFiles.payload];
    setProductInfo((prevState) => ({
      ...prevState,
      images: updatedImages,
    }));
  };

  const handleRemoveClick = (index: number) => {
    const updatedFiles = productInfo?.images?.filter((_, i) => i !== index);
    setProductInfo((prevState) => ({
      ...prevState,
      images: updatedFiles,
    }));
  };

  const handleSaveButtonClick = async () => {
    setIsSaveClicked(true);
    if (!productInfo.name) {
      return;
    }
    try {
      await dispatch(
        createProductAsync({
          ...productInfo,
        } as AddProduct) as any
      );
      setProductInfo({});
      setIsSaveClicked(false);
      await dispatch(getAllProduct() as any);
      navigate(-1);
    } catch (error) {
      console.error(error);
    }
  };

  useBlocker(({ nextLocation }) => {
    if (
      productInfo &&
      Object.keys(productInfo).length > 0 &&
      Object.values(productInfo).some((value) => {
        return (
          (typeof value === "string" && value.trim().length > 0) ||
          (Array.isArray(value) && value.length > 0) ||
          (typeof value === "number" && value !== 0) ||
          typeof value === "boolean"
        );
      })
    ) {
      setShowModal(true);
      setNextLocation(nextLocation.pathname);
      return true;
    }
    return false;
  });

  const handleDiscardChanges = async () => {
    setShowModal(!showModal);
    await setProductInfo({});
    navigate(nextLocation);
  };

  const handlEditing = () => {
    setShowModal(!showModal);
  };

  const handleShowUploadTooltip = (link: string) => {
    setShowUploadTooltip(true);
    setTooltipLink(link);
  };

  return (
    <Container>
      {previewMode ? (
        <ProductPreview
          productInfo={productInfo}
          setPreviewMode={setPreviewMode}
          saveClick={handleSaveButtonClick}
          status={status}
        />
      ) : (
        <>
          <ButtonWrapper>
            <ReturnButton />
            <TittleHeaderWrapper>
              <TitleHeader text={"Add product"} />
            </TittleHeaderWrapper>
          </ButtonWrapper>
          <Wrapper>
            <TopWrapper>
              <VerifyContainer>
                <Subtitle>Product name</Subtitle>
                <Verify>*</Verify>
              </VerifyContainer>
              <VerifyInput
                placeholder="Enter product name"
                type="text"
                value={productInfo?.name || ""}
                onChange={(e) =>
                  setProductInfo({
                    ...productInfo,
                    name: e.target.value,
                  })
                }
                $isEmpty={!productInfo?.name}
                $isSaveClicked={isSaveClicked}
              />
              <VerifyContainer>
                <Subtitle>Keywords:</Subtitle>
                <TooltipWrapper
                  onMouseEnter={() => setShowKeywordTooltip(true)}
                  onMouseLeave={() => setShowKeywordTooltip(false)}
                >
                  <ShieldQuestionIcon />
                </TooltipWrapper>
                {showKeywordTooltip && (
                  <TooltipKeyword>
                    Product photos, discounts, or hot offers
                  </TooltipKeyword>
                )}
              </VerifyContainer>
              <Input
                placeholder="Enter keywords"
                type="text"
                value={keywordInput}
                onChange={handleKeywordInput}
                onKeyDown={handleKeyDown}
              />
              <ChipsList>
                {productInfo?.keywords?.map((keyword, index) => (
                  <Chips key={index}>
                    {keyword}
                    <CrossWrapper onClick={() => handleDeleteKeyword(index)}>
                      <CrossIcon />
                    </CrossWrapper>
                  </Chips>
                ))}
              </ChipsList>
              <VerifyContainer>
                <Description>Description</Description>
              </VerifyContainer>
              <TextareaDescription
                placeholder="Enter description product"
                value={productInfo?.description || ""}
                cols={30}
                rows={10}
                onChange={(e) =>
                  setProductInfo({
                    ...productInfo,
                    description: e.target.value,
                  })
                }
              />
              <VerifyContainer>
                <Subtitle>Minimum order quantity</Subtitle>
              </VerifyContainer>
              <Input
                placeholder="Enter order quantity"
                type="number"
                value={productInfo?.minimumOrderQuantity || ""}
                onChange={(e) =>
                  setProductInfo({
                    ...productInfo,
                    minimumOrderQuantity: parseInt(e.target.value),
                  })
                }
              />
              <VerifyContainer>
                <Subtitle>Unity price</Subtitle>
              </VerifyContainer>
              <Input
                placeholder="Enter unite price"
                type="number"
                value={productInfo?.price || ""}
                onChange={(e) =>
                  setProductInfo({
                    ...productInfo,
                    price: +parseFloat(e.target.value).toFixed(2),
                  })
                }
              />

              <UploadWrapper>
                <VerifyContainer>
                  <Subtitle>Product photo:</Subtitle>
                  <TooltipWrapper
                    onMouseEnter={() => setShowPhotoTooltip(true)}
                    onMouseLeave={() => setShowPhotoTooltip(false)}
                  >
                    <ShieldQuestionIcon />
                  </TooltipWrapper>
                  {showPhotoTooltip && (
                    <TooltipPhoto>
                      Product photos, discounts, or hot offers
                    </TooltipPhoto>
                  )}
                </VerifyContainer>

                <MediaUploadWrapper>
                  <UploadDialogWrapper>
                    <MediaUpload
                      selectedFiles={filesToUpload}
                      onFilesChange={(files) =>
                        handleAttachmentFilesChange(files)
                      }
                    />
                  </UploadDialogWrapper>
                </MediaUploadWrapper>
              </UploadWrapper>
              
              <List>
                {productInfo?.images?.map((file, index) => {
                  return (
                    <ProductItem key={index}>
                      <VectorWrapper>
                        <VectorIcon />
                      </VectorWrapper>
                      <ImageTooltip
                        src={file.link as unknown as string}
                        alt={file.name}
                        onMouseEnter={() =>
                          handleShowUploadTooltip(
                            file.link as unknown as string
                          )
                        }
                        onMouseLeave={() => setShowUploadTooltip(false)}
                      />
                      {file.name}
                      <CloseUploadWrapper
                        onClick={() => handleRemoveClick(index)}
                      >
                        <CrossIcon />
                      </CloseUploadWrapper>
                    </ProductItem>
                  );
                })}
                {showUploadTooltip && (
                  <ImageHoverWrapper>
                    <ImageHover src={tooltipLink} alt={tooltipLink} />
                  </ImageHoverWrapper>
                )}
              </List>
            </TopWrapper>
            <ButtonContainer>
              <SaveWrapper>
                <Button text={"Preview"} onClick={handlePreviewProductClick} />
                <ButtonWhite
                  disabled={status === "loading"}
                  text={status === "loading" ? "loading..." : "Save"}
                  onClick={handleSaveButtonClick}
                />
              </SaveWrapper>
            </ButtonContainer>
          </Wrapper>
        </>
      )}
      {showModal && (
        <ModalLostChanges
          text="The changes you made to  this page will be lost"
          buttonText="Keep editing"
          buttonWhitetext="Discard"
          handleWhiteButtonClick={handleDiscardChanges}
          handleButtonClick={handlEditing}
        />
      )}
    </Container>
  );
};

export default EditProduct;
