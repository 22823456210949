import React, { useState } from "react";
import {
  AllImg,
  AllProductImgWrapper,
  Container,
  Text,
  NameOfProduct,
  SelectedImg,
  Wrapper,
  InfoWrapper,
  TextValue,
  TextWrapper,
  EllipseWrapper,
  AllImgWrapper,
} from "./styles";
import { Item } from "../../utils/types";
import { defaultImg } from "../../assets/img";
import ChevronLeftIcon from "../../assets/icons/ChevronLeftIcon";
import ChevronRightIcon from "../../assets/icons/ChevronRightIcon";

interface ProductInfoProps {
  products: Item;
}



const ProductInfo: React.FC<ProductInfoProps> = ({ products }) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [visibleStartIndex, setVisibleStartIndex] = useState<number>(0);
  const visibleCount = 3;
  if (products?.images.length === 0) {
    return null;
  }

  const handleImageClick = (index: number) => {
    setCurrentIndex(index);
  };

  const handleLeftArrowClick = () => {
    const newIndex =
      currentIndex === 0 ? products.images.length - 1 : currentIndex - 1;
    const newVisibleStartIndex =
      newIndex < visibleStartIndex ? newIndex : visibleStartIndex;
    setCurrentIndex(newIndex);
    setVisibleStartIndex(newVisibleStartIndex);
    if (newIndex === products.images.length - 1) {
      setVisibleStartIndex(products.images.length - visibleCount);
    }
  };

  const handleRightArrowClick = () => {
    setCurrentIndex((prevIndex) => {
      const newIndex =
        prevIndex === products.images.length - 1 ? 0 : prevIndex + 1;
      if (newIndex >= visibleStartIndex + visibleCount) {
        setVisibleStartIndex(newIndex - visibleCount + 1);
      }
      if (newIndex === 0) {
        setVisibleStartIndex(0);
      }
      return newIndex;
    });
  };

  return (
    <Container>
      <Wrapper>
        <SelectedImg
          src={products.images[currentIndex].link || defaultImg}
          alt={products.images[currentIndex].name}
        />
        <AllProductImgWrapper $isCenter={products.images.length > visibleCount}>
        {products.images.length > visibleCount && (
          <EllipseWrapper onClick={handleLeftArrowClick}>
            <ChevronLeftIcon />
          </EllipseWrapper>)}
          <AllImgWrapper>
            {products?.images
              .slice(visibleStartIndex, visibleStartIndex + visibleCount)
              .map((image, index) => (
                <AllImg
                  key={index}
                  src={image.link || defaultImg}
                  alt={image.name || ""}
                  onClick={() => handleImageClick(visibleStartIndex + index)}
                  $isOpen={currentIndex === visibleStartIndex + index}
                />
              ))}
          </AllImgWrapper>
          {products.images.length > visibleCount && (
          <EllipseWrapper onClick={handleRightArrowClick}>
            <ChevronRightIcon />
          </EllipseWrapper>)}
        </AllProductImgWrapper>
        <InfoWrapper>
          <NameOfProduct>{products?.name}</NameOfProduct>
          <TextWrapper>
            <Text>Minimum Order Quantity:</Text>
            <TextValue>{products?.minimumOrderQuantity}</TextValue>
          </TextWrapper>
          <TextWrapper>
            <Text>Unit Price:</Text>
            <TextValue>{products?.price} $</TextValue>
          </TextWrapper>
        </InfoWrapper>
      </Wrapper>
    </Container>
  );
};

export default ProductInfo;
