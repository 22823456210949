import { Link } from "react-router-dom";
import styled from "styled-components";

export const CompanyWrapper = styled.div`
  border-radius: 16px;
  border: 1px solid var(--border, #bdbdbd);
  background: var(--wiht, #fff);
  display: flex;
  width: 367px;
  padding: 30px;
  flex-direction: column;
  align-items: flex-start;
`;

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  margin-left: 20px;
`;

export const Paragraph = styled.p`
  color: var(--txt-dark, #001731);
  font-size: 17px;
  font-weight: 500;
  line-height: 140%;
`;

export const KeywordsParagraph = styled.p`
  color: var(--txt-dark, #001731);
  font-size: 17px;
  font-weight: 500;
  line-height: 140%;
  margin-bottom: 10px;
`;

export const RatingReviewsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 20px;
`;

export const LocationWrapper = styled.div`
  display: flex;
  gap: 5px;
  margin-bottom: 20px;
  align-items: baseline;
`;

export const ParagraphLocation = styled.p`
  color: var(--txt-dak-light, #143255);
  font-size: 17px;
  font-weight: 400;
  line-height: 140%;
`;

export const NameWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 20px;
  position: relative;
`;

export const IndustryWrapper = styled.div`
  display: flex;
  gap: 5px;
  margin-bottom: 10px;
`;

export const ImageWrapper = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const VectorWrapper = styled.div`
  position: absolute;
  right: 6px;
  top: 3px;
`;

export const KeywordsBlock = styled.div`
  margin-bottom: 20px;
`;

export const KeywordsWrapper = styled.div`
  display: flex;
  padding: 8px 16px 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  border: 1px solid var(--border, #bdbdbd);
  background: var(--gray-light, #fafafa);
`;

export const KeywordsContainer = styled.div`
  max-width: 367px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
`;

export const ButtonWrapper = styled.div`
  max-width: 307px;
  width: 100%;
  margin-top: auto;
`;

export const ButtonSendWrapper = styled.div`
  max-width: 104px;
  width: 100%;
`;

// export const ButtonSave = styled.button`
//   border-radius: 16px;
//   border: 1px solid var(--battn-border, #004c62);
//   background: var(--wiht, #fff);
//   padding: 14px 30px 16px 30px;
//   justify-content: center;
//   align-items: center;
//   gap: 10px;
//   color: var(--txt-dark, #001731);
//   font-size: 17px;
//   font-weight: 400;
//   line-height: 140%;
//   &:active {
//     background: var(--green, #03b62a);
//     color: var(--wiht, #fff);
//   }
// `;

export const ButtonSave = styled.button`
  position: absolute;
  top: 53px;
  right: 30px;

  padding: 14px 30px 16px 30px;

  font-size: 17px;
  font-weight: 400;
  line-height: 140%;

  border-radius: 16px;
  background: var(--wiht, #fff);
  border: 1px solid var(--battn-border, #004c62);
  color: var(--txt-dark, #001731);
  white-space: nowrap;
  transition: background 1.5s ease, color 0.3s ease;
  &:hover {
    background: linear-gradient(
      225deg,
      rgb(0, 149, 192) 17.746%,
      rgba(3, 182, 42, 0.6) 90.792%
    );
    border: 1px solid transparent;
    color: var(--txt-light-blue, #f4ffff);
    transition: background 1.5s ease, color 0.3s ease;
  }
  &:active {
    background: var(--button, #03b62a);
    color: var(--txt-light-blue, #f4ffff);
    border: 1px solid transparent;
  }
`;

export const LogoButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const PaginationBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 458px;
  position: absolute;
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;

export const PageButton = styled.button`
  border-radius: 16px;
  border: 1px solid var(--border, #bdbdbd);
  background: var(--wiht, #fff);
  display: flex;
  padding: 14px 30px 16px 10px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 101px;
`;

export const PageWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  max-width: 264px;
  width: 100%;
  gap: 10px;
  position: absolute;
  right: 0;
`;

export const ChevronRightWrapper = styled.div`
  position: absolute;
  right: 20px;
  top: 12px;
`;

export const PageActiveButton = styled.div`
  border-radius: 16px;
  border: 1px solid var(--batton, #0095c0);
  background: var(--wiht, #fff);
  display: inline-flex;
  padding: 14px 20px 16px 15px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 101px;
  cursor: pointer;
  position: absolute;
  bottom: -20px;
  right: 0;
`;

export const PageBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const PageActiveItem = styled.div`
  text-align: center;
`;

export const ChevronUpWrapper = styled.div`
  position: absolute;
  top: 110px;
  right: 5px;
`;

export const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 30px;
  position: relative;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const SortButton = styled.button`
  border-radius: 16px;
  border: 1px solid var(--border, #bdbdbd);
  background: var(--wiht, #fff);
  display: flex;
  padding: 14px 40px 16px 30px;
  gap: 10px;
  color: var(--txt-dark, #001731);
  font-size: 17px;
  font-weight: 500;
  line-height: 140%;
`;

export const SortActiveButton = styled.button`
  background: var(--wiht, #fff);
  color: var(--txt-dark, #001731);
  font-size: 17px;
  font-weight: 500;
  line-height: 140%;
  position: relative;
`;

export const SortWrapper = styled.div`
  /* display: flex; */
  /* justify-content: flex-end; */
  position: absolute;
  right: 0;
  bottom: 0;
  
`;

export const ChevronRightSortWrapper = styled.div`
  position: relative;
  left: 90px;
  bottom: 43px;
`;

export const ChevronDownSortWrapper = styled.div`
  position: absolute;
  left: 70px;
  top: 0px;
`;

export const SortActiveWrapper = styled.div`
  border-radius: 16px;
  border: 1px solid var(--batton, #0095c0);
  background: var(--wiht, #fff);
  display: flex;
  justify-content: center;
  align-items:center;
  padding: 14px 20px 16px 30px;
  flex-direction: column;
`;

export const IndustryLocationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
`;

export const LocationIndustryButton = styled.button`
  color: var(--txt-dak-light, #143255);
  font-size: 17px;
  font-weight: 400;
  line-height: 140%;
  &:hover {
    color: var(--txt-blue, #0095c0);
  }
`;

export const Up = styled.span`
  color: var(--txt-green, #03b62a);
`;

export const SaveParagraph = styled.p`
  color: var(--txt-light-blue, #f4ffff);
  font-size: 17px;
  font-weight: 400;
  line-height: 140%;
`;

export const SaveWrapper = styled.div`
  border-radius: 16px;
  background: var(--dark, #001731);
  display: flex;
  width: 365px;
  padding: 30px;
  flex-direction: column;
  align-items: flex-start;
  position: fixed;
  top: 30px;
  right: 30px;
`;

export const CrossWhiteIconWrapper = styled.div`
  position: absolute;
  right: 20px;
  top: 10px;
  cursor: pointer;
`;

export const Tittle = styled.p`
  color: var(--txt-dark, #001731);
  font-size: 38px;
  font-weight: 500;
  line-height: 120%;
`;

export const ConnectWrapper = styled.div`
  border-radius: 16px;
  background: var(--bg, #fdfdfd);
  padding: 30px;
  max-width: 826px;
  width: 100%;
`;

export const NameLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
`;

export const YourMessageWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

export const ConnectParagraph = styled.p`
  color: var(--txt-dark, #001731);
  font-size: 17px;
  font-weight: 500;
  line-height: 140%;
`;

export const Message = styled.textarea`
  border-radius: 16px;
  border: 1px solid var(--border, #bdbdbd);
  background: var(--wiht, #fff);
  display: flex;
  max-width: 665px;
  width: 100%;
  height: 200px;
  align-items: flex-start;
  gap: 10px;
  resize: none;
  margin-bottom: 20px;
  padding: 20px;
  &::placeholder {
    color: var(--txt-grey-light, #717880);
    font-size: 17px;
    font-weight: 400;
    line-height: 140%;
  }
  &:focus {
    border: 1px solid var(--batton, #0095c0);
  }
`;

export const RedStar = styled.p`
  color: var(--txt-red, #f00);
  font-size: 17px;
  font-weight: 400;
  line-height: 140%;
`;

export const IndustryParagraph = styled.p`
  color: var(--txt-dak-light, #143255);
  font-size: 17px;
  font-weight: 400;
  line-height: 140%;
`;

export const ButtonCancelSendWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  gap: 20px;
  justify-content: flex-end;
`;

export const CancelButton = styled.button`
  display: flex;
  border-radius: 16px;
  border: 1px solid var(--border, #bdbdbd);
  background: var(--wiht, #fff);
  padding: 14px 30px 16px 30px;
  justify-content: center;
  align-items: center;
  &:active {
    background: var(--green, #03b62a);
    color: var(--wiht, #fff);
  }
`;

export const SendButton = styled.button`
  border-radius: 16px;
  background: var(--batton, #0095c0);
  display: flex;
  padding: 14px 30px 16px 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

export const ConnectWrapperStyled = styled(ConnectWrapper)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const DialogContent = styled.div`
  display: flex;
  align-items: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  z-index: 2;
  border-radius: 16px;
  padding: 30px;
  max-width: 826px;
  width: 100%;
  height: 184px;
`;

export const SortContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 20px;
`;


export const WrapperProvider = styled.div`
  position: relative;

  width: 367px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  padding: 30px;

  border-radius: 16px;
  border: 1px solid var(--border, #bdbdbd);
  background: var(--wiht, #fff);
`;

export const CompanyWrapperLink = styled(Link)`
  width: 100%;
  cursor: pointer;
`;


export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
`;

