import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getAllCompanyUsersAsync, sendInviteAsync } from "../../store/slices/userSlice";
import { RootState } from "../../store/store";
import { ButtonBlock, Input, InputBlock, TeamWrapper, TitleAdd, TitleTeam } from "./styles";
import { useEffect, useState } from "react";
import UserInformation from "./UserInformation/UserInformation";
import ModalSuccessfullyChange from "../ModalWindows/ModalSuccessfullyChange/ModalSuccessfullyChange";

const Users = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state: RootState) => state.user);
  const [inviteEmail, setInviteEmail] = useState('');
  const [isChanged, setIsChanged] = useState(false);
  const [text, setText] = useState('');

  const handleSendInvite = async () => {
    setText('Sending...');
    setIsChanged(true);
    const res = await dispatch(sendInviteAsync(inviteEmail));

    if (res.meta.requestStatus === 'fulfilled') {
      setText('Your invitation has been sent successfully!');
      setIsChanged(true);
      setInviteEmail('');
    } else if (res.meta.requestStatus === 'rejected') {

      // To catch error code 417, indicating that the user already exists, or another code, and provide the appropriate message.
      // MESSAGE: 'This user can’t be added'
      
      setText('ERROR!!!');
    }


  };

  const handleIsChanged = () => {
    setIsChanged(!isChanged);

  };

  useEffect(() => {
    dispatch(getAllCompanyUsersAsync(user.userAccount?.companyId!));
  }, []);

  return (
    <>
      <TeamWrapper>
        <TitleAdd>Add new user</TitleAdd>
        <InputBlock>
          <Input
            value={inviteEmail}
            type="text"
            placeholder="Type email"
            onChange={e => setInviteEmail(e.target.value)}
          />
          <ButtonBlock onClick={handleSendInvite} >Send invite</ButtonBlock>
        </InputBlock>

        <TitleTeam>Team</TitleTeam>

        {user.company?.allUsers && user.company.allUsers.map((companyUser, index) => {

          return <UserInformation key={companyUser.id} companyUser={companyUser} />
        })}
      </TeamWrapper>

      {isChanged && <ModalSuccessfullyChange handleIsChanged={handleIsChanged} text={text} modal='modal' />}

    </>
  )
}

export default Users