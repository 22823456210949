import styled from "styled-components";

interface IButtonProps {
  $isActive?: boolean;
}

export const Container = styled.div`
  max-height: 800px;
  height: auto;
  max-width: 600px;
  width: 100%;
  position: sticky;
  top: 160px;
  padding: 30px;

  border: 1px solid var(--border, #bdbdbd);
  border-radius: 16px;
  background: var(--wiht, #fff);
  overflow: auto;
`;

export const Wrapper = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

export const Title = styled.h3`
  margin-bottom: 20px;
  text-align: center;

  font-size: 24px;
  font-weight: 500;
  line-height: 140%;

  color: var(--txt-dak-light, #143255);
`;

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Item = styled.div<IButtonProps>`
  max-width: 500px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px;
  border: ${(props) =>
    props.$isActive
      ? "2px solid var(--batton, #0095c0)"
      : "1px solid var(--border, #bdbdbd)"};
  background: ${(props) => props.$isActive ? "#e8f8fd" : "var(--wiht, #fff)"};    
  border-radius: 16px;
  transition: all 0.3s ease;

  cursor: pointer;

  &:hover {
    border: 2px solid var(--batton, #0095c0);
    background: var(--bg, #fdfdfd);
    box-shadow: 0px 2px 5.3px 0px rgba(0, 6, 81, 0.5);
  }
`;

export const ItemTitle = styled.h4`
  font-size: 17px;
  font-weight: 500;
  line-height: 140%;
  color: var(--txt-dark-light, #143255);
`;

export const ItemSubtitle = styled.h4`
  font-size: 16px;
  font-weight: 500;
  line-height: 140%;
  color: var(--txt-dark-light, #143255);
`;

export const ItemText = styled.h5`
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
  color: var(--txt-dark-light, #143255);
`;

export const TextWrapper = styled.div`
  display: flex;
  gap: 10px;
`;
