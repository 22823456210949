import React, { useEffect, useState } from "react";
import {
  IndustryItem,
  IndustryList,
  SearchInput,
  CountryItem,
  CountryInputWrapper,
  SearchInputCountryList,
  CountryList,
} from "../../Filter/style";
import SearchIconBlack from "../../../assets/icons/SearchIconBlack";
import ChevronRightIcon from "../../../assets/icons/ChevronRightIcon";
import ChevronDownIcon from "../../../assets/icons/ChevronDownIcon";
import {
  ChevronDown,
  SearchIcon,
  SearchIconInput,
  SearchButton,
  ChevronRightWrapper,
  InputWrapper,
  IndustryKeqwordsP,
  SearchInputWrapper,
  CountrySearchWrapper,
  IndustrySearchWrapper,
  SearchInputIndustries,
  SearchButtonCountry,
  SearchIconCountry,
  ChevronDownCity,
  SearchInputCountryCityList,
  SearchIconCity,
} from "./style";
import { PartnerBaseFiltersType, PartnerBaseProvider, ProviderDetails } from "../../../utils/types";
import { ItemFail } from "../../PesonalInfo/styles";
import { useAppSelector } from "../../../store/hooks";

interface BasicInformationFilterProps {
  companyItems: PartnerBaseProvider[];
  filterValues: ProviderDetails;
  onFilterChange: (filterValues: ProviderDetails) => void;
  setFilterValues: React.Dispatch<React.SetStateAction<ProviderDetails>>;
}

const BasicInformationFilter: React.FC<BasicInformationFilterProps> = ({
  companyItems,
  setFilterValues,
  filterValues,
  onFilterChange,
}) => {
  const [showIndustryList, setshowIndustryList] = useState(false);
  const [showKeywordsList, setShowKeywordsList] = useState(false);
  const [showCityList, setShowCityList] = useState(false);
  const [showCountryList, setShowCountryList] = useState(false);
  const [showFoundYearList, setShowFoundYearList] = useState(false);
  const [showLanguagesList, setShowLanguagesList] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [selectedIndustries, setSelectedIndustries] = useState<string[]>([]);
  const [selectedKeywords, setSelectedKeywords] = useState<string[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<string[]>([]);
  const [selectedCity, setSelectedCity] = useState<string[]>([]);
  const [selectedYear, setSelectedYear] = useState<number[]>([]);
  const [selectedLanguage, setSelectedLanguage] = useState<string[]>([]);

  const baseSearchfilterValues: PartnerBaseFiltersType = useAppSelector((state) => state.partnerBaseSearch.partnerFilterValues);

  const handleIndustryClick = (industry: string) => {
    setSearchText("");
    setFilterValues((prevState) => {
      const updatedIndustry = [...(prevState?.industry || []), industry];
      const updatedSelectedIndustries = selectedIndustries.filter(
        (item) => item !== industry
      );
      setSelectedIndustries(updatedSelectedIndustries);
      return {
        ...prevState,
        industry: updatedIndustry,
      };
    });
  };

  const handleKeywordsClick = (keyword: string) => {
    setSearchText("");
    setFilterValues((prevState) => {
      const updatedKeywords = [...(prevState?.keywords || []), keyword];
      const updatedSelectedKeywords = selectedKeywords.filter(
        (item) => item !== keyword
      );
      setSelectedKeywords(updatedSelectedKeywords);
      return {
        ...prevState,
        keywords: updatedKeywords,
      };
    });
  };

  const handleCountryClick = (country: string) => {
    setSearchText("");
    setFilterValues((prevState) => {
      const updatedCountry = [...(prevState?.country || []), country];
      const updatedSelectedCountry = selectedCountry.filter(
        (item) => item !== country
      );
      setSelectedCountry(updatedSelectedCountry);
      return {
        ...prevState,
        country: updatedCountry,
      };
    });
  };

  const handleCityClick = (city: string) => {
    setSearchText("");
    setFilterValues((prevState) => {
      const updatedCity = [...(prevState?.city || []), city];
      const updatedSelectedCity = selectedCountry.filter(
        (item) => item !== city
      );
      setSelectedCity(updatedSelectedCity);
      return {
        ...prevState,
        city: updatedCity,
      };
    });
  };

  const handleYearClick = (founded: number) => {
    setSearchText("");
    setFilterValues((prevState) => {
      const updatedYear = [...(prevState?.founded || []), founded];
      const updatedSelectedYear = selectedYear.filter(
        (item) => item !== founded
      );
      setSelectedYear(updatedSelectedYear);
      return {
        ...prevState,
        founded: updatedYear,
      };
    });
  };

  const handleLanguageClick = (language: string) => {
    setSearchText("");
    setFilterValues((prevState) => {
      const updatedLanguages = [...(prevState?.languages || []), language];
      const updatedSelectedLanguage = selectedLanguage.filter(
        (item) => item !== language
      );
      setSelectedLanguage(updatedSelectedLanguage);
      const updatedFilteredLanguages = baseSearchfilterValues.languages!.filter(
        (lang) => lang !== language
      );
      return {
        ...prevState,
        languages: updatedLanguages,
      };
    });
  };

  return (
    <>
      <IndustryKeqwordsP>Industry</IndustryKeqwordsP>
      <InputWrapper>
        {!showIndustryList ? (
          <>
            <SearchInputWrapper>
              <SearchInput
                type="text"
                name="Industry"
                placeholder="Search industries"
                onClick={() => {
                  setshowIndustryList(!showIndustryList);
                }}
                value={filterValues?.industry?.join(" ") || []}
              />
              <SearchButton
                onClick={() => {
                  setshowIndustryList(!showIndustryList);
                }}
              >
                <SearchIconBlack />
              </SearchButton>
              <ChevronRightWrapper
                onClick={() => {
                  setshowIndustryList(!showIndustryList);
                }}
              >
                <ChevronRightIcon />
              </ChevronRightWrapper>
            </SearchInputWrapper>
          </>
        ) : (
          <IndustrySearchWrapper>
            <SearchIconInput type="button">
              <SearchIconBlack />
            </SearchIconInput>
            <ChevronDown
              onClick={() => {
                setshowIndustryList(false);
              }}
            >
              <ChevronDownIcon />
            </ChevronDown>
            <SearchInputIndustries
              placeholder="Search industries"
              type="text"
              autoFocus
              value={
                filterValues?.industry?.length &&
                !filterValues.industry.includes(searchText)
                  ? filterValues.industry.join(" ")
                  : searchText
              }
              onChange={(e) => {
                const newValue = e.target.value;
                setSearchText(newValue);
                if (
                  filterValues?.industry &&
                  !filterValues.industry.includes(newValue.trim())
                ) {
                  setFilterValues({
                    ...filterValues,
                    industry: newValue.trim() ? [newValue.trim()] : [],
                  });
                }
              }}
            />
            <IndustryList>
              {baseSearchfilterValues.industry &&
                baseSearchfilterValues.industry.length > 0 &&
                baseSearchfilterValues.industry.map((industry) => {
                  if (
                    industry &&
                    industry.trim() !== "" &&
                    !filterValues?.industry?.includes(industry.trim())
                  ) {
                    return (
                      <IndustryItem
                        key={industry}
                        onClick={() => handleIndustryClick(industry)}
                      >
                        {industry}
                      </IndustryItem>
                    );
                  } else {
                    return null;
                  }
                })}
            </IndustryList>
          </IndustrySearchWrapper>
        )}
      </InputWrapper>

      <IndustryKeqwordsP>Keywords</IndustryKeqwordsP>
      <InputWrapper>
        {!showKeywordsList ? (
          <>
            <SearchInputWrapper>
              <SearchInput
                type="text"
                name="Keywords"
                placeholder="Search keywords"
                onClick={() => {
                  setShowKeywordsList(!showKeywordsList);
                }}
                value={filterValues?.keywords?.join(" ") || []}
              />
              <SearchButton
                onClick={() => {
                  setShowKeywordsList(!showKeywordsList);
                }}
              >
                <SearchIconBlack />
              </SearchButton>
              <ChevronRightWrapper
                onClick={() => {
                  setShowKeywordsList(!showKeywordsList);
                }}
              >
                <ChevronRightIcon />
              </ChevronRightWrapper>
            </SearchInputWrapper>
          </>
        ) : (
          <IndustrySearchWrapper>
            <SearchIconInput type="button">
              <SearchIconBlack />
            </SearchIconInput>
            <ChevronDown
              onClick={() => {
                setShowKeywordsList(false);
              }}
            >
              <ChevronDownIcon />
            </ChevronDown>
            <SearchInputIndustries
              placeholder="Search keywords"
              type="text"
              autoFocus
              value={
                filterValues?.keywords?.length &&
                !filterValues.keywords.includes(searchText)
                  ? filterValues.keywords.join(" ")
                  : searchText
              }
              onChange={(e) => {
                const newValue = e.target.value;
                setSearchText(newValue);
                if (filterValues?.keywords) {
                  setFilterValues({
                    ...filterValues,
                    keywords: newValue.split(" ").map((item) => item.trim()),
                  });
                }
              }}
            />
            <IndustryList>
              {baseSearchfilterValues.keywords &&
                baseSearchfilterValues.keywords.length > 0 &&
                baseSearchfilterValues.keywords.map((keyword) => {
                  if (keyword && keyword.trim() !== "") {
                    return (
                      <IndustryItem
                        key={keyword}
                        onClick={() => handleKeywordsClick(keyword)}
                      >
                        {keyword}
                      </IndustryItem>
                    );
                  } else {
                    return null;
                  }
                })}
              {baseSearchfilterValues.keywords && baseSearchfilterValues.keywords.length === 0 && (
                <ItemFail>Choose from existing keywords</ItemFail>
              )}
            </IndustryList>
          </IndustrySearchWrapper>
        )}
      </InputWrapper>

      <IndustryKeqwordsP>Country</IndustryKeqwordsP>
      <InputWrapper>
        {!showCountryList ? (
          <SearchInputWrapper>
            <SearchInput
              type="text"
              name="country"
              placeholder="Enter country"
              onClick={() => setShowCountryList(!showCountryList)}
              value={filterValues?.country?.join(" ") || []}
            />
            <SearchButtonCountry
              onClick={() => setShowCountryList(!showCountryList)}
            >
              <SearchIconBlack />
            </SearchButtonCountry>
            <ChevronRightWrapper
              onClick={() => {
                setShowCountryList(!showCountryList);
              }}
            >
              <ChevronRightIcon />
            </ChevronRightWrapper>
          </SearchInputWrapper>
        ) : (
          <CountrySearchWrapper>
            <CountryInputWrapper onClick={() => setShowCountryList(false)}>
              <SearchIconCountry type="button">
                <SearchIconBlack />
              </SearchIconCountry>
              <ChevronDown
                onClick={() => {
                  setShowCountryList(false);
                }}
              >
                <ChevronDownIcon />
              </ChevronDown>
              <SearchInputCountryCityList
                placeholder="Enter country"
                type="text"
                autoFocus
                value={
                  filterValues?.country?.length &&
                  !filterValues.country.includes(searchText)
                    ? filterValues.country.join(" ")
                    : searchText
                }
                onChange={(e) => {
                  const newValue = e.target.value;
                  setSearchText(newValue);
                  if (filterValues?.country) {
                    setFilterValues({
                      ...filterValues,
                      country: newValue.split(" ").map((item) => item.trim()),
                    });
                  }
                }}
              />
            </CountryInputWrapper>
            <CountryList>
              {baseSearchfilterValues.countries &&
                baseSearchfilterValues.countries.length > 0 &&
                baseSearchfilterValues.countries.map((country: string) => {
                  if (country && country.trim() !== "") {
                    return (
                      <CountryItem
                        key={country}
                        onClick={() => handleCountryClick(country)}
                      >
                        {country}
                      </CountryItem>
                    );
                  } else {
                    return null;
                  }
                })}
              {baseSearchfilterValues.countries && baseSearchfilterValues.countries.length === 0 && (
                <ItemFail>Choose from existing country</ItemFail>
              )}
            </CountryList>
          </CountrySearchWrapper>
        )}
      </InputWrapper>

      <IndustryKeqwordsP>City</IndustryKeqwordsP>
      <InputWrapper>
        {!showCityList ? (
          <SearchInputWrapper>
            <SearchInput
              type="text"
              name="city"
              placeholder="Enter city"
              onClick={() => setShowCityList(!showCityList)}
              value={filterValues?.city?.join(" ") || []}
            />
            <SearchButton onClick={() => setShowCityList(!showCityList)}>
              <SearchIconBlack />
            </SearchButton>
            <ChevronRightWrapper
              onClick={() => {
                setShowCityList(!showCityList);
              }}
            >
              <ChevronRightIcon />
            </ChevronRightWrapper>
          </SearchInputWrapper>
        ) : (
          <CountrySearchWrapper>
            <CountryInputWrapper onClick={() => setShowCityList(false)}>
              <SearchIconCity type="button">
                <SearchIconBlack />
              </SearchIconCity>
              <ChevronDownCity
                onClick={() => {
                  setShowCityList(false);
                }}
              >
                <ChevronDownIcon />
              </ChevronDownCity>
              <SearchInputCountryCityList
                placeholder="Enter city"
                type="text"
                autoFocus
                value={
                  filterValues?.city?.length &&
                  !filterValues.city.includes(searchText)
                    ? filterValues.city.join(" ")
                    : searchText
                }
                onChange={(e) => {
                  const newValue = e.target.value;
                  setSearchText(newValue);
                  if (filterValues?.city) {
                    setFilterValues({
                      ...filterValues,
                      city: newValue.split(" ").map((item) => item.trim()),
                    });
                  }
                }}
              />
            </CountryInputWrapper>
            <CountryList>
              {baseSearchfilterValues.cities &&
                baseSearchfilterValues.cities.length > 0 &&
                baseSearchfilterValues.cities.map((city: string) => {
                  if (city.trim() !== "") {
                    return (
                      <CountryItem
                        key={city}
                        onClick={() => handleCityClick(city)}
                      >
                        {city}
                      </CountryItem>
                    );
                  } else {
                    return null;
                  }
                })}
              {baseSearchfilterValues.cities && baseSearchfilterValues.cities.length === 0 && (
                <ItemFail>Choose from existing city</ItemFail>
              )}
            </CountryList>
          </CountrySearchWrapper>
        )}
      </InputWrapper>

      <IndustryKeqwordsP>Founded</IndustryKeqwordsP>
      <InputWrapper>
        {!showFoundYearList ? (
          <>
            <SearchInputWrapper>
              <SearchInput
                type="text"
                name="Founded"
                placeholder="Enter years"
                onClick={() => {
                  setShowFoundYearList(!showFoundYearList);
                }}
                value={filterValues?.founded?.join(" ") || []}
              />
              <SearchButton
                onClick={() => {
                  setShowFoundYearList(!showFoundYearList);
                }}
              >
                <SearchIconBlack />
              </SearchButton>
              <ChevronRightWrapper
                onClick={() => {
                  setShowFoundYearList(!showFoundYearList);
                }}
              >
                <ChevronRightIcon />
              </ChevronRightWrapper>
            </SearchInputWrapper>
          </>
        ) : (
          <IndustrySearchWrapper>
            <SearchIconInput type="button">
              <SearchIconBlack />
            </SearchIconInput>
            <ChevronDown
              onClick={() => {
                setShowFoundYearList(false);
              }}
            >
              <ChevronDownIcon />
            </ChevronDown>
            <SearchInputIndustries
              placeholder="Enter years"
              type="text"
              autoFocus
              value={
                filterValues?.founded?.length &&
                !filterValues.founded.includes(parseInt(searchText))
                  ? filterValues.founded.join(" ")
                  : searchText
              }
              onChange={(e) => {
                const newValue = e.target.value;
                setSearchText(newValue);
                const newYears = newValue
                  .split(" ")
                  .filter((year) => !isNaN(parseInt(year.trim())))
                  .map((year) => parseInt(year.trim(), 10));
                setFilterValues({
                  ...filterValues,
                  founded: newYears,
                });
              }}
            />
            <IndustryList>
              {baseSearchfilterValues.founded &&
                baseSearchfilterValues.founded.length > 0 &&
                baseSearchfilterValues.founded.map((foundYear) => {
                  if (!Number.isNaN(foundYear)) {
                    return (
                      <IndustryItem
                        key={foundYear}
                        onClick={() => handleYearClick(foundYear)}
                      >
                        {foundYear}
                      </IndustryItem>
                    );
                  } else {
                    return null;
                  }
                })}
              {baseSearchfilterValues.founded && baseSearchfilterValues.founded.length === 0 && (
                <ItemFail>Choose from existing years</ItemFail>
              )}
            </IndustryList>
          </IndustrySearchWrapper>
        )}
      </InputWrapper>

      <IndustryKeqwordsP>Language</IndustryKeqwordsP>
      <InputWrapper>
        {!showLanguagesList ? (
          <>
            <SearchInputWrapper>
              <SearchInput
                type="text"
                name="Language"
                placeholder="Enter language"
                onClick={() => {
                  setShowLanguagesList(!showLanguagesList);
                }}
                value={filterValues?.languages?.join(" ") || []}
              />
              <SearchButton
                onClick={() => {
                  setShowLanguagesList(!showLanguagesList);
                }}
              >
                <SearchIconBlack />
              </SearchButton>
              <ChevronRightWrapper
                onClick={() => {
                  setShowLanguagesList(!showLanguagesList);
                }}
              >
                <ChevronRightIcon />
              </ChevronRightWrapper>
            </SearchInputWrapper>
          </>
        ) : (
          <IndustrySearchWrapper>
            <SearchIconInput type="button">
              <SearchIconBlack />
            </SearchIconInput>
            <ChevronDown
              onClick={() => {
                setShowLanguagesList(false);
              }}
            >
              <ChevronDownIcon />
            </ChevronDown>
            <SearchInputIndustries
              placeholder="Enter language"
              type="text"
              autoFocus
              value={
                filterValues?.languages?.length &&
                !filterValues.languages.includes(searchText)
                  ? filterValues.languages.join(" ")
                  : searchText
              }
              onChange={(e) => {
                const newValue = e.target.value;
                setSearchText(newValue);
                if (
                  filterValues?.languages &&
                  !filterValues.languages.includes(newValue.trim())
                ) {
                  setFilterValues({
                    ...filterValues,
                    languages: newValue.trim() ? [newValue.trim()] : [],
                  });
                }
              }}
            />
            <IndustryList>
              {baseSearchfilterValues.languages &&
              baseSearchfilterValues.languages.length > 0 ? (
                baseSearchfilterValues.languages.map((language) => (
                  <IndustryItem
                    key={language.toString()}
                    onClick={() => handleLanguageClick(language)}
                  >
                    {language}
                  </IndustryItem>
                ))
              ) : (
                <>
                  {baseSearchfilterValues.languages && baseSearchfilterValues.languages.length === 0 && (
                    <ItemFail>Choose from existing languages</ItemFail>
                  )}
                </>
              )}
            </IndustryList>
          </IndustrySearchWrapper>
        )}
      </InputWrapper>
    </>
  );
};

export default BasicInformationFilter;
