import React from "react";

import ReturnButton from "../components/ReturnButton/ReturnButton";
import styled from "styled-components";
import TitleHeader from "../components/TitleHeader/TitleHeader";
import ProductsFilter from "../components/CatalogProductsPage/ProductsFilter/ProductsFilter";
import { useAppSelector } from "../store/hooks";
import { RootState } from "../store/store";
import CatalogList from "../components/CatalogProductsPage/CatalogList/CatalogList";

const Container = styled.div``;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
`;

const CatalogProducts: React.FC = () => {
  const providerProducts = useAppSelector(
    (state: RootState) => state.user.providerById.products
  );
  
  return (
    <Container>
      <HeaderWrapper>
        <ReturnButton />
        <TitleHeader text={"Catalog products"} />
      </HeaderWrapper>
      <ProductsFilter />
      <CatalogList products={providerProducts} />
    </Container>
  );
};

export default CatalogProducts;
