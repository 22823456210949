import React from "react";
import styled from "styled-components";
 const IconWrapper = styled.div`
  max-width: 35px;
  display: flex;

  padding: 7px;

  border-radius: 50%;
  border: 2px solid var(--green, #03b62a);
  background: var(--wiht, #fff);
`;

const PlusIcon: React.FC = () => {
  return (
    <IconWrapper>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="16"
        viewBox="0 0 17 16"
        fill="none"
      >
        <path
          d="M4.08301 8H13.4163"
          stroke="#03B62A"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.75 3.33325V12.6666"
          stroke="#03B62A"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </IconWrapper>
  );
};

export default PlusIcon;
