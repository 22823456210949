import { StyledTitleText } from "./styles";

interface ITitleProps {
  text: string;
}

const TitleText = ({ text }: ITitleProps) => {
  return <StyledTitleText>{text}</StyledTitleText>;
};

export default TitleText;
