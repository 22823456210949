import React, {useState} from "react";
import {
    AvatarBlock,
    ChatBlock, CreateText,
    DateRequest,
    EmptyCenterBlock, FileOrWhat,
    IconWrapper,
    Request,
    RequestCreate, Screpka, SearchHelper,
    SubTitle, Tag,
    TagsBlock,
    Wrapper,
    WrapperLeft,
    WrapperRight,
} from "./style";

import {uploadFilesToServer} from "../../store/slices/userSlice";
import {Attachment, ModifiedFileObject} from "../../utils/types";
import {useDispatch} from "react-redux";
import TrashIcon from "../../assets/icons/TrashIcon";
import ComputerIcon from "../../assets/icons/ComputerIcon";
import ModalSuccessfullyChange from "../ModalWindows/ModalSuccessfullyChange/ModalSuccessfullyChange";
import ModalAssistent from "../ModalWindows/ModalAssistent/ModalAssistent";

const Matching = () => {
    const dispatch = useDispatch();
    const [message, setMessage] = useState("");
    const [uploading, setUploading] = useState(false);
    const [filesToUpload, setFilesToUpload] = useState<File[]>([]);
    const handleFileChange = async (filesArray: File[]) => {
        if (filesArray.length <= 0) {
            return;
        }
        setUploading(true);
        setFilesToUpload(filesArray);
        const saveFiles = await dispatch(
            uploadFilesToServer({files: filesArray}) as any
        );

        if (saveFiles.payload.message === "400") {
            saveFiles.payload = [];
        }
        const saveFilesPayload: Attachment[] = saveFiles.payload;
        const modifiedFilesArray: ModifiedFileObject[] = saveFilesPayload.map(
            (obj) => {
                const {link, name} = obj;
                return {link, name};
            }
        );
        // setConnectionData({
        //   ...connectionData,
        //   files: modifiedFilesArray || [],
        // } as any);
        setUploading(false);

        return saveFilesPayload;
    };
    return (
        <>
            <Wrapper>
                <WrapperLeft>
                    <EmptyCenterBlock>
                        <ChatBlock>
                            <AvatarBlock style={{fontSize: "38px", marginBottom: "30px"}}>S</AvatarBlock>
                            <SubTitle>Hello, can I help you find the product you're looking for? I'll ask just 10
                                guiding
                                questions.</SubTitle>
                        </ChatBlock>
                        <TagsBlock>
                            <Tag>Shirt</Tag>
                            <Tag>Safety belt components</Tag>
                            <Tag>Tank top with drawings of various infrast...</Tag>
                            <Tag>Pants</Tag>
                        </TagsBlock>
                        <SearchHelper>
                            Text for SelfMan
                            <IconWrapper style={{gap: "20px"}}>
                                <FileOrWhat/>
                                <Screpka/>
                            </IconWrapper>
                        </SearchHelper>
                    </EmptyCenterBlock>
                </WrapperLeft>
                <WrapperRight>
                    <RequestCreate>
                        <AvatarBlock style={{width: "40px", height: "40px"}}>S</AvatarBlock>
                        <CreateText>Create a request</CreateText>
                        <IconWrapper>
                            <ComputerIcon/>
                        </IconWrapper>
                    </RequestCreate>
                    <DateRequest>
                        Request 23 September
                    </DateRequest>
                    <Request>
                        Shirt
                        <IconWrapper>
                            <TrashIcon/>
                        </IconWrapper>
                    </Request>
                    <DateRequest>
                        Request 15 September
                    </DateRequest>
                    <Request>
                        Safety belt components
                        <IconWrapper>
                            <TrashIcon/>
                        </IconWrapper>
                    </Request>
                    <DateRequest>
                        Request 2023
                    </DateRequest>
                    <Request>
                        Tank top with drawings of...
                        <IconWrapper>
                            <TrashIcon/>
                        </IconWrapper>
                    </Request>
                </WrapperRight>
                {/*<ModalAssistent handleIsChanged={() => setMessage("")} text={"Your request is being processed, please wait a moment..."}></ModalAssistent>*/}
            </Wrapper>
        </>
    );
};

export default Matching;
