import styled from "styled-components";

export const Wrapper = styled.div`
  max-width: 705px;
  width: 100%;

  padding-top: 6px;
  margin-bottom: 12px;
`;

export const MainTitleWrapper = styled.div`
  position: relative;
  display: flex;
  margin-bottom: 18px;
`;

export const TitleWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  margin-bottom: 4px;
`;

export const Label = styled.label`
  margin-bottom: 2px;

  font-size: 17px;
  line-height: 140%;
  color: var(--txt-light-blue-dont-active, #a0b7d1);
`;

export const RedStar = styled.p`
  font-size: 17px;
  font-weight: 400;
  line-height: 140%;

  color: var(--txt-red, #f00);
`;

export const Input = styled.input`
  width: 100%;
  max-width: 691px;

  padding: 6px 0 6px 10px;
  margin-bottom: 18px;

  font-size: 17px;
  line-height: 140%;

  color: var(--txt-dak-light, #143255);
  border: 1px solid var(--border, #bdbdbd);
  border-radius: 12px;
  &:focus {
    border: 1px solid var(--batton, #0095c0);
  }
  &::placeholder {
    color: var(--txt-grey-light, #717880);
    font-size: 17px;
    font-weight: 400;
    line-height: 140%;
  }
`;

export const InputBussines = styled.input`
  width: 100%;
  max-width: 691px;

  padding: 6px 0 6px 10px;

  font-size: 17px;
  line-height: 140%;

  color: var(--txt-dak-light, #143255);
  border: 1px solid var(--border, #bdbdbd);
  border-radius: 12px;
  cursor: pointer;
  &:focus {
    border: 1px solid var(--batton, #0095c0);
  }
  &::placeholder {
    color: var(--txt-grey-light, #717880);
    font-size: 17px;
    font-weight: 400;
    line-height: 140%;
  }
`;

export const Tooltip = styled.h5`
  position: absolute;
  left: 175px;
  bottom: 0px;

  max-width: 384px;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;

  padding: 20px;

  border-radius: 16px;
  color: var(--txt-dark-light, #143255);
  border: 1px solid var(--border, #bdbdbd);
  background: var(--wiht, #fff);
  line-height: 140%;
  white-space: wrap;
  transform: translate(128px, 5%);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
`;

export const TooltipTax = styled.h5`
  position: absolute;
  left: 45px;
  bottom: 0px;

  max-width: 386px;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;

  padding: 20px;

  border-radius: 16px;
  color: var(--txt-dark-light, #143255);
  border: 1px solid var(--border, #bdbdbd);
  background: var(--wiht, #fff);
  line-height: 140%;
  white-space: wrap;
  transform: translate(128px, 5%);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
`;

export const TooltipWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 14px;
  cursor: pointer;
`;

export const TooltipGeneral = styled.h5`
  position: absolute;
  left: 20px;
  top: 0px;

  max-width: 515px;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;

  padding: 20px;

  border-radius: 16px;
  color: var(--txt-dark-light, #143255);
  border: 1px solid var(--border, #bdbdbd);
  background: var(--wiht, #fff);
  line-height: 140%;
  white-space: wrap;
  transform: translate(128px, 5%);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
`;

export const InputWrapper = styled.div`
  position: relative;
  margin-bottom: 18px;
`;

export const ChevronRightSettingsWrapper = styled.div`
  position: absolute;
  right: 6px;
  top: 7px;
`;

export const ItemWrapper = styled.div`
  width: 100%;

  padding: 6px 0 6px 10px;

  font-size: 17px;
  line-height: 140%;

  color: var(--txt-dak-light, #143255);
  border: 1px solid var(--border, #bdbdbd);
  border-top: none;
  border-radius: 12px;
  cursor: pointer;
  &:focus {
    border: 1px solid var(--batton, #0095c0);
  }
  &::placeholder {
    color: var(--txt-grey-light, #717880);
    font-size: 17px;
    font-weight: 400;
    line-height: 140%;
  }
`;

export const Item = styled.h4`
  padding: 8px 0px;

  font-size: 17px;
  line-height: 140%;

  color: var(--txt-dak-light, #143255);
  cursor: pointer;
  &:hover {
    color: var(--txt-blue, #0095c0);
  }
`;
