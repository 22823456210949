import React, { useEffect, useRef, useState } from "react";
import Button from "../../Button/Button";
import { UserAccount, UserResendCode } from "../../../utils/types";
import { confirmEmailAsync, resendCode } from "../../../store/slices/userSlice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { ButtonWrapper, CodeInput, CodeTitle, CodeWrapper, Container, CrossWhiteIconWrapper, InputWrapper, SnackbarParagraph, SnackbarWrapper, StyledLinkText, Subtitle, SubtitleError, SubtitleErrorWrapper, Text, TextRepeatCode } from "./ModalConfirmationEmailAndPhoneStyles";
import CrossWhiteIcon from "../../../assets/icons/CrossWhireIcon";

const TIMER_KEY = "timer";

// At the moment, the component is fully designed for email confirmation. 
// Perhaps it's better to create a separate component for phone confirmation.

interface Props {
  replacementField: string;
  handleFieldClick: () => void;
}

const ModalConfirmationEmailAndPhone = ({ replacementField, handleFieldClick }: Props) => {
  const dispatch = useAppDispatch();
  const state = useAppSelector(state => state);
  const user: UserAccount = useAppSelector((store) => store.user.userAccount!);
  const [otp, setOtp] = useState<string[]>(new Array(6).fill(""));
  const [activeOTPIndex, setactiveOTPIndex] = useState<number>(0);
  const [timer, setTimer] = useState<number>(
    parseInt(localStorage.getItem(TIMER_KEY) || "300", 10)
  );
  const [errorCode, setErrorCode] = useState<string>("");
  const [copiedCode, setCopiedCode] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const [currentOTPIndex, setCurrentOTPIndex] = useState<number>(0);
  // const [showSnackbar, setSnackbar] = useState(true);


  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;

    setOtp(prevOtp => {
      const newOtp = [...prevOtp];
      newOtp[currentOTPIndex] = value[value.length - 1];

      if (!value) {
        newOtp[currentOTPIndex] = '';
        setactiveOTPIndex(currentOTPIndex - 1);
      } else {
        setactiveOTPIndex(currentOTPIndex + 1);
      }

      return newOtp;
    });
  };

  const handleOnkeyDown = (
    { key }: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    setCurrentOTPIndex(index);
    if (key === "Backspace") setactiveOTPIndex(currentOTPIndex - 1);
  };

  useEffect(() => {
    inputRef.current?.focus();
  }, [activeOTPIndex]);

  useEffect(() => {
    if (copiedCode) {
      navigator.clipboard
        .readText()
        .then((copiedText) => {
          const newOtp: string[] = copiedText.split("").slice(0, 6);
          setOtp(newOtp);
          setCopiedCode(false);

        })
        .catch((error) => {
          console.error("Error reading clipboard:", error);
          setErrorCode("Error reading clipboard");
        });
    }
  }, [copiedCode]);

  const resetTimer = () => {
    setTimer(300);
    localStorage.setItem(TIMER_KEY, "300");
  };

  useEffect(() => {
    // dispatch(fetchCountriesAsync() as any);
    const intervalId = setInterval(() => {
      setTimer((prevTimer) => {
        const newTimer = prevTimer > 0 ? prevTimer - 1 : 0;
        if (newTimer === 0) {
          localStorage.removeItem(TIMER_KEY);
        } else {
          localStorage.setItem(TIMER_KEY, newTimer.toString());
        }
        return newTimer;
      });
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  // const navigate = useNavigate();

  // const handleBack = () => {
  //   navigate(-1);
  // };

  const handleConfirmClick = async () => {
    if (otp.join("") === "") {
      setErrorCode("Enter code*");
      return;
    } else if (otp.join("").length < 6) {
      setErrorCode("Enter code*");
      return;
    }

    const code = otp.join('');

    const response: any = await dispatch(confirmEmailAsync(code));

    if (response.payload.errorStatus && response.payload.errorStatus === 401) {
      setErrorCode(`${response.payload.message}`);
    } else {
      handleFieldClick();

      // setSnackbar(true);
      // setTimeout(async () => {
      //   setSnackbar(false);
      // }, 3000);

    }


  };

  const handleResendClick = () => {
    dispatch(
      resendCode({ id: user.id } as UserResendCode) as any
    )
      .then((result: any) => {
        if (result.payload.message === "429") {
          setErrorCode(
            "Please wait for the specified time and try the request again*"
          );
        } else {
          resetTimer();
        }
      })
      .catch((error: any) => {
        console.error("failed:", error);
      });
  };

  const handlePasteCode = () => {
    navigator.clipboard
      .readText()
      .then((copiedText) => {
        const newOtp: string[] = Array(6).fill("");
        const copiedOtp = copiedText.split("").slice(0, 6);
        copiedOtp.forEach((char, index) => {
          newOtp[index] = char;
        });
        setOtp(newOtp);
        setCopiedCode(false);
        setactiveOTPIndex(copiedOtp.length - 1);
      })
      .catch((error) => {
        console.error("Error reading clipboard:", error);
        setErrorCode("Error reading clipboard");
      });
  };

  return (
      <Container>
        <Subtitle>We have sent a confirmation code to your {replacementField}</Subtitle>
        <SubtitleErrorWrapper>
          {errorCode && <SubtitleError>{errorCode}</SubtitleError>}
        </SubtitleErrorWrapper>

        <CodeWrapper>
          <CodeTitle>Code</CodeTitle>
          <InputWrapper>
            {otp.map((_, index) => (
              <React.Fragment key={index}>
                <CodeInput
                  color={errorCode}
                  ref={index === activeOTPIndex ? inputRef : null}
                  type="number"
                  onChange={handleOnChange}
                  onKeyDown={(e) => handleOnkeyDown(e, index)}
                  value={otp[index]}
                  onPaste={handlePasteCode}
                />
              </React.Fragment>
            ))}
          </InputWrapper>
          <Text>
            Please enter the code: {Math.floor(timer / 60)}:
            {timer % 60 < 10 ? `0${timer % 60}` : timer % 60}
          </Text>
          <ButtonWrapper>
            <Button
              disabled={state.user.status === "loading"}
              text={state.user.status === "loading" ? "loading..." : "Confirm"}
              onClick={handleConfirmClick} />
          </ButtonWrapper>
          <TextRepeatCode>
            If you haven't received the code within this time,{" "}
            <StyledLinkText onClick={handleResendClick}>
              click here
            </StyledLinkText>
          </TextRepeatCode>
        </CodeWrapper>

        {/* {showSnackbar && (
        <SnackbarWrapper>
          <SnackbarParagraph>
            {`Email has been successfully changed`}
          </SnackbarParagraph>
          <CrossWhiteIconWrapper onClick={() => setSnackbar(false)}>
            <CrossWhiteIcon />
          </CrossWhiteIconWrapper>
        </SnackbarWrapper>
      )} */}

      </Container>





  );
}

export default ModalConfirmationEmailAndPhone