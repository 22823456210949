import React from "react";
import styled from "styled-components";

const StyledArrowUpDownIcon = styled.svg`
  width: 24px;
  height: 24px;

  path {
    stroke: var(--txt-dark, #001731);
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    fill: none;
    transition: stroke 0.3s;
  }

  &:hover path {
    stroke: var(--batton, #0095c0);
  }
`;

const ArrowUpDownIcon: React.FC = () => {
  return (
    <StyledArrowUpDownIcon viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <g id="Icons/arrow-up-down27">
        <path id="Vector" d="M21 16L17 20L13 16" />
        <path id="Vector_2" d="M17 20V4" />
        <path id="Vector_3" d="M3 8L7 4L11 8" />
        <path id="Vector_4" d="M7 4V20" />
      </g>
    </StyledArrowUpDownIcon>
  );
};

export default ArrowUpDownIcon;
