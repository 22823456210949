import React from 'react'
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';
import { Navigate, Outlet } from 'react-router-dom';
import { routes } from '../routes/routes';

const ProtectedLayout = () => {
    const token = useSelector((state: RootState) => state.user.userAccount?.token);
    const userName = useSelector((state: RootState) => state.user.userAccount?.firstName);

    if (!token) {
      return <Navigate to="/" replace />;
    }

    // if (!userName) {
    //   return <Navigate to={`${routes.PERSONAL}`} replace />;
    // }
  
    return <Outlet />;
}

export default ProtectedLayout