import React, { useEffect, useState } from "react";
import {
  Wrapper,
  MainTitleWrapper,
  TooltipWrapper,
  TooltipDocuments,
  DocumentWrapper,
  DocumentName,
  RedStar,
  TooltipBisCertificate,
  Item,
  List,
  MediaUploadWrapper,
  CloseUploadWrapper,
  UploadDialogWrapper,
  VectorWrapper,
  DocumentConainer,
  TooltipAddress,
  TooltipTax,
  TooltipArticles,
  TooltipBank,
  DocumentWrapperItem,
  TooltipCertificates,
} from "./styles";
import Title from "../Title/Title";
import CrossIcon from "../../assets/icons/CrossIcon";
import VectorIcon from "../../assets/icons/VectorIcon";
import MediaUpload from "../MediaUpload/MediaUpload";
import {
  removeAttachmentCompany,
  updateGeneralCompanyInfo,
} from "../../store/slices/userSlice";
import ShieldQuestionIcon from "../../assets/icons/ShieldQuestionIcon";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  CompanyData,
  CompanyType,
  DocumentEnum,
} from "../../utils/types";
import { uploadFiles } from "../../services/uploadFilesToServer";

const CompanyDocuments: React.FC = ({
}) => {
  const userAccount = useAppSelector((store) => store.user.userAccount!);
  const [showDocumentsTooltip, setShowDocumentsTooltip] =
    useState<boolean>(false);
  const [showTaxTooltip, setShowTaxTooltip] = useState<boolean>(false);
  const [showArticlesTooltip, setShowArticlesooltip] = useState<boolean>(false);
  const [showBankTooltip, setShowBankTooltip] = useState<boolean>(false);
  const [showProofAddressTooltip, setShowProofAddressTooltip] =
    useState<boolean>(false);
  const [showBisCertificateTooltip, setshowBisCertificateTooltip] =
    useState<boolean>(false);
  const [showCertificates, setShowCertificates] = useState<boolean>(false);
  const [filesToUpload, setFilesToUpload] = useState<File[]>([]);
  const dispatch = useAppDispatch();
  const companyInfo: CompanyData = useAppSelector((store) => store.user.company!);
  const [updateDocument, setUpdateDocument] = useState<CompanyData>();
  const [documentType, setDocumentType] = useState<DocumentEnum>(DocumentEnum.businessCertificate);
  
  const handleAttachmentFilesChange = async (files: File[]) => {
    const saveFiles = await uploadFiles({ files, dispatch });

    const previousValue = updateDocument
      ? updateDocument[documentType] || []
      : [];

    const updatedInfo = {
      ...updateDocument,
      [documentType]: [...previousValue, ...saveFiles.payload],
    };

    dispatch(
      updateGeneralCompanyInfo({
        ...updatedInfo,
        companyType: userAccount.companyType.toLowerCase() as CompanyType,
      }) as any
    );
    setUpdateDocument({});
  };

  const handleRemoveClick = async (
    id: number,
    documentType: DocumentEnum
  ) => {
    await dispatch(
      removeAttachmentCompany({
        id,
        documentType,
        token: userAccount.token,
        companyType: userAccount.companyType.toLowerCase() as CompanyType,
      }) as any
    );
  };


  return (
    <Wrapper>
      <MainTitleWrapper>
        <Title text={"Documents"} />
        <TooltipWrapper
          onMouseEnter={() => setShowDocumentsTooltip(true)}
          onMouseLeave={() => setShowDocumentsTooltip(false)}
        >
          <ShieldQuestionIcon />
        </TooltipWrapper>
        {showDocumentsTooltip && (
          <TooltipDocuments>
            Your documents are private. This information will only be used
            internally for verification purposes to add credibility to your
            account. Thus, upload as many documents as possible. Once uploaded,
            request verification by our team.
          </TooltipDocuments>
        )}
      </MainTitleWrapper>





      <DocumentConainer>
        <DocumentWrapperItem>
          <DocumentWrapper>
            <DocumentName>Business registration certificate</DocumentName>
            <RedStar>*</RedStar>

            <TooltipWrapper
              onMouseEnter={() => setshowBisCertificateTooltip(true)}
              onMouseLeave={() => setshowBisCertificateTooltip(false)}
            >
              <ShieldQuestionIcon />
            </TooltipWrapper>
            {showBisCertificateTooltip && (
              <TooltipBisCertificate>
                Legal document stating the legal status of your business unit.
              </TooltipBisCertificate>
            )}
          </DocumentWrapper>
          <MediaUploadWrapper>
            <UploadDialogWrapper
              onClick={() => setDocumentType(DocumentEnum.businessCertificate)}
            >
              <MediaUpload
                selectedFiles={filesToUpload}
                onFilesChange={handleAttachmentFilesChange}
              />
            </UploadDialogWrapper>
          </MediaUploadWrapper>
        </DocumentWrapperItem>
        <List>
          {companyInfo.businessCertificate?.map((attachment, index) => {
            return (
              <Item key={index}>
                <VectorWrapper>
                  <VectorIcon />
                </VectorWrapper>
                {attachment.name}
                <CloseUploadWrapper
                  onClick={() =>
                    handleRemoveClick(
                      attachment.id,
                      DocumentEnum.businessCertificate
                    )
                  }
                >
                  <CrossIcon />
                </CloseUploadWrapper>
              </Item>
            );
          })}
        </List>
      </DocumentConainer>





      <DocumentConainer>
        <DocumentWrapperItem>
          <DocumentWrapper>
            <DocumentName>Tax status</DocumentName>
            <RedStar>*</RedStar>
            <TooltipWrapper
              onMouseEnter={() => setShowTaxTooltip(true)}
              onMouseLeave={() => setShowTaxTooltip(false)}
            >
              <ShieldQuestionIcon />
            </TooltipWrapper>
            {showTaxTooltip && (
              <TooltipTax>
                Government-certified tax identification form.
              </TooltipTax>
            )}
          </DocumentWrapper>
          <MediaUploadWrapper>
            <UploadDialogWrapper
              onClick={() => setDocumentType(DocumentEnum.taxStatus)}
            >
              <MediaUpload
                selectedFiles={filesToUpload}
                onFilesChange={handleAttachmentFilesChange}
              />
            </UploadDialogWrapper>
          </MediaUploadWrapper>
        </DocumentWrapperItem>

        <List>
          {companyInfo.taxStatus?.map((attachment, index) => {
            return (
              <Item key={index}>
                <VectorWrapper>
                  <VectorIcon />
                </VectorWrapper>
                {attachment.name}
                <CloseUploadWrapper
                  onClick={() =>
                    handleRemoveClick(
                      attachment.id,
                      DocumentEnum.taxStatus
                    )
                  }
                >
                  <CrossIcon />
                </CloseUploadWrapper>
              </Item>
            );
          })}
        </List>
      </DocumentConainer>




      <DocumentConainer>
        <DocumentWrapperItem>
          <DocumentWrapper>
            <DocumentName>Proof of address</DocumentName>
            <RedStar>*</RedStar>
            <TooltipWrapper
              onMouseEnter={() => setShowProofAddressTooltip(true)}
              onMouseLeave={() => setShowProofAddressTooltip(false)}
            >
              <ShieldQuestionIcon />
            </TooltipWrapper>
            {showProofAddressTooltip && (
              <TooltipAddress>
                Utility bill or bank statement showing the name and the address
                of your business unit's registered headquarters, office, or
                manufacturing site.
              </TooltipAddress>
            )}
          </DocumentWrapper>
          <MediaUploadWrapper>
            <UploadDialogWrapper
              onClick={() => setDocumentType(DocumentEnum.proofAddress)}
            >
              <MediaUpload
                selectedFiles={filesToUpload}
                onFilesChange={handleAttachmentFilesChange}
              />
            </UploadDialogWrapper>
          </MediaUploadWrapper>
        </DocumentWrapperItem>
        <List>
          {companyInfo.proofAddress?.map((attachment, index) => {
            return (
              <Item key={index}>
                <VectorWrapper>
                  <VectorIcon />
                </VectorWrapper>
                {attachment.name}
                <CloseUploadWrapper
                  onClick={() =>
                    handleRemoveClick(
                      attachment.id,
                      DocumentEnum.proofAddress
                    )
                  }
                >
                  <CrossIcon />
                </CloseUploadWrapper>
              </Item>
            );
          })}
        </List>
      </DocumentConainer>




      <DocumentConainer>
        <DocumentWrapperItem>
          <DocumentWrapper>
            <DocumentName>Proof of bank account</DocumentName>
            <TooltipWrapper
              onMouseEnter={() => setShowBankTooltip(true)}
              onMouseLeave={() => setShowBankTooltip(false)}
            >
              <ShieldQuestionIcon />
            </TooltipWrapper>
            {showBankTooltip && (
              <TooltipBank>
                Bank account statement, mentioning the account number, the name
                and the address of the holder, the bank address and its logo.
              </TooltipBank>
            )}
          </DocumentWrapper>
          <MediaUploadWrapper>
            <UploadDialogWrapper
              onClick={() => setDocumentType(DocumentEnum.proofBank)}
            >
              <MediaUpload
                selectedFiles={filesToUpload}
                onFilesChange={handleAttachmentFilesChange}
              />
            </UploadDialogWrapper>
          </MediaUploadWrapper>
        </DocumentWrapperItem>
        <List>
          {companyInfo.proofBank?.map((attachment, index) => {
            return (
              <Item key={index}>
                <VectorWrapper>
                  <VectorIcon />
                </VectorWrapper>
                {attachment.name}
                <CloseUploadWrapper
                  onClick={() =>
                    handleRemoveClick(
                      attachment.id,
                      DocumentEnum.proofBank
                    )
                  }
                >
                  <CrossIcon />
                </CloseUploadWrapper>
              </Item>
            );
          })}
        </List>
      </DocumentConainer>




      <DocumentConainer>
        <DocumentWrapperItem>
          <DocumentWrapper>
            <DocumentName>Articles of association</DocumentName>
            <TooltipWrapper
              onMouseEnter={() => setShowArticlesooltip(true)}
              onMouseLeave={() => setShowArticlesooltip(false)}
            >
              <ShieldQuestionIcon />
            </TooltipWrapper>
            {showArticlesTooltip && (
              <TooltipArticles>
                Document detailing the business unit's purpose and organization.
              </TooltipArticles>
            )}
          </DocumentWrapper>
          <MediaUploadWrapper>
            <UploadDialogWrapper
              onClick={() => setDocumentType(DocumentEnum.articles)}
            >
              <MediaUpload
                selectedFiles={filesToUpload}
                onFilesChange={handleAttachmentFilesChange}
              />
            </UploadDialogWrapper>
          </MediaUploadWrapper>
        </DocumentWrapperItem>
        <List>
          {companyInfo.articles?.map((attachment, index) => {
            return (
              <Item key={index}>
                <VectorWrapper>
                  <VectorIcon />
                </VectorWrapper>
                {attachment.name}
                <CloseUploadWrapper
                  onClick={() =>
                    handleRemoveClick(
                      attachment.id,
                      DocumentEnum.articles
                    )
                  }
                >
                  <CrossIcon />
                </CloseUploadWrapper>
              </Item>
            );
          })}
        </List>
      </DocumentConainer>




      <DocumentConainer>
        <DocumentWrapperItem>
          <DocumentWrapper>
            <DocumentName>Certificates</DocumentName>
            <RedStar>*</RedStar>
            <TooltipWrapper
              onMouseEnter={() => setShowCertificates(true)}
              onMouseLeave={() => setShowCertificates(false)}
            >
              <ShieldQuestionIcon />
            </TooltipWrapper>


            {showCertificates && (
              <TooltipCertificates>
                Certificates
              </TooltipCertificates>
            )}
          </DocumentWrapper>
          <MediaUploadWrapper>
            <UploadDialogWrapper
              onClick={() => setDocumentType(DocumentEnum.certificates)}
            >
              <MediaUpload
                selectedFiles={filesToUpload}
                onFilesChange={handleAttachmentFilesChange}
              />
            </UploadDialogWrapper>
          </MediaUploadWrapper>
        </DocumentWrapperItem>
        <List>
          {companyInfo.certificates?.map((attachment, index) => {
            return (
              <Item key={index}>
                <VectorWrapper>
                  <VectorIcon />
                </VectorWrapper>
                {attachment.name}
                <CloseUploadWrapper
                  onClick={() =>
                    handleRemoveClick(
                      attachment.id,
                      DocumentEnum.certificates
                    )
                  }
                >
                  <CrossIcon />
                </CloseUploadWrapper>
              </Item>
            );
          })}
        </List>
      </DocumentConainer>



    </Wrapper>
  );
};

export default CompanyDocuments;
