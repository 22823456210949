import { useState } from "react";
import {
  ButtonSave,
  ButtonWrapperLink,
  CompanyWrapperLink,
  ConfirmedWrapper,
  CrossWhiteIconWrapper,
  FeeParagraph,
  FeeWrapper,
  ImageWrapper,
  IndustryParagraph,
  IndustryWrapper,
  KeywordsBlock,
  KeywordsContainer,
  KeywordsParagraph,
  KeywordsWrapper,
  LocationWrapper,
  LogoButtonWrapper,
  NameWrapper,
  Paragraph,
  ParagraphLocation,
  RatingReviewsWrapper,
  SaveParagraph,
  SaveWrapper,
  Up,
  VectorWrapper,
  Wrapper,
  WrapperProvider,
} from "../../PartnerBasePage/PartnerBaseList/style";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import UnionIcon from "../../../assets/icons/UnionIcon";
import VectorYellowIcon from "../../../assets/icons/VectorYellowIcon";
import { renderStars } from "../../CompanyItem/CompanyItem";
import Button from "../../Button/Button";
import { AgentBaseAgent, TypeFee } from "../../../utils/types";
import { defaultAvatar } from "../../../assets/img";
import {
  addDeleteFavoriteAgentAsync,
  getFavoriteAgentsAsync,
} from "../../../store/slices/userSlice";
import CrossWhiteIcon from "../../../assets/icons/CrossWhireIcon";

interface AgentCardProps {
  agent: AgentBaseAgent;
  handleOpenDialog: (agent: AgentBaseAgent) => void;
}

const AgentCardToList = ({ agent, handleOpenDialog }: AgentCardProps) => {
  const user = useAppSelector((state) => state.user?.company);
  const dispatch = useAppDispatch();
  const [showSaveMessage, setShowSaveMessage] = useState(false);
  const [textShowSaveMessage, setTextShowSaveMessage] = useState("");

  // const getButtonStyle = (agentId: number) => {
  //   return user.customerCompany?.favoriteAgents &&
  //     user.customerCompany?.favoriteAgents.some(
  //       (favAgent) => favAgent.id === agentId)
  //     ? { background: "#03B62A", color: "#fff" }
  //     : {};
  // };

  // const handleSaveDeleteButtonClick = async (
  //   id: number,
  //   method: string,
  //   typeFavorite: string
  // ) => {
  //   await dispatch(addDeleteFavoriteAgentAsync({ id, method, typeFavorite }));
  //   method.toLowerCase() === "post"
  //     ? setTextShowSaveMessage("saved")
  //     : setTextShowSaveMessage("deleted");
  //   setShowSaveMessage(true);
  //   await dispatch(getFavoriteAgentsAsync());
  //   setTimeout(() => {
  //     setShowSaveMessage(false);
  //   }, 3000);
  // };

  return (
    <>
      <WrapperProvider>
        {/* {(user.customerCompany || user.providerCompany) && (
          <ButtonSave
            onClick={() => {
              if (
                user.customerCompany?.favoriteAgents &&
                user.customerCompany?.favoriteAgents.some(
                  (favAgent) => favAgent.id === agent.id
                )
              ) {
                handleSaveDeleteButtonClick(agent.id, "delete", "AGENT");
              } else {
                handleSaveDeleteButtonClick(agent.id, "post", "AGENT");
              }
            }}
            style={getButtonStyle(agent.id)}
          >
            {user.customerCompany?.favoriteAgents &&
            user.customerCompany?.favoriteAgents.some(
              (favAgent) => favAgent.id === agent.id
            )
              ? "Delete"
              : "Save"}
          </ButtonSave>
        )} */}
        <CompanyWrapperLink to={`/agents/${agent.id}`} key={agent.id}>
          <LogoButtonWrapper>
            <ImageWrapper>
              <img
                src={agent.avatar || defaultAvatar}
                alt={agent.companyName}
              />
            </ImageWrapper>
          </LogoButtonWrapper>

          <NameWrapper>
            <Paragraph>{agent.firstName}</Paragraph>
            <Paragraph>{agent.lastName}</Paragraph>
            {agent.confirmed && (
               <ConfirmedWrapper>
                  <UnionIcon />
                <VectorWrapper>
                  <VectorYellowIcon />
                </VectorWrapper>
              </ConfirmedWrapper>
            )}
          </NameWrapper>
          <RatingReviewsWrapper>
            {renderStars(Math.min(agent.rating, 5))}({agent.reviews})
          </RatingReviewsWrapper>
          {agent.successRate !== undefined && (
            <Wrapper>
              <Paragraph>Success rate:</Paragraph>
              {agent.successRate}
              {agent.successRate > 0 && <Up>& Up</Up>}
            </Wrapper>
          )}
          {agent.availability && (
            <Wrapper>
              <Paragraph>Availability:</Paragraph>{" "}
              {typeof agent.availability === "string"
                ? (agent.availability as string).charAt(0).toUpperCase() +
                (agent.availability as string)
                  .slice(1)
                  .toLowerCase()
                  .replace(/_/g, " ")
                : "Unknown Availability"}
            </Wrapper>
          )}
          {agent.fee && (
            <FeeWrapper>
              <FeeParagraph>Fee:</FeeParagraph>{" "}
              {agent.fee === TypeFee.HOUR ?
                `${agent.priceHour}$/${TypeFee.HOUR.toLowerCase()}` :
                `${agent.priceHour}$`}
            </FeeWrapper>
          )}
          <LocationWrapper>
            <Paragraph>Location:</Paragraph>{" "}
            <ParagraphLocation>
              {agent.agentContactInfo &&
                `${agent.agentContactInfo.country || ""}, ${agent.agentContactInfo.city || ""
                }`}
            </ParagraphLocation>
          </LocationWrapper>
          <IndustryWrapper>
            <Paragraph>Industry:</Paragraph>
            <IndustryParagraph>
              {agent.category && agent.category.join(", ")}
            </IndustryParagraph>
          </IndustryWrapper>

          <KeywordsBlock>
            <KeywordsParagraph>Keywords:</KeywordsParagraph>
            {agent.keywords && (
              <KeywordsContainer>
                {agent.keywords.map((keyword, index) => (
                  <KeywordsWrapper key={index}>{keyword}</KeywordsWrapper>
                ))}
              </KeywordsContainer>
            )}
          </KeywordsBlock>
        </CompanyWrapperLink>
        <ButtonWrapperLink>
          <Button
            text={"Connect"}
            // disabled={!!user.agent}
            onClick={() => handleOpenDialog(agent)}
          />
        </ButtonWrapperLink>
      </WrapperProvider>

      {showSaveMessage && (
        <SaveWrapper>
          <SaveParagraph>
            {`The selected agent has been successfully ${textShowSaveMessage}.`}
          </SaveParagraph>
          <CrossWhiteIconWrapper onClick={() => setShowSaveMessage(false)}>
            <CrossWhiteIcon />
          </CrossWhiteIconWrapper>
        </SaveWrapper>
      )}
    </>
  );
};

export default AgentCardToList;
