import styled from "styled-components";

export const SortContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  z-index: 2;
`;


export const SortWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: absolute;
  left: 0;
  top: 50px;
  z-index: 2;
`;


export const SortButton = styled.button`
  /* border-radius: 16px; */
  /* border: 1px solid var(--border, #bdbdbd);
  background: var(--wiht, #fff);
  display: flex;
  padding: 14px 40px 16px 30px;
  gap: 10px; */
  color: var(--txt-dark, #001731);
  font-size: 17px;
  font-weight: 500;
  line-height: 140%;
`;

export const ChevronRightSortWrapper = styled.div`
  /* position: relative;
  left: 90px;
  bottom: 43px; */
  cursor: pointer;
`;


export const SortActiveWrapper = styled.div`
  border-radius: 16px;
  border: 1px solid var(--batton, #0095c0);
  background: var(--wiht, #fff);
  display: flex;
  justify-content: center;
  align-items:center;
  padding: 14px 20px 16px 30px;
  flex-direction: column;
`;


export const FilterWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 16px;
  border: 1px solid var(--border, #bdbdbd);
  background: var(--wiht, #fff);
  display: flex;
  padding: 14px 40px 16px 30px;
  gap: 10px;
  color: var(--txt-dark, #001731);
  font-size: 17px;
  font-weight: 500;
  line-height: 140%;
`;


export const SortActiveButton = styled.button`
  background: var(--wiht, #fff);
  color: var(--txt-dark, #001731);
  font-size: 17px;
  font-weight: 500;
  line-height: 140%;
  display: flex;
  align-items: center;
  gap: 10px;
`;


export const ChevronDownSortWrapper = styled.div`
  /* position: absolute;
  left: 70px;
  top: 0px; */
`;


export const IndustryLocationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
`;


export const LocationIndustryButton = styled.button`
  color: var(--txt-dak-light, #143255);
  font-size: 17px;
  font-weight: 400;
  line-height: 140%;
  &:hover {
    color: var(--txt-blue, #0095c0);
  }
`;