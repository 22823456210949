import styled from "styled-components";

export const SearchBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const ParagraphSearch = styled.h2`
  font-size: 38px;
  font-weight: 500;
  line-height: 120%;

  color: var(--txt-dark, #001731);
`;
