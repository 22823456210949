import React from 'react'
import { ItemWrapper, LocationIndustryButton } from '../styles'
import CircleSwitch from '../../CircleSwitch/CircleSwitch'

interface Props {
    role: string;
    newRole: string;
    handleChangeRole: (role: string) => Promise<void>;
    handleCleanedRole: (originalRole: string) => string;
}

const SelectItemRole = ({role, newRole, handleChangeRole, handleCleanedRole}: Props) => {
    return (
        <ItemWrapper key={role}>
            <CircleSwitch
                isActive={newRole.includes(role)}
                onToggle={() => handleChangeRole(role)}
            />
            <LocationIndustryButton onClick={() => handleChangeRole(role)}>
                {handleCleanedRole(role)}
            </LocationIndustryButton>
        </ItemWrapper>
    )
}

export default SelectItemRole