import React from "react";

const HelpIcon: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Icons/badge-info01">
        <path
          id="Vector"
          d="M3.84999 8.61998C3.70404 7.9625 3.72645 7.27882 3.91515 6.63231C4.10386 5.98581 4.45274 5.39742 4.92946 4.9217C5.40618 4.44597 5.9953 4.09832 6.6422 3.91097C7.2891 3.72362 7.97283 3.70264 8.62999 3.84998C8.9917 3.28428 9.49 2.81873 10.0789 2.49626C10.6679 2.17379 11.3285 2.00476 12 2.00476C12.6714 2.00476 13.3321 2.17379 13.921 2.49626C14.51 2.81873 15.0083 3.28428 15.37 3.84998C16.0282 3.702 16.7131 3.72288 17.361 3.91069C18.0089 4.09849 18.5988 4.44712 19.0758 4.92413C19.5529 5.40114 19.9015 5.99105 20.0893 6.63898C20.2771 7.28691 20.298 7.97181 20.15 8.62998C20.7157 8.99168 21.1812 9.48998 21.5037 10.0789C21.8262 10.6679 21.9952 11.3285 21.9952 12C21.9952 12.6714 21.8262 13.3321 21.5037 13.921C21.1812 14.51 20.7157 15.0083 20.15 15.37C20.2973 16.0271 20.2764 16.7109 20.089 17.3578C19.9017 18.0047 19.554 18.5938 19.0783 19.0705C18.6026 19.5472 18.0142 19.8961 17.3677 20.0848C16.7212 20.2735 16.0375 20.2959 15.38 20.15C15.0188 20.7178 14.5201 21.1854 13.9301 21.5093C13.3402 21.8332 12.678 22.003 12.005 22.003C11.332 22.003 10.6698 21.8332 10.0799 21.5093C9.48992 21.1854 8.99123 20.7178 8.62999 20.15C7.97283 20.2973 7.2891 20.2763 6.6422 20.089C5.9953 19.9016 5.40618 19.554 4.92946 19.0783C4.45274 18.6025 4.10386 18.0141 3.91515 17.3676C3.72645 16.7211 3.70404 16.0374 3.84999 15.38C3.27995 15.0192 2.81041 14.5201 2.48504 13.9292C2.15968 13.3382 1.98906 12.6746 1.98906 12C1.98906 11.3254 2.15968 10.6617 2.48504 10.0708C2.81041 9.4798 3.27995 8.98073 3.84999 8.61998Z"
          stroke="#001731"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M12 16V12"
          stroke="#001731"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_3"
          d="M12 8H12.01"
          stroke="#001731"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default HelpIcon;
