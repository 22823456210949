import React, { useEffect, useRef, useState } from "react";
import EmojiPicker, { EmojiClickData } from "emoji-picker-react";
import { v4 as uuidv4 } from "uuid";
import { onValue, ref } from "firebase/database";
import {
  AttachmentsWrapper,
  ButtonBottomContainer,
  ButtonBottomWrapper,
  ButtonText,
  ButtonWrapper,
  Container,
  IconButtonWrapper,
  RequestContainer,
  SendMessageConainer,
  Star,
  SubTitle,
  SubtitleWrapper,
  TitleWrapper,
  UserMessageInput,
  MessageContainer,
  MessageList,
  MediaUploadWrapper,
  UploadLoadingWrapper,
  UploadLoadingText,
  OrderRequestWrapper,
  OrderWrapper,
  TitleOrder,
  SubOrderWrapper,
  ParagraphWrapper,
  NameParagraph,
  AnswerParagraph,
  OrderStatusWrapper,
  CheckIconWrapper,
  OrderCheckMark,
  OrderStatusContentWrapperContainer,
  OrderStatusContentWrapper,
  OrderContentDateComponent,
  CreateOrderButtonWrapper,
  ButtonCreateWrapper,
  NotActiveOrderCheckMark,
  NotActiveOrderStatusContentWrapper,
  LeftWrapper,
  Overlay,
  DialogContent,
  Tittle,
  ConnectWrapper,
  NameLogoWrapper,
  Avatar,
  ConnectParagraph,
  YourMessageWrapper,
  ButtonCancelSendWrapper,
  CancelButton,
  ButtonSendWrapper,
  MessageArea,
  NameMessageArea,
  UserMessageWrapper,
  EmojiWrapper,
  Anchor,
} from "./styles";
import TitleHeader from "../TitleHeader/TitleHeader";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
// import { routes } from "../../routes/routes";
import {
  // createOrderAsync,
  getDialogueByIdAsync,
  // getOrderByIdAsync,
  SendMessageAsync,
  uploadFilesToServer,
} from "../../store/slices/userSlice";
import { RootState } from "../../store/store";
import {
  Attachment,
  // ChatMessageResponse,
  // CompanyById,
  ConnectionData,
  Message,
  ModifiedFileObject,
  // Order,
  // OrderById,
  // OrderCreate,
  // OrderForCreate,
  // PartnerBaseProvider,
  Requst,
} from "../../utils/types";
import CallIcon from "../../assets/icons/CallIcon";
import VideoCallIcon from "../../assets/icons/VideoCallIcon";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import Button from "../Button/Button";
import ButtonWhite from "../ButtonWhite/ButtonWhite";
import DialogueMessages from "./DialogueMessages/DialogueMessages";
import CompanionsNames from "./CompanionsNames/CompanionsNames";
import CheckIcon from "../../assets/icons/CheckIcon";
import ArrowDownIcon from "../../assets/icons/ArrowDownIcon";
import { defaultAvatar } from "../../assets/img";
import { RedStar } from "../PartnerBasePage/PartnerBaseList/style";
import EmojiIcon from "../../assets/icons/EmojiIcon";
import { db } from "../../utils/firebase";
import {
  CloseUploadWrapper,
  Item,
  List,
  VectorWrapper,
} from "../CompanyDocuments/styles";
import VectorIcon from "../../assets/icons/VectorIcon";
import CrossIcon from "../../assets/icons/CrossIcon";
import MediaUpload from "../MediaUpload/MediaUpload";

const Dialogue: React.FC = () => {
  const dispatch = useAppDispatch();
  const store = useSelector((state: RootState) => state.user.dialogueById);
  const user = useAppSelector((state: RootState) => state.user);
  const allOrders = useAppSelector((store: RootState) => store.user.orders);
  const [dialog, setDialog] = useState(store);
  const [message, setMessage] = useState("");
  // const [messages, setMessages] = useState<Message[]>([]);
  const [chatMessages, setChatMessages] = useState<Message[]>([]);
  // const [connected, setConnected] = useState<boolean>(false);
  // const [stompClient, setStompClient] = useState<any>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [attachments, setAttachments] = useState<Attachment[]>([]);
  const [uploading, setUploading] = useState(false);
  const [filesToUpload, setFilesToUpload] = useState<File[]>([]);
  const endRef = useRef<HTMLDivElement | null>(null);
  const [connectionData, setConnectionData] = useState<ConnectionData>({
    typeRequestTo: "",
    id: "",
    text: "",
    files: [],
  });
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const { id } = useParams(); // requestId
  const token = useAppSelector((store) => store.user.userAccount?.token!);
  const [name, setName] = useState("");
  const [technicalSpecifications, setTechnicalSpecifications] = useState("");
  const requests: Requst[] = useAppSelector(
    (state) => state.user.requests?.content || []
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(getDialogueByIdAsync({ id: +id! }) as any);
      } catch (error) {
        console.error(error);
      }
      // connect();
    };
    fetchData();
  }, [dispatch, id]);
  /// запрос надо переделать, чтообы присылал все ордера по Id чата(компании)
  // useEffect(() => {
  //   const fetchOrder = async () => {
  //     if (store?.orders) {
  //       try {
  //         await dispatch(
  //           getOrderByIdAsync({ id: store?.orders, token: token }) as any
  //         );
  //       } catch (error) {
  //         console.error(error);
  //       }
  //     }
  //   };
  //   fetchOrder();
  // }, [store?.orders]);

  useEffect(() => {
    setDialog(store);
  }, [store]);

  // let isConnecting = false;
  // const connect = () => {
  //   if (isConnecting) {
  //     return;
  //   }
  //   isConnecting = true;
  //   const socket = new SockJS(`${base_url}/ws`);
  //   const client = Stomp.over(socket);
  //   client.connect({}, () => {
  //     setConnected(true);
  //     setStompClient(client);
  //     client.subscribe(`/request/chat/${id}`, ({ body }: IMessage) => {
  //       const message = JSON.parse(body) as ChatMessageResponse;
  //       if (message && message.body) {
  //         setMessages(
  //           (prevMessages) => [...prevMessages, message.body] as Message[]
  //         );
  //       }
  //     });
  //   });
  // };

  // const sendMessage = async () => {
  //   if (connected && (message || filesToUpload?.length > 0)) {
  //     const uploadedFiles = await handleFileChange(filesToUpload);
  //     const messagesBusinessRequest = { text: message, files: uploadedFiles };
  //     stompClient.send(
  //       `/app/chat/${id}/${user.userAccount!.id}`,
  //       {},
  //       JSON.stringify(messagesBusinessRequest)
  //     );
  //     setMessage("");
  //     setFilesToUpload([]);
  //   } else {
  //     alert("Please enter a message or wait for the connection to establish.");
  //   }
  // };

  const sendMessage = () => {
    dispatch(
      SendMessageAsync({
        id: uuidv4(),
        text: message,
        dateCreated: new Date().toISOString(),
        ownerId: user.userAccount?.id!,
        orderId: 0,
        email: user.userAccount?.email!,
        chatId: id!,
        files: attachments,
      })
    );
    setMessage("");
    setAttachments([]);
  };

  // const handleFileChange = async (filesArray: File[]) => {
  //   if (filesArray.length <= 0) {
  //     return;
  //   }
  //   setUploading(true);
  //   setFilesToUpload(filesArray);
  //   const saveFiles = await dispatch(
  //     uploadFilesToServer({ files: filesArray }) as any
  //   );

  //   if (saveFiles.payload.message === "400") {
  //     saveFiles.payload = [];
  //   }
  //   const saveFilesPayload: Attachment[] = saveFiles.payload;
  //   const modifiedFilesArray: ModifiedFileObject[] = saveFilesPayload.map(
  //     (obj) => {
  //       const { link, name } = obj;
  //       return { link, name };
  //     }
  //   );
  //   setConnectionData({
  //     ...connectionData,
  //     files: modifiedFilesArray || [],
  //   } as any);
  //   setUploading(false);

  //   return saveFilesPayload;
  // };

  const handleAttachmentFilesChange = async (files: File[]) => {
    try {
      const saveFiles: any = await dispatch(
        uploadFilesToServer({ files: files }) as any
      );
      setAttachments((prev) => [...prev, ...saveFiles.payload]);
    } catch (error) {
      console.error(error);
    }
  };

  const handleOpenDialog = () => {
    setIsOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setIsOpenDialog(false);
  };

  // const handleSendButtonClick = async () => {
  //   const orderMessage = `Order name: ${name}\n Technical specifications: ${technicalSpecifications}`;
  //   try {
  //     const { id: idOrder } = await dispatch(
  //       createOrderAsync({ order, token }) as any
  //     ).unwrap();

  //     if (connected) {
  //       const messagesOrderRequest = {
  //         text: orderMessage,
  //         files: [],
  //         orderId: idOrder,
  //       };
  //       stompClient.send(
  //         `/app/chat/${id}/${user.userAccount!.id}`,
  //         {},
  //         JSON.stringify(messagesOrderRequest)
  //       );
  //     } else {
  //       alert(
  //         "Please enter a message or wait for the connection to establish."
  //       );
  //     }
  //   } catch (e) {
  //     console.log(e);
  //   }
  //   setIsOpenDialog(false);
  //   setName("");
  //   setTechnicalSpecifications("");
  // };

  const matchingRequest = requests?.find(
    (request: { id: number }) => request.id === +id!
  );

  const findOrdersById = allOrders?.filter((order) =>
    store?.orders.includes(order.id)
  );

  // const order: OrderForCreate = {
  //   businessRequestId: +id!,
  //   products: [
  //     {
  //       name: name,
  //       technicalSpecifications: technicalSpecifications.split("\n"),
  //     },
  //   ],
  // };

  const handleEmojiClick = (e: EmojiClickData) => {
    setMessage((prev) => prev + e.emoji);
    setOpen(false);
  };

  const handleRemoveClick = (index: number) => {
    setAttachments((prev) => prev.filter((_, i) => i !== index));
  };

  useEffect(() => {
    endRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [chatMessages]);

  useEffect(() => {
    if (id) {
      const messagesRef = ref(db, `chats/${id}/messages`);
      const unsubscribe = onValue(messagesRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const messagesArray: Message[] = Object.values(data);
          setChatMessages(messagesArray);
        } else {
          setChatMessages([]);
        }
      });
      return () => unsubscribe();
    }
  }, [id, db]);

  return (
    <Container>
      <OrderRequestWrapper>
        <LeftWrapper>
          <TitleWrapper>
            <TitleHeader text={"Business requests"} />
          </TitleWrapper>
          <ButtonWrapper>
            <IconButtonWrapper>
              <ButtonText>Call</ButtonText>
              <CallIcon />
            </IconButtonWrapper>
            <IconButtonWrapper>
              <ButtonText>Video call</ButtonText>
              <VideoCallIcon />
            </IconButtonWrapper>
            {/* <IconButtonWrapper>
              <ButtonText>Order status</ButtonText>
            </IconButtonWrapper> */}
          </ButtonWrapper>
          <RequestContainer>
            <MessageList>
              {chatMessages &&
                chatMessages?.map(
                  ({ text, id, files, dateCreated, ownerId, orderId }) => (
                    <MessageContainer key={id}>
                      <CompanionsNames
                        side={
                          ownerId === user.userAccount?.id ? "right" : "left"
                        }
                        userInfo={dialog}
                      />
                      <DialogueMessages
                        text={text}
                        files={files}
                        dateCreated={dateCreated}
                        side={
                          ownerId === user.userAccount?.id ? "right" : "left"
                        }
                        ownerId={ownerId}
                        currentId={user.userAccount?.id ?? 0}
                        orderId={orderId}
                        dialogueOrders={findOrdersById}
                      />
                    </MessageContainer>
                  )
                )}

              {/* {dialog?.messages.length > 0 &&
                dialog?.messages.map(
                  ({
                    text,
                    id,
                    files,
                    dateCreated,
                    ownerId,
                    orderId,
                  }: Message) => (
                    <MessageContainer key={id}>
                      <CompanionsNames
                        side={
                          ownerId === user.userAccount?.id ? "right" : "left"
                        }
                        userInfo={dialog}
                      />
                      <DialogueMessages
                        text={text}
                        files={files}
                        dateCreated={dateCreated}
                        side={
                          ownerId === user.userAccount?.id ? "right" : "left"
                        }
                        ownerId={ownerId}
                        currentId={user.userAccount?.id ?? 0}
                        orderId={orderId}
                        dialogueOrders={findOrdersById}
                      />
                    </MessageContainer>
                  )
                )}
              {messages.length > 0 &&
                messages.map(
                  ({ text, id, files, dateCreated, ownerId, orderId }) => (
                    <React.Fragment key={id}>
                      <CompanionsNames
                        side={
                          ownerId === user.userAccount?.id ? "right" : "left"
                        }
                        userInfo={dialog}
                      />
                      <DialogueMessages
                        text={text}
                        files={files}
                        dateCreated={dateCreated}
                        side={
                          ownerId === user.userAccount?.id ? "right" : "left"
                        }
                        ownerId={ownerId}
                        currentId={user.userAccount?.id ?? 0}
                        orderId={orderId}
                        dialogueOrders={findOrdersById}
                      />
                    </React.Fragment>
                  )
                )} */}
              <Anchor ref={endRef}></Anchor>
            </MessageList>
            <SendMessageConainer>
              <SubtitleWrapper>
                <SubTitle>Your message</SubTitle>
                <Star>*</Star>
              </SubtitleWrapper>
              <UserMessageWrapper>
                <UserMessageInput
                  placeholder="Text..."
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      sendMessage();
                    }
                  }}
                />
                <EmojiIcon onClick={() => setOpen((prev) => !prev)} />
                <EmojiWrapper>
                  <EmojiPicker open={open} onEmojiClick={handleEmojiClick} />
                </EmojiWrapper>
              </UserMessageWrapper>
              <AttachmentsWrapper>
                <MediaUploadWrapper>
                  <MediaUpload
                    selectedFiles={filesToUpload}
                    onFilesChange={handleAttachmentFilesChange}
                  />
                </MediaUploadWrapper>
                <ButtonBottomContainer>
                  <ButtonBottomWrapper>
                    <ButtonWhite text={"Cancel"} />
                  </ButtonBottomWrapper>
                  <Button
                    onClick={sendMessage}
                    text={user.status === "loading" ? "loading..." : "Send"}
                    disabled={user.status === "loading" || !message && attachments.length === 0}
                  />
                </ButtonBottomContainer>
              </AttachmentsWrapper>
              <List>
                {attachments.length > 0 &&
                  attachments.map((attachment, index) => {
                    return (
                      <Item key={index}>
                        <VectorWrapper>
                          <VectorIcon />
                        </VectorWrapper>
                        {attachment.name}
                        <CloseUploadWrapper
                          onClick={() => handleRemoveClick(index)}
                        >
                          <CrossIcon />
                        </CloseUploadWrapper>
                      </Item>
                    );
                  })}
              </List>
            </SendMessageConainer>
          </RequestContainer>
        </LeftWrapper>
        <OrderWrapper>
          <TitleOrder>Create your order</TitleOrder>
          <SubOrderWrapper>
            <ParagraphWrapper>
              <NameParagraph>Order number:</NameParagraph>
              <AnswerParagraph>№ FA-853-38433</AnswerParagraph>
            </ParagraphWrapper>
            <ParagraphWrapper>
              <NameParagraph>Vendor:</NameParagraph>
              <AnswerParagraph>Electro Solutions</AnswerParagraph>
            </ParagraphWrapper>
          </SubOrderWrapper>
          <OrderStatusWrapper>
            <OrderCheckMark>
              <CheckIconWrapper>
                <CheckIcon />
              </CheckIconWrapper>
            </OrderCheckMark>
            <OrderStatusContentWrapperContainer>
              <OrderStatusContentWrapper>
                {/* {statusName[s.type]} */}
                Create order
              </OrderStatusContentWrapper>
              <OrderContentDateComponent>23 May 2024</OrderContentDateComponent>
            </OrderStatusContentWrapperContainer>
          </OrderStatusWrapper>
          <CreateOrderButtonWrapper>
            <ArrowDownIcon />
            <ButtonCreateWrapper onClick={handleOpenDialog}>
              <Button text="Create order" />
            </ButtonCreateWrapper>
          </CreateOrderButtonWrapper>
          <OrderStatusWrapper>
            <NotActiveOrderCheckMark />
            <OrderStatusContentWrapperContainer>
              <NotActiveOrderStatusContentWrapper>
                {/* {statusName[s.type]} */}
                Documents signed
              </NotActiveOrderStatusContentWrapper>
            </OrderStatusContentWrapperContainer>
          </OrderStatusWrapper>
          <OrderStatusWrapper>
            <NotActiveOrderCheckMark />
            <OrderStatusContentWrapperContainer>
              <NotActiveOrderStatusContentWrapper>
                {/* {statusName[s.type]} */}
                Order manufactored
              </NotActiveOrderStatusContentWrapper>
            </OrderStatusContentWrapperContainer>
          </OrderStatusWrapper>
          <OrderStatusWrapper>
            <NotActiveOrderCheckMark />
            <OrderStatusContentWrapperContainer>
              <NotActiveOrderStatusContentWrapper>
                {/* {statusName[s.type]} */}
                Order send
              </NotActiveOrderStatusContentWrapper>
            </OrderStatusContentWrapperContainer>
          </OrderStatusWrapper>
          <OrderStatusWrapper>
            <NotActiveOrderCheckMark />
            <OrderStatusContentWrapperContainer>
              <NotActiveOrderStatusContentWrapper>
                {/* {statusName[s.type]} */}
                Order received & accepted
              </NotActiveOrderStatusContentWrapper>
            </OrderStatusContentWrapperContainer>
          </OrderStatusWrapper>
        </OrderWrapper>
      </OrderRequestWrapper>
      {isOpenDialog && <Overlay onClick={handleCloseDialog} />}
      {isOpenDialog && (
        <>
          <Overlay onClick={handleCloseDialog} />
          <DialogContent>
            <ConnectWrapper>
              <Tittle>Create order</Tittle>
              <NameLogoWrapper>
                <Avatar src={matchingRequest?.avatar || defaultAvatar} />
                <ConnectParagraph>
                  {matchingRequest?.name.replace(/([a-z])([A-Z])/g, "$1 $2")}
                </ConnectParagraph>
                {/* <ConnectParagraph>
                    {matchingRequest?.industry}
                  </ConnectParagraph> */}
              </NameLogoWrapper>

              <YourMessageWrapper>
                <ConnectParagraph>Order name</ConnectParagraph>
                <RedStar>*</RedStar>
              </YourMessageWrapper>
              <NameMessageArea
                name="Order name"
                id="Order name"
                cols={30}
                rows={10}
                placeholder="Enter your order name"
                autoFocus
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <YourMessageWrapper>
                <ConnectParagraph>Technical specifications</ConnectParagraph>
                <RedStar>*</RedStar>
              </YourMessageWrapper>
              <MessageArea
                name="technical specifications"
                id="technical specifications"
                cols={30}
                rows={10}
                placeholder="Enter your technical specifications"
                autoFocus
                value={technicalSpecifications}
                onChange={(e) => setTechnicalSpecifications(e.target.value)}
              />
              {/* <MediaUploadWrapper>
                {uploading ? (
                  <div>Loading files...</div>
                ) : (
                  <MediaUpload
                    selectedFiles={filesToUpload}
                    onFilesChange={handleAttachmentFilesChange}
                  />
                )}
              </MediaUploadWrapper> */}
              <ButtonCancelSendWrapper>
                <CancelButton onClick={handleCloseDialog}>Cancel</CancelButton>
                <ButtonSendWrapper>
                  <Button
                    text={"Send"}
                    // onClick={handleSendButtonClick}
                    disabled={uploading}
                  />
                </ButtonSendWrapper>
              </ButtonCancelSendWrapper>
            </ConnectWrapper>
          </DialogContent>
        </>
      )}
    </Container>
  );
};

export default Dialogue;
