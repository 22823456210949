import styled from "styled-components";


export const CloseUploadWrapper = styled.div`
  display: flex;
  margin-left: 8px;
  align-items: center;
  cursor: pointer;
`;

export const UploadDialogWrapper = styled.div`
`;

export const Wrapper = styled.div`
  max-width: 705px;
  width: 100%;

  padding-top: 6px;
`;

export const MainTitleWrapper = styled.div`
  position: relative;
  display: flex;
  margin-bottom: 18px;
`;

export const TitleWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  margin-bottom: 4px;
`;

export const TooltipWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 14px;
  cursor: pointer;
`;

const BaseTooltip = styled.h5`
  position: absolute;
  top: 0;
  display: flex;

  flex-direction: column;
  align-items: flex-start;
  gap: 10px;

  padding: 20px;

  line-height: 140%;
  white-space: wrap;

  border-radius: 16px;
  border: 1px solid var(--border, #bdbdbd);
  color: var(--txt-dark-light, #143255);
  background: var(--wiht, #fff);
  transform: translate(128px, 5%);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  z-index: 1;
`;

export const TooltipDocuments = styled(BaseTooltip)`
  left: 55px;
  max-width: 352px;
`;

export const TooltipBisCertificate = styled(BaseTooltip)`
  left: 180px;
  max-width: 314px;
`;

export const TooltipCertificates = styled(BaseTooltip)`
  left: 20px;
  max-width: 314px;
`;

export const TooltipTax = styled(BaseTooltip)`
  left: 10px;
  max-width: 245px;
`;

export const TooltipAddress = styled(BaseTooltip)`
  left: 60px;
  max-width: 375px;
`;

export const TooltipBank = styled(BaseTooltip)`
  left: 100px;
  max-width: 345px;
`;

export const TooltipArticles = styled(BaseTooltip)`
  left: 95px;
  max-width: 310px;
`;

export const DocumentConainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  padding: 0px 10px 10px;
  margin-bottom: 20px;

  border-bottom: 1px solid var(--border, #bdbdbd);
  background: var(--wiht, #fff);
`;

export const DocumentWrapperItem = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const DocumentWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const DocumentName = styled.h5`
  color: var(--txt-dark-light, #143255);
`;

export const RedStar = styled.p`
  font-size: 17px;
  font-weight: 400;
  line-height: 140%;

  color: var(--txt-red, #f00);
`;

export const MediaUploadWrapper = styled.div`
  display: block;
  max-width: 153px;
  width: 100%;
`;

export const UploadButtonWrapper = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  padding: 14px 30px 16px 30px;
  margin-bottom: 20px;

  font-size: 17px;
  font-weight: 400;
  line-height: 140%;

  border-radius: 16px;
  border: 1px dashed var(--green, #03b62a);
  background: var(--wiht, #fff);

  cursor: pointer;
`;

export const List = styled.ul`
  display: flex;
  flex-direction: column;
`;

export const Item = styled.li`
  display: flex;
  align-items: center;

 margin-bottom: 10px;

  font-size: 18px;
  line-height: 140%;

  color: var(--txt-dak-light, #143255);
`;

export const VectorWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 18px;
  padding: 5px 4px;
  margin-right: 8px;

  border-radius: 16px;
  background-color: var(--green, #03b62a);
`;
