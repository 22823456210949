
import styled from "styled-components";

export const DialogContent = styled.div`
  display: flex;
  align-items: flex-start;
  position: fixed;
  flex-direction: column;
  top: 50%;
  left: 85%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  z-index: 3  ;
  max-width: 600px;
  width: 100%;
  height: 100%;
`;

export const DialogContentHeader = styled.div`
  position: absolute;
  width: 368px;
  height: 46px;
  left: 30px;
  right: 202px;
  top: 30px;
  bottom: 1004px;
    color: var(--txt-dark, #001731);
  font-family: Montserrat;
  font-size: 38px;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: 0%;
  text-align: left;
`;

export const DialogContentHeaderFont = styled.h2`
 
 
`;

export const DialogContentWrapper = styled.div`
  margin-top: 70px;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
`;

export const DialogContentWrapper1 = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  border-bottom: 1px solid var(--border, #979797);
  padding-bottom: 10px;
  width: 87%;
  
`;

export const DialogContentLeftHeader = styled.p`
  color: var(--txt-light-blue-dont-active, #A0B7D1);
  margin-right: 5px;
`;

export const DialogContentValue = styled.p`
  color: var(--txt-dark-light, #143255);
  margin-right: 10px;
  
`;

export const DialogContentCompanyComponent = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 16px;
  color: var(--txt-dark, #001731);
  font-size: 17px;
  font-weight: 400;
  line-height: 140%;
  white-space: nowrap;
  margin-top: 30px;
  width: 100%;
`;

export const DialogContentDateComponent = styled.p`
  /* display: flex; */
  padding-right: 16px;
  color: var(--txt-light-blue-dont-active, #A0B7D1);
  font-size: 14px;
  font-weight: 500;
  line-height: 140%;
  white-space: nowrap;
  text-align: end;
  
`;

export const DialogCheckMark = styled.div<{ backgroundColor: string, borderColor: string }>`
  width: 40px;
  height: 40px;
  border: 1px solid ${props => props.borderColor};
  border-radius: 30px;
  background-color: ${props => props.backgroundColor};
  margin-right: 10px;
`;

export const DialogStatusWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  /* display: grid;
  grid-template-columns: 0.5fr 3fr; */
  width: 80%;
  
`;

export const DialogStatusContentWrapper = styled.p`
  margin-right: 10px;
  color: var(--txt-dark, #001731);
  font-size: 17px;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: 0%;
  text-align: center;
`;

export const DialogStatusContentWrapperContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 85%;
  

`;

export const ConnectAgentButton = styled.div`
  width: 192px;
  margin-top: 15px;
  margin-bottom: 25px;
  margin-left: 30px;
  padding: 16px 30px 16px 30px;
  font-size: 17px;
  line-height: 140%;
  border-radius: 16px;
  background: var(--batton, #0095c0);
  color: var(--txt-light-blue, #f4ffff);
  
  &:hover {
    background: var(--hover, linear-gradient(254deg, #0095c0 17.54%, rgba(3, 182, 42, 0.6) 90.58%));
    cursor: pointer;
  }

  &:active {
    background: var(--green, #03b62a);
  }

  &:disabled {
    background: grey;
    cursor: not-allowed;
  }
`;

export const ConnectAgentButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 20px;

`;


export const ConfirmAcceptanceButton = styled.div`
  width: 420px;
  height: 64px;
  border-radius: 16px;
  margin: 0 auto;
  text-align: center;
  padding: 14px 60px 14px 80px;
  background: var(--green, #03b62a);
  color: var(--txt-light-blue, #f4ffff);
  position: absolute;
  top: 90%;
  font-size: 24px;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: 0%;
  cursor: pointer;
`;

export const RepeatOrderButton = styled.div`
  position: absolute;
  top: 71%;
  width: 400px;
  border-radius: 16px;
  text-align: center;
  background: var(--batton, #0095c0);
  color: var(--txt-light-blue, #f4ffff);
  padding: 16px 80px 16px 80px;
  margin: 0 auto;
  font-size: 24px;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: 0%;
  height: 64px;
  cursor: pointer;
  &:hover {
    background: var(--hover, linear-gradient(254deg, #0095c0 17.54%, rgba(3, 182, 42, 0.6) 90.58%));
    cursor: pointer;
  }

  &:active {
    background: var(--green, #03b62a);
  }

  &:disabled {
    background: grey;
    cursor: not-allowed;
  }
`;

export const ArrivingDateButton = styled.div`
  position: absolute;
  top: 79%;
  justify-content: center;
  width: 420px;
  border-radius: 16px;
  height: 64px;
  font-size: 24px;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: 0%;
  background: linear-gradient(78.41deg, rgb(253, 220, 48) 10.518%,rgb(242, 247, 0) 87.418%);
  display: flex;
  flex-direction: row;
  padding: 16px 40px 16px 40px;
  margin: 0 auto;
`;

export const CheckIconWrapper = styled.p`
  display: flex;
  justify-content: center;
  padding-top: 10px;
`;

export const ArrowDownIconSpecial = styled.div`
  margin-left: 19px;
`;