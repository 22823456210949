import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Container,
  Subtitle,
  TitleWrapper,
  URLWrapper,
  Notification,
  ButtonWrapper,
  Paragraph,
  LinkText,
  FixedButtonWrapper,
  ContactFactory,
  FbContainer,
  OuterContainer,
} from "./style";
import ReturnButton from "../../ReturnButton/ReturnButton";
import Button from "../../Button/Button";
import { useDispatch } from "react-redux";
import { getFetchUrl, trackClick } from "../../../api/internetSearch";
import { routes } from "../../../routes/routes";
import { useAppSelector } from "../../../store/hooks";

declare global {
  interface Window {
    FB: any;
  }
}

const URLContainer: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const iframeUrl = new URLSearchParams(location.search).get("url");
  const [showNotification, setShowNotification] = useState(false);
  const [timeSpent, setTimeSpent] = useState<number | null>(null);
  const token = useAppSelector((state) => state.user.userAccount?.token);
  const navigate = useNavigate();
  const fetchUrl = useAppSelector(
    (state) => state.internetSearch.fetchUrl?.body
  );

  useEffect(() => {
    const startTime = Date.now();

    return () => {
      const endTime = Date.now();
      const timeSpent = endTime - startTime;
      setTimeSpent(timeSpent);
      if (iframeUrl !== null) {
        const data = {
          url: iframeUrl,
          timeSpent: timeSpent,
        };
        dispatch(trackClick(data.url, data.timeSpent) as any);
      }
    };
  }, []);

  useEffect(() => {
    if (window.FB) {
      window.FB.XFBML.parse();
    } else {
      (function (d, s, id) {
        var js: HTMLScriptElement,
          fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s) as HTMLScriptElement;
        js.id = id;
        js.src =
          "https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v10.0";
        if (fjs && fjs.parentNode) {
          fjs.parentNode.insertBefore(js, fjs);
        }
      })(document, "script", "facebook-jssdk");
    }
  }, [fetchUrl]);

  const navigateToDelegate = () => {
    navigate(routes.DELEGATESEARCH, { state: location.state });
  };

  return (
    <Container>
      <TitleWrapper>
        <Subtitle>Factory website</Subtitle>
      </TitleWrapper>
      {iframeUrl && iframeUrl.includes("facebook") ? (
        <FbContainer
          className="fb-page"
          data-href={iframeUrl} 
          data-tabs="timeline"
          data-width="500" 
          data-height="1000" 
          data-small-header="false"
          data-adapt-container-width="true"
          data-hide-cover="false"
          data-show-facepile="true"
        />
      ) : iframeUrl && iframeUrl.includes("instagram") ? (
        <FbContainer
          className="ig-page"
          data-href={iframeUrl} 
          data-width="500" 
          data-height="1000"
          data-hide-cover="false"
          data-show-facepile="true"
        />
      ) : (
        <URLWrapper
          as="iframe"
          id="iframe-content"
          srcDoc={fetchUrl}
          title="Factory Website"
        />
      )}
      <FixedButtonWrapper onClick={navigateToDelegate}>
        <ContactFactory>Contact the Factory</ContactFactory>
      </FixedButtonWrapper>
    </Container>
  );
};

export default URLContainer;
