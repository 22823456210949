import React from "react";
import ProductsInput from "../components/ProductsPage/ProductsInput/ProductsInput";
import ProductsList from "../components/ProductsPage/ProductsList/ProductsList";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const ProfileProducts: React.FC = () => {
  return (
    <Container>
      <ProductsInput placeholderValue="Search by product name " />
      <ProductsList />
    </Container>
  );
};

export default ProfileProducts;
