import React, {useState} from 'react'
import {
    CrossWhiteIconWrapper,
    RedCrossButton,
    SnackbarParagraph,
    SnackbarWrapper,
    SortActiveTitleWrapper,
    SortActiveWrapper,
    SortButton,
    SortWrapper,
    UserAvatar,
    UserEmail,
    UserInformationWrapper,
    UserJobTitle,
    UserName,
    UserWrapper
} from '../styles'
import {CompanyUsers, UserRoles} from '../../../utils/types'
import {defaultAvatar} from '../../../assets/img'
import ChevronRightIcon from '../../../assets/icons/ChevronRightIcon';
import ChevronDownIcon from '../../../assets/icons/ChevronDownIcon';
import {useAppDispatch, useAppSelector} from '../../../store/hooks';
import {updateUserRoleAsync} from '../../../store/slices/userSlice';
import {RootState} from '../../../store/store';
import CrossWhiteIcon from '../../../assets/icons/CrossWhireIcon';
import SelectItemRole from '../SelectItemRole/SelectItemRole';
import CrossIcon from '../../../assets/icons/CrossIcon';
import ModalLostChanges from '../../ModalWindows/ModalLostChanges/ModalLostChanges';

interface Props {
    companyUser: CompanyUsers;
}

const UserInformation = ({ companyUser }: Props) => {
    const dispatch = useAppDispatch();
    const [showSortOptions, setShowSortOptions] = useState(false);
    const user = useAppSelector((state: RootState) => state.user);
    const [newRole, setNewRole] = useState(companyUser.roles![0].name);
    const [showSnackbar, setSnackbar] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);

    const handleCleanedRole = (originalRole: string) => {
        originalRole = originalRole === "ROLE_PROVIDER"? "ROLE_OWNER": originalRole;
        return originalRole.replace("ROLE_", "").toLowerCase().replace(/^\w/, c => c.toUpperCase());
    };

    const handleSortOptionToggle = () => {
        setShowSortOptions(!showSortOptions);
    };

    const handleDeleteUser = () => {
    };

    const handleChangeRole = async (role: string) => {
        setNewRole(role);
        const res = await dispatch(updateUserRoleAsync({ userId: companyUser.id, role: role }))

        if (res.meta.requestStatus === 'fulfilled') {
            setSnackbar(true);
            setShowSortOptions(!showSortOptions);
            setTimeout(async () => {
                setSnackbar(false);
            }, 3000);

        } else if (res.meta.requestStatus === 'rejected') {
            console.error("Error encountered during role update");
        }

    };

    return (
        <>
            <UserInformationWrapper>
                <UserWrapper>
                    <UserAvatar src={companyUser.avatar || defaultAvatar} alt="avatar" />
                    <div>
                        <UserName key={companyUser.id} >{companyUser.firstName} {companyUser.lastName}</UserName>
                        <UserJobTitle>{companyUser.jobTitle}</UserJobTitle>
                        <UserEmail>{companyUser.email}</UserEmail>
                    </div>
                </UserWrapper>

                <SortWrapper>
                    {!showSortOptions ? (
                        <SortButton onClick={handleSortOptionToggle}>
                            {handleCleanedRole(companyUser.roles![0].name)}
                            <ChevronRightIcon onClick={handleSortOptionToggle} />
                        </SortButton>
                    ) : (
                        <SortActiveWrapper>
                            <SortActiveTitleWrapper onClick={handleSortOptionToggle} >
                                {handleCleanedRole(companyUser.roles![0].name)}
                                <ChevronDownIcon onClick={handleSortOptionToggle} />
                            </SortActiveTitleWrapper>
                            {Object.values(UserRoles).map((role) => (
                                <SelectItemRole key={role} role={role} newRole={newRole} handleChangeRole={handleChangeRole} handleCleanedRole={handleCleanedRole} />
                            ))}
                        </SortActiveWrapper>
                    )}

                    <RedCrossButton onClick={() => setConfirmDelete(true)}>
                        <CrossIcon color='rgba(255, 0, 0, 1)' />
                    </RedCrossButton>
                </SortWrapper>
            </UserInformationWrapper>

            {showSnackbar && (
                <SnackbarWrapper>
                    <SnackbarParagraph>
                        {`Role has been successfully changed`}
                    </SnackbarParagraph>
                    <CrossWhiteIconWrapper onClick={() => setSnackbar(false)}>
                        <CrossWhiteIcon />
                    </CrossWhiteIconWrapper>
                </SnackbarWrapper>
            )}


            {confirmDelete &&
                <ModalLostChanges
                    handleWhiteButtonClick={handleDeleteUser}
                    handleButtonClick={() => setConfirmDelete(false)}
                    text='Are you sure you want to delete this user?'
                    buttonText='No'
                    buttonWhitetext='Yes'
                />}
        </>
    )
}

export default UserInformation