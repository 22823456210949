import React, { useEffect, useState } from "react";
import ReturnButton from "../ReturnButton/ReturnButton";
import {
  Avatar,
  AvatarComponent,
  BodyMessageLink,
  ButtonWrapper,
  ChevronFilterWapper,
  CompanyComponent,
  CompanyList,
  CompanyWrapper,
  Container,
  CountryComponent,
  DateComponent,
  DefaultParagraph,
  DefaultWrapper,
  FilterSortWrapper,
  FlagComponent,
  FlagWrapper,
  IndustryLocationWrapper,
  KeywordsFilter,
  MarkButton,
  MarkParagraph,
  MarkWrapper,
  MessageComponent,
  MoveContainer,
  MoveWrapper,
  NotInterestedButton,
  PageHeaderWrapper,
  RatingReviewBlock,
  SearchInputWrapper,
  SelectedMessage,
  SelectedText,
  SortButton,
  SortContainer,
  SortWrapper,
  Subtitle,
  SubtitleFilter,
  SwitchWrapper,
  TypeComponent,
} from "./style";
import { ButtonBlock, Input } from "../SearchInput/Styles";
import SearchIcon from "../../assets/icons/SearchIcon";
import SliderIcon from "../../assets/icons/SliderIcon";
import { SwitchButton } from "../AgentListPage/AgentFilter/style";
import {
  ChevronDownSortWrapper,
  ChevronRightSortWrapper,
  LocationIndustryButton,
  SortActiveButton,
  SortActiveWrapper,
} from "../AgentListPage/AgentList/style";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { RequestStatus, Requst } from "../../utils/types";
import FilterSwitch from "../FilterSwitch/FilterSwitch";
import { defaultSelfman } from "../../../src/assets/img";
import { PaginationBlock } from "../ListPage/styles";
import ArrowLeftIcon from "../../assets/icons/ArrowLeftIccon";
import ArrowRightIcon from "../../assets/icons/ArrowRightIcon";
import Pagination from "../Pagination/Pagination";
import {
  changeBusinessRequestsStatusAsync,
  getAllRequestsAsync,
} from "../../store/slices/userSlice";
import DefaultRequest from "../../assets/icons/DefaultRequest";
import ActiveFlag from "../../assets/icons/ActiveFlagIcon";
import NotActiveFlag from "../../assets/icons/NotActiveFlag";
import HoverFlag from "../../assets/icons/HoverFlag";

const DefaultLogo = require("../../assets/img/SearchBlockIcon.png");

interface HoveredStates {
  [key: string]: boolean;
}

export interface ChangeStatusData {
  status: string;
  requestIds: number[];
}

const RequestPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const requests: Requst[] = useAppSelector(
    (state) => state.user.requests?.content || []
  );
  
  const [activeButton, setActiveButton] = useState("InProgress");
  const [showSortOptions, setShowSortOptions] = useState(false);
  const [showMarkOptions, setShowMarkOptions] = useState(false);
  const [showMoveOptions, setShowMoveOptions] = useState(false);
  const [isMarkOpen, setIsMarkOpen] = useState(false);
  const [isMoveOpen, setIsMoveOpen] = useState(false);
  const [selectedMarkOption, setSelectedMarkOption] = useState("Mark as");
  const [selectedMoveOption, setSelectedMoveOption] = useState("Move to");
  const [selectedMarkCategory, setSelectedMarkCategory] = useState<string>("");
  const [selectedMoveCategory, setSelectedMoveCategory] = useState<string>("");
  const [moveDialogVisible, setMoveDialogVisible] = useState(false);
  const [selectedSortCategory, setSelectedSortCategory] = useState<string>("");
  const [selectedSortOption, setSelectedSortOption] = useState("Filter");
  const token = useAppSelector((store) => store.user.userAccount?.token!);

  useEffect(() => {
    dispatch(getAllRequestsAsync() as any).then((result: any) => {});
  }, [dispatch, token]);

  const handleClick = (button: string) => {
    setActiveButton(button);
    setSelectedMoveCategory(button);
    setIsMarkOpen(false);
    setIsMoveOpen(false);
    setFilterSwitchStates((prevStates) => {
      const updatedStates = { ...prevStates };
      Object.keys(updatedStates).forEach((key) => {
        updatedStates[key] = false;
      });
      return updatedStates;
    });
    setSelectedCount(0);
  };

  const [filterSwitchStates, setFilterSwitchStates] = useState<{
    [key: string]: boolean;
  }>({});
  const [selectedCount, setSelectedCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8;

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const goToNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const totalPages = Math.ceil((requests.length || 0) / itemsPerPage);

  function convertStatusStringToEnum(
    statusString: string
  ): RequestStatus | undefined {
    switch (statusString) {
      case "INPROGRESS":
        return RequestStatus.INPROGRESS;
      case "COMPLETED":
        return RequestStatus.COMPLETED;
      case "NONINTERESTED":
        return RequestStatus.NONINTERESTED;
      default:
        return undefined;
    }
  }

  const inProgressRequests = requests?.filter(
    (request) =>
      convertStatusStringToEnum(request.status.toString()) ===
      RequestStatus.INPROGRESS
  );

  const completedRequests = requests?.filter(
    (request) =>
      convertStatusStringToEnum(request.status.toString()) ===
      RequestStatus.COMPLETED
  );

  const notInterestedRequests = requests?.filter(
    (request) =>
      convertStatusStringToEnum(request.status.toString()) ===
      RequestStatus.NONINTERESTED
  );

  const filteredRequests =
    activeButton === "InProgress"
      ? inProgressRequests
      : activeButton === "Completed"
      ? completedRequests
      : notInterestedRequests;

  const handleSortOptionToggle = () => {
    setShowSortOptions(!showSortOptions);
  };

  const handleMarkOptionToggle = () => {
    setIsMarkOpen(!isMarkOpen);
    setIsMoveOpen(false);
  };

  const handleMoveOptionToggle = () => {
    setIsMoveOpen(!isMoveOpen);
    setIsMarkOpen(false);
  };

  const handleMarkOptionSelect = (option: string) => {
    setSelectedMarkOption("Mark as ");
    setSelectedMarkCategory(option);
    setShowMarkOptions(false);

    const selectedDialogIds = Object.keys(filterSwitchStates).filter(
      (key) => filterSwitchStates[key]
    );

    const updatedActiveStates = { ...activeStates };
    selectedDialogIds.forEach((dialogId) => {
      updatedActiveStates[dialogId.toString()] =
        option === "Important" ? true : false;
    });
    setActiveStates(updatedActiveStates);

    let dataToSend = {};

    switch (option) {
      case "Unread":
        dataToSend = {
          notread: selectedDialogIds,
        };
        break;

      case "Important":
        dataToSend = {
          favorite: selectedDialogIds,
        };
        break;

      default:
        break;
    }
  };

  const handleSortOptionSelect = (option: string) => {
    setSelectedSortOption("Filter ");
    setSelectedSortCategory(option);
    setShowSortOptions(false);
    prepareDataForBackend(option);
  };

  const handleMoveOptionSelect = (option: string) => {
    setSelectedMoveOption("Move to");
    setSelectedMoveCategory(option);
    setShowMoveOptions(false);
    setMoveDialogVisible(true);

    const selectedDialogIds = Object.keys(filterSwitchStates)?.filter(
      (key) => filterSwitchStates[key]
    );

    let dataToSend: ChangeStatusData = {
      status: "",
      requestIds: selectedDialogIds.map((id) => parseInt(id)),
    };

    switch (option) {
      case "Completed":
        dataToSend.status = "COMPLETED";
        break;

      case "In progress":
        dataToSend.status = "INPROGRESS";
        break;

      case "Not interested":
        dataToSend.status = "NONINTERESTED";
        break;

      default:
        break;
    }

    dispatch(changeBusinessRequestsStatusAsync(dataToSend) as any);
    setIsMarkOpen(false);
    setIsMoveOpen(false);
    setFilterSwitchStates((prevStates) => {
      const updatedStates = { ...prevStates };
      Object.keys(updatedStates).forEach((key) => {
        updatedStates[key] = false;
      });
      return updatedStates;
    });
    setSelectedCount(0);
    dispatch(getAllRequestsAsync() as any).then((result: any) => {});
  };

  const [hoveredStates, setHoveredStates] = useState<HoveredStates>(
    requests!.reduce((acc, request) => {
      acc[request.id.toString()] = false;
      return acc;
    }, {} as HoveredStates)
  );

  const prepareDataForBackend = (filterType: string) => {
    const filterTypes = ["Agent", "Company", "Important", "Unread"];

    if (filterTypes.includes(filterType)) {
      const dataToSend = {
        filterType: filterType,
      };
    }
  };

  interface ActiveStates {
    [key: string]: boolean;
  }

  const [activeStates, setActiveStates] = useState<ActiveStates>(
    requests.reduce((acc, request) => {
      acc[request.id.toString()] = false;
      return acc;
    }, {} as ActiveStates)
  );

  const handleMouseEnter = (requestId: number) => {
    setHoveredStates((prevStates) => ({
      ...prevStates,
      [requestId.toString()]: true,
    }));
  };

  const handleMouseLeave = (requestId: number) => {
    setHoveredStates((prevStates) => ({
      ...prevStates,
      [requestId.toString()]: false,
    }));
  };

  const handleIconClick = (requestId: number) => {
    setActiveStates((prevStates) => ({
      ...prevStates,
      [requestId.toString()]: !prevStates[requestId.toString()],
    }));

    const selectedDialogIds = [requestId.toString()];

    let dataToSend = {};

    if (!activeStates[requestId.toString()]) {
      setSelectedMarkOption("Mark as ");
      setSelectedMarkCategory("Important");

      dataToSend = {
        favorite: selectedDialogIds,
      };
    }
  };

  useEffect(() => {
    const newSelectedCount =
      Object.values(filterSwitchStates).filter(Boolean).length;
    setSelectedCount(newSelectedCount);
  }, [filterSwitchStates]);

  function getMonthName(month: number): string {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return months[month];
  }

  return (
    <Container>
      <ReturnButton />
      <Subtitle>Business requests</Subtitle>
      <ButtonWrapper>
        <SwitchButton
          isActive={activeButton === "InProgress"}
          onClick={() => handleClick("InProgress")}
        >
          In progress
        </SwitchButton>
        <SwitchButton
          isActive={activeButton === "Completed"}
          onClick={() => {
            handleClick("Completed");
            setMoveDialogVisible(activeButton === "Completed");
          }}
        >
          Completed
        </SwitchButton>
        <SwitchButton
          isActive={activeButton === "Not interested"}
          onClick={() => handleClick("Not interested")}
        >
          Not interested
        </SwitchButton>
      </ButtonWrapper>
      <PageHeaderWrapper>
        <SearchInputWrapper>
          <Input type="text" placeholder="Search by company name or text" />
          <ButtonBlock>
            <SearchIcon />
          </ButtonBlock>
        </SearchInputWrapper>

        <SortContainer>
          <SortWrapper>
            {!showSortOptions ? (
              <FilterSortWrapper>
                <SortButton onClick={handleSortOptionToggle}>
                  {selectedSortOption}
                </SortButton>
                <ChevronFilterWapper onClick={handleSortOptionToggle}>
                  <SliderIcon />
                </ChevronFilterWapper>
              </FilterSortWrapper>
            ) : (
              <SortActiveWrapper>
                <SortActiveButton onClick={handleSortOptionToggle}>
                  {selectedSortOption}
                  <ChevronDownSortWrapper onClick={handleSortOptionToggle}>
                    <SliderIcon />
                  </ChevronDownSortWrapper>
                </SortActiveButton>
                <IndustryLocationWrapper>
                  <LocationIndustryButton
                    onClick={() => handleSortOptionSelect("Agent")}
                  >
                    Agent
                  </LocationIndustryButton>
                  <LocationIndustryButton
                    onClick={() => handleSortOptionSelect("Company")}
                  >
                    Company
                  </LocationIndustryButton>
                  <LocationIndustryButton
                    onClick={() => handleSortOptionSelect("Important")}
                  >
                    Important
                  </LocationIndustryButton>
                  <LocationIndustryButton
                    onClick={() => handleSortOptionSelect("Unread")}
                  >
                    Unread
                  </LocationIndustryButton>
                </IndustryLocationWrapper>
              </SortActiveWrapper>
            )}
          </SortWrapper>
        </SortContainer>
      </PageHeaderWrapper>

      {selectedCount > 0 && (
        <SelectedMessage>
          <SwitchWrapper>
            <FilterSwitch
              isActive={true}
              onToggle={() => {
                setFilterSwitchStates((prevStates) => {
                  const updatedStates = { ...prevStates };
                  const allSelected = Object.values(updatedStates).every(
                    (state) => state
                  );
                  if (allSelected) {
                    Object.keys(updatedStates).forEach((key) => {
                      updatedStates[key] = false;
                    });
                  } else {
                    Object.keys(updatedStates).forEach((key) => {
                      updatedStates[key] = key === "Selected Message";
                    });
                  }
                  const newSelectedCount =
                    Object.values(updatedStates).filter(Boolean).length;
                  setSelectedCount(allSelected ? 0 : newSelectedCount);
                  return updatedStates;
                });
              }}
            />
          </SwitchWrapper>
          <SelectedText> {selectedCount} selected</SelectedText>

          <SortContainer>
            <MarkWrapper>
              {!isMarkOpen ? (
                <>
                  <>
                    <MarkButton onClick={handleMarkOptionToggle}>
                      <MarkParagraph>{selectedMarkOption}</MarkParagraph>
                    </MarkButton>
                  </>
                </>
              ) : (
                <SortActiveWrapper>
                  <SortActiveButton onClick={handleMarkOptionToggle}>
                    {selectedMarkOption}
                  </SortActiveButton>
                  <IndustryLocationWrapper>
                    <LocationIndustryButton
                      onClick={() => handleMarkOptionSelect("Unread")}
                    >
                      Unread
                    </LocationIndustryButton>
                    <LocationIndustryButton
                      onClick={() => handleMarkOptionSelect("Important")}
                    >
                      Important
                    </LocationIndustryButton>
                  </IndustryLocationWrapper>
                </SortActiveWrapper>
              )}
            </MarkWrapper>
          </SortContainer>

          <MoveContainer>
            <MoveWrapper>
              {!isMoveOpen ? (
                <>
                  <>
                    <MarkButton onClick={handleMoveOptionToggle}>
                      <MarkParagraph>{selectedMoveOption}</MarkParagraph>
                    </MarkButton>
                  </>
                </>
              ) : (
                <SortActiveWrapper>
                  <SortActiveButton onClick={handleMoveOptionToggle}>
                    {selectedMoveOption}
                  </SortActiveButton>
                  <IndustryLocationWrapper>
                    <LocationIndustryButton
                      onClick={() => handleMoveOptionSelect("In progress")}
                    >
                      In progress
                    </LocationIndustryButton>
                    <LocationIndustryButton
                      onClick={() => handleMoveOptionSelect("Completed")}
                    >
                      Completed
                    </LocationIndustryButton>
                    <NotInterestedButton
                      onClick={() => handleMoveOptionSelect("Not interested")}
                    >
                      Not interested
                    </NotInterestedButton>
                  </IndustryLocationWrapper>
                </SortActiveWrapper>
              )}
            </MoveWrapper>
          </MoveContainer>
        </SelectedMessage>
      )}
      {filteredRequests && filteredRequests!.length === 0 ? (
        <>
          <DefaultWrapper>
            <DefaultRequest />
          </DefaultWrapper>
          <DefaultParagraph>No data...</DefaultParagraph>
        </>
      ) : (
        <>
          <KeywordsFilter>
            <SubtitleFilter>Name</SubtitleFilter>
            <SubtitleFilter>Type</SubtitleFilter>
            <SubtitleFilter>Location</SubtitleFilter>
            <SubtitleFilter>Last message</SubtitleFilter>
            <SubtitleFilter>Date</SubtitleFilter>
          </KeywordsFilter>
          <CompanyList>
            {filteredRequests.length > 0 &&
              filteredRequests?.map((filteredRequests: any) => (
                <CompanyWrapper
                  key={filteredRequests.id}
                  onMouseEnter={() => handleMouseEnter(filteredRequests.id)}
                  onMouseLeave={() => handleMouseLeave(filteredRequests.id)}
                >
                  <FlagComponent>
                    <FilterSwitch
                      isActive={filterSwitchStates[filteredRequests.id]}
                      onToggle={() =>
                        setFilterSwitchStates((prevStates) => ({
                          ...prevStates,
                          [filteredRequests.id]:
                            !prevStates[filteredRequests.id],
                        }))
                      }
                    />
                    <FlagWrapper
                      onClick={(e) => {
                        e.stopPropagation();
                        handleIconClick(filteredRequests.id);
                      }}
                    >
                      {activeStates[filteredRequests.id.toString()] ? (
                        <ActiveFlag />
                      ) : hoveredStates[filteredRequests.id.toString()] ? (
                        <HoverFlag />
                      ) : (
                        <NotActiveFlag />
                      )}
                    </FlagWrapper>
                  </FlagComponent>
                  <BodyMessageLink to={`/dialogue/${filteredRequests.id}`}>
                    <AvatarComponent>
                      <Avatar src={filteredRequests.avatar || defaultSelfman} />
                    </AvatarComponent>
                    <RatingReviewBlock>
                      <CompanyComponent>
                        {filteredRequests.name.replace(
                          /([a-z])([A-Z])/g,
                          "$1 $2"
                        )}
                      </CompanyComponent>
                      <CompanyComponent>
                        {filteredRequests.industry}
                      </CompanyComponent>
                    </RatingReviewBlock>
                    <TypeComponent>
                      {String(filteredRequests.type).charAt(0).toUpperCase() +
                        String(filteredRequests.type).slice(1).toLowerCase()}
                    </TypeComponent>

                    <CountryComponent>
                      {`${filteredRequests.city}, ${filteredRequests.country}`}
                    </CountryComponent>
                    <MessageComponent>
                      {filteredRequests.messages.length > 0
                        ? filteredRequests.messages[
                            filteredRequests.messages.length - 1
                          ].text
                        : ""}
                    </MessageComponent>
                    <DateComponent>
                      {filteredRequests.messages.length > 0 &&
                        `${new Date(
                          filteredRequests.messages[
                            filteredRequests.messages.length - 1
                          ].dateCreated
                        ).getDate()} ${getMonthName(
                          new Date(
                            filteredRequests.messages[
                              filteredRequests.messages.length - 1
                            ].dateCreated
                          ).getMonth()
                        )}`}
                    </DateComponent>
                  </BodyMessageLink>
                </CompanyWrapper>
              ))}
          </CompanyList>

          <PaginationBlock>
            <ArrowLeftIcon onClick={goToPreviousPage} />
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
            <ArrowRightIcon onClick={goToNextPage} />
          </PaginationBlock>
        </>
      )}
    </Container>
  );
};

export default RequestPage;
