import { base_url } from '../../../utils/constants';
import { logoutFunc, refreshTokenAsync } from '../userSlice';


// Получаем в аргументах эндпоинт и в options получаем метод, body и всё что нужно
export async function customFetch(url: string, options: RequestInit = {},) {

    // // Динамически импортируем store (обход циклических зависимостей) (отложенный импорт)
    const { store } = await import('../../store');
    const state = store.getState();
    const tokenValue = state.user.userAccount?.token;
    const refreshTokenValue = state.user.userAccount?.refreshToken;

    // Устанавливаем значения по умолчанию для опций запроса
    const defaultOptions: RequestInit = {
        method: "GET", // Значение по умолчанию для метода
        headers: {
            "Content-Type": "application/json",
        },
        credentials: 'include',
        ...options, // Переопределяем значения по умолчанию переданными опциями
    };

    // Создаем объект Headers из заголовков по умолчанию
    const headers = new Headers(defaultOptions.headers);

    // Если токен есть, добавляем его в заголовки запроса
    if (tokenValue) {
        headers.set('Authorization', `Bearer ${tokenValue}`);
    }

    // Обновляем заголовки в defaultOptions
    defaultOptions.headers = headers;

    // Функция для обработки ответа от сервера
    const handleResponse = async (response: Response): Promise<any> => {

        // Если статус ответа 403 и заголовок _isRetry не установлен
        if (response.status === 403 && !headers.has('_isRetry')) {
            headers.set('_isRetry', 'true'); // Устанавливаем заголовок _isRetry, чтобы избежать повторного обновления токена

            // Если refresh токен недоступен, выбрасываем ошибку
            if (!refreshTokenValue) {
                throw new Error("Refresh token is not available");
            }

            // Попытка обновления токена
            try {
                // Отправляем запрос для обновления токена
                const resultAction = await store.dispatch!(refreshTokenAsync({ refreshToken: refreshTokenValue }));

                // Если обновление токена прошло успешно
                if (refreshTokenAsync.fulfilled.match(resultAction)) {
                    // Извлекаем новые токены из результата
                    const newToken = resultAction.payload.data.token;

                    // Обновляем заголовок Authorization с новым токеном
                    headers.set('Authorization', `Bearer ${newToken}`);

                    // Обновляем заголовки в defaultOptions
                    defaultOptions.headers = headers;

                    // Повторяем исходный запрос с обновленным токеном
                    const newResponse = await fetch(`${base_url}/${url}`, defaultOptions);
                    // Повторно запускаем метод на проверку ошибки
                    return handleResponse(newResponse);
                } else {
                    // Если обновление токена не удалось, выбрасываем ошибку
                    throw new Error("Не удалось обновить токен");
                }
            } catch (e) {
                // Логируем ошибку обновления токена
                console.log("Ошибка обновления токена", e);
                // Выполняем выход из системы
                store.dispatch(logoutFunc() as any);
                
            }
        } else if (!response.ok) {
        
            const contentType = response.headers.get("Content-Type");
            
            

            // Если ответ text/plain, возвращаем текст ошибки
            if (contentType && contentType.includes("text/plain")) {
                const errorText = await response.text();
                throw { errorStatus: response.status, message: errorText };
            }

             // Если ответ не успешный, выбрасываем ошибку с кодом статуса
            throw new Error(response.status.toString());
        }

        // Если ответ успешный и не требует обновления токена, проверяем, является ли он JSON-ом и 
        // если да, то возвращаем JSON и статус
        const contentType = response.headers.get("Content-Type");

        if (contentType && contentType.includes("application/json")) {
            const data = await response.json();
            return { data, status: response.status };
        } else if (contentType && contentType.includes("text/plain")) {
            const text = await response.text();
            return { text, status: response.status };
        }
    
        // Если это не JSON, возвращаем только статус
        return { status: response.status };

    };

    // Выполняем запрос с исходными опциями
    const response = await fetch(`${base_url}/${url}`, defaultOptions);
    // Обрабатываем ответ с помощью handleResponse
    return handleResponse(response);
}