import React from "react";
import {DelegateSwitchBlock, DelegateWrapper, IconWrapper} from "./style";
import VectorIcon from "../../assets/icons/VectorIcon";

export interface DelegateSwitchProps {
    isActive: boolean;
    onToggle: (e: React.MouseEvent<HTMLDivElement>) => void;
}

const DelegateSwitch: React.FC<DelegateSwitchProps> = ({isActive, onToggle}) => {
    return (
        <DelegateWrapper>
            <DelegateSwitchBlock onClick={onToggle} $active={isActive}>
                <IconWrapper $active={isActive}>
                    <VectorIcon/>
                </IconWrapper>
            </DelegateSwitchBlock>
        </DelegateWrapper>
    );
};

export default DelegateSwitch;