import React from "react";

const CalendarIcon: React.FC = () => {
  return (
    <svg
      enableBackground="new 0 0 520 520"
      viewBox="0 0 520 520"
      xmlns="http://www.w3.org/2000/svg"
     
    >
      <g id="_x37_45_x5F_17_-_Layer_6_x2C__calendar_x2C__date_x2C__time">
        <g>
          <path
            d="m420 76h70v60h-460v-60h70 60 200z"
            fill="#4e8dc2"
            style={{ fill: "rgb(101, 175, 236)" }}
          ></path>
          <g fill="#393939">
            <path
              d="m100 26c-5.523 0-10 4.477-10 10v70c0 5.523 4.477 10 10 10s10-4.477 10-10v-70c0-5.523-4.477-10-10-10z"
              fill="#393939"
            ></path>
            <path
              d="m420 26c-5.523 0-10 4.477-10 10v70c0 5.523 4.477 10 10 10s10-4.477 10-10v-70c0-5.523-4.477-10-10-10z"
              fill="#393939"
            ></path>
            <path
              d="m360 26c-5.523 0-10 4.477-10 10v70c0 5.523 4.477 10 10 10s10-4.477 10-10v-70c0-5.523-4.477-10-10-10z"
              fill="#393939"
            ></path>
            <path
              d="m160 26c-5.523 0-10 4.477-10 10v70c0 5.523 4.477 10 10 10s10-4.477 10-10v-70c0-5.523-4.477-10-10-10z"
              fill="#393939"
            ></path>
          </g>
          <g fill="#3b6a92">
            <path
              d="m110 96h-20c-5.523 0-10 4.477-10 10s4.477 10 10 10h20c5.523 0 10-4.477 10-10s-4.477-10-10-10z"
              fill="#3b6a92"
            ></path>
            <path
              d="m430 96h-20c-5.523 0-10 4.477-10 10s4.477 10 10 10h20c5.523 0 10-4.477 10-10s-4.477-10-10-10z"
              fill="#3b6a92"
            ></path>
            <path
              d="m370 96h-20c-5.523 0-10 4.477-10 10s4.477 10 10 10h20c5.523 0 10-4.477 10-10s-4.477-10-10-10z"
              fill="#3b6a92"
            ></path>
            <path
              d="m170 96h-20c-5.523 0-10 4.477-10 10s4.477 10 10 10h20c5.523 0 10-4.477 10-10s-4.477-10-10-10z"
              fill="#3b6a92"
            ></path>
          </g>
          <path
            d="m30 134v360h400v-70h60v-290z"
            fill="#d8e2e5"
            style={{ fill: "rgb(204, 243, 255)" }}
          ></path>
          <path
            d="m400 334h40v40h-40z"
            fill="#ff5d5d"
            style={{ fill: "rgb(40, 13, 242)" }}
          ></path>
          <path
            d="m400 254h40v40h-40z"
            fill="#ff5d5d"
            style={{ fill: "rgb(40, 13, 242)" }}
          ></path>
          <path
            d="m400 174h40v40h-40z"
            fill="#ff5d5d"
            style={{ fill: "rgb(40, 13, 242)" }}
          ></path>
          <path d="m490 424-60 70v-70z" fill="#c8d3d6"></path>
          <g fill="#2b3d51">
            <path d="m80 414h40v40h-40z" fill="#2b3d51"></path>
            <path d="m160 414h40v40h-40z" fill="#2b3d51"></path>
            <path d="m240 414h40v40h-40z" fill="#2b3d51"></path>
            <path d="m320 414h40v40h-40z" fill="#2b3d51"></path>
            <path d="m320 334h40v40h-40z" fill="#2b3d51"></path>
            <path d="m240 334h40v40h-40z" fill="#2b3d51"></path>
            <path d="m160 334h40v40h-40z" fill="#2b3d51"></path>
            <path d="m80 334h40v40h-40z" fill="#2b3d51"></path>
            <path d="m80 254h40v40h-40z" fill="#2b3d51"></path>
            <path d="m160 254h40v40h-40z" fill="#2b3d51"></path>
            <path d="m240 254h40v40h-40z" fill="#2b3d51"></path>
            <path d="m320 254h40v40h-40z" fill="#2b3d51"></path>
            <path d="m320 174h40v40h-40z" fill="#2b3d51"></path>
            <path d="m240 174h40v40h-40z" fill="#2b3d51"></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default CalendarIcon;
