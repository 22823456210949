import React from "react";
import {
  ButtonWrapper,
  DescriptionStyle,
  StyledSourcingBrief,
  TitleSourcingBrief,
} from "./style";
import Button from "../../Button/Button";

import { routes } from "../../../routes/routes";
import { useNavigate } from "react-router-dom";

const ProductSourcingBrief = () => {
  const navigate = useNavigate();
  return (
    <StyledSourcingBrief>
      <TitleSourcingBrief>Product Sourcing Brief</TitleSourcingBrief>

      <DescriptionStyle>
        Delegate search and streamline communication process: create and manage
        customized briefs for product sourcing, contact multiple suppliers with
        a single click and close deals faster by eliminating repetitive
        questions
      </DescriptionStyle>
      <ButtonWrapper>
        <Button
          text="Get started"
          onClick={() => {
            navigate(routes.DELEGATESEARCHBRIEF);
          }}
        />
      </ButtonWrapper>
    </StyledSourcingBrief>
  );
};

export default ProductSourcingBrief;
