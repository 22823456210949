import React from "react";

const CallIcon: React.FC = () => {
  return (
<svg
  width="21.882812"
  height="21.928467"
  viewBox="0 0 21.8828 21.9285"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <defs />
  <path
    id="Vector"
    d="M20.8828 18.92C20.8867 19.1986 20.8281 19.4742 20.7148 19.7295C20.6055 19.9846 20.4414 20.2137 20.2344 20.402C20.0312 20.5902 19.7891 20.7335 19.5234 20.8228C19.2617 20.912 18.9805 20.9452 18.7031 20.92C15.6289 20.5857 12.6719 19.5343 10.0742 17.8501C7.65625 16.3148 5.60938 14.2662 4.07422 11.8501C2.38281 9.24133 1.33203 6.27112 1.00391 3.18005C0.980469 2.90356 1.01172 2.62488 1.10156 2.36169C1.1875 2.09863 1.33203 1.85681 1.51953 1.65173C1.70703 1.44666 1.93359 1.28284 2.1875 1.17065C2.44141 1.05847 2.71484 1.00037 2.99609 1.00012L5.99609 1.00012C6.48047 0.995361 6.94922 1.16724 7.31641 1.48364C7.6875 1.80005 7.92578 2.2395 7.99609 2.72009C8.12109 3.68018 8.35547 4.6228 8.69531 5.53015C8.82812 5.88806 8.85938 6.27698 8.77734 6.651C8.69922 7.0249 8.51172 7.36816 8.24609 7.64014L6.97266 8.91016C8.39844 11.4137 10.4688 13.4866 12.9727 14.9102L14.2461 13.6401C14.5156 13.3712 14.8594 13.1859 15.2344 13.1062C15.6055 13.0265 15.9961 13.0555 16.3555 13.1901C17.2617 13.5287 18.2031 13.7634 19.1641 13.8901C19.6484 13.9586 20.0938 14.2032 20.4102 14.5776C20.7266 14.9519 20.8945 15.4297 20.8828 15.92L20.8828 18.92Z"
    stroke="#001731"
    strokeOpacity="1.000000"
    strokeWidth="2.000000"
    strokeLinejoin="round"
  />
</svg>
  )}

  export default CallIcon;
