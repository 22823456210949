import React from "react";
import { LeftText, OrderQuantity, Quantity } from "../ManufactoringFilter/style";
import CircleSwitch from "../../CircleSwitch/CircleSwitch";
import { Paragraph, ParagraphRight } from "../ReputationFilter/style";
import { PartnerBaseProvider, ProviderDetails } from "../../../utils/types";

interface CustomizeFilterProps {
  companyItems: PartnerBaseProvider[];
  filterValues: ProviderDetails;
  onFilterChange: (filterValues: ProviderDetails) => void;
  setFilterValues: React.Dispatch<React.SetStateAction<ProviderDetails>>;
}

const CustomizeFilter: React.FC<CustomizeFilterProps> = ({ 
  companyItems,
  setFilterValues,
  filterValues,
  onFilterChange, 
}) => {
  return (
    <div>
      <OrderQuantity>
        <LeftText>
          <Quantity>Sample:</Quantity>
        </LeftText>
        <CircleSwitch
          isActive={filterValues?.sample || false}
          onToggle={() =>
            setFilterValues({
              ...filterValues,
              sample: !filterValues?.sample,
            })
          }
        />
        <ParagraphRight>Yes</ParagraphRight>
      </OrderQuantity>
      <OrderQuantity>
        <LeftText>
        <Quantity>Special packing:</Quantity>
        </LeftText>
        <CircleSwitch
          isActive={filterValues?.specialPacking || false}
          onToggle={() =>
            setFilterValues({
              ...filterValues,
              specialPacking: !filterValues?.specialPacking,
            })
          }
        />
        <ParagraphRight>Yes</ParagraphRight>
      </OrderQuantity>
      <OrderQuantity>
        <LeftText>
        <Quantity>White label:</Quantity>
        </LeftText>
        <CircleSwitch
          isActive={filterValues?.whiteLabel || false}
          onToggle={() =>
            setFilterValues({
              ...filterValues,
              whiteLabel: !filterValues?.whiteLabel,
            })
          }
        />
        <ParagraphRight>Yes</ParagraphRight>
      </OrderQuantity>
      <OrderQuantity>
        <LeftText>
        <Quantity>Customization capacity</Quantity>
        </LeftText>
        <CircleSwitch
          isActive={filterValues?.customizationCapacity || false}
          onToggle={() =>
            setFilterValues({
              ...filterValues,
              customizationCapacity: !filterValues?.customizationCapacity,
            })
          }
        />
        <ParagraphRight>Yes</ParagraphRight>
      </OrderQuantity>
      <OrderQuantity>
        <LeftText>
        <Quantity>R&D capacity:</Quantity>
        </LeftText>
        <CircleSwitch
          isActive={filterValues?.rndCapacity || false}
          onToggle={() =>
            setFilterValues({
              ...filterValues,
              rndCapacity: !filterValues?.rndCapacity,
            })
          }
        />
        <ParagraphRight>Yes</ParagraphRight>
      </OrderQuantity>
    </div>
  );
};

export default CustomizeFilter;
