import React from 'react'
import { FilterSwitchBlock, IconWrapper } from '../../FilterSwitch/style';
import VectorIcon from '../../../assets/icons/VectorIcon';
import StarIcon from '../../../assets/icons/StarIcon';

interface FilterSwitchIndustryProps {
  label?: string;
  status : string;
  onToggle: () => void;
  isRating?: boolean;
}

const FilterSwitchIndustry: React.FC<FilterSwitchIndustryProps> = ({
  label = "",
  status,
  onToggle,
  isRating,
}) => {
  const isActive = status === "selected";

  const defineColor = () => {
    switch (status) {
      case "selected":
        return "var(--batton, #0095C0)";

      case "other":
        return "#A1A1A1";

      default:
        return "transparent";
    }
  };

  const handleClick =()=>{
    if(status ==="selected" || status === "free" ){
      return onToggle();
    }
  }

  return (
    <div style={{ display: "flex", alignItems: 'center'}}>
      <FilterSwitchBlock onClick={handleClick} $back ={defineColor()}>
        <IconWrapper fill={defineColor()}>
          <VectorIcon />
        </IconWrapper>
      </FilterSwitchBlock>
      {isRating ? (
        <div>
          {Array.from({ length: parseInt(label, 10) }, (_, i) => (
            <StarIcon key={i} />
          ))}
        </div>
      ) : (
        <span style={{ marginLeft: "5px" }}>{label}</span>
      )}
    </div>
  );
}

export default FilterSwitchIndustry