import Slider from "rc-slider";
import styled from "styled-components";

export const IndustryKeqwordsP = styled.p`
  color: var(--txt-dark, #001731);
  font-size: 17px;
  font-weight: 500;
  line-height: 140%;
  margin-left: 20px;
`;

export const StyledSlider = styled(Slider)`
  border-radius: 16px;
  margin-bottom: 20px;
  .rc-slider-handle {
    background-color: var(--green, #03b62a); 
    width: 20px;
    height: 20px;
    opacity: 1;
    box-shadow: none;
    border: none;
    &:active,
    &:focus {
      border: none; 
      box-shadow: none; 
      cursor: default;
    }
    &:hover,
    &:focus {
      cursor: default; 
    }
  }

  .rc-slider-track {
    background-color: var(--batton, #0095c0); 
    height: 10px;
  }

  .rc-slider-rail {
    background-color: var(--green, #03b62a); 
    height: 10px;
  }
`;

export const SliderWrapper = styled.div`
  margin-left: 20px;
  margin-right: 20px;
`;

export const PriceInput = styled.input`
  max-width: 100px;
  width: 100%;
  border-radius: 16px;
  border: 1px solid var(--border, #bdbdbd);
  background: var(--wiht, #fff);
  padding: 14px 20px 16px 30px;
  display: flex;
  justify-content: center;
  text-align: center;
`;


export const InputWrapper=styled.div`
display: flex;
margin-bottom: 30px;
align-items: center;
gap: 8px;
`;

export const Dash=styled.div`
color: var(--txt-dark, #001731);
font-size: 17px;
font-weight: 400;
line-height: 130%;
`;

export const AvailabilityWrapper = styled.div`
margin-bottom: 20px;
margin-top: 20px;
margin-left: 20px;
`;