import React from "react";

const StarIcon: React.FC<{ style?: React.CSSProperties }> = ({ style }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <g id="Star 1">
        <path
          id="Star 1_2"
          d="M12 0L14.6942 8.2918H23.4127L16.3593 13.4164L19.0534 21.7082L12 16.5836L4.94658 21.7082L7.64074 13.4164L0.587322 8.2918H9.30583L12 0Z"
          fill="url(#paint0_linear_3484_2187)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_3484_2187"
          x1="2.5"
          y1="13.5"
          x2="21"
          y2="9.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDDC30" />
          <stop offset="1" stopColor="#F2F700" />
        </linearGradient>
      </defs>
    </svg>
  );
};


export default StarIcon;