import React, { FormEvent, useEffect, useState } from "react";
import {
  AverageWrapper,
  BestCompaniesWrapper,
  BlueParagraph,
  BottomDiargamWrapper,
  BottomWrapper,
  ButtonContainer,
  CalendarActiveButton,
  CalendarActiveWrapper,
  CalendarButton,
  CalendarContainer,
  CalendarTopWrapper,
  Container,
  CrossIconWrapper,
  DateContainer,
  DateWrapper,
  DiagramWrapper,
  FinancicalWrapper,
  GraphicsWrapper,
  HeaderWrapper,
  IconWrapper,
  MapWrapper,
  NewCustomers,
  NewOldCustomers,
  NoneParagraph,
  NoneParagraphWrapper,
  NumberOrders,
  OperationalEfficiency,
  OrderDetails,
  OrdersStatus,
  OrdersWrapper,
  Paragraph,
  PayingOrders,
  ReturnRate,
  SearchInputWrapper,
  StyledInputDate,
  SubFinancicalTitle,
  SubOrdersWrapper,
  SubTitle,
  SubTitleWrapper,
  Table,
  TableBody,
  TableHeader,
  TableMapWrapper,
  TableWrapper,
  Td,
  Thead,
  TimePeriodButton,
  TimePeriodParagraph,
  TimePeriodWrapper,
  Title,
  TitleWrapper,
  TopButtonContainer,
  TopCustomers,
  TopWrapper,
  TotalOrders,
  Tr,
  WeekButton,
  WhiteSquare,
  Wrapper,
} from "./style";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
  Bar,
  PieChart,
  Pie,
  Cell,
  Label,
  ComposedChart,
} from "recharts";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import WorldMap from "./WorldMap/WorldMap";
import WorldMap2 from "./WorldMap2/WorldMap2";
import WorldMap3 from "./WorldMap3/WorldMap3";
import CalendarIcon from "../../assets/icons/CalendarIcon";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { DateSelectArg } from "@fullcalendar/core";
import { format } from "date-fns";
import Button from "../Button/Button";
import CrossIcon from "../../assets/icons/CrossIcon";
import MyCalendar from "./Calendar/Calendar";
import { StyledCalendar } from "./Calendar/style";
import WorldMap4 from "./WorldMap4/WorldMap4";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { RootState } from "../../store/store";
import {
  getAllCustomersDashboardAsync,
  getAllDetailsDashboardAsync,
  getAllOrdersDashboardAsync,
  getAllProductsDashboardAsync,
  getAllRequestsDashboardAsync,
  getConversionRateDashboardAsync,
  getCoordinatesDashboardAsync,
  getDetailsForMapDashboardAsync,
  getDetailsOrdersAsync,
} from "../../store/slices/userSlice";
import { DetailsDashboard, OrderDashboard } from "../../utils/types";

export enum OrderStatus {
  IN_PENDING,
  CONFIRMED,
  CANCELED,
  SIGNED,
  MANUFACTURED,
  SENT,
  RECEIVED_AND_ACCEPTED,
  COMPLETED,
}

const DashboardPage = () => {
  const payingData = [
    { name: "New customer", value: 70, color: "#0095c0" },
    { name: "Old customer", value: 30, color: "#143255" },
  ];

  const data3 = [
    { name: "Returned Orders", value: 15 },
    { name: "Non-Returned Orders", value: 85 },
  ];

  const data = [
    { name: "Visits", value: 1000 },
    { name: "Requests", value: 800 },
    { name: "Orders", value: 500 },
  ];

  const data1 = [
    { name: "Category 1", avgTime: 5.2 },
    { name: "Category 2", avgTime: 4.8 },
    { name: "Category 3", avgTime: 6.1 },
  ];

  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const dispatch = useAppDispatch();
  const token = useAppSelector(
    (state: RootState) => state.user.userAccount!.token
  );
  const dashboard = useAppSelector((state) => state.user.dashboard);
  const conversionRate = useAppSelector(
    (state) => state.user.dashboard?.conversionRateArray?.conversionRate
  );
  const [activePeriod, setActivePeriod] = useState("Week");
  const conversionRateArray = useAppSelector(
    (state) => state.user.dashboard?.conversionRateArray
  );
  const orderDetailsData =
    useAppSelector((state) => state.user.dashboard?.orderDetails) ?? {};

  const handleCalendarOptionToggle = (
    event: React.MouseEvent<HTMLElement>,
    period: string
  ) => {
    setIsCalendarOpen(!isCalendarOpen);
    setActivePeriod(period);
  };

  const handleTimePeriodClick = (period: string) => {
    const endDate = new Date();
    let startDate = new Date();

    switch (period) {
      case "Week":
        startDate.setDate(endDate.getDate() - 7);
        break;
      case "Month":
        startDate.setMonth(endDate.getMonth() - 1);
        break;
      case "Half a year":
        startDate.setMonth(endDate.getMonth() - 6);
        break;
      case "Year":
        startDate.setFullYear(endDate.getFullYear() - 1);
        break;
    }

    setActivePeriod(period);

    const formatDate = (date: Date) => {
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();
      return `${day}.${month}.${year}`;
    };

    dispatch(
      getAllRequestsDashboardAsync({
        credentials: {
          startdate: formatDate(startDate),
          enddate: formatDate(endDate),
        }
      })
    );
    dispatch(
      getAllOrdersDashboardAsync({
        credentials: {
          startdate: formatDate(startDate),
          enddate: formatDate(endDate),
        }
      })
    );
    dispatch(
      getAllCustomersDashboardAsync({
        credentials: {
          startdate: formatDate(startDate),
          enddate: formatDate(endDate),
        }
      })
    );
    dispatch(
      getAllProductsDashboardAsync({
        credentials: {
          startdate: formatDate(startDate),
          enddate: formatDate(endDate),
        }
      })
    );
    dispatch(
      getAllDetailsDashboardAsync({
        credentials: {
          startdate: formatDate(startDate),
          enddate: formatDate(endDate),
        }
      })
    );
    dispatch(
      getCoordinatesDashboardAsync({
        credentials: {
          startdate: formatDate(startDate),
          enddate: formatDate(endDate),
        },
      })
    );
    dispatch(
      getDetailsForMapDashboardAsync({
        credentials: {
          startdate: formatDate(startDate),
          enddate: formatDate(endDate),
        },
      })
    );
    dispatch(getConversionRateDashboardAsync() as any);
    dispatch(
      getDetailsOrdersAsync({
        credentials: {
          startdate: formatDate(startDate),
          enddate: formatDate(endDate),
        }
      })
    );
  };

  useEffect(() => {
    const formatDate = (date: Date): string => {
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear();
      return `${day}.${month}.${year}`;
    };
    const endDate = new Date();
    const startDate = new Date(endDate);
    startDate.setDate(startDate.getDate() - 7);
    const formattedStartDate = formatDate(startDate);
    const formattedEndDate = formatDate(endDate);
    dispatch(
      getAllRequestsDashboardAsync({
        credentials: {
          startdate: formattedStartDate,
          enddate: formattedEndDate,
        }
      })
    );
    dispatch(
      getAllOrdersDashboardAsync({
        credentials: {
          startdate: formattedStartDate,
          enddate: formattedEndDate,
        }
      })
    );
    dispatch(
      getAllCustomersDashboardAsync({
        credentials: {
          startdate: formattedStartDate,
          enddate: formattedEndDate,
        }
      })
    );
    dispatch(
      getAllProductsDashboardAsync({
        credentials: {
          startdate: formattedStartDate,
          enddate: formattedEndDate,
        }
      })
    );
    dispatch(
      getAllDetailsDashboardAsync({
        credentials: {
          startdate: formattedStartDate,
          enddate: formattedEndDate,
        }
      })
    );
    dispatch(
      getCoordinatesDashboardAsync({
        credentials: {
          startdate: formattedStartDate,
          enddate: formattedEndDate,
        },
      })
    );
    dispatch(getConversionRateDashboardAsync() as any);
    dispatch(
      getDetailsForMapDashboardAsync({
        credentials: {
          startdate: formattedStartDate,
          enddate: formattedEndDate,
        },
      })
    );
    dispatch(
      getDetailsOrdersAsync({
        credentials: {
          startdate: formattedStartDate,
          enddate: formattedEndDate,
        },
      })
    );
  }, [dispatch]);

  const newCustomersData = Object.entries(dashboard?.customers || {}).map(
    ([date, requests]) => ({
      date,
      requests,
    })
  );

  const transformedOrdersData = dashboard?.orders
    ? Object.entries(dashboard.orders).map(([date, data]) => ({
        date,
        ...data.statusCount,
      }))
    : [];

  const nonReturnedOrdersPercent =
    data3.find((entry) => entry.name === "Non-Returned Orders")?.value || 0;

  const transformedArray =
    dashboard && dashboard.details
      ? Object.entries(dashboard.details).map(([key, value]) => ({
          name: key,
          totalCustomers: value.totalCustomers,
          transactions: value.transactions,
          revenue: value.revenue,
        }))
      : [];

  const statusColors: { [key: string]: string } = {
    IN_PENDING: "#143255",
    CONFIRMED: "#0095c0",
    CANCELED: "#8884d8",
    SIGNED: "#0a536b",
    MANUFACTURED: "#63cdda",
    SENT: "#4a69bd",
    RECEIVED_AND_ACCEPTED: "#2e86de",
    COMPLETED: "#1e3799",
  };

  const isOrderDashboard = (obj: any): obj is OrderDashboard => {
    return obj && typeof obj === "object" && !Array.isArray(obj);
  };

  const orders = isOrderDashboard(dashboard?.orders) ? dashboard!.orders : {};

  const getStatusesFromOrders = (orders: OrderDashboard): string[] => {
    const statuses = new Set<string>();

    Object.values(orders).forEach((orderData) => {
      if (orderData && orderData.statusCount) {
        Object.keys(orderData.statusCount).forEach((status) =>
          statuses.add(status)
        );
      }
    });

    return Array.from(statuses);
  };

  const filteredStatuses = getStatusesFromOrders(orders).filter((status) =>
    statusColors.hasOwnProperty(status)
  );

  const conversionData = [
    {
      name: "Completed Orders",
      value: conversionRateArray?.totalCustomersWithCompletedOrders,
    },
    {
      name: "Potential Customers",
      value: conversionRateArray?.totalPotentialCustomers,
    },
  ];

  const orderDetail = Object.entries(
    orderDetailsData.ordersMertics ?? {}
  ).flatMap(([date, statuses]) =>
    Object.entries(statuses).map(([status, details]) => ({
      date,
      totalOrders: details.totalOrders,
      totalAmount: details.totalAmount,
      totalCustomers: details.totalCustomers,
      [`totalOrders_${status}`]: details.totalOrders,
      [`totalAmount_${status}`]: details.totalAmount,
      [`totalCustomers_${status}`]: details.totalCustomers,
    }))
  );

  return (
    <Container>
      <Title>Dashboard</Title>
      <HeaderWrapper>
        <SubTitle>Business requests</SubTitle>

        <TopButtonContainer>
          <WeekButton
            onClick={() => handleTimePeriodClick("Week")}
            isActive={activePeriod === "Week"}
          >
            1W
          </WeekButton>
          <WeekButton
            onClick={() => handleTimePeriodClick("Month")}
            isActive={activePeriod === "Month"}
          >
            1M
          </WeekButton>
          <WeekButton
            onClick={() => handleTimePeriodClick("Half a year")}
            isActive={activePeriod === "Half a year"}
          >
            6M
          </WeekButton>
          <WeekButton
            onClick={() => handleTimePeriodClick("Year")}
            isActive={activePeriod === "Year"}
          >
            1Y
          </WeekButton>

          <CalendarContainer>
            <>
              <CalendarButton
                onClick={(event) =>
                  handleCalendarOptionToggle(event, "Calendar")
                }
                isActive={activePeriod === "Calendar"}
              >
                <IconWrapper>
                  <CalendarIcon />
                </IconWrapper>
              </CalendarButton>
            </>
            {isCalendarOpen && (
              <CalendarActiveWrapper>
                <CalendarTopWrapper>
                  <CalendarActiveButton
                    onClick={(event) =>
                      handleCalendarOptionToggle(event, "Calendar")
                    }
                  >
                    Select time period
                  </CalendarActiveButton>
                  <CrossIconWrapper
                    onClick={(event) =>
                      handleCalendarOptionToggle(event, "Calendar")
                    }
                  >
                    <CrossIcon />
                  </CrossIconWrapper>
                </CalendarTopWrapper>

                <TimePeriodWrapper>
                  <MyCalendar
                    isCalendarOpen={isCalendarOpen}
                    setIsCalendarOpen={setIsCalendarOpen}
                  />
                </TimePeriodWrapper>
              </CalendarActiveWrapper>
            )}
          </CalendarContainer>
        </TopButtonContainer>
      </HeaderWrapper>

      <GraphicsWrapper>
        {dashboard?.requests && dashboard?.requests.length > 0 ? (
          <ResponsiveContainer width="100%" height={400}>
            <LineChart data={dashboard?.requests}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis interval={1}/>
              <Tooltip />
              <Line
                type="monotone"
                dataKey="requests"
                stroke="#0095c0"
                activeDot={{ r: 8 }}
                strokeWidth={3}
              />
            </LineChart>
          </ResponsiveContainer>
        ) : (
          <NoneParagraphWrapper>
            <NoneParagraph>
              A graph will appear here when you receive your first message
            </NoneParagraph>
          </NoneParagraphWrapper>
        )}
      </GraphicsWrapper>

      <DiagramWrapper>
        <TotalOrders>
          <TitleWrapper>
            <Paragraph>Total orders</Paragraph>
          </TitleWrapper>
          {transformedOrdersData && transformedOrdersData.length > 0 ? (
            <ResponsiveContainer width="100%" height={442}>
              <BarChart data={transformedOrdersData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis interval={1}/>
                <Legend
                  wrapperStyle={{ fontSize: "22px" }}
                  formatter={(value) => {
                    switch (value) {
                      case "IN_PENDING":
                        return "Pending";
                      case "CONFIRMED":
                        return "Confirmed";
                      case "CANCELED":
                        return "Canceled";
                      case "SIGNED":
                        return "Signed";
                      case "MANUFACTURED":
                        return "Manufactured";
                      case "SENT":
                        return "Sent";
                      case "RECEIVED_AND_ACCEPTED":
                        return "Received and Accepted";
                      case "COMPLETED":
                        return "Completed";
                      default:
                        return value;
                    }
                  }}
                />
                <Tooltip
                  cursor={{ fill: "transparent" }}
                  formatter={(value, name) => {
                    switch (name) {
                      case "IN_PENDING":
                        return ["Pending", value];
                      case "CONFIRMED":
                        return ["Confirmed", value];
                      case "CANCELED":
                        return ["Canceled", value];
                      case "SIGNED":
                        return ["Signed", value];
                      case "MANUFACTURED":
                        return ["Manufactured", value];
                      case "SENT":
                        return ["Sent", value];
                      case "RECEIVED_AND_ACCEPTED":
                        return ["Received and Accepted", value];
                      case "COMPLETED":
                        return ["Completed", value];
                      default:
                        return [name, value];
                    }
                  }}
                />
                {filteredStatuses.map((status) => (
                  <Bar
                    key={status}
                    dataKey={status}
                    stackId="status"
                    fill={statusColors[status]}
                    barSize={20}
                  />
                ))}
              </BarChart>
            </ResponsiveContainer>
          ) : (
            <NoneParagraphWrapper>
              <NoneParagraph>
                A graph will appear here when you receive your first order
              </NoneParagraph>
            </NoneParagraphWrapper>
          )}
        </TotalOrders>

        <NewCustomers>
          <TitleWrapper>
            <Paragraph>Order details</Paragraph>
          </TitleWrapper>
          {orderDetail && orderDetail.length > 0 ? (
            <ResponsiveContainer width="100%" height={440}>
              <ComposedChart data={orderDetail}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis yAxisId="left" orientation="left" stroke="#8884d8" interval={1}/>
                <YAxis yAxisId="right" orientation="right" stroke="#0095c0" />
                <Tooltip
                  content={({ active, payload, label }) => {
                    if (active && payload && payload.length) {
                      const data = payload[0].payload;
                      return (
                        <div
                          className="custom-tooltip"
                          style={{
                            backgroundColor: "#fff",
                            border: "1px solid #ccc",
                            padding: "10px",
                          }}
                        >
                          <p className="label">{`Date: ${label}`}</p>
                          <p>{`Total Orders: ${data.totalOrders}`}</p>
                          <p>{`Total Amount: ${data.totalAmount}`}</p>
                          <p>{`Total Customers: ${data.totalCustomers}`}</p>
                        </div>
                      );
                    }
                    return null;
                  }}
                />
                <Legend />
                <Bar
                  yAxisId="left"
                  dataKey="totalOrders"
                  name="Total Orders"
                  fill="#8884d8"
                  barSize={20}
                />
                <Line
                  yAxisId="right"
                  type="monotone"
                  dataKey="totalAmount"
                  name="Total Amount"
                  stroke="#0095c0"
                  strokeWidth={4}
                />
              </ComposedChart>
            </ResponsiveContainer>
          ) : (
            <NoneParagraphWrapper>
              <NoneParagraph>
                A graph will appear here when you receive your first order
              </NoneParagraph>
            </NoneParagraphWrapper>
          )}
        </NewCustomers>

        <TopCustomers>
          <TitleWrapper>
            <Paragraph>Return rate</Paragraph>
          </TitleWrapper>
          {data3 && data3.length > 0 ? (
            <PieChart width={850} height={300}>
              <Pie
                data={data3 || []}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                labelLine={false}
                innerRadius={80}
                outerRadius={110}
                fill="#8884d8"
              >
                {(data3 || []).map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={["#143255", "#8884d8", "#0095c0"][index % 3]}
                  />
                ))}
              </Pie>
              <Label
                value="Amount sold"
                position="center"
                fill="#143255"
                fontSize={16}
              />
              <Tooltip
                formatter={(value, name, props) => <span>{` ${value}`}</span>}
              />
              <text
                x="35%"
                y="50%"
                textAnchor="middle"
                dominantBaseline="central"
                fill="#143255"
                fontSize="50"
              >
                {`${nonReturnedOrdersPercent.toFixed(0)}%`}
              </text>
              <Legend
                layout="vertical"
                align="right"
                verticalAlign="middle"
                wrapperStyle={{ fontSize: "22px" }}
                formatter={(value) =>
                  value.charAt(0).toUpperCase() + value.slice(1)
                }
              />
            </PieChart>
          ) : (
            <NoneParagraphWrapper>
              <NoneParagraph>
                A graph will appear here when you receive your first order
              </NoneParagraph>
            </NoneParagraphWrapper>
          )}
        </TopCustomers>

        <PayingOrders>
          <TitleWrapper>
            <Paragraph>Order conversion rate</Paragraph>
          </TitleWrapper>
          {conversionData && conversionData.length > 0 ? (
            <PieChart width={700} height={300}>
              <Pie
                data={conversionData}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                labelLine={false}
                innerRadius={80}
                outerRadius={110}
                fill="#8884d8"
              >
                {(data || []).map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={["#143255", "#8884d8", "#0095c0"][index % 3]}
                  />
                ))}
              </Pie>
              <Label
                value="Amount sold"
                position="center"
                fill="#143255"
                fontSize={16}
              />
              <Tooltip
                formatter={(value, name, props) => <span>{` ${value}`}</span>}
              />
              <text
                x="33%"
                y="50%"
                textAnchor="middle"
                dominantBaseline="central"
                fill="#143255"
                fontSize="50"
              >
                {conversionRate !== undefined ? `${Math.round(conversionRate)}%` : 'N/A'}
              </text>
              <Legend
                layout="vertical"
                align="right"
                verticalAlign="middle"
                wrapperStyle={{ fontSize: "22px" }}
                formatter={(value) =>
                  value.charAt(0).toUpperCase() + value.slice(1)
                }
              />
            </PieChart>
          ) : (
            <NoneParagraphWrapper>
              <NoneParagraph>
                A graph will appear here when you receive your first order
              </NoneParagraph>
            </NoneParagraphWrapper>
          )}
        </PayingOrders>

        <NewOldCustomers>
          <TitleWrapper>
            <Paragraph>New vs returning buyers</Paragraph>
          </TitleWrapper>
          {payingData && payingData.length > 0 ? (
            <PieChart width={750} height={300}>
              <Pie
                data={payingData}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                startAngle={0}
                endAngle={180}
                innerRadius={80}
                outerRadius={100}
                labelLine={false}
                fill="#0095c0"
              >
                {payingData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color} />
                ))}
              </Pie>
              <Tooltip
                formatter={(value, name, props) => <span>{` ${value}%`}</span>}
              />
              <Legend
                layout="vertical"
                align="right"
                verticalAlign="middle"
                wrapperStyle={{ fontSize: "22px" }}
              />
            </PieChart>
          ) : (
            <NoneParagraphWrapper>
              <NoneParagraph>
                A graph will appear here when you receive your first order
              </NoneParagraph>
            </NoneParagraphWrapper>
          )}
        </NewOldCustomers>

        <OperationalEfficiency>
          <TitleWrapper>
            <Paragraph>New customers</Paragraph>
          </TitleWrapper>
          {newCustomersData && newCustomersData.length > 0 ? (
            <ResponsiveContainer width="100%" height={425}>
              <LineChart data={newCustomersData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Line
                  type="monotone"
                  dataKey="requests"
                  stroke="#0095c0"
                  activeDot={{ r: 8 }}
                  strokeWidth={3}
                />
              </LineChart>
            </ResponsiveContainer>
          ) : (
            <NoneParagraphWrapper>
              <NoneParagraph>
                A graph will appear here when you receive your first customer
              </NoneParagraph>
            </NoneParagraphWrapper>
          )}
        </OperationalEfficiency>
        <ReturnRate>
          <TitleWrapper>
            <Paragraph>Bestsellers products</Paragraph>
          </TitleWrapper>
          {dashboard?.products && dashboard?.products.length > 0 ? (
            <PieChart width={650} height={300}>
              <Pie
                data={dashboard?.products || []}
                dataKey="amount"
                nameKey="name"
                cx="50%"
                cy="50%"
                labelLine={false}
                innerRadius={80}
                outerRadius={110}
                fill="#8884d8"
              >
                {(dashboard?.products || []).map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={["#143255", "#8884d8", "#0095c0"][index % 3]}
                  />
                ))}
              </Pie>
              <Label
                value="Amount sold"
                position="center"
                fill="#143255"
                fontSize={16}
              />
              <Tooltip
                formatter={(value, name, props) => <span>{` ${value}`}</span>}
              />
              <text
                x="40%"
                y="50%"
                textAnchor="middle"
                dominantBaseline="central"
                fill="#143255"
                fontSize="50"
              >
                {dashboard?.products &&
                  dashboard?.products.length > 0 &&
                  `${dashboard?.products[0].amount.toFixed(0)}`}
              </text>
              <Legend
                layout="vertical"
                align="right"
                verticalAlign="middle"
                wrapperStyle={{ fontSize: "22px" }}
                formatter={(value) =>
                  value.charAt(0).toUpperCase() + value.slice(1)
                }
              />
            </PieChart>
          ) : (
            <NoneParagraphWrapper>
              <NoneParagraph>
                A graph will appear here when you receive your first products
              </NoneParagraph>
            </NoneParagraphWrapper>
          )}
        </ReturnRate>
        <OrderDetails>
          <TitleWrapper>
            <Paragraph>Operational Efficiency</Paragraph>
          </TitleWrapper>
          {data1 && data1.length > 0 ? (
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={data1}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip
                formatter={(value) => `${value} days`}
                cursor={{ fill: "transparent" }}
              />
              <Legend wrapperStyle={{ fontSize: "22px" }} />
              <Bar
                dataKey="avgTime"
                name="Average Time (days)"
                fill="#0095c0"
                barSize={20}
              />
            </BarChart>
          </ResponsiveContainer>
          ) : (
            <NoneParagraphWrapper>
              <NoneParagraph>
                A graph will appear here when you receive your first products
              </NoneParagraph>
            </NoneParagraphWrapper>
          )}
        </OrderDetails>
      </DiagramWrapper>

      <TableWrapper>
      {transformedArray && transformedArray.length > 0 ? (
        <Table>
          <Thead>
            <Tr>
              <TableHeader>Country</TableHeader>
              <TableHeader>Users</TableHeader>
              <TableHeader>Transactions</TableHeader>
              <TableHeader>Revenue</TableHeader>
            </Tr>
          </Thead>
          <TableBody>
            {transformedArray.map(
              ({ name, totalCustomers, transactions, revenue }) => (
                <Tr key={name}>
                  <Td>{name}</Td>
                  <Td>{totalCustomers.toString()}</Td>
                  <Td>{transactions.toString()}</Td>
                  <Td>{revenue.toString()}</Td>
                </Tr>
              )
            )}
          </TableBody>
        </Table>
         ) : (
          <NoneParagraphWrapper>
            <NoneParagraph>
              A table will appear here when you receive your first products
            </NoneParagraph>
          </NoneParagraphWrapper>
        )}
      </TableWrapper>

      <MapWrapper>
        <WorldMap4 />
      </MapWrapper>
    </Container>
  );
};

export default DashboardPage;
