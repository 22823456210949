import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  gap: 30px;
  margin-bottom: 30px;
`;

export const AboutWrapper = styled.div`
  max-width: 765px;
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const ImageWrapper = styled.div`
  max-width: 765px;
  width: 100%;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const AvatarWrapper = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`;

export const Avatar = styled.img`
  width: 100px;
  height: 100px;

  display: flex;

  border-radius: 100px;
  object-fit: cover;
`;

export const Title = styled.h3`
  margin: auto 0;

  font-size: 24px;
  font-weight: 500;
  line-height: 140%;

  color: var(--txt-dark, #001731);
`;

export const SocialWrapper = styled.div`
  display: flex;
  gap: 44px;
`;

export const AvailabilityWrapper = styled.div`
  display: flex;
  gap: 10px;
  padding: 8px 20px 10px 20px;

  border-radius: 16px;
  border: 1px solid var(--green, #03b62a);
`;

export const Label = styled.h4`
  font-size: 17px;
  font-weight: 500;
  line-height: 140%;
  white-space: nowrap;
  color: var(--txt-dark, #001731);
`;

export const Value = styled.h5`
  font-size: 17px;
  line-height: 140%;
  color: var(--txt-green, #03b62a);
`;

export const SocialMedia = styled.div`
  display: flex;
  gap: 20px;
`;

export const Link = styled.a`
  cursor: pointer;
`;

export const IndustryValue = styled.h5`
  font-size: 17px;
  line-height: 140%;

  color: var(--txt-blue, #0095c0);
`;
export const FeeValue = styled.h5`
  font-size: 17px;
  line-height: 140%;

  color: rgba(3, 182, 42, 1);
`;

export const Wrapper = styled.div`
  display: flex;
  gap: 10px;
`;

export const KeywordsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 765px;
  gap: 20px;
`;

export const Keyword = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 8px 16px 10px 16px;

  border-radius: 12px;
  border: 1px solid var(--border, #bdbdbd);
  background: var(--gray-light, #fafafa);
  white-space: nowrap;
  transition: background 1.5s ease, color 0.3s ease;
  cursor: pointer;
  &:hover {
    background: linear-gradient(
      225deg,
      rgb(0, 149, 192) 17.746%,
      rgba(3, 182, 42, 0.6) 90.792%
    );
    border: 1px solid transparent;
    transition: background 1.5s ease, color 0.3s ease;
    & > h6 {
      color: var(--txt-light-blue, #f4ffff);
    }
  }
  &:active {
    background: var(--button, #03b62a);
    & > h6 {
      color: var(--txt-light-blue, #f4ffff);
    }
  }
`;

export const KeywordText = styled.h6`
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;

  color: var(--txt-dark, #001731);
`;

export const AboutBlockWrapper = styled.div``;
export const About = styled.h5`
  font-size: 17px;
  font-weight: 400;
  line-height: 140%;

  color: var(--txt-dak-light, #143255);
`;

export const ListWrapper = styled.div`
  display: flex;
  gap: 46px;
`;

export const List = styled.ul`
  max-width: 431px;
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 14px;
  li:nth-child(5) h4 {
    max-width: 138px;
    width: 100%;
  }

  li:nth-child(5) {
    align-items: baseline;
  }
`;

export const Item = styled.li`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const ListValue = styled.h5`
  font-size: 17px;

  line-height: 140%;
  color: var(--txt-dak-light, #143255);
`;

export const IconWrapper = styled.div``;

export const Listspecial = styled.ul`
  max-width: 257px;
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 14px;
`;

export const ProductWrapper = styled.div`
  margin-bottom: 20px;
`;

export const PaginationBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ValueRate = styled.h4`
  font-size: 17px;
  font-weight: 400;
  line-height: 140%;

  color: var(--txt-dak-light, #143255);
`;

export const SuccessWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const ButtonWrapper = styled.div`
  max-width: 263px;
  width: 100%;
  display: flex;
  gap: 30px;
  margin: 23px 0;
`;

export const TitleError = styled.p`
  height: 70vh;

  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  color: var(--txt-dark, #001731);
  font-size: 38px;
  font-weight: 500;
  line-height: 120%;
`;


export const DownWrapper=styled.div`
display: flex;
align-items: center;
gap: 5px;
`

export const Up = styled.span`
  color: var(--txt-green, #03b62a);
`;


export const ConfirmedWrapper = styled.div`
  position: relative;
  width: 24px;
  height: 24px;
  margin-left: 10px;
`;


export const VectorWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
`;


export const RatingReviewsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
`;


export const LocationText=styled.p``;