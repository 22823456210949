import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  CloseWrapper,
  CodeInput,
  CodeTitle,
  CodeWrapper,
  Container,
  InputWrapper,
  Subtitle,
  TitleWrapper,
  Text,
  ButtonWrapper,
  TextRepeatCode,
  StyledLinkText,
  SubtitleError,
  SubtitleErrorWrapper,
} from "./styles";
import Close from "../Close/Close";
import Title from "../Title/Title";
import Button from "../Button/Button";
import { routes } from "../../routes/routes";
import { UserAccount, UserConfirm, UserResendCode } from "../../utils/types";
import {confirmUserAsync, fetchCountriesAsync, resendCode} from "../../store/slices/userSlice";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../store/hooks";

let currentOTPIndex: number = 0;
const TIMER_KEY = "timer";

const Confirm: React.FC = () => {
  const dispatch = useDispatch();
  const state = useAppSelector(state => state);
  const user: UserAccount = useAppSelector((store) => store.user.userAccount!);
  const [otp, setOtp] = useState<string[]>(new Array(6).fill(""));
  const [activeOTPIndex, setactiveOTPIndex] = useState<number>(0);
  const [timer, setTimer] = useState<number>(
    parseInt(localStorage.getItem(TIMER_KEY) || "300", 10)
  );
  const [errorCode, setErrorCode] = useState<string>("");
  const [copiedCode, setCopiedCode] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleOnChange = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = target;
    const newOtp: string[] = [...otp];
    newOtp[currentOTPIndex] = value.substring(value.length - 1);

    if (!value) setactiveOTPIndex(currentOTPIndex - 1);
    else setactiveOTPIndex(currentOTPIndex + 1);

    setOtp(newOtp);
  };

  const handleOnkeyDown = (
    { key }: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    currentOTPIndex = index;
    if (key === "Backspace") setactiveOTPIndex(currentOTPIndex - 1);
  };

  useEffect(() => {
    inputRef.current?.focus();
  }, [activeOTPIndex]);

  useEffect(() => {
    if (copiedCode) {
      navigator.clipboard
        .readText()
        .then((copiedText) => {
          const newOtp: string[] = copiedText.split("").slice(0, 6);
          setOtp(newOtp);
          setCopiedCode(false);
        })
        .catch((error) => {
          console.error("Error reading clipboard:", error);
          setErrorCode("Error reading clipboard");
        });
    }
  }, [copiedCode]);

  const resetTimer = () => {
    setTimer(300);
    localStorage.setItem(TIMER_KEY, "300");
  };

  useEffect(() => {
    // dispatch(fetchCountriesAsync() as any);
    const intervalId = setInterval(() => {
      setTimer((prevTimer) => {
        const newTimer = prevTimer > 0 ? prevTimer - 1 : 0;
        if (newTimer === 0) {
          localStorage.removeItem(TIMER_KEY);
        } else {
          localStorage.setItem(TIMER_KEY, newTimer.toString());
        }
        return newTimer;
      });
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1);
  };

  const handleConfirmClick = () => {
    if (otp.join("") === "") {
      setErrorCode("Enter code*");
      return;
    } else if (otp.join("").length < 6) {
      setErrorCode("Enter code*");
      return;
    }
    dispatch(
      confirmUserAsync({
        code: otp.join(""),
        id: user.id,
      } as UserConfirm) as any
    )
      .then((result: any) => {
        if (result.payload.message === "401") {
          setErrorCode("Incorrect code entered*");
        } else if (result.payload.message === "404") {
          setErrorCode("User not found*");
          navigate(-1);
        } else {
          localStorage.removeItem(TIMER_KEY);
          // navigate(routes.PERSONAL);
        }
      })
      .catch((error: any) => {
        console.error("Login failed:", error);
      });
  };

  const handleResendClick = () => {
    dispatch(
      resendCode({ id: user.id } as UserResendCode) as any
    )
      .then((result: any) => {
        if (result.payload.message === "429") {
          setErrorCode(
            "Please wait for the specified time and try the request again*"
          );
        } else {
          resetTimer();
        }
      })
      .catch((error: any) => {
        console.error("failed:", error);
      });
  };

  const handlePasteCode = () => {
    navigator.clipboard
      .readText()
      .then((copiedText) => {
        const newOtp: string[] = Array(6).fill("");
        const copiedOtp = copiedText.split("").slice(0, 6);
        copiedOtp.forEach((char, index) => {
          newOtp[index] = char;
        });
        setOtp(newOtp);
        setCopiedCode(false);
        setactiveOTPIndex(copiedOtp.length - 1);
      })
      .catch((error) => {
        console.error("Error reading clipboard:", error);
        setErrorCode("Error reading clipboard");
      });
  };

  return (
    <Container>
      <CloseWrapper onClick={handleBack}>
        <Close />
      </CloseWrapper>
      <TitleWrapper>
        <Title text={"Confirmation"} />
      </TitleWrapper>
      <Subtitle>We have sent a confirmation code to your email</Subtitle>
      <SubtitleErrorWrapper>
        {errorCode && <SubtitleError>{errorCode}</SubtitleError>}
      </SubtitleErrorWrapper>

      <CodeWrapper>
        <CodeTitle>Code</CodeTitle>
        <InputWrapper>
          {otp.map((_, index) => (
            <React.Fragment key={index}>
              <CodeInput
                color={errorCode}
                ref={index === activeOTPIndex ? inputRef : null}
                type="number"
                onChange={handleOnChange}
                onKeyDown={(e) => handleOnkeyDown(e, index)}
                value={otp[index]}
                onPaste={handlePasteCode}
              />
            </React.Fragment>
          ))}
        </InputWrapper>
        <Text>
          Please enter the code: {Math.floor(timer / 60)}:
          {timer % 60 < 10 ? `0${timer % 60}` : timer % 60}
        </Text>
        <ButtonWrapper>
          <Button
              disabled={state.user.status === "loading"}
              text={ state.user.status === "loading" ? "loading..." : "Confirm"}
              onClick={handleConfirmClick} />
        </ButtonWrapper>
        <TextRepeatCode>
          If you haven't received the code within this time,{" "}
          <StyledLinkText onClick={handleResendClick}>
            click here
          </StyledLinkText>
        </TextRepeatCode>
      </CodeWrapper>
    </Container>
  );
};

export default Confirm;
