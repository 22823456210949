import React, { useEffect, useState } from "react";
import CompanyFilter from "../components/Filter/InternetSearchFilter";
import ListPage from "../components/ListPage/ListPage";
import styled from "styled-components";
import TitleHeader from "../components/TitleHeader/TitleHeader";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { routes } from "../routes/routes";
import { FactoryInternetSearch, InternetSearchResponse } from "../utils/types";
import ArrowUpDownIcon from "../assets/icons/ArrowUpDownIcon";
import { setKeywordsSearch } from "../store/slices/internetSearchSlice";
import { getAllFavorite, getGeocodeByCountryCity, searchKeywords, searchTopKeywords } from "../api/internetSearch";
import SearchIcon from "../assets/icons/SearchIcon";
import PopularRequest from "../components/PopularRequest/PopularRequest";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 30px;
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ListContainer = styled.div`
  margin-right: 30px;
  /* max-width: 1162px; */
  width: 100%;
`;

export const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const SwitchButton = styled.button<{
  isActive?: boolean;
}>`
  display: flex;
  padding: 14px 30px 16px 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 16px;
  border: 1px solid var(--border, #bdbdbd);
  background: var(--wiht, #fff);

  color: var(--txt-dark, #001731);
  font-size: 17px;
  font-weight: 400;
  line-height: 140%;

  background-color: ${({ isActive }) =>
    isActive ? "var(--batton, #0095C0)" : "var(--wiht, #FFF)"};
  color: ${({ isActive }) =>
    isActive ? " var(--wiht, #FFF)" : "var(--txt-dark, #001731)"};
`;

export const IconContainer = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(90deg);
`;

export const InputBlock = styled.div`
  display: flex;
  margin: 20px 0 30px 0;
`;

export const Input = styled.input`
  /* max-width: 938px; */
  width: 100%;

  padding: 14px 0px 16px 20px;
  border-radius: 16px 0px 0px 16px;
  border: 1px solid var(--border, #bdbdbd);
  border-right: none;
  background: var(--wiht, #fff);
  &::placeholder {
    font-size: 17px;
    color: var(--txt-grey-light, #717880);
  }
`;

export const ButtonBlock = styled.button`
  max-width: 224px;
  width: 100%;
  padding: 12px 100px;
  border-radius: 0px 16px 16px 0px;
  background: var(--batton, #0095c0);
`;

export const RequestBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-right: 20px;
`;

export const Tittle = styled.p`
  font-size: 24px;
  font-weight: 500;
  line-height: 140%;
  color: var(--txt-dark, #001731);
`;

export const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
`;

const InternetSearch: React.FC = () => {
  const user = useAppSelector((state) => state.user);
  const companies = useAppSelector<FactoryInternetSearch[]>(
    (state) => state.internetSearch?.internetSearchResponse?.content || []
  );
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const token = useAppSelector((state) => state.user.userAccount?.token);

  const { keywordsSearch, pending } = useAppSelector(
    (state) => state.internetSearch
  );

  const [industrySearchText, setIndustrySearchText] = useState(keywordsSearch);
  const topKeywords = useAppSelector(
    (state) => state.internetSearch.topKeywords
  );

  const [country, setCountry] = useState("");
  const [city, setCity] = useState<string>("");
  const [rating, setRating] = useState<string>("");
  const [reviews, setReviews] = useState<string>("");
  const [industry, setIndustry] = useState<string>("");
  const [sorted, setSorted] = useState<string>("");

  const page = 0;

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIndustrySearchText(e.target.value);
  };

  const handleSearchClick = () => {
    dispatch(setKeywordsSearch(industrySearchText));
    if (
      country === "" &&
      rating === "" &&
      city === "" &&
      reviews === "" &&
      industry === ""
    ) {
      dispatch(searchKeywords(token as string, page));
    } else {
      dispatch(
        getGeocodeByCountryCity(
          { country, city },
          page,
          rating,
          reviews,
          industry,
          "",
          token as string
        )
      );
    }
  };

  useEffect(() => {
    setIndustrySearchText(keywordsSearch);
  }, [keywordsSearch]);

  useEffect(() => {
    if (!user.userAccount) {
      navigate(routes.HOME);
    }
  }, [user, navigate]);

  // const [filteredCompanies, setFilteredCompanies] =
  //   useState<FactoryInternetSearch[]>(companies);

  const [activeButton, setActiveButton] = useState<"All" | "Saved">("All");

  // const handleFilterChange = (filteredCompanies: FactoryInternetSearch[]) => {
  //   setFilteredCompanies(filteredCompanies);
  // };

  const handleButtonClick = (button: "All" | "Saved") => {
    setActiveButton(button);
    if (button === "Saved") {
      dispatch(getAllFavorite(user?.token as string, page));
    }
  };

  const handleIconClick = () => {
    setActiveButton((prevButton) => (prevButton === "All" ? "Saved" : "All"));
  };



  return (
    <MainContainer>
      <Container>
        <TitleHeader text={"Search"} />

        <InputBlock>
          <Input
            type="text"
            placeholder="Search by product, industry or keywords"
            value={industrySearchText}
            onChange={handleInputChange}
          />
          <ButtonBlock onClick={handleSearchClick}>
            <SearchIcon />
          </ButtonBlock>
        </InputBlock>
        <TopWrapper>
          <RequestBlock>
            <Tittle>Popular:</Tittle>
            {topKeywords.map((keyword) => (
              <PopularRequest name={keyword} key={keyword} />
            ))}
          </RequestBlock>
          <SwitchContainer>
            <SwitchButton
              isActive={activeButton === "All"}
              onClick={() => handleButtonClick("All")}
            >
              All
            </SwitchButton>
            <IconContainer onClick={handleIconClick}>
              <ArrowUpDownIcon />
            </IconContainer>
            <SwitchButton
              isActive={activeButton === "Saved"}
              onClick={() => handleButtonClick("Saved")}
            >
              Saved
            </SwitchButton>
          </SwitchContainer>
        </TopWrapper>
        {activeButton === "All" && (
          <CompanyFilter
            companyItems={companies}
            setCountry={setCountry}
            setCity={setCity}
            rating={rating}
            setRating={setRating}
            reviews={reviews}
            setReviews={setReviews}
            industry={industry}
            setIndustry={setIndustry}
          />
        )}
      </Container>
      <ListContainer>
        <ListPage
          companies={companies}
          activeButton={activeButton}
          selectedCountry={country}
          selectedCity={city}
          rating={rating}
          reviews={reviews}
          industry={industry}
          sorted={sorted}
          setSorted={setSorted}
        />
      </ListContainer>
    </MainContainer>
  );
};

export default InternetSearch;
