import React from "react";
import {
  AboutContainer,
  AvailabilityContainer,
  AvailabilityContent,
  AvailabilityHeader,
  Avatar,
  AvatarContainer,
  AvatarNameContainer,
  BlueDot,
  ButtonReturn,
  ButtonSaveWrapper,
  ButtonWrapper,
  ButtonsContainer,
  CompanyInfoContainer,
  CompanyPageContainer,
  ImagesContainer,
  InfoContainer,
  InfoContainerPlus,
  InfoContainerPlusWrapper,
  InfoContent,
  InfoElement,
  InfoHeader,
  KeywordElement,
  KeywordsContainer,
  LinkElement,
  LinksContainer,
  LogoContainer,
  LogoElement,
  LogoHeader,
  LogoWrapper,
  NameContainer,
  NameHeader,
  PhotosWrapper,
  ProductWrapper,
  StarAndNameContainer,
} from "./styles";
import { defaultSelfman } from "../../../assets/img";
import InstIcon from "../../../assets/icons/InstIcon";
import FbIcon from "../../../assets/icons/FbIcon";
import LinInIcon from "../../../assets/icons/LinInIcon";
import SiteIcon from "../../../assets/icons/SiteIcon";
import StarGreen from "../../../assets/icons/StarGreenIcon";
import {
  CompanyProfile,
  Item,
  allMarketplacesContentType,
} from "../../../utils/types";
import { parseDateString } from "../Company";
import SlidePhoto from "../../SlidePhoto/SlidePhoto";
import ProductInfo from "../../ProductInfo/ProductInfo";
import Button from "../../Button/Button";
import { useAppSelector } from "../../../store/hooks";
import ModalLostChanges from "../../ModalWindows/ModalLostChanges/ModalLostChanges";

interface Props {
  companyState: CompanyProfile;
  save: () => void;
  edit: () => void;
  discard: () => void;
  editing: () => void;
  showModal: boolean;
  isStateChanged: boolean;
}

interface SocialIconProps {
  link: string;
  children: React.ReactNode;
}

const SocialIcon: React.FC<SocialIconProps> = ({ link, children }) => {
  const handleClickLink = () => {
    window.location.href = link;
  };

  return link !== "" ? (
    <a href={link} onClick={handleClickLink}>
      <LinkElement>{children}</LinkElement>
    </a>
  ) : null;
};

const CompanyProfilePreview: React.FC<Props> = ({
  companyState: {
    avatar,
    companyName,
    founded,
    businessType,
    operationInfo,
    numberOfEmployees,
    industry,
    dateCreate,
    completionRate,
    keywords,
    about,
    website,
    instagram,
    facebook,
    linkedIn,
    experienceMarketplace,
    languages,
    shippingTo,
    paymentTerms,
    availability,
    customizationCapacity,
    rndCapacity,
    specialPacking,
    whiteLabel,
    certification,
    sample,
    products,
    photos,
  },
  edit,
  save,
  showModal,
  discard,
  editing,
  isStateChanged,
}) => {
  // const companyPhoto = products!.images!.find(p=>p.name==="companyPhoto")?.photo_url||[];

  const marketplacesLogos =
    useAppSelector((store) => store.user.allMarketplaces) || [];
  console.log(products);

  function handleReturnClick(): void {
    edit();
  }

  function handleSaveClick(): void {
    save();
  }

  return (
    <>
      <CompanyPageContainer>
        <CompanyInfoContainer>
          <AvatarNameContainer>
            <AvatarContainer>
              {avatar ? (
                <Avatar src={avatar} alt={companyName} />
              ) : (
                <Avatar src={defaultSelfman} alt={defaultSelfman} />
              )}
            </AvatarContainer>
            <NameContainer>
              <StarAndNameContainer>
                <StarGreen />
                <NameHeader>{companyName}</NameHeader>
              </StarAndNameContainer>
              <AvailabilityContainer>
                <AvailabilityHeader>Availability: </AvailabilityHeader>
                <AvailabilityContent>{availability}</AvailabilityContent>
              </AvailabilityContainer>
            </NameContainer>
          </AvatarNameContainer>

          <InfoContainer>
            <InfoElement>
              <BlueDot /> <InfoHeader>Joined SelfMan in:</InfoHeader>{" "}
              <InfoContent>
                {dateCreate ? parseDateString(dateCreate) : ""}
              </InfoContent>
            </InfoElement>
            <InfoElement>
              <BlueDot /> <InfoHeader>Founded:</InfoHeader>{" "}
              <InfoContent>{founded}</InfoContent>
            </InfoElement>
            <InfoElement>
              <BlueDot /> <InfoHeader>Business type:</InfoHeader>{" "}
              <InfoContent>{businessType}</InfoContent>
            </InfoElement>
            <InfoElement>
              <BlueDot /> <InfoHeader>Operational address:</InfoHeader>{" "}
              <InfoContent>{operationInfo?.address || ""}</InfoContent>
            </InfoElement>
            <InfoElement>
              <BlueDot /> <InfoHeader>Industry:</InfoHeader>{" "}
              <InfoContent>{industry!.join(", ")}</InfoContent>
            </InfoElement>
            <InfoElement>
              <BlueDot /> <InfoHeader>Number of employees:</InfoHeader>{" "}
              <InfoContent>{numberOfEmployees}</InfoContent>
            </InfoElement>
          </InfoContainer>

          <InfoHeader>Keywords:</InfoHeader>
          <KeywordsContainer>
            {keywords && keywords.length > 0
              ? keywords.map((kw, i) => (
                  <KeywordElement key={kw + 1}>{kw}</KeywordElement>
                ))
              : null}
          </KeywordsContainer>

          <AboutContainer>{about}</AboutContainer>

          <LogoContainer>
            <LogoHeader>Experience on marketplaces</LogoHeader>
            <LogoWrapper>
              {experienceMarketplace && experienceMarketplace.length > 0
                ? experienceMarketplace!.map((mp) => {
                    const logo = marketplacesLogos.find(
                      (item: allMarketplacesContentType) => item.name === mp
                    );
                    if (logo) {
                      return <LogoElement key={mp} src={logo.icon} alt={mp} />;
                    } else {
                      return null;
                    }
                  })
                : null}
            </LogoWrapper>
          </LogoContainer>

          <InfoContainerPlus>
            <InfoContainerPlusWrapper>
              <InfoElement>
                <BlueDot /> <InfoHeader>Languages:</InfoHeader>{" "}
                <InfoContent>
                  {languages && languages.length > 0
                    ? languages.join(", ")
                    : ""}
                </InfoContent>
              </InfoElement>
              <InfoElement>
                <BlueDot /> <InfoHeader>Shipping to:</InfoHeader>{" "}
                <InfoContent>
                  {shippingTo && shippingTo.length > 0 ? shippingTo[0] : ""}
                </InfoContent>
              </InfoElement>
              <InfoElement>
                {/* <BlueDot/> <InfoHeader>Payment terms:</InfoHeader> <InfoContent>{paymentTerms&&paymentTerms.length>0? paymentTerms[0]:""}</InfoContent> */}
                <BlueDot /> <InfoHeader>Payment terms:</InfoHeader>{" "}
                <InfoContent>{paymentTerms}</InfoContent>
              </InfoElement>
              <InfoElement>
                <BlueDot /> <InfoHeader>Sample:</InfoHeader>{" "}
                <InfoContent>{sample ? "Yes" : "No"}</InfoContent>
              </InfoElement>
            </InfoContainerPlusWrapper>

            <InfoContainerPlusWrapper>
              <InfoElement>
                <BlueDot /> <InfoHeader>Customization capacity:</InfoHeader>{" "}
                <InfoContent>
                  {customizationCapacity ? "Yes" : "No"}
                </InfoContent>
              </InfoElement>
              <InfoElement>
                <BlueDot /> <InfoHeader>R&D capacity:</InfoHeader>{" "}
                <InfoContent>{rndCapacity ? "Yes" : "No"}</InfoContent>
              </InfoElement>
              <InfoElement>
                <BlueDot /> <InfoHeader>Special packing:</InfoHeader>{" "}
                <InfoContent>{specialPacking ? "Yes" : "No"}</InfoContent>
              </InfoElement>
              <InfoElement>
                <BlueDot /> <InfoHeader>White label:</InfoHeader>{" "}
                <InfoContent>{whiteLabel ? "Yes" : "No"}</InfoContent>
              </InfoElement>
              <InfoElement>
                <BlueDot /> <InfoHeader>Certification:</InfoHeader>{" "}
                <InfoContent>{certification ? "Yes" : "No"}</InfoContent>
              </InfoElement>
            </InfoContainerPlusWrapper>
          </InfoContainerPlus>

          <LinksContainer>
            {website !== "" && (
              <SocialIcon link={website!}>
                <SiteIcon />
              </SocialIcon>
            )}
            {instagram !== "" && (
              <SocialIcon link={instagram!}>
                <InstIcon />
              </SocialIcon>
            )}
            {facebook !== "" && (
              <SocialIcon link={facebook!}>
                <FbIcon />
              </SocialIcon>
            )}
            {linkedIn !== "" && (
              <SocialIcon link={linkedIn!}>
                <LinInIcon />
              </SocialIcon>
            )}
          </LinksContainer>
        </CompanyInfoContainer>
        <ImagesContainer>
          <PhotosWrapper>
            <SlidePhoto photos={photos || []} />
          </PhotosWrapper>
          {products && (
            <>
              <ProductWrapper>
                {products.content?.map((product) => (
                  <ProductInfo key={product.id} products={product} />
                ))}
              </ProductWrapper>
              {/* <ButtonWrapper >
                <Button text={"View all products"} />
              </ButtonWrapper> */}
            </>
          )}
        </ImagesContainer>
      </CompanyPageContainer>
      <ButtonsContainer>
        <ButtonReturn onClick={handleReturnClick}>Return to edit</ButtonReturn>
        {isStateChanged && (
          <ButtonSaveWrapper>
            <Button text={"Save"} onClick={handleSaveClick} />
          </ButtonSaveWrapper>
        )}
      </ButtonsContainer>
      {showModal && (
        <ModalLostChanges
          text="The changes you made to  this page will be lost"
          buttonText="Keep editing"
          buttonWhitetext="Discard"
          handleWhiteButtonClick={discard}
          handleButtonClick={editing}
        />
      )}
    </>
  );
};

export default CompanyProfilePreview;
