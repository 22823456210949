import React from 'react'
import { CloseWrapper, DialogContent, Overlay, Title } from '../ModalStyle'
import ModalConfirmationEmailAndPhone from '../ModalConfirmationEmailAndPhone/ModalConfirmationEmailAndPhone'
import Close from '../../Close/Close'
import { TitleWrapper } from '../ModalConfirmationEmailAndPhone/ModalConfirmationEmailAndPhoneStyles'

interface Props {
    handleConfirmationClick: () => void,
    replacementField: string
}

const ModalConfirmation = ({ handleConfirmationClick, replacementField }: Props) => {
    return (
        <>
            <Overlay />
            <DialogContent>
                <CloseWrapper onClick={handleConfirmationClick}>
                    <Close />
                </CloseWrapper>
                <TitleWrapper>
                    <Title>Confirmation</Title>
                </TitleWrapper>
                <ModalConfirmationEmailAndPhone replacementField={replacementField} handleFieldClick={handleConfirmationClick} />

            </DialogContent>
        </>
    )
}

export default ModalConfirmation