import React, { useEffect, useState } from "react";
import ArrowUpDownIcon from "../../../assets/icons/ArrowUpDownIcon";
import {
  IconContainer,
  MainContainer,
  SwitchButton,
  SwitchContainer,
  FilterByP,
  BasicInformation,
  ButtonInformation,
  ButtonClear,
  BasicInformationWrapper,
  BasicInformationOpen,
  ButtonFilter,
  ChevronRightWrapper,
  ClearWrapper,
} from "./style";
import ChevronRightIcon from "../../../assets/icons/ChevronRightIcon";
import ChevronDownIcon from "../../../assets/icons/ChevronDownIcon";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { useDispatch } from "react-redux";
import { PartnerBaseProvider, ProviderDetails, UserAccount } from "../../../utils/types";
import BasicInformationFilter from "../BasicInformationFilter/BasicInformationFilter";
import ManufactoringFilter from "../ManufactoringFilter/ManufactoringFilter";
import FinancicalDetailsFilter from "../FinancicalDetailsFilter/FinancicalDetailsFilter";
import ReputationFilter from "../ReputationFilter/ReputationFilter";
import CustomizeFilter from "../CustomizeFilter/CustomizeFilter";
import { changePartnerBase } from "../../../store/slices/sliceData";
import { getPartnerBaseFiltersValuesAsync } from "../../../store/slices/PartnerBaseSearch/partnerBaseSearchSlice";

interface PartnerBaseFilterProps {
  handleApplyFilter: () => void;
  filterValues: ProviderDetails;
  setFilterValues: React.Dispatch<React.SetStateAction<ProviderDetails>>;
  allProvider: PartnerBaseProvider[];
}

const PartnerBaseFilter: React.FC<PartnerBaseFilterProps> = ({
  handleApplyFilter,
  setFilterValues,
  filterValues,
  allProvider,
}) => {
  const [showBasicInformation, setShowBasicInformation] = useState(false);
  const [showManufactoringInformation, setShowManufactoringInformation] =
    useState(false);
  const [showReputationInformation, setShowReputationInformation] =
    useState(false);
  const [showFinancicalInformation, setShowFinancicalInformation] =
    useState(false);
  const [showCustomizeInformation, setShowCustomizeInformation] =
    useState(false);
  const [iconType, setIconType] = useState("ChevronRightIcon");
  // const [filterValues, setFilterValues] = useState({} as ProviderDetails);

  const page = useAppSelector((state) => state.themes.partnerBaseFavorites);
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();
  const token = useAppSelector((store) => store.user.userAccount?.token!);

  const handleButtonClick =
    (stateUpdater: (arg: (prevState: any) => boolean) => void, state: string) =>
      () => {
        stateUpdater((prevState) => !prevState);
        if (state === "ChevronRightIcon") {
          setIconType("ChevronDownIcon");
        } else {
          setIconType("ChevronRightIcon");
        }
      };

  const handleClick = (button: string) => {
    dispatch(changePartnerBase(button));
  };

  const handleIconClickMainContainer = () => {
    dispatch(changePartnerBase(page === "All" ? "Saved" : "All"));
  };

  // const handleApplyFilter = () => {
  //   dispatch(getAllProvidersAsync(filterValues) as any)
  //     .then((result: any) => {
  //       console.log("Result:", result.payload);
  //     })
  //     .catch((error: any) => {
  //       console.error("error", error);
  //     });
  // };

  // const handleClearAll = () => {
  //   setFilterValues({ page: 0, size: 12 });
  //   handleApplyFilter();
  // };

  // useEffect(() => {
  //   handleApplyFilter();
  // }, [filterValues]);

  const [clearAllClicked, setClearAllClicked] = useState(false);

  const handleClearAll = () => {
    setClearAllClicked(true);
    setFilterValues({});
  };

  if (clearAllClicked) {
    handleApplyFilter();
    setClearAllClicked(false);
  }

  const handleApplyFilterAndResetPage = () => {
    setFilterValues((prevValues) => ({ ...prevValues, page: 0 }));
    handleApplyFilter();
  };

  useEffect(() => {
    appDispatch(getPartnerBaseFiltersValuesAsync());
  }, []);
  

  return (
    <MainContainer>
      <SwitchContainer>
        <SwitchButton
          isActive={page === "All"}
          onClick={() => handleClick("All")}
        >
          All
        </SwitchButton>
        <IconContainer onClick={handleIconClickMainContainer}>
          <ArrowUpDownIcon />
        </IconContainer>
        <SwitchButton
          isActive={page === "Saved"}
          onClick={() => handleClick("Saved")}
        >
          Saved
        </SwitchButton>
      </SwitchContainer>

      <FilterByP>Filter by</FilterByP>

      <div>
        {!showBasicInformation ? (
          <>
            <BasicInformation
              onClick={handleButtonClick(
                setShowBasicInformation,
                iconType
              )}
            >
              <ButtonInformation>
                Basic Information
              </ButtonInformation>
              <ChevronRightWrapper
              >
                <ChevronRightIcon />
              </ChevronRightWrapper>
            </BasicInformation>
          </>
        ) : (
          <BasicInformationWrapper>
            <BasicInformationOpen>
              <ButtonInformation
                onClick={handleButtonClick(
                  setShowBasicInformation,
                  iconType
                )}
              >
                Basic Information
              </ButtonInformation>
              <ChevronRightWrapper
                onClick={handleButtonClick(setShowBasicInformation, iconType)}
              >
                <ChevronDownIcon />
              </ChevronRightWrapper>
            </BasicInformationOpen>
            <BasicInformationFilter
              companyItems={allProvider}
              setFilterValues={setFilterValues}
              filterValues={filterValues}
              onFilterChange={(newFilterValues) =>
                setFilterValues((prevFilterValues) => ({
                  ...prevFilterValues,
                  ...newFilterValues,
                }))
              }
            />
          </BasicInformationWrapper>
        )}
      </div>

      <div>
        {!showManufactoringInformation ? (
          <>
            <BasicInformation
              onClick={handleButtonClick(
                setShowManufactoringInformation,
                iconType
              )}
            >
              <ButtonInformation>
                Manufacturing & Shipping
              </ButtonInformation>
              <ChevronRightWrapper>
                <ChevronRightIcon />
              </ChevronRightWrapper>
            </BasicInformation>
          </>
        ) : (
          <BasicInformationWrapper>
            <BasicInformationOpen>
              <ButtonInformation
                onClick={handleButtonClick(
                  setShowManufactoringInformation,
                  iconType
                )}
              >
                Manufacturing & Shipping
              </ButtonInformation>
              <ChevronRightWrapper
                onClick={handleButtonClick(
                  setShowManufactoringInformation,
                  iconType
                )}
              >
                <ChevronDownIcon />
              </ChevronRightWrapper>
            </BasicInformationOpen>
            <ManufactoringFilter
              companyItems={allProvider || []}
              setFilterValues={setFilterValues}
              filterValues={filterValues}
              onFilterChange={(newFilterValues) =>
                setFilterValues((prevFilterValues) => ({
                  ...prevFilterValues,
                  ...newFilterValues,
                }))
              }
            />
          </BasicInformationWrapper>
        )}
      </div>

      <div>
        {!showFinancicalInformation ? (
          <>
            <BasicInformation
              onClick={handleButtonClick(
                setShowFinancicalInformation,
                iconType
              )}
            >
              <ButtonInformation>
                Financial Details
              </ButtonInformation>
              <ChevronRightWrapper>
                <ChevronRightIcon />
              </ChevronRightWrapper>
            </BasicInformation>
          </>
        ) : (
          <BasicInformationWrapper>
            <BasicInformationOpen>
              <ButtonInformation
                onClick={handleButtonClick(
                  setShowFinancicalInformation,
                  iconType
                )}
              >
                Financial Details
              </ButtonInformation>
              <ChevronRightWrapper
                onClick={handleButtonClick(
                  setShowFinancicalInformation,
                  iconType
                )}
              >
                <ChevronDownIcon />
              </ChevronRightWrapper>
            </BasicInformationOpen>
            <FinancicalDetailsFilter
              companyItems={allProvider || []}
              setFilterValues={setFilterValues}
              filterValues={filterValues}
              onFilterChange={(newFilterValues) =>
                setFilterValues((prevFilterValues) => ({
                  ...prevFilterValues,
                  ...newFilterValues,
                }))
              }
            />
          </BasicInformationWrapper>
        )}
      </div>

      <div>
        {!showReputationInformation ? (
          <>
            <BasicInformation
              onClick={handleButtonClick(
                setShowReputationInformation,
                iconType
              )}
            >
              <ButtonInformation>
                Reputation & Quality
              </ButtonInformation>
              <ChevronRightWrapper>
                <ChevronRightIcon />
              </ChevronRightWrapper>
            </BasicInformation>
          </>
        ) : (
          <BasicInformationWrapper>
            <BasicInformationOpen>
              <ButtonInformation
                onClick={handleButtonClick(
                  setShowReputationInformation,
                  iconType
                )}
              >
                Reputation & Quality
              </ButtonInformation>
              <ChevronRightWrapper
                onClick={handleButtonClick(
                  setShowReputationInformation,
                  iconType
                )}
              >
                <ChevronDownIcon />
              </ChevronRightWrapper>
            </BasicInformationOpen>
            <ReputationFilter
              companyItems={allProvider || []}
              setFilterValues={setFilterValues}
              filterValues={filterValues}
              onFilterChange={(newFilterValues) =>
                setFilterValues((prevFilterValues) => ({
                  ...prevFilterValues,
                  ...newFilterValues,
                }))
              }
            />
          </BasicInformationWrapper>
        )}
      </div>

      <div>
        {!showCustomizeInformation ? (
          <>
            <BasicInformation
              onClick={handleButtonClick(
                setShowCustomizeInformation,
                iconType
              )}
            >
              <ButtonInformation>
                Customization
              </ButtonInformation>
              <ChevronRightWrapper>
                <ChevronRightIcon />
              </ChevronRightWrapper>
            </BasicInformation>
          </>
        ) : (
          <BasicInformationWrapper>
            <BasicInformationOpen>
              <ButtonInformation
                onClick={handleButtonClick(
                  setShowCustomizeInformation,
                  iconType
                )}
              >
                Customization
              </ButtonInformation>
              <ChevronRightWrapper
                onClick={handleButtonClick(
                  setShowCustomizeInformation,
                  iconType
                )}
              >
                <ChevronDownIcon />
              </ChevronRightWrapper>
            </BasicInformationOpen>
            <CustomizeFilter
              companyItems={allProvider || []}
              setFilterValues={setFilterValues}
              filterValues={filterValues}
              onFilterChange={(newFilterValues) =>
                setFilterValues((prevFilterValues) => ({
                  ...prevFilterValues,
                  ...newFilterValues,
                }))
              }
            />
          </BasicInformationWrapper>
        )}
      </div>
      <ButtonFilter onClick={handleApplyFilterAndResetPage}>
        Apply Filter
      </ButtonFilter>
      <ClearWrapper
        onClick={() => {
          handleClearAll();
        }}
      >
        Clear all
      </ClearWrapper>
    </MainContainer>
  );
};

export default PartnerBaseFilter;
