import { Dispatch } from "@reduxjs/toolkit";
import { uploadFilesToServer } from "../store/slices/userSlice";

interface UploadFilesParams {
    files: File[];
    dispatch: Dispatch<any>;
}

export const uploadFiles = async ({ files, dispatch }: UploadFilesParams) => {
    try {
        const serverFilesUpload = await dispatch(uploadFilesToServer({ files}) as any)
            if (serverFilesUpload.payload.message === "400") {
                throw new Error("An error occurred while uploading files to the server. Please check your internet connection and try again.");
            };
         return serverFilesUpload;
    } catch (error) {
        console.error('Error uploading files:', error);
        throw error;
    }
}