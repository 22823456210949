import React, { useState } from "react";
import styled from "styled-components";
interface TrashIconIconProps {
  onClick?: () => void;
}

const IconContainer = styled.svg`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  cursor: pointer;

  /* Setting the default color */
  path {
    stroke: #001731;
  }

  /* Change color on hover */
  &:hover path {
    stroke: rgb(244, 255, 255); 
  }
`;

const TrashIcon: React.FC<TrashIconIconProps> = ({ onClick }) => {
  const [hovered, setHovered] = useState(false);

  return (
    <IconContainer
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={onClick}
    >
      <g clipPath="url(#clip3_21423)">
        <path
          id="Vector"
          d="M3 6L21 6"
          stroke={hovered ? "#0095c0" : "#001731"}
          strokeOpacity="1.000000"
          strokeWidth="2.000000"
          strokeLinejoin="round"
          strokeLinecap="round"
        />
        <path
          id="Vector"
          d="M19 6L19 20C19 21 18 22 17 22L7 22C6 22 5 21 5 20L5 6"
          stroke={hovered ? "#0095c0" : "#001731"}
          strokeOpacity="1.000000"
          strokeWidth="2.000000"
          strokeLinejoin="round"
          strokeLinecap="round"
        />
        <path
          id="Vector"
          d="M8 6L8 4C8 3 9 2 10 2L14 2C15 2 16 3 16 4L16 6"
          stroke={hovered ? "#0095c0" : "#001731"}
          strokeOpacity="1.000000"
          strokeWidth="2.000000"
          strokeLinejoin="round"
          strokeLinecap="round"
        />
        <path
          id="Vector"
          d="M10 11L10 17"
          stroke={hovered ? "#0095c0" : "#000000"}
          strokeOpacity="1.000000"
          strokeWidth="2.000000"
          strokeLinejoin="round"
          strokeLinecap="round"
        />
        <path
          id="Vector"
          d="M14 11L14 17"
          stroke={hovered ? "#0095c0" : "#000000"}
          strokeOpacity="1.000000"
          strokeWidth="2.000000"
          strokeLinejoin="round"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_3484_2187"
          x1="2.5"
          y1="13.5"
          x2="21"
          y2="9.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDDC30" />
          <stop offset="1" stopColor="#F2F700" />
        </linearGradient>
      </defs>
    </IconContainer>
  );
};

export default TrashIcon;
