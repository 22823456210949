import styled from "styled-components";

export const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 11;
`

export const ErrorNotification = styled.div`
position: absolute;
width: 600px;
height: auto;
left: 50%;
top: 50%;
transform: translate(-50%, -50%); 
display: flex;
flex-direction: column;
align-items: flex-start;
padding: 30px;
border-radius: 16px;
border: 1px solid var(--border, #e8e8e8);
background: var(--wiht, #fff);
z-index: 12;
`
export const NotificationHeader = styled.div`
width:100%;
display: flex;
align-items: center;
justify-content: space-between;
`
export const CloseIconWrapper = styled.div`
display:flex;
`
export const ErrorMessage = styled.div`
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: flex-start;
padding: 40px 20px;
order: 0;
align-self: stretch;
flex-grow: 1;
margin: -20px;
color: var(--txt-dark, #001731);
`
