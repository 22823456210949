import React, { SetStateAction, useState } from "react";
import {
  AboutProduct,
  ButtonContainer,
  ButtonWrapper,
  CompanyPhoto,
  CompanyPhotoElement,
  Container,
  EllipseWrapper,
  GreenParagraph,
  ImagesContainer,
  LeftWrapper,
  NameProduct,
  OrderQuantity,
  Paragraph,
  PhotoMinContainer,
  PhotoMinWrapper,
  PhotoMini,
  PhotoWrapper,
  RightWrapper,
  SubParagraph,
  SubTitle,
  UnitPrice,
  Wrapper,
} from "./style";
import Button from "../../Button/Button";
import ChevronDownIcon from "../../../assets/icons/ChevronDownIcon";
import ChevronUpIcon from "../../../assets/icons/ChevronUpIcon";

import { AddProduct, PhotoProduct } from "../../../utils/types";
import { defaultImg } from "../../../assets/img";
import ButtonWhite from "../../ButtonWhite/ButtonWhite";

interface ProductProps {
  productInfo: AddProduct;
  status: string;
  setPreviewMode: React.Dispatch<SetStateAction<boolean>>;
  saveClick: () => void;
  updateProductInfo?: AddProduct;
}

const ProductPreview: React.FC<ProductProps> = ({
  updateProductInfo,
  productInfo: {
    name,
    keywords,
    description,
    minimumOrderQuantity,
    price,
    images,
  },
  setPreviewMode,
  saveClick,
  status,
}) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [visibleStartIndex, setVisibleStartIndex] = useState<number>(0);
  const visibleCount = 6;

  const handleBack = () => {
    setPreviewMode(false);
  };

  const handleUpArrowClick = (images: PhotoProduct[]) => {
    const newIndex =
      currentIndex === 0 && images?.length
        ? images.length - 1
        : currentIndex - 1;
    const newVisibleStartIndex =
      newIndex < visibleStartIndex ? newIndex : visibleStartIndex;
    setCurrentIndex(newIndex);
    setVisibleStartIndex(newVisibleStartIndex);
    if (images && newIndex === images.length - 1) {
      setVisibleStartIndex(images.length - visibleCount);
    }
  };

  const handleDownArrowClick = (images: PhotoProduct[]) => {
    setCurrentIndex((prevIndex) => {
      const newIndex =
        images && prevIndex === images.length - 1 ? 0 : prevIndex + 1;
      if (newIndex >= visibleStartIndex + visibleCount) {
        setVisibleStartIndex(newIndex - visibleCount + 1);
      }
      if (newIndex === 0) {
        setVisibleStartIndex(0);
      }
      return newIndex;
    });
  };

  const handlePhotoMiniClick = (index: number) => {
    setCurrentIndex(index);
    if (index < visibleStartIndex) {
      setVisibleStartIndex(index);
    } else if (index >= visibleStartIndex + visibleCount) {
      setVisibleStartIndex(index - visibleCount + 1);
    }
  };

  return (
    <Container>
      <NameProduct>{updateProductInfo?.name || name}</NameProduct>
      <Wrapper>
        <LeftWrapper>
          <ImagesContainer>
            <CompanyPhoto>
              {updateProductInfo?.images &&
              updateProductInfo?.images.length > 0 ? (
                <>
                  <PhotoWrapper>
                    <CompanyPhotoElement
                      src={
                        updateProductInfo?.images[currentIndex].link ||
                        defaultImg
                      }
                      alt={updateProductInfo?.images[currentIndex].name}
                    />
                    <PhotoMinContainer
                      $isCenter={
                        updateProductInfo?.images.length > visibleCount
                      }
                    >
                      {updateProductInfo?.images.length > visibleCount && (
                        <EllipseWrapper
                          onClick={() =>
                            handleUpArrowClick(
                              updateProductInfo?.images as PhotoProduct[]
                            )
                          }
                        >
                          <ChevronUpIcon />
                        </EllipseWrapper>
                      )}
                      <PhotoMinWrapper>
                        {updateProductInfo?.images
                          .slice(
                            visibleStartIndex,
                            visibleStartIndex + visibleCount
                          )
                          .map((image, index) => (
                            <PhotoMini
                              key={visibleStartIndex + index}
                              src={image.link || defaultImg}
                              alt={image.name || ""}
                              onClick={() =>
                                handlePhotoMiniClick(visibleStartIndex + index)
                              }
                              $isOpen={
                                currentIndex === visibleStartIndex + index
                              }
                            />
                          ))}
                      </PhotoMinWrapper>
                      {updateProductInfo?.images.length > visibleCount && (
                        <EllipseWrapper
                          onClick={() =>
                            handleDownArrowClick(
                              updateProductInfo?.images as PhotoProduct[]
                            )
                          }
                        >
                          <ChevronDownIcon />
                        </EllipseWrapper>
                      )}
                    </PhotoMinContainer>
                  </PhotoWrapper>
                </>
              ) : (
                <>
                  {images && images.length > 0 && (
                    <PhotoWrapper>
                      <CompanyPhotoElement
                        src={images[currentIndex].link || defaultImg}
                        alt={images[currentIndex].name}
                      />
                      <PhotoMinContainer
                        $isCenter={images.length > visibleCount}
                      >
                        {images.length > visibleCount && (
                          <EllipseWrapper
                            onClick={() => handleUpArrowClick(images)}
                          >
                            <ChevronUpIcon />
                          </EllipseWrapper>
                        )}
                        <PhotoMinWrapper>
                          {images
                            .slice(
                              visibleStartIndex,
                              visibleStartIndex + visibleCount
                            )
                            .map((image, index) => (
                              <PhotoMini
                                key={visibleStartIndex + index}
                                src={image.link || defaultImg}
                                alt={image.name || ""}
                                onClick={() =>
                                  handlePhotoMiniClick(
                                    visibleStartIndex + index
                                  )
                                }
                                $isOpen={
                                  currentIndex === visibleStartIndex + index
                                }
                              />
                            ))}
                        </PhotoMinWrapper>
                        {images.length > visibleCount && (
                          <EllipseWrapper
                            onClick={() => handleDownArrowClick(images)}
                          >
                            <ChevronDownIcon />
                          </EllipseWrapper>
                        )}
                      </PhotoMinContainer>
                    </PhotoWrapper>
                  )}
                </>
              )}
            </CompanyPhoto>
          </ImagesContainer>
        </LeftWrapper>
        <RightWrapper>
          <AboutProduct>
            <Paragraph>
              {updateProductInfo?.description || description}
            </Paragraph>
          </AboutProduct>
          <OrderQuantity>
            <SubTitle>Minimum order quantity:</SubTitle>
            <SubParagraph>
              {updateProductInfo?.minimumOrderQuantity || minimumOrderQuantity}
            </SubParagraph>
          </OrderQuantity>
          <UnitPrice>
            <SubTitle>Unit price:</SubTitle>
            <GreenParagraph>
              {updateProductInfo?.price || price} $
            </GreenParagraph>
          </UnitPrice>
        </RightWrapper>
      </Wrapper>
      <ButtonContainer>
        <ButtonWrapper>
          <Button text={"Return to editing"} onClick={handleBack} />
        </ButtonWrapper>
        {updateProductInfo ? (
          <>
            <ButtonWrapper>
              <ButtonWhite
                disabled={status === "loading"}
                text={status === "loading" ? "loading..." : "Save"}
                onClick={saveClick}
              />
            </ButtonWrapper>
          </>
        ) : (
          name && (
            <ButtonWrapper>
              <ButtonWhite
                disabled={status === "loading"}
                text={status === "loading" ? "loading..." : "Save"}
                onClick={saveClick}
              />
            </ButtonWrapper>
          )
        )}
      </ButtonContainer>
    </Container>
  );
};

export default ProductPreview;
