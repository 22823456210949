import styled from "styled-components";
interface IsEditingProps {
    disabled: boolean;
}

interface FormFieldProps {
    isEmpty: boolean;
    isSaveClicked: boolean;
}

export const ProfileLogoContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 30px;
    border: 1px solid rgb(232, 232, 232);
    border-radius: 16px;
    /* wiht */
    background: rgb(255, 255, 255);
    margin-bottom: 30px;
`;

export const Wrapper = styled.div`
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 50% 50%;
    gap: 20px;
    grid-template-rows: 1;
`;

export const BusinessInfoWrapper = styled.div`
    gap: 15px;
    margin-bottom: 16px;
`;

export const RadioContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;
    height: 45px;
`;

export const RadioWrapper = styled.div`
    display: flex;
    gap: 4px;
`;

export const AvatarWrapper = styled.div`
    position: relative;
`;

export const Avatar = styled.img`
    width: 100px;
    height: 100px;
    display: flex;
    margin-bottom: 30px;
    border-radius: 100px;
    object-fit: cover;
`;

export const AvatarInput = styled.input`
    display: none;
`;

export const AvatarLabel = styled.label`
    position: absolute;
    left: 70px;
    top: 71px;
    cursor: pointer;
`;

export const Verify = styled.h6`
    font-size: 14px;
    line-height: 140%;

    color: var(--txt-red, #f00);
`;

export const FieldText = styled.h4`
    margin-bottom: 4px;

    font-size: 17px;
    font-weight: 400;
    line-height: 140%;

    color: var(--txt-dark, #001731);
    overflow: hidden;
    text-overflow: ellipsis;

    color: rgb(20, 50, 85);
`;

export const CompanyName = styled.h6`
    margin-bottom: 4px;

    font-size: 14px;
    line-height: 140%;

    color: var(--txt-dak-light, #143255);
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const EmailName = styled.h6`
    margin-bottom: 20px;

    font-size: 14px;
    line-height: 140%;

    color: var(--txt-blue, #0095c0);
`;

export const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
`;

export const DialogContent = styled.div`
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    border-radius: 16px;
    background: var(--wiht, #fff);
    padding: 30px 40px 40px;
    z-index: 2;

    max-width: 510px;
    width: 100%;
`;

export const Title = styled.h3`
    text-align: center;

    font-size: 24px;
    font-weight: 500;
    line-height: 140%;

    color: var(--txt-dark, #001731);
`;

export const ContentWrapper = styled.div`
    display: flex;
    padding: 10px 20px 20px 20px;
    flex-direction: column;
    gap: 20px;

    border-radius: 16px;
    background: var(--wiht, #fff);
`;

export const InputWrapper = styled.div`
    position: relative;
`;

export const Input = styled.input`
    max-width: 370px;
    width: 100%;
    padding: 8px 10px;
    font-size: 17px;
    line-height: 140%;
    border-bottom: 1px solid var(--border, #bdbdbd);

    &::placeholder {
        color: var(--txt-grey-light, #717880);
    }
    &:focus {
        border-bottom: 1px solid var(--batton, #0095c0);
    }
`;

export const TogglePasswordButton = styled.button`
    position: absolute;
    top: 6px;
    right: 0;
`;

export const InputContainer = styled.div``;

export const Text = styled.h6`
    font-size: 16px;
    line-height: 140%;
    color: var(--txt-dark, #001731);
    cursor: pointer;

    &:hover {
        color: var(--txt-blue, #0095c0);
    }
`;

export const CloseWrapper = styled.div`
    position: absolute;
    right: 20.5px;

    cursor: pointer;
`;

export const TextError = styled.h6`
    font-size: 14px;
    line-height: 140%;

    color: var(--txt-red, #f00);
`;

export const ErrorWrapper = styled.div`
    height: 17px;
    text-align: center;
`;

export const Subtitle = styled.p`
    font-size: 17px;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0%;
    text-align: left;
    color: var(--txt-light-blue-dont-active, #a0b7d1);
    margin-bottom: 4px;
`;

export const GenderSubtitle = styled.p`
    font-size: 17px;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0%;
    text-align: left;
    color: var(--txt-light-blue-dont-active, #a0b7d1);
`;

export const ButtonContainer = styled.div`
    display: flex;
    margin-left: auto;
    justify-content: flex-end;
    align-items: flex-end;
`;

export const VerifyContainer = styled.div`
    display: flex;
    gap: 5px;
`;

export const RequiredField = styled.input<FormFieldProps>`
    padding: 6px 0px 5px 10px;
    box-sizing: border-box;
    border: 1px solid
    ${({ isEmpty, isSaveClicked }) =>
            isEmpty && isSaveClicked ? "rgb(255, 0, 0)" : "rgb(232, 232, 232)"};
    border-radius: 12px;
    margin-bottom: 18px;
    width: 100%;
    height: 38px;

    &::placeholder {
        margin-bottom: 4px;
        font-size: 17px;
        font-weight: 400;
        line-height: 140%;
        overflow: hidden;
        text-overflow: ellipsis;
        color: var(--txt-light-blue-dont-active, #a0b7d1);
    }
`;

export const Field = styled.input`
    padding: 6px 0px 5px 10px;
    box-sizing: border-box;
    border: 1px solid rgb(232, 232, 232);
    border-radius: 12px;
    margin-bottom: 18px;
    width: 100%;
    height: 38px;

    &::placeholder {
        margin-bottom: 4px;

        font-size: 17px;
        font-weight: 400;
        line-height: 140%;

        overflow: hidden;
        text-overflow: ellipsis;

        color: var(--txt-light-blue-dont-active, #a0b7d1);
    }
`;

export const ConfirmField = styled.input`
    padding: 6px 0px 5px 10px;
    box-sizing: border-box;
    border: 1px solid rgb(232, 232, 232);
    border-radius: 12px;
    width: 100%;
    height: 40px;

    &::placeholder {
        margin-bottom: 4px;

        font-size: 17px;
        font-weight: 400;
        line-height: 140%;

        overflow: hidden;
        text-overflow: ellipsis;

        color: var(--txt-light-blue-dont-active, #a0b7d1);
    }
`;

export const EmailField = styled.input<FormFieldProps>`
    padding: 6px 0px 5px 10px;
    box-sizing: border-box;
    border: 1px solid
    ${({ isEmpty, isSaveClicked }) =>
            isEmpty && isSaveClicked ? "rgb(255, 0, 0)" : "rgb(232, 232, 232)"};
    border-radius: 12px;
    width: 100%;
    height: 40px;

    &::placeholder {
        margin-bottom: 4px;

        font-size: 17px;
        font-weight: 400;
        line-height: 140%;

        overflow: hidden;
        text-overflow: ellipsis;

        color: var(--txt-light-blue-dont-active, #a0b7d1);
    }
`;

export const LeftWrapper = styled.div``;

export const RightWrapper = styled.div`
`;

export const ButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 20px;
`;

export const ChangeEditWrapper = styled.div``;

export const ConfrmButton = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 20px 8px 20px;
    margin: 0px 20px;
    border: 1px solid rgb(3, 182, 42);
    border-radius: 8px;
    color: rgb(3, 182, 42);
    font-size: 17px;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0%;
`;

export const ChangeButton = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 20px 8px 20px;
    border: 1px solid rgb(0, 149, 192);
    border-radius: 8px;
    color: rgb(0, 149, 192);
    font-size: 17px;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0%;
    text-align: left;
`;

export const ButtonInputWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    gap: 10px;
`;

export const RightFieldWrapper = styled.div`
    margin-bottom: 25px;
`;


export const ChevronRightSettingsWrapper = styled.div`
    position: absolute;
    right: 6px;
    top: 7px;
`;

export const ItemWrapper = styled.div`
    width: 100%;

    padding: 6px 0 6px 10px;

    font-size: 17px;
    line-height: 140%;

    color: var(--txt-dak-light, #143255);
    border: 1px solid var(--border, #bdbdbd);
    border-top: none;
    border-radius: 12px;
    cursor: pointer;
    &:focus {
        border: 1px solid var(--batton, #0095c0);
    }
    &::placeholder {
        color: var(--txt-grey-light, #717880);
        font-size: 17px;
        font-weight: 400;
        line-height: 140%;
    }
`;

export const Item = styled.h4`
    padding: 8px 0px;

    font-size: 17px;
    line-height: 140%;

    color: var(--txt-dak-light, #143255);
    cursor: pointer;
    &:hover {
        color: var(--txt-blue, #0095c0);
    }
`;