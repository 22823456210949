import styled from "styled-components";

interface ToggleSwitchProps {
  $isActive?: boolean;
}

export const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const ToggleSwitch = styled.div<ToggleSwitchProps>`
  width: 16px;
  height: 16px;
  border-radius: 50px;
  transition: background-color 0.3s ease;
  border: ${(props) =>
    props.$isActive
      ? "4px solid var(--batton, #0095C0)"
      : "1px solid var(--battn-border, #004C62)"};
`;