import React from "react";

import ReturnButton from "../components/ReturnButton/ReturnButton";
import styled from "styled-components";
import { useAppSelector } from "../store/hooks";
import { RootState } from "../store/store";
import { Item } from "../utils/types";
import ProductById from "../components/CatalogProductsPage/ProductById/ProductById";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ItemByCatalogPruduct: React.FC = () => {
  const {
    providerById: { products },
  } = useAppSelector((state: RootState) => state.user);
  const productId = Number(window.location.href.split("/")[6]) || -1;
  const product = products.find(({ id }: Item) => id === productId);
  return (
    <Container>
      <ReturnButton />
      {product && <ProductById product={product} />}
    </Container>
  );
};

export default ItemByCatalogPruduct;
