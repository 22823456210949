import styled from "styled-components";

export const SlideContainer = styled.div`
  position: relative;
`;

export const SlideWrapper = styled.div`
  border-radius: 16px;
`;

export const Image = styled.img`
  max-width: 765px;
  width: 100%;
  border-radius: 16px;
  height: 470px;
`;
