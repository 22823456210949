import React from "react";
import { CompanyName, UserAvatar, UserName, UserWrapper } from "../styles";
import { useAppSelector } from "../../../store/hooks";
import { RootState } from "../../../store/store";
import { defaultAvatar } from "../../../assets/img";

interface Props {
  isOpen: boolean;
}

const SideBarUserDetails = ({ isOpen }: Props) => {
  const userAccount = useAppSelector(
    (state: RootState) => state.user.userAccount
  );
  // const userRole = user.customer || user.provider || user.agent;

  return (
    <>
      <UserAvatar src={userAccount?.avatar || defaultAvatar}></UserAvatar>
      <UserWrapper $isOpen={isOpen}>
        <UserName>
          {userAccount?.firstName} {userAccount?.lastName}
        </UserName>
        <CompanyName>{userAccount?.companyName}</CompanyName>
      </UserWrapper>
    </>
  );
};

export default SideBarUserDetails;
