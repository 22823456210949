import styled from "styled-components";

export const StyledDelegateSearch = styled.div`
  display: flex;
  justify-content: center;
  cursor: pointer;
  max-width: 1110px;
  width: 100%;
  padding: 209px 30px;

  background: linear-gradient(
    46deg,
    rgba(5, 119, 152, 0.8) 8.94%,
    rgba(80, 58, 214, 0.7) 46.63%,
    rgba(3, 173, 112, 0.7) 88.48%
  );

  border-radius: 16px;
`;

export const Title = styled.p`
  font-size: 24px;
  font-weight: 500;
  line-height: 140%;
  text-align: center;
  color: var(--txt-light-blue, #f4ffff);
`;
