import { NamedTupleMember } from "typescript";

export interface AddAgentToCustomerFavorite {
  agentEmail: string;
}
export interface deleteAgentFromCustomerFavorite {
  agentEmail: string;
}
export interface addProviderToCustomerFavorite {
  providerEmail: string;
}
export interface deleteProviderFromCustomerFavorite {
  providerEmail: string;
}
export interface AddAgentToProvideravorite {
  agentEmail: string;
}
export interface deleteAgentFromProviderFavorite {
  agentEmail: string;
}
export interface addProviderImages {
  name: string;
  photos_url: string[];
}
export interface updateProviderImages {
  id: number;
  name: string;
  photo_url: string[];
}
export interface Roles {
  name: string;
}
export interface PartnerBaseProvider {
  id: number;
  email: string;
  avatar: string;
  companyName: string;
  registrationInfo: {
    country: string;
    city: string;
  };
  industry: string[];
  keywords: string[];
  shippingTo: string[];
  products: Item[];
  rating: number;
  reviews: number;
  founded: number;
  languages: string[];
  availability: AvailabilityStatus;
  successRate: number;
  confirmed: boolean;
  roles: Roles[];
}

export enum TypeFee {
  HOUR = "HOUR",
  FIX = "FIX",
}

export enum CompanyType {
  CUSTOMER = "CUSTOMER",
  PROVIDER = "PROVIDER",
  AGENT = "AGENT",
}

export enum BusinessType {
  COMPANY = "COMPANY",
  INDIVIDUAL = "INDIVIDUAL",
}

export enum CurrencyType {
  USD = "USD",
  EUR = "EUR",
}

export enum UnitMeasurement {
  PCS = "pcs",
  KG = "kg",
  G = "g",
  L = "L",
  ML = "mL",
  M = "m",
  CM = "cm",
  IN = "in",
  FT = "ft",
  M2 = "m²",
  FT2 = "ft²",
  DOZ = "doz.",
  T = "t",
  OZ = "oz",
  LB = "lb",
}

export enum GenderType {
  MALE = "MALE",
  FEMALE = "FEMALE",
}

export interface AgentBaseAgent {
  id: number;
  email: string;
  avatar: string;
  confirmed: boolean;
  password: string;
  firstName: string;
  lastName: string;
  companyName: string;
  languages: string[];
  agentContactInfo: {
    country: string;
    city: string;
    street: string;
    building: string;
    zipCode: number;
    phoneNumber: string;
  };
  about: string;
  priceHour: number;
  fee: TypeFee;
  availability: AvailabilityStatus;
  category: string[];
  keywords: string[];
  attachments: string;
  successRate: number;
  rating: number;
  reviews: number;
  socialMedia: SocialMedia[];
  roles: Roles[];
}
export interface AgentDetails {
  category?: string[];
  keywords?: string[];
  country?: string[];
  city?: string[];
  languages?: string[];
  confirmed?: boolean;
  ratings?: number[];
  reviews?: number[];
  successRate?: number;
  minPrice?: number;
  maxPrice?: number;
  availability?: AvailabilityStatus[];
  fee?: TypeFee[];
  page?: number;
  size?: number;
}
export interface ProviderDetails {
  industry?: string[];
  keywords?: string[];
  country?: string[];
  city?: string[];
  founded?: number[];
  languages?: string[];
  availability?: AvailabilityStatus[];
  shippingTo?: string[];
  paymentTerms?: string[];
  confirmed?: boolean;
  ratings?: number[];
  reviews?: string[];
  successRate?: number[];
  minPrice?: number;
  maxPrice?: number;
  customizationCapacity?: boolean;
  rndCapacity?: boolean;
  specialPacking?: boolean;
  whiteLabel?: boolean;
  certification?: boolean;
  sample?: boolean;
  minimumOrderQuantity?: boolean;
  //what is it?
  milestonePayment?: boolean;
  partialPayment?: boolean;
  deferredPayment?: boolean;
  letterCredit?: boolean;
  discountPayment?: boolean;
  flexiblePayment?: boolean;
  verifiedFactory?: boolean;
  page?: number;
  size?: number;
  sort?: string;
}

export interface UserLogin {
  email: string;
  password: string;
}

export interface UpdateToken {
  refreshToken: string;
}

export interface UserRegister {
  email: string;
  password: string;
}

export interface profilUser {
  userAvatar: any;
  firstName: string;
  lastName: string;
  companyName: string;
  email: string;
}

export interface UserInformation {
  userAvatar?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  gender?: string;
  phoneNumber?: string;
  jobTitle?: string;
  attachments?: Attachment[];
}

// export interface updateUserAvatar {
//   avatar_link: string;
//   token: string;
//   userAvatar?: any;
//   firstName?: string;
//   lastName?: string;
//   companyName?: string;
//   email?: string;
//   gender?: string;
//   phoneNumber?: string;
//   jobTitle?: string;
//   attachments?: string[];
// }

export interface updateCustomerDataRequest {
  email?: string;
  firstName?: string;
  lastName?: string;
  companyName?: string;
  customerContactInfo?: {
    country?: string;
    city?: string;
    street?: string;
    building?: string;
    zipCode?: number;
    phoneNumber?: string;
  };
}

export interface updateAgentDataRequest {
  email: string;
  firstName: string;
  lastName: string;
  companyName: string;
  languages: string[];
  agentContactInfo: {
    country: string;
    city: string;
    street: string;
    building: string;
    zipCode: number;
    phoneNumber: string;
  };
}

export interface SocialMedia {
  name: string;
  link: string;
}

export interface updateProviderData {
  email: string;
  companyName: string;
  availability: AvailabilityStatus;
  about: string;
  shippingTo: string[];
  paymentTerms: string[];
  languages: string[];
  industry: string[];
  keywords: string[];
  founded: number;
  customizationCapacity: boolean;
  rndCapacity: boolean;
  specialPacking: boolean;
  whiteLabel: boolean;
  certification: boolean;
  sample: boolean;
  providerContactInfo: {
    country: string;
    city: string;
  };
  socialMedia: SocialMedia[];
}

export interface updateCustomerDataAbout {
  about?: string;
  attachments?: Attachment[];
}

export interface updateAgentDataAbout {
  about?: string;
}

export interface updateAgentDataSocial {
  socialMedia: SocialMedia[];
}

export interface uploadFiles {
  files: File[];
}

export interface uploadFilesResponse {
  payload: Attachment | { message: string };
}

export interface updateCustomerDataAttachments {
  attachments: Attachment[];
}

export interface updateCustomerAvatar {
  avatar_link: string;
}

export interface updateAgentAvatar {
  avatar_link: string;
}

export interface updateAvatar {
  avatar_link: string;
  token: string;
  companyType: CompanyType;
}

// export interface delegateSearches {
//   identification: string;
//   description: string;
//   attachments: string[];
//   quantity: number;
//   howOften: "WEEKLY" | "MONTHLY" | "QUARTERLY" | "ANNUALLY" | "OTHER";
//   commentHowOften: string;
//   priceBudget: number;
//   commentPriceBudget: string;
//   timeLine: string;
//   helpDelivery: boolean;
//   commentHelpDelivery: string;
//   helpCustomsClearance: boolean;
//   commentHelpCustomsClearance: string;
//   whereToDeliver: string;
//   qualityStandards: boolean;
//   commentQualityStandards: string;
//   needCertificate: boolean;
//   commentNeedCertificate: string;
//   specificPackaging: boolean;
//   commentSpecificPackaging: string;
//   specificLabeling: boolean;
//   commentSpecificLabeling: string;
//   paymentTerms: string;
//   additionalRequirements: string;
//   comment: string;
// }

export interface updateUserAvatar {
  avatar_link: string;
  token: string;
}

export interface CustomerAccount {
  id: string;
  email: string;
  avatar: string;
  firstName: string;
  lastName: string;
  companyName: string;
  confirmed: boolean;
  customerContactInfo: {
    country: string;
    city: string;
    building: string;
    street: string;
    zipCode: number;
    phoneNumber: string;
  };
  about: string;
  attachments: Attachment[];
  roles: Roles[];
  delegateSearches: string;
  requests: Requst[];
  favoriteAgents: AgentBaseAgent[];
  favoriteProviders: PartnerBaseProvider[];
}

export interface Attachment {
  id: number;
  link: string;
  name: string;
}

export interface AgentAccount {
  languages: string[];
  id: string;
  email: string;
  avatar: string;
  firstName: string;
  lastName: string;
  companyName: string;
  confirmed: boolean;
  fee: TypeFee;
  priceHour: number;
  successRate: number;
  agentContactInfo: {
    country: string;
    city: string;
    building: string;
    street: string;
    zipCode: number;
    phoneNumber: string;
  };
  about: string;
  attachments: Attachment[];
  roles: Roles[];
  socialMedia: SocialMedia[];
  availability: AvailabilityStatus;
  category: string[];
  keywords: string[];
  requests: Requst[];
}

export interface updateAgentWorkInfo {
  fee: TypeFee;
  availability: AvailabilityStatus;
  priceHour: number;
  category: string[];
  keywords: string[];
  requests: Requst[];
}

export interface MessagesBusinessRequest {
  id: number;
  text: string;
  files: Attachment[];
  dateCreated: Date;
}

export enum RequestStatus {
  INPROGRESS,
  COMPLETED,
  NONINTERESTED,
}

enum TypeStatus {
  AGENT,
  COMPANY,
}

enum FavoriteStatus {
  FAVORITE,
  NORMAL,
}

export interface Requst {
  id: number;
  avatar: string;
  name: string;
  industry: string[];
  messages: MessagesBusinessRequest[];
  status: RequestStatus;
  companyIds: number[];
  dateUpdate: Date;
  type: TypeStatus;
  city: string;
  country: string;
  favorite: FavoriteStatus;
  orders: string[];
}

export interface allProductsResponse {
  content?: Item[];
  pageable: {
    pageNumber: number;
    pageSize: number;
    sort: {
      sorted: boolean;
      empty: boolean;
      unsorted: boolean;
    };
    offset: number;
    paged: boolean;
    unpaged: boolean;
  };
  last: boolean;
  totalPages: number;
  totalElements: number;
  first: boolean;
  size: number;
  number: number;
  sort: {
    sorted: boolean;
    empty: boolean;
    unsorted: boolean;
  };
  numberOfElements: number;
  empty: boolean;
  token?: string;
}

export interface Item {
  id: number;
  images: PhotoProduct[];
  keywords: string[];
  description: string;
  name: string;
  price: number;
  published: boolean;
  minimumOrderQuantity: number;
  token: string;
}

export interface AddProduct {
  id?: number;
  name?: string;
  description?: string;
  minimumOrderQuantity?: number;
  price?: number;
  keywords?: string[];
  images?: PhotoProduct[];
  published?: boolean;
  token?: string;
}

export interface PhotoProduct {
  id: number | undefined;
  name: string;
  link: string;
}

export interface Photo {
  id: number;
  name: string;
  photo_url: string[];
}

export enum AvailabilityStatus {
  NOW = "NOW",
  NEXT_WEEK = "NEXT_WEEK",
  NEXT_MONTH = "NEXT_MONTH",
}

export enum UserRoles {
  OWNER = "ROLE_OWNER",
  MANAGER = "ROLE_MANAGER",
  EDITOR = "ROLE_EDITOR",
}

// export interface ProviderAccount {
//   id: number;
//   avatar: string;
//   companyName: string;
//   email: string;
//   smsCode: string;
//   confirmed: boolean;
//   availability: AvailabilityStatus;
//   about: string;
//   shippingTo: string[];
//   paymentTerms: string[];
//   languages: string[];
//   industry: string[];
//   keywords: string[];
//   founded: number;
//   customizationCapacity: boolean;
//   rndCapacity: boolean;
//   specialPacking: boolean;
//   whiteLabel: boolean;
//   certification: boolean;
//   sample: boolean;
//   rating: number;
//   reviews: number;
//   providerContactInfo: {
//     country: string;
//     city: string;
//   };
//   socialMedia: SocialMedia[];
//   requests: Requst[];
//   products: Item[];
//   photos: Photo[];
//   favoriteRequest: number[];
//   favoriteAgents: number[];
//   roles: Roles[];
//   favoriteProviders: number[];
// }

export interface Company {
  token?: string;
  avatar?: string;
  companyName?: string;
  email?: string;
  founded?: string;
  businessType?: BusinessType;
  industry?: string[];
  businessRegistrationId?: string;
  taxId?: string;
  website?: string;
  registrationInfo?: AddressInfo;
  operationInfo?: AddressInfo;
  businessCertificate?: Attachment[];
  taxStatus?: Attachment[];
  proofAddress?: Attachment[];
  proofBank?: Attachment[];
  articles?: Attachment[];
  certificates?: Attachment[];
  roles?: Roles[];
  favoriteAgents?: AgentBaseAgent[];
  photos?: Photo[];
  about?: string;
  availability?: AvailabilityStatus;
  socialMedia?: SocialMedia[];
  keywords?: string[];
  shippingTo?: string[];
  paymentTerms?: string[];
  languages?: string[];
  customizationCapacity?: boolean;
  rndCapacity?: boolean;
  specialPacking?: boolean;
  whiteLabel?: boolean;
  certification?: boolean;
  sample?: boolean;
  rating?: number;
  companyType?: CompanyType;
  allUsers?: CompanyUsers[];
}

export interface CompanyUsers {
  id: number;
  roles?: Roles[];
  email: string;
  firstName: string;
  lastName: string;
  jobTitle: string;
  avatar: string;
}

export interface CompanyData extends Company {
  products?: allProductsResponse;
}

export interface CompanyProfile extends Company {
  instagram?: string;
  facebook?: string;
  linkedIn?: string;
  numberOfEmployees?: string;
  experienceMarketplace?: string[];
  dateCreate?: string;
  completionRate?: number;
  products?: allProductsResponse;
  productById?: Item;
  //   TEMP
  // companyProducts:CompanyProduct[];
  fileList?: File[];

  //     else states
  // reviews: number
  // providerCompanySocialMedia: any
  // orders: any
  // unreadUpdatedOrders: any
  // messageUnread: any[]
  // successRate: number
  // usersId: number[]
  // user: any
}

export interface CompanyById extends Company {
  instagram?: string;
  facebook?: string;
  linkedIn?: string;
  numberOfEmployees?: string;
  experienceMarketplace?: string[];
  dateCreate?: string;
  completionRate?: number;
  products?: Item[];
  fileList?: File[];
}

export interface AddressInfo {
  address?: string;
  country?: string;
  city?: string;
  postalCode?: string;
}

// export interface OperationAddressInfo {
//   address?: string;
//   country?: string;
//   city?: string;
//   postalCode?: number;
// }

// export interface CompanyDetailsInfo {
//   avatar: string;
//   companyName: string;
//   founded: number;
//   businessType: BusinessType;
//   industry: string[];
//   businessRegistrationId: string;
//   taxId: number;
//   website: string;
//   registrationInfo: AddressInfo;
//   operationInfo: AddressInfo;
//   token?: string;
//   companyType: CompanyType;
//   businessCertificate: Attachment[];
//   taxStatus: Attachment[];
//   proofAddress: Attachment[];
//   proofBank: Attachment[];
//   articles: Attachment[];
// }

export interface CompanyAdressInfo {
  registrationInfo?: AddressInfo;
  operationInfo?: AddressInfo;
}
export interface CompanyLogoProps {
  avatar: string;
  changeAvatar: React.Dispatch<React.SetStateAction<Company | undefined>>;
  newAvatar?: string;
}

export interface CompanyDetailProps {
  companyDetails: Company;
  updateCompany?: Company;
  onGenralChange: React.Dispatch<React.SetStateAction<Company | undefined>>;
}

export interface CompanyAddressProps {
  companyDetails: CompanyAdressInfo;
  onAddressChange: React.Dispatch<
    React.SetStateAction<CompanyAdressInfo | undefined>
  >;
  updateCompany?: Company;
}

export interface CompanyIndustryProps {
  isGeneralEditing: boolean;
}

export enum DocumentEnum {
  businessCertificate = "businessCertificate",
  taxStatus = "taxStatus",
  proofAddress = "proofAddress",
  proofBank = "proofBank",
  articles = "articles",
  certificates = "certificates",
}

export enum DelegateFileEnum {
  specifation = "productSpecifications",
  photos = "sketchesOrPhotos",
}

export interface UpdateProviderProduct {
  id: number | null;
  name: string;
  minimumOrderQuantity: number;
  price: number;
  images: string[] | null;
}

export interface UserAccount {
  id: number | null;
  confirmed: boolean;
  companyId: number;
  email: string;
  avatar: string;
  lastName: string;
  firstName: string;
  gender: string;
  jobTitle: string;
  companyName: string;
  phoneNumber: string;
  token: string;
  refreshToken: string;
  attachments: Attachment[];
  companyType: CompanyType;
  delegateSearches: string;
  roles: [
    {
      name: string;
    }
  ];
}

export enum OrderStatus {
  IN_PENDING = "IN_PENDING",
  CONFIRMED = "CONFIRMED",
  CANCELED = "CANCELED",
  SIGNED = "SIGNED",
  MANUFACTURED = "MANUFACTURED",
  SENT = "SENT",
  RECEIVED_AND_ACCEPTED = "RECEIVED_AND_ACCEPTED",
}

export interface UserResendCode {
  id: number;
}

export interface UserConfirm {
  id: number;
  code: string;
}

export interface PersonalRegister {
  id: number;
  firstName: string;
  lastName: string;
  companyName: string;
  country: string;
  city: string;
  userType: string;
}

export interface CompanyRequst {
  companyType: CompanyType;
}

export interface allAgentsResponse {
  content: AgentBaseAgent[] | null;
  pageable: {
    pageNumber: number;
    pageSize: number;
    sort: {
      sorted: boolean;
      empty: boolean;
      unsorted: boolean;
    };
    offset: number;
    paged: boolean;
    unpaged: boolean;
  };
  last: boolean;
  totalPages: number;
  totalElements: number;
  first: boolean;
  size: number;
  number: number;
  sort: {
    sorted: boolean;
    empty: boolean;
    unsorted: boolean;
  };
  numberOfElements: number;
  empty: boolean;
}

export interface allProvidersResponse {
  content: PartnerBaseProvider[] | null;
  pageable: {
    pageNumber: number;
    pageSize: number;
    sort: {
      sorted: boolean;
      empty: boolean;
      unsorted: boolean;
    };
    offset: number;
    paged: boolean;
    unpaged: boolean;
  };
  last: boolean;
  totalPages: number;
  totalElements: number;
  first: boolean;
  size: number;
  number: number;
  sort: {
    sorted: boolean;
    empty: boolean;
    unsorted: boolean;
  };
  numberOfElements: number;
  empty: boolean;
}

export interface allRequest {
  content: Requst[] | null;
  pageable: {
    pageNumber: number;
    pageSize: number;
    sort: {
      sorted: boolean;
      empty: boolean;
      unsorted: boolean;
    };
    offset: number;
    paged: boolean;
    unpaged: boolean;
  };
  last: boolean;
  totalPages: number;
  totalElements: number;
  first: boolean;
  size: number;
  number: number;
  sort: {
    sorted: boolean;
    empty: boolean;
    unsorted: boolean;
  };
  numberOfElements: number;
  empty: boolean;
}

export interface AuthState {
  status: string;
  userAccount: UserAccount | null;
  company: CompanyProfile | null;
  allProviders: allProvidersResponse | null;
  allAgents: allAgentsResponse | null;
  dashboard: Dashboard | null;
  requests: allRequest | null;
  orders: Order[] | null;
  error: null;
  [key: string]: any;
}

export interface DatesDashboard {
  startdate: string;
  enddate: string;
}

export interface IFactoryList {
  id: number;
  productName: string;
  img: string;
}

export interface ITeamList {
  id: number;
  firstName: string;
  lastName: string;
  companyName: string;
  teamAvatar: string;
  categories: string[];
  keywords: string[];
  language: string;
}

export interface Dashboard {
  requests?: RequestDashboard[];
  orders?: OrderDashboard[];
  customers?: CustomerDashboard[];
  products?: ProductsDashboard[];
  details?: DetailsDashboard[];
  conversionRateArray?: ConversionRateDashboard;
  coordinates?: CoordinatesDashboard[];
  detailsMap?: CountryData[];
  orderDetails?: OrderDetails;
}

export interface OrderStatusData {
  totalOrders: number;
  totalAmount: number;
  totalCustomers: number;
  listCustomersId: number[];
}

export interface OrderDateData {
  [status: string]: OrderStatusData;
}

export interface OrderDetails {
  [date: string]: OrderDateData;
}

export interface CountryData {
  properties: {
    name: string;
    totalCustomers: number;
    transactions: number;
    revenue: number;
    conversionRate: number;
  };
  geometry: {
    coordinates: [number, number];
  };
}

export interface ConversionRateDashboard {
  conversionRate: number;
  totalCustomersWithCompletedOrders: number;
  totalPotentialCustomers: number;
}

export interface RequestDashboard {
  requests?: string[];
}

export interface OrderDashboard {
  [date: string]: {
    totalCount: number;
    statusCount: OrderStatusCount;
  };
}

export interface DetailsDashboard {
  [name: string]: {
    totalCustomers: number;
    transactions: number;
    revenue: number;
  };
}

export interface CoordinatesDashboard {
  latitude: number;
  longitude: number;
}

interface OrderStatusCount {
  IN_PENDING?: number;
  CONFIRMED?: number;
  CANCELED?: number;
  SIGNED?: number;
  MANUFACTURED?: number;
  SENT?: number;
  RECEIVED_AND_ACCEPTED?: number;
  COMPLETED?: number;
}

export interface CustomerDashboard {
  customers: string[];
}
export interface ProductsDashboard {
  name: string;
  amount: number;
}

export interface DelegateSearchOld {
  description: string;
  quantity: number;
  howOften: HowOften;
  commentHowOften: string;
  priceBudget: number;
  commentPriceBudget: string;
  timeLine: string;
  helpDelivery: boolean;
  // commentHelpDelivery: string;
  helpCustomsClearance: boolean;
  // commentHelpCustomsClearance: string;
  whereToDeliver: string;
  qualityStandards: boolean;
  commentQualityStandards: string;
  needCertificate: boolean;
  commentNeedCertificate: string;
  specificPackaging: boolean;
  commentSpecificPackaging: string;
  specificLabeling: boolean;
  commentSpecificLabeling: string;
  paymentTerms: string;
  additionalRequirements: string;
  comment: string;
  attachments: Attachment[];
}

export interface DelegateSearchType {
  id?: number;
  identification?: string;
  createTime?: string;
  name?: string;
  overview?: string;
  productSpecifications?: Attachment[];
  sketchesOrPhotos?: Attachment[];
  price?: number;
  currency?: CurrencyType;
  priceUnit?: UnitMeasurement;
  orderQuantity?: number;
  paymentTerms?: string;
  paymentTermsOther?: string;
  orderFrequency?: HowOften;
  leadTime?: string;
  leadTimeAsap?: boolean;
  needDelivery?: boolean;
  orderUnit?: string;
  deliveryDeadline?: string;
  deliveryDeadlineAsap?: boolean;
  deliveryAddress?: string;
  qualityStandards?: string;
  certificates?: string;
  packagingRequirements?: string;
  labelingRequirements?: string;
  additionalRequests?: string;
}

export interface allBriefsResponse {
  content?: BriefItem[];
  pageable: {
    pageNumber: number;
    pageSize: number;
    sort: {
      sorted: boolean;
      empty: boolean;
      unsorted: boolean;
    };
    offset: number;
    paged: boolean;
    unpaged: boolean;
  };
  last: boolean;
  totalPages: number;
  totalElements: number;
  first: boolean;
  size: number;
  number: number;
  sort: {
    sorted: boolean;
    empty: boolean;
    unsorted: boolean;
  };
  numberOfElements: number;
  empty: boolean;
  token: string;
}

export interface BriefItem {
  id: number;
  createTime: string;
  deliveryDeadline: string;
  identification: string;
  name: string;
  overview: string;
}

export interface BusinessMatchmaking {
  overview: boolean;
  productSpecification: boolean;
  price: boolean;
  paymentTerms: boolean;
  numberOfProducts: boolean;
  oftenOrder: boolean;
  leadTime: boolean;
  help: boolean;
  date: boolean;
  place: boolean;
  qualityStandards: boolean;
  certificates: boolean;
  specificPackaging: boolean;
  specificLabeling: boolean;
  communicate: boolean;
  customQuestion1: boolean;
  customQuestion2: boolean;
  customQuestion3: boolean;
  customQuestion1Text: string;
  customQuestion2Text: string;
  customQuestion3Text: string;
}

export enum HowOften {
  SAMPLE = "Sample order",
  TRIAL = "Trial batch",
  QUARTERLY = "Quarterly",
  ANNUALLY = "Annually",
  ONETIME = "One-time order ",
  MONTHLY = "Monthly",
}

export interface ChatMessageResponse {
  body: Message[];
}
export interface Message {
  id: string;
  text: string;
  dateCreated: string;
  files?: Attachment[];
  ownerId: number;
  orderId: number;
  email: string;
  chatId: string;
}

export interface DialoueData {
  avatar: string;
  city: string;
  country: string;
  companyid: Number[];
  dateUpdate: string;
  favorite: string;
  id: number;
  industry: String[];
  name: string;
  status: string;
  type: string;
  messages: Message[];
  orders: Order[];
}

export interface ConnectionData {
  typeRequestTo: string;
  id: string;
  text: string;
  files: File[];
}

export interface OrderCreate {
  order: OrderForCreate;
  token: string;
}

export interface OrderForCreate {
  businessRequestId: number;
  products: [
    {
      name: string;
      technicalSpecifications: string[];
    }
  ];
}

export interface ModifiedFileObject {
  link: string;
  name: string;
}
export interface InternetSearchResponse {
  content: FactoryInternetSearch[];
  pageable: Pageable;
  last: boolean;
  totalElements: number;
  totalPages: number;
  first: boolean;
  size: number;
  number: number;
  sort: Sort2;
  numberOfElements: number;
  empty: boolean;
}

export interface FactoryInternetSearch {
  place_id: string;
  companyName: string;
  country: string;
  city: string;
  industry: string[];
  keywords: string[];
  internationalPhone: string;
  rating: number;
  reviews: number;
  website: string;
  logo: string;
}

export interface MessageToProvider {
  attachments?: Attachment[];
  text?: string;
  briefIds?: number[];
  placeUrl?: string;
  token?: string;
  ownerMail?: string;
  senderMail?: string;
}

export interface FetchUrl {
  headers: string[];
  body: string;
  statusCode: number;
}

export interface Pageable {
  pageNumber: number;
  pageSize: number;
  sort: Sort;
  offset: number;
  paged: boolean;
  unpaged: boolean;
}

export interface Sort {
  sorted: boolean;
  empty: boolean;
  unsorted: boolean;
}

export interface Sort2 {
  sorted: boolean;
  empty: boolean;
  unsorted: boolean;
}

export interface Coordinates {
  latitude: number;
  longitude: number;
}

export interface FavoritesActionType {
  entityId: number;
  method: string;
  typeFavorite: string;
}

export interface PartnerBaseFiltersType {
  cities?: string[];
  countries?: string[];
  founded?: number[];
  industry?: string[];
  keywords?: string[];
  languages?: string[];
  shippingTo?: string[];
}

export interface Order {
  id: number;
  avatar: string;
  name: string;
  industry: any[];
  city: string | null;
  country: string | null;
  vendor: string | null;
  status: Status | StatusFromBack | null;
  processingStatuses: ProcessingStatuse[];
  dateOrdered: string;
  dateArriving: string | null;
  products: Product[];
  files: any[];
  amount: number | null;
  request: number;
}

export interface OrderById extends Order {
  id: number;
  avatar: string;
  name: string;
  industry: any[];
  city: string | null;
  country: string | null;
  vendor: string | null;
  status: Status | StatusFromBack | null;
  processingStatuses: ProcessingStatuse[];
  dateOrdered: string;
  dateArriving: string | null;
  products: Product[];
  files: any[];
  amount: number | null;
  request: number;
}

export enum Status {
  InPending = "In pending",
  InProgress = "In progress",
  Completed = "Completed",
  Cancelled = "Cancelled",
}

export enum StatusFromBack {
  InPending = "IN_PENDING",
  InProgress = "IN_PROGRESS",
  Completed = "COMPLETED",
  Cancelled = "CANCELED",
}

export interface ProcessingStatuse {
  type: string;
  dateStatus: string;
}

export interface Product {
  id: number;
  name: string;
  technicalSpecifications: any[];
}

export interface CompanyProduct {
  name: string;
  minimumOrderQuantity: number;
  price: number;
  images: string[];
}

export interface allMarketplacesContentType {
  icon: string;
  name: string;
}

export interface IndustryType {
  id: number;
  name: string;
  activities: ActivityType[];
}

export interface ActivityType {
  id: number;
  name: string;
}

export interface ChatType {
  activityId: number;
  assistantId: string;
  changed_at: string;
  created_at: string;
  customersCompanyId: number;
  id: number;
  industryId: number;
  name: string;
  threadId: string;
}

export interface messageType {
  role: string;
  value: string;
  created_at: string;
}

export interface IndustryActivityType {
  id: number;
  name: string;
}
