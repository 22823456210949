import styled from "styled-components";

export const Container = styled.div``;

export const TitleWrapper = styled.div`
  margin-bottom: 30px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 20px;
  margin-bottom: 30px;
`;

export const ButtonText = styled.h4`
  font-size: 17px;
  font-weight: 500;
  line-height: 140%;
  color: var(--txt-dark, #001731);
`;

export const IconButtonWrapper = styled.div`
  display: flex;
  gap: 12px;
  padding: 14px 30px 16px 30px;

  border-radius: 16px;

  border: 1px solid var(--battn-border, #004c62);
  background: var(--wiht, #fff);
  white-space: nowrap;
  cursor: pointer;
  &:hover {
    background: var(--batton, #0095c0);
    & ${ButtonText} {
      color: var(--txt-light-blue, #f4ffff);
    }
    & svg * {
      stroke: var(--blue-light, #f4ffff);
    }
    &:active {
      background: var(--green, #03b62a);
    }
  }
`;

export const RequestContainer = styled.div`
  max-width: 765px;
  width: 100%;
`;

export const UserLogo = styled.img`
  width: 58px;
  height: 58px;
  border-radius: 50%;
  object-fit: cover;
`;

export const UserWrapper = styled.div``;

export const NamesWrapper = styled.div<{ side: string }>`
  display: flex;
  align-items: center;
  justify-content: ${({ side }) => (side === "right" ? "end" : "start")};
  gap: 16px;
  margin-bottom: 20px;
`;

export const UserName = styled.h4`
  font-size: 17px;
  font-weight: 500;
  line-height: 140%;

  color: var(--txt-dark, #001731);
`;

export const MessageWrapper = styled.div`
  margin-bottom: 4px;
  padding: 20px;

  display: inline-block;
  max-width: 90%;

  border-radius: 16px;
  border: 1px solid var(--batton, #0095c0);
  background: var(--wiht, #fff);
`;

export const MessageWrapperSide = styled.div<{ side: string }>`
  display: flex;
  justify-content: ${({ side }) => (side === "right" ? "end" : "start")};
`;

export const MessageContainer = styled.div``;

export const MessageText = styled.h5`
  font-size: 17px;
  line-height: 140%;

  color: var(--txt-dark, #001731);
  word-wrap: break-word;
`;

export const MessageDataSide = styled.h6<{ side: string }>`
  display: flex;
  justify-content: ${({ side }) =>
    side === "right" ? "flex-end" : "flex-start"};

  margin-bottom: 20px;

  line-height: 140%;
  color: var(--txt-light-blue-dont-active, #a0b7d1);
`;

export const CompanyName = styled.h6`
  font-size: 18px;
  font-weight: 400;
  line-height: 140%;

  color: var(--txt-dak-light, #143255);
`;

export const SendMessageConainer = styled.div``;

export const SubTitle = styled.h5`
  font-size: 17px;
  line-height: 140%;

  color: var(--txt-dark, #001731);
`;
export const Star = styled.h5`
  font-size: 17px;
  line-height: 140%;

  color: var(--txt-red, #f00);
`;

export const SubtitleWrapper = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

export const UserMessageInput = styled.input`
  max-width: 725px;
  width: 100%;
  padding: 20px;

  font-size: 17px;
  line-height: 140%;

  border-radius: 16px;
  border: 1px solid var(--batton, #0095c0);
  color: var(--txt-dark, #001731);
  background: var(--wiht, #fff);
  &::placeholder {
    color: var(--txt-grey-light, #717880);
    font-size: 17px;
    font-weight: 400;
    line-height: 140%;
  }
`;

export const UploadButtonWrapper = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  padding: 18px 40px 20px 40px;
  margin-bottom: 20px;

  font-size: 17px;
  font-weight: 400;
  line-height: 140%;

  border-radius: 16px;
  border: 1px dashed var(--green, #03b62a);
  background: var(--wiht, #fff);

  cursor: pointer;
`;

export const UploadDialogWrapper = styled.div`
  margin-bottom: 20px;
`;

export const UploadButton = styled.p`
  padding: 0 3px 0 0;
  text-align: center;

  font-size: 17px;
  font-weight: 400;
  line-height: 140%;

  color: var(--green, #03b62a);
  text-decoration-line: underline;
`;

export const AttachmentsWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  margin-bottom: 20px;
`;

export const ButtonBottomContainer = styled.div`
  max-width: 242px;
  width: 100%;

  display: flex;
  gap: 10px;
  align-items: center;
`;

export const ButtonBottomWrapper = styled.div`
  max-width: 118px;
  width: 100%;
`;

export const VectorWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 18px;
  padding: 5px 4px;
  margin-right: 8px;

  border-radius: 16px;
  background-color: var(--green, #03b62a);
`;

export const Item = styled.li`
  display: flex;
  align-items: center;
  margin-top: 10px;
  font-size: 18px;
  line-height: 140%;

  color: var(--txt-dak-light, #143255);
`;

export const ListFile = styled.ul`
  display: flex;
  flex-direction: column;
`;

export const MessageList = styled.div`
  width: 100%;
  max-height: 710px;

  margin-bottom: 20px;
  padding-right: 10px;

  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 7px;
  }

  &::-webkit-scrollbar-thumb {
    background: linear-gradient(#0095c0, #0095c0);
    border-radius: 5px;
  }

  &::-webkit-scrollbar-track {
    background-color: #fafafa;
    border-radius: 5px;
  }
`;

export const Anchor = styled.div``;

export const Span = styled.span`
  font-size: 17px;
  line-height: 140%;

  color: var(--txt-dak-light, #143255);
`;

export const MediaUploadWrapper = styled.div`
  max-width: 266px;
  width: 100%;
`;

export const UploadLoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 18px 40px 20px 40px;
  max-width: 500px;
  width: 100%;

  border-radius: 16px;
  border: 1px dashed var(--green, #03b62a);
  background: var(--wiht, #fff);
`;

export const UploadLoadingText = styled.h5`
  font-size: 17px;
  line-height: 140%;
  color: var(--txt-dark, #001731);
`;

export const Download = styled.a`
  display: flex;
  align-items: center;

  color: var(--txt-dark, #001731);
  cursor: pointer;
`;

export const OrderRequestWrapper = styled.div`
  display: flex;
  /* align-items: center; */
  gap: 30px;
`;

export const OrderWrapper = styled.div`
  border-left: 1px solid var(--batton, #0095c0);
  padding-left: 30px;
`;

export const TitleOrder = styled.p`
  font-size: 38px;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: 0%;
  margin-bottom: 10px;
`;

export const SubOrderWrapper = styled.div`
  border-bottom: 1px solid var(--txt-grey-light, #717880);
  margin-bottom: 20px;
`;

export const ParagraphWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
`;

export const NameParagraph = styled.p`
  font-size: 17px;
  line-height: 140%;
  color: var(--txt-light-blue-dont-active, #a0b7d1);
`;

export const AnswerParagraph = styled.p`
  font-size: 17px;
  line-height: 140%;
`;

export const OrderCheckMark = styled.div`
  width: 40px;
  height: 40px;
  border: 1px solid #03b62a;
  border-radius: 30px;
  background-color: #fafafa;
  margin-right: 10px;
`;

export const NotActiveOrderCheckMark = styled.div`
  width: 40px;
  height: 40px;
  border: 1px solid #e8e8e8;
  border-radius: 30px;
  background-color: #fafafa;
  margin-right: 10px;
`;

export const OrderStatusWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

export const CheckIconWrapper = styled.p`
  display: flex;
  justify-content: center;
  padding-top: 10px;
`;

export const OrderStatusContentWrapperContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 85%;
`;

export const OrderStatusContentWrapper = styled.p`
  margin-right: 10px;
  color: var(--txt-dark, #001731);
  font-size: 17px;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: 0%;
  text-align: center;
`;

export const NotActiveOrderStatusContentWrapper = styled.p`
  margin-right: 10px;
  color: #dadada;
  font-size: 17px;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: 0%;
  text-align: center;
`;

export const OrderContentDateComponent = styled.p`
  color: var(--txt-light-blue-dont-active, #a0b7d1);
  font-size: 14px;
  font-weight: 500;
  line-height: 140%;
`;

export const CreateOrderButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 20px;
  margin-bottom: 20px;
`;

export const ButtonCreateWrapper = styled.div`
  width: 100%;
  max-width: 200px;
  margin-left: 20px;
`;

export const LeftWrapper = styled.div`
  width: 100%;
  max-width: 765px;
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
`;

export const DialogContent = styled.div`
  display: flex;
  align-items: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  z-index: 2;
  border-radius: 16px;
  padding: 30px;
  max-width: 826px;
  width: 100%;
  height: 184px;
`;

export const ConnectWrapper = styled.div`
  border-radius: 16px;
  background: var(--bg, #fdfdfd);
  padding: 30px;
  max-width: 826px;
  width: 100%;
`;

export const Tittle = styled.p`
  margin-bottom: 30px;

  color: var(--txt-dark, #001731);
  font-size: 38px;
  font-weight: 500;
  line-height: 120%;
`;

export const NameLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
`;

export const Avatar = styled.img`
  width: 100px;
  height: 100px;

  display: flex;

  border-radius: 100px;
  object-fit: cover;
`;

export const ConnectParagraph = styled.h4`
  color: var(--txt-dark, #001731);
  font-size: 17px;
  font-weight: 500;
  line-height: 140%;
`;

export const YourMessageWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

export const MessageArea = styled.textarea`
  border-radius: 16px;
  border: 1px solid var(--border, #bdbdbd);
  background: var(--wiht, #fff);
  display: flex;
  max-width: 665px;
  width: 100%;
  height: 200px;
  align-items: flex-start;
  gap: 10px;
  resize: none;
  margin-bottom: 20px;
  padding: 20px;
  &::placeholder {
    color: var(--txt-grey-light, #717880);
    font-size: 17px;
    font-weight: 400;
    line-height: 140%;
  }
  &:focus {
    border: 1px solid var(--batton, #0095c0);
  }
`;

export const NameMessageArea = styled.textarea`
  border-radius: 16px;
  border: 1px solid var(--border, #bdbdbd);
  background: var(--wiht, #fff);
  display: flex;
  max-width: 665px;
  width: 100%;
  height: 25px;
  align-items: flex-start;
  gap: 10px;
  resize: none;
  margin-bottom: 20px;
  padding: 20px;
  &::placeholder {
    color: var(--txt-grey-light, #717880);
    font-size: 17px;
    font-weight: 400;
    line-height: 140%;
  }
  &:focus {
    border: 1px solid var(--batton, #0095c0);
  }
`;

export const ButtonCancelSendWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  gap: 20px;
  justify-content: flex-end;
`;

export const CancelButton = styled.button`
  display: flex;
  border-radius: 16px;
  border: 1px solid var(--border, #bdbdbd);
  background: var(--wiht, #fff);
  padding: 14px 30px 16px 30px;
  justify-content: center;
  align-items: center;
  &:active {
    background: var(--green, #03b62a);
    color: var(--wiht, #fff);
  }
`;

export const ButtonSendWrapper = styled.div`
  max-width: 104px;
  width: 100%;
`;

export const OrderMessageContainer = styled.div`
  margin-bottom: 4px;
  padding: 20px;
  /* margin: 0 auto; */
  width: 100%;

  display: inline-block;
  max-width: 90%;

  border-radius: 25px;
  border: 1px solid var(--wiht, #fff);
  background: var(--batton, #0095c0);
`;

export const OrderMessageText = styled.p`
  font-size: 17px;
  line-height: 140%;

  color: var(--wiht, #fff);
  /* color: var(--txt-dark, #001731); */

  margin-bottom: 15px;
`;

export const OrderMessageSubTitle = styled.p`
  font-size: 21px;
  line-height: 160%;
  font-weight: 600;

  color: var(--wiht, #fff);
  /* color: var(--txt-dark, #001731); */

  margin-bottom: 15px;
`;

export const OrderMessageBottomTitle = styled.p`
  font-size: 25px;
  line-height: 160%;
  font-weight: 600;

  color: var(--wiht, #fff);
  /* color: var(--txt-dark, #001731); */

  text-align: center;
`;

export const OrderMessageTitle = styled.p`
  font-size: 30px;
  line-height: 140%;
  font-weight: 600;
  text-align: center;

  color: var(--wiht, #fff);
  /* color: var(--txt-dark, #001731); */

  margin-bottom: 10px;
`;

export const ButtonMessageWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: center;
`;

export const UserMessageWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
`;

export const EmojiWrapper = styled.div`
  position: absolute;
  bottom: 70px;
  right: 0;
`;
