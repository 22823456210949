import styled from "styled-components";

export const Container=styled.div`
margin-left: 30px;
max-width: 765px;
width: 100%;
`;

export const Title=styled.p`
color: var(--txt-dark, #001731);
font-size: 38px;
font-weight: 500;
line-height: 120%; 
margin-top: 30px;
margin-bottom: 20px;
`;

export const SubTitle=styled.p`
color: var(--txt-dark, #001731);
font-size: 24px;
font-weight: 500;
line-height: 140%; 
`;

export const Paragraph=styled.p`
color: var(--txt-dak-light, #143255);
font-size: 17px;
font-weight: 400;
line-height: 140%;
`;

export const MailParagraph=styled.p`
color: var(--txt-blue, #0095C0);
font-size: 17px;
font-weight: 400;
line-height: 140%; 
`;

export const TitleWrapper=styled.div`
display: flex;
align-items: center;
margin-bottom:10px;
`;

export const RedStar=styled.p`
color: var(--txt-red, #F00);
font-size: 24px;
font-weight: 500;
line-height: 140%;
`;

export const TextTitle = styled.textarea`
  border-radius: 16px;
  border: 1px solid var(--border, #bdbdbd);
  background: var(--wiht, #fff);
  display: flex;
  max-width: 745px;
  width: 100%;
  height: 44px;
  padding: 20px;
  align-items: flex-start;
  resize: none;
  margin-bottom: 30px;
  &::placeholder {
    color: var(--txt-grey-light, #717880);
    font-size: 17px;
    font-weight: 400;
    line-height: 140%;
  }
  &:focus {
    border: 1px solid var(--batton, #0095c0);
  }
`;


export const TextDetails = styled.textarea`
  border-radius: 16px;
  border: 1px solid var(--border, #bdbdbd);
  background: var(--wiht, #fff);
  display: flex;
  max-width: 745px;
  width: 100%;
  height: 180px;
  padding: 20px;
  align-items: flex-start;
  resize: none;
  margin-bottom: 30px;
  &::placeholder {
    color: var(--txt-grey-light, #717880);
    font-size: 17px;
    font-weight: 400;
    line-height: 140%;
  }
  &:focus {
    border: 1px solid var(--batton, #0095c0);
  }
`;


export const AttachmentsWrapper=styled.div`
max-width: 264px;
width: 100%;
margin-bottom:30px;
`;

export const ButtonWrapper= styled.div`
display: flex;
max-width: 235px;
width: 100%;
margin-left: auto;
`;