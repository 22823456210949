import React from "react";

const EllipseIcon: React.FC = () => {
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle id="Ellipse 41" cx="50" cy="50" r="50" fill="#D9D9D9" />
    </svg>
  );
};


export default EllipseIcon;