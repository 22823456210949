import React, { useEffect, useState } from "react";
import PartnerBasePage from "../components/PartnerBasePage/PartnerBasePage/PartnerBasePage";
import styled from "styled-components";
import PartnerBaseFilter from "../components/PartnerBasePage/PartnerBaseFilter/PartnerBaseFilter";
import TitleHeader from "../components/TitleHeader/TitleHeader";
import { ButtonWrapper } from "../components/DelegateSearchPage/style";
import ReturnButton from "../components/ReturnButton/ReturnButton";
import PartnerBaseList from "../components/PartnerBasePage/PartnerBaseList/PartnerBaseList";
import {
  getAllProvidersAsync,
  searchProvidersAsync,
} from "../store/slices/userSlice";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { RootState } from "../store/store";
import { useNavigate } from "react-router-dom";
import { routes } from "../routes/routes";
import { ProviderDetails, allProvidersResponse } from "../utils/types";

export const Container = styled.div`
  margin-right: 30px;
`;

export const MainContainer = styled.div`
  display: flex;
`;

export const ListContainer = styled.div`
  margin-right: 30px;
  width: 100%;
`;
export const TittleHeaderWrapper = styled.div``;

const PartnerBase: React.FC = () => {
  const [searchValue, setSearchValue] = useState("");

  const allProviders: allProvidersResponse = useAppSelector(
    (state) => state.user.allProviders! || []
  );
  const [filterValues, setFilterValues] = useState({
    page: 0,
    size: 12,
  } as ProviderDetails);

  const user = useAppSelector((state: RootState) => state.user);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user.userAccount) {
      navigate(routes.HOME);
    }
  }, [user, navigate]);

  const dispatch = useAppDispatch();
  useEffect(() => {
    const delay = 3000;
    const timeoutId = setTimeout(() => {
      dispatch(
        getAllProvidersAsync({
          credentials: {}
        }) as any
      );
    }, delay);
    return () => clearTimeout(timeoutId);
  }, [dispatch]);

  const handleApplyFilter = () => {
    if (searchValue === "") {
      dispatch(
        getAllProvidersAsync({
          credentials: filterValues
        }) as any
      )
        .then((result: any) => {
          // console.log("Result:", result.payload);
        })
        .catch((error: any) => {
          console.error("error", error);
        });
    } else {
      dispatch(
        searchProvidersAsync({
          credentials: filterValues,
          searchValue: [searchValue]
        })
      )
        .then((result: any) => {
          // console.log("Result:", result.payload);
        })
        .catch((error: any) => {
          console.error("error", error);
        });
    }
  };

  return (
    <MainContainer>
      <Container>
        <ButtonWrapper>
          <ReturnButton />
          <TittleHeaderWrapper>
            <TitleHeader text={"PartnerBase"} />
          </TittleHeaderWrapper>
        </ButtonWrapper>
        <PartnerBaseFilter
          filterValues={filterValues}
          setFilterValues={setFilterValues}
          handleApplyFilter={handleApplyFilter}
          allProvider={allProviders.content || []}
        />
      </Container>
      <ListContainer>
        <PartnerBasePage
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          filterValues={filterValues}
        />
        <PartnerBaseList
          allProviders={allProviders || []}
          filterValues={filterValues}
          setFilterValues={setFilterValues}
          handleApplyFilter={handleApplyFilter}
          searchValue={searchValue}
        />
      </ListContainer>
    </MainContainer>
  );
};

export default PartnerBase;
