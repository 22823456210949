import React, { useState } from "react";
import {
    ChevronDown,
    ChevronRightWrapper,
    IndustryInputWrapper,
    Item,
    List,
    DescriptionStyle,
    InputWrapper,
    InputsWrapper,
    SearchButton,
    SearchIcon,
    SearchInput,
    SearchInputList,
    StartButtonWrapper,
    ProductSpecificationInputs,
    ProductSpecificationStyle,
    ProductSpecificationButtonsStyle,
    ProductSpecificationInputsWrapper,
    IndustryTitle,
    ActivityTitle,
    SearchInputIndustryWrapper,
    SearchInputActivityWrapper,
    SearchIndustryWrapper,
    SearchActivityWrapper,
    SpecificationInputsContinueButtonWrapper,
    TitleSpecificationBlock,
    TitleSpecificationInputsBlock
} from "./style";
import SearchIconBlack from "../../../assets/icons/SearchIconBlack";
import ChevronRightIcon from "../../../assets/icons/ChevronRightIcon";
import ChevronDownIcon from "../../../assets/icons/ChevronDownIcon";
import Button from "../../Button/Button";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../routes/routes";
import { ActivityType, ChatType, IndustryType } from '../../../utils/types';
import { useAppSelector } from '../../../store/hooks';
import { clearData, setIndustryActivityValues } from '../../../store/slices/Assistants/AssistanseSlice';
import { useDispatch } from 'react-redux';

const ProductSpecification = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const assystantsInputsData: IndustryType[] = useAppSelector((state) => state.assistants.industries);
    const chats: ChatType[] = useAppSelector((state) => state.assistants.chats) || [];

    const industries = assystantsInputsData?.map((industry, index) => ({
        id: industry.id,
        name: industry.name
    })) || [];

    const [showIndustryList, setShowIndustryList] = useState(false);
    const [showActivitiesList, setShowActivitiesList] = useState(false);
    const [industryValue, setIndustry] = useState<ActivityType>({ id: -1, name: '' });
    const [activities, setActivities] = useState<ActivityType[]>([]);
    const [activityValue, setActivityValue] = useState<ActivityType>({ id: -1, name: '' });
    const [hasAssistant, setHasAssistant] = useState(true);

    const handleCreateAssistantClick = (type: string, value: string, id: number) => {
        if (type === "industry") {
            setIndustry({ id, name: value });
            setActivityValue({ id: -1, name: '' });
            const selectedIndustry = assystantsInputsData.find(ind => ind.name === value);
            if (selectedIndustry) {
                const activitiesNames: ActivityType[] = selectedIndustry.activities.map((activity, index) => ({
                    id: activity.id,
                    name: activity.name
                }));
                setActivities(activitiesNames);
            }
            setShowIndustryList(false);
        }

        if (type === "activities") {
            setActivityValue({ id, name: value });
            setShowActivitiesList(false);
        }
    };

    const handleStartChatingButtonClick = () => {
        if (industryValue.name && activityValue.name) {
            dispatch(clearData([]));
            dispatch(setIndustryActivityValues({ industryValue, activityValue }));
            navigate(`${routes.ASSISTANTS}${routes.SPECIFICATION}`);
        }
    };

    const handleContinueButtonClick = () => {
        dispatch(setIndustryActivityValues({ industryValue, activityValue }));
        navigate(`${routes.ASSISTANTS}${routes.SPECIFICATION}`);
    };

    return (
        <>
            {hasAssistant ?
                <ProductSpecificationStyle>
                    <TitleSpecificationBlock>Specification</TitleSpecificationBlock>
                    <DescriptionStyle>
                        Crack the product: collaborate with your personal AI assistant to create precise product specifications, enabling you to delve into the details, communicate effectively with suppliers and save time by reducing the need for multiple questions and iterations.
                    </DescriptionStyle>

                    <ProductSpecificationButtonsStyle>
                        <Button text='Create new assistant' onClick={() => setHasAssistant(false)} />
                        <Button
                            text='Continue with existing assistant'
                            onClick={handleContinueButtonClick}
                            disabled={chats.length === 0}
                        />
                    </ProductSpecificationButtonsStyle>
                </ProductSpecificationStyle >

                :

                <ProductSpecificationInputs>
                    <ProductSpecificationInputsWrapper>
                        <TitleSpecificationInputsBlock>Specification</TitleSpecificationInputsBlock>
                        <InputsWrapper>
                            <IndustryTitle>Industry</IndustryTitle>
                            <InputWrapper>
                                {!showIndustryList ? (
                                    <SearchInputIndustryWrapper>
                                        <SearchInput
                                            type="text"
                                            name="industry"
                                            placeholder="Enter industry"
                                            onClick={() => setShowIndustryList(!showIndustryList)}
                                            value={industryValue.name}
                                            readOnly
                                        />
                                        <SearchButton
                                            onClick={() => setShowIndustryList(!showIndustryList)}
                                        >
                                            <SearchIconBlack />
                                        </SearchButton>
                                        <ChevronRightWrapper
                                            onClick={() => {
                                                setShowIndustryList(!showIndustryList);
                                            }}
                                        >
                                            <ChevronRightIcon />
                                        </ChevronRightWrapper>
                                    </SearchInputIndustryWrapper>
                                ) : (
                                    <SearchIndustryWrapper>
                                        <IndustryInputWrapper
                                            onClick={() => setShowIndustryList(false)}
                                        >
                                            <SearchIcon>
                                                <SearchIconBlack />
                                            </SearchIcon>
                                            <ChevronDown
                                                onClick={() => {
                                                    setShowIndustryList(false);
                                                }}
                                            >
                                                <ChevronDownIcon />
                                            </ChevronDown>
                                            <SearchInputList
                                                placeholder="Enter industry"
                                                type="text"
                                                autoFocus
                                                value={industryValue.name}
                                                readOnly
                                            />
                                        </IndustryInputWrapper>
                                        <List>
                                            {industries &&
                                                industries.map(({ name, id }) => {
                                                    if (name && name.trim() !== "") {
                                                        return (
                                                            <Item
                                                                key={name}
                                                                onClick={() =>
                                                                    handleCreateAssistantClick("industry", name, id)
                                                                }
                                                            >
                                                                {name}
                                                            </Item>
                                                        );
                                                    } else {
                                                        return null;
                                                    }
                                                })}
                                        </List>
                                    </SearchIndustryWrapper>
                                )}
                            </InputWrapper>

                            <ActivityTitle>Activities</ActivityTitle>
                            <InputWrapper>
                                {!showActivitiesList ? (
                                    <SearchInputActivityWrapper>
                                        <SearchInput
                                            type="text"
                                            name="activities"
                                            placeholder="Enter activities"
                                            onClick={() => setShowActivitiesList(!showActivitiesList)}
                                            value={activityValue.name}
                                            readOnly
                                        />
                                        <SearchButton
                                            onClick={() => setShowActivitiesList(!showActivitiesList)}
                                        >
                                            <SearchIconBlack />
                                        </SearchButton>
                                        <ChevronRightWrapper
                                            onClick={() => {
                                                setShowActivitiesList(!showActivitiesList);
                                            }}
                                        >
                                            <ChevronRightIcon />
                                        </ChevronRightWrapper>
                                    </SearchInputActivityWrapper>
                                ) : (
                                    <SearchActivityWrapper>
                                        <IndustryInputWrapper
                                            onClick={() => setShowActivitiesList(false)}
                                        >
                                            <SearchIcon>
                                                <SearchIconBlack />
                                            </SearchIcon>
                                            <ChevronDown
                                                onClick={() => {
                                                    setShowActivitiesList(false);
                                                }}
                                            >
                                                <ChevronDownIcon />
                                            </ChevronDown>
                                            <SearchInputList
                                                placeholder="Enter activities"
                                                type="text"
                                                autoFocus
                                                value={activityValue.name}
                                                readOnly
                                            />
                                        </IndustryInputWrapper>
                                        <List>
                                            {activities &&
                                                activities.map(({ name, id }) => {
                                                    if (name && name.trim() !== "") {
                                                        return (
                                                            <Item
                                                                key={name}
                                                                onClick={() =>
                                                                    handleCreateAssistantClick("activities", name, id)
                                                                }
                                                            >
                                                                {name}
                                                            </Item>
                                                        );
                                                    } else {
                                                        return null;
                                                    }
                                                })}
                                        </List>
                                    </SearchActivityWrapper>
                                )}
                            </InputWrapper>
                        </InputsWrapper>

                        <StartButtonWrapper>
                            <Button
                                disabled={!industryValue.name || !activityValue.name}
                                text="Start chatting with an new assistant"
                                onClick={handleStartChatingButtonClick}
                            />
                            <SpecificationInputsContinueButtonWrapper>
                                <Button
                                    text='Continue with existing assistant'
                                    onClick={handleContinueButtonClick}
                                    disabled={chats.length === 0}
                                />
                            </SpecificationInputsContinueButtonWrapper>
                        </StartButtonWrapper>
                    </ProductSpecificationInputsWrapper>
                </ProductSpecificationInputs>}
        </>
    );
};

export default ProductSpecification;

// eslint-disable-next-line no-lone-blocks
{
    /* <ButtonWrapper style={{ gap: 20, justifyContent: "center" }}>
                  {Object.keys(routeMap).map((item, key) => (
                      <ButtonWhite key={key} text={item} onClick={() => handleButtonClick(item)}
                      isSelected={selectedButton === item} />
                      ))}
                      </ButtonWrapper> */
}