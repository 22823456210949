import styled from "styled-components";

export const Wrapper = styled.div`
    display: flex;
    max-width: 2000px;
    width: 100%;
    height: 100%;
`;
export const WrapperLeft = styled.div`
    max-width: 1100px;
    width: 70%;
    height: 85%;
    margin: 30px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    box-sizing: border-box;
    border: 1px solid rgb(216, 216, 216);
    border-radius: 16px 0 0 16px;
    padding: 20px 160px 20px 160px;
`;
export const WrapperRight = styled.div`
    max-width: 1100px;
    width: 30%;
    height: 85%;
    margin: 30px auto;
    padding-top: 20px;
    flex-direction: column;
    border-bottom: 1px solid rgb(216, 216, 216);
    border-right: 1px solid rgb(216, 216, 216);
    border-top: 1px solid rgb(216, 216, 216);
    border-radius: 0 16px 16px 0;
`;
export const DateRequest = styled.div`
    margin-top: 40px;
    color: rgb(20, 50, 85);
    display: flex;
    position: relative;
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0;
    text-align: left;
    padding: 0 0 0 20px;
`;
export const Request = styled.div`
    width: 100%;
    height: 44px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-overflow: ellipsis;
    padding: 10px 20px 10px 20px;
    cursor: pointer;

    &&:hover {
        border-radius: 12px;
        background: rgb(0, 149, 192);
        color: rgb(244, 255, 255);
    }
    &&:active {
        scale: 0.99;
    }
`;
export const IconWrapper = styled.div`
    display: flex;
    position: relative;
    cursor: pointer;
`;
export const RequestCreate = styled.div`
    color: rgb(0, 23, 49);
    font-size: 28px;
    font-weight: 500;
    line-height: 140%;
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 10px 20px 10px 20px;
    cursor: pointer;
    &&:active {
        scale: 0.99;
    }
`;
export const EmptyCenterBlock = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
`;
export const SubTitle = styled.p`
    color: rgb(0, 23, 49);
    font-size: 17px;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0;
    text-align: center;
`;
export const TagsBlock = styled.div`
    position: relative;
    width: 843px;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
`;
export const Tag = styled.div`
    position: relative;
    width: calc(50% - 10px); 
    height: 64px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;
    border: 1px solid rgb(216, 216, 216);
    border-radius: 16px;
    box-sizing: border-box;
    cursor: pointer;
    &&:hover {
        border-radius: 12px;
        background: rgb(0, 149, 192);
        color: rgb(244, 255, 255);
    }
    &&:active {
        scale: 0.99;
    }
`;
export const SearchHelper = styled.div`
    position: relative;
    width: 843px;
    height: 76px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    margin-top: 20px;
    border: 1px solid rgb(216, 216, 216);
    border-radius: 16px;
    color: rgb(208, 208, 208);
    font-size: 17px;
    font-weight: 400;
    line-height: 140%;
    text-align: left;
`;
export const ChatBlock = styled.div`
    position: static;
    width: 843px;
    height: 114px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    margin: 150px 0;
`;
export const AvatarBlock = styled.div`
    position: relative;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: rgb(0, 149, 192);
    color: white;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 24px;
`;
export const CreateText = styled.p`
    margin-left: 20px;
    position: relative;
    color: rgb(0, 23, 49);
    font-size: 28px;
    font-weight: 500;
    line-height: 140%;
    text-align: left;
    
`;
export const Screpka = styled.div`
    position: relative;
    width: 36px;
    height: 36px;
    padding: 6px;
    border: 1px dashed rgb(3, 182, 42);
    border-radius: 6px;
`;
export const FileOrWhat = styled.div`
    position: relative;
    width: 36px;
    height: 36px;
    padding: 6px;
    border-radius: 6px;
    background: rgb(0, 149, 192);
`;
