import React from "react";
import styled from "styled-components";

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  cursor: pointer;

  /* Setting the default color */
  path {
    stroke: #001731;
  }

  /* Change color on hover */
  &:hover path {
    stroke: rgb(244, 255, 255); 
  }
`;

interface IPencilRenameProps {
  onClick?: () => void;
}

const PencilRenameIcon: React.FC<IPencilRenameProps> = ({ onClick }) => {
  return (
    <IconWrapper onClick={onClick}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1947_16932)">
          <path
            d="M11.333 2.0001C11.5028 1.79946 11.7128 1.63614 11.9497 1.52055C12.1866 1.40497 12.4451 1.33966 12.7088 1.32878C12.9725 1.31789 13.2356 1.36169 13.4813 1.45736C13.727 1.55303 13.9499 1.69848 14.1359 1.88445C14.3218 2.07041 14.4668 2.29281 14.5614 2.53746C14.656 2.78211 14.6982 3.04366 14.6854 3.30542C14.6726 3.56718 14.605 3.82342 14.4869 4.05781C14.3689 4.2922 14.2029 4.49959 13.9997 4.66677L4.99967 13.6668L1.33301 14.6668L2.33301 11.0001L11.333 2.0001Z"
            stroke="#001731"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10 3.33325L12.6667 5.99992"
            stroke="#001731"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_1947_16932">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </IconWrapper>
  );
};

export default PencilRenameIcon;