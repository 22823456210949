import styled from "styled-components";

export const UploadFile = styled.div`
border-radius: 16px;
border: 1px dashed var(--green, #03B62A);
background: var(--wiht, #FFF);
display: flex;
padding: 18px 40px 20px 40px;
justify-content: center;
align-items: center;
max-width: 500px;
width: 100%;
font-size: 17px;
font-weight: 400;
line-height: 140%;
`;

export const UploadButton= styled.button`
margin-right: 0;
color: var(--green, #03B62A);
text-align: center;
font-size: 17px;
font-weight: 400;
line-height: 140%;
text-decoration-line: underline;
`;

export const FileTittle = styled.div`
margin: 20px 8px 20px 0;
display: flex;
`;

export const VectorWrapper= styled.div`
display: flex;
justify-content: center;
align-items: center;
border-radius: 16px;
background-color: var(--green, #03B62A);
width: 18px;
padding: 5px 4px;
margin-right: 8px;
`;

export const PhotoWrapper= styled.ul`
display: column;
align-items: center;
`;

export const PhotoId= styled.li`
display: flex;
margin-bottom: 20px;
align-items: center;
`;

export const CloseWrapper = styled.div`
display: flex;
margin-left: 8px;
align-items: center;
`;