import React, { useEffect, useState } from "react";
import {
  IndustryItem,
  IndustryList,
  SearchInput,
  CountryItem,
  SearchInputCountryList,
  CountryList,
} from "../../Filter/style";
import SearchIconBlack from "../../../assets/icons/SearchIconBlack";
import ChevronRightIcon from "../../../assets/icons/ChevronRightIcon";
import ChevronDownIcon from "../../../assets/icons/ChevronDownIcon";
import {
  ChevronDown,
  SearchIcon,
  SearchIconInput,
  SearchButton,
  InputWrapper,
  IndustryKeqwordsP,
  SearchInputWrapper,
  CountrySearchWrapper,
  IndustrySearchWrapper,
  ChevronRightWrapper,
  SearchInputIndustries,
  SearchInputCountryCityList,
  SearchIconCountry,
  CountryInputWrapper,
  ItemFail,
  SearchButtonCountry,
  SearchIconCity,
  ChevronDownCity,
} from "./style";
import { AgentBaseAgent, AgentDetails } from "../../../utils/types";
// import { getFilterValuesAgentAsync } from "../../../store/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";

interface BasicInformationFilterProps {
  teamList: AgentBaseAgent[];
  filterValues: AgentDetails;
  onFilterChange: (filterValues: AgentDetails) => void;
  setFilterValues: React.Dispatch<React.SetStateAction<AgentDetails>>;
}

const BasicInformationFilter: React.FC<BasicInformationFilterProps> = ({
  teamList,
  setFilterValues,
  filterValues,
  onFilterChange,
}) => {
  const [showCategoryList, setshowCategoryList] = useState(false);
  const [showKeywordsList, setShowKeywordsList] = useState(false);
  const [showCountryList, setShowCountryList] = useState(false);
  const [showCityList, setShowCityList] = useState(false);
  const [showLanguagesList, setShowLanguagesList] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [selectedKeywords, setSelectedKeywords] = useState<string[]>([]);
  const [selectedLanguage, setSelectedLanguage] = useState<string[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<string[]>([]);
  const [selectedCity, setSelectedCity] = useState<string[]>([]);

  const filteredCategory = Array.from(
    new Set(
      teamList.flatMap((team) =>
        team.category.map((category) => category.trim())
      )
    )
  ).filter(
    (category) =>
      category.toLowerCase().includes(searchText.toLowerCase()) &&
      !filterValues.category?.includes(category) &&
      !selectedCategories.includes(category)
  );

  const filteredKeywords = Array.from(
    new Set(
      teamList.flatMap((team) => 
      team.keywords.map((keyword) => keyword.trim()))
    )
  ).filter(
    (keyword) =>
      keyword.toLowerCase().includes(searchText.toLowerCase()) &&
      !filterValues.keywords?.includes(keyword) &&
      !selectedKeywords.includes(keyword)
  );

  const filteredLanguages = Array.from(
    new Set(
      teamList.flatMap((team) =>
        team.languages.map((languages) => languages.trim())
      )
    )
  ).filter(
    (languages) =>
      languages.toLowerCase().includes(searchText.toLowerCase()) &&
      !filterValues.languages?.includes(languages) &&
      !selectedLanguage.includes(languages)
  );

  const filteredCountry = Array.from(
    new Set(
      teamList.flatMap((team) => {
        const countries = team.agentContactInfo.country;
        return Array.isArray(countries)
          ? countries.map((c) => c.trim())
          : [countries.trim()];
      })
    )
  ).filter(
    (country) =>
      country.toLowerCase().includes(searchText.toLowerCase()) &&
      !filterValues.country?.includes(country) &&
      !selectedCountry.includes(country)
  );

  const filteredCity = Array.from(
    new Set(
      teamList.flatMap((team) => {
        const cities = team.agentContactInfo.city;
        return Array.isArray(cities)
          ? cities.map((c) => c.trim())
          : [cities.trim()];
      })
    )
  ).filter(
    (cities) =>
      cities.toLowerCase().includes(searchText.toLowerCase()) &&
      !filterValues.city?.includes(cities) &&
      !selectedCity.includes(cities)
  );

  const handleCategoryClick = (category: string) => {
    setSearchText("");
    setFilterValues((prevState) => {
      const updatedCategory = [...(prevState?.category || []), category];
      const updatedSelectedCategories = selectedCategories.filter(
        (item) => item !== category
      );
      setSelectedCategories(updatedSelectedCategories);
      return {
        ...prevState,
        category: updatedCategory,
      };
    });
  };

  const handleKeywordsClick = (keyword: string) => {
    setSearchText("");
    setFilterValues((prevState) => {
      const updatedKeywords = [...(prevState?.keywords || []), keyword];
      const updatedSelectedKeywords = selectedKeywords.filter(
        (item) => item !== keyword
      );
      setSelectedKeywords(updatedSelectedKeywords);
      return {
        ...prevState,
        keywords: updatedKeywords,
      };
    });
  };

  const handleCountryClick = (country: string) => {
    setSearchText("");
    setFilterValues((prevState) => {
      const updatedCountry = [...(prevState?.country || []), country];
      const updatedSelectedCountry = selectedCountry.filter(
        (item) => item !== country
      );
      setSelectedCountry(updatedSelectedCountry);
      return {
        ...prevState,
        country: updatedCountry,
      };
    });
  };

  const handleCityClick = (city: string) => {
    setSearchText("");
    setFilterValues((prevState) => {
      const updatedCity = [...(prevState?.city || []), city];
      const updatedSelectedCity = selectedCountry.filter(
        (item) => item !== city
      );
      setSelectedCity(updatedSelectedCity);
      return {
        ...prevState,
        city: updatedCity,
      };
    });
  };

  const handleLanguageClick = (language: string) => {
    setSearchText("");
    setFilterValues((prevState) => {
      const updatedLanguages = [...(prevState?.languages || []), language];
      const updatedSelectedLanguage = selectedLanguage.filter(
        (item) => item !== language
      );
      setSelectedLanguage(updatedSelectedLanguage);
      const updatedFilteredLanguages = filteredLanguages.filter(
        (lang) => lang !== language
      );
      return {
        ...prevState,
        languages: updatedLanguages,
      };
    });
  };

  return (
    <>
      <IndustryKeqwordsP>Categories</IndustryKeqwordsP>
      <InputWrapper>
        {!showCategoryList ? (
          <>
            <SearchInputWrapper>
              <SearchInput
                type="text"
                name="Category"
                placeholder="Search categories"
                onClick={() => {
                  setshowCategoryList(!showCategoryList);
                }}
                value={filterValues?.category?.join(", ") || ""}
              />
              <SearchButton
                onClick={() => {
                  setshowCategoryList(!showCategoryList);
                }}
              >
                <SearchIconBlack />
              </SearchButton>
              <ChevronRightWrapper
                onClick={() => {
                  setshowCategoryList(!showCategoryList);
                }}
              >
                <ChevronRightIcon />
              </ChevronRightWrapper>
            </SearchInputWrapper>
          </>
        ) : (
          <IndustrySearchWrapper>
            <SearchIconInput type="button">
              <SearchIconBlack />
            </SearchIconInput>
            <ChevronDown
              onClick={() => {
                setshowCategoryList(false);
              }}
            >
              <ChevronDownIcon />
            </ChevronDown>
            <SearchInputIndustries
              placeholder="Search categories"
              type="text"
              autoFocus
              value={
                filterValues?.category?.length &&
                !filterValues.category.includes(searchText)
                  ? filterValues.category.join(" ")
                  : searchText
              }
              onChange={(e) => {
                const newValue = e.target.value;
                setSearchText(newValue);
                if (
                  filterValues?.category &&
                  !filterValues.category.includes(newValue.trim())
                ) {
                  setFilterValues({
                    ...filterValues,
                    category: newValue.trim() ? [newValue.trim()] : [],
                  });
                }
              }}
            />
                        <IndustryList>
              {filteredCategory &&
                filteredCategory.length > 0 &&
                filteredCategory.map((category) => {
                  if (
                    category &&
                    category.trim() !== "" &&
                    !filterValues?.category?.includes(category.trim())
                  ) {
                    return (
                      <IndustryItem
                        key={category}
                        onClick={() => handleCategoryClick(category)}
                      >
                        {category}
                      </IndustryItem>
                    );
                  } else {
                    return null;
                  }
                })}
            </IndustryList>
          </IndustrySearchWrapper>
        )}
      </InputWrapper>

      <IndustryKeqwordsP>Keywords</IndustryKeqwordsP>
      <InputWrapper>
        {!showKeywordsList ? (
          <>
            <SearchInputWrapper>
              <SearchInput
                type="text"
                name="Keywords"
                placeholder="Search keywords"
                onClick={() => {
                  setShowKeywordsList(!showKeywordsList);
                }}
                value={filterValues?.keywords?.join(" ") || []}
              />
              <SearchButton
                onClick={() => {
                  setShowKeywordsList(!showKeywordsList);
                }}
              >
                <SearchIconBlack />
              </SearchButton>
              <ChevronRightWrapper
                onClick={() => {
                  setShowKeywordsList(!showKeywordsList);
                }}
              >
                <ChevronRightIcon />
              </ChevronRightWrapper>
            </SearchInputWrapper>
          </>
        ) : (
          <IndustrySearchWrapper>
            <SearchIconInput type="button">
              <SearchIconBlack />
            </SearchIconInput>
            <ChevronDown
              onClick={() => {
                setShowKeywordsList(false);
              }}
            >
              <ChevronDownIcon />
            </ChevronDown>
            <SearchInputIndustries
              placeholder="Search keywords"
              type="text"
              autoFocus
              value={
                filterValues?.keywords?.length &&
                !filterValues.keywords.includes(searchText)
                  ? filterValues.keywords.join(" ")
                  : searchText
              }
              onChange={(e) => {
                const newValue = e.target.value;
                setSearchText(newValue);
                if (filterValues?.keywords) {
                  setFilterValues({
                    ...filterValues,
                    keywords: newValue.split(" ").map((item) => item.trim()),
                  });
                }
              }}
            />
            <IndustryList>
              {filteredKeywords &&
                filteredKeywords.length > 0 &&
                filteredKeywords.map((keyword) => {
                  if (keyword && keyword.trim() !== "") {
                    return (
                      <IndustryItem
                        key={keyword}
                        onClick={() => handleKeywordsClick(keyword)}
                      >
                        {keyword}
                      </IndustryItem>
                    );
                  } else {
                    return null;
                  }
                })}
              {filteredKeywords && filteredKeywords.length === 0 && (
                <ItemFail>Choose from existing keywords</ItemFail>
              )}
            </IndustryList>
          </IndustrySearchWrapper>
        )}
      </InputWrapper>

      <IndustryKeqwordsP>Country</IndustryKeqwordsP>
      <InputWrapper>
        {!showCountryList ? (
          <SearchInputWrapper>
            <SearchInput
              type="text"
              name="country"
              placeholder="Enter country"
              onClick={() => setShowCountryList(!showCountryList)}
              value={filterValues?.country?.join(" ") || []}
            />
            <SearchButtonCountry
              onClick={() => setShowCountryList(!showCountryList)}
            >
              <SearchIconBlack />
            </SearchButtonCountry>
            <ChevronRightWrapper
              onClick={() => {
                setShowCountryList(!showCountryList);
              }}
            >
              <ChevronRightIcon />
            </ChevronRightWrapper>
          </SearchInputWrapper>
        ) : (
          <CountrySearchWrapper>
            <CountryInputWrapper onClick={() => setShowCountryList(false)}>
              <SearchIconCountry type="button">
                <SearchIconBlack />
              </SearchIconCountry>
              <ChevronDown
                onClick={() => {
                  setShowCountryList(false);
                }}
              >
                <ChevronDownIcon />
              </ChevronDown>
              <SearchInputCountryCityList
                placeholder="Enter country"
                type="text"
                autoFocus
                value={
                  filterValues?.country?.length &&
                  !filterValues.country.includes(searchText)
                    ? filterValues.country.join(" ")
                    : searchText
                }
                onChange={(e) => {
                  const newValue = e.target.value;
                  setSearchText(newValue);
                  if (filterValues?.country) {
                    setFilterValues({
                      ...filterValues,
                      country: newValue.split(" ").map((item) => item.trim()),
                    });
                  }
                }}
              />
            </CountryInputWrapper>
            <CountryList>
              {filteredCountry &&
                filteredCountry.length > 0 &&
                filteredCountry.map((country: string) => {
                  if (country && country.trim() !== "") {
                    return (
                      <CountryItem
                        key={country}
                        onClick={() => handleCountryClick(country)}
                      >
                        {country}
                      </CountryItem>
                    );
                  } else {
                    return null;
                  }
                })}
              {filteredCountry && filteredCountry.length === 0 && (
                <ItemFail>Choose from existing country</ItemFail>
              )}
            </CountryList>
          </CountrySearchWrapper>
        )}
      </InputWrapper>

      <IndustryKeqwordsP>City</IndustryKeqwordsP>
      <InputWrapper>
        {!showCityList ? (
          <SearchInputWrapper>
            <SearchInput
              type="text"
              name="city"
              placeholder="Enter city"
              onClick={() => setShowCityList(!showCityList)}
              value={filterValues?.city?.join(" ") || []}
            />
            <SearchButton onClick={() => setShowCityList(!showCityList)}>
              <SearchIconBlack />
            </SearchButton>
            <ChevronRightWrapper
              onClick={() => {
                setShowCityList(!showCityList);
              }}
            >
              <ChevronRightIcon />
            </ChevronRightWrapper>
          </SearchInputWrapper>
        ) : (
          <CountrySearchWrapper>
            <CountryInputWrapper onClick={() => setShowCityList(false)}>
              <SearchIconCity type="button">
                <SearchIconBlack />
              </SearchIconCity>
              <ChevronDownCity
                onClick={() => {
                  setShowCityList(false);
                }}
              >
                <ChevronDownIcon />
              </ChevronDownCity>
              <SearchInputCountryCityList
                placeholder="Enter city"
                type="text"
                autoFocus
                value={
                  filterValues?.city?.length &&
                  !filterValues.city.includes(searchText)
                    ? filterValues.city.join(" ")
                    : searchText
                }
                onChange={(e) => {
                  const newValue = e.target.value;
                  setSearchText(newValue);
                  if (filterValues?.city) {
                    setFilterValues({
                      ...filterValues,
                      city: newValue.split(" ").map((item) => item.trim()),
                    });
                  }
                }}
              />
            </CountryInputWrapper>
            <CountryList>
              {filteredCity &&
                filteredCity.length > 0 &&
                filteredCity.map((city: string) => {
                  if (city.trim() !== "") {
                    return (
                      <CountryItem
                        key={city}
                        onClick={() => handleCityClick(city)}
                      >
                        {city}
                      </CountryItem>
                    );
                  } else {
                    return null;
                  }
                })}
              {filteredCity && filteredCity.length === 0 && (
                <ItemFail>Choose from existing city</ItemFail>
              )}
            </CountryList>
          </CountrySearchWrapper>
        )}
      </InputWrapper>

      <IndustryKeqwordsP>Language</IndustryKeqwordsP>
      <InputWrapper>
        {!showLanguagesList ? (
          <>
            <SearchInputWrapper>
              <SearchInput
                type="text"
                name="Language"
                placeholder="Enter language"
                onClick={() => {
                  setShowLanguagesList(!showLanguagesList);
                }}
                value={filterValues?.languages?.join(" ") || []}
              />
              <SearchButton
                onClick={() => {
                  setShowLanguagesList(!showLanguagesList);
                }}
              >
                <SearchIconBlack />
              </SearchButton>
              <ChevronRightWrapper
                onClick={() => {
                  setShowLanguagesList(!showLanguagesList);
                }}
              >
                <ChevronRightIcon />
              </ChevronRightWrapper>
            </SearchInputWrapper>
          </>
        ) : (
          <IndustrySearchWrapper>
            <SearchIconInput type="button">
              <SearchIconBlack />
            </SearchIconInput>
            <ChevronDown
              onClick={() => {
                setShowLanguagesList(false);
              }}
            >
              <ChevronDownIcon />
            </ChevronDown>
            <SearchInputIndustries
              placeholder="Enter language"
              type="text"
              autoFocus
              value={
                filterValues?.languages?.length &&
                !filterValues.languages.includes(searchText)
                  ? filterValues.languages.join(" ")
                  : searchText
              }
              onChange={(e) => {
                const newValue = e.target.value;
                setSearchText(newValue);
                if (
                  filterValues?.languages &&
                  !filterValues.languages.includes(newValue.trim())
                ) {
                  setFilterValues({
                    ...filterValues,
                    languages: newValue.trim() ? [newValue.trim()] : [],
                  });
                }
              }}
            />
            <IndustryList>
              {filteredLanguages.length > 0 ? (
                filteredLanguages.map((language) => (
                  <IndustryItem
                    key={language.toString()}
                    onClick={() => handleLanguageClick(language)}
                  >
                    {language}
                  </IndustryItem>
                ))
              ) : (
                <>
                  {filteredLanguages && filteredLanguages.length === 0 && (
                    <ItemFail>Choose from existing languages</ItemFail>
                  )}
                </>
              )}
            </IndustryList>
          </IndustrySearchWrapper>
        )}
      </InputWrapper>
    </>
  );
};

export default BasicInformationFilter;
