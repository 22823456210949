import { routes } from "../routes/routes";
import CompanyInformation from "./CompanyInformation";
import Settings from "./Settings";
import styled from "styled-components";
import ReturnButton from "../components/ReturnButton/ReturnButton";
import TitleHeader from "../components/TitleHeader/TitleHeader";
import ButtonWhite from "../components/ButtonWhite/ButtonWhite";
import {
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useEffect, useState } from "react";
import ProfileCompany from "./ProfileCompany";
import ProfileProducts from "./ProfileProducts";
import ProfileBusinessMatchmaking from "./ProfileBusinessMatchmakingPage";

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
`;

const TittleHeaderWrapper = styled.div`
  margin-left: 30px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  margin-bottom: 30px;
`;

const Profile = () => {
  const [selectedButton, setSelectedButton] = useState("Company");
  const navigate = useNavigate();
  const location = useLocation();

  const routeMap: { [key: string]: routes } = {
    Company: routes.COMPANY,
    Products: routes.PRODUCTS,
    "Business matchmaking": routes.BUSINESSMATCHMAKING,
  };

  const handleButtonClick = (item: string) => {
    const route = routeMap[item];
    if (route) {
      setSelectedButton(item);
      navigate(`/profile${route}`);
    }
  };

  useEffect(() => {
    const currentPath = location.pathname;
    const selectedButton = Object.keys(routeMap).find((button) =>
      currentPath.endsWith(routeMap[button])
    );

    if (selectedButton) {
      setSelectedButton(selectedButton);
    }
    const currentRoute = routeMap[selectedButton!];
    if (currentRoute) {
      if (currentPath !== `/profile${currentRoute}`) {
        navigate(`/profile${currentRoute}`);
      }
    }
  }, [location.pathname, routeMap, navigate]);

  return (
    <>
      <ButtonWrapper>
        <ReturnButton />
        <TittleHeaderWrapper>
          <TitleHeader text={selectedButton} />
        </TittleHeaderWrapper>
      </ButtonWrapper>

      <ButtonsWrapper style={{ gap: 20 }}>
        {Object.keys(routeMap).map((item, key) => (
          <ButtonWhite
            key={key}
            text={item}
            onClick={() => handleButtonClick(item)}
            isSelected={selectedButton === item}
          />
        ))}
      </ButtonsWrapper>

      <Outlet />
      <Routes>
        <Route path={routes.COMPANY} element={<ProfileCompany />} />
        <Route path={routes.PRODUCTS} element={<ProfileProducts />} />
        <Route path={routes.BUSINESSMATCHMAKING} element={<ProfileBusinessMatchmaking />} />
      </Routes>
    </>
  );
};

export default Profile;

