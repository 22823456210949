import styled from "styled-components";
import { Link } from "react-router-dom";

export const Container = styled.div`
  display: flex;
  gap: 30px;
  margin-bottom: 30px;
`;

export const AboutWrapper = styled.div`
  max-width: 765px;
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const NameContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InfoContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 6px;
  flex-direction: column;
`;

export const InfoElement = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  align-items: center;
`;

export const BlueDot = styled.div`
  width: 6px;
  height: 6px;
  margin: 0px 6px;
  background-color: #0095c0;
  border-radius: 50%;
`;

export const InfoHeader = styled.p`
  margin-right: 10px;

  font-size: 17px;
  font-weight: 500;
  line-height: 140%;
  text-align: left;
  white-space: nowrap;
`;

export const InfoContent = styled.p`
  font-size: 17px;
  line-height: 140%;
  text-align: left;
`;

export const ImageWrapper = styled.div`
  max-width: 765px;
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-left: 30px;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const AvatarWrapper = styled.div`
  display: flex;
  gap: 20px;
`;

export const Avatar = styled.img`
  width: 100px;
  height: 100px;

  display: flex;

  border-radius: 100px;
  object-fit: cover;
`;

export const Title = styled.h3`
  margin: auto 0;

  font-size: 24px;
  font-weight: 500;
  line-height: 140%;

  color: var(--txt-dark, #001731);
`;

export const AvailabilityWrapper = styled.div`
  max-width: 255px;
  display: flex;
  align-items: center;
  flex-direction: row;

  gap: 10px;
  padding: 8px 20px 10px 20px;

  border-radius: 12px;
  border: 1px solid var(--green, #03b62a);
  background: var(--order-green-1, #eaffef);
`;

export const Label = styled.h4`
  font-size: 17px;
  font-weight: 500;
  line-height: 140%;
  white-space: nowrap;
  color: var(--txt-dark, #001731);
`;

export const Value = styled.h5`
  font-size: 17px;
  line-height: 140%;
  color: var(--txt-green, #03b62a);
`;

export const SocialMedia = styled.div`
  display: flex;
  gap: 20px;
`;

export const LinkSocial = styled.a`
  cursor: pointer;
`;

export const KeywordsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 765px;
  gap: 20px;
`;

export const Keyword = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 8px 16px 10px 16px;

  border-radius: 12px;
  border: 1px solid var(--border, #bdbdbd);
  background: var(--gray-light, #fafafa);
  white-space: nowrap;
  transition: background 1.5s ease, color 0.3s ease;
  cursor: pointer;
  &:hover {
    background: linear-gradient(
      225deg,
      rgb(0, 149, 192) 17.746%,
      rgba(3, 182, 42, 0.6) 90.792%
    );
    border: 1px solid transparent;
    transition: background 1.5s ease, color 0.3s ease;
    & > h6 {
      color: var(--txt-light-blue, #f4ffff);
    }
  }
  &:active {
    background: var(--button, #03b62a);
    & > h6 {
      color: var(--txt-light-blue, #f4ffff);
    }
  }
`;

export const KeywordText = styled.h6`
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;

  color: var(--txt-dark, #001731);
`;

export const AboutBlockWrapper = styled.div``;
export const About = styled.h5`
  font-size: 17px;
  font-weight: 400;
  line-height: 140%;

  color: var(--txt-dak-light, #143255);
`;

export const ListWrapper = styled.div`
  display: flex;
  gap: 46px;
`;

export const List = styled.ul`
  max-width: 431px;
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 14px;
  li:nth-child(5) h4 {
    max-width: 138px;
    width: 100%;
  }

  li:nth-child(5) {
    align-items: baseline;
  }
`;

export const ItemStyle = styled.li`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const ListValue = styled.h5`
  font-size: 17px;

  line-height: 140%;
  color: var(--txt-dak-light, #143255);
`;

export const IconWrapper = styled.div``;

export const Listspecial = styled.ul`
  max-width: 257px;
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 14px;
`;

export const ProductWrapper = styled.div`
  max-width: 765px;
  display: flex;
  gap: 30px;
`;

export const ButtonWrapper = styled.div`
  max-width: 263px;
  width: 100%;
  display: flex;
  gap: 30px;
  margin: 23px 0;
`;

export const TitleError = styled.p`
  height: 70vh;

  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  color: var(--txt-dark, #001731);
  font-size: 38px;
  font-weight: 500;
  line-height: 120%;
`;

export const LogoHeader = styled.h4`
  font-size: 17px;
  font-weight: 500;
  line-height: 140%;
  text-align: center;
`;

export const LogoWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
`;

export const LogoElement = styled.img`
  width: 160px;
  height: 48px;
  margin-bottom: 20px;
  margin-right: 10px;
`;

export const ButtonAllProductWrapper = styled(Link)`
  max-width: 302px;
  margin: 0 auto 20px;
`;
