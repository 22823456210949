import { StyledButtonWhite } from "./styles";

interface IButtonWhiteProps {
  text: string;
  onClick?: () => void;
  isSelected?: boolean;
  modal?: boolean;
  disabled?: boolean
}

const ButtonWhite = ({ text, onClick, isSelected, modal,disabled }: IButtonWhiteProps) => {
  return (
    <StyledButtonWhite type="submit" onClick={onClick}disabled={disabled} $isSelected={isSelected} modal={modal}>
      {text}
    </StyledButtonWhite>
  );
};

export default ButtonWhite;
