import React from 'react'
import { ButtonsBlockStyle, DialogContent, Overlay, Text } from '../ModalStyle'
import Button from '../../Button/Button'
import ButtonWhite from '../../ButtonWhite/ButtonWhite'

interface Props {
    handleWhiteButtonClick: () => void,
    handleButtonClick: () => void,
    text: string;
    buttonText: string;
    buttonWhitetext: string;
}

const ModalLostChanges = ({handleWhiteButtonClick, handleButtonClick, text, buttonText, buttonWhitetext}: Props) => {
    return (
        <>
            <Overlay />
            <DialogContent modal>
                <Text>{text}</Text>
                <ButtonsBlockStyle style={{gap: 20}} >
                <Button text={buttonText} onClick={handleButtonClick} />
                <ButtonWhite text={buttonWhitetext} modal onClick={handleWhiteButtonClick} />
                </ButtonsBlockStyle>

            </DialogContent>
        </>
    )
}

export default ModalLostChanges