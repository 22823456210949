import styled from "styled-components";
interface IsEditingProps {
  disabled: boolean;
}


export const Title = styled.p`
  color: rgb(0, 23, 49);
  font-size: 24px;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: 0%;
  text-align: left;
`;

export const UploadDialogWrapper = styled.div`
  margin-bottom: 20px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  border: 1px solid rgb(232, 232, 232);
  border-radius: 16px;
  /* wiht */
  background: rgb(255, 255, 255);
  margin-bottom: 30px;
  position: relative;
  width: 100%;
  max-width: 765px;
`;


export const Tooltip = styled.div`
  color: var(--txt-light-blue-dont-active, #a0b7d1);
  border-radius: 16px;
  border: 1px solid var(--border, #bdbdbd);
  background: var(--wiht, #fff);
  position: absolute;
  top: 30px;
  left: 200px;
  transform: translate(128px, 5%);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 300px;
  width: 100%;
  padding: 20px;
  gap: 10px;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
`;

export const DocumentTooltip= styled.div`
  color: var(--txt-light-blue-dont-active, #a0b7d1);
  border-radius: 16px;
  border: 1px solid var(--border, #bdbdbd);
  background: var(--wiht, #fff);
  position: absolute;
  top: 0px;
  left:80px;
  transform: translate(128px, 5%);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 400px;
  width: 100%;
  padding: 20px;
  gap: 10px;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
`;

export const ProofWrapper=styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const DocumentWrapper=styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 10px;
`;

export const Subtitle=styled.p`
  font-size: 17px;
  font-weight: 400;
  line-height: 140%;

  color: var(--txt-dark, #001731);
  overflow: hidden;
  text-overflow: ellipsis;

  color: rgb(20, 50, 85);
`;

export const IconContainer=styled.div``;




export const MediaUploadWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  max-width: 266px;
  width: 100%;
`;

export const List = styled.ul`
  display: flex;
  flex-direction: column;
`;

export const Item = styled.li`
  display: flex;
  align-items: center;

  margin-bottom: 20px;

  font-size: 18px;
  line-height: 140%;

  color: var(--txt-dak-light, #143255);
`;

export const VectorWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 18px;
  padding: 5px 4px;
  margin-right: 8px;

  border-radius: 16px;
  background-color: var(--green, #03b62a);
`;

export const CloseUploadWrapper = styled.div`
  display: flex;
  margin-left: 8px;
  align-items: center;
  cursor: pointer;
`;



export const MediaContainer=styled.div`
  display:flex;
  justify-content: space-between;

`