import styled from "styled-components";

export const TextArea = styled.textarea`
  width: 100%;
  padding: 20px;
  margin-bottom: 30px;

  border-radius: 16px;
  border: 1px solid var(--border, #bdbdbd);
  background: var(--wiht, #fff);

  resize: none;
  box-sizing: border-box;

  &::placeholder {
    color: var(--txt-grey-light, #717880);
    font-size: 17px;
    font-weight: 400;
    line-height: 140%;
  }
  &:focus {
    border: 1px solid var(--batton, #0095c0);
  }
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--batton, #0095c0);
    border-radius: 5px;
  }

  &::-webkit-scrollbar-track {
    background-color: #fafafa;
    border-radius: 5px;
  }
`;