import React from 'react';
import RectangleImage from '../img/Rectangle 23.png';

interface ProgressbarProps {
  percentage: number;
}

const Progressbar: React.FC<ProgressbarProps> = ({ percentage }) => {
  const validatedPercentage = Math.min(100, Math.max(0, percentage));
  const barWidth = 718 * (validatedPercentage / 100); // Ширина заполненной части прогресс-бара

  return (
    <svg
      width="718"
      height="20"
      viewBox="0 0 718 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0" y="0" width="718" height="20" fill="#FAFAFA" rx="10" />

      <clipPath id="progressClip">
        <rect x="0" y="0" width={barWidth} height="20" rx="10" />
      </clipPath>

      <image
        xlinkHref={RectangleImage}
        x="0"
        y="0"
        width="718"
        height="20"
        preserveAspectRatio="none"
        clipPath="url(#progressClip)"
      />
    </svg>
  );
};

export default Progressbar;
