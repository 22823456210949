import React from "react";

const ShieldQuestionIcon: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 22C12 22 20 18 20 12V5L12 2L4 5V12C4 18 12 22 12 22Z"
        stroke="#001731"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.10156 9.00005C9.34154 8.33868 9.80634 7.78269 10.4147 7.42928C11.0231 7.07586 11.7363 6.94751 12.4297 7.06665C13.1231 7.18579 13.7525 7.54485 14.208 8.08105C14.6635 8.61726 14.9161 9.29651 14.9216 10C14.9216 12 11.9216 13 11.9216 13"
        stroke="#001731"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 17H12.01"
        stroke="#001731"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ShieldQuestionIcon;
