import { routes } from "../../routes/routes";
import { StyledDelegateSearch, Title } from "./Styles";
import { Link, useNavigate } from "react-router-dom";



const DelegateSearch = () => {
  const navigate = useNavigate();
  const handleDelegateSearchClick = () => {
    navigate(routes.DELEGATESEARCH);
  }; 
  return (
    <StyledDelegateSearch onClick={handleDelegateSearchClick}>
      <Title>
        Delegate the Search: Simply fill out our brief questionnaire,
        <br />
        and let the system find your perfect factory.
      </Title>
    </StyledDelegateSearch>
  );
};

export default DelegateSearch;
