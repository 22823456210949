import { useNavigate } from "react-router-dom";
import { StyledPartnerBase, Title } from "./Styles";
import { routes } from "../../routes/routes";

const PartnerBase = () => {
  const navigate = useNavigate();
  const handlePartnerBaseSearchClick = () => {
    navigate(routes.PARTNERBASE);
  };
  return (
    <StyledPartnerBase onClick={handlePartnerBaseSearchClick}>
      <Title>
        Explore Our Partner Base:
        <br />
        Unlock opportunities with verified factories and advanced filters.
      </Title>
    </StyledPartnerBase>
  );
};

export default PartnerBase;
