import React from "react";

const ComputerIcon: React.FC<{ style?: React.CSSProperties }> = ({ style }) => {
  return (
      <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={style}
      >
        <g clipPath="url(#clip3_6043)">
          <path id="Vector"
                d="M13 3L4 3C3.46 3 2.96 3.21 2.58 3.58C2.21 3.96 2 4.46 2 5L2 15C2 15.53 2.21 16.03 2.58 16.41C2.96 16.78 3.46 17 4 17L20 17C20.53 17 21.03 16.78 21.41 16.41C21.78 16.03 22 15.53 22 15L22 12"
                stroke="#001731" strokeOpacity="1.000000" strokeWidth="2.000000" strokeLinejoin="round"
                strokeLinecap="round"/>
          <path id="Vector" d="M8 21L16 21" stroke="#001731" strokeOpacity="1.000000" strokeWidth="2.000000"
                strokeLinejoin="round" strokeLinecap="round"/>
          <path id="Vector" d="M12 17L12 21" stroke="#001731" strokeOpacity="1.000000" strokeWidth="2.000000"
                strokeLinejoin="round" strokeLinecap="round"/>
          <path id="Vector" d="M17 8L22 3" stroke="#001731" strokeOpacity="1.000000" strokeWidth="2.000000"
                strokeLinejoin="round" strokeLinecap="round"/>
          <path id="Vector" d="M17 3L22 3L22 8" stroke="#001731" strokeOpacity="1.000000" strokeWidth="2.000000"
                strokeLinejoin="round" strokeLinecap="round"/>
        </g>
        <defs>
          <linearGradient
              id="paint0_linear_3484_2187"
              x1="2.5"
              y1="13.5"
              x2="21"
              y2="9.5"
              gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FDDC30"/>
            <stop offset="1" stopColor="#F2F700"/>
          </linearGradient>
        </defs>
      </svg>
  );
};


export default ComputerIcon;
