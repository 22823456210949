import React from "react";
import { Link } from "react-router-dom";
import { Container, LogoIcon, LogoName } from "./styles";
import { routes } from "../../routes/routes";
import { defaultSelfman } from "../../assets/img";

const Logo: React.FC = () => {
  return (
    <Container>
      <Link to={routes.HOME}>
        <LogoName>SelfMan</LogoName>
      </Link>
    </Container>
  );
};

export default Logo;
