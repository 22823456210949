import React, { useState } from "react";
import styled from "styled-components";
interface EmojiIconProps {
  onClick?: () => void;
}

const IconContainer = styled.svg`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  cursor: pointer;

  /* Setting the default color */
  path {
    fill: #001731;
  }

  /* Change color on hover */
  &:hover path {
    fill: #0095c0;
  }
`;

const EmojiIcon: React.FC<EmojiIconProps> = ({ onClick }) => {
  const [hovered, setHovered] = useState(false);

  return (
    <IconContainer
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={onClick}
    >
      <svg>
        <path d="M8,11V9a1,1,0,0,1,2,0v2a1,1,0,0,1-2,0Zm7.225,3.368a4,4,0,0,1-6.45,0,1,1,0,0,0-1.55,1.264,6,6,0,0,0,9.55,0,1,1,0,1,0-1.55-1.264ZM23,12A11,11,0,1,1,12,1,11.013,11.013,0,0,1,23,12Zm-2,0a9,9,0,1,0-9,9A9.011,9.011,0,0,0,21,12ZM17,9H14a1,1,0,0,0,0,2h3a1,1,0,0,0,0-2Z" />
      </svg>
    </IconContainer>
  );
};

export default EmojiIcon;
