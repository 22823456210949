import React, {useEffect, useState} from 'react'
import {Outlet, Route, Routes, useLocation, useNavigate} from 'react-router-dom'
import Users from '../components/Users/Users'
import {routes} from '../routes/routes'
import CompanyInformation from './CompanyInformation'
import Settings from './Settings'
import styled from 'styled-components'
import ReturnButton from '../components/ReturnButton/ReturnButton'
import TitleHeader from '../components/TitleHeader/TitleHeader'
import ButtonWhite from '../components/ButtonWhite/ButtonWhite'

const ButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 30px;
`;

const TittleHeaderWrapper = styled.div`
    margin-left: 30px;
`;

const ButtonsWrapper = styled.div`
    display: flex;
    margin-bottom: 30px;
`;

const Account = () => {
    const [selectedButton, setSelectedButton] = useState("Personal information");
    const navigate = useNavigate();
    const location = useLocation();

    const routeMap: { [key: string]: routes } = {
        "Personal information": routes.PERSONALINFORMATION,
        "Company information": routes.COMPANYINFORMATION,
        "Users": routes.USERS
    };

    const handleButtonClick = (item: string) => {
        const route = routeMap[item];
        if (route) {
            setSelectedButton(item);
            navigate(`/account${route}`);
        }
    };

    useEffect(() => {
        const currentPath = location.pathname;

        /* Obtain an array of keys from the routeMap object.
        Check which route the application is currently on and match this route with the button in the routeMap object. */
        const selectedButton = Object.keys(routeMap).find(button =>
            currentPath.endsWith(routeMap[button])
        );


        if (selectedButton) {
            setSelectedButton(selectedButton);
        }
        const currentRoute = routeMap[selectedButton!];
        if (currentPath !== `/account${currentRoute}`) {
            navigate(`/account${currentRoute}`);
        }

    }, [location.pathname, routeMap]);

    return (
        <>
            <ButtonWrapper>
                <ReturnButton/>
                <TittleHeaderWrapper>
                    <TitleHeader text={selectedButton}/>
                </TittleHeaderWrapper>
            </ButtonWrapper>

            <ButtonsWrapper style={{gap: 20}}>
                {Object.keys(routeMap).map((item, key) => (
                    <ButtonWhite key={key} text={item} onClick={() => handleButtonClick(item)}
                                 isSelected={selectedButton === item}/>
                ))}
            </ButtonsWrapper>

            <Outlet/>
            <Routes>
                <Route path={routes.PERSONALINFORMATION} element={<Settings/>}/>
                <Route path={routes.COMPANYINFORMATION} element={<CompanyInformation/>}/>
                <Route path={routes.USERS} element={<Users/>}/>
            </Routes>
        </>
    )
}

export default Account
