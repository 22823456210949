import AgentIcon from "../../assets/icons/AgentIcon";
import DashboardIcon from "../../assets/icons/DashboardIcon";
import FactorieIcon from "../../assets/icons/FactorieIcon";
import RequestIcon from "../../assets/icons/RequestIcon";
import OrderIcon from "../../assets/icons/OrderIcon";
import { useState } from "react";
import HideLeftIcon from "../../assets/icons/HideLeftIcon";
import ShowRightIcon from "../../assets/icons/ShowRightIcon";
import SettingsIcon from "../../assets/icons/SettingsIcon";
import HelpIcon from "../../assets/icons/HelpIcon";
import BookUserIcon from "../../assets/icons/BookUserIcon";
import LogOutIcon from "../../assets/icons/LogOutIcon";
import { routes } from "../../routes/routes";
import { useLocation, useNavigate } from "react-router-dom";

import {
  Container,
  TopSection,
  LogoName,
  ItemName,
  ChevronBlock,
  LinksBlock,
  MidleBlock,
  BottomBlock,
  SwitcherBlockDown,
  UserContainer,
  ItemBlockLog,
  ItemWrapperLog,
  ChevronRightSettingsWrapper,
  LogoIcon,
  ItemFactoriesAndSettingsBlock,
  ItemFactoriesAndSettingsWrapper,
  ItemFactoriesAndSettingsName,
} from "./styles";
import Switch from "../Switch/Switch";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { changeOpenMenu } from "../../store/slices/sliceData";
import { RootState } from "../../store/store";
import { logoutFunc } from "../../store/slices/userSlice";
import SideBarItem from "./SideBarItem/SideBarItem";
import ChevronRightIcon from "../../assets/icons/ChevronRightIcon";
import ChevronDownIcon from "../../assets/icons/ChevronDownIcon";
import SideBarUserDetails from "./SideBarUserDetails/SideBarUserDetails";
import { Requst } from "../../utils/types";
import { logo } from "../../assets/img";

const SideBar = () => {
  const isSideBarOn = useAppSelector((state: RootState) => state.themes.isOpen);
  const [isOpen, setIsOpen] = useState<boolean>(isSideBarOn);
  const user = useAppSelector((state: RootState) => state.user);
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isFactoriesOpen, setIsFactoriesOpen] = useState(false);
  const requests: Requst[] = useAppSelector(
    (state) => state.user.requests?.content || []
  );
  const orders = useAppSelector((state) => state.user.orders);
  const navigate = useNavigate();

  const toggleFactoriesAndSettings = (item: string) => {
    item === "factories"
      ? setIsFactoriesOpen(!isFactoriesOpen)
      : setIsSettingsOpen(!isSettingsOpen);
  };

  const toggle = () => {
    setIsOpen(!isOpen);
    dispatch(changeOpenMenu(!isOpen));
  };

  const handleLogOut = () => {
    dispatch(logoutFunc() as any);
  };

  const roles: string[] = useAppSelector(
    (store) => store.user.userAccount?.roles.map((role) => role.name) ?? []
  );

  const dashboardAssistantsMenu = [
    ...(roles.includes("ROLE_PROVIDER")
      ? [
          {
            path: `${routes.DASHBOARD}`,
            name: "Dashboard",
            icon: <DashboardIcon />,
            message: null,
          },
        ]
      : [
          {
            path: `${routes.ASSISTANTS}`,
            name: "Assistants",
            icon: <DashboardIcon />,
            message: null,
          },
        ]),
  ];

  const menuItemFactories = [
    ...(roles.includes("ROLE_PROVIDER")
      ? []
      : [
          {
            path: `${routes.NETWORKSEARCH}`,
            name: "Search",
            icon: null,
            message: null,
          },
          {
            path: `${routes.PARTNERBASE}`,
            name: "Partner Base",
            icon: null,
            message: null,
          },
        ]),
  ];

  const menuItemTop = [
    ...(roles.includes("ROLE_PROVIDER")
      ? [
          {
            path: `${routes.REQUESTS}`,
            name: "Business requests",
            icon: <RequestIcon />,
            message: requests && requests.length > 0 ? requests.length : null,
          },
          {
            path: `${routes.ORDERS}`,
            name: "Orders",
            icon: <OrderIcon />,
            message: orders && orders.length > 0 ? orders.length : null,
          },
        ]
      : [
          {
            path: `${routes.REQUESTS}`,
            name: "Business requests",
            icon: <RequestIcon />,
            message: requests && requests.length > 0 ? requests.length : null,
          },
          {
            path: `${routes.ORDERS}`,
            name: "Orders",
            icon: <OrderIcon />,
            message: orders && orders.length > 0 ? orders.length : null,
          },
          {
            path: `${routes.AGENTS}`,
            name: "Agents",
            icon: <AgentIcon />,
            message: null,
          },
        ]),
  ];

  const menuItemSettings = [
    {
      path: `/account${routes.PERSONALINFORMATION}`,
      name: "Account",
      icon: null,
      message: null,
    },
    ...(roles.includes("ROLE_CUSTOMER")
      ? [
          {
            path: `${routes.UNDER_CONSTRUCTION}`,
            name: "Profile",
            icon: null,
            message: null,
          },
        ]
      : [
          {
            path: `/profile${routes.COMPANY}`,
            name: "Profile",
            icon: null,
            message: null,
          },
        ]),
  ];

  const menuItemMidle = [
    {
      path: `${routes.HELP}`,
      name: "Help",
      icon: <HelpIcon />,
      message: null,
    },
    {
      path: `${routes.CONTACT_US}`,
      name: "Contact us",
      icon: <BookUserIcon />,
      message: null,
    },
  ];

  const isUserLoggedIn = !!user.userAccount;

  const handleDoubleClick = () => {
    navigate("/account/personal-information");
  };

  return (
    <Container $isOpen={isOpen}>
      <TopSection $isOpen={isOpen}>
        {/* <Link to={routes.FACTORIES}> */}
        {isOpen ? <LogoName>SelfMan</LogoName> : <LogoIcon src={logo} />}
        {/* </Link> */}
        <ChevronBlock onClick={toggle}>
          {isOpen ? <HideLeftIcon /> : <ShowRightIcon />}
        </ChevronBlock>
      </TopSection>

      <div>
        {dashboardAssistantsMenu.map((item, index) => (
          <SideBarItem
            key={index}
            item={item}
            isOpen={isOpen}
            location={location}
          />
        ))}
      </div>
      {roles.includes("ROLE_PROVIDER") ? (
        <></>
      ) : (
        <ItemFactoriesAndSettingsBlock
          $isOpen={isOpen}
          onClick={() => toggleFactoriesAndSettings("factories")}
        >
          <ItemFactoriesAndSettingsWrapper>
            <FactorieIcon />
            <ItemFactoriesAndSettingsName $isOpen={isOpen}>
              Factories
            </ItemFactoriesAndSettingsName>
            {isOpen && (
              <ChevronRightSettingsWrapper>
                {!isFactoriesOpen ? <ChevronRightIcon /> : <ChevronDownIcon />}
              </ChevronRightSettingsWrapper>
            )}
          </ItemFactoriesAndSettingsWrapper>
        </ItemFactoriesAndSettingsBlock>
      )}

      {isOpen &&
        isFactoriesOpen &&
        menuItemFactories.map((item, index) => (
          <SideBarItem
            key={index}
            item={item}
            isOpen={isOpen}
            location={location}
          />
        ))}

      <LinksBlock>
        {menuItemTop.map((item, index) => (
          <SideBarItem
            key={index}
            item={item}
            isOpen={isOpen}
            location={location}
          />
        ))}
      </LinksBlock>

      <ItemFactoriesAndSettingsBlock
        $isOpen={isOpen}
        onClick={() => toggleFactoriesAndSettings("settings")}
      >
        <ItemFactoriesAndSettingsWrapper>
          <SettingsIcon />
          <ItemFactoriesAndSettingsName $isOpen={isOpen}>
            Settings
          </ItemFactoriesAndSettingsName>
          {isOpen && (
            <ChevronRightSettingsWrapper>
              {!isSettingsOpen ? <ChevronRightIcon /> : <ChevronDownIcon />}
            </ChevronRightSettingsWrapper>
          )}
        </ItemFactoriesAndSettingsWrapper>
      </ItemFactoriesAndSettingsBlock>

      {isOpen &&
        isSettingsOpen &&
        menuItemSettings.map((item, index) => (
          <SideBarItem
            key={index}
            item={item}
            isOpen={isOpen}
            location={location}
          />
        ))}

      <MidleBlock>
        {menuItemMidle.map((item, index) => (
          <SideBarItem
            key={index}
            item={item}
            isOpen={isOpen}
            location={location}
          />
        ))}
      </MidleBlock>

      <BottomBlock>
        <UserContainer $isOpen={isOpen} onDoubleClick={handleDoubleClick}>
          <SideBarUserDetails isOpen={isOpen} />
        </UserContainer>
        <ItemBlockLog $isOpen={isOpen} onClick={handleLogOut}>
          <ItemWrapperLog>
            <LogOutIcon />
            {isUserLoggedIn ? (
              <ItemName $isOpen={isOpen}>Log Out</ItemName>
            ) : (
              <ItemName $isOpen={isOpen}>Log In</ItemName>
            )}
          </ItemWrapperLog>
        </ItemBlockLog>
        <SwitcherBlockDown>
          <Switch />
        </SwitcherBlockDown>
      </BottomBlock>
    </Container>
  );
};

export default SideBar;
