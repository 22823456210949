import { useAppSelector } from "../store/hooks";
import {
  setAllFavorites,
  setFavorites,
  setFavoritesPending,
  setFetchUrl,
  setInternetSearchFactories,
  setLocation,
  setPendingGeocodeSearch,
  setPendingInternetSearch,
  setTopKeywords,
} from "../store/slices/internetSearchSlice";
import { AppDispatch, store } from "../store/store";
import {
  position_url,
  search_url,
  token_for_position_url,
} from "../utils/constants";

export const searchKeywords = (token: string, page: number) => {
  return async (dispatch: AppDispatch, getState: typeof store.getState) => {
    try {
      dispatch(setPendingInternetSearch(token));
      const state = getState();
      const text = replaceSpacesWithUnderscore(
        state.internetSearch.keywordsSearch.toLowerCase()
      );
      const location = state.internetSearch.location;
      const data = [{ lat: location.latitude, lon: location.longitude }];
      const response = await fetch(
        text
          ? `${search_url}/search/keywords?ltd=${location.latitude}&lng=${location.longitude}&radius=3000&keywords=${text}&size=10&page=${page}`
          : `${search_url}/search/nearby/main?ltd=${location.latitude}&radius=3000&lng=${location.longitude}&size=10&page=${page}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${getState().user.userAccount?.token}`,
          },
        }
      );
      // const response =  await fetch(
      //   `${search_url}/search/providers?keywords=${text}&rating=1,2,3,4,5&size=100&page=0`, {
      //   method: 'POST',
      //   body: JSON.stringify(data)
      // })
      if (!response.ok) {
        throw new Error(`wrong response from server (${response.status})`);
      } else {
        const date = await response.json();
        dispatch(setInternetSearchFactories(date));
      }
    } catch (error: any) {
      dispatch(setInternetSearchFactories([]));
      // throw error
      console.log(error);
    }
  };
};

export const searchByLocation = (token: string) => {
  return async (dispatch: AppDispatch, getState: typeof store.getState) => {
    try {
      dispatch(setPendingInternetSearch(token as string));
      const state = getState();
      // const text = replaceSpacesWithUnderscore(state.internetSearch.keywordsSearch.toLowerCase())
      const location = state.internetSearch.location;
      const response = await fetch(
        `${search_url}/search/nearby/main?ltd=${location.latitude}&radius=3000&lng=${location.longitude}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${getState().user.userAccount?.token}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error(`wrong response from server (${response.status})`);
      } else {
        const date = await response.json();
        dispatch(setInternetSearchFactories(date));
      }
    } catch (error: any) {
      dispatch(setInternetSearchFactories([]));
      // throw error
    }
  };
};

export interface LocationRequest {
  country: string;
  city: string;
}
export const getGeocodeByCountryCity = (
  payload: LocationRequest,
  page: number,
  rating: string = "",
  reviews: string = "",
  industry: string = "",
  sort: string = "",
  token: string
) => {
  return (dispatch: AppDispatch, getState: typeof store.getState) => {
    const functionForVladislove = async (
      dispatch: AppDispatch,
      getState: typeof store.getState
    ) => {
      try {
        dispatch(setPendingGeocodeSearch(true));
        const state = getState();
        const location = state.internetSearch.location;
        const requestBody =
          payload.country === ""
            ? JSON.stringify([
                { lat: location.latitude, lon: location.longitude },
              ])
            : JSON.stringify([]);
        const response = await fetch(
          `${search_url}/search/providers?country=${payload.country}&city=${payload.city}&size=10&page=${page}&rating=${rating}&reviews=${reviews}&industry=${industry}&sort=${sort}`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${getState().user.userAccount?.token}`,
              "Content-Type": "application/json",
            },
            body: requestBody,
          }
        );
        if (!response.ok) {
          throw new Error(`wrong response from server (${response.status})`);
        } else {
          const date = await response.json();
          dispatch(setInternetSearchFactories(date));
        }
      } catch (error: any) {
        console.log(error);
      } finally {
        dispatch(setPendingGeocodeSearch(false));
      }
    };
    functionForVladislove(dispatch, getState);
  };
};

export const getLocation = () => {
  return async (dispatch: AppDispatch) => {
    try {
      if (!navigator.geolocation) {
        throw new Error("Geolocation is not supported by your browser");
      }
      navigator.geolocation.getCurrentPosition(
        (p) => {
          const { latitude, longitude } = p.coords;
          dispatch(setLocation({ latitude, longitude }));
        },
        (error) => {
          dispatch(
            setLocation({
              latitude: 31.91219667008985,
              longitude: 34.807007776593295,
            })
          );
        }
      );
    } catch (e) {}
  };
};

export const searchTopKeywords = (token: string) => {
  return async (dispatch: AppDispatch, getState: typeof store.getState) => {
    try {
      dispatch(setPendingInternetSearch(token));
      const response = await fetch(`${search_url}/search/top/keywords?size=5`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${getState().user.userAccount?.token}`,
        },
      });
      if (!response.ok) {
        throw new Error(`wrong response from server (${response.status})`);
      } else {
        const data = await response.json();
        dispatch(setTopKeywords(data));
      }
    } catch (error: any) {
      console.log(error);
    }
  };
};

export const getFetchUrl = (url: string, token: string) => {
  return async (dispatch: AppDispatch, getState: typeof store.getState) => {
    try {
      const response = await fetch(`${search_url}/fetch-url?url=${url}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${getState().user.userAccount?.token}`,
        },
      });
      if (!response.ok) {
        throw new Error(`wrong response from server (${response.status})`);
      } else {
        const data = await response.json();
        dispatch(setFetchUrl(data));
      }
    } catch (error: any) {
      console.log(error);
    }
  };
};

export const trackClick = (url: string, timeSpent: number) => {
  return async (dispatch: AppDispatch, getState: typeof store.getState) => {
    try {
      const token = getState().user.userAccount?.token;
      if (!token) {
        throw new Error("No token found");
      }

      const response = await fetch(
        `${search_url}/search/track/click?url=${url}&timeSpent=${timeSpent}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`Wrong response from server (${response.status})`);
      } else {
        const data = await response.json();
      }
    } catch (error: any) {
      console.log(error);
    }
  };
};

export const saveClick = (placeId: string, token: string) => {
  return async (dispatch: AppDispatch, getState: typeof store.getState) => {
    try {
      const token = getState().user.userAccount?.token;
      if (!token) {
        throw new Error("No token found");
      }

      const response = await fetch(
        `${search_url}/search/favorite?place_id=${placeId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      if (!response.ok) {
        throw new Error(`Wrong response from server (${response.status})`);
      } else {
        const newCompany = await response.json();
        const updatedFavorites = [...getState().internetSearch.favorites!.content, newCompany];
        dispatch(setFavorites({ ...getState().internetSearch.favorites, content: updatedFavorites }));
        dispatch(setAllFavorites(updatedFavorites));
      }
    } catch (error: any) {
      console.log(error);
    }
  };
};

export const deleteFavorite = (placeId: string, token: string) => {
  return async (dispatch: AppDispatch, getState: typeof store.getState) => {
    try {
      const token = getState().user.userAccount?.token;
      if (!token) {
        throw new Error("No token found");
      }

      const response = await fetch(
        `${search_url}/search/favorite?place_id=${placeId}`,
        {
          method: "delete",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`Wrong response from server (${response.status})`);
      } else {
        const currentFavorites = getState().internetSearch.favorites!.content;
        const updatedFavorites = currentFavorites.filter(
          (company) => company.place_id !== placeId
        );
        dispatch(setFavorites({ ...getState().internetSearch.favorites, content: updatedFavorites }));
        dispatch(setAllFavorites(updatedFavorites))
      }
    } catch (error: any) {
      console.log(error);
    }
  };
};
export const getAllFavorite = (token: string, page: number) => {
  return async (dispatch: AppDispatch, getState: typeof store.getState) => {
    try {
      dispatch(setFavoritesPending(true));
      const response = await fetch(
        `${search_url}/search/favorite/providers?page=${page}&size=10`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${getState().user.userAccount?.token}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error(`wrong response from server (${response.status})`);
      } else {
        const data = await response.json();
        dispatch(setFavorites(data));
      }
    } catch (error: any) {
      console.log(error);
    } finally {
      dispatch(setFavoritesPending(false));
    }
  };
};

export const getAllFavoriteWithoutPagination = (token: string) => {
  return async (dispatch: AppDispatch, getState: typeof store.getState) => {
    try {
      // dispatch(setFavoritesPending(true));
      const response = await fetch(
        `${search_url}/search/favorite/providers?all=true`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${getState().user.userAccount?.token}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error(`wrong response from server (${response.status})`);
      } else {
        const data = await response.json();
        dispatch(setAllFavorites(data));
      }
    } catch (error: any) {
      console.log(error);
    }
    // finally {
    //   dispatch(setFavoritesPending(false));
    // }
  };
};


export function replaceSpacesWithUnderscore(text: string) {
  return text.replace(/ /g, "_");
}

export function replaceUnderscoreWithSpaces(text: string) {
  return text.replace(/_/g, " ");
}
