import React, { useEffect, useState } from "react";
import SearchIcon from "../../assets/icons/SearchIcon";
import PopularRequest from "../PopularRequest/PopularRequest";
import { useNavigate } from "react-router-dom";
import { routes } from "../../routes/routes";
import {
  BlockTitle,
  ButtonBlock,
  ChevronDown,
  CountryInputWrapper,
  IndustryList,
  IndustrySearchWrapper,
  Input,
  RequestBlock,
  SearchInputWrapper,
  Title,
  Wrapper,
} from "./Styles";
import {
  IndustryItem,
  InputWrapper,
  SearchInputIndustries,
} from "../Filter/style";
import ChevronDownIcon from "../../assets/icons/ChevronDownIcon";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setKeywordsSearch } from "../../store/slices/internetSearchSlice";
import { searchKeywords, searchTopKeywords } from "../../api/internetSearch";
import { useDispatch } from "react-redux";

interface SearchInputProps {
  onFilterChange: (filteredIndustry: string) => void;
  companyItems: Company[];
}

interface Company {
  id: number;
  name: string;
  Location: string;
  Industry: string;
  Keywords: string;
  Rating: number;
  Reviews: number;
  Logo: React.ReactNode;
}
export const popular = [
  "Men’s Fashion",
  "Manufacturing",
  "Technology",
  "Energy",
  "Engineering",
  "Innovation",
];

const SearchInput: React.FC<SearchInputProps> = ({
  onFilterChange,
  companyItems,
}) => {
  const navigate = useNavigate();

  const [showIndustryDropdown, setShowIndustryDropdown] = useState(false);
  const searchedText = useAppSelector(
    (state) => state.internetSearch.keywordsSearch
  );
  const [industrySearchText, setIndustrySearchText] = useState(searchedText);
  const dispatch = useAppDispatch();
  const token = useAppSelector((state) => state.user.userAccount?.token);
  const topKeywords = useAppSelector((state) => state.internetSearch.topKeywords);

  const page = 0;
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIndustrySearchText(e.target.value);
  };

  const handleIndustryClick = (industry: string) => {
    setIndustrySearchText(industry);
    setShowIndustryDropdown(false);
    onFilterChange(industry);
  };

  const handleSearchClick = () => {
    // ADD Dispach
    dispatch(setKeywordsSearch(industrySearchText));
    dispatch(searchKeywords(token as string, page as number));
    navigate(routes.NETWORKSEARCH);
  };

  const filteredIndustries = Array.from(
    new Set(companyItems.map((company) => company.Industry))
  ).filter((industry) =>
    industry.toLowerCase().includes(industrySearchText.toLowerCase())
  );
  useEffect(() => {
    setIndustrySearchText(searchedText);
  }, [searchedText]);

  useEffect(() => {
    dispatch(searchTopKeywords(token as string));
  }, [dispatch, token]);

  return (
    <>
      <div>
        <Wrapper>
          <BlockTitle>
            <Title>
              Internet Search: Type your inquiry to find numerous factories
              worldwide.
            </Title>
          </BlockTitle>
          <InputWrapper>
            {!showIndustryDropdown ? (
              <>
                <SearchInputWrapper>
                  <Input
                    type="text"
                    placeholder="Search by product, industry or keywords"
                    value={industrySearchText}
                    onChange={handleInputChange}
                    onFocus={() => setShowIndustryDropdown(true)}
                  />
                  <ButtonBlock onClick={handleSearchClick}>
                    <SearchIcon />
                  </ButtonBlock>
                </SearchInputWrapper>
              </>
            ) : (
              <>
                <IndustrySearchWrapper>
                  <CountryInputWrapper
                    onClick={() => setShowIndustryDropdown(false)}
                  >
                    <ChevronDown>
                      <ChevronDownIcon />
                    </ChevronDown>
                    <SearchInputIndustries
                      placeholder="Search by product, industry or keywords"
                      type="text"
                      autoFocus
                      value={industrySearchText}
                      onChange={handleInputChange}
                    />
                  </CountryInputWrapper>
                  <IndustryList>
                    {filteredIndustries.map((industry) => (
                      <IndustryItem
                        key={industry}
                        onClick={() => handleIndustryClick(industry)}
                      >
                        {industry}
                      </IndustryItem>
                    ))}
                  </IndustryList>
                </IndustrySearchWrapper>
                <ButtonBlock onClick={handleSearchClick}>
                  <SearchIcon />
                </ButtonBlock>
              </>
            )}
          </InputWrapper>
          <RequestBlock>
            <Title>Popular:</Title>
            {topKeywords.map((keyword) => ( 
              <PopularRequest name={keyword} key={keyword} />
            ))}
            {/* <PopularRequest name={`Men's Fashion`} />
            <PopularRequest name={"Manufacturing"} />
            <PopularRequest name={"Technology"} />
            <PopularRequest name={"Energy"} />
            <PopularRequest name={"Engineering"} />
            <PopularRequest name={"Innovation"} /> */}
          </RequestBlock>
        </Wrapper>
      </div>
    </>
  );
};

export default SearchInput;
