import React, { useState } from "react";
import { Item } from "../../../utils/types";
import {
  AboutProduct,
  CompanyPhoto,
  CompanyPhotoElement,
  Container,
  EllipseWrapper,
  GreenParagraph,
  ImagesContainer,
  LeftWrapper,
  NameProduct,
  OrderQuantity,
  Paragraph,
  PhotoMini,
  PhotoMinContainer,
  PhotoMinWrapper,
  PhotoWrapper,
  RightWrapper,
  SubParagraph,
  SubTitle,
  UnitPrice,
  Wrapper,
} from "./styles";
import ChevronDownIcon from "../../../assets/icons/ChevronDownIcon";
import ChevronUpIcon from "../../../assets/icons/ChevronUpIcon";
import { defaultImg } from "../../../assets/img";

const ProductById: React.FC<{ product: Item }> = ({ product }) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [visibleStartIndex, setVisibleStartIndex] = useState<number>(0);
  const visibleCount = 6;

  const handleUpArrowClick = () => {
    const newIndex =
      currentIndex === 0 ? product.images.length - 1 : currentIndex - 1;
    const newVisibleStartIndex =
      newIndex < visibleStartIndex ? newIndex : visibleStartIndex;
    setCurrentIndex(newIndex);
    setVisibleStartIndex(newVisibleStartIndex);
    if (newIndex === product.images.length - 1) {
      setVisibleStartIndex(product.images.length - visibleCount);
    }
  };

  const handleDownArrowClick = () => {
    setCurrentIndex((prevIndex) => {
      const newIndex =
        prevIndex === product.images.length - 1 ? 0 : prevIndex + 1;
      if (newIndex >= visibleStartIndex + visibleCount) {
        setVisibleStartIndex(newIndex - visibleCount + 1);
      }
      if (newIndex === 0) {
        setVisibleStartIndex(0);
      }
      return newIndex;
    });
  };

  const handlePhotoMiniClick = (index: number) => {
    setCurrentIndex(index);
    if (index < visibleStartIndex) {
      setVisibleStartIndex(index);
    } else if (index >= visibleStartIndex + visibleCount) {
      setVisibleStartIndex(index - visibleCount + 1);
    }
  };

  return (
    <Container>
      <NameProduct>{product.name}</NameProduct>
      <Wrapper>
        <LeftWrapper>
          <ImagesContainer>
            <CompanyPhoto>
              {product.images.length > 0 && (
                <PhotoWrapper>
                  <CompanyPhotoElement
                    src={product.images[currentIndex].link || defaultImg}
                    alt={product.images[currentIndex].name}
                  />
                  <PhotoMinContainer
                    $isCenter={product.images.length > visibleCount}
                  >
                    {product.images.length > visibleCount && (
                      <EllipseWrapper onClick={handleUpArrowClick}>
                        <ChevronUpIcon />
                      </EllipseWrapper>
                    )}
                    <PhotoMinWrapper>
                      {product.images
                        .slice(
                          visibleStartIndex,
                          visibleStartIndex + visibleCount
                        )
                        .map((image, index) => (
                          <PhotoMini
                            key={visibleStartIndex + index}
                            src={image.link || defaultImg}
                            alt={image.name || ""}
                            onClick={() =>
                              handlePhotoMiniClick(visibleStartIndex + index)
                            }
                            $isOpen={currentIndex === visibleStartIndex + index}
                          />
                        ))}
                    </PhotoMinWrapper>
                    {product.images.length > visibleCount && (
                      <EllipseWrapper onClick={handleDownArrowClick}>
                        <ChevronDownIcon />
                      </EllipseWrapper>
                    )}
                  </PhotoMinContainer>
                </PhotoWrapper>
              )}
            </CompanyPhoto>
          </ImagesContainer>
        </LeftWrapper>
        <RightWrapper>
          <AboutProduct>
            <Paragraph>{product.description}</Paragraph>
          </AboutProduct>
          <OrderQuantity>
            <SubTitle>Minimum order quantity:</SubTitle>
            <SubParagraph>{product.minimumOrderQuantity}</SubParagraph>
          </OrderQuantity>
          <UnitPrice>
            <SubTitle>Unit price:</SubTitle>
            <GreenParagraph>{product.price} $</GreenParagraph>
          </UnitPrice>
        </RightWrapper>
      </Wrapper>
    </Container>
  );
};

export default ProductById;
