import styled from "styled-components";


export const FilterSwitchBlock = styled.div<{ $back: string }>`
  border-radius: 4px;
  border: 1px solid var(--battn-border, #004c62);
  height: 16px;
  cursor: pointer;
  background-color: ${props=>props.$back};
  display: inline-flex;
  align-items: center;
  margin: 0 8px 0 20px;
`;

export const IconWrapper = styled.div<{fill: string}>`
  svg {
    fill: ${props=>props.fill};
    padding: 1px;
  }
`;

export const FilterSwitchWrapper=styled.div`
display: flex;
align-items: center;
`;