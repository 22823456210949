import React, { useEffect, useState } from "react";
import ArrowUpDownIcon from "../../../assets/icons/ArrowUpDownIcon";
import {
  IconContainer,
  MainContainer,
  SwitchButton,
  SwitchContainer,
  FilterByP,
  BasicInformation,
  ButtonInformation,
  ButtonClear,
  BasicInformationWrapper,
  BasicInformationOpen,
  ChevronRightWrapper,
  ClearWrapper,
} from "./style";
import ChevronRightIcon from "../../../assets/icons/ChevronRightIcon";
import ChevronDownIcon from "../../../assets/icons/ChevronDownIcon";
import { ButtonFilter } from "../../PartnerBasePage/PartnerBaseFilter/style";
import { AgentBaseAgent, AgentDetails } from "../../../utils/types";
import BasicInformationFilter from "../BasicInformationFilter/BasicInformationFilter";
import ReputationFilter from "../ReputationFilter/ReputationFilter";
import FinancicalDetailsFilter from "../FinancicalDetailsFilter/FinancicalDetailsFilter";
import { useDispatch, useSelector } from "react-redux";
// import { getFilterValuesAgentAsync } from "../../../store/slices/userSlice";
import { RootState } from "../../../store/store";

interface AgentFilterProps {
  handleApplyFilter: () => void;
  filterValues: AgentDetails;
  setFilterValues: React.Dispatch<React.SetStateAction<AgentDetails>>;
  allAgents: AgentBaseAgent[];
}

const AgentFilter: React.FC<AgentFilterProps> = ({
  handleApplyFilter,
  setFilterValues,
  filterValues,
  allAgents,
}) => {
  const [activeButton, setActiveButton] = useState("All");
  const [showBasicInformation, setShowBasicInformation] = useState(false);
  const [showReputationInformation, setShowReputationInformation] =
    useState(false);
  const [showFinancicalInformation, setShowFinancicalInformation] =
    useState(false);
  const [iconType, setIconType] = useState("ChevronRightIcon");

  const handleButtonClick =
    (stateUpdater: (arg: (prevState: any) => boolean) => void, state: string) =>
    () => {
      stateUpdater((prevState) => !prevState);
      if (state === "ChevronRightIcon") {
        setIconType("ChevronDownIcon");
      } else {
        setIconType("ChevronRightIcon");
      }
    };

  const handleClick = (button: string) => {
    setActiveButton(button);
  };

  const handleIconClickMainContainer = () => {
    setActiveButton((prev) => (prev === "All" ? "Saved" : "All"));
  };

  const [clearAllClicked, setClearAllClicked] = useState(false);

  const handleClearAll = () => {
    setClearAllClicked(true);
    setFilterValues({});
  };

  if (clearAllClicked) {
    handleApplyFilter();
    setClearAllClicked(false);
  }

  const handleApplyFilterAndResetPage = () => {
    setFilterValues((prevValues) => ({ ...prevValues, page: 0 }));
    handleApplyFilter();
  };


  return (
    <MainContainer>
      <SwitchContainer>
        <SwitchButton
          isActive={activeButton === "All"}
          onClick={() => handleClick("All")}
        >
          All
        </SwitchButton>
        <IconContainer onClick={handleIconClickMainContainer}>
          <ArrowUpDownIcon />
        </IconContainer>
        <SwitchButton
          isActive={activeButton === "Saved"}
          onClick={() => handleClick("Saved")}
        >
          Saved
        </SwitchButton>
      </SwitchContainer>

      <FilterByP>Filter by</FilterByP>

      <div>
        {!showBasicInformation ? (
            <BasicInformation onClick={handleButtonClick(setShowBasicInformation, iconType)}>
              <ButtonInformation
                // onClick={handleButtonClick(setShowBasicInformation, iconType)}
              >
                Basic Information
              </ButtonInformation>
              <ChevronRightWrapper
                // onClick={handleButtonClick(setShowBasicInformation, iconType)}
              >
                <ChevronRightIcon />
              </ChevronRightWrapper>
            </BasicInformation>
        ) : (
          <BasicInformationWrapper>
            <BasicInformationOpen>
              <ButtonInformation
                onClick={handleButtonClick(setShowBasicInformation, iconType)}
              >
                Basic Information
              </ButtonInformation>
              <ChevronRightWrapper
                onClick={handleButtonClick(setShowBasicInformation, iconType)}
              >
                <ChevronDownIcon />
              </ChevronRightWrapper>
            </BasicInformationOpen>
            <BasicInformationFilter
              teamList={allAgents}
              setFilterValues={setFilterValues}
              filterValues={filterValues}
              onFilterChange={(newFilterValues) =>
                setFilterValues((prevFilterValues) => ({
                  ...prevFilterValues,
                  ...newFilterValues,
                }))
              }
            />
          </BasicInformationWrapper>
        )}
      </div>

      <div>
        {!showReputationInformation ? (
          <>
            <BasicInformation 
              onClick={handleButtonClick(
                setShowReputationInformation,
                iconType
              )}
              >
              <ButtonInformation
                // onClick={handleButtonClick(
                //   setShowReputationInformation,
                //   iconType
                // )}
              >
                Reputation
              </ButtonInformation>
              <ChevronRightWrapper
                // onClick={handleButtonClick(
                //   setShowReputationInformation,
                //   iconType
                // )}
              >
                <ChevronRightIcon />
              </ChevronRightWrapper>
            </BasicInformation>
          </>
        ) : (
          <BasicInformationWrapper>
            <BasicInformationOpen>
              <ButtonInformation
                onClick={handleButtonClick(
                  setShowReputationInformation,
                  iconType
                )}
              >
                Reputation
              </ButtonInformation>
              <ChevronRightWrapper
                onClick={handleButtonClick(
                  setShowReputationInformation,
                  iconType
                )}
              >
                <ChevronDownIcon />
              </ChevronRightWrapper>
            </BasicInformationOpen>
            <ReputationFilter
              companyItems={allAgents}
              setFilterValues={setFilterValues}
              filterValues={filterValues}
              onFilterChange={(newFilterValues) =>
                setFilterValues((prevFilterValues) => ({
                  ...prevFilterValues,
                  ...newFilterValues,
                }))
              }
            />
          </BasicInformationWrapper>
        )}
      </div>

      <div>
        {!showFinancicalInformation ? (
          <>
            <BasicInformation
              onClick={handleButtonClick(
                setShowFinancicalInformation,
                iconType
              )}
              >
              <ButtonInformation
                // onClick={handleButtonClick(
                //   setShowFinancicalInformation,
                //   iconType
                // )}
              >
                Financial Details
              </ButtonInformation>
              <ChevronRightWrapper
                // onClick={handleButtonClick(
                //   setShowFinancicalInformation,
                //   iconType
                // )}
              >
                <ChevronRightIcon />
              </ChevronRightWrapper>
            </BasicInformation>
          </>
        ) : (
          <BasicInformationWrapper>
            <BasicInformationOpen>
              <ButtonInformation
                onClick={handleButtonClick(
                  setShowFinancicalInformation,
                  iconType
                )}
              >
                Financial Details
              </ButtonInformation>
              <ChevronRightWrapper
                onClick={handleButtonClick(
                  setShowFinancicalInformation,
                  iconType
                )}
              >
                <ChevronDownIcon />
              </ChevronRightWrapper>
            </BasicInformationOpen>
            <FinancicalDetailsFilter
              companyItems={allAgents}
              setFilterValues={setFilterValues}
              filterValues={filterValues}
              onFilterChange={(newFilterValues) =>
                setFilterValues((prevFilterValues) => ({
                  ...prevFilterValues,
                  ...newFilterValues,
                }))
              }
            />
          </BasicInformationWrapper>
        )}
      </div>

      <ButtonFilter onClick={handleApplyFilterAndResetPage}>
        Apply Filter
      </ButtonFilter>
      <ClearWrapper
        onClick={() => {
          handleClearAll();
        }}
      >
        Clear all
      </ClearWrapper>
    </MainContainer>
  );
};

export default AgentFilter;
