import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import { getPartnerBaseFiltersValues, getPopularPartnerIndustry } from "./PartnerBaseSearchActions";

// new slices

export const getPopularPartnerIndustryAsync = createAsyncThunk(
  "popularPartnerIndustry/get",
  async (_, thunkAPI) => {
      try {
          const response = await getPopularPartnerIndustry();
          return response.data;
      } catch (error) {
          return thunkAPI.rejectWithValue(error);
      }
  }
);

export const getPartnerBaseFiltersValuesAsync = createAsyncThunk(
  "partnerBaseFiltersValues/get",
  async (_, thunkAPI) => {
    try {
      const response = await getPartnerBaseFiltersValues();
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

interface PartnerBaseSearch {
  status: string;
  popularPartnerIndustry: string[];
  partnerFilterValues: {
    [key: string]: string[];
  };
  error: null;
}

const initialState: PartnerBaseSearch = {
  status: "idle",
  popularPartnerIndustry: [],
  partnerFilterValues: {},
  error: null,
};

const PartnerBaseSearchSlice = createSlice({
  name: "partnerBaseSearch",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPopularPartnerIndustryAsync.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getPopularPartnerIndustryAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.popularPartnerIndustry = action.payload as any;
      })
      .addCase(getPopularPartnerIndustryAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message as any;
      })

      .addCase(getPartnerBaseFiltersValuesAsync.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getPartnerBaseFiltersValuesAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.partnerFilterValues = action.payload as any;
      })
      .addCase(getPartnerBaseFiltersValuesAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message as any;
      })
  },
});

export const {} = PartnerBaseSearchSlice.actions;
export default PartnerBaseSearchSlice.reducer;