import React, { useEffect, useState } from "react";
import {
  Container,
  Item,
  ItemSubtitle,
  ItemText,
  ItemTitle,
  List,
  TextWrapper,
  Title,
  Wrapper,
} from "./style";

import { useAppSelector } from "../../../store/hooks";
import { RootState } from "../../../store/store";
import {
  BriefItem,
  DelegateSearchType,
  MessageToProvider,
} from "../../../utils/types";
import TrashIconBrief from "../../../assets/icons/TrashIconBrief";
import { useDispatch } from "react-redux";
import {
  downloadDelegatedBrif,
  getAllDelegatedBriefAsync,
  getDelegatedBrif,
  removeDelegatedBrief,
} from "../../../store/slices/userSlice";
import DownloadIcon from "../../../assets/icons/DownloadIcon";
import ModalLostChanges from "../../ModalWindows/ModalLostChanges/ModalLostChanges";
import Magnifying from "../../../assets/icons/Magnifying";

interface BriefListProps {
  delegateSearch?: DelegateSearchType;
  setDelegateSearch?: React.Dispatch<React.SetStateAction<DelegateSearchType>>;
  setBriefId?: React.Dispatch<React.SetStateAction<MessageToProvider>>;
  message?: MessageToProvider;
  selectedBrief?: number[];
  setSelectedBrief?: React.Dispatch<React.SetStateAction<number[]>>;
  setShowModalMagnifying?: React.Dispatch<React.SetStateAction<boolean>>;
}

const BriefList: React.FC<BriefListProps> = ({
  delegateSearch,
  setDelegateSearch,
  setBriefId,
  message,
  selectedBrief,
  setSelectedBrief,
  setShowModalMagnifying,
}) => {
  const dispatch = useDispatch();
  const allBriefs = useAppSelector(
    (state: RootState) => state.user.delegateBrief
  );
  const userAccount = useAppSelector(
    (state: RootState) => state.user.userAccount!
  );
  const status = useAppSelector((state) => state.user.status);
  const [briefList, setBriefList] = useState<BriefItem[]>(allBriefs.content);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [clickedBriefId, setClickedBriefId] = useState<number>(0);

  const formatCreateTime = (isoString: string) => {
    const dateObj = new Date(isoString);
    return dateObj.toLocaleString();
  };

  const trimOverview = (text: string) => {
    return text?.length > 40 ? `${text.substring(0, 40)}...` : text;
  };

  useEffect(() => {
    setBriefList(allBriefs.content);
  }, [allBriefs.content]);

  useEffect(() => {
    return () => {
      dispatch(getAllDelegatedBriefAsync() as any);
    };
  }, []);

  const handleDeleteBrief = (briefId: number) => () => {
    dispatch(
      removeDelegatedBrief({ id: briefId, token: userAccount.token }) as any
    ).then(() => {
      if (status === "succeeded") {
        setBriefList(briefList.filter((brief) => brief.id !== briefId));
      }
    });
  };

  const handleGetBrief = (briefId: number) => () => {
    setClickedBriefId(briefId);
    if (message) {
      return isClickedBrief(briefId);
    }
    if (delegateSearch) {
      Object.keys(delegateSearch).length > 0
        ? setShowModal(!showModal)
        : dispatchBrief(briefId);
    } else {
      dispatchBrief(briefId);
    }
  };

  const isClickedBrief = (briefId: number) => {
    if (selectedBrief?.includes(briefId)) {
      const updatedBriefIds = selectedBrief.filter((id) => id !== briefId);
      setSelectedBrief && setSelectedBrief(updatedBriefIds);
      setBriefId && setBriefId({ ...message, briefIds: updatedBriefIds });
    } else {
      const updatedBriefIds = [...selectedBrief || [], briefId];
      setSelectedBrief && setSelectedBrief(updatedBriefIds);
      setBriefId && setBriefId({ ...message, briefIds: updatedBriefIds });
    }
  };

  const dispatchBrief = (briefId: number) => {
    dispatch(
      getDelegatedBrif({ id: briefId, token: userAccount.token }) as any
    ).then(({ payload }: { payload: DelegateSearchType }) => {
      if (setDelegateSearch) {
        setDelegateSearch(payload);
      }
    });
  };

  const handleDownloadBrief =
    (briefId: number, identification: string) => () => {
      dispatch(
        downloadDelegatedBrif({
          id: briefId,
          token: userAccount.token,
          nameBrief: identification,
        }) as any
      );
    };

  const handleDiscardChanges = () => {
    setShowModal(!showModal);
    setDelegateSearch && setDelegateSearch({});
    dispatchBrief(clickedBriefId);
  };

  const handlEditing = () => {
    setShowModal(!showModal);
  };

  const handleShowBrief = (briefId: number) => () => {
    dispatchBrief(briefId);
    setShowModalMagnifying && setShowModalMagnifying(true);
  };


  return (
    <>
      <Container>
        <Title>Saved briefs</Title>
        <List>
          {briefList.map(
            ({
              id,
              createTime,
              deliveryDeadline,
              identification,
              name,
              overview,
            }) => (
              <Wrapper key={id}>
                <Item
                  onClick={handleGetBrief(id)}
                  $isActive={selectedBrief?.includes(id)}
                >
                  <ItemTitle>{name}</ItemTitle>
                  <TextWrapper>
                    <ItemSubtitle>ID:</ItemSubtitle>
                    <ItemText> {identification}</ItemText>
                  </TextWrapper>
                  <TextWrapper>
                    <ItemSubtitle>Created:</ItemSubtitle>
                    <ItemText> {formatCreateTime(createTime)}</ItemText>
                  </TextWrapper>
                  {overview && (
                    <TextWrapper>
                      <ItemSubtitle>Overview:</ItemSubtitle>
                      <ItemText> {trimOverview(overview)}</ItemText>
                    </TextWrapper>
                  )}
                </Item>
                {message ? (
                  <Magnifying onClick={handleShowBrief(id)} />
                ) : (
                  <>
                    <TrashIconBrief onClick={handleDeleteBrief(id)} />
                    <DownloadIcon
                      onClick={handleDownloadBrief(id, identification)}
                    />
                  </>
                )}
              </Wrapper>
            )
          )}
        </List>
      </Container>
      {showModal && (
        <ModalLostChanges
          text="The changes you made to  this page will be lost"
          buttonText="Keep editing"
          buttonWhitetext="Discard"
          handleWhiteButtonClick={handleDiscardChanges}
          handleButtonClick={handlEditing}
        />
      )}
    </>
  );
};

export default BriefList;
