import styled from "styled-components";
import { Link } from "react-router-dom";

interface IChevroneProps {
  $isOpen: boolean;
}
interface ISelectProps {
  $isSelected: boolean | null;
}

interface IconProps {
  $isIcon?: boolean | null;
}

const Container = styled.div<IChevroneProps>`
  width: ${(props) => (props.$isOpen ? "300px" : "140px")};
  background: var(--wiht, #fff);
  transition: width 0.3s ease;
  white-space: nowrap;
`;

const StyledLink = styled(Link)`
  margin-bottom: 29px;
`;

const TopSection = styled.div<IChevroneProps>`
  justify-content: ${(props) => (props.$isOpen ? "space-between" : "flex-end;")};
  gap: ${(props) => (props.$isOpen ? "16px;" : " 12px")};
  display: flex;
  align-items: center;
  margin: ${(props) =>
    props.$isOpen ? "30px 0 42px 30px" : " 30px 0 42px 40px"};
  & > a {
    display: flex;
  }
`;

const LogoIcon = styled.img`
  width: 60px;
  height: 60px;
`;

const LogoName = styled.h2`
  display: flex;
  align-items: center;
  font-size: 38px;
  font-weight: 500;
  line-height: 45.6px;
  color: var(--txt-dark, #001731);
`;

const ChevronBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 6px 0px 0px 6px;
  background: var(--batton, #0095c0);
  padding: 8px 0 8px 2px;
`;

const ItemName = styled.h4<IChevroneProps>`
  display: ${(props) => (props.$isOpen ? "" : "none")};
  font-family: "Montserrat", sans-serif;
  font-size: 17px;
  font-weight: 500;
  color: var(--txt-dark, #001731);
`;

const LinksBlock = styled.div`
  padding-bottom: 19px;
  margin-bottom: 20px;
  border-bottom: 1px solid var(--border, #bdbdbd);
`;

const MidleBlock = styled.div`
  margin-bottom: 40px;
`;

const SwitcherBlockDown = styled.div`
  margin-top: 29px;
  & #moon svg * {
    stroke: var(--mooncolor);
  }
  & #sun svg * {
    stroke: var(--suncolor);
  }
`;

const BottomBlock = styled.div``;

const UserAvatar = styled.img`
  width: 58px;
  height: 58px;
  border-radius: 58px;
  object-fit: cover;
`;

const UserWrapper = styled.div<IChevroneProps>`
  display: ${(props) => (props.$isOpen ? "block" : "none")};
  max-width: 172px;
  width: 100%;
  white-space: nowrap;
`;

const UserContainer = styled.div<IChevroneProps>`
  display: flex;
  justify-content: ${(props) => (props.$isOpen ? "flex-start" : "center")};
  align-items: center;
  padding: 0 30px;
  margin-bottom: 204px;
  gap: 10px;
  cursor: pointer;
`;

const UserName = styled.h4`
  font-size: 17px;
  font-weight: 500;
  line-height: 140%;

  color: var(--txt-dark, #001731);
  overflow: hidden;
  text-overflow: ellipsis;
`;

const CompanyName = styled.h6`
  font-size: 18px;
  font-weight: 400;
  line-height: 140%;

  color: var(--txt-dak-light, #143255);
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ItemWrapper = styled.div<ISelectProps & IconProps>`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: ${(props) => (props.$isIcon ? "35px" : "0px")};
  background: ${(props) =>
    props.$isSelected ? "var(--batton, #0095C0)" : "var(--wiht, #FFF)"};
  & svg * {
    stroke: ${(props) =>
      props.$isSelected ? "var(--blue-light, #f4ffff)" : ""};
  }
  & > h4 {
    color: ${(props) =>
      props.$isSelected ? "var(--blue-light, #f4ffff)" : ""};
  }

  &:hover {
    background: var(--batton, #0095c0);
    & svg * {
      stroke: var(--blue-light, #f4ffff);
    }

    & > h4 {
      color: var(--blue-light, #f4ffff);
    }
  }
`;

const ItemBlock = styled.div<IChevroneProps & ISelectProps>`
  position: relative;
  display: flex;
  justify-content: ${(props) => (props.$isOpen ? "space-between" : "center")};
  height: 54px;
  padding: ${(props) =>
    props.$isOpen ? "14px 20px 16px 30px" : "14px 30px 16px 30px"};
  gap: 10px;
  align-items: center;
  border-radius: 16px 0px 0px 16px;

  background: ${(props) =>
    props.$isSelected ? "var(--batton, #0095C0)" : "var(--wiht, #FFF)"};
  & svg * {
    stroke: ${(props) =>
      props.$isSelected ? "var(--blue-light, #f4ffff)" : ""};
  }
  & h4 * {
    color: ${(props) =>
      props.$isSelected ? "var(--blue-light, #f4ffff)" : ""};
  }

  &:hover {
    background: var(--batton, #0095c0);
    & ${ItemWrapper} {
      background: var(--batton, #0095c0);
    }
    & svg * {
      stroke: var(--blue-light, #f4ffff);
    }
    & div > ${ItemName} {
      color: var(--blue-light-hover, #f4ffff);
    }
  }
`;

const MessageWrapper = styled.div<IChevroneProps>`
  display: ${(props) => (props.$isOpen ? "" : "none")};
  padding: 0px 8px;
  border-radius: 16px;
  background: var(--green, #03b62a);
`;

const Message = styled.h4`
  font-size: 17px;
  font-weight: 500;
  line-height: 140%;
  color: var(--txt-light-blue, #f4ffff);
`;

const GreenDot = styled.div<ISelectProps>`
  position: absolute;
  right: 50px;
  top: 6px;

  width: 9px;
  height: 9px;
  background-color: var(--green, #03b62a);
  border-radius: 50%;
  border: 1px solid
    ${(props) =>
      props.$isSelected
        ? "var(--blue-light, #F4FFFF)"
        : "var(--green, #03b62a)"};
`;

const ItemBlockLog = styled.div<IChevroneProps>`
  position: relative;
  display: flex;
  justify-content: ${(props) => (props.$isOpen ? "space-between" : "center")};

  padding: ${(props) =>
    props.$isOpen ? "14px 20px 16px 30px" : "14px 30px 16px 30px"};
  gap: 10px;
  align-items: center;
  border-radius: 16px 0px 0px 16px;
  cursor: pointer;
  &:hover {
    background: var(--batton, #0095c0);
    & svg * {
      stroke: var(--blue-light, #f4ffff);
    }
    & ${ItemName} {
      color: var(--blue-light, #f4ffff);
    }
  }
`;

const ItemWrapperLog = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export {
  ItemBlockLog,
  ItemWrapperLog,
  GreenDot,
  ItemWrapper,
  Message,
  MessageWrapper,
  Container,
  TopSection,
  LogoIcon,
  LogoName,
  ItemName,
  ItemBlock,
  ChevronBlock,
  LinksBlock,
  MidleBlock,
  BottomBlock,
  StyledLink,
  SwitcherBlockDown,
  UserContainer,
  CompanyName,
  UserAvatar,
  UserWrapper,
  UserName,
};

export const ItemFactoriesAndSettingsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  &:hover {
    background: var(--batton, #0095c0);
    & svg * {
      stroke: var(--blue-light, #f4ffff);
    }
    & > h4 {
      color: var(--blue-light, #f4ffff);
    }
  }
`;

export const ItemFactoriesAndSettingsName = styled.h4<IChevroneProps>`
  display: ${(props) => (props.$isOpen ? "" : "none")};
  font-family: "Montserrat", sans-serif;
  font-size: 17px;
  font-weight: 500;
  color: var(--txt-dark, #001731);
`;


export const ItemFactoriesAndSettingsBlock = styled.div<IChevroneProps>`
  display: flex;
  justify-content: ${(props) => (props.$isOpen ? "space-between" : "center")};
  height: 54px;
  padding: ${(props) =>
    props.$isOpen ? "14px 20px 16px 30px" : "14px 30px 16px 30px"};
  border-radius: 16px 0px 0px 16px;

  &:hover {
    cursor: pointer;
    background: var(--batton, #0095c0);
    & ${ItemFactoriesAndSettingsWrapper} {
      background: var(--batton, #0095c0);
    }
    & svg * {
      stroke: var(--blue-light, #f4ffff);
    }
    & div > ${ItemFactoriesAndSettingsName} {
      color: var(--blue-light-hover, #f4ffff);
    }
  }
`;


export const ChevronRightSettingsWrapper = styled.div`
  position: relative;
  left: 105px;
`;