import React from "react";
import styled from "styled-components";
const IconWrapper = styled.div`
  max-width: 42px;
  display: flex;

  padding: 7px;

  border-radius: 50%;
  border: 2px solid var(--border, #bdbdbd);
  background: var(--wiht, #fff);
  cursor: pointer;
`;

interface ICrossCircleProps {
  onClick?: () => void;
}

const CrossCircleIcon: React.FC<ICrossCircleProps> = ({ onClick }) => {
  return (
    <IconWrapper onClick={onClick}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Icons/x31">
          <path
            id="Vector"
            d="M18 6L6 18"
            stroke="#001731"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_2"
            d="M6 6L18 18"
            stroke="#001731"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    </IconWrapper>
  );
};

export default CrossCircleIcon;
