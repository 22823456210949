import React, {useEffect, useState} from "react";
import {
    AvailabilityWrapper,
    Dash,
    IndustryKeqwordsP,
    InputWrapper,
    PriceInput,
    SliderWrapper,
    StyledSlider,
} from "./style";
import "rc-slider/assets/index.css";
import DelegateSwitch from "../../DelegateSwitch/DelegateSwitch";
import {AvailabilitySwitch, OrderQuantity, Paragraph, Quantity, UpParagraph, } from "../../PartnerBasePage/ManufactoringFilter/style";
import {AgentBaseAgent, AgentDetails, AvailabilityStatus, TypeFee} from "../../../utils/types";
import CircleSwitch from "../../CircleSwitch/CircleSwitch";

interface FinancialDetailsFilterProps {
    companyItems: AgentBaseAgent[];
    filterValues: AgentDetails;
    onFilterChange: (filterValues: AgentDetails) => void;
    setFilterValues: React.Dispatch<React.SetStateAction<AgentDetails>>;

}

const FinancialDetailsFilter: React.FC<
    FinancialDetailsFilterProps
> = ({ companyItems, filterValues, setFilterValues, onFilterChange }) => {
    const [searchText, setSearchText] = useState("");

    const prices = companyItems
        .map((company) => parseFloat(company.priceHour.toString()))
        .filter((price) => price.toString().toLowerCase().includes(searchText.toLowerCase()));

    // if (filterValues.minPrice === undefined || filterValues.maxPrice === undefined) {
    //     setFilterValues({ fee: [TypeFee.HOUR, TypeFee.FIX], availability: [AvailabilityStatus.NOW,AvailabilityStatus.NEXT_MONTH,AvailabilityStatus.NEXT_WEEK], minPrice: Math.min(...prices), maxPrice: Math.max(...prices) });
    // }
    const toggleNowAvailability = () => {
        const currentAvailability = filterValues?.availability || [];
        const updatedAvailability = currentAvailability.includes(AvailabilityStatus.NOW)
            ? currentAvailability.filter((status) => status !== AvailabilityStatus.NOW)
            : [...currentAvailability, AvailabilityStatus.NOW];

        setFilterValues({ ...filterValues, availability: updatedAvailability });
    };

    return (
        <div>
            <IndustryKeqwordsP>Fee</IndustryKeqwordsP>
            <AvailabilityWrapper>
                <AvailabilitySwitch>
                    <DelegateSwitch
                        isActive={filterValues?.fee?.includes(TypeFee.HOUR) || false}
                        onToggle={(e) => {
                            const updatedFee = filterValues?.fee?.includes(TypeFee.HOUR)
                                ? filterValues.fee.filter((fee) => fee !== TypeFee.HOUR)
                                : [...(filterValues?.fee || []), TypeFee.HOUR];
                            setFilterValues({ ...filterValues, fee: updatedFee });
                        }}
                    />

                    <UpParagraph>Hour</UpParagraph>
                </AvailabilitySwitch>
                <AvailabilitySwitch>
                    <DelegateSwitch
                        isActive={filterValues?.fee?.includes(TypeFee.FIX) || false}
                        onToggle={(e) => {
                            const updatedFee = filterValues?.fee?.includes(TypeFee.FIX)
                                ? filterValues.fee.filter((fee) => fee !== TypeFee.FIX)
                                : [...(filterValues?.fee || []), TypeFee.FIX];
                            setFilterValues({ ...filterValues, fee: updatedFee });
                        }} />
                    <UpParagraph>Fix</UpParagraph>
                </AvailabilitySwitch>
            </AvailabilityWrapper>
            <SliderWrapper>
                <StyledSlider
                    range
                    min={0}
                    max={1000}
                    value={[filterValues.minPrice || 0, filterValues.maxPrice || 1000]}
                    onChange={(rangeValues) => {
                        const [minPrice, maxPrice] = Array.isArray(rangeValues) ? rangeValues : [rangeValues, rangeValues];
                        setFilterValues({ ...filterValues, minPrice: minPrice, maxPrice: maxPrice });
                    }}
                />
                <InputWrapper>
                    <PriceInput
                        type="text"
                        value={`${filterValues?.minPrice || 0}` + '$'}
                        onChange={(e) => {
                            const numericValue = Number(e.target.value.replace(/[^\d.]/g, ''));
                            setFilterValues({ ...filterValues, minPrice: numericValue });
                        }} />
                    <Dash>—</Dash>
                    <PriceInput
                        type="text"
                        value={` ${filterValues?.maxPrice || 1000}` + '$'}
                        onChange={(e) => {
                            const numericValue = Number(e.target.value.replace(/[^\d.]/g, ''));
                            setFilterValues({ ...filterValues, maxPrice: numericValue });
                        }}
                    />
                </InputWrapper>
            </SliderWrapper>
            <OrderQuantity>
                <Quantity>Available now:</Quantity>
                <div style={{display: 'flex'}} >
                    <CircleSwitch
                        isActive={filterValues?.availability?.includes(AvailabilityStatus.NOW) || false}
                        onToggle={toggleNowAvailability}
                    />
                    <Paragraph>Yes</Paragraph>
                </div>
            </OrderQuantity>
        </div>
    );
};

export default FinancialDetailsFilter;
