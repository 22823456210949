import styled from "styled-components";

export const StyledButtonWhite = styled.button<{$isSelected?: boolean, modal?: boolean,  disabled?: boolean;}>`
  padding: 14px 30px 16px 30px;
  width: ${({ modal }) => (modal ? "100%" : "auto")};

  font-size: 17px;
  line-height: 140%;

  border-radius: 16px;
  color: var(--txt-dark, #001731);
  border: 1px solid var(--battn-border, #004c62);
  background: ${(props) =>
    props.disabled ? "#e9e9e9" : "var(--wiht, #fff)"};

  ${({$isSelected}) => $isSelected && `
    background: rgb(0, 149, 192);
    color: var(--txt-light-blue, #f4ffff);
    border: 1px solid transparent;

  &:hover {
      background: rgb(0, 149, 192);
      color: var(--txt-light-blue, #f4ffff);
      border: 1px solid transparent;
    }
  `}

  &:hover:not(:disabled) {
    ${({$isSelected}) => !$isSelected && `
    background: var(
      --hover,
      linear-gradient(254deg, #0095c0 17.54%, rgba(3, 182, 42, 0.6) 90.58%)
    );
    color: var(--txt-light-blue, #f4ffff);
    border: 1px solid transparent;
    `}
  }

  &:active:not(:disabled) {
    background: var(--green, #03b62a);
    color: var(--txt-light-blue, #f4ffff);
  }
  &:disabled {
    cursor: not-allowed;
  }
`;
