import React, { useEffect, useState } from "react";
import PartnerBasePage from "../components/PartnerBasePage/PartnerBasePage/PartnerBasePage";
import styled from "styled-components";
import TitleHeader from "../components/TitleHeader/TitleHeader";
import { ButtonWrapper } from "../components/DelegateSearchPage/style";

import ReturnButton from "../components/ReturnButton/ReturnButton";
import AgentFilter from "../components/AgentListPage/AgentFilter/AgentFilter";
import AgentList from "../components/AgentListPage/AgentList/AgentList";
import { useDispatch } from "react-redux";
import { getAllAgentsAsync } from "../store/slices/userSlice";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../store/hooks";
import { RootState } from "../store/store";
import { routes } from "../routes/routes";
import { AgentDetails, allAgentsResponse } from "../utils/types";
import AgentsSearch from "../components/AgentListPage/AgentsSearch/AgentsSearch";
import { allAgents } from "../utils/constants";

export const Container = styled.div`
  margin-right: 30px;
`;

export const MainContainer = styled.div`
  display: flex;
`;

export const ListContainer = styled.div`
  margin-right: 30px;
  width: 100%;
`;
export const TittleHeaderWrapper = styled.div``;

const Agents: React.FC = () => {
  const [searchValue, setSearchValue] = useState("");

  // const allAgents: allAgentsResponse = useAppSelector(
  // (state) => state.user.allAgents! || []
  // );
  const user = useAppSelector((state: RootState) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [filterValues, setFilterValues] = useState({
    page: 0,
    size: 12,
  } as AgentDetails);

  useEffect(() => {
    if (!user.userAccount) {
      navigate(routes.HOME);
    }
  }, [user, navigate]);

  useEffect(() => {
    const delay = 3000;
    const timeoutId = setTimeout(() => {
      // dispatch(getAllAgentsAsync({}) as any);
    }, delay);
    return () => clearTimeout(timeoutId);
  }, [dispatch]);

  const handleApplyFilter = () => {
    // dispatch(getAllAgentsAsync(filterValues) as any)
    //   .then((result: any) => {
    //     // console.log("Result:", result.payload);
    //   })
    //   .catch((error: any) => {
    //     console.error("error", error);
    //   });
  };

  return (
    <MainContainer>
      <Container>
        <ButtonWrapper>
          <ReturnButton />
          <TittleHeaderWrapper>
            <TitleHeader text={"Agent's"} />
          </TittleHeaderWrapper>
        </ButtonWrapper>
        <AgentFilter
          filterValues={filterValues}
          setFilterValues={setFilterValues}
          handleApplyFilter={handleApplyFilter}
          allAgents={allAgents.content || []}
        />
      </Container>
      <ListContainer>
        <AgentsSearch
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          filterValues={filterValues}
        />
        <AgentList
          allAgents={allAgents || []}
          filterValues={filterValues}
          setFilterValues={setFilterValues}
          handleApplyFilter={handleApplyFilter}
        />
      </ListContainer>
    </MainContainer>
  );
};

export default Agents;
