import styled from "styled-components";

export const ButtonPopularRequest = styled.button`
  padding: 14px 20px 16px 20px;
  position: static;
  font-size: 17px;
  line-height: 23.8px;
  color: var(--txt-dark, #001731);
  border-radius: 16px;
  border: 1px solid var(--battn-border, #004c62);
  background: var(--wiht, #fff);
  white-space: nowrap;
  transition: background 1.5s ease, color 0.3s ease;
  width: 100%;
  max-width: 190px;
  text-overflow: ellipsis;
  overflow: hidden;
  


  &:first-letter {
    text-transform: uppercase;
  }

  &:hover {
    background: linear-gradient(225.00deg, rgb(0, 149, 192) 17.746%,rgba(3, 182, 42, 0.6) 90.792%); 
    border: 1px solid transparent;
    color: var(--txt-light-blue, #f4ffff);
    transition: background 1.5s ease, color 0.3s ease;
  }

  &:active {
    background: var(--button, #03B62A);
    color: var(--txt-light-blue, #f4ffff)
  }
`;
