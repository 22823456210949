import {
  ConnectionData,
  FavoritesActionType,
  UpdateToken,
  CompanyRequst,
  updateAvatar,
  UserAccount,
  updateUserAvatar,
  UserInformation,
  CompanyData,
  AgentDetails,
  Item,
  OrderCreate,
  Order,
  OrderForCreate,
  AddProduct,
  OrderStatus,
  DatesDashboard,
  Message,
  MessageToProvider,
} from "./../../utils/types";
import { v4 as uuidv4 } from "uuid";
import { get, getDatabase, ref, set, push } from "firebase/database";
import { createAction } from "@reduxjs/toolkit";
import {
  AddAgentToCustomerFavorite,
  AddAgentToProvideravorite,
  addProviderImages,
  DelegateSearchType,
  deleteAgentFromCustomerFavorite,
  deleteAgentFromProviderFavorite,
  PersonalRegister,
  ProviderDetails,
  updateAgentAvatar,
  updateAgentDataAbout,
  updateAgentDataRequest,
  updateCustomerAvatar,
  updateCustomerDataAbout,
  updateCustomerDataRequest,
  updateProviderData,
  updateProviderImages,
  UpdateProviderProduct,
  uploadFiles,
  UserConfirm,
  UserLogin,
  UserRegister,
  UserResendCode,
} from "../../utils/types";
import { base_url } from "../../utils/constants";
import { error } from "console";
import { db } from "../../utils/firebase";
import { customFetch } from "./CustomFetch/CustomFetch";

const createRequestBody = (credentials: PersonalRegister) => {
  if (credentials.userType === "agentCompany") {
    return {
      id: credentials.id,
      firstName: credentials.firstName,
      lastName: credentials.lastName,
      companyName: credentials.companyName,
      registrationInfo: {
        country: credentials.country,
        city: credentials.city,
      },
    };
  } else if (credentials.userType === "providerCompany") {
    return {
      id: credentials.id,
      firstName: credentials.firstName,
      lastName: credentials.lastName,
      companyName: credentials.companyName,
      registrationInfo: {
        country: credentials?.country,
        city: credentials?.city,
      },
    };
  } else if (credentials.userType === "customerCompany") {
    return {
      id: credentials.id,
      firstName: credentials.firstName,
      lastName: credentials.lastName,
      companyName: credentials.companyName,
      registrationInfo: {
        country: credentials?.country,
        city: credentials?.city,
      },
    };
  }
};

const authService = {
  async login(credentials: UserLogin) {
    try {
      const response: Response = await fetch(`${base_url}/user/login`, {
        method: "post",
        headers: {
          "Content-type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(credentials),
      });

      if (response.ok) {
        const data = await response.json();
        return { data };
      } else {
        throw new Error(response.status.toString());
      }
    } catch (error: string | any) {
      throw { message: error.message };
    }
  },
  async getNewToken(credentials: UpdateToken) {
    try {
      const response = await fetch(`${base_url}/user/updateToken`, {
        method: "put",
        headers: {
          Refresh: `Refresh ${credentials.refreshToken}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        return { data };
      } else {
        throw new Error(response.status.toString());
      }
    } catch (error: string | any) {
      throw { message: error.message };
    }
  },

  async resendCode(credentials: UserResendCode) {
    try {
      const response = await fetch(
        `${base_url}/user/confirm/${credentials.id}`,
        {
          method: "post",
          headers: {
            "Content-type": "application/json",
          },
          credentials: "include",
        }
      );

      if (response.ok) {
        const data = await response.json();
        return { data };
      } else {
        throw new Error(response.status.toString());
      }
    } catch (error: string | any) {
      throw { message: error.message };
    }
  },
  async register(credentials: UserRegister) {
    try {
      const response = await fetch(`${base_url}/user/register`, {
        method: "post",
        body: JSON.stringify(credentials),
        headers: {
          "Content-type": "application/json",
        },
        credentials: "include",
      });

      if (response.ok) {
        const data = await response.json();
        return { data };
      } else {
        throw new Error(response.status.toString());
      }
    } catch (error: string | any) {
      throw { message: error.message };
    }
  },
  async confirm(credentials: UserConfirm) {
    try {
      const response = await fetch(
        `${base_url}/user/confirm/${credentials.id}/${credentials.code}`,
        {
          method: "post",
          body: JSON.stringify(credentials),
          headers: {
            "Content-type": "application/json",
          },
          credentials: "include",
        }
      );

      if (response.ok) {
        const data = await response.json();
        return { data };
      } else {
        throw new Error(response.status.toString());
      }
    } catch (error: string | any) {
      throw { message: error.message };
    }
  },
  async personalRegister(credentials: PersonalRegister) {
    const requestBody = createRequestBody(credentials);
    try {
      const response = await fetch(
        `${base_url}/${credentials.userType}/register`,
        {
          method: "post",
          body: JSON.stringify(requestBody),
          headers: {
            "Content-type": "application/json",
          },
          credentials: "include",
        }
      );

      if (response.ok) {
        const data = await response.json();
        return { data };
      } else {
        throw new Error(response.status.toString());
      }
    } catch (error: string | any) {
      throw { message: error.message };
    }
  },
  async updateCustomer(credentials: updateCustomerDataRequest) {
    try {
      const response = await fetch(`${base_url}/customer/user`, {
        method: "put",
        body: JSON.stringify(credentials),
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      });
      if (response.ok) {
        const data = await response.json();
        return { data };
      } else {
        throw new Error(response.status.toString());
      }
    } catch (error: string | any) {
      throw { message: error.message };
    }
  },
  async updateProvider(credentials: updateProviderData) {
    try {
      const response = await fetch(`${base_url}/provider`, {
        method: "PUT",
        body: JSON.stringify(credentials),
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      });
      if (response.ok) {
        const data = await response.json();
        return { data };
      } else {
        throw new Error(response.status.toString());
      }
    } catch (error: string | any) {
      throw { message: error.message };
    }
  },
  async updateAgent(credentials: updateAgentDataRequest) {
    try {
      const response = await fetch(`${base_url}/agent/user`, {
        method: "put",
        body: JSON.stringify(credentials),
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      });
      if (response.ok) {
        const data = await response.json();
        return { data };
      } else {
        throw new Error(response.status.toString());
      }
    } catch (error: string | any) {
      throw { message: error.message };
    }
  },
  async updateCustomerAbout(credentials: updateCustomerDataAbout) {
    try {
      const response = await fetch(`${base_url}/customer/user`, {
        method: "put",
        body: JSON.stringify(credentials),
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      });
      if (response.ok) {
        const data = await response.json();
        return { data };
      } else {
        throw new Error(response.status.toString());
      }
    } catch (error: string | any) {
      throw { message: error.message };
    }
  },
  async updateAgentAbout(credentials: updateAgentDataAbout) {
    try {
      const response = await fetch(`${base_url}/agent/user`, {
        method: "put",
        body: JSON.stringify(credentials),
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      });
      if (response.ok) {
        const data = await response.json();
        return { data };
      } else {
        throw new Error(response.status.toString());
      }
    } catch (error: string | any) {
      throw { message: error.message };
    }
  },
  async updateCustomerAvatar(credentials: updateCustomerAvatar) {
    try {
      const response = await fetch(`${base_url}/user/avatar`, {
        method: "put",
        body: JSON.stringify(credentials),
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      });
      if (response.ok) {
        return response.text();
      } else {
        throw new Error(response.status.toString());
      }
    } catch (error: string | any) {
      throw { message: error.message };
    }
  },
  async updateUserAvatar(credentials: updateUserAvatar) {
    try {
      const response = await fetch(`${base_url}/user/avatar`, {
        method: "put",
        body: JSON.stringify(credentials),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${credentials.token}`,
        },
        credentials: "include",
      });
      if (response.ok) {
        return response.text();
      } else {
        throw new Error(response.status.toString());
      }
    } catch (error: string | any) {
      throw { message: error.message };
    }
  },

  async updateAgentAvatar(credentials: updateAgentAvatar) {
    try {
      const response = await fetch(`${base_url}/agent/user/avatar`, {
        method: "put",
        body: JSON.stringify(credentials),
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      });
      if (response.ok) {
        return response.text();
      } else {
        throw new Error(response.status.toString());
      }
    } catch (error: string | any) {
      throw { message: error.message };
    }
  },
  async updateAvatar(credentials: updateAvatar) {
    try {
      const response = await fetch(
        `${base_url}/${credentials.companyType}/user/avatar`,
        {
          method: "put",
          body: JSON.stringify(credentials),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${credentials.token}`,
          },
          credentials: "include",
        }
      );
      if (response.ok) {
        return response.text();
      } else {
        throw new Error(response.status.toString());
      }
    } catch (error: string | any) {
      throw { message: error.message };
    }
  },
  async removeProduct(credentials: { id: number }) {
    try {
      const response = await fetch(
        `${base_url}/provider/item/${credentials.id}`,
        {
          method: "delete",
          credentials: "include",
        }
      );
      if (response.ok) {
        const data = await response.json();
        return { data };
      } else {
        throw new Error(response.status.toString());
      }
    } catch (error: string | any) {
      throw { message: error.message };
    }
  },
  async removePhoto(credentials: { id: number }) {
    try {
      const response = await fetch(
        `${base_url}/provider/photos/${credentials.id}`,
        {
          method: "delete",
          credentials: "include",
        }
      );
      if (response.ok) {
        const data = await response.json();
        return { data };
      } else {
        throw new Error(response.status.toString());
      }
    } catch (error: string | any) {
      throw { message: error.message };
    }
  },
  async uploadFiles(credentials: uploadFiles) {
    try {
      const formData = new FormData();
      credentials.files.forEach((file, index) => {
        formData.append(`files`, file);
      });
      const response = await fetch(`${base_url}/files/upload`, {
        method: "post",
        body: formData,
        credentials: "include",
      });

      if (response.ok) {
        const data = await response.json();
        return { data };
      } else {
        throw new Error(response.status.toString());
      }
    } catch (error: string | any) {
      throw { message: error.message };
    }
  },
  async updateProduct(credentials: UpdateProviderProduct) {
    try {
      const response = await fetch(
        `${base_url}/provider/item/${credentials.id}`,
        {
          method: "put",
          body: JSON.stringify(credentials),
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        }
      );
      if (response.ok) {
        const data = await response.json();
        return { data };
      } else {
        throw new Error(response.status.toString());
      }
    } catch (error: string | any) {
      throw { message: error.message };
    }
  },

  async deleteDelegatedBrief(credentials: { id: number; token: string }) {
    try {
      const response = await fetch(
        `${base_url}/customer/delegated/delete/${credentials.id}`,
        {
          method: "delete",
          headers: {
            Authorization: `Bearer ${credentials.token}`,
          },
        }
      );
      if (response.ok) {
        const data = await response.text();
        return { data };
      } else {
        throw new Error(response.status.toString());
      }
    } catch (error: string | any) {
      throw { message: error.message };
    }
  },
  async getDelegatedBrif(credentials: { id: number; token: string }) {
    try {
      const response: Response = await fetch(
        `${base_url}/customer/delegated/${credentials.id}`,
        {
          method: "get",
          headers: {
            Authorization: `Bearer ${credentials.token}`,
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        return { data };
      } else {
        throw new Error(response.status.toString());
      }
    } catch (error: string | any) {
      throw { message: error.message };
    }
  },
  async downloadDelegatedBrif(credentials: {
    id: number;
    token: string;
    nameBrief: string;
  }) {
    try {
      const response = await fetch(
        `${base_url}/pdf/generate/${credentials.id}`,
        {
          method: "get",
          headers: {
            Authorization: `Bearer ${credentials.token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(response.status.toString());
      }

      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = credentials.nameBrief + ".pdf";
      anchor.setAttribute("style", "display: none");
      document.body.append(anchor);
      anchor.click();
      anchor.remove();
      return { data: blob };
    } catch (error: string | any) {
      throw { message: error.message };
    }
  },
  async sendLetterToProvider(credentials: MessageToProvider) {
    try {
      const response: Response = await fetch(`${base_url}/brief/send`, {
        method: "post",
        body: JSON.stringify(credentials),
        headers: {
          Authorization: `Bearer ${credentials.token}`,
          "Content-Type": "application/json",
        },
        credentials: "include",
      });
      if (response.ok) {
        const data = await response.json();
        return { data };
      } else {
        throw new Error(response.status.toString());
      }
    } catch (error: string | any) {
      throw { message: error.message };
    }
  },

  async removeAttachmentFromCompany(credentials: {
    id: number;
    token: string;
    companyType: string;
  }) {
    try {
      const response = await fetch(
        `${base_url}/${credentials.companyType}/attachments/${credentials.id}`,
        {
          method: "delete",
          headers: {
            Authorization: `Bearer ${credentials.token}`,
          },
        }
      );
      if (response.ok) {
        const responseText = await response.text();
        return {
          id: credentials.id,
          message: responseText,
        };
      } else {
        throw new Error(response.status.toString());
      }
    } catch (error: string | any) {
      throw { message: error.message };
    }
  },

  async addProduct(credentials: UpdateProviderProduct) {
    try {
      const response = await fetch(`${base_url}/provider/items`, {
        method: "put",
        body: JSON.stringify(credentials),
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      });

      if (response.ok) {
        const data = await response.json();
        return { data };
      } else {
        throw new Error(response.status.toString());
      }
    } catch (error: string | any) {
      throw { message: error.message };
    }
  },

  // not used
  async getFavoriteAgents() {
    try {
      const response = await fetch(`${base_url}/favorite/agents`, {
        method: "get",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      });
      if (response.ok) {
        const data = await response.json();
        return { data };
      } else {
        throw new Error(response.status.toString());
      }
    } catch (error: string | any) {
      throw { message: error.message };
    }
  },

  // used but without token
  async getAllAgents(credentials: AgentDetails) {
    try {
      const queryParams = new URLSearchParams();
      Object.entries(credentials).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          value.forEach((item) => {
            queryParams.append(key, item.toString());
          });
        } else if (typeof value === "number") {
          queryParams.append(key, value.toString());
        } else {
          queryParams.append(key, value);
        }
      });
      const response = await fetch(`${base_url}/agents?${queryParams}`, {
        method: "get",
        credentials: "include",
      });

      if (response.ok) {
        const data = await response.json();
        return { data };
      } else {
        throw new Error(response.status.toString());
      }
    } catch (error: string | any) {
      throw { message: error.message };
    }
  },

  async confirmOrderAcceptance(id: number) {
    try {
      const response = await fetch(
        `${base_url}/order/confirm/acceptance/${id}`,
        {
          method: "put",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        }
      );
      if (response.ok) {
        const data = await response.json();
        return { data };
      } else {
        throw new Error(response.status.toString());
      }
    } catch (error: string | any) {
      throw { message: error.message };
    }
  },

  async createOrder(order: OrderForCreate, token: string) {
    try {
      const response = await fetch(`${base_url}/order/create`, {
        method: "post",
        body: JSON.stringify(order),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        credentials: "include",
      });
      if (response.ok) {
        const data = await response.json();
        return { data };
      } else {
        throw new Error(response.status.toString());
      }
    } catch (error: string | any) {
      throw { message: error.message };
    }
  },

  async updateOrderStatus(credentials: {
    processingStatus: OrderStatus;
    orderId: number;
    token: string;
  }) {
    try {
      const response = await fetch(
        `${base_url}/order/processing/${credentials.orderId}`,
        {
          method: "put",
          body: JSON.stringify({
            processingStatus: credentials.processingStatus,
          }),
          headers: {
            Authorization: `Bearer ${credentials.token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        return { data };
      } else {
        throw new Error(response.status.toString());
      }
    } catch (error: string | any) {
      throw { message: error.message };
    }
  },

  async logOut() {
    try {
      const response = await fetch(`${base_url}/logout`, {
        method: "post",
        credentials: "include",
      });
      if (response.ok) {
        localStorage.clear();
      } else {
        throw new Error(response.status.toString());
      }
    } catch (error) {
      console.error("Logout failed", error);
    }
  },
  async createDelegateSearch(credentials: {
    delegateSearch: DelegateSearchType;
    token: string;
  }) {
    try {
      const response: Response = await fetch(
        `${base_url}/customer/delegated/create`,
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${credentials.token}`,
          },
          body: JSON.stringify(credentials.delegateSearch),
          credentials: "include",
        }
      );
      if (response.ok) {
        const data = await response.json();
        return { data };
      } else {
        throw new Error(response.status.toString());
      }
    } catch (error: string | any) {
      throw { message: error.message };
    }
  },
  async sendDelegateSearch(credentials: {
    delegateSearch: DelegateSearchType;
    token: string;
    placeId: string;
  }) {
    try {
      const response: Response = await fetch(
        `${base_url}/customer/delegated/send/${credentials.placeId}`,
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${credentials.token}`,
          },
          body: JSON.stringify(credentials.delegateSearch),
          credentials: "include",
        }
      );
      if (response.ok) {
        const data = await response.json();
        return { data };
      } else {
        throw new Error(response.status.toString());
      }
    } catch (error: string | any) {
      throw { message: error.message };
    }
  },
  async updateBrif(credentials: {
    delegateSearch: DelegateSearchType;
    token: string;
  }) {
    try {
      const response: Response = await fetch(
        `${base_url}/customer/delegated/update`,
        {
          method: "put",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${credentials.token}`,
          },
          body: JSON.stringify(credentials.delegateSearch),
          credentials: "include",
        }
      );
      if (response.ok) {
        const data = await response.json();
        return { data };
      } else {
        throw new Error(response.status.toString());
      }
    } catch (error: string | any) {
      throw { message: error.message };
    }
  },

  // not used
  async addAgentToCustomer(credentials: AddAgentToCustomerFavorite) {
    try {
      const response = await fetch(`${base_url}/customer/favorite/agents`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(credentials),
        credentials: "include",
      });

      if (response.ok) {
        const data = await response.json();
        return { data };
      } else {
        throw new Error(response.status.toString());
      }
    } catch (error: string | any) {
      throw { message: error.message };
    }
  },

  // not used
  async deleteAgentFromCustomer(credentials: deleteAgentFromCustomerFavorite) {
    try {
      const response = await fetch(`${base_url}/customer/favorite/agents`, {
        method: "delete",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(credentials),
        credentials: "include",
      });

      if (response.ok) {
        const data = await response.json();
        return { data };
      } else {
        throw new Error(response.status.toString());
      }
    } catch (error: string | any) {
      throw { message: error.message };
    }
  },

  // not used
  async addAgentToProvider(credentials: AddAgentToProvideravorite) {
    try {
      const response = await fetch(`${base_url}/provider/favorite/agents`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(credentials),
        credentials: "include",
      });

      if (response.ok) {
        const data = await response.json();
        return { data };
      } else {
        throw new Error(response.status.toString());
      }
    } catch (error: string | any) {
      throw { message: error.message };
    }
  },

  // not used
  async deleteAgentFromProvider(credentials: deleteAgentFromProviderFavorite) {
    try {
      const response = await fetch(`${base_url}/provider/favorite/agents`, {
        method: "delete",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(credentials),
        credentials: "include",
      });

      if (response.ok) {
        const data = await response.json();
        return { data };
      } else {
        throw new Error(response.status.toString());
      }
    } catch (error: string | any) {
      throw { message: error.message };
    }
  },

  async changePasswordAgent({ newPassword, token }: { newPassword: string, token: string }) {
    try {
      const response = await fetch(`${base_url}/agent/password`, {
        method: "put",
        body: JSON.stringify({ newPassword }),
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      });
      if (response.ok) {
        return response.text();
      } else {
        throw new Error(response.status.toString());
      }
    } catch (error: string | any) {
      throw { message: error.message };
    }
  },

  async removeAttachmentFromCustomer(credentials: {
    id: number;
    token: string;
  }) {
    try {
      const response = await fetch(
        `${base_url}/user/attachment/${credentials.id}`,
        {
          method: "delete",
          credentials: "include",
          headers: {
            Authorization: `Bearer ${credentials.token}`,
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        return { data };
      } else {
        throw new Error(response.status.toString());
      }
    } catch (error: string | any) {
      throw { message: error.message };
    }
  },
  async removePhotoProduct(credentials: {
    id: number;
    photoId: number;
    token: string;
  }) {
    try {
      const response = await fetch(
        `${base_url}/product/photos/${credentials.id}/${credentials.photoId}`,
        {
          method: "delete",
          credentials: "include",
          headers: {
            Authorization: `Bearer ${credentials.token}`,
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        return { data };
      } else {
        throw new Error(response.status.toString());
      }
    } catch (error: string | any) {
      throw { message: error.message };
    }
  },

  async addPhotoToProvider(credentials: addProviderImages, token: string) {
    try {
      const response = await fetch(`${base_url}/provider/photos`, {
        method: "put",
        body: JSON.stringify(credentials),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        credentials: "include",
      });
      if (response.ok) {
        const data = await response.json();
        return { data };
      } else {
        throw new Error(response.status.toString());
      }
    } catch (error: string | any) {
      throw { message: error.message };
    }
  },

  async removePhotoFromProvider(photoId: number, token: string) {
    try {
      const response = await fetch(`${base_url}/provider/photos/${photoId}`, {
        method: "delete",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        credentials: "include",
      });
      if (response.ok) {
        const data = await response.json();
        return { data };
      } else {
        throw new Error(response.status.toString());
      }
    } catch (error: string | any) {
      throw { message: error.message };
    }
  },

  async updatePhotoFromProvider(credentials: updateProviderImages) {
    try {
      const response = await fetch(
        `${base_url}/provider/photos/${credentials.id}`,
        {
          method: "put",
          body: JSON.stringify(credentials),
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        }
      );
      if (response.ok) {
        const data = await response.json();
        return { data };
      } else {
        throw new Error(response.status.toString());
      }
    } catch (error: string | any) {
      throw { message: error.message };
    }
  },

  async getCompanyById(credentials: { id: number; token: string }) {
    try {
      const response = await fetch(
        `${base_url}/provider/company/${credentials.id}`,
        {
          method: "get",
          credentials: "include",
          headers: {
            Authorization: `Bearer ${credentials.token}`,
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        return { data };
      } else {
        throw new Error(response.status.toString());
      }
    } catch (error: string | any) {
      throw { message: error.message };
    }
  },
  async getOrderById(credentials: { id: number; token: string }) {
    try {
      const response = await fetch(`${base_url}/order/${credentials.id}`, {
        method: "get",
        credentials: "include",
        headers: {
          Authorization: `Bearer ${credentials.token}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        return { data };
      } else {
        throw new Error(response.status.toString());
      }
    } catch (error: string | any) {
      throw { message: error.message };
    }
  },
  async getAgentById(id: number) {
    try {
      const response = await fetch(`${base_url}/agent/user/${id}`, {
        method: "get",
        credentials: "include",
      });
      if (response.ok) {
        const data = await response.json();
        return { data };
      } else {
        throw new Error(response.status.toString());
      }
    } catch (error: string | any) {
      throw { message: error.message };
    }
  },

  async sendMessage(payload: Message) {
    try {
      const message: Message = {
        id: uuidv4(),
        text: payload.text,
        dateCreated: payload.dateCreated,
        files: payload.files,
        ownerId: payload.ownerId,
        orderId: payload.orderId,
        email: payload.email,
        chatId: payload.chatId,
      };
      const messageRef = ref(db, `chats/${payload.chatId}/messages`);
      await push(messageRef, message);
    } catch (error: any) {
      return {
        code: error.code || "unknown_error",
        message: error.message || "Unknown error",
      };
    }
  },

  async fetchCountries() {
    try {
      const response = await fetch(`${base_url}/getCountries`, {
        method: "get",
      });
      if (response.ok) {
        const data = await response.json();
        return data;
      } else {
        throw new Error(response.status.toString());
      }
    } catch (error: string | any) {
      throw { message: error.message };
    }
  },
  async getCityByCountry(country: string) {
    try {
      const response = await fetch(`${base_url}/getCities/${country}`, {
        method: "get",
      });
      if (response.ok) {
        const data = await response.json();
        return data;
      } else {
        throw new Error(response.status.toString());
      }
    } catch (error: string | any) {
      throw { message: error.message };
    }
  },

};













// new requests with custom fetch


export async function createProduct(credentials: AddProduct) {
  return customFetch(`product/create`, {
    method: "POST",
    body: JSON.stringify(credentials),
  });
};

export async function removeProduct(id: number) {
  return customFetch(`product/delete/${id}`, {
    method: "DELETE",
    body: JSON.stringify({ id }),
  });
};

export async function updateProductPublished(credentials: { id: number, published: boolean }) {
  return customFetch(`product/update/${credentials.id}`, {
    method: "PUT",
    body: JSON.stringify(credentials),
  });
};

export async function updateProductNew(credentials: AddProduct) {
  return customFetch(`product/update/${credentials.id}`, {
    method: "PUT",
    body: JSON.stringify(credentials),
  });
};

export async function getAllLanguages() {
  return customFetch(`getAllLanguages`);
};

export async function getAllMarketplaces() {
  return customFetch(`getAllMarketPlaces`);
};

export async function getAllProducts(credentials: { page: number, size: number }) {
  return customFetch(`product/getAll?page=${credentials.page}&size=${credentials.size}`);
};

export async function getProduct() {
  return customFetch(`product/getAll`);
};

export async function updateUserRole(credentials: { userId: number, role: string }) {
  customFetch(`user/${credentials.userId}/role/${credentials.role}`, {
    method: "PUT",
  });
  return { userId: credentials.userId, role: credentials.role };
};

export async function sendInvite(emailUser: string) {
  return customFetch(`user/send/invite/join`, {
    method: "POST",
    body: JSON.stringify({ emailUser }),
  });
};

export async function getAllCompanyUsers(companyId: number) {
  return customFetch(`user/allUsers/${companyId}`);
};

export async function updateGeneralInfo(credentials: CompanyData) {
  return customFetch(`${credentials.companyType}`, {
    method: "PUT",
    body: JSON.stringify(credentials),
  });
};

export async function changePassword(newPassword: string) {
  return customFetch(`user/changePassword`, {
    method: "PUT",
    body: JSON.stringify({ newPassword }),
  });
};

export async function validatePassword(password: string) {
  return customFetch(`user/password`, {
    method: "PUT",
    body: JSON.stringify({ password }),
  });
};

export async function confirmEmail(confirmCode: string) {
  return customFetch(`user/confirm/email/${confirmCode}`, {
    method: "POST",
  });
};

export async function changeEmail(newEmail: string) {
  return customFetch(`user/changeEmail`, {
    method: "PUT",
    body: JSON.stringify({ newEmail }),
  });
};

export async function updateUserInfo(credentials: UserInformation) {
  return customFetch(`user/update`, {
    method: "PUT",
    body: JSON.stringify(credentials),
  });
};

export async function getDialogueById(credentials: { id: number }) {
  return customFetch(`business/${credentials.id}`);
};

export async function changeBusinessRequestsStatus(credentials: { status: string, requestIds: number[] }) {
  return customFetch(`business/status`, {
    method: "PUT",
    body: JSON.stringify(credentials),
  });
};

export async function createBusinessRequests(credentials: ConnectionData) {
  return customFetch(`business/create`, {
    method: "POST",
    body: JSON.stringify(credentials),
  });
};

// export async function getCityByCountry(country: string) {
//   return customFetch(`getCities/${country}`);
// };

export async function getCompanyInfo(credentials: CompanyRequst) {
  return customFetch(`${credentials.companyType}/company`);
};

export async function getAllRequests() {
  return customFetch(`business/get`);
};

export async function getCoordinatesDashboard(credentials: DatesDashboard) {
  return customFetch(`provider/dashboard/customers/geopoints?start=${credentials.startdate}&end=${credentials.enddate}`);
};

export async function getDetailsForMapDashboard(credentials: DatesDashboard) {
  return customFetch(`provider/dashboard/orders/completed/details-location?start=${credentials.startdate}&end=${credentials.enddate}`);
};

export async function getDetailsOrders(credentials: DatesDashboard) {
  return customFetch(`provider/dashboard/orders/details?start=${credentials.startdate}&end=${credentials.enddate}`);
};

export async function getAllRequestsDashboard(credentials: DatesDashboard) {
  return customFetch(`provider/dashboard/requests/count?start=${credentials.startdate}&end=${credentials.enddate}`);
};

export async function getAllDetailsDashboard(credentials: DatesDashboard) {
  return customFetch(`provider/dashboard/orders/completed/details?start=${credentials.startdate}&end=${credentials.enddate}`);
};

export async function getConversionRateDashboard() {
  return customFetch(`provider/dashboard/conversionRate`);
};

export async function getAllCustomersDashboard(credentials: DatesDashboard) {
  return customFetch(`provider/dashboard/customers/count?start=${credentials.startdate}&end=${credentials.enddate}`);
};

export async function getAllProductsDashboard(credentials: DatesDashboard) {
  return customFetch(`provider/dashboard/orders/completed/products?start=${credentials.startdate}&end=${credentials.enddate}&limit=3`);
};

export async function getAllOrdersDashboard(credentials: DatesDashboard) {
  return customFetch(`provider/dashboard/orders/statuses/count?start=${credentials.startdate}&end=${credentials.enddate}`);
};

export async function getAllOrders() {
  return customFetch(`orders`);
};

export async function getAllDelegatedBrief() {
  return customFetch(`customer/delegated/all`);
};

export async function addDeleteFavorite({ entityId, method, typeFavorite }: FavoritesActionType) {
  return customFetch(`favorite`, {
    method,
    body: JSON.stringify({ typeFavorite, entityId }),
  });
};

export async function searchProviders(credentials: ProviderDetails, searchValue: string[]) {
  try {
    const queryParams = new URLSearchParams();

    Object.entries(credentials).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((item) => {
          queryParams.append(key, item.toString());
        });
      } else if (typeof value === "number") {
        queryParams.append(key, value.toString());
      } else {
        queryParams.append(key, value);
      }
    });

    return customFetch(`search/providers?${queryParams}`, {
      method: "PUT",
      body: JSON.stringify({ texts: searchValue }),
    });

  } catch (error: string | any) {
    throw new Error(`Failed to fetch: ${error}`);
  }
};


export async function getFavoriteProviders() {
  return customFetch(`favorite/providers`);
};

export async function getAllProviders(credentials: ProviderDetails) {
  try {
    const queryParams = new URLSearchParams();

    Object.entries(credentials).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((item) => {
          queryParams.append(key, item.toString());
        });
      } else if (typeof value === "number") {
        queryParams.append(key, value.toString());
      } else {
        queryParams.append(key, value);
      }
    });
    return customFetch(`providers?${queryParams}`);
  } catch (error: string | any) {
    throw new Error(`Failed to fetch: ${error}`);
  }
};







export const logout = createAction("auth/logout");

export default authService;
