import React, { useEffect, useState } from "react";
import {
  ButtonWrapper,
  Product,
  ProductsWrapper,
  Image,
  Price,
  Title,
  ChevronWrapper,
  PublishWrapper,
  ButtonsWrapper,
  ButtonRed,
  NotPublishWrapper,
  PaginationBlock,
  ChevronRightWrapper,
  ChevronUpWrapper,
  PageActiveButton,
  PageActiveItem,
  PageBlock,
  PageButton,
  PageWrapper,
  TextPage,
  PaginationContainer,
} from "./styles";
import Button from "../../Button/Button";
import ChevronDownIcon from "../../../assets/icons/ChevronDownIcon";
import ChevronUpIcon from "../../../assets/icons/ChevronUpIcon";
import ButtonWhite from "../../ButtonWhite/ButtonWhite";
import { useAppSelector } from "../../../store/hooks";
import { RootState } from "../../../store/store";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../routes/routes";
import {
  removeProductAsync,
  updateProductPublishedAsync,
  saveProducts,
  getAllProductsAsync,
} from "../../../store/slices/userSlice";
import { Item, UserAccount, allProductsResponse } from "../../../utils/types";
import { useDispatch } from "react-redux";
import ArrowLeftIcon from "../../../assets/icons/ArrowLeftIccon";
import Pagination from "../../Pagination/Pagination";
import ArrowRightIcon from "../../../assets/icons/ArrowRightIcon";
import ChevronRightIcon from "../../../assets/icons/ChevronRightIcon";
const DefaultAvatar = require("../../../assets/img/default-avatar.png");

const ProductsList: React.FC = () => {
  const allProduct = useAppSelector(
    (state: RootState) => state.user.company?.products as allProductsResponse
  );
  const [openDropdown, setOpenDropdown] = useState<{ [id: number]: boolean }>(
    {}
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [products, setProducts] = useState<Item[]>(allProduct?.content || []);
  const [showProductsPerPageOptions, setShowProductsPerPageOptions] =
    useState(false);
  const status = useAppSelector((state) => state.user.status);
  const userAccount: UserAccount = useAppSelector(
    (store) => store.user.userAccount!
  );
  const dispatch = useDispatch();

  const itemsPerPageOptions = [48, 24, 12, 8, 4];
  const [selectedProductsPerPage, setSelectedProductsPerPage] = useState(12);
  const [filterValues, setFilterValues] = useState({
    page: 0,
    size: 12,
  });

  const totalPages = allProduct?.totalPages;

  const toggleDropdown = (id: number) => {
    setOpenDropdown((prevStates) => ({
      ...prevStates,
      [id]: !prevStates[id],
    }));
  };

  const navigate = useNavigate();
  const handleEditProductClick = (productId: number) => {
    navigate(routes.EDITPRODUCT.replace(":id", productId.toString()), {
      state: { productId: productId },
    });
  };

  const handleAddProductClick = () => {
    navigate(routes.ADDPRODUCT);
  };

  const deleteProduct = (productId: number) => {
    dispatch(
      removeProductAsync(productId) as any
    );
    status === "succeeded" &&
      setProducts(products.filter((product) => product.id !== productId));
  };

  const handlePublishedClick = (productId: number, published: boolean) => {
    if (!allProduct) {
      return;
    }
    dispatch(
      updateProductPublishedAsync({
        published: !published,
        id: productId,
      }) as any
    ).then((action: any) => {
      const updatedProduct = action.payload;
      status === "succeeded" &&
        setProducts((prevProducts) =>
          prevProducts.map((product) =>
            product.id === updatedProduct.id ? updatedProduct : product
          )
        );
    });
  };

  const handleProductsPerPageChange = (value: number) => {
    setFilterValues((prevValues) => ({ ...prevValues, page: 0, size: value }));
    setSelectedProductsPerPage(value);
    setShowProductsPerPageOptions(false);
  };

  const handleProductsPerPageOptionToggle = () => {
    setShowProductsPerPageOptions(!showProductsPerPageOptions);
  };

  const handlePageChange = (newPage: number) => {
    setFilterValues((prevValues) => ({ ...prevValues, page: newPage - 1 }));
  };

  const goToPreviousPage = () => {
    if (!allProduct.first) {
      setFilterValues((prevValues) => ({
        ...prevValues,
        page: prevValues.page! - 1,
      }));
    }
  };

  const goToNextPage = () => {
    if (!allProduct.last) {
      setFilterValues((prevValues) => ({
        ...prevValues,
        page: prevValues.page! + 1,
      }));
    }
  };

  const handleApplyFilter = () => {
    dispatch(
      getAllProductsAsync({
        page: filterValues.page,
        size: filterValues.size,
      }) as any
    ).catch((error: any) => {
      console.error("error", error);
    });
  };

  useEffect(() => {
    handleApplyFilter();
  }, [filterValues.page, filterValues.size]);

  useEffect(() => {
    setProducts(allProduct?.content || []);
  }, [allProduct]);

  useEffect(() => {
    setCurrentPage(filterValues.page! + 1);
  }, [filterValues.page]);

  return (
    <>
      <ButtonWrapper>
        <Button text="Add product" onClick={handleAddProductClick} />
      </ButtonWrapper>
      <ProductsWrapper>
        {products.map(({ id, images, name, price, published }) => (
          <Product key={id}>
            <NotPublishWrapper $isPublish={published}>
              <Image
                src={
                  (images && images.length > 0
                    ? images[0]?.link
                    : DefaultAvatar) as string
                }
                alt={name}
              />
              <Title>{name}</Title>
              <Price>{price}$</Price>
            </NotPublishWrapper>
            <PublishWrapper>
              <Button
                text={published ? "Published" : "Not published"}
                isActive={published}
              />
              <ChevronWrapper onClick={() => toggleDropdown(id)}>
                {openDropdown[id] ? <ChevronUpIcon /> : <ChevronDownIcon />}
              </ChevronWrapper>
            </PublishWrapper>
            {openDropdown[id] && (
              <ButtonsWrapper>
                <Button
                  text={published ? "Not Published" : "Published"}
                  isActive={!published}
                  onClick={() => handlePublishedClick(id, published)}
                />
                <ButtonWhite
                  text="Edit"
                  onClick={() => handleEditProductClick(id)}
                />
                <ButtonRed onClick={() => deleteProduct(id)}>Delete</ButtonRed>
              </ButtonsWrapper>
            )}
          </Product>
        ))}
      </ProductsWrapper>
      {products.length > 0 && (
        <PaginationContainer>
          <PaginationBlock>
            <ArrowLeftIcon onClick={goToPreviousPage} />
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
            <ArrowRightIcon onClick={goToNextPage} />
          </PaginationBlock>

          <PageWrapper>
            <PageBlock>
              <TextPage>Products per page:</TextPage>
              {showProductsPerPageOptions ? (
                <>
                  <PageActiveButton>
                    {itemsPerPageOptions.map((option) => (
                      <PageActiveItem
                        key={option}
                        onClick={() => handleProductsPerPageChange(option)}
                      >
                        {option}
                      </PageActiveItem>
                    ))}
                    <ChevronUpWrapper>
                      <ChevronUpIcon
                        onClick={handleProductsPerPageOptionToggle}
                      />
                    </ChevronUpWrapper>
                  </PageActiveButton>
                </>
              ) : (
                <>
                  <PageButton onClick={handleProductsPerPageOptionToggle}>
                    {selectedProductsPerPage}
                    <ChevronRightWrapper>
                      <ChevronRightIcon
                        onClick={handleProductsPerPageOptionToggle}
                      />
                    </ChevronRightWrapper>
                  </PageButton>
                </>
              )}
            </PageBlock>
          </PageWrapper>
        </PaginationContainer>
      )}
    </>
  );
};

export default ProductsList;
