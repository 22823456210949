import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Coordinates, FactoryInternetSearch, FetchUrl, InternetSearchResponse } from "../../utils/types";
import { useAppSelector } from "../hooks";
import { getGeocodeByCountryCity } from "../../api/internetSearch";

interface InternetSearch {
  keywordsSearch: string;
  location: Coordinates;
  pending: boolean;
  internetSearchResponse: InternetSearchResponse | null;
  token: string;
  topKeywords: string[]; 
  favorites: InternetSearchResponse | null;
  allFavorites: FactoryInternetSearch[];
  fetchUrl: FetchUrl;
  pendingGeocode: boolean;
  pendingFavorites: boolean;
}

const initialState: InternetSearch = {
  keywordsSearch: "",
  location: {
    latitude: 0,
    longitude: 0,
  },
  pending: false,
  internetSearchResponse: null,
  token: "",
  topKeywords: [], 
  favorites: null,
  allFavorites: [],
  fetchUrl: { headers: [], body: "", statusCode: 0 },
  pendingGeocode: false,
  pendingFavorites: false,
};

const internetSearchSlice = createSlice({
  name: "internetSearch",
  initialState,
  reducers: {
    setPendingInternetSearch(state, action) {
      state.pending = true;
      state.token = action.payload;
    },
    setLocation(state, action) {
      const { latitude, longitude } = action.payload;
      state.location = { latitude, longitude };
    },
    setKeywordsSearch(state, action) {
      state.keywordsSearch = action.payload;
    },
    setInternetSearchFactories(state, action) {
      state.internetSearchResponse = action.payload;
      state.pending = false;
    },
    setTopKeywords(state, action) {
      state.topKeywords = action.payload;
    },
    setFavoritesPending(state, action: PayloadAction<boolean>) {
      state.pendingFavorites = action.payload;
    },
    setFavorites(state, action) {
      state.favorites = action.payload;
      state.pendingFavorites = false;
    },
    setAllFavorites(state, action) {
      state.allFavorites = action.payload;
    },
    setFetchUrl(state, action) {
      state.fetchUrl = action.payload;
    },
    setPendingGeocodeSearch(state, action: PayloadAction<boolean>) {
      state.pendingGeocode = action.payload; 
    },
  },
});

export const {
  setPendingInternetSearch,
  setLocation,
  setKeywordsSearch,
  setInternetSearchFactories,
  setTopKeywords,
  setFavorites,
  setFetchUrl,
  setPendingGeocodeSearch,
  setFavoritesPending,
  setAllFavorites,
} = internetSearchSlice.actions;
export default internetSearchSlice.reducer;
