import React, { ChangeEvent } from "react";
import CrossIcon from "../../../assets/icons/CrossIcon";
import {
  UploadFile,
  UploadButton,
  FileTitle,
  VectorWrapper,
  PhotoWrapper,
  PhotoId,
  CloseWrapper,
  Span,
  Input,
} from "./style";
import VectorIcon from "../../../assets/icons/VectorIcon";

interface UploadContactProps {
  selectedFiles: File[];
  onFilesChange: (files: File[]) => void;
}

const UploadContact: React.FC<UploadContactProps> = ({
  selectedFiles,
  onFilesChange,
}) => {
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const files: FileList = event.target.files;
      const newFiles = [...selectedFiles, ...Array.from(files)];
      onFilesChange(newFiles);
    }
  };

  const handleRemoveFile = (index: number) => {
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);
    onFilesChange(updatedFiles);
  };

  return (
    <>
      <UploadFile>
        <UploadButton
          onClick={() => document.getElementById("ContactFile")?.click()}
        >
          Upload
        </UploadButton>
        <Span>a photo or file</Span>
        <Input
          type="file"
          id="ContactFile"
          onChange={handleFileChange}
          multiple
        />
      </UploadFile>
      {selectedFiles.length > 0 && (
        <FileTitle>
          <PhotoWrapper>
            {selectedFiles.map((file, index) => (
              <PhotoId key={index}>
                <VectorWrapper>
                  <VectorIcon />
                </VectorWrapper>
                {file.name}
                <CloseWrapper onClick={() => handleRemoveFile(index)}>
                  <CrossIcon />
                </CloseWrapper>
              </PhotoId>
            ))}
          </PhotoWrapper>
        </FileTitle>
      )}
    </>
  );
};

export default UploadContact;
