import styled from "styled-components";

export const StyledMainTemplate = styled.div`
  display: flex;

  max-width: 1920px;
  width: 100%;
  padding: 0 30px;
  margin: 0 auto;
`;

export const Wrapper = styled.div`
  max-width: 1780px;
  width: 100%;
  padding: 40px 0 30px 30px;
`;

export const FooterWrapper = styled.div`
  display: flex;
  max-width: 1920px;
  width: 100%;
  padding: 0 30px;
  margin: 0 auto;
  justify-content: flex-end;
`;
