import React from "react";

const VectorIcon: React.FC = () => {
  return (
    <svg
      width="12"
      height="8"
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="Vector 1"
        d="M1 3L5 7L11 1"
        stroke="#F4FFFF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default VectorIcon;
