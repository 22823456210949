import styled from "styled-components";

interface InputWrapperProps {
  $hasError?: boolean;
}

export const Container = styled.div`
  position: relative;

  max-width: 473px;
  padding: 30px 40px 40px;
  margin: 0 auto 30px;

  border-radius: 16px;
  border: 1px solid var(--dark, #001731);
  background: var(--wiht, #fff);
`;

export const CloseWrapper = styled.div`
  position: absolute;
  right: 19.5px;

  cursor: pointer;
`;

export const RadioWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-bottom: 5px;
`;

export const TitleWrapper = styled.div`
  margin-bottom: 20px;
`;

export const Label = styled.label`
  font-size: 17px;
  line-height: 140%;
  color: var(--txt-dark, #001731);
  cursor: pointer;

  input[type="radio"] {
    opacity: 0;
    position: absolute;

    &:checked + span {
      background-color: var(--batton, #0095c0);
    }

    &:checked + span:after {
      display: block;
    }
  }
`;

export const Checkmark = styled.div`
  position: relative;
  display: inline-block;
  height: 16px;
  width: 16px;
  margin-right: 8px;

  border: 1px solid var(--dark, #001731);
  border-radius: 20px;

  &:after {
    content: "";
    position: absolute;
    display: none;
  }

  input:checked + & {
    background-color: var(--batton, #0095c0);
    border: 1px solid var(--batton, #0095c0);
  }

  input:checked + &:after {
    display: block;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #fdfdfd;
  }
`;

export const InputWrapper = styled.div<InputWrapperProps>`
  margin-bottom: 20px;
  position: relative;

  input {
    max-width: 373px;
    width: 100%;
    padding: 0 10px 8px;

    font-size: 17px;
    line-height: 140%;

    border-bottom: 1px solid var(--border, #bdbdbd);
    color: ${(props) =>
      props.$hasError ? "var(--txt-red, #f00)" : "var(--txt-dark, #001731)"};
    &::placeholder {
      font-size: 17px;
      line-height: 140%;
      color: var(--txt-grey-light, #717880);
    }
  }
`;

export const TogglePasswordButton = styled.button`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  background: none;
  border: none;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  max-width: 390px;
  margin-bottom: 20px;
`;

export const ForgotWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
  a {
    font-size: 14px;
    line-height: 140%;
    text-decoration-line: underline;
    color: var(--txt-blue, #0095c0);
  }
`;

export const TextAuthWrapper = styled.div`
  margin: 20px 0 14px;
`;

export const SignUpWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SignUpText = styled.p`
  text-align: center;
  font-size: 17px;
  line-height: 140%;

  color: var(--txt-light-blue-dont-active, #a0b7d1);
`;

export const ButtonSignUp = styled.button`
  width: 100%;
  padding: 14px 30px 16px 30px;

  font-size: 17px;
  line-height: 140%;

  border-radius: 16px;
  color: var(--txt-dark, #001731);
  border: 1px solid var(--battn-border, #004c62);
  background: var(--wiht, #fff);
  &:hover {
    background: var(
      --hover,
      linear-gradient(254deg, #0095c0 17.54%, rgba(3, 182, 42, 0.6) 90.58%)
    );
    color: var(--txt-light-blue, #f4ffff);

    &:active {
      background: var(--green, #03b62a);
      color: var(--txt-light-blue, #f4ffff);
    }
  }
`;

export const ErrorBlock = styled.div`
  height: 40px;
  overflow: hidden;
  margin-bottom: 5px;
  .error-message {
    font-size: 14px;
    line-height: 140%;

    color: var(--txt-red, #f00);
  }
`;

export const SubtitleError = styled.h6`
  padding: 10px 0;

  font-size: 14px;
  line-height: 140%;

  color: var(--txt-red, #f00);
`;
