import { ParagraphSearch, SearchBlock } from "./style";

interface IParagraphSearch {
  text: string;
}

const TitleHeader = ({ text }: IParagraphSearch) => {
  return (
    <>
      <SearchBlock>
        <ParagraphSearch>{text}</ParagraphSearch>
      </SearchBlock>
    </>
  );
};

export default TitleHeader;
