import styled from "styled-components";

export const Paragraph=styled.p`
margin-left: 8px;
`;

export const UpParagraph=styled.p`
color: var(--txt-green, #03B62A);
margin-left: 5px;
`;


export const RatingInformation = styled.div`
  margin-bottom: 30px;
`;

export const ReviewsInformation = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
`;

export const SuccessWrapper=styled.div`
display: flex;
margin-left: 20px;
margin-top: 10px;
`;

export const SuccesRateWrapper=styled.div`
margin-bottom: 30px;
`;

export const RatingWrapper = styled.p`
  color: var(--txt-dark, #001731);
  font-size: 17px;
  font-weight: 500;
  line-height: 140%;
  margin: 0 10px 10px 20px;
`;

export const StarSwitch = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
`;