import React, { useState } from "react";
import Calendar, { CalendarProps } from "react-calendar";
import "react-calendar/dist/Calendar.css";
import enUS from "date-fns/locale/en-US";
import {
  BottomWrapper,
  ButtonContainer,
  ButtonWrapper,
  Container,
  DateParagraph,
  StyledCalendar,
} from "./style";
import ButtonWhite from "../../ButtonWhite/ButtonWhite";
import Button from "../../Button/Button";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { RootState } from "../../../store/store";
import {
  getAllCustomersDashboardAsync,
  getAllOrdersDashboardAsync,
  getAllProductsDashboardAsync,
  getAllRequestsDashboardAsync,
} from "../../../store/slices/userSlice";

interface MyCalendarProps {
  isCalendarOpen: boolean;
  setIsCalendarOpen: (isOpen: boolean) => void;
}

const CalendarComponent: React.FC<MyCalendarProps> = ({
  isCalendarOpen,
  setIsCalendarOpen,
}) => {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const dispatch = useAppDispatch();
  const token = useAppSelector(
    (state: RootState) => state.user.userAccount!.token
  );

  const handleDateChange = (date: Date | Date[]) => {
    if (Array.isArray(date)) {
      setStartDate(date[0]);
      setEndDate(date[1]);
    } else {
      setStartDate(date);
      setEndDate(null);
    }
  };

  const tileClassName = ({ date, view }: { date: Date; view: string }) => {
    if (startDate && endDate) {
      if (date >= startDate && date <= endDate) {
        if (date.getTime() === startDate.getTime()) {
          return "selected-start-date";
        } else if (date.getTime() === endDate.getTime()) {
          return "selected-end-date";
        } else {
          return "selected-date";
        }
      }
    } else if (startDate && date.getTime() === startDate.getTime()) {
      return "selected-start-date";
    }
    return "";
  };

  const handleClearDates = () => {
    setStartDate(null);
    setEndDate(null);
  };

  const handleDateSubmit = () => {
    const formatDate = (date: Date | null) => {
      if (!date) return "";
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();
      return `${day}.${month}.${year}`;
    };

    const formattedStartDate = formatDate(startDate);
    const formattedEndDate = formatDate(endDate);

    dispatch(
      getAllRequestsDashboardAsync({
        credentials: {
          startdate: formattedStartDate,
          enddate: formattedEndDate,
        }
      })
    );
    dispatch(
      getAllOrdersDashboardAsync({
        credentials: {
          startdate: formattedStartDate,
          enddate: formattedEndDate,
        }
      })
    );
    dispatch(
      getAllCustomersDashboardAsync({
        credentials: {
          startdate: formattedStartDate,
          enddate: formattedEndDate,
        }
      })
    );
    dispatch(
      getAllProductsDashboardAsync({
        credentials: {
          startdate: formattedStartDate,
          enddate: formattedEndDate,
        }
      })
    );
    setIsCalendarOpen(!isCalendarOpen);
  };

  return (
    <Container>
      <>
        <StyledCalendar
          onChange={handleDateChange as CalendarProps["onChange"]}
          value={startDate}
          selectRange={true}
          tileClassName={tileClassName}
          locale="en-US"
        />
      </>
      <BottomWrapper>
        <ButtonWrapper>
          <Button text="Submit" onClick={handleDateSubmit} />
          <ButtonWhite text="Clear dates" onClick={handleClearDates} />
        </ButtonWrapper>
      </BottomWrapper>
    </Container>
  );
};

export default CalendarComponent;
