import React from "react";

const LinInIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
    >
      <rect width="40.4335" height="40" rx="20" fill="#2878B7" />
      <path
        d="M28.3032 20.7444V26.2601H25.0395V21.0807C25.0395 19.8027 24.5635 18.9283 23.4076 18.9283C22.5237 18.9283 21.9798 19.5336 21.7758 20.0718C21.7078 20.2735 21.6398 20.5426 21.6398 20.8789V26.2601H18.3761C18.3761 26.2601 18.4441 17.5157 18.3761 16.6413H21.6398V17.9865C22.0478 17.3139 22.8637 16.3722 24.5635 16.3722C26.6713 16.3722 28.3032 17.7848 28.3032 20.7444ZM14.9764 12C13.8886 12 13.1406 12.7399 13.1406 13.6816C13.1406 14.6233 13.8206 15.3632 14.9085 15.3632C16.0643 15.3632 16.7443 14.6233 16.7443 13.6816C16.8123 12.6726 16.1323 12 14.9764 12ZM13.3446 26.2601H16.6083V16.6413H13.3446V26.2601Z"
        fill="white"
      />
    </svg>
  );
};

export default LinInIcon;
