import EllipseIcon from "../../assets/icons/EllipseIcon";
import StarIcon from "../../assets/icons/StarIcon";
import { FactoryInternetSearch } from "../../utils/types";

export interface Company {
  id: number;
  name: string;
  Location: string;
  Industry: string;
  Keywords: string;
  Rating: number;
  Reviews: number;
  Founded?: number;
  Language?: string;
  Availability?: "Now" | "Next week" | "Next month";
  SuccessRate?: string;
  Logo: React.ReactNode;
}

const CompanyItem: Company[] = [
  {
    id: 1,
    name: "Самое длинное название компании 1",
    Location: "USA, New York",
    Industry: "Consumer goods",
    Keywords: "Men’s fashion, Women’s fashion, Home&Garden, Consumer",
    Rating: 4,
    Reviews: 25,
    Founded: 2005,
    Language: "English",
    Availability: "Now",
    SuccessRate: "90% & Up",
    Logo: <EllipseIcon />,
  },
  {
    id: 2,
    name: "Самое длинное название компании 2",
    Location: "USA, Los Angeles",
    Industry: "Technology",
    Keywords: "Software, Hardware",
    Rating: 5,
    Reviews: 30,
    Founded: 2010,
    Language: "English",
    Availability: "Next week",
    SuccessRate: "80% & Up",
    Logo: <EllipseIcon />,
  },
  {
    id: 3,
    name: "Самое длинное название компании 3",
    Location: "Canada, Toronto",
    Industry: "Automotive",
    Keywords: "Cars, Trucks",
    Rating: 3,
    Reviews: 150,
    Founded: 2002,
    Language: "English",
    Availability: "Next month",
    SuccessRate: "90% & Up",
    Logo: <EllipseIcon />,
  },
  {
    id: 4,
    name: "Самое длинное название компании",
    Location: "Germany, Berlin",
    Industry: "Fashion",
    Keywords: "Clothing, Accessories",
    Rating: 4,
    Reviews: 20,
    Founded: 2008,
    Language: "German",
    Availability: "Now",
    SuccessRate: "90% & Up",
    Logo: <EllipseIcon />,
  },
  {
    id: 5,
    name: "Самое длинное название компании",
    Location: "Japan, Tokyo",
    Industry: "Electronics",
    Keywords: "Gadgets, Appliances",
    Rating: 5,
    Reviews: 40,
    Founded: 2012,
    Language: "Japanese",
    Availability: "Next week",
    SuccessRate: "80% & Up",
    Logo: <EllipseIcon />,
  },
  {
    id: 6,
    name: "Самое длинное название компании",
    Location: "UK, London",
    Industry: "Finance",
    Keywords: "Banking, Investments",
    Rating: 4,
    Reviews: 28,
    Founded: 2000,
    Language: "English",
    Availability: "Next month",
    SuccessRate: "90% & Up",
    Logo: <EllipseIcon />,
  },
  {
    id: 7,
    name: "Самое длинное название компании",
    Location: "Australia, Sydney",
    Industry: "Hospitality",
    Keywords: "Restaurants, Hotels",
    Rating: 4,
    Reviews: 22,
    Founded: 2015,
    Language: "English",
    Availability: "Now",
    SuccessRate: "90% & Up",
    Logo: <EllipseIcon />,
  },
  {
    id: 8,
    name: "Самое длинное название компании",
    Location: "China, Beijing",
    Industry: "Manufacturing",
    Keywords: "Production, Assembly",
    Rating: 3,
    Reviews: 18,
    Founded: 2004,
    Language: "Mandarin",
    Availability: "Next week",
    SuccessRate: "80% & Up",
    Logo: <EllipseIcon />,
  },
  {
    id: 9,
    name: "Самое длинное название компании",
    Location: "Brazil, Rio de Janeiro",
    Industry: "Entertainment",
    Keywords: "Movies, Music",
    Rating: 5,
    Reviews: 35,
    Founded: 2007,
    Language: "Portuguese",
    Availability: "Next month",
    SuccessRate: "90% & Up",
    Logo: <EllipseIcon />,
  },
  {
    id: 10,
    name: "Самое длинное название компании",
    Location: "India, Mumbai",
    Industry: "Internet",
    Keywords: "Telecom, Networking",
    Rating: 4,
    Reviews: 26,
    Founded: 2011,
    Language: "Hindi",
    Availability: "Now",
    SuccessRate: "90% & Up",
    Logo: <EllipseIcon />,
  },
  {
    id: 11,
    name: "Еще одно длинное название компании",
    Location: "France, Paris",
    Industry: "Beauty",
    Keywords: "Skincare, Makeup",
    Rating: 4,
    Reviews: 27,
    Founded: 2006,
    Language: "French",
    Availability: "Next week",
    SuccessRate: "80% & Up",
    Logo: <EllipseIcon />,
  },
  {
    id: 12,
    name: "Еще одно длинное название компании",
    Location: "Spain, Barcelona",
    Industry: "Tourism",
    Keywords: "Travel, Accommodation",
    Rating: 4,
    Reviews: 23,
    Founded: 2003,
    Language: "Spanish",
    Availability: "Next month",
    SuccessRate: "90% & Up",
    Logo: <EllipseIcon />,
  },
  {
    id: 13,
    name: "Еще одно длинное название компании",
    Location: "South Korea, Seoul",
    Industry: "Gaming",
    Keywords: "Video games, Esports",
    Rating: 5,
    Reviews: 38,
    Founded: 2013,
    Language: "Korean",
    Availability: "Now",
    SuccessRate: "90% & Up",
    Logo: <EllipseIcon />,
  },
  {
    id: 14,
    name: "Еще одно длинное название компании",
    Location: "Mexico, Mexico City",
    Industry: "Food and Beverage",
    Keywords: "Cuisine, Beverages",
    Rating: 3,
    Reviews: 16,
    Founded: 2009,
    Language: "Spanish",
    Availability: "Next week",
    SuccessRate: "80% & Up",
    Logo: <EllipseIcon />,
  },
  {
    id: 15,
    name: "Еще одно длинное название компании",
    Location: "Russia, Moscow",
    Industry: "Energy",
    Keywords: "Renewable energy, Power plants",
    Rating: 4,
    Reviews: 21,
    Founded: 2001,
    Language: "Russian",
    Availability: "Next month",
    SuccessRate: "90% & Up",
    Logo: <EllipseIcon />,
  },
  {
    id: 16,
    name: "Еще одно длинное название компании",
    Location: "Belarus, Minsk",
    Industry: "Mining",
    Keywords: "Minerals, Extraction",
    Rating: 3,
    Reviews: 19,
    Founded: 2014,
    Language: "Belarusian",
    Availability: "Now",
    SuccessRate: "90% & Up",
    Logo: <EllipseIcon />,
  },
  {
    id: 17,
    name: "Еще одно длинное название компании",
    Location: "Italy, Rome",
    Industry: "Healthcare",
    Keywords: "Medical services, Pharmaceuticals",
    Rating: 5,
    Reviews: 32,
    Founded: 2008,
    Language: "Italian",
    Availability: "Next week",
    SuccessRate: "80% & Up",
    Logo: <EllipseIcon />,
  },
  {
    id: 18,
    name: "Еще одно длинное название компании",
    Location: "Netherlands, Amsterdam",
    Industry: "Transportation",
    Keywords: "Logistics, Shipping",
    Rating: 4,
    Reviews: 24,
    Founded: 2010,
    Language: "Dutch",
    Availability: "Next month",
    SuccessRate: "90% & Up",
    Logo: <EllipseIcon />,
  },
  {
    id: 19,
    name: "Еще одно длинное название компании",
    Location: "Singapore, Singapore City",
    Industry: "Biotechnology",
    Keywords: "Biotech research, Genetics",
    Rating: 5,
    Reviews: 36,
    Founded: 2012,
    Language: "English",
    Availability: "Now",
    SuccessRate: "90% & Up",
    Logo: <EllipseIcon />,
  },
  {
    id: 20,
    name: "Еще одно длинное название компании",
    Location: "Sweden, Stockholm",
    Industry: "Education",
    Keywords: "Learning, Training",
    Rating: 4,
    Reviews: 29,
    Founded: 2005,
    Language: "Swedish",
    Availability: "Next week",
    SuccessRate: "80% & Up",
    Logo: <EllipseIcon />,
  },
];

export const renderStars = (rating: number) => {
  if (rating === 0) {
    return <div>N/A</div>;
  }

  const starArray = Array.from({ length: rating }, (_, index) => index + 1);
  return (
    <div>
      {starArray.map((star) => (
        <StarIcon key={star} />
      ))}
    </div>
  );
};

export const companies: FactoryInternetSearch[] = [
  {
    place_id: "aaa1",
    companyName: 'Самое длинное название компании5',
    country: 'USA',
    city: 'Chicago',
    industry: ['Consumer goods','phone'],
    keywords: ['Men’s fashion', 'Women’s fashion'],
    internationalPhone: '',
    rating: 5,
    website: '2024-01-31T15:59:49.898Z',
    logo: '',
    reviews: 1296,
  },
  {
    place_id: "aaa2",
    companyName: 'Самое длинное название компании4',
    country: 'USA',
    city: 'New York',
    industry: ['Consumer goods'],
    keywords: ['Men’s fashion', 'Women’s fashion'],
    internationalPhone: '',
    rating: 4,
    website: '2024-01-31T15:59:49.878Z',
    logo: '',
    reviews: 101,
  },
  {
    place_id: "aaa3",
    companyName: 'Самое длинное название компании3',
    country: 'USA',
    city: 'Boston',
    industry: ['Consumer goods'],
    keywords: ['Men’s fashion', 'Women’s fashion'],
    internationalPhone: '',
    rating: 3,
    website: '2024-01-31T15:59:49.848Z',
    logo: '',
    reviews: 1,
  },
  {
    place_id: "aaa4",
    companyName: 'Самое длинное название компании2',
    country: 'USA',
    city: 'New York',
    industry: ['Consumer goods'],
    keywords: ['Men’s fashion', 'Women’s fashion'],
    internationalPhone: '',
    rating: 2,
    website: '2024-01-31T15:59:49.598Z',
    logo: '',
    reviews: 51,
  },
  {
    place_id: "aaa5",
    companyName: 'Самое длинное название компании1',
    country: 'USA',
    city: 'New York',
    industry: ['Consumer goods'],
    keywords: ['Men’s fashion', 'Women’s fashion'],
    internationalPhone: '',
    rating: 1,
    website: '2024-01-31T15:59:49.498Z',
    logo: '',
    reviews: 29,
  },
]

export default CompanyItem;
