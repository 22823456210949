import React, { useEffect, useRef, useState } from "react";

import { defaultAvatar } from "../../../assets/img";
import ButtonWhite from "../../ButtonWhite/ButtonWhite";
import Button from "../../Button/Button";
import Progressbar from "../../../assets/icons/Progressbar";
import ShieldQuestionIcon from "../../../assets/icons/ShieldQuestionIcon";
import {
  AvatarWrapper,
  ButtonContainer,
  ButtonWrapper,
  Input,
  Label,
  ProfileCompletionWrapper,
  RedStar,
  TitleProfile,
  TitleAndToolTip,
  TitleJoined,
  TitleProfileWrapper,
  TitleWrapper,
  TooltipProfileCompletion,
  TooltipWrapper,
  Wrapper,
  TitleIndustryWrapper,
  TooltipIndustry,
  ChipsList,
  KeywordLabel,
  SectionWrapper,
  Chips,
  LabelAbout,
  AboutTextArea,
  SliderWrapper,
  TooltipSlider,
  MediaUploadWrapper,
  UploadDialogWrapper,
  LabelYesNo,
  TitleYesNoWrapper,
  Overlay,
  IndustryWrapper,
  IndustryChangeButton,
  SliderTitleAndToolTip,
  DropdownMenu,
  SectionWithDropdownWrapper,
  StyledCloseIcon,
  InputWithDropdown,
  InputDropdownWrapper,
  ChevronDownIconWrapper,
  List,
  Item,
  VectorWrapper,
  CloseUploadWrapper,
  SliderBigWrapper,
  FileName,
  ImageTooltip,
  ImageHover,
  ImageHoverWrapper,
} from "./style";
import Title from "../../Title/Title";
import RadioButtonYesNo from "../Components/RadioButtonYesNo";
import RadioButtonWithOptions from "../Components/RadioButtonWithOptions";
import IndustryModal from "../IndustryModal/IndustryModal";
import DropdownChips from "../Components/DropdownChips";
import CloseIcon from "../../../assets/icons/CloseIcon";
import ChevronDownIcon from "../../../assets/icons/ChevronDownIcon";
import { base_url, paymentTermsFixList } from "../../../utils/constants";
import {
  AvailabilityStatus,
  CompanyProfile,
  Photo,
  allMarketplacesContentType,
} from "../../../utils/types";
import { parseDateString } from "../Company";
import DropdownWithCheckbox from "../Components/DropdownWithCheckbox";
import UserLogo from "../../UserLogo/UserLogo";
import MediaUpload from "../../MediaUpload/MediaUpload";
import {
  addPhotoToProviderAsync,
  getAllLanguagesAsync,
  getAllMarketplacesAsync,
  removePhotoFromProviderAsync,
  uploadFilesToServer,
} from "../../../store/slices/userSlice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import VectorIcon from "../../../assets/icons/VectorIcon";
import CrossIcon from "../../../assets/icons/CrossIcon";
import { unwrapResult } from "@reduxjs/toolkit";
import ModalLostChanges from "../../ModalWindows/ModalLostChanges/ModalLostChanges";

export interface Activity {
  id: number;
  name: string;
}

export interface Industry {
  id: number;
  name: string;
  activities: Activity[];
  isOpen?: boolean;
}

interface Props {
  companyState: CompanyProfile;
  setCompanyStateValue: (key: string, value: any) => void;
  updateCompany: CompanyProfile | undefined;
  setUpdateCompany: React.Dispatch<React.SetStateAction<CompanyProfile | undefined>>;
  isStateChanged: boolean;
  showModal: boolean;
  preview: () => void;
  save: () => void;
  discard: () => void;
  editing: () => void;
}
const CompanyProfileEdit: React.FC<Props> = ({
  companyState: {
    avatar,
    companyName,
    founded,
    businessType,
    operationInfo,
    numberOfEmployees,
    industry,
    dateCreate,
    completionRate,
    keywords,
    about,
    website,
    instagram,
    facebook,
    linkedIn,
    experienceMarketplace,
    languages,
    shippingTo,
    paymentTerms,
    availability,
    customizationCapacity,
    rndCapacity,
    specialPacking,
    whiteLabel,
    certification,
    sample,
    photos,
  },
  setCompanyStateValue,
  updateCompany,
  setUpdateCompany,
  preview,
  save,
  discard,
  editing,
  isStateChanged,
  showModal,
}) => {


  const dispatch = useAppDispatch();
  const user = useAppSelector((store) => store.user);

  const [showProfileCompletionTooltip, setShowProfileCompletionTooltip] = useState(false);
  const [showIndustryTooltip, setShowIndustryTooltip] = useState(false);
  const [showSliderTooltip, setShowSliderTooltip] = useState(false);
  const [showIndustryModal, setShowIndustryModal] = useState(false);
  const [currentIndustry, setCurrentIndustry] = useState(0);
  // const [selectedFiles, setSelectedFiles] = useState(fileList);
  const [filesToUpload, setFilesToUpload] = useState<File[]>([]);
  const [showDropdownLanguage, setShowDropdownLanguage] = useState(false);
  const [languageList, setLanguageList] = useState(languages);
  const [showDropdownMarketplace, setShowDropdownMarketplace] = useState(false);
  const [marketplaceList, setMarketplaceList] = useState(experienceMarketplace);
  const [showDropdownPaymentTerms, setShowDropdownPaymentTerms] = useState(false);
  const [paymentTermsList, setPaymentTermsList] = useState(paymentTerms);
  const [showUploadTooltip, setShowUploadTooltip] = useState<boolean>(false);
  const [tooltipLink, setTooltipLink] = useState<string>("");
  const [photoList, setPhotoList] = useState<Photo[]>(
    photos || []
  );
  const [draggedIndex, setDraggedIndex] = useState<number | null>(null);

  const saveIndustry = (selectedIndustry: string, selectedKeywords: string[], nIndustry: number) => {
    const tempIndustry = [...industry!];

    tempIndustry[nIndustry] = selectedIndustry;
    setCompanyStateValue("industry", tempIndustry);
    const tempKeywords: Set<string> = new Set();
    keywords && keywords.forEach((k) => tempKeywords.add(k));
    selectedKeywords.forEach((k) => tempKeywords.add(k));
    setCompanyStateValue("keywords", Array.from(tempKeywords));
    setShowIndustryModal(false);
  };

  const marketplacesFixList: string[] =
    user.allMarketplaces &&
    user.allMarketplaces.map((mp: allMarketplacesContentType) => mp.name);
  const languagesFixList =
    (user.allLanguages && user.allLanguages.languages) || [];

  const [industriesFetched, setIndustriesFetched] = useState<Industry[]>([]);

  const openIndustryModal = (industry: number) => {
    setCurrentIndustry(industry);
    setShowIndustryModal(true);
  };

  const handleDropdownChips = (e: React.MouseEvent<HTMLLIElement>) => {
    if ((e.target as HTMLLIElement).tagName === "LI") {
      if (
        (e.target as HTMLElement).parentElement?.parentElement?.firstChild
          ?.textContent === "Languages"
      )
        setShowDropdownLanguage(!showDropdownLanguage);
      if (
        (e.target as HTMLElement).parentElement?.parentElement?.firstChild
          ?.textContent === "Experience on marketplaces"
      )
        setShowDropdownMarketplace(!showDropdownMarketplace);
    }
  };

  const handleDeleteLang = (language: string) => {
    // const arrayAfterRemove = [...(languageList || [])];
    // if (arrayAfterRemove.length > 1) {

    const arrayAfterRemove = [...languageList!];
    if (arrayAfterRemove.length > 0) {
      arrayAfterRemove.splice(arrayAfterRemove.indexOf(language), 1);
      setLanguageList(arrayAfterRemove);
      setCompanyStateValue("languages", arrayAfterRemove);
    }
  };
  const handleDeleteMarketplace = (marketplace: string) => {
    // const arrayAfterRemove = [...(marketplaceList || [])];
    // if (arrayAfterRemove.length > 1 && marketplaceList) {

    const arrayAfterRemove = [...marketplaceList!];
    if (arrayAfterRemove.length > 0) {
      arrayAfterRemove.splice(marketplaceList!.indexOf(marketplace), 1);
      setMarketplaceList(arrayAfterRemove);
      setCompanyStateValue("experienceMarketplace", arrayAfterRemove);
    }
  };

  const handleDeleteKeyword = (keyword: string) => {
    const arrayAfterRemove = [...(keywords || [])];
    if (arrayAfterRemove.length > 0 && keywords) {
      arrayAfterRemove.splice(keywords.indexOf(keyword), 1);
      setCompanyStateValue("keywords", arrayAfterRemove);
    }
  };

  const toggleRefDropdownLang = useRef<HTMLDivElement>(null);
  const toggleRefDropdownMarketplace = useRef<HTMLDivElement>(null);
  const toggleRefDropdownPaymentTerms = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutsideDropdownLang = (event: MouseEvent) => {
      if (
        toggleRefDropdownLang.current &&
        !toggleRefDropdownLang.current.contains(event.target as Node)
      ) {
        setShowDropdownLanguage(false);
      }
    };
    const handleClickOutsideDropdownMarketplace = (event: MouseEvent) => {
      if (
        toggleRefDropdownMarketplace.current &&
        !toggleRefDropdownMarketplace.current.contains(event.target as Node)
      ) {
        setShowDropdownMarketplace(false);
      }
    };
    const handleClickOutsideDropdownPaymentTerms = (event: MouseEvent) => {
      if (
        toggleRefDropdownPaymentTerms.current &&
        !toggleRefDropdownPaymentTerms.current.contains(event.target as Node)
      ) {
        setShowDropdownPaymentTerms(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutsideDropdownLang);
    document.addEventListener(
      "mousedown",
      handleClickOutsideDropdownMarketplace
    );
    document.addEventListener(
      "mousedown",
      handleClickOutsideDropdownPaymentTerms
    );
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideDropdownLang);
      document.removeEventListener(
        "mousedown",
        handleClickOutsideDropdownMarketplace
      );
      document.removeEventListener(
        "mousedown",
        handleClickOutsideDropdownPaymentTerms
      );
    };
  }, []);

  useEffect(() => {
    fetch(`${base_url}/getAllIndustries`)
      .then((response) => response.json())
      .then((data: Industry[]) => {
        setIndustriesFetched(data);
      })
      .catch((error) =>
        console.error("Error fetching industries data:", error)
      );
    dispatch(getAllMarketplacesAsync());
    dispatch(getAllLanguagesAsync());
  }, []);

  const handleAttachmentFilesChange = async (files: File[]) => {
    try {
      const saveFiles: any = await dispatch(
        uploadFilesToServer({ files: files }) as any
      );
      const addPhotoAction = await dispatch(
        addPhotoToProviderAsync({
          credentials: {
            name: saveFiles.payload[0].name,
            photos_url: [saveFiles.payload[0].link],
          },
          token: user.userAccount?.token!,
        })
      );
      const addPhotoResult = unwrapResult(addPhotoAction);      
      setPhotoList(addPhotoResult);
    } catch (error) {
      console.error(error);
    }
  };

  const handleRemoveClick = async (idToRemove: number) => {
    try {
      const resultAction = await dispatch(
        removePhotoFromProviderAsync({
          photoId: idToRemove,
          token: user.userAccount?.token!,
        })
      );
      const originalPromiseResult = unwrapResult(resultAction);
      setPhotoList(originalPromiseResult);
    } catch (error) {
      console.error(error);
    }
  };

  const handleShowUploadTooltip = (photo_url: string) => {
    setShowUploadTooltip(true);
    setTooltipLink(photo_url);
  };

  const dragStartHandler = (e: React.DragEvent, index: number) => {
    setDraggedIndex(index);
    e.dataTransfer.effectAllowed = "move";
  };

  const dragOverHandler = (e: React.DragEvent) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = "move";
    (e.currentTarget as HTMLElement).style.background = "lightgray";
  };
  const dragEndHandler = (e: React.DragEvent) => {
    e.preventDefault();
    (e.currentTarget as HTMLElement).style.background = "var(--wiht, #FFF)";
  };

  const dropHandler = (e: React.DragEvent, index: number) => {
    e.preventDefault();
    if (draggedIndex === null) return;
    const newPhotoList = [...photoList];
    const [draggedPhoto] = newPhotoList.splice(draggedIndex, 1);
    newPhotoList.splice(index, 0, draggedPhoto);
    setPhotoList(newPhotoList);
    setCompanyStateValue("photos", newPhotoList)
    setDraggedIndex(null);
    (e.currentTarget as HTMLElement).style.background = "var(--wiht, #FFF)";
  };

  return (
    <Wrapper>
      <>
        <AvatarWrapper>
          <UserLogo
            avatar={avatar || defaultAvatar}
            changeAvatar={setUpdateCompany}
            newAvatar={updateCompany?.avatar}
          />
          <TitleProfileWrapper>
            <ProfileCompletionWrapper>
              <TitleAndToolTip>
                <TitleProfile>Profile completion</TitleProfile>
                <TooltipWrapper
                  onMouseEnter={() => setShowProfileCompletionTooltip(true)}
                  onMouseLeave={() => setShowProfileCompletionTooltip(false)}
                >
                  <ShieldQuestionIcon />
                </TooltipWrapper>
                {showProfileCompletionTooltip && (
                  <TooltipProfileCompletion>
                    To optimize your experience on the platform and unlock its
                    full potential, please complete as many fields as possible:
                    <br />
                    Basic data (Company name, Founded, Business type,
                    Operational address, City, Country, Postal code, Number of
                    employees) - 40%,
                    <br />
                    Industry and specialization (Industry 1-3, Keywords) - 20%,
                    <br />
                    Description (About) - 15%,
                    <br />
                    Links (Website, Instagram, Facebook, LinkedIn) - 10%,
                    <br />
                    Experience on marketplaces - 5%,
                    <br />
                    Languages and Shipping to - 5%,
                    <br />
                    Payment terms and Availability - 5%.
                  </TooltipProfileCompletion>
                )}
              </TitleAndToolTip>
              <TitleProfile>{`${completionRate}%`}</TitleProfile>
            </ProfileCompletionWrapper>
            <Progressbar percentage={completionRate || 0} />
            <TitleJoined>{`Joined SelfMan in ${
              dateCreate ? parseDateString(dateCreate) : ""
            }`}</TitleJoined>
          </TitleProfileWrapper>
        </AvatarWrapper>

        <TitleWrapper>
          <Label>Company name</Label>
          <RedStar>*</RedStar>
        </TitleWrapper>
        <Input
          placeholder="Enter your Company name"
          value={companyName ? companyName : ""}
          onChange={(e) => setCompanyStateValue("companyName", e.target.value)}
        />
        <TitleWrapper>
          <Label>Founded</Label>
          <RedStar>*</RedStar>
        </TitleWrapper>
        <Input
          type="number"
          value={founded || ""}
          onChange={(e) => setCompanyStateValue("founded", e.target.value)}
        />
        <TitleWrapper>
          <Label>Business type</Label>
        </TitleWrapper>
        <Input
          value={businessType || ""}
          onChange={(e) => setCompanyStateValue("businessType", e.target.value)}
        />
        <TitleWrapper>
          <Label>Operation address</Label>
          <RedStar>*</RedStar>
        </TitleWrapper>
        <Input
          value={
            operationInfo && operationInfo.address ? operationInfo.address : ""
          }
          onChange={(e) => setCompanyStateValue("address", e.target.value)}
        />
        <TitleWrapper>
          <Label>City</Label>
          <RedStar>*</RedStar>
        </TitleWrapper>
        <Input
          value={operationInfo && operationInfo.city ? operationInfo.city : ""}
          onChange={(e) => setCompanyStateValue("city", e.target.value)}
        />
        <TitleWrapper>
          <Label>Country</Label>
          <RedStar>*</RedStar>
        </TitleWrapper>
        <Input
          value={
            operationInfo && operationInfo.country ? operationInfo.country : ""
          }
          onChange={(e) => setCompanyStateValue("country", e.target.value)}
        />
        <TitleWrapper>
          <Label>Postal code</Label>
          <RedStar>*</RedStar>
        </TitleWrapper>
        <Input
          value={
            operationInfo && operationInfo.postalCode
              ? operationInfo.postalCode
              : ""
          }
          onChange={(e) => setCompanyStateValue("postalCode", e.target.value)}
        />
        <TitleWrapper>
          <Label>Number of employees</Label>
        </TitleWrapper>
        <RadioButtonWithOptions
          title={"numberOfEmployees"}
          initialValue={numberOfEmployees ? numberOfEmployees : "1-10"}
          options={[
            "1-10",
            "11-50",
            "51-100",
            "101-500",
            "501-1000",
            "1000-5000",
            "5001+",
          ]}
          setValue={setCompanyStateValue}
        />
      </>
      <TitleIndustryWrapper>
        <Title text={"Industry"} />
        <RedStar>*</RedStar>
        <TooltipWrapper
          onMouseEnter={() => setShowIndustryTooltip(true)}
          onMouseLeave={() => setShowIndustryTooltip(false)}
        >
          <ShieldQuestionIcon />
        </TooltipWrapper>

        {showIndustryTooltip && (
          <TooltipIndustry>
            Please prioritize the industries you are active in by selecting up
            to three options. Your choices will influence how customers discover
            your business. Within each industry, you may select multiple
            categories to further specify your offerings. These selections will
            be included in your keywords for enhanced searchability.
          </TooltipIndustry>
        )}
      </TitleIndustryWrapper>
      <SectionWrapper>
        {industry!.map((ind, index) => (
          <div key={index + "key-ind"}>
            <TitleWrapper>
              <Label>{`Industry ${index + 1}`}</Label>
            </TitleWrapper>
            <IndustryWrapper>
              <Input readOnly value={ind} />
              <IndustryChangeButton onClick={() => openIndustryModal(index)}>
                Change
              </IndustryChangeButton>
            </IndustryWrapper>
          </div>
        ))}
      </SectionWrapper>
      <SectionWrapper>
        <KeywordLabel>Keywords:</KeywordLabel>

        <ChipsList>
          {keywords!.map((keyword, index) => (
            <Chips key={index + "key-kw"}>
              {keyword}
              <StyledCloseIcon
                onClick={() => {
                  handleDeleteKeyword(keyword);
                }}
              >
                <CloseIcon />
              </StyledCloseIcon>
            </Chips>
          ))}
        </ChipsList>
      </SectionWrapper>
      <>
        <LabelAbout>About</LabelAbout>
        <AboutTextArea
          value={about || ""}
          onChange={(e) => setCompanyStateValue("about", e.target.value)}
        />
        <TitleWrapper>
          <Label>Website</Label>
        </TitleWrapper>
        <Input
          value={website || ""}
          onChange={(e) => setCompanyStateValue("website", e.target.value)}
        />
        <TitleWrapper>
          <Label>Instagram</Label>
        </TitleWrapper>
        <Input
          value={instagram || ""}
          onChange={(e) => setCompanyStateValue("instagram", e.target.value)}
        />
        <TitleWrapper>
          <Label>Facebook</Label>
        </TitleWrapper>
        <Input
          value={facebook || ""}
          onChange={(e) => setCompanyStateValue("facebook", e.target.value)}
        />
        <TitleWrapper>
          <Label>LinkedIn</Label>
        </TitleWrapper>
        <Input
          value={linkedIn || ""}
          onChange={(e) => setCompanyStateValue("linkedIn", e.target.value)}
        />
      </>
      <SectionWithDropdownWrapper ref={toggleRefDropdownMarketplace}>
        <Label>Experience on marketplaces</Label>
        <InputDropdownWrapper onClick={() => setShowDropdownMarketplace(true)}>
          <InputWithDropdown placeholder={"Enter marketplaces"} />
          <ChevronDownIconWrapper>
            <ChevronDownIcon />
          </ChevronDownIconWrapper>
        </InputDropdownWrapper>
        <ChipsList>
          {marketplaceList!.map((mp, index) => (
            <Chips key={index + "mp"} onClick={handleDropdownChips}>
              {mp}
              <StyledCloseIcon
                onClick={() => {
                  handleDeleteMarketplace(mp);
                }}
              >
                <CloseIcon />
              </StyledCloseIcon>
            </Chips>
          ))}
        </ChipsList>
        <DropdownMenu>
          {showDropdownMarketplace && (
            <DropdownChips
              title={"marketplace"}
              initialValue={marketplacesFixList}
              itemList={marketplaceList || []}
              setItemList={setMarketplaceList}
              setCompanyStateValue={setCompanyStateValue}
              setShowDropdown={setShowDropdownMarketplace}
            />
          )}
        </DropdownMenu>
      </SectionWithDropdownWrapper>
      <SectionWithDropdownWrapper ref={toggleRefDropdownLang}>
        <Label>Languages</Label>
        <InputDropdownWrapper onClick={() => setShowDropdownLanguage(true)}>
          <InputWithDropdown placeholder={"Enter languages"} />
          <ChevronDownIconWrapper>
            <ChevronDownIcon />
          </ChevronDownIconWrapper>
        </InputDropdownWrapper>
        <ChipsList>
          {languageList &&
            languageList.map((language, index) => (
              <Chips key={index + "lang"} onClick={handleDropdownChips}>
                {language}
                <StyledCloseIcon
                  onClick={() => {
                    handleDeleteLang(language);
                  }}
                >
                  <CloseIcon />
                </StyledCloseIcon>
              </Chips>
            ))}
        </ChipsList>
        <DropdownMenu>
          {showDropdownLanguage && (
            <DropdownChips
              title={"language"}
              initialValue={languagesFixList}
              itemList={languageList || []}
              setItemList={setLanguageList}
              setCompanyStateValue={setCompanyStateValue}
              setShowDropdown={setShowDropdownLanguage}
            />
          )}
        </DropdownMenu>
      </SectionWithDropdownWrapper>
      <TitleYesNoWrapper>
        <Label>Shipping to</Label>
        <RadioButtonWithOptions
          title={"shippingTo"}
          initialValue={
            shippingTo && shippingTo.length > 0 ? shippingTo[0] : "Domestic"
          }
          options={["International", "Domestic"]}
          setValue={setCompanyStateValue}
        />
      </TitleYesNoWrapper>
      <SectionWithDropdownWrapper ref={toggleRefDropdownPaymentTerms}>
        <Label>Payment terms</Label>
        <InputDropdownWrapper onClick={() => setShowDropdownPaymentTerms(true)}>
          <InputWithDropdown value={paymentTermsList!.join(", ")} readOnly />
          <ChevronDownIconWrapper>
            <ChevronDownIcon />
          </ChevronDownIconWrapper>
        </InputDropdownWrapper>
        <DropdownMenu>
          {showDropdownPaymentTerms && (
            <DropdownWithCheckbox
              title={"payment terms"}
              initialValue={paymentTermsFixList}
              itemList={paymentTermsList!}
              setItemList={setPaymentTermsList}
              setCompanyStateValue={setCompanyStateValue}
              setShowDropdown={setShowDropdownPaymentTerms}
            />
          )}
        </DropdownMenu>
      </SectionWithDropdownWrapper>

      <>
        <TitleYesNoWrapper>
          <Label>Availability</Label>
          <RadioButtonWithOptions
            title={"availability"}
            initialValue={availability ? availability : ""}
            options={[
              AvailabilityStatus.NOW,
              AvailabilityStatus.NEXT_WEEK,
              AvailabilityStatus.NEXT_MONTH,
            ]}
            setValue={setCompanyStateValue}
          />
        </TitleYesNoWrapper>

        <TitleYesNoWrapper>
          <LabelYesNo>Customization capacity</LabelYesNo>
          <RadioButtonYesNo
            title={"customizationCapacity"}
            initialValue={!!customizationCapacity}
            setValue={setCompanyStateValue}
          />
        </TitleYesNoWrapper>

        <TitleYesNoWrapper>
          <LabelYesNo>R&D capacity</LabelYesNo>
          <RadioButtonYesNo
            title={"rndCapacity"}
            initialValue={!!rndCapacity}
            setValue={setCompanyStateValue}
          />
        </TitleYesNoWrapper>

        <TitleYesNoWrapper>
          <LabelYesNo>Special packing</LabelYesNo>
          <RadioButtonYesNo
            title={"specialPacking"}
            initialValue={!!specialPacking}
            setValue={setCompanyStateValue}
          />
        </TitleYesNoWrapper>

        <TitleYesNoWrapper>
          <LabelYesNo>White label</LabelYesNo>
          <RadioButtonYesNo
            title={"whiteLabel"}
            initialValue={!!whiteLabel}
            setValue={setCompanyStateValue}
          />
        </TitleYesNoWrapper>

        <TitleYesNoWrapper>
          <LabelYesNo>Certification</LabelYesNo>
          <RadioButtonYesNo
            title={"certification"}
            initialValue={!!certification}
            setValue={setCompanyStateValue}
          />
        </TitleYesNoWrapper>

        <TitleYesNoWrapper>
          <LabelYesNo>Sample</LabelYesNo>
          <RadioButtonYesNo
            title={"sample"}
            initialValue={!!sample}
            setValue={setCompanyStateValue}
          />
        </TitleYesNoWrapper>
      </>
      <SliderBigWrapper>
        <SliderWrapper>
          <SliderTitleAndToolTip>
            <TitleProfile>Slider for the main page</TitleProfile>
            <TooltipWrapper
              onMouseEnter={() => setShowSliderTooltip(true)}
              onMouseLeave={() => setShowSliderTooltip(false)}
            >
              <ShieldQuestionIcon />
            </TooltipWrapper>
            {showSliderTooltip && (
              <TooltipSlider>
                Factory photos, product photos, discounts, hot offers, or any
                announcements. Please, see the following requirements for the
                pictures:
                <br />
                file type - .jpg, .jpeg, .png, file size - up to 5 MB, quantity
                - up to 5 files.
              </TooltipSlider>
            )}
          </SliderTitleAndToolTip>
          <MediaUploadWrapper disabled={user.company!.photos?.length! >= 5}>
            <UploadDialogWrapper>
              <MediaUpload
                onlyPhoto
                selectedFiles={filesToUpload}
                onFilesChange={(files) => handleAttachmentFilesChange(files)}
              />
            </UploadDialogWrapper>
          </MediaUploadWrapper>
        </SliderWrapper>
        <List>
          {photoList &&
            photoList.map((photo: Photo, index: number) => {
              return (
                <Item
                  key={index}
                  draggable={true}
                  onDragStart={(e) => dragStartHandler(e, index)}
                  onDragOver={(e) => dragOverHandler(e)}
                  onDrop={(e) => dropHandler(e, index)}
                  onDragEnd={(e) => dragEndHandler(e)}
                  onDragLeave={(e) => dragEndHandler(e)}
                >
                  <VectorWrapper>
                    <VectorIcon />
                  </VectorWrapper>
                  <ImageTooltip
                    src={photo?.photo_url as unknown as string}
                    alt={photo?.name}
                    onMouseEnter={() =>
                      handleShowUploadTooltip(
                        photo?.photo_url as unknown as string
                      )
                    }
                    onMouseLeave={() => setShowUploadTooltip(false)}
                  />
                  <FileName>{photo.name}</FileName>
                  <CloseUploadWrapper
                    onClick={() => handleRemoveClick(photo.id)}
                  >
                    <CrossIcon />
                  </CloseUploadWrapper>
                </Item>
              );
            })}
          {showUploadTooltip && (
            <ImageHoverWrapper>
              <ImageHover src={tooltipLink} alt={tooltipLink} />
            </ImageHoverWrapper>
          )}
        </List>
      </SliderBigWrapper>
      <ButtonContainer>
        <Button disabled={!isStateChanged} text={"Update"} onClick={save} />
        <ButtonWrapper>
          <ButtonWhite text={"Preview"} onClick={preview} />
        </ButtonWrapper>
      </ButtonContainer>
      {showIndustryModal && (
        <>
          <Overlay onClick={() => setShowIndustryModal(false)} />
          <IndustryModal
            industry={industry || []}
            industriesFetched={industriesFetched}
            keywords={keywords || []}
            industryForChange={currentIndustry}
            onSave={saveIndustry}
          />
        </>
      )}
        {showModal && (
        <ModalLostChanges
          text='The changes you made to  this page will be lost'
          buttonText="Keep editing"
          buttonWhitetext="Discard"
          handleWhiteButtonClick={discard}
          handleButtonClick={editing}
        />
      )}
    </Wrapper>  
  );
};

export default CompanyProfileEdit;
