import React, { useEffect, useState } from "react";
import {
  DialogContent,
  ExpandButton,
  IndustriesContainer,
  IndustryItem,
  IndustryRow,
  Input,
  InputWrapper,
  Label,
  SearchInputWrapper,
  SubIndustryItem,
  TitleModal,
} from "./style";
import { base_url } from "../../../utils/constants";
import SearchIconBlack from "../../../assets/icons/SearchIconBlack";
import ButtonWhite from "../../ButtonWhite/ButtonWhite";
import Minus from "../../../assets/icons/Minus";
import Plus from "../../../assets/icons/Plus";
import FilterSwitchIndustry from "../Components/FilterSwitchIndustry";
import { Activity, Industry } from "../CompanyProfile/CompanyProfileEdit";



interface IndustriesProps {
  industry: string[];
  industriesFetched:Industry[];
  keywords: string[];
  industryForChange: number;
  onSave: (industry: string, keywords: string[], nIndustry: number) => void;
}

const IndustryModal: React.FC<IndustriesProps> = ({
  industry,
  industriesFetched,
  keywords,
  industryForChange,
  onSave,
}) => {
  const [industries, setIndustries] = useState(industriesFetched);
  const [selectedIndustry, setSelectedIndustry] = useState<number | null>(null);
  const [selectedKeywords, setSelectedKeywords] = useState<number[]>([]);
  const [isChanged, setIsChanged] = useState(false);

  const [industrySearchText, setIndustrySearchText] = useState("");
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIndustrySearchText(e.target.value);
  };

 


  const toggleSubIndustries = (id: number) => {
    const updatedIndustries = industries.map((ind) =>
      ind.id === id ? { ...ind, isOpen: !ind.isOpen } : ind
    );
    setIndustries(updatedIndustries);
  };

  const handleIndustrySelect = (id: number) => {
    setSelectedIndustry(id);
    setSelectedKeywords([]);
    setIsChanged(true);
  };

  const handleKeywordSelect = (id: number) => {
    const newSelectedKeywords = [...selectedKeywords];
    const index = newSelectedKeywords.indexOf(id);
    if (index !== -1) {
      newSelectedKeywords.splice(index, 1);
    } else {
      newSelectedKeywords.push(id);
    }
    setSelectedKeywords(newSelectedKeywords);
    setIsChanged(true);
  };

  const handleSave = () => {
    if (selectedIndustry === null) return;

    const selectedIndustryObj = industries.find(
      (ind) => ind.id === selectedIndustry
    );
    if (!selectedIndustryObj) return;

    const selectedKeywordsNames = selectedIndustryObj.activities
      .filter((activity) => selectedKeywords.includes(activity.id))
      .map((activity) => activity.name);

    onSave(selectedIndustryObj.name, selectedKeywordsNames, industryForChange);
  };

  const defineStatus = (name: string) => {

    if (selectedIndustry) {
      const selectedIndustryObj = industries.find(
        (ind) => ind.id === selectedIndustry
      );
      if (selectedIndustryObj?.name === name) {
        return "selected";
      }
    }
    if (
      (industry.includes(name) || keywords.includes(name)) &&
      industry[industryForChange] !== name
    ) {
      return "other";
    }
    return "free";
  };

  const defineStatusKeyword = (activity: Activity)=>{
    if(selectedKeywords.includes(activity.id)){return "selected"}
    if(keywords.includes(activity.name)){return "other"}
    return "free"
  }

  const defineColor = (name: string) => {
    switch (name) {
      case "selected":
        return "#004C62";

      case "other":
        return "#A1A1A1";

      default:
        return "#03B62A";
    }
  };
useEffect(()=>{
setSelectedKeywords([]);
},[selectedIndustry])

useEffect(() => {
      const tempIndustryObj = industriesFetched.find(
        (ind) => ind.name === industry[industryForChange]
      );
      if (tempIndustryObj) {
        setSelectedIndustry(tempIndustryObj.id);
      }
}, []);

  return (
    <DialogContent>
      <TitleModal>Select your industries & categories</TitleModal>
      <InputWrapper>
        <SearchInputWrapper>
          <Input
            type="text"
            placeholder="Enter category"
            value={industrySearchText}
            onChange={handleInputChange}
          />
          <button>
            <SearchIconBlack />
          </button>
        </SearchInputWrapper>
      </InputWrapper>

      <IndustriesContainer>
        {industries.map((industry) => {
          const statusI = defineStatus(industry.name);
          const colorI = defineColor(statusI);
          return (
            <IndustryRow key={industry.id}>
              <IndustryItem>
                <ExpandButton onClick={() => toggleSubIndustries(industry.id)}>
                  {industry.isOpen ? (
                    <Minus color={colorI} />
                  ) : (
                    <Plus color={colorI} />
                  )}

                  <FilterSwitchIndustry
                    status={statusI}
                    onToggle={() => handleIndustrySelect(industry.id)}
                    isRating={false}
                  />
                </ExpandButton>
                <Label color={"#fff"}>{industry.name}</Label>
              </IndustryItem>
              {industry.isOpen &&
                industry.activities.map((activity) => (
                  <SubIndustryItem key={activity.id}>
                    <FilterSwitchIndustry
                      status={defineStatusKeyword(activity)
                      }
                      onToggle={() => handleKeywordSelect(activity.id)}
                      isRating={false}
                    />
                    <Label>{activity.name}</Label>
                  </SubIndustryItem>
                ))}
            </IndustryRow>
          );
        })}
      </IndustriesContainer>
      <ButtonWhite text={"Save"} onClick={handleSave} isSelected={isChanged} />
    </DialogContent>
  );
};

export default IndustryModal;
