import {Action, combineReducers, configureStore, ThunkAction} from "@reduxjs/toolkit";
import themesReducer from "./slices/sliceData";
import userSlice from "./slices/userSlice";
import internetSearchSlice from "./slices/internetSearchSlice";
import partnerBaseSearchSlice from "./slices/PartnerBaseSearch/partnerBaseSearchSlice";
import AssistantsSlice from "./slices/Assistants/AssistanseSlice";

const preloadedState = JSON.parse(localStorage.getItem('reduxState') ?? '{}');
const rootReducer = combineReducers({
  themes: themesReducer,
  user: userSlice,
  internetSearch: internetSearchSlice,
  partnerBaseSearch: partnerBaseSearchSlice,
  assistants: AssistantsSlice
});
export const store = configureStore({
  preloadedState,
  reducer: rootReducer,
});

store.subscribe(() => localStorage.setItem('reduxState', JSON.stringify(store.getState())));

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
