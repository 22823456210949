import styled from "styled-components";

export const StyledTitle = styled.h3`
  text-align: center;

  font-size: 24px;
  font-weight: 500;
  line-height: 140%;

  color: var(--txt-dark, #001731);
`;
