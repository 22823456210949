import React from "react";
import styled from "styled-components";
import ReturnButton from "../components/ReturnButton/ReturnButton";
import Dialogue from "../components/Dialogue/Dialogue";


const ButtonWrapper = styled.div`
  margin-bottom: 30px;
`;

const DialoguePage: React.FC = () => {
  return (
    <>
      <ButtonWrapper>
        <ReturnButton />
      </ButtonWrapper>
      <Dialogue />
      {/* <Fialogue/> */}
    </>
  );
};

export default DialoguePage;
