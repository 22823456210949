import React from "react";
import { Order, Status } from "../../../utils/types";
import {
  ArrivingDateButton,
  ArrowDownIconSpecial,
  CheckIconWrapper,
  ConfirmAcceptanceButton,
  ConnectAgentButton,
  ConnectAgentButtonWrapper,
  DialogCheckMark,
  DialogContent,
  DialogContentCompanyComponent,
  DialogContentDateComponent,
  DialogContentHeader,
  DialogContentLeftHeader,
  DialogContentValue,
  DialogContentWrapper,
  DialogContentWrapper1,
  DialogStatusContentWrapper,
  DialogStatusContentWrapperContainer,
  DialogStatusWrapper,
  RepeatOrderButton,
} from "./style";
import CheckIcon from "../../../assets/icons/CheckIcon";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  confirmAcceptanceOrderAsync,
  getAllOrdersAsync,
} from "../../../store/slices/userSlice";
import ArrowDownIcon from "../../../assets/icons/ArrowDownIcon";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../routes/routes";
interface OrderDetailsProps {
  order: Order;
  modalClose: () => void;
}
const OrderDetails: React.FC<OrderDetailsProps> = ({
  order,
  modalClose,
}: OrderDetailsProps) => {
  const dispatch = useAppDispatch();
  const token = useAppSelector((state) => state.user.userAccount!.token);
  const navigate = useNavigate();
  const statusName: { [key: string]: string } = {
    IN_PENDING: "Order in pending",
    CONFIRMED: "Order confirmed",
    DOCUMENTS_SIGNED: "Documents signed",
    MANUFACTURED: "Order manufactured",
    SENT: "Order send",
    RECEIVED_AND_ACCEPTED: "Order received & accepted",
  };

  const statusColors = [
    { statusName: "IN_PENDING", color: "#F9F9F9" },
    { statusName: "CONFIRMED", color: "#EAFFEF" },
    { statusName: "DOCUMENTS_SIGNED", color: "#CCFBD6" },
    { statusName: "MANUFACTURED", color: "#96EDA9" },
    { statusName: "SENT", color: "#69DD82" },
    { statusName: "RECEIVED_AND_ACCEPTED", color: "#3ACE5A" },
  ];

  function formatDate(dateString: string): string {
    const date = new Date(dateString);
    const day = date.getDate();
    const year = date.getFullYear();

    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const monthIndex = date.getMonth();
    const monthName = monthNames[monthIndex];

    return `${day} ${monthName} ${year}`;
  }
  const lastStatusType =
    order.processingStatuses[order.processingStatuses.length - 1].type;

  async function confirmAcceptanceHandler() {
    try {
      await dispatch(confirmAcceptanceOrderAsync(order.id));
      await dispatch(getAllOrdersAsync());
    } catch (error) {
      console.error("Error:", error);
    } finally {
      modalClose();
    }
  }

  async function connectAgentHandler() {
    //logic for navigation to chat with agent for this business request
    navigate(routes.AGENTS)
    modalClose();
  }

  async function repeatOrderHandler() {
    //logic for create new business request using order data
    modalClose();
  }

  return (
    <DialogContent>
      <DialogContentHeader>Manage your order</DialogContentHeader>

      <DialogContentWrapper>
        <DialogContentLeftHeader>{`Order number: `}</DialogContentLeftHeader>
        <DialogContentValue>{`№ FA-853-38433 - ${order.id}`}</DialogContentValue>
      </DialogContentWrapper>

      <DialogContentWrapper1>
        <DialogContentLeftHeader>{`Vendor: `}</DialogContentLeftHeader>
        <DialogContentValue>{order.name}</DialogContentValue>
      </DialogContentWrapper1>

      <DialogContentCompanyComponent>
        {order.processingStatuses.map((s, index) => (
          <DialogStatusWrapper key={index}>
            <DialogCheckMark
              backgroundColor={
                (
                  statusColors.find((sc) => sc.statusName === s.type) || {
                    color: "#fafafa",
                  }
                ).color
              }
              borderColor={"#03b62a"}
            >
              <CheckIconWrapper>
                <CheckIcon />
              </CheckIconWrapper>
            </DialogCheckMark>
            <DialogStatusContentWrapperContainer>
              <DialogStatusContentWrapper>
                {statusName[s.type]}
              </DialogStatusContentWrapper>
              <DialogContentDateComponent>
                {formatDate(s.dateStatus)}
              </DialogContentDateComponent>
            </DialogStatusContentWrapperContainer>
          </DialogStatusWrapper>
        ))}
        {lastStatusType === "SENT" ||
        lastStatusType === "RECEIVED_AND_ACCEPTED" ? null : (
          <ConnectAgentButtonWrapper>
            {" "}
            <ArrowDownIcon />
            <ConnectAgentButton onClick={connectAgentHandler}>
              Connect agent
            </ConnectAgentButton>{" "}
          </ConnectAgentButtonWrapper>
        )}

        {lastStatusType === "SENT" ? (
          <ArrowDownIconSpecial>
            {" "}
            <ArrowDownIcon />{" "}
          </ArrowDownIconSpecial>
        ) : null}

        {statusColors
          .slice(order.processingStatuses.length)
          .map((status, index) => (
            <DialogStatusWrapper key={status.statusName}>
              <DialogCheckMark
                backgroundColor={
                  lastStatusType === "SENT" ? "#3ACE5A" : "#FAFAFA"
                }
                borderColor={lastStatusType === "SENT" ? "#03b62a" : "#e8e8e8"}
              ></DialogCheckMark>
              <DialogStatusContentWrapper
                style={{
                  color: lastStatusType === "SENT" ? "#001731" : "#DADADA",
                }}
              >
                {statusName[status.statusName]}
              </DialogStatusContentWrapper>
            </DialogStatusWrapper>
          ))}
      </DialogContentCompanyComponent>

      {lastStatusType === "SENT" ? (
        <ArrivingDateButton>
          {" "}
          <DialogContentValue>Arriving</DialogContentValue>
          {order.dateArriving ? (
            <DialogContentValue>
              {formatDate(order.dateArriving)}
            </DialogContentValue>
          ) : null}
        </ArrivingDateButton>
      ) : null}

      {lastStatusType === "SENT" && order.status === Status.InProgress ? (
        <ConfirmAcceptanceButton onClick={confirmAcceptanceHandler}>
          Confirm acceptance
        </ConfirmAcceptanceButton>
      ) : null}

      {lastStatusType === "RECEIVED_AND_ACCEPTED" &&
      order.status === Status.Completed ? (
        <RepeatOrderButton onClick={repeatOrderHandler}>
          Repeat your order
        </RepeatOrderButton>
      ) : null}
    </DialogContent>
  );
};

export default OrderDetails;
