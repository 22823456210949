import React from 'react'

const ChevronLeftIcon: React.FC = () => {
  return (
    <svg width="9.000000" height="15.000000" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg" >
	<desc>
			Created with Pixso.
	</desc>
	<defs/>
	<path id="Vector" d="M7 13L1 7L7 1" stroke="#001731" strokeOpacity="1.000000" strokeWidth="2.000000" strokeLinejoin="round" strokeLinecap="round"/>
</svg>

  )
}

export default ChevronLeftIcon