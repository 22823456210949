import styled from "styled-components";

export const AvailabilitySwitch = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  gap: 10px;
`;

export const Paragraph = styled.p`
  color: var(--txt-dak-light, #143255);
  font-size: 17px;
  font-weight: 400;
  line-height: 140%;
  margin-left: 8px;
`;

export const UpParagraph = styled.p`
  color: var(--txt-dak-light, #143255);
  font-size: 17px;
  font-weight: 400;
  line-height: 140%;
`;

export const Quantity = styled.p`
  font-size: 17px;
  font-weight: 500;
  line-height: 140%;
  color: var(--txt-dark, #001731);
`;

export const SuccessRateTitle = styled.p`
  font-size: 17px;
  font-weight: 500;
  line-height: 140%;
  color: var(--txt-dark, #001731);
  margin: 0 0 20px 20px;
`;

export const AvailabilityWrapper = styled.div`
margin-left: 20px;
`;

export const IndustryKeqwordsP = styled.p`
  font-size: 17px;
  font-weight: 500;
  line-height: 140%;
  color: var(--txt-dark, #001731);
  margin-left: 20px;
  margin-bottom: 10px;
`;

export const OrderQuantity = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 20px 30px;
`;

export const LeftText = styled.div`
  flex: 1;
`;

export const SearchInputWrapper = styled.div`
  position: relative;
  margin-left: 20px;
  max-width: 368px;
  width: 100%;
  margin-bottom: 30px;
`;

export const ChevronRightWrapper = styled.div`
  position: absolute;
  right: 50px;
  bottom: 10px;
`;

export const CountrySearchWrapper = styled.div`
  position: relative;
  margin-left: 20px;
  max-width: 304px;
  width: 100%;
  padding: 0 14px 20px;
  border-radius: 16px;
  border: 1px solid var(--batton, #0095c0);
  background: var(--wiht, #fff);
  transition: all 0.3s ease;
`;

export const SearchIcon = styled.button`
  position: relative;
  left: 0px;
  top: 3px;
`;


export const SearchInputCountryList = styled.input`
  font-size: 17px;
  line-height: 130%;
  border-radius: 16px;
  background: #fff;
  color: var(--txt-dark, #001731);
  padding: 14px 0px 14px 0px;
  &::placeholder {
    font-size: 17px;
    line-height: 140%;
    color: var(--txt-grey-light, #dadada);
  }
`;