import styled from "styled-components";

export const SearchInputWrapper = styled.div`
  display: flex;
  align-items: center;
  max-width: 1316px;
  width: 100%;
`;

export const Input = styled.input`
  width: 100%;
  padding: 14px 0px 16px 20px;

  border-radius: 16px 0px 0px 16px;
  border: 1px solid var(--border, #bdbdbd);
  border-right: none;
  background: var(--with, #fff);
  &::placeholder {
    font-size: 17px;
    color: var(--txt-grey-light, #717880);
  }
`;

export const ButtonBlock = styled.div`
  max-height: 52px;
  height: 100%;
  padding: 12px 100px;

  border-radius: 0px 16px 16px 0px;
  background: var(--button, #0095c0);
  cursor: pointer;
`;

export const ButtonWrapper = styled.div`
  max-width: 204px;
`;
