import React, {ChangeEvent } from "react";
import CrossIcon from "../../../assets/icons/CrossIcon";
import {
  UploadFile,
  UploadButton,
  FileTittle,
  VectorWrapper,
  PhotoWrapper,
  PhotoId,
  CloseWrapper,
} from "./style";
import VectorIcon from "../../../assets/icons/VectorIcon";

interface UploadPhotoAgentProps {
  selectedFiles: File[];
  onFilesChange: (files: File[]) => void;
}

const UploadPhotoAgent: React.FC<UploadPhotoAgentProps> = ({  
  selectedFiles,
  onFilesChange, }) => {

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const files: FileList = event.target.files;
      const newFiles = [...selectedFiles, ...Array.from(files)];
      onFilesChange(newFiles);
    }
  };

  const handleRemoveFile = (index: number) => {
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);
    onFilesChange(updatedFiles);
  };

  return (
    <div>
      <UploadFile>
        <UploadButton onClick={() => document.getElementById("fileInputAgentConnect")?.click()}>
          Upload
        </UploadButton>
        photo or file
        <input
          type="file"
          id="fileInputAgentConnect"
          onChange={handleFileChange}
          multiple
          style={{ display: "none" }}
        />
      </UploadFile>
      {selectedFiles && selectedFiles.length > 0 && (
        <FileTittle>
          <PhotoWrapper>
            {Array.from(selectedFiles).map((file, index) => (
              <PhotoId key={index}>
                <VectorWrapper>
                  <VectorIcon />
                </VectorWrapper>
                {file.name}
                <CloseWrapper onClick={() => handleRemoveFile(index)}>
                  <CrossIcon />
                </CloseWrapper>
              </PhotoId>
            ))}
          </PhotoWrapper>
        </FileTittle>
      )}
    </div>
  );
};

export default UploadPhotoAgent;
