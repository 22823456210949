import React, {useState} from "react";
import {
    CloseUploadWrapper,
    Container,
    DocumentTooltip,
    DocumentWrapper,
    IconContainer,
    Item,
    List,
    MediaContainer,
    MediaUploadWrapper,
    ProofWrapper,
    Subtitle,
    Title,
    Tooltip,
    UploadDialogWrapper,
    VectorWrapper,
} from "./style";
import ShieldAlertIcon from "../../../assets/icons/ShieldAlertIcon";
import {removeAttachmentFromCustomerAsync, updateGeneralUserInfo,} from "../../../store/slices/userSlice";
import {UserInformation,} from "../../../utils/types";
import {useDispatch} from "react-redux";
import MediaUpload from "../../MediaUpload/MediaUpload";
import VectorIcon from "../../../assets/icons/VectorIcon";
import CrossIcon from "../../../assets/icons/CrossIcon";
import {useAppSelector} from "../../../store/hooks";
import {ButtonContainer, ButtonWrapper} from "../Information/style";
import {uploadFiles} from "../../../services/uploadFilesToServer";

const Documents: React.FC<
    UserInformation & {
    onSaveClick: () => void;
    onFormChange: React.Dispatch<
        React.SetStateAction<UserInformation | undefined>
    >;
    updateDocuments?: UserInformation;
    handleDiscardClick: () => void;
}
> = ({
         onSaveClick,
         attachments,
         onFormChange,
         updateDocuments,
         handleDiscardClick,
     }) => {
    const customer = useAppSelector((state) => state.user.userAccount!);
    const [showProofTooltip, setShowProofTooltip] = useState(false);
    const [showDocumentTooltip, setShowDocumentTooltip] = useState(false);
    const dispatch = useDispatch();
    const [filesToUpload, setFilesToUpload] = useState<File[]>([]);
    const [generalData, setGeneralData] = useState<UserInformation>(customer!);
    const token = useAppSelector((store) => store.user.userAccount?.token!);

    const handleAttachmentFilesChange = async (files: File[]) => {
        const saveFiles = await uploadFiles({files, dispatch});

        const updatedAttachment = [
            ...(generalData.attachments || []),
            ...saveFiles.payload.map((file: any) => ({id: file.id, ...file})),
        ];

        setGeneralData((prevState) => ({
            ...prevState,
            attachments: updatedAttachment,
        }));

        dispatch(
            updateGeneralUserInfo({
                ...updateDocuments,
                attachments: updatedAttachment,
            }) as any
        );
    };

    const handleRemoveClick = async (idToRemove: number, token: string) => {
        try {
            await dispatch(
                removeAttachmentFromCustomerAsync({id: idToRemove, token}) as any
            );
            if (generalData && generalData.attachments) {
                const updatedAttachments = generalData.attachments.filter(
                    (attachment) => attachment.id !== idToRemove
                );
                setGeneralData((prevState) => ({
                    ...prevState,
                    attachments: updatedAttachments,
                }));
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <Container>
            <DocumentWrapper>
                <Title>Documents</Title>
                <IconContainer
                    onMouseEnter={() => setShowDocumentTooltip(true)}
                    onMouseLeave={() => setShowDocumentTooltip(false)}
                >
                    <ShieldAlertIcon/>
                </IconContainer>
                {showDocumentTooltip && (
                    <DocumentTooltip>
                        Your documents are private. This information will only be used
                        internally for verification purposes to add credibility to your
                        account.
                    </DocumentTooltip>
                )}
            </DocumentWrapper>

            <MediaContainer>
                <ProofWrapper>
                    <Subtitle>Business registration certificate</Subtitle>
                    <IconContainer
                        onMouseEnter={() => setShowProofTooltip(true)}
                        onMouseLeave={() => setShowProofTooltip(false)}
                    >
                        <ShieldAlertIcon/>
                    </IconContainer>
                    {showProofTooltip && (
                        <Tooltip>ID card, passport or residence permit</Tooltip>
                    )}
                </ProofWrapper>
                <MediaUploadWrapper>
                    <UploadDialogWrapper>
                        <MediaUpload
                            selectedFiles={filesToUpload}
                            onFilesChange={(files) => handleAttachmentFilesChange(files)}
                        />
                    </UploadDialogWrapper>
                </MediaUploadWrapper>
            </MediaContainer>
            <List>
                {generalData.attachments?.map((attachment: any, index: number) => {
                    return (
                        <Item key={index}>
                            <VectorWrapper>
                                <VectorIcon/>
                            </VectorWrapper>
                            {attachment.name}
                            <CloseUploadWrapper
                                onClick={() => handleRemoveClick(attachment.id, token)}
                            >
                                <CrossIcon/>
                            </CloseUploadWrapper>
                        </Item>
                    );
                })}
            </List>
            <ButtonContainer>
                <ButtonWrapper>
                    {/* <ButtonWhite text={"Discard"} onClick={handleDiscardClick} /> */}
                    {/*<Button text={"Update"} onClick={onSaveClick} />*/}
                </ButtonWrapper>
            </ButtonContainer>
        </Container>
    );
};

export default Documents;