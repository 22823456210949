import styled from "styled-components";

export const StyledSourcingBrief = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 48%;
  height: 890px;
  position: relative;

  background: linear-gradient(
    46deg,
    rgba(5, 119, 152, 0.8) 8.94%,
    rgba(80, 58, 214, 0.7) 46.63%,
    rgba(3, 173, 112, 0.7) 88.48%
  );
  border-radius: 16px;
`;

export const TitleSourcingBrief = styled.h2`
  margin: 42px 0 120px;

  font-size: 36px;
  font-weight: 500;

  color: var(--txt-light-blue, #f4ffff);
`;

export const ButtonWrapper = styled.div`
  width: 400px;
`;

export const DescriptionStyle = styled.p`
  width: 75%;
  text-align: center;
  margin-bottom: 350px;

  font-size: 17px;
  font-weight: 500;
  line-height: 150%;
  color: var(--txt-light-blue, #f4ffff);
`;
