import {
  LeoBorderOne,
  LeoBorderTwo,
  LeoCoreOne,
  LeoCoreTwo,
  SpinnerBox,
  SpinnerBoxContainer
} from "./styles";

export const Loading = () => {
  return (
    <SpinnerBoxContainer>
      <SpinnerBox>
        <LeoBorderOne>
          <LeoCoreOne />
        </LeoBorderOne>
        <LeoBorderTwo>
          <LeoCoreTwo />
        </LeoBorderTwo>
      </SpinnerBox>
    </SpinnerBoxContainer>
  );
};
