import React, { useEffect } from "react";
import { RootState } from "../store/store";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../store/hooks";
import { routes } from "../routes/routes";
import { Title } from "../components/AgentProviderDelegateSearchPage/style";

const UnderConstruction: React.FC = () => {
  const user = useAppSelector((state: RootState) => state.user);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user.userAccount) {
      navigate(routes.HOME);
    }
  }, [user, navigate]);
  return <Title>Page - Under Construction</Title>;
};

export default UnderConstruction;
