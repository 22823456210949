import styled from "styled-components";

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  max-width: 700px;
  width: 100%;
  margin-bottom: 30px;
`;

export const SwitchButton = styled.button<{
  isActive?: boolean;
}>`
  display: flex;
  padding: 14px 30px 16px 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 16px;
  border: 1px solid var(--border, #979797);
  background: var(--with, #fff);

  color: var(--txt-dark, #001731);
  font-size: 17px;
  font-weight: 400;
  line-height: 140%;

  background-color: ${({ isActive }) =>
    isActive ? "var(--button, #0095C0)" : "var(--with, #FFF)"};
  color: ${({ isActive }) =>
    isActive ? " var(--with, #FFF)" : "var(--txt-dark, #001731)"};
`;

export const PageHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
`;

export const SearchInputWrapper = styled.div`
  display: flex;
  align-items: center;
  max-width: 778px;
  width: 100%;
  margin-right: 30px;
`;

export const Input = styled.input`
  width: 100%;
  padding: 12px 0px 12px 20px;
  max-width: 1276px;
  border-radius: 16px 0px 0px 16px;
  border: 1px solid var(--border, #bdbdbd);
  border-right: none;
  background: var(--with, #fff);
  &::placeholder {
    font-size: 17px;
    color: var(--txt-grey-light, #717880);
  }
`;

export const ButtonBlock = styled.div`
  max-height: 47px;
  height: 100%;
  max-width: 224px;
  width: 100%;
  padding: 12px 100px;
  border-radius: 0px 16px 16px 0px;
  background: var(--button, #0095c0);
  cursor: pointer;
`;

export const SortContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 20px;
  z-index: 1;
`;

export const SortWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 20;
  top: -37px;
  z-index: 2;
  width: max-content;
`;

export const SortButtonWrapper = styled.div`
position: relative;
`;

export const OrderStatusFilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
`;

export const ChevronRightSortWrapper = styled.div`
  position: absolute;
  right: 10px;
  bottom: 14px;
`;

export const ChevronDownSortWrapper = styled.div`
  position: absolute;
  right: -180px;
  top: 0;
`;

export const SortActiveWrapper = styled.div`
  border-radius: 16px;
  border: 1px solid var(--button, #0095c0);
  background: var(--with, #fff);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 14px 20px 16px 23px;
  flex-direction: column;
  width: max-content;
  position: relative;
`;

export const OrderStatusFilterButton = styled.button`
  color: var(--txt-dak-light, #143255);
  font-size: 17px;
  font-weight: 400;
  line-height: 140%;
  text-align: left;
  margin: 5px 0;
  &:hover {
    color: var(--txt-blue, #0095c0);
  }
`;

export const SortButton = styled.button`
  border-radius: 16px;
  border: 1px solid var(--border, #979797);
  background: var(--with, #fff);
  display: flex;
  padding: 14px 40px 16px 30px;
  gap: 10px;
  color: var(--txt-dark, #001731);
  font-size: 17px;
  font-weight: 500;
  line-height: 140%;
`;

export const SortActiveButton = styled.button`
  background: var(--with, #fff);
  color: var(--txt-dark, #001731);
  font-size: 17px;
  font-weight: 500;
  line-height: 140%;
  position: relative;
`;