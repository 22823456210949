import React, { useEffect, useState } from "react";
import { Order } from "../../../utils/types";
import {
  Avatar,
  BodyMessageLink,
  CompanyComponent,
  CompanyComponentKeyWords,
  CompanyList,
  CompanyWrapper,
  TextComponent,
  DefaultParagraph,
  DefaultWrapper,
  IndustryLocationWrapper,
  KeywordsFilter,
  KeywordsFilterWrapper,
  LocationIndustryButton,
  MarkButton,
  MarkParagraph,
  MoveContainer,
  MoveWrapper,
  NameBlock,
  NotInterestedButton,
  SelectedMessage,
  SelectedText,
  SortActiveButton,
  SortActiveWrapper,
  SubtitleFilter,
  SwitchWrapper,
  TypeComponent,
} from "./style";
import DefaultRequest from "../../../assets/icons/DefaultRequest";
import NotActiveFlag from "../../../assets/icons/NotActiveFlag";
import HoverFlag from "../../../assets/icons/HoverFlag";
import ActiveFlag from "../../../assets/icons/ActiveFlagIcon";
import FilterSwitch from "../../FilterSwitch/FilterSwitch";

import { PaginationBlock } from "../../ListPage/styles";
import ArrowLeftIcon from "../../../assets/icons/ArrowLeftIccon";
import ArrowRightIcon from "../../../assets/icons/ArrowRightIcon";
import Pagination from "../../Pagination/Pagination";
import { useAppSelector } from "../../../store/hooks";
import { defaultSelfman } from "../../../assets/img";

interface OrdersProps {
  ordersList: Order[];
  orderOnClick: (order: Order) => void;
}

interface HoveredStates {
  [key: string]: boolean;
}

interface ActiveStates {
  [key: string]: boolean;
}

const OrdersList: React.FC<OrdersProps> = ({ ordersList, orderOnClick }) => {
  const statusName: { [key: string]: string } = {
    IN_PENDING: "In pending",
    CONFIRMED: "Order confirmed",
    DOCUMENTS_SIGNED: "Documents signed",
    MANUFACTURED: "Order manufactured",
    SENT: "Order send",
    RECEIVED_AND_ACCEPTED: "Order received & accepted",
  };

  const statusColors = [
    { statusName: "IN_PROGRESS", color: "#F9F9F9" },
    { statusName: "CONFIRMED", color: "#EAFFEF" },
    { statusName: "DOCUMENTS_SIGNED", color: "#CCFBD6" },
    { statusName: "MANUFACTURED", color: "#96EDA9" },
    { statusName: "SENT", color: "#69DD82" },
    { statusName: "RECEIVED_AND_ACCEPTED", color: "#3ACE5A" },
  ];

  function formatDate(dateString: string): string {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const formattedDate = `${day < 10 ? "0" : ""}${day}/${
      month < 10 ? "0" : ""
    }${month}/${year}`;

    return formattedDate;
  }
  const [selectedCount, setSelectedCount] = useState(0);
  const [selectedMoveOption, setSelectedMoveOption] = useState("Move to");
  const [selectedMoveCategory, setSelectedMoveCategory] = useState<string>("");
  const [isMoveOpen, setIsMoveOpen] = useState(false);

  const handleMoveOptionToggle = () => {
    setIsMoveOpen(!isMoveOpen);
  };

  const [filterSwitchStates, setFilterSwitchStates] = useState<{
    [key: string]: boolean;
  }>({});

  // const handleClick = (button: string) => {
  //   setActiveButton(button);
  //   setSelectedMoveCategory(button);
  // };

  const handleMoveOptionSelect = (option: string) => {
    setSelectedMoveOption("Move to");
    setSelectedMoveCategory(option);
    //  setShowMoveOptions(false);
    //  setMoveDialogVisible(true);

    const selectedDialogIds = Object.keys(filterSwitchStates).filter(
      (key) => filterSwitchStates[key]
    );

    let dataToSend = {};

    switch (option) {
      case "Completed":
        dataToSend = {
          status: "COMPLETED",
          id: selectedDialogIds,
        };
        break;

      case "In progress":
        dataToSend = {
          status: "INPROGRESS",
          id: selectedDialogIds,
        };
        break;

      case "Cancelled":
        dataToSend = {
          status: "CANCELLED",
          id: selectedDialogIds,
        };
        break;

      default:
        break;
    }
  };

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const getCurrentPageOrders = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return ordersList.slice(startIndex, endIndex);
  };

  const [hoveredStates, setHoveredStates] = useState<HoveredStates>(
    ordersList.reduce((acc, request) => {
      acc[request.id.toString()] = false;
      return acc;
    }, {} as HoveredStates)
  );

  const [activeStates, setActiveStates] = useState<ActiveStates>(
    ordersList.reduce((acc, request) => {
      acc[request.id.toString()] = false;
      return acc;
    }, {} as ActiveStates)
  );

  const handleMouseEnter = (requestId: number) => {
    setHoveredStates((prevStates) => ({
      ...prevStates,
      [requestId.toString()]: true,
    }));
  };

  const handleMouseLeave = (requestId: number) => {
    setHoveredStates((prevStates) => ({
      ...prevStates,
      [requestId.toString()]: false,
    }));
  };

  const handleIconClick = (requestId: number) => {
    setActiveStates((prevStates) => ({
      ...prevStates,
      [requestId.toString()]: !prevStates[requestId.toString()],
    }));

    const selectedDialogIds = [requestId.toString()];

    let dataToSend = {};

    if (!activeStates[requestId.toString()]) {
      // setSelectedMarkOption("Mark as ");
      // setSelectedMarkCategory("Important");

      dataToSend = {
        favorite: selectedDialogIds,
      };
    }
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const totalPages = Math.ceil((ordersList.length || 0) / itemsPerPage);
  const goToNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  useEffect(() => {
    const newSelectedCount =
      Object.values(filterSwitchStates).filter(Boolean).length;
    setSelectedCount(newSelectedCount);
  }, [filterSwitchStates]);

  return (
    <>
      {/* {selectedCount > 0 && (
        <SelectedMessage>
          <SwitchWrapper>
            <FilterSwitch
              isActive={true}
              onToggle={() => {
                setFilterSwitchStates((prevStates) => {
                  const updatedStates = { ...prevStates };
                  const allSelected = Object.values(updatedStates).every(
                    (state) => state
                  );
                  if (allSelected) {
                    Object.keys(updatedStates).forEach((key) => {
                      updatedStates[key] = false;
                    });
                  } else {
                    Object.keys(updatedStates).forEach((key) => {
                      updatedStates[key] = key === "Selected Message";
                    });
                  }
                  const newSelectedCount =
                    Object.values(updatedStates).filter(Boolean).length;
                  setSelectedCount(allSelected ? 0 : newSelectedCount);
                  return updatedStates;
                });
              }}
            />
          </SwitchWrapper>
          <SelectedText> {selectedCount} selected</SelectedText>

          <MoveContainer>
            <MoveWrapper>
              {!isMoveOpen ? (
                <>
                  <div>
                    <MarkButton onClick={handleMoveOptionToggle}>
                      <MarkParagraph>{selectedMoveOption}</MarkParagraph>
                    </MarkButton>
                  </div>
                </>
              ) : (
                <SortActiveWrapper>
                  <SortActiveButton onClick={handleMoveOptionToggle}>
                    {selectedMoveOption}
                  </SortActiveButton>
                  <IndustryLocationWrapper>
                    <LocationIndustryButton
                      onClick={() => handleMoveOptionSelect("In progress")}
                    >
                      In progress
                    </LocationIndustryButton>
                    <LocationIndustryButton
                      onClick={() => handleMoveOptionSelect("Completed")}
                    >
                      Completed
                    </LocationIndustryButton>
                    <NotInterestedButton
                      onClick={() => handleMoveOptionSelect("Cancelled")}
                    >
                      Cancelled
                    </NotInterestedButton>
                  </IndustryLocationWrapper>
                </SortActiveWrapper>
              )}
            </MoveWrapper>
          </MoveContainer>
        </SelectedMessage>
      )} */}
      {ordersList && ordersList.length === 0 ? (
        <>
          <DefaultWrapper>
            <DefaultRequest />
          </DefaultWrapper>
          <DefaultParagraph>No data...</DefaultParagraph>
        </>
      ) : (
        <>
          <KeywordsFilterWrapper>
            <KeywordsFilter>
              <SubtitleFilter>Name</SubtitleFilter>
              <SubtitleFilter>Location</SubtitleFilter>
              <SubtitleFilter>Status</SubtitleFilter>
              <SubtitleFilter>Ordered</SubtitleFilter>
              <SubtitleFilter>Order number</SubtitleFilter>
              <SubtitleFilter>Amount</SubtitleFilter>
            </KeywordsFilter>
          </KeywordsFilterWrapper>

          <CompanyList>
            {ordersList &&
              getCurrentPageOrders().map((order) => (
                <CompanyWrapper
                  key={order.id}
                  onMouseEnter={() => handleMouseEnter(order.id)}
                  onMouseLeave={() => handleMouseLeave(order.id)}
                >
                  {/* <SwitchWrapper>
                    <FilterSwitch
                      isActive={filterSwitchStates[order.id]}
                      onToggle={() =>
                        setFilterSwitchStates((prevStates) => ({
                          ...prevStates,
                          [order.id]: !prevStates[order.id],
                        }))
                      }
                    />
                  </SwitchWrapper> */}
                  <BodyMessageLink onClick={() => orderOnClick(order)}>
                    <CompanyComponent>
                      <Avatar src={order.avatar || defaultSelfman} />
                    </CompanyComponent>
                    <NameBlock>
                      <CompanyComponent>
                        {order.name
                          ? order.name.replace(/([a-z])([A-Z])/g, "$1 $2")
                          : order.name}
                      </CompanyComponent>
                      <CompanyComponentKeyWords>
                        {order.industry.join(", ")}
                      </CompanyComponentKeyWords>
                    </NameBlock>

                    <TextComponent>
                      {`${order.country}, ${order.city}`}
                    </TextComponent>

                    {order.processingStatuses.length > 0 ? (
                      <TypeComponent
                        backgroundColor={
                          (
                            statusColors.find(
                              (s) =>
                                s.statusName ===
                                order.processingStatuses[
                                  order.processingStatuses.length - 1
                                ].type
                            ) || { color: "#fafafa" }
                          ).color
                        }
                      >
                        {order.processingStatuses[
                          order.processingStatuses.length - 1
                        ] &&
                          statusName[
                            order.processingStatuses[
                              order.processingStatuses.length - 1
                            ].type
                          ]}
                      </TypeComponent>
                    ) : (
                      <TypeComponent backgroundColor="#fafafa">
                        {"N/A"}
                      </TypeComponent>
                    )}

                    <CompanyComponent>
                      {formatDate(order.dateOrdered)}
                    </CompanyComponent>

                    <TextComponent>
                      {`№ FA-853-38433 - ${order.id}`}
                    </TextComponent>

                    <TextComponent>{order.amount}</TextComponent>
                  </BodyMessageLink>
                </CompanyWrapper>
              ))}
          </CompanyList>
          <PaginationBlock>
            <ArrowLeftIcon onClick={goToPreviousPage} />
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
            <ArrowRightIcon onClick={goToNextPage} />
          </PaginationBlock>
        </>
      )}
    </>
  );
};

export default OrdersList;
