import React, { useEffect, useState } from "react";
import FbIcon from "../../../assets/icons/FbIcon";
import InstIcon from "../../../assets/icons/InstIcon";
import LinInIcon from "../../../assets/icons/LinInIcon";
import SiteIcon from "../../../assets/icons/SiteIcon";
import { defaultAvatar } from "../../../assets/img";
import { CompanyById, allMarketplacesContentType } from "../../../utils/types";
import Button from "../../Button/Button";
import ButtonWhite from "../../ButtonWhite/ButtonWhite";
import ProductInfo from "../../ProductInfo/ProductInfo";
import SlidePhoto from "../../SlidePhoto/SlidePhoto";
import {
  Container,
  AboutWrapper,
  HeaderWrapper,
  Avatar,
  Title,
  AvailabilityWrapper,
  Label,
  Value,
  SocialMedia,
  KeywordsWrapper,
  Keyword,
  KeywordText,
  AboutBlockWrapper,
  About,
  ListWrapper,
  List,
  LinkSocial,
  IconWrapper,
  ListValue,
  Listspecial,
  ImageWrapper,
  ProductWrapper,
  ButtonWrapper,
  AvatarWrapper,
  TitleError,
  NameContainer,
  InfoElement,
  BlueDot,
  InfoHeader,
  InfoContent,
  InfoContainer,
  LogoHeader,
  LogoWrapper,
  LogoElement,
  ButtonAllProductWrapper,
  ItemStyle,
} from "./styles";
import { getCompanyByIdAsync } from "../../../store/slices/userSlice";
import { RootState } from "../../../store/store";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { parseDateString } from "../../Company/Company";
import { PhotosWrapper } from "../../Company/CompanyProfilePreview/styles";

const ProviderToConnect: React.FC = () => {
  const store = useAppSelector((state: RootState) => state.user.providerById);
  const token = useAppSelector((store) => store.user.userAccount!.token);
  const dispatch = useAppDispatch();
  const [provider, setProvider] = useState<CompanyById | null>(store);
  const [errorCode, setErrorCode] = useState<string | null>(null);

  const urlString = window.location.href;
  const urlParts = urlString.split("/");
  const lastPart = +urlParts[urlParts.length - 1];

  const marketplacesLogos = useAppSelector((store) => store.user.allMarketplaces) || [];

  useEffect(() => {
    dispatch(getCompanyByIdAsync({ id: lastPart, token: token }) as any)
      .then((result: any) => {
        if (result.payload.message === "404") {
          setErrorCode("User not found");
        }
      })
      .catch((error: any) => {
        console.error("Error fetching provider:", error);
      });
  }, [dispatch, lastPart]);

  

  useEffect(() => {
    setProvider(store);
  }, [store]); // ?? little delay of loading

  const formatAvailability = (availability: string) => {
    if (!availability) return "";
    return (
      availability.charAt(0).toUpperCase() +
      availability.slice(1).toLowerCase().split("_").join(" ")
    );
  };

  const textAbout = provider?.about ? provider.about.split("\n") : [];
  return (
    <>
      <Container>
        {errorCode && <TitleError>{errorCode}</TitleError>}
        {!errorCode && (
          <>
            <AboutWrapper>
              <HeaderWrapper>
                <AvatarWrapper>
                  <Avatar src={provider?.avatar || defaultAvatar} alt="logo" />
                  <NameContainer>
                    <Title>{provider?.companyName}</Title>
                    <AvailabilityWrapper>
                      <Label>Availability:</Label>
                      <Value>
                        {formatAvailability(provider?.availability || "")}
                      </Value>
                    </AvailabilityWrapper>
                  </NameContainer>
                </AvatarWrapper>
                <ButtonWrapper>
                  <Button text={"Connect"}></Button>
                  <ButtonWhite text={"Save"}></ButtonWhite>
                </ButtonWrapper>
              </HeaderWrapper>
              <InfoContainer>
                <InfoElement>
                  <BlueDot /> <InfoHeader>Joined SelfMan in:</InfoHeader>
                  <InfoContent>
                    {provider?.dateCreate
                      ? parseDateString(provider?.dateCreate)
                      : ""}
                  </InfoContent>
                </InfoElement>
                <InfoElement>
                  <BlueDot /> <InfoHeader>Founded:</InfoHeader>
                  <InfoContent>{provider?.founded}</InfoContent>
                </InfoElement>
                <InfoElement>
                  <BlueDot /> <InfoHeader>Business type:</InfoHeader>
                  <InfoContent>{provider?.businessType}</InfoContent>
                </InfoElement>
                <InfoElement>
                  <BlueDot /> <InfoHeader>Operational address:</InfoHeader>
                  <InfoContent>
                    {provider?.operationInfo?.address || ""}
                  </InfoContent>
                </InfoElement>
                <InfoElement>
                  <BlueDot /> <InfoHeader>Industry:</InfoHeader>
                  <InfoContent>{provider?.industry!.join(", ")}</InfoContent>
                </InfoElement>
                <InfoElement>
                  <BlueDot /> <InfoHeader>Number of employees:</InfoHeader>
                  <InfoContent>{provider?.numberOfEmployees}</InfoContent>
                </InfoElement>
              </InfoContainer>
              <Label>Keywords:</Label>
              <KeywordsWrapper>
                {provider?.keywords?.map((keyword, index) => (
                  <Keyword key={index}>
                    <KeywordText>{keyword}</KeywordText>
                  </Keyword>
                ))}
              </KeywordsWrapper>
              <AboutBlockWrapper>
                {textAbout.map((line, index) =>
                  line ? <About key={index}>{line}</About> : <br key={index} />
                )}
              </AboutBlockWrapper>
              {provider?.experienceMarketplace &&
                provider?.experienceMarketplace.length > 0 && (
                  <LogoHeader>Experience on marketplaces</LogoHeader>
                )}
              <LogoWrapper>
                {provider?.experienceMarketplace?.map((mp) => {
                  const logo = marketplacesLogos.find(
                    (item: allMarketplacesContentType) => item.name === mp
                  );
                  return logo ? (
                    <LogoElement key={mp} src={logo.icon} alt={mp} />
                  ) : null;
                })}
              </LogoWrapper>
              <ListWrapper>
                <List>
                  {[
                    {
                      label: "Languages:",
                      value: provider?.languages?.join(", "),
                    },
                    { label: "Shipping to:", value: provider?.shippingTo },
                    {
                      label: "Payment terms:",
                      value: provider?.paymentTerms?.join(", "),
                    },
                    {
                      label: "Sample:",
                      value: provider?.sample ? "Yes" : "No",
                    },
                  ].map((item, index) => (
                    <ItemStyle key={index}>
                      <IconWrapper>
                        <BlueDot />
                      </IconWrapper>
                      <Label>{item.label}</Label>
                      <ListValue>{item.value}</ListValue>
                    </ItemStyle>
                  ))}
                </List>
                <Listspecial>
                  {[
                    {
                      label: "Customization capacity:",
                      value: provider?.customizationCapacity ? "Yes" : "No",
                    },
                    {
                      label: "R&D capacity:",
                      value: provider?.rndCapacity ? "Yes" : "No",
                    },
                    {
                      label: "Special packing:",
                      value: provider?.specialPacking ? "Yes" : "No",
                    },
                    {
                      label: "White label:",
                      value: provider?.whiteLabel ? "Yes" : "No",
                    },
                    {
                      label: "Certification:",
                      value: provider?.certification ? "Yes" : "No",
                    },
                  ].map((item, index) => (
                    <ItemStyle key={index}>
                      <IconWrapper>
                        <BlueDot />
                      </IconWrapper>
                      <Label>{item.label}</Label>
                      <ListValue>{item.value}</ListValue>
                    </ItemStyle>
                  ))}
                </Listspecial>
              </ListWrapper>
              <SocialMedia>
                {provider?.website && (
                  <LinkSocial target="_blank" href={provider?.website}>
                    <SiteIcon />
                  </LinkSocial>
                )}
                {provider?.instagram && (
                  <LinkSocial target="_blank" href={provider?.instagram}>
                    <InstIcon />
                  </LinkSocial>
                )}
                {provider?.facebook && (
                  <LinkSocial target="_blank" href={provider?.facebook}>
                    <FbIcon />
                  </LinkSocial>
                )}
                {provider?.linkedIn && (
                  <LinkSocial target="_blank" href={provider?.linkedIn}>
                    <LinInIcon />
                  </LinkSocial>
                )}
              </SocialMedia>
            </AboutWrapper>
            <ImageWrapper>
              <PhotosWrapper>
                <SlidePhoto photos={provider?.photos || []} />
              </PhotosWrapper>
              {provider?.products &&
                provider?.products.filter((product) => product.published)
                  .length > 0 && (
                  <>
                    <ProductWrapper>
                      {provider?.products
                        .filter((product) => product.published)
                        .slice(0, 2)
                        .map((product, index) => (
                          <ProductInfo key={index} products={product}/>
                        ))}
                    </ProductWrapper>
                    <ButtonAllProductWrapper
                      to={`/partner_base-provider/${lastPart}/products`}
                    >
                      <Button text={"View all products"} />
                    </ButtonAllProductWrapper>
                  </>
                )}
            </ImageWrapper>
          </>
        )}
      </Container>
    </>
  );
};
export default ProviderToConnect;
