import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { RootState } from "../../../store/store";
import { AgentDetails } from "../../../utils/types";
import { Container, InputBlock, RequestBlock } from "./style";
import { ButtonBlock, Input, Tittle } from "../../ListPage/styles";
import SearchIcon from "../../../assets/icons/SearchIcon";
import { routes } from "../../../routes/routes";
import PartnerBasePopularRequest from "../../PartnerBasePage/PartnerBasePopularRequest/PartnerBasePopularRequest";


interface Props {
  searchValue: string;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
  filterValues: AgentDetails;
}

const AgentsSearch = ({ searchValue, setSearchValue, filterValues }: Props) => {
    const dispatch = useAppDispatch();
    const popularIndustry = ['Translation', ' Factory verification', 'Freight forwarder', 'Contract lawyer', 'Engineering'];
    // const popularIndustry = useAppSelector((state) => state.partnerBaseSearch.popularPartnerIndustry);
    const user = useAppSelector((state: RootState) => state.user);
    const currentPage = `/${window.location.pathname.split('/').pop()}`;
    
  
    useEffect(() => {
    //   dispatch(getPopularPartnerIndustryAsync());
    }, []);
  
    const handleSearchProviders = () => {
    //   if (searchValue !== '') {
    //     dispatch(searchProvidersAsync({credentials: filterValues, searchValue: [searchValue], token: user.userAccount?.token!}))
    //       .then((result: any) => {
    //         // if(result.payload.content && result.payload.content.length === 0){
    //         //   setResOfSearch('No search results');
    //         // }
    //       })
    //       .catch((error: any) => {
    //         console.error("error", error);
    //       });
    //   } else {
    //     dispatch(getAllProvidersAsync({credentials: filterValues, token: user.userAccount?.token!}));
    //   }
    };
  
    return (
      <Container>
        <InputBlock>
          <Input
            value={searchValue}
            type="text"
            placeholder="Search by product, industry or keywords"
            onChange={(e) => setSearchValue(e.target.value)}
          />
          <ButtonBlock onClick={handleSearchProviders} >
            <SearchIcon />
          </ButtonBlock>
        </InputBlock>
        <RequestBlock>
          <Tittle>Popular:</Tittle>
          {currentPage === routes.AGENTS && popularIndustry && popularIndustry.slice(0, 5).map((industry, key) => {
            return <PartnerBasePopularRequest key={key} name={industry} setSearchValue={setSearchValue} />
          })}
        </RequestBlock>
      </Container>
    );
}

export default AgentsSearch