import styled from "styled-components";


export const MainTitleWrapper = styled.div`
  display: flex;
  margin-bottom: 18px;
`;

export const CheckBox = styled.input`
  width: 16px;
  height: 16px;
  cursor: pointer;
`;

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 337.5px);
  gap: 30px;
  margin-bottom: 20px;
`;

export const InputWrapper = styled.div`
`;

export const Label = styled.label`
  margin-bottom: 2px;

  font-size: 17px;
  line-height: 140%;
  color: var(--txt-light-blue-dont-active, #a0b7d1);
`;

export const RedStar = styled.p`
  margin-right: 14px;

  font-size: 17px;
  font-weight: 400;
  line-height: 140%;

  color: var(--txt-red, #f00);
`;

export const Input = styled.input`
  width: 100%;
  width: 325px;

  padding: 6px 0 6px 10px;
  margin-bottom: 18px;

  font-size: 17px;
  line-height: 140%;

  color: var(--txt-dak-light, #143255);
  border: 1px solid var(--border, #bdbdbd);
  border-radius: 12px;
  &:focus {
    border: 1px solid var(--batton, #0095c0);
  }
  &::placeholder {
    color: var(--txt-grey-light, #717880);
    font-size: 17px;
    font-weight: 400;
    line-height: 140%;
  }
`;

export const TitleWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  margin-bottom: 4px;
`;

export const CheckBoxWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const CheckBoxLabel = styled.label`
  font-size: 17px;
  line-height: 140%;

  color: var(--txt-dark, #001731);
  cursor: pointer;
`;
