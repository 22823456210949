import React from "react";

const HoverFlag: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M2.66797 10C2.66797 10 3.33464 9.33334 5.33464 9.33334C7.33464 9.33334 8.66797 10.6667 10.668 10.6667C12.668 10.6667 13.3346 10 13.3346 10V2.00001C13.3346 2.00001 12.668 2.66668 10.668 2.66668C8.66797 2.66668 7.33464 1.33334 5.33464 1.33334C3.33464 1.33334 2.66797 2.00001 2.66797 2.00001V10Z"
        stroke="#001731"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.66797 14.6667V10"
        stroke="#001731"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default HoverFlag;
