import React from "react";

const NotActiveFlag: React.FC = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Icons/flag 32">
        <path
          id="Vector"
          d="M2.66797 9.99998C2.66797 9.99998 3.33464 9.33331 5.33464 9.33331C7.33464 9.33331 8.66797 10.6666 10.668 10.6666C12.668 10.6666 13.3346 9.99998 13.3346 9.99998V1.99998C13.3346 1.99998 12.668 2.66665 10.668 2.66665C8.66797 2.66665 7.33464 1.33331 5.33464 1.33331C3.33464 1.33331 2.66797 1.99998 2.66797 1.99998V9.99998Z"
          stroke="#bdbdbd"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M2.66797 14.6667V10"
          stroke="#bdbdbd"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default NotActiveFlag;
