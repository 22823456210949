import Company from "../components/Company/Company";
import { useAppSelector } from "../store/hooks";


const ProfileCompany: React.FC = () => {
  const user = useAppSelector(state=>state.user);
  return (
    <>
    {user.company !=null&& user.userAccount?.companyType ==="PROVIDER" &&<Company/>}
    
    </>
  );
};

export default ProfileCompany;
