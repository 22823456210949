import React, { useEffect, useState } from 'react'
import ComputerIcon from '../../../assets/icons/ComputerIcon'
import { ChangeButton, ChatWrapper, CreateText, CrossWhiteIconWrapper, DateRequest, DateUpdateRequest, Field, IconsWrapper, IconWrapper, InputAndButtonWrapper, NameAndIconsWrapper, Request, RequestCreate, SaveParagraph, SaveWrapper, WrapperRight } from './style'
import TrashIcon from '../../../assets/icons/TrashIcon'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { changeChatNameAsync, clearData, getAllMessagesInThreadAsync, newNameResponseType, setChatData } from '../../../store/slices/Assistants/AssistanseSlice'
import { ChatType } from '../../../utils/types'
import { useDispatch } from 'react-redux'
import PencilRenameIcon from '../../../assets/icons/PencilRenameIcon'
import CrossWhiteIcon from '../../../assets/icons/CrossWhireIcon'

interface Props {
    setShowDeleteModal: React.Dispatch<React.SetStateAction<boolean>>;
    setChatIdToDelete: React.Dispatch<React.SetStateAction<number>>;
    industryId: number;
    activityId: number;
    isCreatingNewChat: boolean;
}

const AllAssistantsList = ({ setShowDeleteModal, setChatIdToDelete, activityId, industryId, isCreatingNewChat }: Props) => {
    const appDispatch = useAppDispatch();
    const dispatch = useDispatch();
    const chats: ChatType[] = useAppSelector((state) => state.assistants.chats);
    const assistantData = useAppSelector((state) => state.assistants);
    const [showSendMessage, setShowSendMessage] = useState(false);

    const [isRename, setIsRename] = useState<number | null>(null);
    const [chatName, setChatName] = useState('');
    const maxNameLength = 25;

    const formatDate = (isoString: string): string => {
        return new Date(isoString)
            .toLocaleDateString('ru-RU', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
            });
    };

    const handleChatClick = (assistantId: string, threadId: string, chatId: number) => {
        setIsRename(-1);
        dispatch(clearData([]));

        // Set the IDs in Redux for the conversation.
        dispatch(setChatData({ assistantId, threadId, chatId }));

        appDispatch(getAllMessagesInThreadAsync(threadId));
    };

    const handleKeyDown = async (e: React.KeyboardEvent<HTMLInputElement>, chatId: number) => {
        if (e.key === 'Enter' && !e.ctrlKey) {
            e.preventDefault(); // Prevent the default behavior of Enter key
            const res = await appDispatch(changeChatNameAsync({ chatId, newChatName: chatName }));

            const payload = res.payload as { data: newNameResponseType, status: number };
        
            if (payload && payload.status === 200) {
                setShowSendMessage((prevShowSendMessage) => !prevShowSendMessage);
                setTimeout(() => {
                    setShowSendMessage((prevShowSendMessage) => !prevShowSendMessage);
                }, 3000);
            }

            setChatName('');
            setIsRename(-1);
        }
    };

    const handleDeleteChatClick = (e: React.MouseEvent, chatIdToDelete: number) => {
        e.stopPropagation();
        setChatIdToDelete(chatIdToDelete);
        setShowDeleteModal(true);
    };

    // click on PencilRenameIcon
    const handleRenameChatClick = (e: React.MouseEvent, chatId: number) => {
        const currentChat = chats?.find(chat => chat.id === chatId);
        setChatName(currentChat?.name!);
        e.stopPropagation();
        setIsRename(chatId);
    };

    // click on ChangeButton for change name
    const handleChangeNameClick = async (e: React.MouseEvent, chatId: number) => {
        e.stopPropagation();
        const res = await appDispatch(changeChatNameAsync({ chatId, newChatName: chatName }));

        const payload = res.payload as { data: newNameResponseType, status: number };
        
        if (payload && payload.status === 200) {
            setShowSendMessage((prevShowSendMessage) => !prevShowSendMessage);
            setTimeout(() => {
                setShowSendMessage((prevShowSendMessage) => !prevShowSendMessage);
            }, 3000);
        }

        setChatName('');
        setIsRename(-1);
    };

    // Set the new name in the variable while checking the length.
    const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        // if (value.length <= maxNameLength) {
            setChatName(value);
        // }
    };

    // Opening the most recent chat from the existing ones if there is no data about the last chat that had communication
    useEffect(() => {
        if (industryId === -1 && activityId === -1) {
            handleChatClick(chats[0].assistantId, chats[0].threadId, chats[0].id);
        }
    }, []);

    // Closing the input for a new chat name when clicking on free space on the page.
    useEffect(() => {
        const handleClickOutside = () => isRename !== -1 && setIsRename(-1);
        document.addEventListener('click', handleClickOutside);
        return () => document.removeEventListener('click', handleClickOutside);
    }, [isRename]);

    return (
        <WrapperRight disabled={isCreatingNewChat} >

            <RequestCreate disabled={isCreatingNewChat} >
                <CreateText>Create a request</CreateText>
                <IconWrapper>
                    <ComputerIcon />
                </IconWrapper>
            </RequestCreate>

            {chats.map((chat, index) => {
                return <ChatWrapper
                    key={chat.id}
                    $isRename={isRename === chat.id}
                    disabled={isCreatingNewChat}
                    $isActive={chat.id === assistantData.chatId}
                    onClick={(isCreatingNewChat || isRename !== -1) ? undefined : () => handleChatClick(chat.assistantId, chat.threadId, chat.id)}>
                    <DateRequest>{`Created: ${formatDate(chat.created_at)}`}</DateRequest>
                    <DateUpdateRequest>{`Updated: ${formatDate(chat.changed_at)}`}</DateUpdateRequest>

                    <NameAndIconsWrapper>
                        {isRename === chat.id ? (
                            <InputAndButtonWrapper onClick={e => e.stopPropagation()} >
                                <Field
                                    placeholder="Enter new chat name"
                                    type="text"
                                    value={chatName}
                                    onChange={handleChangeName}
                                    onClick={e => e.stopPropagation()}
                                    maxLength={maxNameLength}
                                    onKeyDown={(e) => handleKeyDown(e, chat.id)}
                                />
                                <ChangeButton
                                    onClick={(e) => handleChangeNameClick(e, chat.id)}
                                >
                                    Change
                                </ChangeButton>
                            </InputAndButtonWrapper>
                        ) : (
                            <>
                                <Request disabled={isCreatingNewChat}>
                                    {chat.name}
                                </Request>
                                <IconsWrapper>
                                    {!isCreatingNewChat && (
                                        <IconWrapper onClick={(e) => handleRenameChatClick(e, chat.id)}>
                                            <PencilRenameIcon />
                                        </IconWrapper>
                                    )}
                                    {!isCreatingNewChat && (
                                        <IconWrapper onClick={(e) => handleDeleteChatClick(e, chat.id)}>
                                            <TrashIcon />
                                        </IconWrapper>
                                    )}
                                </IconsWrapper>
                            </>
                        )}
                    </NameAndIconsWrapper>
                </ChatWrapper>
            })}

            {showSendMessage && (
                <SaveWrapper>
                    <SaveParagraph>
                        Chat name has been changed successfully.
                    </SaveParagraph>
                    <CrossWhiteIconWrapper
                        onClick={() => setShowSendMessage(!showSendMessage)}
                    >
                        <CrossWhiteIcon />
                    </CrossWhiteIconWrapper>
                </SaveWrapper>
            )}

        </WrapperRight>
    )
}

export default AllAssistantsList