import React from "react";
import styled from "styled-components";

interface RadioButtonProps {
  title: string;
  initialValue: boolean;
  setValue: (key: string, value: boolean) => void;
}

const RadioWrapper = styled.div`
  display: flex;
`;
const Title = styled.p`
  margin-right: 40px;
  margin-bottom: 10px;
`;
const RadioLabel = styled.label`
  position: relative;
  cursor: pointer;
`;

const RadioInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;

  &:checked ~ span:after {
    display: block;
  }
`;

interface RadioCheckmarkProps {
  checked: boolean;
}
const RadioCheckmarkWrapper = styled.div`
  width: 30px;
`;
const RadioCheckmark = styled.span<RadioCheckmarkProps>`
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: ${(props) => (props.checked ? "#0095C0" : "#fff")};
  border: 1px solid #004c62;

  border-radius: 50%;

  &:after {
    content: "";
    position: absolute;
    display: none;
    top: 4px;
    left: 4px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #fafafa;
  }
`;

const RadioButtonYesNo: React.FC<RadioButtonProps> = ({
  title,
  initialValue,
  setValue,
}) => {
  return (
    <RadioWrapper>
      <RadioLabel>
        <RadioCheckmarkWrapper></RadioCheckmarkWrapper>
        <RadioInput
          type="radio"
          value="No"
          checked={!initialValue}
          onChange={() => setValue(title, false)}
        />
        <RadioCheckmark checked={!initialValue} />
      </RadioLabel>
      <Title>No</Title>

      <RadioLabel>
        <RadioCheckmarkWrapper></RadioCheckmarkWrapper>
        <RadioInput
          type="radio"
          value="Yes"
          checked={initialValue}
          onChange={() => setValue(title, true)}
        />
        <RadioCheckmark checked={initialValue} />
      </RadioLabel>
      <Title>Yes</Title>
    </RadioWrapper>
  );
};

export default RadioButtonYesNo;
