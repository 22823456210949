import styled from "styled-components";

interface IButtonProps {
  $isEditing?: boolean;
  disabled?: boolean;
  $isActive?: boolean;
}

export const StyledButton = styled.button<IButtonProps>`
  width: 100%;
  padding: 14px 30px 16px 30px;

  font-size: 17px;
  line-height: 140%;

  border-radius: 16px;

  background: ${(props) =>
    props.disabled ? "grey" : "var(--batton, #0095c0)"};
  background: ${(props) =>
    props.$isActive ? "var(--green, #03b62a)" : "var(--batton, #0095c0)"};
  color: var(--txt-light-blue, #f4ffff);
  &:hover {
    background: ${(props) =>
      props.disabled
        ? "grey"
        : "var(--hover, linear-gradient(254deg, #0095c0 17.54%, rgba(3, 182, 42, 0.6) 90.58%))"};

    &:active {
      background: ${(props) =>
        props.disabled ? "grey" : "var(--green, #03b62a)"};
    }
    &:disabled {
      background: grey;
      cursor: not-allowed;
    }
  }
`;
