import React from 'react'

const CheckIcon = () => {
  return (
    <svg width="16.000000" height="16.000000" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" >
	<desc>
			Created with Pixso.
	</desc>
	<defs/>
	<path id="✓" d="M3.47 16C3.47 15.81 3.45 15.72 3.36 15.72L2.85 15.95C2.85 15.85 2.79 15.79 2.68 15.75L2.5 15.72C2.35 15.72 2.28 15.75 2.07 15.87C2 15.75 1.91 15.6 1.84 15.48C1.27 14.44 0.68 12.88 0.41 12.19C0.28 11.84 0.15 11.11 0 10.01C0.17 10.11 0.3 10.16 0.37 10.16C0.46 10.16 0.57 10.01 0.66 9.72C0.7 9.78 0.79 9.8 0.9 9.8C0.96 9.8 1.05 9.78 1.1 9.72L1.45 9.22L1.84 9.35L1.87 9.35C1.91 9.35 1.98 9.28 2.08 9.22C2.19 9.16 2.28 9.12 2.35 9.12L2.42 9.14C2.77 9.3 3.01 9.59 3.12 10.05C3.39 11.11 3.63 11.63 3.91 11.63C4.16 11.63 4.48 11.36 4.84 10.84C5.19 10.32 5.54 9.62 5.94 8.76C5.96 8.93 5.98 9.01 6.03 9.01C6.16 9.01 6.49 8.29 7.3 7.06C8.51 5.21 11.51 1.66 12.28 1.16C12.85 0.78 13.29 0.43 13.6 0.12C13.55 0.33 13.51 0.47 13.51 0.54C13.51 0.6 13.55 0.62 13.6 0.62L14.21 0.33L14.21 0.41C14.21 0.51 14.23 0.58 14.3 0.58C14.39 0.58 14.74 0.24 14.78 0.12L14.74 0.41L15.49 0L15.31 0.37C15.53 0.22 15.71 0.14 15.82 0.14C15.93 0.14 16 0.31 16 0.41C16 0.58 15.84 0.81 15.6 1.1C15.33 1.43 14.67 2.07 12.69 4.21C11.83 5.13 8.03 10.01 7.3 11.17L5.94 13.36C5.34 14.29 4.97 14.89 4.77 15.12C4.57 15.35 4.33 15.58 4.05 15.79L3.85 15.68L3.67 15.79L3.47 16Z" fill="#00390D" fillOpacity="1.000000" fill-rule="evenodd"/>
</svg>

  )
}

export default CheckIcon