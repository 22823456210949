import styled from "styled-components";

export const Container = styled.div`
  max-width: 765px;
  width: 100%;
  margin-left: 30px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  gap: 30px;
`;

export const BlockWrapper = styled.div`
  margin-bottom: 30px;
`;

export const Tittle = styled.p`
  margin: 40px 0 20px 0;
`;

export const TitleText = styled.h3`
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: 500;
  line-height: 140%;

  color: var(--txt-dak-light, #143255);
`;

export const SubtitleText = styled.div`
  margin-bottom: 20px;

  font-size: 17px;
  line-height: 140%;
  color: var(--txt-dak-light, #143255);
`;

export const DeliverySubtitleText = styled(SubtitleText)`
  margin-bottom: 0;
`;

export const TextArea = styled.textarea`
  max-width: 725px;
  width: 100%;
  padding: 20px;
  margin-bottom: 30px;

  border-radius: 16px;
  border: 1px solid var(--border, #bdbdbd);
  background: var(--wiht, #fff);

  resize: none;
  &::placeholder {
    color: var(--txt-grey-light, #717880);
    font-size: 17px;
    font-weight: 400;
    line-height: 140%;
  }
  &:focus {
    border: 1px solid var(--batton, #0095c0);
  }
`;
export const TextAreaStandarts = styled(TextArea)`
  margin-bottom: 0px;
`;

export const MediaUploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export const MediaUploadWrapper = styled.div``;

export const UploadDialogWrapper = styled.div``;

export const List = styled.ul`
  display: flex;
  flex-direction: column;
`;

export const VectorWrapper = styled.div`
  width: 18px;
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 5px 4px;

  border-radius: 16px;
  background-color: var(--green, #03b62a);
`;

export const CloseUploadWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const NumberInput = styled.input`
  max-width: 200px;
  width: 100%;

  text-align: center;
  margin-bottom: 20px;
  padding: 8px 10px;

  border-radius: 16px;
  border: 1px solid var(--border, #bdbdbd);
  background: var(--wiht, #fff);
  &::placeholder {
    font-size: 17px;
    font-weight: 400;
    line-height: 140%;
    text-align: center;
    color: var(--txt-grey-light, #717880);
  }
  &:focus {
    border: 1px solid var(--batton, #0095c0);
  }
`;

export const DateInput = styled.input`
  width: 100%;

  text-align: center;
  cursor: pointer;
`;

export const DateBlock = styled.div`
  max-width: 352px;
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px 10px;

  border-radius: 16px;
  border: 1px solid var(--border, #bdbdbd);
  background: var(--wiht, #fff);

  &:focus {
    border: 1px solid var(--batton, #0095c0);
  }
  cursor: pointer;
`;

export const InputWrapper = styled.div`
  position: relative;
  margin-bottom: 18px;
`;

export const Input = styled.input`
  max-width: 755px;
  width: 100%;

  padding: 6px 0 6px 10px;

  font-size: 17px;
  line-height: 140%;

  color: var(--txt-dak-light, #143255);
  border: 1px solid var(--border, #bdbdbd);
  border-radius: 12px;
  cursor: pointer;
  &:focus {
    border: 1px solid var(--batton, #0095c0);
  }
  &::placeholder {
    color: var(--txt-grey-light, #717880);
    font-size: 17px;
    font-weight: 400;
    line-height: 140%;
  }
`;

export const InputAddress = styled(Input)`
  cursor: auto;
`;

export const InputBrifName = styled(Input)`
  cursor: auto;
  max-width: none;
  width: auto;
  margin-bottom: 20px;
`;

export const ChevronRightSettingsWrapper = styled.div`
  position: absolute;
  right: 6px;
  top: 7px;
`;

export const ItemWrapper = styled.div`
  width: 100%;

  padding: 6px 0 6px 10px;

  font-size: 17px;
  line-height: 140%;

  color: var(--txt-dak-light, #143255);
  border: 1px solid var(--border, #bdbdbd);
  border-top: none;
  border-radius: 12px;
  cursor: pointer;
  &:focus {
    border: 1px solid var(--batton, #0095c0);
  }
  &::placeholder {
    color: var(--txt-grey-light, #717880);
    font-size: 17px;
    font-weight: 400;
    line-height: 140%;
  }
`;

export const Item = styled.li`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 0px;
  &:hover {
    color: var(--txt-blue, #0095c0);
  }
`;

export const ItemName = styled.h4`
  font-size: 17px;
  line-height: 140%;

  color: var(--txt-dak-light, #143255);
  cursor: pointer;
  &:hover {
    color: var(--txt-blue, #0095c0);
  }
`;

export const OrderWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  margin-bottom: 20px;
  gap: 20px;
`;

export const Switch = styled.div`
  display: flex;
  max-width: 300px;
  max-height: 36px;
  justify-content: center;
  align-items: center;

  padding: 8px 20px 10px 20px;
  gap: 10px;

  border-radius: 16px;
  border: 1px solid var(--battn-border, #004c62);
  background: var(--wiht, #fff);
  cursor: pointer;
`;

export const DateOrder = styled.h5`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LeadWrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
`;

export const ShipmentWrapper = styled(LeadWrapper)`
  margin-bottom: 20px;
`;

export const HelpDeliveryWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  margin-bottom: 20px;
`;

export const DeliveryWrapper = styled.div`
  display: flex;
`;

export const SubSwitchWrapper = styled.div`
  display: flex;
  gap: 8px;
  margin-right: 20px;
`;

export const ImageTooltip = styled.img`
  width: 30px;
  height: 30px;
`;

export const SendWrapper = styled.div`
  display: flex;
  max-width: 480px;
  width: 100%;
  gap: 10px;
  margin: 0 auto 20px;
`;

export const ModalField = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
`;

export const DialogContent = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;

  max-width: 510px;
  width: 100%;

  display: flex;
  flex-direction: column;

  transform: translate(-50%, -50%);

  border-radius: 16px;
  background: var(--wiht, #fff);
  padding: 30px 30px 40px;
  z-index: 2;
`;

export const CloseWrapper = styled.div`
  position: absolute;
  right: 20.5px;

  cursor: pointer;
`;

export const Text = styled.h3`
  text-align: center;
  margin-bottom: 20px;

  font-size: 24px;
  font-weight: 500;
  line-height: 140%;

  color: var(--txt-dark, #001731);
`;

export const UploadWrapper = styled.div``;


export const IconWrapper = styled.div`
  width: 27px;
  height: 27px;
`;
