import React, {useState } from "react";
import {
  AvailabilityWrapper,
  Dash,
  IndustryKeqwordsP,
  InputWrapper,
  PriceInput,
  SliderWrapper,
  StyledSlider,
} from "./style";
import "rc-slider/assets/index.css";
import { AvailabilitySwitch, Paragraph } from "../ManufactoringFilter/style";
import DelegateSwitch from "../../DelegateSwitch/DelegateSwitch";
import { PartnerBaseProvider, ProviderDetails } from "../../../utils/types";

interface FinancialDetailsFilterProps {
  companyItems: PartnerBaseProvider[];
  filterValues: ProviderDetails;
  onFilterChange: (filterValues: ProviderDetails) => void;
  setFilterValues: React.Dispatch<React.SetStateAction<ProviderDetails>>;
}

const FinancicalDetailsFilter: React.FC<FinancialDetailsFilterProps> = ({
  companyItems,
  setFilterValues,
  filterValues,
  onFilterChange,
}) => {
  const [searchText, setSearchText] = useState("");

  // useEffect(() => {
  //   updateFilter();
  // }, [filterValues]);

  // const updateFilter = () => {
  //   const filters: ProviderDetails = {
  //     minPrice: filterValues?.minPrice,
  //     maxPrice: filterValues?.maxPrice,
  //     milestonePayment: filterValues?.milestonePayment,
  //     partialPayment: filterValues?.partialPayment,
  //     deferredPayment: filterValues?.deferredPayment,
  //     letterCredit: filterValues?.letterCredit,
  //     discountPayment: filterValues?.discountPayment,
  //     flexiblePayment: filterValues?.flexiblePayment,
  //   };
  //   onFilterChange(filters);
  // };

  const prices = companyItems
    .flatMap((company) =>
      company.products.map((product) => parseFloat(product.price.toString()))
    )
    .filter((price) =>
      price.toString().toLowerCase().includes(searchText.toLowerCase())
    );

  filterValues?.minPrice ||
    (filterValues?.maxPrice === undefined &&
      setFilterValues({
        minPrice: Math.min(...prices) < 0 ? 0 : Math.min(...prices),
        maxPrice: Math.max(...prices),
      }));

  return (
    <div>
      <IndustryKeqwordsP>Unit price</IndustryKeqwordsP>

      <SliderWrapper>
        <StyledSlider
          range
          min={0}
          max={1000}
          value={[filterValues?.minPrice || 0, filterValues?.maxPrice || 1000]}
          onChange={(rangeValues) => {
            const [minPrice, maxPrice] = Array.isArray(rangeValues)
              ? rangeValues
              : [rangeValues, rangeValues];
            setFilterValues({
              ...filterValues,
              minPrice: minPrice,
              maxPrice: maxPrice,
            });
          }}
        />

        <InputWrapper>
          <PriceInput
            type="text"
            value={`${filterValues?.minPrice}` + "$"}
            onChange={(e) => {
              const numericValue = Number(
                e.target.value.replace(/[^\d.]/g, "")
              );
              setFilterValues({ ...filterValues, minPrice: numericValue });
            }}
          />
          <Dash>—</Dash>
          <PriceInput
            type="text"
            value={` ${filterValues?.maxPrice}` + "$"}
            onChange={(e) => {
              const numericValue = Number(
                e.target.value.replace(/[^\d.]/g, "")
              );
              setFilterValues({ ...filterValues, maxPrice: numericValue });
            }}
          />
        </InputWrapper>
      </SliderWrapper>
      <IndustryKeqwordsP>Payment terms</IndustryKeqwordsP>
      <AvailabilityWrapper>
        <AvailabilitySwitch>
          <DelegateSwitch
            isActive={filterValues?.milestonePayment || false}
            onToggle={(e) =>
              setFilterValues({
                ...filterValues,
                milestonePayment: !filterValues?.milestonePayment,
              })
            }
          />
          <Paragraph>Milestone payment</Paragraph>
        </AvailabilitySwitch>
        <AvailabilitySwitch>
          <DelegateSwitch
            isActive={filterValues?.partialPayment || false}
            onToggle={(e) =>
              setFilterValues({
                ...filterValues,
                partialPayment: !filterValues?.partialPayment,
              })
            }
          />
          <Paragraph>Partial payments</Paragraph>
        </AvailabilitySwitch>
        <AvailabilitySwitch>
          <DelegateSwitch
            isActive={filterValues?.deferredPayment || false}
            onToggle={(e) =>
              setFilterValues({
                ...filterValues,
                deferredPayment: !filterValues?.deferredPayment,
              })
            }
          />
          <Paragraph>Deferred payment</Paragraph>
        </AvailabilitySwitch>
        <AvailabilitySwitch>
          <DelegateSwitch
            isActive={filterValues?.letterCredit || false}
            onToggle={(e) =>
              setFilterValues({
                ...filterValues,
                letterCredit: !filterValues?.letterCredit,
              })
            }
          />
          <Paragraph>Letter of credit</Paragraph>
        </AvailabilitySwitch>
        <AvailabilitySwitch>
          <DelegateSwitch
            isActive={filterValues?.discountPayment || false}
            onToggle={(e) =>
              setFilterValues({
                ...filterValues,
                discountPayment: !filterValues?.discountPayment,
              })
            }
          />
          <Paragraph>Discounts for upfront payment</Paragraph>
        </AvailabilitySwitch>
        <AvailabilitySwitch>
          <DelegateSwitch
            isActive={filterValues?.flexiblePayment || false}
            onToggle={(e) =>
              setFilterValues({
                ...filterValues,
                flexiblePayment: !filterValues?.flexiblePayment,
              })
            }
          />
          <Paragraph>Flexible</Paragraph>
        </AvailabilitySwitch>
      </AvailabilityWrapper>
    </div>
  );
};

export default FinancicalDetailsFilter;
