import React, { useState } from "react";
import {
    ButtonWrapper,
  Container,
  CustomQuestion,
  CustomWrapper,
  HeaderTittle,
  QuestionWrapper,
  SecondTittle,
  Subtitle,
  Tittle,
} from "./style";
import { BusinessMatchmaking } from "../../utils/types";
import DelegateSwitch from "../DelegateSwitch/DelegateSwitch";
import Button from "../Button/Button";
import ModalSuccessfullyChange from "../ModalWindows/ModalSuccessfullyChange/ModalSuccessfullyChange";
import { useNavigate } from "react-router-dom";
import { routes } from "../../routes/routes";

const ProfileBusinessMatchmaking = () => {
  const [businessMatchmaking, setBusinessMatchmaking] =
    useState<BusinessMatchmaking>({
      overview: false,
      productSpecification: false,
      price: false,
      paymentTerms: false,
      numberOfProducts: false,
      oftenOrder: false,
      leadTime: false,
      help: false,
      date: false,
      place: false,
      qualityStandards: false,
      certificates: false,
      specificPackaging: false,
      specificLabeling: false,
      communicate: false,
      customQuestion1: false,
      customQuestion2: false,
      customQuestion3: false,
      customQuestion1Text: "",
      customQuestion2Text: "",
      customQuestion3Text: "",
    });
    const [isSaveClicked, setIsSaveClicked] = useState(false);
    const navigate = useNavigate();

  const toggleCheckbox = (key: keyof BusinessMatchmaking) => {
    setBusinessMatchmaking((prevBusinessMatchmaking) => ({
      ...prevBusinessMatchmaking,
      [key]: !prevBusinessMatchmaking[key],
    }));
  };

  const handleSaveClick = () => {
    setIsSaveClicked(true);
  }
  const handleIsChanged = () => {
    setIsSaveClicked(false);
  }

  return (
    <Container>
      <HeaderTittle>
        Please choose the questions from the list below that you would like
        customers to answer when sending you a business request.
      </HeaderTittle>
      <SecondTittle>
        Additionally, you have an option to add up to 3 customized questions
        that are critical to you:
      </SecondTittle>
      <Tittle>Product details:</Tittle>
      <QuestionWrapper>
        <DelegateSwitch
          isActive={businessMatchmaking.overview}
          onToggle={() => toggleCheckbox("overview")}
        />
        <Subtitle>
          Provide a detailed overview of the product you want to manufacture
        </Subtitle>
      </QuestionWrapper>
      <QuestionWrapper>
        <DelegateSwitch
          isActive={businessMatchmaking.productSpecification}
          onToggle={() => toggleCheckbox("productSpecification")}
        />
        <Subtitle>
          Add the product specification that will include specific features,
          dimensions, materials, and any other technical details. If possible,
          add sketches and / or photos as a reference.
        </Subtitle>
      </QuestionWrapper>
      <Tittle>Price:</Tittle>
      <QuestionWrapper>
        <DelegateSwitch
          isActive={businessMatchmaking.price}
          onToggle={() => toggleCheckbox("price")}
        />
        <Subtitle>What is your target EXW price per product unit?</Subtitle>
      </QuestionWrapper>
      <QuestionWrapper>
        <DelegateSwitch
          isActive={businessMatchmaking.paymentTerms}
          onToggle={() => toggleCheckbox("paymentTerms")}
        />
        <Subtitle>What payment terms do you prefer?</Subtitle>
      </QuestionWrapper>
      <Tittle>Order quantity:</Tittle>
      <QuestionWrapper>
        <DelegateSwitch
          isActive={businessMatchmaking.numberOfProducts}
          onToggle={() => toggleCheckbox("numberOfProducts")}
        />
        <Subtitle>Specify the number of product units in your order.</Subtitle>
      </QuestionWrapper>
      <Tittle>Order frequency:</Tittle>
      <QuestionWrapper>
        <DelegateSwitch
          isActive={businessMatchmaking.oftenOrder}
          onToggle={() => toggleCheckbox("oftenOrder")}
        />
        <Subtitle>How often do you plan to place such orders?</Subtitle>
      </QuestionWrapper>
      <Tittle>Lead time:</Tittle>
      <QuestionWrapper>
        <DelegateSwitch
          isActive={businessMatchmaking.leadTime}
          onToggle={() => toggleCheckbox("leadTime")}
        />
        <Subtitle>When do you need the products to be manufactured?</Subtitle>
      </QuestionWrapper>
      <Tittle>Shipment:</Tittle>
      <QuestionWrapper>
        <DelegateSwitch
          isActive={businessMatchmaking.help}
          onToggle={() => toggleCheckbox("help")}
        />
        <Subtitle>Will you need help with the delivery?</Subtitle>
      </QuestionWrapper>
      <QuestionWrapper>
        <DelegateSwitch
          isActive={businessMatchmaking.date}
          onToggle={() => toggleCheckbox("date")}
        />
        <Subtitle>When do you need the products to be delivered?</Subtitle>
      </QuestionWrapper>
      <QuestionWrapper>
        <DelegateSwitch
          isActive={businessMatchmaking.place}
          onToggle={() => toggleCheckbox("place")}
        />
        <Subtitle>Where do you need the products to be delivered?</Subtitle>
      </QuestionWrapper>
      <Tittle>Quality standards:</Tittle>
      <QuestionWrapper>
        <DelegateSwitch
          isActive={businessMatchmaking.qualityStandards}
          onToggle={() => toggleCheckbox("qualityStandards")}
        />
        <Subtitle>
          Are there any quality standards that the product must meet?
        </Subtitle>
      </QuestionWrapper>
      <QuestionWrapper>
        <DelegateSwitch
          isActive={businessMatchmaking.certificates}
          onToggle={() => toggleCheckbox("certificates")}
        />
        <Subtitle>Do you need any certificates?</Subtitle>
      </QuestionWrapper>
      <Tittle>Packaging & labeling:</Tittle>
      <QuestionWrapper>
        <DelegateSwitch
          isActive={businessMatchmaking.specificPackaging}
          onToggle={() => toggleCheckbox("specificPackaging")}
        />
        <Subtitle>Are there any specific packaging requirements?</Subtitle>
      </QuestionWrapper>
      <QuestionWrapper>
        <DelegateSwitch
          isActive={businessMatchmaking.specificLabeling}
          onToggle={() => toggleCheckbox("specificLabeling")}
        />
        <Subtitle>
          Are there any specific labeling or branding requirements?
        </Subtitle>
      </QuestionWrapper>
      <Tittle>Additional requirements: </Tittle>
      <QuestionWrapper>
        <DelegateSwitch
          isActive={businessMatchmaking.communicate}
          onToggle={() => toggleCheckbox("communicate")}
        />
        <Subtitle>
          Do you have additional requests that you would like to communicate
          (e.g., documents to be provided, samples, consultation, special terms,
          etc.)?
        </Subtitle>
      </QuestionWrapper>
      <Tittle>Custom questions: </Tittle>
      <QuestionWrapper>
        <DelegateSwitch
          isActive={businessMatchmaking.customQuestion1}
          onToggle={() => toggleCheckbox("customQuestion1")}
        />
        <Subtitle>Customized question from the factory 1.</Subtitle>
      </QuestionWrapper>
      {businessMatchmaking.customQuestion1 && (
        <CustomWrapper>
          <CustomQuestion
            name="customQuestion1Text"
            id="customQuestion1Text"
            cols={30}
            rows={10}
            maxLength={150}
            placeholder="Enter your question..."
            value={businessMatchmaking.customQuestion1Text}
            onChange={(e) =>
              setBusinessMatchmaking({
                ...businessMatchmaking,
                customQuestion1Text: e.target.value,
              })
            }
          />
          <Subtitle>
            {businessMatchmaking.customQuestion1Text.length}/150 characters
          </Subtitle>
        </CustomWrapper>
      )}
      <QuestionWrapper>
        <DelegateSwitch
          isActive={businessMatchmaking.customQuestion2}
          onToggle={() => toggleCheckbox("customQuestion2")}
        />
        <Subtitle>Customized question from the factory 2.</Subtitle>
      </QuestionWrapper>
      {businessMatchmaking.customQuestion2 && (
        <CustomWrapper>
          <CustomQuestion
            name="customQuestion2Text"
            id="customQuestion2Text"
            cols={30}
            rows={10}
            maxLength={150}
            placeholder="Enter your question..."
            value={businessMatchmaking.customQuestion2Text}
            onChange={(e) =>
              setBusinessMatchmaking({
                ...businessMatchmaking,
                customQuestion2Text: e.target.value,
              })
            }
          />
          <Subtitle>
            {businessMatchmaking.customQuestion2Text.length}/150 characters
          </Subtitle>
        </CustomWrapper>
      )}
      <QuestionWrapper>
        <DelegateSwitch
          isActive={businessMatchmaking.customQuestion3}
          onToggle={() => toggleCheckbox("customQuestion3")}
        />
        <Subtitle>Customized question from the factory 3.</Subtitle>
      </QuestionWrapper>
      {businessMatchmaking.customQuestion3 && (
        <CustomWrapper>
          <CustomQuestion
            name="customQuestion3Text"
            id="customQuestion3Text"
            cols={30}
            rows={10}
            maxLength={150}
            placeholder="Enter your question..."
            value={businessMatchmaking.customQuestion3Text}
            onChange={(e) =>
              setBusinessMatchmaking({
                ...businessMatchmaking,
                customQuestion3Text: e.target.value,
              })
            }
          />
          <Subtitle>
            {businessMatchmaking.customQuestion3Text.length}/150 characters
          </Subtitle>
        </CustomWrapper>
      )}
      <ButtonWrapper onClick={handleSaveClick}>
        <Button text="Save" />
      </ButtonWrapper>
      {isSaveClicked && (
          <ModalSuccessfullyChange
          text="Your questions have been saved successfully"
          handleIsChanged={handleIsChanged}
          />
        )}
    </Container>
  );
};

export default ProfileBusinessMatchmaking;
