import styled from "styled-components";

interface DelegateSwitchProps {
  $active: boolean;
}

export const DelegateSwitchBlock = styled.div<DelegateSwitchProps>`
  border-radius: 4px;
  border: 1px solid var(--battn-border, #004c62);
  height: 16px;
  cursor: pointer;
  background-color: ${({ $active }) =>
    $active ? "var(--batton, #0095C0)" : "transparent"};
  display: inline-flex;
  align-items: center;
`;

export const IconWrapper = styled.div<DelegateSwitchProps>`
  svg {
    fill: ${({ $active }) =>
      $active ? "var(--batton, #0095C0)" : "transparent"};
    padding: 1px;
  }
`;
export const DelegateWrapper = styled.div`
  display: flex;
  align-items: center;
`;
