import React, { useState, ChangeEvent, KeyboardEvent } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  ButtonContainer,
  ButtonWrapper,
  Chips,
  ChipsList,
  CloseUploadWrapper,
  Container,
  CrossWrapper,
  Description,
  ImageHover,
  ImageHoverWrapper,
  ImageTooltip,
  Input,
  TextareaDescription,
  List,
  MediaUploadWrapper,
  PhotoItem,
  SaveWrapper,
  Subtitle,
  TittleHeaderWrapper,
  TooltipKeyword,
  TooltipPhoto,
  TooltipWrapper,
  TopWrapper,
  UploadDialogWrapper,
  UploadWrapper,
  VectorWrapper,
  Verify,
  VerifyContainer,
  VerifyInput,
  Wrapper,
} from "./style";
import ReturnButton from "../../ReturnButton/ReturnButton";
import TitleHeader from "../../TitleHeader/TitleHeader";
import ShieldQuestionIcon from "../../../assets/icons/ShieldQuestionIcon";
import CrossIcon from "../../../assets/icons/CrossIcon";
import MediaUpload from "../../MediaUpload/MediaUpload";
import VectorIcon from "../../../assets/icons/VectorIcon";
import { uploadFiles } from "../../../services/uploadFilesToServer";
import { useDispatch } from "react-redux";
import Button from "../../Button/Button";
import { useBlocker, useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../store/hooks";

import {
  AddProduct,
  Item,
  UserAccount,
  allProductsResponse,
} from "../../../utils/types";
import ButtonWhite from "../../ButtonWhite/ButtonWhite";
import ProductPreview from "../ProductPreview/ProductPreview";
import {
  getAllProduct,
  updateProductAsync,
} from "../../../store/slices/userSlice";
import ModalLostChanges from "../../ModalWindows/ModalLostChanges/ModalLostChanges";

const EditProduct: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const userAccount: UserAccount = useAppSelector(
    (store) => store.user.userAccount!
  );
  const status = useAppSelector((state) => state.user.status);
  const productId = location.state?.productId;
  const products = useAppSelector(
    (state) => state.user.company!.products as allProductsResponse | undefined
  );
  const [productByIdInfo, setproductByIdInfo] = useState(
    products?.content?.find((product) => product.id === productId) ||
      ({} as Item)
  );
  const [showKeywordTooltip, setShowKeywordTooltip] = useState(false);
  const [showPhotoTooltip, setShowPhotoTooltip] = useState(false);
  const [keywordInput, setKeywordInput] = useState<string>("");
  const [keywordList, setKeywordList] = useState<string[]>(
    productByIdInfo?.keywords || []
  );
  const [filesToUpload, setFilesToUpload] = useState<File[]>([]);
  const [productList, setProductList] = useState<any[]>(
    productByIdInfo?.images
  );
  const [updateProductInfo, setUpdateProductInfo] = useState<AddProduct>();
  const [previewMode, setPreviewMode] = useState(false);
  const [showUploadTooltip, setShowUploadTooltip] = useState<boolean>(false);
  const [tooltipLink, setTooltipLink] = useState<string>("");
  const [isSaveClicked, setIsSaveClicked] = useState<boolean>(false);
  const [nameChanged, setNameChanged] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [nextLocation, setNextLocation] = useState<string>("");

  const handlePreviewProductClick = () => {
    if (!updateProductInfo?.name && nameChanged) {
      setIsSaveClicked(true);
      return;
    }
    setPreviewMode(true);
  };

  const handleKeywordInput = (e: ChangeEvent<HTMLInputElement>) => {
    setKeywordInput(e.target.value);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && keywordInput.trim() !== "") {
      const newKeywords = keywordInput
        .trim()
        .split(",")
        .map((kw) => kw.trim())
        .filter((kw) => kw !== "");

      const updatedKeywords = [...keywordList, ...newKeywords];

      setKeywordList(updatedKeywords);
      setUpdateProductInfo((prevInfo = {}) => ({
        ...prevInfo,
        keywords: updatedKeywords,
      }));

      setKeywordInput("");
    }
  };

  const handleDeleteKeyword = (index: number) => {
    const updatedKeywords = [...keywordList]
      .filter((_, i) => i !== index)
      .filter(Boolean) as string[];
    setKeywordList(updatedKeywords);
    setUpdateProductInfo({ ...updateProductInfo, keywords: updatedKeywords });
  };

  const handleDeleteFiles = (index: number) => {
    const updateFiles = [...productList].filter((_, i) => i !== index);
    setProductList(updateFiles);
    setUpdateProductInfo({ ...updateProductInfo, images: updateFiles });
  };

  const handleAttachmentFilesChange = async (files: File[]) => {
    const saveFiles = await uploadFiles({ files, dispatch });

    const updatedFiles = [
      ...productList,
      ...saveFiles.payload.map((file: any, index: number) => ({
        id: uuidv4(),
        ...file,
      })),
    ];
    setProductList(updatedFiles);

    setUpdateProductInfo((prevInfo = {}) => ({
      ...prevInfo,
      images: updatedFiles,
    }));
  };

  const handleSaveClick = () => {
    setIsSaveClicked(true);
    if (!updateProductInfo?.name && nameChanged) {
      return;
    }
    if (updateProductInfo) {
      dispatch(
        updateProductAsync({
          ...updateProductInfo,
          id: productId,
        }) as any
      ).then(() => {
        setUpdateProductInfo({});
        setIsSaveClicked(false);
        dispatch(getAllProduct() as any).then(
          () => {
            navigate(-1);
          }
        );
      });
    }
  };
  const handleShowUploadTooltip = (link: string) => {
    setShowUploadTooltip(true);
    setTooltipLink(link);
  };

  useBlocker(({ nextLocation }) => {
    if (
      updateProductInfo &&
      Object.keys(updateProductInfo).length > 0 &&
      Object.values(updateProductInfo).some((value) => {
        return (
          (typeof value === "string" && value.trim().length > 0) ||
          (Array.isArray(value) && value.length > 0) ||
          (typeof value === "number" && value !== 0) ||
          typeof value === "boolean"
        );
      })
    ) {
      setShowModal(true);
      setNextLocation(nextLocation.pathname);
      return true;
    }
    return false;
  });

  const handleDiscardChanges = async () => {
    setShowModal(!showModal);
    await setUpdateProductInfo({});
    navigate(nextLocation);
  };

  const handlEditing = () => {
    setShowModal(!showModal);
  };

  return (
    <Container>
      {previewMode ? (
        <ProductPreview
          productInfo={productByIdInfo || {}}
          updateProductInfo={updateProductInfo}
          setPreviewMode={setPreviewMode}
          saveClick={handleSaveClick}
          status={status}
        />
      ) : (
        <>
          <ButtonWrapper>
            <ReturnButton />
            <TittleHeaderWrapper>
              <TitleHeader text={"Product upload"} />
            </TittleHeaderWrapper>
          </ButtonWrapper>
          <Wrapper>
            <TopWrapper>
              <VerifyContainer>
                <Subtitle>Product name</Subtitle>
                <Verify>*</Verify>
              </VerifyContainer>
              <VerifyInput
                placeholder="Enter product name"
                type="text"
                value={updateProductInfo?.name ?? productByIdInfo?.name}
                onChange={(e) => {
                  const newValue = e.target.value;
                  if (newValue.trim() !== updateProductInfo?.name) {
                    setNameChanged(true);
                  } else {
                    setNameChanged(false);
                  }
                  setUpdateProductInfo((prevUpdateProductInfo) => ({
                    ...prevUpdateProductInfo!,
                    name: newValue,
                  }));
                }}
                $isEmpty={!updateProductInfo?.name}
                $isSaveClicked={isSaveClicked}
              />
              <VerifyContainer>
                <Subtitle>Keywords:</Subtitle>
                <TooltipWrapper
                  onMouseEnter={() => setShowKeywordTooltip(true)}
                  onMouseLeave={() => setShowKeywordTooltip(false)}
                >
                  <ShieldQuestionIcon />
                </TooltipWrapper>
                {showKeywordTooltip && (
                  <TooltipKeyword>
                    Product photos, discounts, or hot offers
                  </TooltipKeyword>
                )}
              </VerifyContainer>
              <Input
                placeholder="Enter keywords"
                type="string"
                value={keywordInput}
                onChange={handleKeywordInput}
                onKeyDown={handleKeyDown}
              />
              <ChipsList>
                {keywordList.map((keyword, index) => (
                  <Chips key={index + "key-kw"}>
                    {keyword}
                    <CrossWrapper onClick={() => handleDeleteKeyword(index)}>
                      <CrossIcon />
                    </CrossWrapper>
                  </Chips>
                ))}
              </ChipsList>
              <VerifyContainer>
                <Description>Description</Description>
              </VerifyContainer>
              <TextareaDescription
                placeholder="Enter description product"
                cols={30}
                rows={10}
                value={
                  updateProductInfo?.description ?? productByIdInfo?.description ?? ""
                }
                onChange={(e) =>
                  setUpdateProductInfo((prevUpdateProductInfo) => ({
                    ...prevUpdateProductInfo!,
                    description: e.target.value,
                  }))
                }
              />
              <VerifyContainer>
                <Subtitle>Minimum order quantity</Subtitle>
              </VerifyContainer>
              <Input
                placeholder="Enter order quantity"
                type="number"
                value={
                  updateProductInfo?.minimumOrderQuantity ??
                  productByIdInfo?.minimumOrderQuantity
                }
                onChange={(e) =>
                  setUpdateProductInfo((prevUpdateProductInfo) => ({
                    ...prevUpdateProductInfo!,
                    minimumOrderQuantity: +e.target.value,
                  }))
                }
              />
              <VerifyContainer>
                <Subtitle>Unity price</Subtitle>
              </VerifyContainer>
              <Input
                placeholder="Enter unite price"
                type="number"
                value={updateProductInfo?.price ?? productByIdInfo?.price }
                onChange={(e) =>
                  setUpdateProductInfo((prevUpdateProductInfo) => ({
                    ...prevUpdateProductInfo!,
                    price: +e.target.value,
                  }))
                }
              />

              <UploadWrapper>
                <VerifyContainer>
                  <Subtitle>Product photo:</Subtitle>
                  <TooltipWrapper
                    onMouseEnter={() => setShowPhotoTooltip(true)}
                    onMouseLeave={() => setShowPhotoTooltip(false)}
                  >
                    <ShieldQuestionIcon />
                  </TooltipWrapper>
                  {showPhotoTooltip && (
                    <TooltipPhoto>
                      Product photos, discounts, or hot offers
                    </TooltipPhoto>
                  )}
                </VerifyContainer>

                <MediaUploadWrapper>
                  <UploadDialogWrapper>
                    <MediaUpload
                      selectedFiles={filesToUpload}
                      onFilesChange={(files) =>
                        handleAttachmentFilesChange(files)
                      }
                    />
                  </UploadDialogWrapper>
                </MediaUploadWrapper>
              </UploadWrapper>
              <List>
                {productList.map((file, index) => (
                  <PhotoItem key={index}>
                    <VectorWrapper>
                      <VectorIcon />
                    </VectorWrapper>
                    <ImageTooltip
                      src={file.link as unknown as string}
                      alt={file.name}
                      onMouseEnter={() =>
                        handleShowUploadTooltip(file.link as unknown as string)
                      }
                      onMouseLeave={() => setShowUploadTooltip(false)}
                    />
                    {file.name}
                    <CloseUploadWrapper
                      onClick={() => handleDeleteFiles(index)}
                    >
                      <CrossIcon />
                    </CloseUploadWrapper>
                  </PhotoItem>
                ))}
                {showUploadTooltip && (
                  <ImageHoverWrapper>
                    <ImageHover src={tooltipLink} alt={tooltipLink} />
                  </ImageHoverWrapper>
                )}
              </List>
            </TopWrapper>
            <ButtonContainer>
              <SaveWrapper>
                <Button text={"Preview"} onClick={handlePreviewProductClick} />

                <ButtonWhite
                  disabled={status === "loading" || !updateProductInfo}
                  text={status === "loading" ? "loading..." : "Save"}
                  onClick={handleSaveClick}
                />
              </SaveWrapper>
            </ButtonContainer>
          </Wrapper>
        </>
      )}
      {showModal && (
        <ModalLostChanges
          text="The changes you made to  this page will be lost"
          buttonText="Keep editing"
          buttonWhitetext="Discard"
          handleWhiteButtonClick={handleDiscardChanges}
          handleButtonClick={handlEditing}
        />
      )}
    </Container>
  );
};

export default EditProduct;
