import React, { useEffect, useState } from "react";
import Resizer from "react-image-file-resizer";
import styled from "styled-components";

interface ResizedImageProps {
  src: string;
}

const CompanyIconImg = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%; 
  object-fit: contain;
`;


const ResizedImage: React.FC<ResizedImageProps> = ({ src }) => {
  const [resizedImage, setResizedImage] = useState<string | null>(null);

  useEffect(() => {
    const convertUrlToBlob = async (url: string): Promise<Blob> => {
      const response = await fetch(url);
      const blob = await response.blob();
      return blob;
    };

    const resizeImage = async () => {
        try {
          const blob = await convertUrlToBlob(src);
          Resizer.imageFileResizer(
            blob, 
            50, 
            50, 
            "PNG", 
            100, 
            0, 
            (uri) => {
              setResizedImage(uri as string); 
            },
            "base64" 
          );
        } catch (error) {
          console.error(error);
        }
      };
  
      resizeImage();
    }, [src]);

  return resizedImage ? (
    <CompanyIconImg src={resizedImage} />
  ) : (
    <CompanyIconImg src={src} />
  );
};

export default ResizedImage;
