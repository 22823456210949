import { CloseWrapper, ContentWrapper, DialogContent, ErrorWrapper, Input, InputContainer, InputWrapper, Overlay, Text, TextError, Title, TogglePasswordButton } from '../ModalStyle'
import Close from '../../Close/Close'
import EyeIcon from '../../../assets/icons/EyeIcon'
import EyeOffIcon from '../../../assets/icons/EyeOffIcon'
import Button from '../../Button/Button'
import { useState } from 'react'
import { changePasswordAsync, validatePasswordAsync } from '../../../store/slices/userSlice'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'

interface Props {
    handlePasswordClick: () => void
}

const ModalChangePassword = ({ handlePasswordClick }: Props) => {
    const dispatch = useAppDispatch();
    const user = useAppSelector((store) => store.user);
    const [isChanged, setIsChanged] = useState(false);

    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [showOldPassword, setShowOldPassword] = useState<boolean>(false);
    const [showConfirmPassword, setshowConfirmPassword] =
        useState<boolean>(false);
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [oldPasswordError, setOldPasswordError] = useState<string>("");
    const [passwordError, setPasswordError] = useState<string>("");
    const [confirmationError, setConfirmationError] = useState<string>("");

    const validatePassword = (password: string) => {
        const regexLowercase = /[a-z]/;
        const regexUppercase = /[A-Z]/;
        const regexDigit = /\d/;
        const regexLatinAndDigits = /^[a-zA-Z0-9]+$/;

        if (!regexLatinAndDigits.test(password)) {
            return "Password must contain only Latin letters and digits.";
        }
        if (password.length < 8) {
            return "Password is too short - should be 8 chars minimum.";
        }
        if (!regexLowercase.test(password)) {
            return "Password must contain at least one lowercase letter.";
        }
        if (!regexUppercase.test(password)) {
            return "Password must contain at least one uppercase letter.";
        }
        if (!regexDigit.test(password)) {
            return "Password must contain at least one digit.";
        }
        if (oldPassword === newPassword) {
            return "The old and new passwords are the same"
        }
        return "";
    };

    const validateConfirmationPassword = (
        password: string,
        confirmPassword: string
    ) => {
        if (password !== confirmPassword) {
            return "Passwords must match.";
        }
        return "";
    };

    const validateOldPassword = async (oldPassword: string) => {
        const response: any = await dispatch(validatePasswordAsync(oldPassword));
        if(response.payload === 200){
            setOldPasswordError('');
        } else if(response.payload.errorStatus && response.payload.errorStatus === 400){
            setOldPasswordError('Old password is incorrect');
        }

    };

    const handleSave = async () => {
        const passwordError = validatePassword(newPassword);
        const confirmationError = validateConfirmationPassword(
            newPassword,
            confirmPassword
        );

        // setOldPasswordError(passwordError);
        setPasswordError(passwordError);
        setConfirmationError(confirmationError);

        if (!passwordError && !confirmationError && !oldPasswordError) {
            if (oldPassword === '') {
                setOldPasswordError('Please, enter current password');
            } else {
                const response: any = await dispatch(changePasswordAsync(newPassword) as any);
                if (response.payload === 200) {
                    setIsChanged(!isChanged);
                    setOldPassword("");
                    setNewPassword("");
                    setConfirmPassword("");
                }
            }
        }
    };

    return (
        <>
            <Overlay />
            <DialogContent>
                <CloseWrapper onClick={handlePasswordClick}>
                    <Close />
                </CloseWrapper>
                {isChanged ? <Text>Your password has been <br /> changed successfully!</Text> :
                    <>
                        <Title>Change password</Title>
                        <ErrorWrapper>
                            {oldPasswordError && <TextError>{oldPasswordError}</TextError>}
                            {passwordError && <TextError>{passwordError}</TextError>}
                            {confirmationError && <TextError>{confirmationError}</TextError>}
                        </ErrorWrapper>
                        <ContentWrapper>
                            <InputContainer>
                                <p style={{ margin: '20px 0 10px' }} >Old password</p>
                                <InputWrapper>
                                    <Input
                                        type={showOldPassword ? "text" : "password"}
                                        value={oldPassword}
                                        onChange={(e) => setOldPassword(e.target.value)}
                                        onBlur={() => validateOldPassword(oldPassword)}
                                    />
                                    <TogglePasswordButton
                                        type="button"
                                        onClick={() => setShowOldPassword(!showOldPassword)}
                                    >
                                        {showOldPassword ? <EyeIcon /> : <EyeOffIcon />}
                                    </TogglePasswordButton>
                                </InputWrapper>
                                <p style={{ margin: '20px 0 10px' }} >New password</p>
                                <InputWrapper>
                                    <Input
                                        type={showPassword ? "text" : "password"}
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                    />
                                    <TogglePasswordButton
                                        type="button"
                                        onClick={() => setShowPassword(!showPassword)}
                                    >
                                        {showPassword ? <EyeIcon /> : <EyeOffIcon />}
                                    </TogglePasswordButton>
                                </InputWrapper>
                                <p style={{ margin: '20px 0 10px' }} >Repeat new password</p>
                                <InputWrapper>
                                    <Input
                                        type={showConfirmPassword ? "text" : "password"}
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                    />
                                    <TogglePasswordButton
                                        type="button"
                                        onClick={() => setshowConfirmPassword(!showConfirmPassword)}
                                    >
                                        {showConfirmPassword ? <EyeIcon /> : <EyeOffIcon />}
                                    </TogglePasswordButton>
                                </InputWrapper>
                            </InputContainer>
                            <Button text={"Confirm"} onClick={handleSave} />
                        </ContentWrapper>
                    </>
                }
            </DialogContent>
        </>
    )
}

export default ModalChangePassword