
import styled from "styled-components";


export const SelectedMessage = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

export const SwitchWrapper = styled.div`
  display: flex;
`;

export const SelectedText = styled.p`
  color: var(--txt-dak-light, #143255);
  font-size: 17px;
  font-weight: 400;
  line-height: 140%;
`;

export const MoveContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 20px;
`;

export const MoveWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: absolute;
  left: 120px;
  top: -27px;
  z-index: 1;
`;

export const MarkButton = styled.button`
  border-radius: 12px;
  border: 1px solid var(--border, #979797);
  background: var(--wiht, #fff);
  display: flex;
  padding: 8px 16px 10px 16px;
`;

export const MarkParagraph = styled.p`
  color: var(--txt-dark, #001731);
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  white-space: nowrap;
`;

export const SortActiveWrapper = styled.div`
  border-radius: 16px;
  border: 1px solid var(--batton, #0095c0);
  background: var(--wiht, #fff);
  display: flex;
  justify-content: center;
  align-items:center;
  padding: 14px 20px 16px 30px;
  flex-direction: column;
`;

export const SortActiveButton = styled.button`
  background: var(--wiht, #fff);
  color: var(--txt-dark, #001731);
  font-size: 17px;
  font-weight: 500;
  line-height: 140%;
  position: relative;
`;

export const IndustryLocationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
`;

export const LocationIndustryButton = styled.button`
  color: var(--txt-dak-light, #143255);
  font-size: 17px;
  font-weight: 400;
  line-height: 140%;
  &:hover {
    color: var(--txt-blue, #0095c0);
  }
`;

export const NotInterestedButton = styled.button`
  color: var(--txt-dak-light, #143255);
  font-size: 17px;
  font-weight: 400;
  line-height: 140%;
  white-space: nowrap;
  &:hover {
    color: var(--txt-blue, #0095c0);
  }
`;

export const DefaultWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 152px;
`;

export const DefaultParagraph = styled.p`
  font-size: 24px;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: 0%;
  text-align: left;
  display: flex;
  justify-content: center;
`;

export const KeywordsFilterWrapper = styled.div`
display: flex;
justify-content:end;
border-bottom: 1px solid var(--border, #979797);
padding-bottom: 10px;
`;


export const KeywordsFilter = styled.div`
  display: grid;
  width: 94%;
  grid-template-columns:  2.7fr 1.6fr 2.6fr 0.9fr 1.9fr 1.9fr;
  gap: 16px;
  justify-content: center;
  text-align: center;
  color: var(--txt-dark, #001731);
  font-size: 17px;
  font-weight: 500;
  line-height: 140%;
`;

export const SubtitleFilter = styled.div``;

export const CompanyList = styled.div``;

export const CompanyWrapper = styled.div`
  display: flex;
  justify-content: end;
  align-items: center; 
  border-bottom: 1px solid var(--border, #979797);
  
  &:hover {
    border-bottom: 1px solid var(--border, #979797);
    background: var(--bg, #fdfdfd);
    box-shadow: 0px 2px 5.3px 0px rgba(0, 6, 81, 0.5);
  }
`;

export const BodyMessageLink = styled.div`
width: 94%;
  display: grid;
  grid-template-columns:  0.4fr 2.3fr 1.6fr 2.6fr 0.9fr 1.9fr 1.9fr  ;
  gap: 16px;
  align-items: center;
  padding: 20px 0;
  cursor: pointer;
  `;

export const CompanyComponent = styled.div`
  display: flex;
  color: var(--txt-dark, #001731);
  font-size: 17px;
  font-weight: 400;
  line-height: 140%;
  white-space: nowrap;
  width: 100%;
`;

export const Avatar = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
`;

export const NameBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const CompanyComponentKeyWords = styled.div`
  display: flex;
  color: var(--txt-dark, #001731);
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  white-space: nowrap;
  width: 100%;
`;

export const TextComponent = styled.h5`
  width: 100%;
  font-size: 17px;
  line-height: 140%;
  white-space: pre-line;
 text-align: center;
  color: var(--txt-dark-light, #143255);
`;

export const TypeComponent = styled.h6<{ backgroundColor: string }>`
  display: flex;
  max-width: 250px;
  width: 100%;
  height: 38px;
  padding: 8px 16px 10px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  border: 1px solid var(--battn-border, #004c62);
  background-color: ${props => props.backgroundColor};
  color: ${props => props.backgroundColor === '#3ACE5A' ? '#F4FFFF' : 'var(--txt-dark, #001731)'};
`;


























