import React from 'react'
import { CloseWrapper, DialogContent, Overlay, Text } from '../ModalStyle'
import Close from '../../Close/Close'

interface Props {
  handleIsChanged: () => void;
  text: string;
  modal?: string;
}

const ModalSuccessfullyChange = ({ handleIsChanged, text, modal }: Props) => {
  return (
    <>
      <Overlay />
      <DialogContent modal>
        <CloseWrapper onClick={handleIsChanged}>
          <Close />
        </CloseWrapper>
        <Text>{text}</Text>
      </DialogContent>
    </>
  )
}

export default ModalSuccessfullyChange
