import React, { useEffect } from "react";
import styled from "styled-components";
import Footer from "../components/Footer/Footer";
import Logo from "../components/Logo/Logo";
import Confirm from "../components/Confirmation/Confirm";
import { useAppSelector } from "../store/hooks";
import { RootState } from "../store/store";
import { useNavigate } from "react-router-dom";
import { routes } from "../routes/routes";

const Wrapper = styled.div`
  max-width: 1256px;
  width: 100%;
  margin: 30px auto;
  padding: 0 20px;
`;

const FooterWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Confirmation: React.FC = () => {
  const user = useAppSelector((state: RootState) => state.user.userAccount);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate(routes.HOME);
    }
  }, [user, navigate]);

  return (
    <Wrapper>
      <Logo />
      <Confirm />
      <FooterWrapper>
        <Footer />
      </FooterWrapper>
    </Wrapper>
  );
};

export default Confirmation;
