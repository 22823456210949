import { css } from "styled-components";

export const lightTheme = css`
  --batton: #0095c0;
  --wiht: #fff;
  --txt-light-blue: #f4ffff;
  --border: #bdbdbd;
  --txt-dark: #001731;
  --txt-dark-light: #143255;
  --blue-light: #f4ffff;
  --suncolor: #fff;
  --mooncolor: #000;
  --txt-grey-light: #717880;
  --border: #bdbdbd;
  --battn-border: #004c62;
  --txt-light-blue-dont-active: #a0b7d1;
  --txt-red: #f00;
  --hover: linear-gradient(
    254deg,
    #0095c0 17.54%,
    rgba(3, 182, 42, 0.6) 90.58%
  );
  --green: #03b62a;
  --red: #F00;
  --order-green-1: #EAFFEF;
`;

export const darkTheme = css`
  --batton: #000;
  --wiht: #fff;
  --txt-light-blue: #f4ffff;
  --border: #bdbdbd;
  --txt-dark: #001731;
  --txt-dark-light: #143255;
  --blue-light: #f4ffff;
  --suncolor: #000;
  --mooncolor: #fff;
  --txt-grey-light: #717880;
  --border: #bdbdbd;
  --battn-border: #004c62;
  --txt-light-blue-dont-active: #a0b7d1;
  --txt-red: #f00;
  --hover: linear-gradient(254deg, #0095c0 17.54%, rgba(3, 182, 42, 0.6) 90.58%);
  --green: #03b62a;
  --red: #F00;
  --order-green-1: #EAFFEF;
`;
