import { RouterProvider } from "react-router-dom";
import { AppRouter } from "./router/AppRouter";
import GlobalStyles from "./ui/GlobalStyles";

export const App = () => {
  const browserRouter = AppRouter();
  return (
    <>
      <GlobalStyles />
      <RouterProvider router={browserRouter} />
    </>
  );
};
