import React from "react";
import { ToggleContainer, ToggleSwitch } from "./style";

interface DelegateSwitchProps {
  isActive: boolean;
  onToggle?: (e: React.MouseEvent<HTMLDivElement>) => void;
}

const CircleSwitch: React.FC<DelegateSwitchProps> = ({ isActive, onToggle }) => {
  return (
    <ToggleContainer onClick={onToggle}>
      <ToggleSwitch $isActive={isActive} />
    </ToggleContainer>
  );
};

export default CircleSwitch;