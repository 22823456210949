import React from "react";

interface ChevronRightIconProps {
  onClick?: () => void;
}

const ChevronRightIcon: React.FC<ChevronRightIconProps> = ({ onClick }) => {
  return (
    <button type="button" onClick={onClick} style={{ background: "none", border: "none", cursor: "pointer", padding: '0', display: 'flex', }}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Icons/chevron-right21">
          <path
            id="Vector"
            d="M9 18L15 12L9 6"
            stroke="#001731"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    </button>
  );
};

export default ChevronRightIcon;
