import React from "react";

interface MinusProps {
  color?: string;
}

const Minus:React.FC<MinusProps> = ({color= "#004C62"}) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Icons/minus37">
        <rect
          x="0.5"
          y="0.5"
          width="15"
          height="15"
          rx="3.5"
          stroke={color} 
        />
        <path
          id="Vector"
          d="M3.33203 8H12.6654"
          stroke={color} 
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default Minus;
