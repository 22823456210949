import { CloseWrapper, ContentWrapper, DialogContent, ErrorWrapper, Input, InputContainer, InputWrapper, LabelStyle, Overlay, TextError, Title } from '../ModalStyle'
import Close from '../../Close/Close'
import Button from '../../Button/Button'
import { useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { TitleWrapper } from '../../Confirmation/styles'
import ModalConfirmationEmailAndPhone from '../ModalConfirmationEmailAndPhone/ModalConfirmationEmailAndPhone'
import { changeEmailAsync, confirmEmailAsync } from '../../../store/slices/userSlice'

interface Props {
    handleFieldClick: () => void
    replacementField: string
    oldValueState: string
}

const ModalChangeField = ({ handleFieldClick, replacementField, oldValueState }: Props) => {
    const dispatch = useAppDispatch();
    const user = useAppSelector((store) => store.user.userAccount);
    // const currentValue = replacementField === 'email' ? user!.email : user!.phoneNumber !== null ? user!.phoneNumber : '';
    const [isChanged, setIsChanged] = useState(false);
    const [oldValue, setOldValue] = useState(oldValueState);
    const [newValue, setNewValue] = useState("");
    const [newValueError, setNewValueError] = useState<string>("");
    const [oldValueError, setOldValueError] = useState<string>("");
    const validateNewValue = () => {
        if (newValue.toLowerCase().trim() === oldValue.toLowerCase().trim()) {
            return `This is the old ${replacementField}. Please enter the new one.`;
        }

        if (replacementField === 'email') {
            const emailRegex: RegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            return (!emailRegex.test(newValue.toLowerCase().trim())) ? `New ${replacementField} is incorrect` : '';
        } else {
            return '';
        }
    };
   
    const validateOldValue = () => {
        return oldValue.toLowerCase().trim() !== oldValueState.toLowerCase().trim() ? `Old ${replacementField} is incorrect` : '';
    };

    const handleSave = async () => {
        const newValueError = validateNewValue();
        const oldValueError = validateOldValue();

        setOldValueError(oldValueError);
        setNewValueError(newValueError);

        if (newValueError === '' && oldValueError === '') {

            const response: any = await dispatch(changeEmailAsync(newValue.toLocaleLowerCase().trim() ));
            
            if(response.payload.errorStatus && response.payload.errorStatus === 409){
                setNewValueError(`${response.payload.message}`);
            } else {
                setIsChanged(!isChanged);
            }
        }
    };

    return (
        <>
            <Overlay />
            <DialogContent>
                <CloseWrapper onClick={handleFieldClick}>
                    <Close />
                </CloseWrapper>
                <>
                    {!isChanged ?
                        <Title>Change {replacementField}</Title>
                        :
                        <TitleWrapper>
                            <Title>Confirmation</Title>
                        </TitleWrapper>
                    }
                    <ErrorWrapper>
                        {oldValueError && <TextError>{oldValueError}</TextError>}
                        {newValueError && <TextError>{newValueError}</TextError>}
                    </ErrorWrapper>
                    <ContentWrapper>
                        <InputContainer>
                            {[
                                { label: `Old ${replacementField}`, value: oldValue, setter: setOldValue },
                                { label: `New ${replacementField}`, value: newValue, setter: setNewValue },
                            ].map(({ label, value, setter }, index) => (
                                <div key={index}>
                                    <LabelStyle>{label}</LabelStyle>
                                    <InputWrapper>
                                        <Input
                                            type="text"
                                            defaultValue={value}
                                            onChange={(e) => setter(e.target.value)}
                                        />
                                    </InputWrapper>
                                </div>
                            ))}
                        </InputContainer>

                        {isChanged && <ModalConfirmationEmailAndPhone replacementField={replacementField} handleFieldClick={handleFieldClick} />}
                        {!isChanged && <Button text={"Confirm"} onClick={handleSave} />}
                    </ContentWrapper>
                </>

            </DialogContent>
        </>
    )
}

export default ModalChangeField