import React from "react";

const FbIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
    >
      <rect width="40.4335" height="40" rx="20" fill="#0B84EE" />
      <path
        d="M21.8924 20.3648H25.6549L26.2457 16.5605H21.8916V14.4813C21.8916 12.901 22.4104 11.4996 23.8957 11.4996H26.2824V8.17974C25.8631 8.12338 24.9762 8 23.3004 8C19.8011 8 17.7496 9.8393 17.7496 14.0297V16.5605H14.1523V20.3648H17.7496V30.821C18.462 30.9276 19.1836 31 19.9243 31C20.5938 31 21.2473 30.9391 21.8924 30.8522V20.3648Z"
        fill="white"
      />
    </svg>
  );
};

export default FbIcon;
