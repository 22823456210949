import { ChatType, IndustryActivityType, IndustryType, messageType } from './../../../utils/types';
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AssistantsActions, { changeChatName, createChat, createMessageToThreadAndRun, deleteChat, getAllChats, getAllIndustries, getAllMessagesInThread } from "./AssistantsActions";
import { RootState } from '../../store';

export const createInstructionsAsync = createAsyncThunk(
  "createInstructions/post",
  async (user_input: string, thunkAPI) => {
    // const state = thunkAPI.getState() as RootState;
    // const token: string = state.user.userAccount?.token!;
    try {
      const response = await AssistantsActions.createInstructions(user_input);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);



// new slices

export const deleteChatAsync = createAsyncThunk(
  "deleteChat/put",
  async (chatId: number, thunkAPI) => {
    try {
      const response = await deleteChat(chatId);
      return response.status;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const changeChatNameAsync = createAsyncThunk(
  "changeChatName/put",
  async ({ chatId, newChatName }: { chatId: number, newChatName: string }, thunkAPI) => {
    try {
      const response = await changeChatName(chatId, newChatName);
      return { data: response.data, status: response.status };
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createMessageToThreadAndRunAsync = createAsyncThunk(
  "createMessageToThreadAndRun/post",
  async ({ message }: { message: string }, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const threadId = state.assistants.threadId;
    const assistantId = state.assistants.assistantId;
    try {
      const response = await createMessageToThreadAndRun(message, threadId, assistantId);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createChatAsync = createAsyncThunk(
  "createChat/post",
  async ({ industryId, activityId, instructions }: { industryId: string, activityId: string, instructions: string }, thunkAPI) => {
    try {
      const response = await createChat(industryId, activityId, instructions);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getAllIndustriesAsync = createAsyncThunk(
  "getAllIndustries/get",
  async (_, thunkAPI) => {
    try {
      const response = await getAllIndustries();
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getAllMessagesInThreadAsync = createAsyncThunk(
  "getAllMessagesInThread/get",
  async (threadId: string, thunkAPI) => {
    try {
      const response = await getAllMessagesInThread(threadId);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getAllChatsAsync = createAsyncThunk(
  "getAllChats/get",
  async (_, thunkAPI) => {
    try {
      const response = await getAllChats();
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);





interface InstructionsType {
  response: string,
}

export interface newNameResponseType {
  id: number,
  name: string,
  assistantId: string,
  threadId: string,
  created_at: string,
  changed_at: string,
}

interface AssistantsStateType {
  status: string,
  assistantId: string;
  threadId: string;
  messages: messageType[];
  error: null;
  industries: IndustryType[];
  chats: ChatType[];
  chatId: number;
  industryValue: IndustryActivityType;
  activityValue: IndustryActivityType;
}

const initialState: AssistantsStateType = {
  status: "idle",
  assistantId: '',
  threadId: '',
  messages: [],
  error: null,
  industries: [],
  chats: [],
  chatId: -1,
  industryValue: { id: -1, name: '' },
  activityValue: { id: -1, name: '' },
};

const AssistantsSlice = createSlice({
  name: "Assistants",
  initialState,
  reducers: {
    addMessage: (state, action: PayloadAction<messageType>) => {
      state.messages.push(action.payload);
    },
    clearData: (state, action) => {
      state.messages = action.payload;
      state.assistantId = '';
      state.threadId = '';
      state.chatId = -1;
    },
    setChatData: (state, action) => {
      state.assistantId = action.payload.assistantId;
      state.threadId = action.payload.threadId;
      state.chatId = action.payload.chatId;
    },
    setIndustryActivityValues: (state, action) => {
      state.industryValue = action.payload.industryValue;
      state.activityValue = action.payload.activityValue;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(createInstructionsAsync.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(createInstructionsAsync.fulfilled, (state, action: PayloadAction<InstructionsType>) => {
        state.status = "succeeded";
      })
      .addCase(createInstructionsAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message as any;
      })

      .addCase(createMessageToThreadAndRunAsync.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(createMessageToThreadAndRunAsync.fulfilled, (state, action: PayloadAction<messageType>) => {
        state.status = "succeeded";
        state.messages.push(action.payload);

        const chatIndex = state.chats.findIndex(chat => chat.id === state.chatId);
        if (chatIndex !== -1) {
          const chatToUpdate = state.chats[chatIndex];
          chatToUpdate.changed_at = action.payload.created_at;

          // Check if the chat position needs to be updated.
          if (chatIndex > 0) {
            // Move the updated chat to the first position
            const updatedChat = state.chats.splice(chatIndex, 1)[0];
            state.chats.unshift(updatedChat);
          }
        }
      })
      .addCase(createMessageToThreadAndRunAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message as any;
      })

      .addCase(getAllIndustriesAsync.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getAllIndustriesAsync.fulfilled, (state, action: PayloadAction<IndustryType[]>) => {
        state.status = "succeeded";
        state.industries = action.payload;
      })
      .addCase(getAllIndustriesAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message as any;
      })

      .addCase(getAllChatsAsync.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getAllChatsAsync.fulfilled, (state, action: PayloadAction<ChatType[]>) => {
        state.status = "succeeded";
        state.chats = action.payload.sort((a, b) => new Date(b.changed_at).getTime() - new Date(a.changed_at).getTime());
      })
      .addCase(getAllChatsAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message as any;
      })

      .addCase(getAllMessagesInThreadAsync.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getAllMessagesInThreadAsync.fulfilled, (state, action: PayloadAction<messageType[]>) => {
        state.status = "succeeded";
        state.messages = action.payload.reverse();
      })
      .addCase(getAllMessagesInThreadAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message as any;
      })

      .addCase(createChatAsync.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(createChatAsync.fulfilled, (state, action: PayloadAction<ChatType>) => {
        state.status = "succeeded";
        state.chats.unshift(action.payload);
        state.assistantId = action.payload.assistantId;
        state.threadId = action.payload.threadId;
        state.chatId = action.payload.id;
      })
      .addCase(createChatAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message as any;
      })


      .addCase(changeChatNameAsync.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(changeChatNameAsync.fulfilled, (state, action: PayloadAction<{ data: newNameResponseType, status: number }>) => {
        state.status = "succeeded";

        const { data } = action.payload;

        const chatIndex = state.chats.findIndex(chat => chat.id === data.id);
        if (chatIndex !== -1) {
          const chatToUpdate = state.chats[chatIndex];
          chatToUpdate.name = data.name;
          chatToUpdate.changed_at = data.changed_at;

          // Check if the chat position needs to be updated.
          if (chatIndex > 0) {
            // Move the updated chat to the first position
            const updatedChat = state.chats.splice(chatIndex, 1)[0];
            state.chats.unshift(updatedChat);
          }
        }
      })
      .addCase(changeChatNameAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message as any;
      })

      .addCase(deleteChatAsync.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(deleteChatAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        const chatIdToDelete = action.meta.arg; // `action.meta.arg` содержит `chatId`
        state.chats = state.chats.filter(chat => chat.id !== chatIdToDelete);
      })
      .addCase(deleteChatAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message as any;
      })
  },
});

export const { addMessage, clearData, setChatData, setIndustryActivityValues } = AssistantsSlice.actions;
export default AssistantsSlice.reducer;