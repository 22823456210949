import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import FilterSwitch from "../FilterSwitch/FilterSwitch";
import {
  ButtonWrapper,
  ChevronDown,
  ChevronRightWrapper,
  Container,
  CountryInputWrapper,
  CountryItem,
  CountryList,
  CountrySearchWrapper,
  FilterByP,
  IndustryKeqwordsP,
  IndustrySearchWrapper,
  InputWrapper,
  ItemFail,
  RatingContainer,
  RatingIconWrapper,
  RatingInformation,
  RatingWrapperP,
  ReviewsInformation,
  ReviewsTooltip,
  SearchButton,
  SearchIcon,
  SearchInput,
  SearchInputCountryList,
  SearchInputWrapper,
  SearchLocationP,
  StarSwitch,
  Tooltip,
} from "./style";
import SearchIconBlack from "../../assets/icons/SearchIconBlack";
import ShieldAlertIcon from "../../assets/icons/ShieldAlertIcon";
import ChevronDownIcon from "../../assets/icons/ChevronDownIcon";
import ChevronRightIcon from "../../assets/icons/ChevronRightIcon";
import { FilterSwitchWrapper } from "../PartnerBasePage/ReputationFilter/style";
import { FactoryInternetSearch } from "../../utils/types";
import {
  getGeocodeByCountryCity,
  replaceUnderscoreWithSpaces,
} from "../../api/internetSearch";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getCityByCountryAsync } from "../../store/slices/userSlice";
import Button from "../Button/Button";
import { InputContainer } from "../ModalWindows/ModalStyle";

interface FilterProps {
  companyItems: FactoryInternetSearch[];
  // onFilterChange: (filteredCompanies: FactoryInternetSearch[]) => void;
  setCountry: Dispatch<SetStateAction<string>>;
  setCity: Dispatch<SetStateAction<string>>;
  setRating: Dispatch<SetStateAction<string>>;
  setReviews: Dispatch<SetStateAction<string>>;
  rating: string;
  reviews: string;
  setIndustry: Dispatch<SetStateAction<string>>;
  industry: string;
}

const CompanyFilter: React.FC<FilterProps> = ({
  companyItems,
  // onFilterChange,
  setCountry,
  setCity,
  setRating,
  rating,
  reviews,
  setReviews,
  setIndustry,
  industry,
}) => {
  //Location variables
  const dispatch = useAppDispatch();
  const countries = useAppSelector((state) => state.user.countries);
  const cities = useAppSelector((state) => state.user.cities);
  const [countryList, setCountryList] = useState<string[]>(countries);
  const [cityList, setCityList] = useState<string[]>(cities || []);
  const [showCountryList, setShowCountryList] = useState<boolean>(false);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedCity, setSelectedCity] = useState<string>("");
  const [showCityList, setShowCityList] = useState<boolean>(false);
  const [manualCountry, setManualCountry] = useState<string>("");
  const [manualCity, setManualCity] = useState<string>("");
  const [filteredCountryList, setFilteredCountryList] = useState(countryList);
  const [filteredCityList, setFilteredCityList] = useState(cityList);
  const [searchCity, setSearchCity] = useState<string>("");
  //Industry variables
  const industryArray = getAllIndustries();
  const [manualIndustry, setManualIndustry] = useState("");
  const [manualRating, setManualRating] = useState<string>("");
  const [filteredIndustryList, setFilteredIndustryList] =
    useState(industryArray);
  const [showIndustryList, setShowIndustryList] = useState(false);
  const [showRatingList, setShowRatingList] = useState(false);
  const [showReviewsList, setShowReviewsList] = useState(false);
  const [selectedIndustry, setSelectedIndustry] = useState<string | undefined>(
    undefined
  );

  //Rating & Reviews variables
  const [showReviewsTooltip, setShowReviewsTooltip] = useState(false);
  const [showRatingTooltip, setShowRatingTooltip] = useState(false);
  const [selectedRatings, setSelectedRatings] = useState<number[]>([]);
  const [selectedReview, setSelectedReview] = useState<string[]>([]);
  const token = useAppSelector((state) => state.user.userAccount?.token);

  const handleCountryClick = (country: string) => {
    setSelectedCountry(country);
    setManualCountry(country);
    setCountry(country);
    setShowCountryList(false);
    setSelectedCity("");
    setCity("");
    if (!country) {
      setFilteredCityList([]);
    } else {
      const citiesForSelectedCountry = cityList.filter((city) =>
        cities[country]?.includes(city)
      );
      setFilteredCityList(citiesForSelectedCountry);
    }
  };

  const handleCityClick = (city: string) => {
    setManualCity(city);
    setSelectedCity(city);
    setCity(city);
    setShowCityList(false);
    if (!selectedCountry) {
      setManualCity("");
    }
  };

  const handleCountryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let searchTerm = e.target.value;
    setManualCountry(searchTerm);

    if (!searchTerm) {
      setSelectedCountry("");
      setCountry("");
      setCity("");
      setFilteredCityList([]);
    } else {
      const filteredCountries = countryList.filter((l) =>
        l.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredCountryList(filteredCountries);
    }
  };

  const handleCountryInputClick = () => {
    setManualCountry(selectedCountry);
    setShowCountryList(!showCountryList);
  };

  const handleCityInputClick = () => {
    if (!manualCity) {
      setSelectedCity("");
      setCity("");
    }
    setShowCityList(!showCityList);
  };

  const handleCityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let searchTerm = e.target.value;
    setManualCity(searchTerm);
    setSearchCity(searchTerm);

    if (!searchTerm) {
      setSelectedCity("");
      setCity("");
      setFilteredCityList([]);
    } else {
      const filteredCities = cityList.filter((l) =>
        l.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredCityList(filteredCities);
    }
  };

  const resetIndustrySearch = () => {
    setFilteredIndustryList(industryArray);
  };

  const handleIndustryClick = (industry: string) => {
    setSelectedIndustry(industry);
    setManualIndustry(industry);
    setShowIndustryList(false);
    resetIndustrySearch();
    setIndustry(industry);
  };

  const handleIndustryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let searchTerm = e.target.value;
    setManualIndustry(searchTerm);
    setIndustry(searchTerm);

    if (!searchTerm) {
      setSelectedIndustry(undefined);
    } else {
      const filteredIndustry = industryArray.filter((l) =>
        l.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredIndustryList(filteredIndustry);
    }
  };

  const handleIndustryInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const searchTerm = event.target.value;
    setManualIndustry(searchTerm);

    if (searchTerm.trim() === "") {
      setSelectedIndustry(undefined);
    }
  };

  function getAllIndustries() {
    const filteredIndustries: Set<string> = new Set();
    companyItems.forEach((f) => {
      if (f.industry.length > 0) {
        f.industry.forEach((k) => filteredIndustries.add(k));
      }
      if (f.keywords.length > 0) {
        f.keywords.forEach((k) => filteredIndustries.add(k));
      }
    });
    return Array.from(filteredIndustries);
  }

  const handleToggleRating = (rating: number) => {
    const updatedRatings = [...selectedRatings];
    const index = updatedRatings.indexOf(rating);

    if (index !== -1) {
      updatedRatings.splice(index, 1);
    } else {
      updatedRatings.push(rating);
    }

    setSelectedRatings(updatedRatings);
    setRating(updatedRatings.join(","));
  };

  const handleToggleReview = (range: string) => {
    const updatedReviews = [...selectedReview];
    const index = updatedReviews.indexOf(range);

    if (index !== -1) {
      updatedReviews.splice(index, 1);
    } else {
      updatedReviews.push(range);
    }

    setSelectedReview(updatedReviews);

    const reviewsString = updatedReviews
      .map((review) => {
        if (review === "101+") return "+101";
        return review;
      })
      .join(",");

    setReviews(reviewsString);
  };

  // useEffect(() => {
  //   const filteredCompanies = companyItems.filter(
  //     (company) =>
  //       (selectedRatings.length === 0 ||
  //         selectedRatings.includes(Math.round(company.rating))) &&
  //       (selectedReview.length === 0 ||
  //         (selectedReview.includes("1-10") &&
  //           company.reviews >= 1 &&
  //           company.reviews <= 10) ||
  //         (selectedReview.includes("11-50") &&
  //           company.reviews >= 11 &&
  //           company.reviews <= 50) ||
  //         (selectedReview.includes("51-100") &&
  //           company.reviews >= 51 &&
  //           company.reviews <= 100) ||
  //         (selectedReview.includes("101+") && company.reviews >= 101)) &&
  //       (!selectedIndustry ||
  //         company.industry.some((i) =>
  //           i.toLowerCase().includes(selectedIndustry.toLowerCase())
  //         ) ||
  //         company.keywords.some((i) =>
  //           i.toLowerCase().includes(selectedIndustry.toLowerCase())
  //         ))
  //   );

  //   onFilterChange(filteredCompanies);
  // }, [selectedIndustry, selectedRatings, selectedReview, companyItems]);

  useEffect(() => {
    setFilteredIndustryList(getAllIndustries());
  }, [companyItems]);

  useEffect(() => {
    setSelectedCity("");
    dispatch(getCityByCountryAsync(selectedCountry) as any)
      .then((result: any) => {
        if (result.payload && result.payload.length) {
          setCityList(result.payload);
        }
      })
      .catch((error: any) => {
        console.error("Error fetching cities:", error);
      });
  }, [selectedCountry]);

  useEffect(() => {
    setCityList(cities ? cities : []);
  }, [cities]);

  useEffect(() => {
    setCityList([]);
  }, []);

  const filteredCity = cityList?.filter((city: string) =>
    city.toLowerCase().includes(searchCity.toLowerCase())
  );

  useEffect(() => {
    const filteredCity = cityList?.filter((city: string) =>
      city.toLowerCase().includes(searchCity.toLowerCase())
    );
    setFilteredCityList(filteredCity);
  }, [searchCity, cityList]);

  return (
    <Container>
      <FilterByP>Filter by</FilterByP>
      <InputWrapper>
        <SearchInputWrapper>
          <SearchInput
            type="text"
            name="country"
            placeholder={selectedCountry ? selectedCountry : "Enter country"}
            value={manualCountry}
            onClick={handleCountryInputClick}
            onChange={handleCountryChange}
          />
          <SearchButton>
            <SearchIconBlack />
          </SearchButton>
          <ChevronRightWrapper onClick={handleCountryInputClick}>
            <ChevronRightIcon />
          </ChevronRightWrapper>
        </SearchInputWrapper>
        {showCountryList && (
          <CountrySearchWrapper>
            <CountryInputWrapper onClick={() => setShowCountryList(false)}>
              <SearchIcon type="button">
                <SearchIconBlack />
              </SearchIcon>
              <ChevronDown>
                <ChevronDownIcon />
              </ChevronDown>
              <SearchInputCountryList
                placeholder="Enter country"
                type="text"
                value={manualCountry}
                onChange={handleCountryChange}
                autoFocus
              />
            </CountryInputWrapper>
            <CountryList>
              {filteredCountryList.map((country, i) => (
                <CountryItem
                  key={country + i}
                  onClick={() => handleCountryClick(country)}
                >
                  {country}
                </CountryItem>
              ))}
            </CountryList>
          </CountrySearchWrapper>
        )}
      </InputWrapper>

      <InputWrapper>
        <SearchInputWrapper>
          <SearchInput
            type="text"
            name="city"
            placeholder={selectedCity ? selectedCity : "Enter city"}
            value={manualCity}
            onClick={selectedCountry ? handleCityInputClick : undefined}
            onChange={selectedCountry ? handleCityChange : undefined}
            style={{
              cursor: selectedCountry ? "text" : "not-allowed",
            }}
            disabled={!selectedCountry}
          />
          <SearchButton
            style={{
              cursor: selectedCountry ? "text" : "not-allowed",
            }}
          >
            <SearchIconBlack />
          </SearchButton>
          <ChevronRightWrapper
            onClick={selectedCountry ? handleCityInputClick : undefined}
            style={{
              cursor: selectedCountry ? "text" : "not-allowed",
            }}
          >
            <ChevronRightIcon />
          </ChevronRightWrapper>
        </SearchInputWrapper>
        {showCityList && selectedCountry && (
          <CountrySearchWrapper>
            <CountryInputWrapper onClick={() => setShowCityList(false)}>
              <SearchIcon type="button">
                <SearchIconBlack />
              </SearchIcon>
              <ChevronDown>
                <ChevronDownIcon />
              </ChevronDown>
              <SearchInputCountryList
                placeholder="Enter city"
                type="text"
                value={searchCity}
                onChange={handleCityChange}
                autoFocus
              />
            </CountryInputWrapper>
            <CountryList>
              {filteredCity.length > 0 ? (
                filteredCity.map((city, i) => (
                  <CountryItem
                    key={city + i}
                    onClick={() => handleCityClick(city)}
                  >
                    {city}
                  </CountryItem>
                ))
              ) : (
                <ItemFail>
                  {manualCity
                    ? "Choose from existing cities"
                    : "No matching cities found"}
                </ItemFail>
              )}
            </CountryList>
          </CountrySearchWrapper>
        )}
      </InputWrapper>

      <InputWrapper>
        <>
          <SearchInputWrapper>
            <SearchInput
              style={{ paddingLeft: "20px" }}
              type="text"
              name="Industry"
              placeholder={
                selectedIndustry ? selectedIndustry : "Search industries"
              }
              value={manualIndustry}
              onClick={() => setShowIndustryList(!showIndustryList)}
              onChange={handleIndustryInputChange}
            />
            <ChevronRightWrapper
              onClick={() => {
                setShowIndustryList(!showIndustryList);
              }}
            >
              <ChevronRightIcon />
            </ChevronRightWrapper>
          </SearchInputWrapper>
        </>
        {showIndustryList && (
          <IndustrySearchWrapper>
            <CountryInputWrapper onClick={() => setShowIndustryList(false)}>
              <ChevronDown>
                <ChevronDownIcon />
              </ChevronDown>
              <SearchInputCountryList
                style={{ paddingLeft: "20px" }}
                placeholder="Search industries"
                type="text"
                value={manualIndustry}
                onChange={handleIndustryChange}
                autoFocus
              />
            </CountryInputWrapper>
            <CountryList>
              {filteredIndustryList.length > 0 ? (
                filteredIndustryList.map((industry) => (
                  <CountryItem
                    key={industry}
                    onClick={() => handleIndustryClick(industry)}
                  >
                    {replaceUnderscoreWithSpaces(industry)}
                  </CountryItem>
                ))
              ) : (
                <ItemFail>No matching industries found</ItemFail>
              )}
            </CountryList>
          </IndustrySearchWrapper>
        )}
      </InputWrapper>

      {/* RATING */}
      <InputWrapper>
        <>
          <SearchInputWrapper>
            <SearchInput
              type="text"
              name="Industry"
              placeholder="Rating"
              onClick={() => setShowRatingList(!showRatingList)}
              // onChange={handleIndustryInputChange}
            />

            <SearchButton>
              <div
                onMouseEnter={() => setShowRatingTooltip(true)}
                onMouseLeave={() => setShowRatingTooltip(false)}
              >
                <ShieldAlertIcon />
              </div>
              {showRatingTooltip && (
                <Tooltip>
                  The ratings are sourced for informational purposes only. They
                  are not recommendations for action. Kindly use your discretion
                  and conduct additional research before making decisions based
                  on this information.
                </Tooltip>
              )}
            </SearchButton>

            <ChevronRightWrapper
              onClick={() => {
                setShowRatingList(!showRatingList);
              }}
            >
              <ChevronRightIcon />
            </ChevronRightWrapper>
          </SearchInputWrapper>
        </>
        {showRatingList && (
          <IndustrySearchWrapper>
            <CountryInputWrapper onClick={() => setShowRatingList(false)}>
              <ChevronDown>
                <ChevronDownIcon />
              </ChevronDown>
              <SearchInputCountryList
                style={{ paddingLeft: "20px" }}
                placeholder="Rating"
                type="text"
                value={manualRating}
                onChange={handleIndustryChange}
                autoFocus
              />
            </CountryInputWrapper>
            <CountryList>
              {[1, 2, 3, 4, 5].map((rating) => (
                <RatingContainer key={rating}>
                  <FilterSwitch
                    key={rating}
                    label={`${rating}`}
                    isActive={selectedRatings.includes(rating)}
                    onToggle={() => handleToggleRating(rating)}
                    isRating={true}
                  />
                </RatingContainer>
              ))}
            </CountryList>
          </IndustrySearchWrapper>
        )}
      </InputWrapper>

      {/* REVIEWS */}
      <InputWrapper>
        <>
          <SearchInputWrapper>
            <SearchInput
              type="text"
              name="Industry"
              placeholder="Reviews"
              onClick={() => setShowReviewsList(!showReviewsList)}
              // onChange={handleIndustryInputChange}
            />

            <SearchButton>
              <div
                onMouseEnter={() => setShowReviewsTooltip(true)}
                onMouseLeave={() => setShowReviewsTooltip(false)}
              >
                <ShieldAlertIcon />
              </div>
              {showReviewsTooltip && (
                <Tooltip>
                  The reviews are sourced for informational purposes only. They
                  are not recommendations for action. Kindly use your discretion
                  and conduct additional research before making decisions based
                  on this information.
                </Tooltip>
              )}
            </SearchButton>

            <ChevronRightWrapper
              onClick={() => {
                setShowReviewsList(!showReviewsList);
              }}
            >
              <ChevronRightIcon />
            </ChevronRightWrapper>
          </SearchInputWrapper>
        </>
        {showReviewsList && (
          <IndustrySearchWrapper>
            <CountryInputWrapper onClick={() => setShowReviewsList(false)}>
              <ChevronDown>
                <ChevronDownIcon />
              </ChevronDown>
              <SearchInputCountryList
                style={{ paddingLeft: "20px" }}
                placeholder="Reviews"
                type="text"
                value={manualIndustry}
                onChange={handleIndustryChange}
                autoFocus
              />
            </CountryInputWrapper>
            <CountryList>
              {["1-10", "11-50", "51-100", "101+"].map((range) => (
                <FilterSwitchWrapper key={range}>
                  <FilterSwitch
                    label={range}
                    isActive={selectedReview.includes(range)}
                    onToggle={() => handleToggleReview(range)}
                    isRating={false}
                  />
                </FilterSwitchWrapper>
              ))}
            </CountryList>
          </IndustrySearchWrapper>
        )}
      </InputWrapper>
    </Container>
  );
};

export default CompanyFilter;
