import styled from "styled-components";

export const DialogContent = styled.div`
position: fixed;
top: 1%;
  left: 25%;
  background-color: white;
  padding:30px;
  z-index: 3  ;
  width: 874px;
  height: 942px;
  border-radius: 16px;
border: 1px solid var(--border, #E8E8E8);
display: flex;
flex-direction: column;
align-items: end;
`;

export const TitleModal = styled.h3`
color: var(--txt-dark, #001731);
font-family: Montserrat;
font-size: 24px;
font-style: normal;
font-weight: 500;
line-height: 140%; 
width: 100%;
`;

export const IndustriesContainer = styled.div`
  height: 680px;
  width: 100%;
  overflow-y: scroll;
  margin-bottom: 20px;
`;

export const IndustryRow =styled.div`
padding: 8px;
`;

export const IndustryItem = styled.div`
display: flex;

`;

export const SubIndustryItem = styled.div`
  margin-left: 70px;
  display: flex;
`;

export const ToggleButton = styled.button`
`;


export const ExpandButton = styled.button`
display: flex;
`;


// INPUT 
export const InputWrapper = styled.div`
  width: 100%;

`;

export const SearchInputWrapper = styled.div`
  position: relative;
  display: flex;
  padding: 14px 0px 16px 20px;
margin:20px 0;
  height: 54px;
  border-radius: 16px;
  border: 1px solid var(--border, #bdbdbd);
box-sizing: border-box;
  background: var(--wiht, #fff);
`;

export const Input = styled.input`
  width: 90%;
  
  &::placeholder {
    font-size: 17px;
    color: var(--txt-grey-light, #717880);
  }
`;


export const ButtonBlock = styled.div`
  max-height: 52px;
  height: 100%;
  max-width: 224px;
  width: 100%;
  padding: 12px 100px;
  border-radius: 0px 16px 16px 0px;
  background: var(--batton, #0095c0);

  cursor: pointer;
`;

export const IndustrySearchWrapper = styled.div`
  position: relative;
  max-width: 1265px;
  width: 100%;
  padding: 0 14px 20px;
  border-radius: 16px 0px 16px 16px;
  border: 1px solid var(--batton, #0095c0);
  background: var(--wiht, #fff);
  transition: all 0.3s ease;
  margin-left: 30px;
`;

export const CountryInputWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 14px 0px 14px;
`;

export const ChevronDown = styled.button`
  position: absolute;
  right: 0px;
  top: 15px;
`;

export const SearchInputIndustries = styled.input`
  width: 100%;
  font-size: 17px;
  line-height: 130%;
  border-radius: 16px;
  background: #fff;
  color: var(--txt-dark, #001731);
  &::placeholder {
    font-size: 17px;
    line-height: 140%;
    color: var(--txt-grey-light, #717880);
  }
`;

export const IndustryList = styled.ul`
  max-height: 260px;
  overflow-y: scroll;

  padding-right: 40px;

  scrollbar-width: thin;
  scrollbar-color: transparent transparent;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: linear-gradient(#0095c0, #0095c0);
    border-radius: 5px;
  }

  &::-webkit-scrollbar-track {
    background-color: #fafafa;
    border-radius: 5px;
  }
`;




export const IndustryListItem = styled.li`
  padding: 8px 0px;

  font-size: 17px;
  line-height: 140%;

  color: var(--txt-dak-light, #143255);
  cursor: pointer;
  &:hover {
    color: var(--txt-blue, #0095c0);
  }
`;

export const Label = styled.h5`
color: var(--txt-dak-light, #143255);
font-family: Montserrat;
font-size: 17px;
font-style: normal;
font-weight: 400;
line-height: 140%; /* 23.8px */
margin-left: 10px ;
`;