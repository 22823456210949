import React from "react";

const SiteIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 40C8.97195 40 0 31.028 0 20C0 8.97195 8.97195 0 20 0C31.028 0 40 8.97195 40 20C40 31.028 31.028 40 20 40ZM23.6768 30.6323C26.5194 29.6466 28.8482 27.5516 30.1417 24.8672H25.5609C25.3041 26.4115 24.9238 27.8355 24.4326 29.0637C24.201 29.6426 23.948 30.1659 23.6768 30.6323ZM9.85836 24.8673C11.1519 27.5516 13.4806 29.6466 16.3232 30.6324C16.052 30.1659 15.799 29.6426 15.5674 29.0638C15.0761 27.8356 14.6959 26.4116 14.4391 24.8673H9.85836ZM16.3232 9.36766C13.4806 10.3534 11.1519 12.4484 9.85836 15.1328H14.4391C14.6959 13.5885 15.0761 12.1645 15.5674 10.9362C15.799 10.3574 16.052 9.83414 16.3232 9.36766ZM20 8.75C19.1798 8.75 18.2174 9.88227 17.4884 11.7047C17.0863 12.7102 16.7674 13.8708 16.5384 15.1328H23.4616C23.2327 13.8709 22.9139 12.7102 22.5116 11.7047C21.7826 9.88227 20.8202 8.75 20 8.75ZM8.75 20C8.75 20.9657 8.87242 21.9033 9.10234 22.7983H14.1771C14.0949 21.8881 14.0513 20.9517 14.0513 20C14.0513 19.0483 14.0949 18.1119 14.1771 17.2017H9.10234C8.8678 18.116 8.74942 19.0561 8.75 20ZM16.2533 22.7983H23.7468C23.8341 21.8939 23.8798 20.9563 23.8798 20C23.8798 19.0437 23.8342 18.1061 23.7468 17.2017H16.2533C16.1659 18.1061 16.1203 19.0437 16.1203 20C16.1203 20.9563 16.1659 21.8939 16.2533 22.7983ZM20 31.25C20.8202 31.25 21.7826 30.1177 22.5116 28.2953C22.9138 27.2898 23.2326 26.1292 23.4616 24.8672H16.5384C16.7673 26.1291 17.0862 27.2898 17.4884 28.2953C18.2174 30.1177 19.1798 31.25 20 31.25ZM31.25 20C31.25 19.0343 31.1276 18.0967 30.8977 17.2017H25.8228C25.905 18.1119 25.9486 19.0483 25.9486 20C25.9486 20.9517 25.905 21.8881 25.8228 22.7983H30.8977C31.1322 21.884 31.2506 20.9439 31.25 20ZM30.1416 15.1327C28.8482 12.4484 26.5194 10.3534 23.6767 9.36758C23.9479 9.83414 24.2009 10.3574 24.4325 10.9362C24.9238 12.1645 25.304 13.5884 25.5609 15.1328L30.1416 15.1327ZM33.319 20C33.319 27.3441 27.3441 33.319 20 33.319C12.6559 33.319 6.68102 27.3441 6.68102 20C6.68102 12.6559 12.6559 6.68102 20 6.68102C27.3441 6.68102 33.319 12.6559 33.319 20Z"
        fill="url(#paint0_linear_6232_3982)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_6232_3982"
          x1="20"
          y1="0"
          x2="20"
          y2="40"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#01F1FE" />
          <stop offset="1" stopColor="#4FADFE" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default SiteIcon;
