import styled from "styled-components";

export const ButtonBackWrapper = styled.div`
  margin-bottom: 30px;
`;

export const AvatarWrapper = styled.div`
  position: relative;
  display: flex;
  max-width: 100%;
  width: 100%;
  gap: 20px;
  margin-bottom: 22px;
`;

export const Avatar = styled.img`
  width: 100px;
  height: 100px;

  display: flex;

  border-radius: 100px;
  object-fit: cover;
`;

export const AvatarInput = styled.input`
  display: none;
`;

export const AvatarLabel = styled.label`
  position: absolute;
  top: 64px;
  left: 67px;

  cursor: pointer;
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;

  max-width: 363px;
  width: 100%;
`;

export const InputTitle = styled.input`
  padding: 4px 40px 6px 40px;

  font-size: 24px;
  font-weight: 500;
  line-height: 140%;

  border-radius: 16px;
  border: 1px solid var(--border, #bdbdbd);
  background: var(--wiht, #fff);
  color: var(--txt-dark, #001731);

  &:focus {
    border: 1px solid var(--batton, #0095c0);
  }
`;

export const ContactInfoWrapper = styled.div`
  max-width: 515px;

  display: flex;
  align-items: center;
  gap: 8px;
`;

export const MidleInfoWrapper = styled.div`
  max-width: 765px;

  display: flex;
  align-items: center;
  gap: 8px;
`;

export const FooterInfoWrapper = styled.div`
  max-width: 349px;

  display: flex;
  align-items: center;
  gap: 20px;
`;

export const ContactInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 30px;
`;

export const Label = styled.label`
  max-width: 103px;
  width: 100%;
  font-size: 17px;
  font-weight: 500;
  line-height: 140%;

  color: var(--txt-dark, #001731);
`;

export const LabelMidle = styled.label`
  max-width: 138px;
  width: 100%;
  font-size: 17px;
  font-weight: 500;
  line-height: 140%;

  color: var(--txt-dark, #001731);
`;

export const LabelFooter = styled.label`
  max-width: 207px;
  width: 100%;
  font-size: 17px;
  font-weight: 500;
  line-height: 140%;

  color: var(--txt-dark, #001731);
`;

export const Input = styled.input`
  width: 100%;
  padding: 4px 30px 6px 30px;
  border-radius: 16px;
  border: 1px solid var(--border, #bdbdbd);
  background: var(--wiht, #fff);
  cursor: pointer;
  &:focus {
    border: 1px solid var(--batton, #0095c0);
  }
`;

export const SocialmediaWrapper = styled.div`
  max-width: 1060px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 20px;
  column-gap: 30px;
`;

export const Text = styled.h5`
  font-size: 17px;
  line-height: 140%;

  color: var(--txt-dak-light, #143255);
`;

export const RadioContainer = styled.div`
  display: flex;
  gap: 20px;
`;

export const RadioWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

export const KeywordDisplay = styled.div`
  padding: 8px 16px 10px 16px;
  margin-bottom: 5px;

  border-radius: 12px;
  border: 1px solid var(--border, #bdbdbd);
  background: var(--gray-light, #fafafa);
`;

export const KeywordsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 765px;
  gap: 20px;
`;

export const CrossIconWrapper = styled.div`
  width: 16px;
  height: 16px;

  display: flex;
  align-items: center;
  opacity: 0;
  cursor: pointer;
`;

export const Keyword = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 8px 16px 10px 16px;

  border-radius: 12px;
  border: 1px solid var(--border, #bdbdbd);
  background: var(--gray-light, #fafafa);
  &:hover {
    border: 1px solid var(--batton, #0095c0);
    & ${CrossIconWrapper} {
      opacity: 1;
    }
  }
`;

export const KeywordText = styled.h6`
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;

  color: var(--txt-dark, #001731);
`;

export const TextAreaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const TextArea = styled.textarea`
  display: flex;
  max-width: 725px;
  width: 100%;
  height: 414px;

  padding: 20px;
  margin-bottom: 35px;
  align-items: flex-start;

  font-size: 17px;
  line-height: 140%;

  border-radius: 16px;
  border: 1px solid var(--border, #bdbdbd);
  background: var(--wiht, #fff);
  color: var(--txt-dak-light, #143255);
  resize: none;

  &::placeholder {
    color: var(--txt-grey-light, #717880);
    font-size: 17px;
    font-weight: 400;
    line-height: 140%;
  }
  &:focus {
    border: 1px solid var(--batton, #0095c0);
  }
  &::-webkit-scrollbar {
    width: 7px;
  }

  &::-webkit-scrollbar-thumb {
    background: linear-gradient(#0095c0, #0095c0);
    border-radius: 5px;
  }

  &::-webkit-scrollbar-track {
    background-color: #fafafa;
    border-radius: 5px;
  }
`;

export const ChoiceContainer = styled.div`
  display: flex;
  gap: 20px;
`;

export const ChoiceWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

export const ButtonWrapper = styled.div`
  max-width: 128px;
  margin: 0 auto;
`;
