import { StyledButton } from "./styles";

interface IButtonProps {
  disabled?: boolean;
  text: string;
  onClick?: () => void;
  isActive?: boolean;
}

const Button: React.FC<IButtonProps> = ({ text, onClick, disabled,  isActive }) => {
  return (
    <StyledButton type="submit" onClick={onClick} disabled={disabled} $isActive={isActive}>
      {text}
    </StyledButton>
  );
};

export default Button;
