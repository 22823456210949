import { routes } from "../../../routes/routes";
import {
  GreenDot,
  ItemBlock,
  ItemName,
  ItemWrapper,
  Message,
  MessageWrapper,
  StyledLink,
} from "../styles";

type Item = {
  path: string;
  name: string;
  icon: JSX.Element | null;
  message: null | number;
};

interface Props {
  item: Item;
  isOpen: boolean;
  location: any;
}

const SideBarItem = ({ item, isOpen, location }: Props) => {
  const newLocation = location.pathname.includes("account")
    ? `/account${routes.PERSONALINFORMATION}`
    : location.pathname;

  return (
    <StyledLink to={item.path}>
      <ItemBlock $isOpen={isOpen} $isSelected={newLocation === item.path}>
        <ItemWrapper
          $isSelected={newLocation === item.path}
          $isIcon={item.icon === null}
        >
          {item.icon}
          <ItemName $isOpen={isOpen}>{item.name}</ItemName>
        </ItemWrapper>
        {!isOpen && item.message ? (
          <GreenDot $isSelected={location.pathname === item.path} />
        ) : (
          <MessageWrapper $isOpen={isOpen}>
            <Message>{item.message}</Message>
          </MessageWrapper>
        )}
      </ItemBlock>
    </StyledLink>
  );
};

export default SideBarItem;
