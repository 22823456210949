import styled from "styled-components";

export const Container = styled.div``;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px 30px 16px 30px;
  border: 1px solid rgb(0, 76, 98);
  border-radius: 16px;
  width: 100%;
  max-width: 271px;
  cursor: pointer;
`;

export const Button=styled.button`
color: rgb(0, 23, 49);
font-size: 17px;
font-weight: 400;
line-height: 140%;
letter-spacing: 0%;
`;
