import React, { useRef, useState } from "react";
import ReturnButton from "../ReturnButton/ReturnButton";
import {
  Avatar,
  AvatarInput,
  AvatarLabel,
  AvatarWrapper,
  ButtonBackWrapper,
  ButtonWrapper,
  ChoiceContainer,
  ChoiceWrapper,
  ContactInfoContainer,
  ContactInfoWrapper,
  CrossIconWrapper,
  FooterInfoWrapper,
  Input,
  InputTitle,
  InputWrapper,
  Keyword,
  KeywordsWrapper,
  KeywordText,
  Label,
  LabelFooter,
  LabelMidle,
  MidleInfoWrapper,
  RadioContainer,
  RadioWrapper,
  SocialmediaWrapper,
  Text,
  TextArea,
  TextAreaWrapper,
} from "./styles";
import PlusIcon from "../../assets/icons/PlusIcon";
import { RootState } from "../../store/store";
import CircleSwitch from "../CircleSwitch/CircleSwitch";
import DelegateSwitch from "../DelegateSwitch/DelegateSwitch";
import Button from "../Button/Button";
import {
  AvailabilityStatus,
  CompanyData,
  SocialMedia,
  updateProviderData,
} from "../../utils/types";
import { useAppSelector } from "../../store/hooks";
import { useDispatch } from "react-redux";
import {
  uploadFilesToServer,
} from "../../store/slices/userSlice";
import CrossIcon from "../../assets/icons/CrossIcon";
import { useLocation, useNavigate } from "react-router-dom";
import { routes } from "../../routes/routes";
import { defaultAvatar } from "../../assets/img";
import ModalImageErrorMessage from "../ModalImageErrorMessage/ModalImageErrorMessage";

const FactorySettings: React.FC = () => {

  const avatarInputRef = useRef<HTMLInputElement | null>(null);

  const location = useLocation();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const provider: CompanyData = useAppSelector(
    (state: RootState) => state.user.provider!
  );
  const [editProvider, setEditProvider] =
    useState<updateProviderData>(location.state ? location.state : provider);
  const [fileList, setFileList] = useState<File[]>([]);
  const [text, setText] = useState<string>("");
  const [imageTypeError, setImageTypeError] = useState<string | null>(null);

  // const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const newFile = e.target.files?.[0];
  //   if (newFile) {
  //     const allowedFormats = ["image/jpeg", "image/jpg", "image/png"];
  //     const uploadAvatarAllowedSize = 3000000;
  //     if(newFile.size > uploadAvatarAllowedSize){
  //       setImageTypeError("Image size is too large. Please upload image less then 3MB.");
  //       return;
  //     }
  //     if (allowedFormats.includes(newFile.type)) {
  //       try {
  //         const newFileList = [...fileList, newFile];
  //         setFileList(newFileList);
  //         const uploadedAvatars = await dispatch(
  //           uploadFilesToServer({ files: newFileList }) as any
  //         );
  //         dispatch(
  //           updateProviderAvatars({
  //             avatar_link: uploadedAvatars.payload[0].link,
  //           }) as any
  //         );
  //         setFileList([]);
  //       } catch (error) {
  //         console.error("Error uploading file:", error);
  //       }
  //     } else {
  //       setImageTypeError("Invalid image format. Please use valid format / .jpg, .jpeg, .png /")
  //     }
  //   }
  // };

  const handleModalCLose = () => {
    setImageTypeError(null);
    if (avatarInputRef.current !== null) {
      avatarInputRef.current.value = '';
    }
  };


  /**
 * Updates a social media link in the editProvider object.
 * 
 * Given a new social media link and its index, this function updates the corresponding 
 * social media link in the editProvider object and sets the updated state.
 */
  const handleSocialMediaChange = (newLink: string, index: number) => {
    const updatedSocialMedia = [...editProvider.socialMedia];
    const socialMediaLink = newLink.trim() && createSocialMediaLink(newLink, updatedSocialMedia, index);
    updatedSocialMedia[index] = { ...updatedSocialMedia[index], link: socialMediaLink };
    setEditProvider({ ...editProvider, socialMedia: updatedSocialMedia });
  };

  // Creating a working link to a social network
  const createSocialMediaLink = (newLink: string, updatedSocialMedia: SocialMedia[], index: number) => {
    const newLinkTrim = newLink.trim();
    const socialMedia = updatedSocialMedia[index];
    if (newLinkTrim.startsWith("https")) {
      return newLinkTrim;
    } else if (socialMedia.name === 'Web site') {
      return `https://${newLinkTrim}`;
    } else {
      if(socialMedia.name.toLowerCase() === 'linkedin'){
        return `https://${socialMedia.name.toLowerCase()}.com/in/${newLinkTrim}`;
      }
      return `https://${socialMedia.name.toLowerCase()}.com/${newLinkTrim}`;
    }
  }


  const handlePreviewClick = () => {
    navigate(routes.PROVIDERPREVIEW, { state: editProvider });
  };
  const handleRemoveKeyword = (indexToRemove: number) => {
    setEditProvider((prevEditProvider) => ({
      ...prevEditProvider,
      keywords: prevEditProvider.keywords.filter(
        (_, index) => index !== indexToRemove
      ),
    }));
  };
  return (
    <>
      <ButtonBackWrapper>
        <ReturnButton />
      </ButtonBackWrapper>
      <AvatarWrapper>
        <Avatar src={provider.avatar || defaultAvatar} alt="logo" />
        <AvatarInput
          type="file"
          ref={avatarInputRef}
          id="input_file"
          accept="image/*"
          // onChange={handleFileChange}
        />
        <AvatarLabel htmlFor="input_file">
          <PlusIcon />
        </AvatarLabel>
        <InputWrapper>
          <InputTitle
            defaultValue={editProvider.companyName || provider.companyName}
            onChange={(e) =>
              setEditProvider({ ...editProvider, companyName: e.target.value })
            }
          />
        </InputWrapper>
      </AvatarWrapper>
      <ContactInfoContainer>
      {imageTypeError && (
    <ModalImageErrorMessage
      errorMessage={imageTypeError}
      onClose={handleModalCLose}
      />
      )}
        <ContactInfoWrapper>
          <Label>Country:</Label>
          <Input
            // defaultValue={ editProvider.providerContactInfo.country || provider.registrationInfo.country}
            onChange={(e) =>
              setEditProvider({
                ...editProvider,
                providerContactInfo: {
                  ...editProvider.providerContactInfo,
                  country: e.target.value,
                },
              })
            }
          />
        </ContactInfoWrapper>
        <ContactInfoWrapper>
          <Label>City:</Label>
          <Input
            // defaultValue={editProvider.providerContactInfo.city || provider.registrationInfo.city}
            onChange={(e) =>
              setEditProvider({
                ...editProvider,
                providerContactInfo: {
                  ...editProvider.providerContactInfo,
                  city: e.target.value,
                },
              })
            }
          />
        </ContactInfoWrapper>
        <ContactInfoWrapper>
          <Label>Email:</Label>
          <Input
            defaultValue={ editProvider.email || provider.email}
            onChange={(e) =>
              setEditProvider({ ...editProvider, email: e.target.value })
            }
          />
        </ContactInfoWrapper>
        <SocialmediaWrapper>
          {( editProvider.socialMedia || provider.socialMedia).map((social, index) => (
            <ContactInfoWrapper key={index}>
              <Label>{social.name}:</Label>
              <Input placeholder={social.name === 'Web site' ? 'Please, enter your website' : 'Please, enter full link or nickname'}
                defaultValue={social.link || ""}
                onChange={(e) => handleSocialMediaChange(e.target.value, index)}
              />
            </ContactInfoWrapper>
          ))}
        </SocialmediaWrapper>
        <ContactInfoWrapper>
          <Label>Availability</Label>
          <RadioContainer>
            <RadioWrapper>
              <CircleSwitch
                isActive={
                  editProvider.availability === null
                    ? false
                    : editProvider.availability === AvailabilityStatus.NOW ||
                    editProvider.availability.toString() === "NOW"
                }
                onToggle={() =>
                  setEditProvider((prevEditedProvider) => ({
                    ...prevEditedProvider,
                    availability: AvailabilityStatus.NOW,
                  }))
                }
              />
              <Text>Now</Text>
            </RadioWrapper>
            <RadioWrapper>
              <CircleSwitch
                isActive={
                  editProvider.availability === null
                    ? false
                    : editProvider.availability ===
                    AvailabilityStatus.NEXT_WEEK ||
                    editProvider.availability.toString() === "NEXT_WEEK"
                }
                onToggle={() =>
                  setEditProvider((prevEditedProvider) => ({
                    ...prevEditedProvider,
                    availability: AvailabilityStatus.NEXT_WEEK,
                  }))
                }
              />
              <Text>Next week</Text>
            </RadioWrapper>
            <RadioWrapper>
              <CircleSwitch
                isActive={
                  editProvider.availability === null
                    ? false
                    : editProvider.availability ===
                    AvailabilityStatus.NEXT_MONTH ||
                    editProvider.availability.toString() === "NEXT_MONTH"
                }
                onToggle={() =>
                  setEditProvider((prevEditedProvider) => ({
                    ...prevEditedProvider,
                    availability: AvailabilityStatus.NEXT_MONTH,
                  }))
                }
              />
              <Text>Next month</Text>
            </RadioWrapper>
          </RadioContainer>
        </ContactInfoWrapper>
        <ContactInfoWrapper>
          <Label>Industry:</Label>
          <Input
            defaultValue={ editProvider.industry || provider.industry}
            onChange={(e) => {
              const industries = e.target.value.split(",").map((industry) => industry.trim()).filter((industry) => industry !== "");
              setEditProvider({
                ...editProvider,
                industry: industries,
              });
            }}
          />
        </ContactInfoWrapper>
        <ContactInfoWrapper>
          <Label>Keywords:</Label>
          <Input
            type="text"
            value={text}
            onChange={(e) => setText(e.target.value)}
            onKeyDown={(e) => {
              if ((e.key === "Enter" || e.key === ",") && text.trim() !== "") {
                e.preventDefault();
                setEditProvider((prevEditProvider) => ({
                  ...prevEditProvider,
                  keywords: [
                    ...prevEditProvider.keywords,
                    ...text.split(",").map((keyword) => keyword.trim()),
                  ],
                }));
                setText("");
              }
            }}
          />
        </ContactInfoWrapper>
        <KeywordsWrapper>
          {editProvider.keywords.map((keyword, index) => (
            <Keyword key={index}>
              <KeywordText>{keyword}</KeywordText>
              <CrossIconWrapper onClick={() => handleRemoveKeyword(index)}>
                <CrossIcon />
              </CrossIconWrapper>
            </Keyword>
          ))}
        </KeywordsWrapper>
      </ContactInfoContainer>
      <TextAreaWrapper>
        <Label>About:</Label>
        <TextArea
          defaultValue={editProvider.about || provider.about}
          name="AboutProvider"
          id="AboutProvider"
          placeholder="Describe yourself ..."
          onChange={(e) =>
            setEditProvider({ ...editProvider, about: e.target.value })
          }
        />
      </TextAreaWrapper>
      <ContactInfoContainer>
        <MidleInfoWrapper>
          <LabelMidle>Founded:</LabelMidle>
          <Input
            defaultValue={editProvider.founded || provider.founded}
            onChange={(e) =>
              setEditProvider({
                ...editProvider,
                founded: e.target.value as unknown as number,
              })
            }
          />
        </MidleInfoWrapper>
        <MidleInfoWrapper>
          <LabelMidle>Languages:</LabelMidle>
          <Input
            defaultValue={editProvider.languages || provider.languages}
            onChange={(e) =>
              setEditProvider({
                ...editProvider,
                languages: e.target.value.split(","),
              })
            }
          />
        </MidleInfoWrapper>
        <MidleInfoWrapper>
          <LabelMidle>Shipping to:</LabelMidle>
          <Input
            defaultValue={editProvider.shippingTo || provider.shippingTo}
            onChange={(e) =>
              setEditProvider({
                ...editProvider,
                shippingTo: e.target.value.split(","),
              })
            }
          />
        </MidleInfoWrapper>
        <MidleInfoWrapper>
          <LabelMidle>Payment terms:</LabelMidle>
          <Input
            defaultValue={ editProvider.paymentTerms || provider.paymentTerms}
            onChange={(e) =>
              setEditProvider({
                ...editProvider,
                paymentTerms: e.target.value.split(","),
              })
            }
          />
        </MidleInfoWrapper>
      </ContactInfoContainer>
      <ContactInfoContainer>
        <FooterInfoWrapper>
          <LabelFooter>Customization capacity:</LabelFooter>
          <ChoiceContainer>
            <ChoiceWrapper>
              <DelegateSwitch
                isActive={editProvider.customizationCapacity}
                onToggle={() =>
                  setEditProvider((prevEditedProvider) => ({
                    ...prevEditedProvider,
                    customizationCapacity: true,
                  }))
                }
              />
              <Text>Yes</Text>
            </ChoiceWrapper>
            <ChoiceWrapper>
              <DelegateSwitch
                isActive={!editProvider.customizationCapacity}
                onToggle={() =>
                  setEditProvider((prevEditedProvider) => ({
                    ...prevEditedProvider,
                    customizationCapacity: false,
                  }))
                }
              />
              <Text>No</Text>
            </ChoiceWrapper>
          </ChoiceContainer>
        </FooterInfoWrapper>
        <FooterInfoWrapper>
          <LabelFooter>R&D capacity:</LabelFooter>
          <ChoiceContainer>
            <ChoiceWrapper>
              <DelegateSwitch
                isActive={editProvider.rndCapacity}
                onToggle={() =>
                  setEditProvider((prevEditedProvider) => ({
                    ...prevEditedProvider,
                    rndCapacity: true,
                  }))
                }
              />
              <Text>Yes</Text>
            </ChoiceWrapper>
            <ChoiceWrapper>
              <DelegateSwitch
                isActive={!editProvider.rndCapacity}
                onToggle={() =>
                  setEditProvider((prevEditedProvider) => ({
                    ...prevEditedProvider,
                    rndCapacity: false,
                  }))
                }
              />
              <Text>No</Text>
            </ChoiceWrapper>
          </ChoiceContainer>
        </FooterInfoWrapper>
        <FooterInfoWrapper>
          <LabelFooter>Special packing:</LabelFooter>
          <ChoiceContainer>
            <ChoiceWrapper>
              <DelegateSwitch
                isActive={editProvider.specialPacking}
                onToggle={() =>
                  setEditProvider((prevEditedProvider) => ({
                    ...prevEditedProvider,
                    specialPacking: true,
                  }))
                }
              />
              <Text>Yes</Text>
            </ChoiceWrapper>
            <ChoiceWrapper>
              <DelegateSwitch
                isActive={!editProvider.specialPacking}
                onToggle={() =>
                  setEditProvider((prevEditedProvider) => ({
                    ...prevEditedProvider,
                    specialPacking: false,
                  }))
                }
              />
              <Text>No</Text>
            </ChoiceWrapper>
          </ChoiceContainer>
        </FooterInfoWrapper>
        <FooterInfoWrapper>
          <LabelFooter>White label:</LabelFooter>
          <ChoiceContainer>
            <ChoiceWrapper>
              <DelegateSwitch
                isActive={editProvider.whiteLabel}
                onToggle={() =>
                  setEditProvider((prevEditedProvider) => ({
                    ...prevEditedProvider,
                    whiteLabel: true,
                  }))
                }
              />
              <Text>Yes</Text>
            </ChoiceWrapper>
            <ChoiceWrapper>
              <DelegateSwitch
                isActive={!editProvider.whiteLabel}
                onToggle={() =>
                  setEditProvider((prevEditedProvider) => ({
                    ...prevEditedProvider,
                    whiteLabel: false,
                  }))
                }
              />
              <Text>No</Text>
            </ChoiceWrapper>
          </ChoiceContainer>
        </FooterInfoWrapper>
        <FooterInfoWrapper>
          <LabelFooter>Certification:</LabelFooter>
          <ChoiceContainer>
            <ChoiceWrapper>
              <DelegateSwitch
                isActive={editProvider.certification}
                onToggle={() =>
                  setEditProvider((prevEditedProvider) => ({
                    ...prevEditedProvider,
                    certification: true,
                  }))
                }
              />
              <Text>Yes</Text>
            </ChoiceWrapper>
            <ChoiceWrapper>
              <DelegateSwitch
                isActive={!editProvider.certification}
                onToggle={() =>
                  setEditProvider((prevEditedProvider) => ({
                    ...prevEditedProvider,
                    certification: false,
                  }))
                }
              />
              <Text>No</Text>
            </ChoiceWrapper>
          </ChoiceContainer>
        </FooterInfoWrapper>
        <FooterInfoWrapper>
          <LabelFooter>Sample:</LabelFooter>
          <ChoiceContainer>
            <ChoiceWrapper>
              <DelegateSwitch
                isActive={editProvider.sample}
                onToggle={() =>
                  setEditProvider((prevEditedProvider) => ({
                    ...prevEditedProvider,
                    sample: true,
                  }))
                }
              />
              <Text>Yes</Text>
            </ChoiceWrapper>
            <ChoiceWrapper>
              <DelegateSwitch
                isActive={!editProvider.sample}
                onToggle={() =>
                  setEditProvider((prevEditedProvider) => ({
                    ...prevEditedProvider,
                    sample: false,
                  }))
                }
              />
              <Text>No</Text>
            </ChoiceWrapper>
          </ChoiceContainer>
        </FooterInfoWrapper>
      </ContactInfoContainer>
      <ButtonWrapper>
        <Button text={"Preview"} onClick={handlePreviewClick} />
      </ButtonWrapper>
    </>
  );
};

export default FactorySettings;
