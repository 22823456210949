import styled from "styled-components";

export const Title=styled.p`
display: flex;
justify-content: center;
height: 70vh;
align-items: center;
color: var(--txt-dark, #001731);
font-size: 38px;
font-weight: 500;
line-height: 120%; 
`;