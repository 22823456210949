import { Field } from "formik";
import styled from "styled-components";

export const Container = styled.div`
  position: relative;

  max-width: 473px;
  padding: 30px 40px 30px;
  margin: 0 auto 30px;

  border-radius: 16px;
  border: 1px solid var(--dark, #001731);
  background: var(--wiht, #fff);
`;

export const CloseWrapper = styled.div`
  position: absolute;
  right: 19.5px;

  cursor: pointer;
`;

export const TitleWrapper = styled.div`
  margin-bottom: 20px;
`;

export const RadioWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-bottom: 5px;
`;

export const Label = styled.label`
  font-size: 17px;
  line-height: 140%;
  color: var(--txt-dark, #001731);
  cursor: pointer;

  input[type="radio"] {
    opacity: 0;
    position: absolute;

    &:checked + span {
      background-color: var(--batton, #0095c0);
    }

    &:checked + span:after {
      display: block;
    }
  }
`;

export const Checkmark = styled.div`
  position: relative;
  display: inline-block;
  height: 16px;
  width: 16px;
  margin-right: 8px;

  border: 1px solid var(--dark, #001731);
  border-radius: 20px;

  &:after {
    content: "";
    position: absolute;
    display: none;
  }

  input:checked + & {
    background-color: var(--batton, #0095c0);
    border: 1px solid var(--batton, #0095c0);
  }

  input:checked + &:after {
    display: block;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #fdfdfd;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

export const InputField = styled(Field)`
  max-width: 373px;
  width: 100%;
  padding: 0 10px 8px;

  font-size: 17px;
  line-height: 140%;

  border-bottom: 1px solid var(--border, #bdbdbd);
  color: var(--txt-dark, #001731);
  &::placeholder {
    font-size: 17px;
    line-height: 140%;
    color: var(--txt-grey-light, #717880);
  }
`;

export const ButtonWrapper = styled.div`
  max-width: 390px;
  margin: 0 auto 20px;
`;

export const SearchButton = styled.button``;

export const SearchWrapper = styled.div`
  min-height: 50px;
  padding: 0 14px 20px;
  border-radius: 0 0 16px 16px;
  box-shadow: -5px 5px 10px rgba(0, 0, 0, 0.15), 5px 5px 10px rgba(0, 0, 0, 0.15);
  background: var(--wiht, #fff);
  transition: all 0.3s ease;
`;

export const InputSearchWrapper = styled.div`
    display: flex; 
    width: 100%; 
    border-bottom: 1px solid var(--border, #bdbdbd);
    padding-left: 10px;
`;

export const SearchInput = styled.input`
  font-size: 17px;
  line-height: 130%;

  color: var(--txt-dark, #001731);
  &::placeholder {
    font-size: 17px;
    line-height: 140%;
    color: var(--txt-grey-light, #717880);
  }
`;

export const List = styled.ul`
  max-height: 200px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: linear-gradient(#0095c0, #0095c0);
    border-radius: 5px;
  }

  &::-webkit-scrollbar-track {
    background-color: #fafafa;
    border-radius: 5px;
  }
`;

export const Item = styled.li`
  padding: 8px 0px;

  font-size: 17px;
  line-height: 140%;

  color: var(--txt-dak-light, #143255);
  cursor: pointer;
  &:hover {
    color: var(--txt-blue, #0095c0);
  }
`;

export const ItemFail = styled.li`
  text-align: center;
  margin-top: 10px;
  font-size: 17px;
  line-height: 120%;

  color: gray;
  cursor: not-allowed;
`;

export const Verify = styled.h6`
    font-size: 14px;
    line-height: 140%;
    margin-right: 5px;
    color: var(--txt-red, #f00);
`;

export const SearchInputField = styled(Field)`
    max-width: 373px;
    width: 100%;
    padding: 0 10px 8px;

    font-size: 17px;
    line-height: 140%;

    
  color: var(--txt-dark, #001731);
  &::placeholder {
    font-size: 17px;
    line-height: 140%;
    color: var(--txt-grey-light, #717880);
  }
`;

export const InputSearchListWrapper = styled.div`
    width: 100%; 
`;