import React, { useEffect, useRef, useState } from "react";
import {
  ButtonWrapper,
  ChevronDownSortWrapper,
  ChevronRightSortWrapper,
  OrderStatusFilterWrapper,
  OrderStatusFilterButton,
  PageHeaderWrapper,
  SearchInputWrapper,
  SortActiveButton,
  SortActiveWrapper,
  SortButton,
  SortContainer,
  SortWrapper,
  SwitchButton,
  SortButtonWrapper,
  Input,
  ButtonBlock,
} from "./style";
import SearchIcon from "../../../assets/icons/SearchIcon";
import SliderIcon from "../../../assets/icons/SliderIcon";
import { Order } from "../../../utils/types";

interface OrderFilterProps {
  ordersList: Order[];
  onFilterChange: (filteredOrders: Order[]) => void;
}

enum ProcessingStatusType {
  pending = "IN_PENDING",
  confirmed = "CONFIRMED",
  signed = "DOCUMENTS_SIGNED",
  manufactured = "MANUFACTURED",
  sent = "SENT",
  accepted = "RECEIVED_AND_ACCEPTED",
}

const OrdersFilter: React.FC<OrderFilterProps> = ({
  ordersList,
  onFilterChange,
}) => {
  const [activeButton, setActiveButton] = useState("In progress");
  const [showSortOptions, setShowSortOptions] = useState(false);
  const [selectedSortOption, setSelectedSortOption] = useState("Filter");
  const [companySearchText, setCompanySearchText] = useState("");
  const [isButtonSearch, setIsButtonSearch] = useState(false);

  const handleClickButtonStatus = (button: string) => {
    activeButton === button ? setActiveButton("") : setActiveButton(button);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCompanySearchText(e.target.value);
    setIsButtonSearch(false);
  };

  const handleSearchClick = () => {
    if (companySearchText) setIsButtonSearch(true);
  };

  const handleSortOptionToggle = () => {
    setShowSortOptions(!showSortOptions);
  };

  const handleSortOptionSelect = (option: string) => {
    setSelectedSortOption(option);
    setShowSortOptions(false);
    prepareDataForBackend(option);
  };

  const prepareDataForBackend = (filterType: string) => {
    const filterTypes = [
      "Pending, Confirmed",
      "Signed",
      "Manufactured",
      "Sent",
      "Accepted",
    ];

    if (filterTypes.includes(filterType)) {
      const dataToSend = {
        filterType: filterType,
      };
    }
  };

  const toggleRefSortOptions = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutsideSortOptions = (event: MouseEvent) => {
      if (
        toggleRefSortOptions.current &&
        !toggleRefSortOptions.current.contains(event.target as Node)
      ) {
        setShowSortOptions(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutsideSortOptions);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideSortOptions);
    };
  }, []);

  useEffect(() => {
    const filteredOrders = ordersList.filter(
      (order) =>
        (activeButton === "" ||
          (activeButton === "In progress" &&
            (order.status === "In progress" ||
              order.status === "IN_PROGRESS")) ||
          (activeButton === "Completed" &&
            (order.status === "Completed" || order.status === "COMPLETED")) ||
          (activeButton === "Cancelled" &&
            (order.status === "Cancelled" || order.status === "CANCELED"))) &&
        (selectedSortOption === "Filter" ||
          (selectedSortOption === "In pending" &&
            order.processingStatuses[order.processingStatuses.length - 1]
              ?.type === ProcessingStatusType.pending) ||
          (selectedSortOption === "Confirmed" &&
            order.processingStatuses[order.processingStatuses.length - 1]
              ?.type === ProcessingStatusType.confirmed) ||
          (selectedSortOption === "Signed" &&
            order.processingStatuses[order.processingStatuses.length - 1]
              ?.type === ProcessingStatusType.signed) ||
          (selectedSortOption === "Manufactured" &&
            order.processingStatuses[order.processingStatuses.length - 1]
              ?.type === ProcessingStatusType.manufactured) ||
          (selectedSortOption === "Sent" &&
            order.processingStatuses[order.processingStatuses.length - 1]
              ?.type === ProcessingStatusType.sent) ||
          (selectedSortOption === "Accepted" &&
            order.processingStatuses[order.processingStatuses.length - 1]
              ?.type === ProcessingStatusType.accepted)) &&
        (companySearchText === "" ||
          order.name?.includes(companySearchText.toLowerCase().trim()))
    );

    onFilterChange(filteredOrders);
  }, [activeButton, isButtonSearch, selectedSortOption, ordersList]);

  return (
    <>
      <ButtonWrapper>
        <SwitchButton
          isActive={activeButton === "In progress"}
          onClick={() => handleClickButtonStatus("In progress")}
        >
          In progress
        </SwitchButton>
        <SwitchButton
          isActive={activeButton === "Completed"}
          onClick={() => {
            handleClickButtonStatus("Completed");
          }}
        >
          Completed
        </SwitchButton>
        <SwitchButton
          isActive={activeButton === "Cancelled"}
          onClick={() => handleClickButtonStatus("Cancelled")}
        >
          Cancelled
        </SwitchButton>
      </ButtonWrapper>
      <PageHeaderWrapper>
        <SearchInputWrapper>
          <Input
            type="text"
            placeholder="Filter by company name or text"
            value={companySearchText}
            onChange={handleInputChange}
          />
          <ButtonBlock onClick={handleSearchClick}>
            <SearchIcon />
          </ButtonBlock>
        </SearchInputWrapper>

        <SortContainer>
          <SortWrapper>
            {!showSortOptions ? (
              <SortButtonWrapper>
                <SortButton onClick={handleSortOptionToggle}>
                  {selectedSortOption}
                </SortButton>
                <ChevronRightSortWrapper onClick={handleSortOptionToggle}>
                  <SliderIcon />
                </ChevronRightSortWrapper>
              </SortButtonWrapper>
            ) : (
              <SortActiveWrapper ref={toggleRefSortOptions}>
                <SortActiveButton
                  onClick={() => {
                    handleSortOptionToggle();
                    handleSortOptionSelect("Filter");
                  }}
                >
                  <p>Filter</p>
                  <ChevronDownSortWrapper onClick={handleSortOptionToggle}>
                    <SliderIcon />
                  </ChevronDownSortWrapper>
                </SortActiveButton>
                <OrderStatusFilterWrapper>
                <OrderStatusFilterButton
                    onClick={() => handleSortOptionSelect("In pending")}
                  >
                    Order pending
                  </OrderStatusFilterButton>
                  <OrderStatusFilterButton
                    onClick={() => handleSortOptionSelect("Confirmed")}
                  >
                    Order confirmed
                  </OrderStatusFilterButton>
                  <OrderStatusFilterButton
                    onClick={() => handleSortOptionSelect("Signed")}
                  >
                    Documents signed
                  </OrderStatusFilterButton>
                  <OrderStatusFilterButton
                    onClick={() => handleSortOptionSelect("Manufactured")}
                  >
                    Order manufactured
                  </OrderStatusFilterButton>
                  <OrderStatusFilterButton
                    onClick={() => handleSortOptionSelect("Sent")}
                  >
                    Order sent
                  </OrderStatusFilterButton>
                  <OrderStatusFilterButton
                    onClick={() => handleSortOptionSelect("Accepted")}
                  >
                    Order received & Accepted
                  </OrderStatusFilterButton>
                </OrderStatusFilterWrapper>
              </SortActiveWrapper>
            )}
          </SortWrapper>
        </SortContainer>
      </PageHeaderWrapper>
    </>
  );
};

export default OrdersFilter;
