import { Link } from "react-router-dom";
import styled from "styled-components";

export const Wrapper = styled.div`
  margin-bottom: 31px;
  padding-bottom: 166px;
  margin-right: 30px;
`;

export const Tittle = styled.p`
  font-size: 24px;
  font-weight: 500;
  line-height: 140%;
  color: var(--txt-dark, #001731);
`;

export const InputBlock = styled.div`
  display: flex;
  margin: 0 0 30px 30px;
`;

export const Input = styled.input`
  max-width: 938px;
  width: 100%;

  padding: 14px 0px 16px 20px;
  border-radius: 16px 0px 0px 16px;
  border: 1px solid var(--border, #bdbdbd);
  border-right: none;
  background: var(--wiht, #fff);
  &::placeholder {
    font-size: 17px;
    color: var(--txt-grey-light, #717880);
  }
`;

export const ButtonBlock = styled.button`
  max-width: 224px;
  width: 100%;
  padding: 12px 100px;
  border-radius: 0px 16px 16px 0px;
  background: var(--batton, #0095c0);
`;

export const RequestBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-left: 30px;
  margin-bottom: 28px;
`;

export const LogoWrapper = styled.div`
  display: flex;

  width: 50px;
  height: 50px;
`;

export const KeywordsFilter = styled.div`
  margin-left: 30px;
  color: var(--txt-light-blue-dont-active, #a0b7d1);
  border-bottom: 1px solid var(--border, #bdbdbd);
  font-size: 17px;
  line-height: 140%;
  display: grid;
  grid-template-columns: 0.3fr 1.4fr 1.1fr 1.3fr 0.9fr 1.5fr;
  gap: 16px;
  padding: 16px;
  align-items: center;

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;
export const KeywordsFilterElement = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

export const SubtitleFilter = styled.p`
  &:nth-child(2) {
    margin-left: 30px;
  }
  &:nth-child(5) {
    margin-left: 10px;
  }
`;

export const IconContainer = styled.div``;

export const ButtonRating = styled.button`
  display: flex;
  color: var(--txt-light-blue-dont-active, #a0b7d1);
  font-size: 17px;
  line-height: 140%;
  &:hover {
    color: var(--batton, #0095c0);
    transition: all 0.3s;
  }
`;

export const ButtonReview = styled.button`
  display: flex;
  color: var(--txt-light-blue-dont-active, #a0b7d1);
  font-size: 17px;
  line-height: 140%;
  &:hover {
    color: var(--batton, #0095c0);
    transition: all 0.3s;
  }
`;

export const ButtonReviewSaved = styled.div`
  display: flex;
  color: var(--txt-light-blue-dont-active, #a0b7d1);
  font-size: 17px;
  line-height: 140%;
`;

export const InfoMessage = styled.p`
  width: 100%;
  font-size: 20px;
  text-align: center;
  margin: 40px;
  font-weight: 600;
`;

export const CompanyList = styled.div`
  margin-left: 30px;
  position: relative;

  display: grid;
  grid-template-columns: 0.1fr 0.3fr 1.5fr 1fr 0.8fr 1.3fr 1.0fr 0.1fr 0.1fr;
  gap: 16px;
  padding: 16px;
  border-bottom: 1px solid var(--border, #bdbdbd);
  align-items: center;

  &:hover {
    border-bottom: 1px solid var(--border, #979797);
    background: var(--bg, #fdfdfd);
    box-shadow: 0px 2px 5.3px 0px rgba(0, 6, 81, 0.5);
  }

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

export const CompanyComponent = styled.p`
  &:nth-child(1) {
    max-width: 13px;
    width: 100%;
    margin-right: 15px;
  }
  &:nth-child(2) {
    max-width: 250px;
    width: 100%;
  }

  &:nth-child(3) {
    max-width: 159px;
    width: 100%;
    margin-right: 15px;
    cursor: pointer;
    &:hover {
      color: var(--batton, #0095c0);
    }
  }

  &:nth-child(4) {
    max-width: 122px;
    width: 100%;
    margin-right: 30px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:nth-child(5) {
    max-width: 231px;
    width: 100%;
    margin-right: 30px;
  }
`;

export const RatingComponent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const CompanyRoundWrapper = styled.div`
  margin-right: 15px;
  height: 50px;
  width: 50px;
  background: #d9d9d9;
  border-radius: 50%;
  display: flexbox;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;
export const CompanyIconImg = styled.img`
  width: 100%;
  height: 100%;
`;

export const ReviewComponent = styled.p`
  display: flex;
  padding: 20px 10px 0px;
`;

export const FilterContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const RatingReviewBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const PaginationBlock = styled.div`
  display: flex;
  align-items: center;
  margin-top: 30px;
  justify-content: center;
`;

export const RightBlock = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 auto;
`;

export const ButtonSave = styled.button`
  border-radius: 16px;
  background: linear-gradient(
      254deg,
      #0095c0 17.54%,
      rgba(3, 182, 42, 0.6) 90.58%
    ),
    #fff;
  padding: 14px 30px 16px 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: var(--txt-light-blue, #f4ffff);
  font-size: 17px;
  font-weight: 400;
  line-height: 140%;
`;

export const VerticalBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContactLink = styled(Link)`
  width: 100%;
  cursor: pointer;
`;

export const ModalWrapper = styled.div`
  border-radius: 16px;
  background: var(--dark, #001731);
  display: flex;
  width: 365px;
  padding: 30px;
  flex-direction: column;
  align-items: flex-start;
  position: fixed;
  top: 30px;
  right: 30px;
  z-index: 10;
`;

export const ModalParagraph = styled.p`
  color: var(--txt-light-blue, #f4ffff);
  font-size: 17px;
  font-weight: 400;
  line-height: 140%;
`;

export const CrossWhiteIconWrapper = styled.div`
  position: absolute;
  right: 20px;
  top: 10px;
  cursor: pointer;
`;



export const IconWrapper = styled.div`
  cursor: pointer;
  `;

export const IconMarket = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 4px;
`;

export const MarketWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;


export const CompanyModalWrapper = styled.div`
  z-index: 10;
`;

