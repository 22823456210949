import React, { useState } from "react";
import { Company, updateProviderData } from "../../utils/types";
import {
  Avatar,
  AvatarWrapper,
  Container,
  AboutWrapper,
  ImageWrapper,
  SocialWrapper,
  Label,
  AvailabilityWrapper,
  Value,
  Title,
  SocialMedia,
  Link,
  IndustryValue,
  Wrapper,
  Keyword,
  KeywordText,
  KeywordsWrapper,
  AboutBlockWrapper,
  About,
  ListWrapper,
  Item,
  List,
  ListValue,
  IconWrapper,
  Listspecial,
  ProductWrapper,
  PaginationBlock,
  ButtonContainer,
  ButtonWhiteWrapper,
  ButtonWrapper,
} from "./styles";
import SiteIcon from "../../assets/icons/SiteIcon";
import InstIcon from "../../assets/icons/InstIcon";
import FbIcon from "../../assets/icons/FbIcon";
import LinInIcon from "../../assets/icons/LinInIcon";
import { defaultAvatar } from "../../assets/img";
import ItemIcon from "../../assets/icons/ItemIcon";
import SlidePhoto from "../SlidePhoto/SlidePhoto";
import ProductInfo from "../ProductInfo/ProductInfo";
import ArrowLeftIcon from "../../assets/icons/ArrowLeftIccon";
import ArrowRightIcon from "../../assets/icons/ArrowRightIcon";
import Pagination from "../Pagination/Pagination";
import ButtonWhite from "../ButtonWhite/ButtonWhite";
import Button from "../Button/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { updateProvider } from "../../store/slices/userSlice";
import { useDispatch } from "react-redux";
import { routes } from "../../routes/routes";

const FactoryPreview: React.FC = () => {
  // const location = useLocation();
  // const navigate = useNavigate();
  // const dispatch = useDispatch();
  // const provider: Company = location.state;
  // const [currentPage, setCurrentPage] = useState(1);
  // const textAbout = provider?.about ? provider.about.split("\n") : [];

  // const goToPreviousPage = () => {
  //   if (currentPage > 1) {
  //     setCurrentPage(currentPage - 1);
  //   }
  // };

  // const goToNextPage = () => {
  //   if (currentPage < total) {
  //     setCurrentPage(currentPage + 1);
  //   }
  // };

  // const handlePageChange = (newPage: number) => {
  //   setCurrentPage(newPage);
  // };

  // const PerPage = 2;
  // const startIndex = (currentPage - 1) * PerPage;
  // const endIndex = startIndex + PerPage;
  // const displayedItems = provider?.products.slice(startIndex, endIndex) || [];
  // const total = provider?.products
  //   ? Math.ceil(provider.products?.length / PerPage)
  //   : 0;

  // const handleSaveClick = () => {
  //   dispatch(updateProvider(provider as updateProviderData) as any).then(
  //     (result: any) => {}
  //   );
  //   console.log(provider);
  //   navigate(routes.PROFILE);
  // };
  // const handleReturnClick = () => {
  //   navigate(routes.PROVIDERSETTINGS, { state: provider });
  // };

  // const formatAvailability = (availability: string) => {
  //   if (!availability) return "";
  //   return (
  //     availability.charAt(0).toUpperCase() +
  //     availability.slice(1).toLowerCase().split("_").join(" ")
  //   );
  // };

  return (
    <>
      {/* <Container>
        <AboutWrapper>
          <AvatarWrapper>
            <Avatar src={provider?.avatar || defaultAvatar} alt="logo" />
            <Title>{provider?.companyName}</Title>
          </AvatarWrapper>
          <SocialWrapper>
            <AvailabilityWrapper>
              <Label>Availability:</Label>
              <Value>{formatAvailability(provider?.availability || "")}</Value>
            </AvailabilityWrapper>
            <SocialMedia>
              {provider?.socialMedia.map((social, index) => (
                social.link && (
                  <Link target="_blank" key={index} href={social.link}>
                    {social.name === "Web site" && <SiteIcon />}
                    {social.name === "Instagram" && <InstIcon />}
                    {social.name === "Facebook" && <FbIcon />}
                    {social.name === "LinkedIn" && <LinInIcon />}
                  </Link>
                )
              ))}
            </SocialMedia>
          </SocialWrapper>
          <Wrapper>
            <Label>Industry:</Label>
            {provider?.industry.map((item, index) => (
              <IndustryValue key={index}>{item}</IndustryValue>
            ))}
          </Wrapper>
          <Label>Keywords:</Label>
          <KeywordsWrapper>
            {provider?.keywords.map((keyword, index) => (
              <Keyword key={index}>
                <KeywordText>{keyword}</KeywordText>
              </Keyword>
            ))}
          </KeywordsWrapper>
          <AboutBlockWrapper>
            {textAbout.map((line, index) =>
              line ? <About key={index}>{line}</About> : <br key={index} />
            )}
          </AboutBlockWrapper>
          <ListWrapper>
            <List>
              {[
                {
                  label: "Location:",
                  value: `${provider?.registrationInfo.country}, ${provider?.registrationInfo.city}`,
                },
                { label: "Founded:", value: provider?.founded },
                { label: "Languages:", value: provider?.languages },
                { label: "Shipping to:", value: provider?.shippingTo },
                { label: "Payment terms:", value: provider?.paymentTerms },
                { label: "Sample:", value: provider?.sample ? "Yes" : "No" },
              ].map((item, index) => (
                <Item key={index}>
                  <IconWrapper>
                    <ItemIcon />
                  </IconWrapper>
                  <Label>{item.label}</Label>
                  <ListValue>{item.value}</ListValue>
                </Item>
              ))}
            </List>
            <Listspecial>
              {[
                {
                  label: "Customization capacity:",
                  value: provider?.customizationCapacity ? "Yes" : "No",
                },
                {
                  label: "R&D capacity:",
                  value: provider?.rndCapacity ? "Yes" : "No",
                },
                {
                  label: "Special packing:",
                  value: provider?.specialPacking ? "Yes" : "No",
                },
                {
                  label: "White label:",
                  value: provider?.whiteLabel ? "Yes" : "No",
                },
                {
                  label: "Certification:",
                  value: provider?.certification ? "Yes" : "No",
                },
              ].map((item, index) => (
                <Item key={index}>
                  <IconWrapper>
                    <ItemIcon />
                  </IconWrapper>
                  <Label>{item.label}</Label>
                  <ListValue>{item.value}</ListValue>
                </Item>
              ))}
            </Listspecial>
          </ListWrapper>
        </AboutWrapper>
        <ImageWrapper>
          <SlidePhoto photos={provider?.photos} />
          <ProductWrapper>
            <ProductInfo products={displayedItems} />
          </ProductWrapper>
          {provider?.products && provider.products.length > 0 && (
            <PaginationBlock>
              <ArrowLeftIcon onClick={goToPreviousPage} />
              <Pagination
                currentPage={currentPage}
                totalPages={total}
                onPageChange={handlePageChange}
              />
              <ArrowRightIcon onClick={goToNextPage} />
            </PaginationBlock>
          )}
        </ImageWrapper>
      </Container>
      <ButtonContainer>
        <ButtonWhiteWrapper>
          <ButtonWhite text={"Return to editing"} onClick={handleReturnClick} />
        </ButtonWhiteWrapper>
        <ButtonWrapper>
          {/* <Button text={"Save"} onClick={handleSaveClick} /> 
        </ButtonWrapper>
      </ButtonContainer> */}
    </>
  );
};
export default FactoryPreview;
