import { FC } from "react";

interface SunIconProps {
  onClick: () => void;
  isLightTheme: boolean;
}

const SunIcon: FC<SunIconProps> = ({ onClick, isLightTheme }) => {
  return (
    <div id="sun"
      onClick={onClick}
      style={{
        background: isLightTheme ? "var(--batton)" : "transparent",
        borderRadius: "50%",
        padding: "3px",
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16Z"
          stroke="#001731"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 2V4"
          stroke="#001731"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 20V22"
          stroke="#001731"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.93005 4.92999L6.34005 6.33999"
          stroke="#001731"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.66 17.66L19.07 19.07"
          stroke="#001731"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2 12H4"
          stroke="#001731"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20 12H22"
          stroke="#001731"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.34005 17.66L4.93005 19.07"
          stroke="#001731"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.07 4.92999L17.66 6.33999"
          stroke="#001731"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};
export default SunIcon;
