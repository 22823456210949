import styled from "styled-components";

interface Published {
  $isPublish: boolean;
}

export const ButtonWrapper = styled.div`
  max-width: 204px;
  margin-bottom: 20px;
`;

export const ProductsWrapper = styled.ul`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
  margin-bottom: 14px;
`;

export const Product = styled.li`
  position: relative;
  max-width: 367px;
  display: flex;
  flex-direction: column;
  padding: 30px;

  border-radius: 16px;
  border: 1px solid var(--border, #e8e8e8);
  background: var(--wiht, #fff);
`;

export const Image = styled.img`
  width: 307px;
  height: 270px;
  margin-bottom: 14px;
  border-radius: 16px;
  object-fit: scale-down;
`;

export const Title = styled.h3`
  margin-bottom: 12px;

  font-size: 20px;
  line-height: 140%;

  color: var(--txt-dark, #001731);
`;

export const Price = styled.h3`
  margin-top: auto;
  margin-bottom: 2px;

  font-size: 24px;
  font-weight: 600;
  line-height: 140%;

  color: var(--txt-dark, #001731);
`;

export const PublishWrapper = styled.div`
  position: relative;
  margin-bottom: 16px;
`;

export const ChevronWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0;

  display: flex;
  align-items: center;
  padding: 13.91px;

  border-radius: 12px;
  border: 1px solid var(--border, #e8e8e8);
  background: var(--wiht, #fff);
  cursor: pointer;
`;

export const ButtonsWrapper = styled.div`
  position: absolute;
  top: 93%;
  left: 0;

  width: 100%;

  display: flex;
  flex-direction: column;
  row-gap: 16px;

  padding: 0 30px 30px;
  font-size: 17px;
  line-height: 140%;

  color: var(--txt-dak-light, #143255);
  /* border: 1px solid var(--border, #e8e8e8); */
  border-top: none;
  border-radius: 16px;
  background: var(--wiht, #fff);

  cursor: pointer;
  z-index: 1;
  &:focus {
    border: 1px solid var(--batton, #0095c0);
  }
  &::placeholder {
    color: var(--txt-grey-light, #717880);
    font-size: 17px;
    font-weight: 400;
    line-height: 140%;
  }
`;

export const ButtonRed = styled.button`
  padding: 14px 30px 16px 30px;
  width: 100%;
  font-size: 17px;
  line-height: 140%;
  border-radius: 16px;
  color: var(--txt-dark, #001731);
  border: 1px solid var(--red, #f00);
  background: var(--wiht, #fff);
`;

export const NotPublishWrapper = styled.div<Published>`
  position: relative;
  max-width: 367px;
  display: flex;
  flex-direction: column;
  opacity: ${({ $isPublish }) => ($isPublish ? "1" : "0.5")};
`;

export const PaginationBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
`;

export const PaginationContainer = styled.div`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PageActiveButton = styled.div`
  position: absolute;
  bottom: -20px;
  right: 0;

  display: flex;

  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 101px;
  padding: 14px 20px 16px 15px;

  border-radius: 16px;
  border: 1px solid var(--batton, #0095c0);
  background: var(--wiht, #fff);

  cursor: pointer;
`;

export const PageActiveItem = styled.div``;

export const ChevronUpWrapper = styled.div`
  position: absolute;
  top: 110px;
  right: 5px;
`;

export const PageBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const ChevronRightWrapper = styled.div``;

export const PageButton = styled.div`
  display: flex;

  align-items: center;
  gap: 10px;
  padding: 14px 20px 16px 30px;

  border-radius: 16px;
  border: 1px solid var(--border, #bdbdbd);
  background: var(--wiht, #fff);
`;

export const PageWrapper = styled.div`
  position: absolute;
  right: 0;

  max-width: 283px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
`;

export const TextPage = styled.h4`
  font-size: 17px;
  font-weight: 500;
  line-height: 140%;

  color: var(--txt-dak-light, #143255);
`;
