import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import MainTemplate from "../components/MainTemplate/MainTemplate";
import DashBoard from "../pages/DashBoard";
import Requests from "../pages/Requests";
import Agents from "../pages/Agents";
import Orders from "../pages/Orders";
import NotFound from "../pages/NotFound";
import Settings from "../pages/Settings";
import Help from "../pages/Help";
import Contact from "../pages/Contact";
import LogIn from "../pages/LogIn";
import { routes } from "../routes/routes";
import SignUp from "../pages/SignUp";
import Personal from "../pages/Personal";
import Confirmation from "../pages/Confirmation";
import DelegateSearch from "../pages/DelegateSearch";
import PartnerBase from "../pages/PartnerBase";
import ProviderPreview from "../pages/ProviderPreview";
import ProviderSettings from "../pages/ProviderSettings";
import InternetSearch from "../pages/InternetSearch";
import Factories from "../pages/Factories";
import PatnerBaseProvider from "../pages/PatnerBaseProvider";
import Assistants from "../components/Assistants/Assistants";
import Dialogue from "../pages/DialoguePage";
import AgentProvider from "../pages/AgentProvider";
import Profile from "../pages/Profile";
import Account from "../pages/Account";
import EditProduct from "../components/ProductsPage/EditProduct/EditProduct";
import AddProduct from "../components/ProductsPage/AddProduct/AddProduct";
import UnderConstruction from "../pages/UnderConstraction";
import CatalogProducts from "../pages/CatalogProducts";
import Specifitation from "../components/Assistants/Specification/Specification";
import Matching from "../components/Matching/Matching";
import ItemByCatalogPruduct from "../pages/ItemByCatalogPruduct";
import URLContainer from "../components/ListPage/URL Container/URLContainer";

import DelegateSearchBriefPage from "../pages/DelegateSearchBriefPage";
import InternetSearchContact from "../pages/InternetSearchContact";
import ProtectedLayout from "./ProtectedLayout";
import InternetSearchFactoryInfo from "../components/InternetSearchFactoryInfo/InternetSearchFactoryInfo";


export const AppRouter = () =>
  createBrowserRouter(
    createRoutesFromElements(
      <Route>
        <Route index element={<LogIn />} />
        {/* <Route path={routes.SIGN_UP} element={<SignUp />} /> */}
        {/* <Route path={routes.PERSONAL} element={<Personal />} /> */}
        <Route path={routes.CONFIRMATION} element={<Confirmation />} />

        <Route element={<ProtectedLayout />}>
          <Route path={routes.HOME} element={<MainTemplate />}>
            <Route path={routes.EDITPRODUCT} element={<EditProduct />} />
            <Route path={routes.ADDPRODUCT} element={<AddProduct />} />
            <Route path={routes.DELEGATESEARCH} element={<DelegateSearch />} />
            <Route path={routes.DELEGATESEARCHBRIEF} element={<DelegateSearchBriefPage />}/>
            <Route path={routes.PARTNERBASE} element={<PartnerBase />} />
            <Route path={routes.NETWORKSEARCH} element={<InternetSearch />} />
            <Route path={routes.SEARCHCONTACTPROVIDER} element={<InternetSearchContact />} />
            <Route path={routes.COMPANYNETWORK} element={<URLContainer />} />
            <Route path={routes.DASHBOARD} element={<DashBoard />} />
            <Route path={routes.ASSISTANTS} element={<Assistants />} />
            <Route path={routes.FACTORIES} element={<Factories />} />
            <Route path={routes.REQUESTS} element={<Requests />} />
            <Route path={routes.ORDERS} element={<Orders />} />
            <Route path={routes.AGENTS} element={<Agents />} />
            <Route path={routes.FACTORYINFO} element={<InternetSearchFactoryInfo />} />
            <Route path={routes.HELP} element={<Help />} />
            <Route path={routes.CONTACT_US} element={<Contact />} />
            <Route
              path={routes.UNDER_CONSTRUCTION}
              element={<UnderConstruction />}
            />
            <Route
              path={routes.PROVIDERSETTINGS}
              element={<ProviderSettings />}
            />
            <Route path={routes.PROVIDERPREVIEW} element={<ProviderPreview />} />
            <Route path={routes.DIALOG} element={<Dialogue />} />
            <Route path={routes.ACCOUNT} element={<Account />} />
            <Route path={routes.PROFILE} element={<Profile />} />
            <Route
              path={routes.PARTNERBASEPROVIDER}
              element={<PatnerBaseProvider />}
            />
            <Route
              path={routes.COMPANYALLPRODUCTS}
              element={<CatalogProducts />}
            />
            <Route
              path={routes.ITEMBYCATALOGPRODUCT}
              element={<ItemByCatalogPruduct />}
            />
            <Route path={routes.AGENTSPROVIDER} element={<AgentProvider />} />
            <Route path={routes.NOT_FOUND} element={<NotFound />} />
            <Route path={routes.ASSISTANTS} element={<Assistants />}>
              <Route path={"/assistants/matching"} element={<Matching />} />
              <Route
                path={"/assistants/specification"}
                element={<Specifitation />}
              />
              {/*<Route path={"/assistants/something"} element={<Settings />} />*/}
            </Route>
          </Route>
        </Route>
      </Route>
    )
  );
