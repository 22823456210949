import { Field } from "formik";
import styled from "styled-components";

export const Input = styled.input`
  width: 100%;
  border-radius: 16px 0px 0px 16px;
  border: none;
  background: var(--wiht, #fff);
  &::placeholder {
    color: var(--txt-grey-light, #717880);
    font-size: 17px;
    font-weight: 400;
    line-height: 130%;
  }
`;

export const InputBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid #ebebeb;
  background: #fff;
  margin: 0 0 30px 30px;
  position: relative;
`;

export const ButtonSearch = styled.div`
  max-width: 24px;
  width: 100%;
  padding-right: 20px;
`;

export const FilterByP = styled.p`
  min-width: max-content;
  color: var(--txt-dark, #001731);
  font-size: 24px;
  font-weight: 500;
  line-height: 140%;
`;

export const SearchLocationP = styled.p`
  margin: 0 0 10px 30px;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  color: var(--txt-dark, #001731);
  align-self: stretch;
`;

export const IndustryKeqwordsP = styled.p`
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  color: var(--txt-dark, #001731);
  margin: 0 0 10px 30px;
`;

export const ButtonChevron = styled.button`
  max-width: 24px;
  width: 100%;
  padding-right: 13px;
`;

export const RatingWrapperP = styled.p`
  color: var(--txt-dark, #001731);
  font-size: 17px;
  font-weight: 500;
  line-height: 140%;
  margin: 0 10px 10px 30px;
`;

export const RatingInformation = styled.div`
  margin-bottom: 10px;
`;

export const RatingContainer = styled.div`
  margin-left: 10px;
`;

export const StarSwitch = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  margin-left: 10px;
`;

export const StarBlock = styled.div`
  margin-right: 8px;
`;
export const ReviewsInformation = styled.div`
  margin-top: 30px;
  margin-bottom: 10px;
`;

export const RatingTooltip = styled.div`
  color: var(--txt-light-blue-dont-active, #a0b7d1);
  border-radius: 16px;
  border: 1px solid var(--border, #bdbdbd);
  background: var(--wiht, #fff);
  position: absolute;
  transform: translate(128px, 5%);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 241px;
  width: 100%;
  padding: 20px;
  gap: 10px;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
`;

export const ReviewsTooltip = styled.div`
  color: var(--txt-light-blue-dont-active, #a0b7d1);
  border-radius: 16px;
  border: 1px solid var(--border, #bdbdbd);
  background: var(--wiht, #fff);
  position: absolute;
  transform: translate(140px, 10%);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 241px;
  /* width: 100%; */
  padding: 20px;
  /* gap: 10px; */
  /* transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out; */
`;

export const Tooltip = styled.div`
  color: var(--txt-light-blue-dont-active, #a0b7d1);
  border-radius: 16px;
  border: 1px solid var(--border, #bdbdbd);
  background: var(--wiht, #fff);
  position: absolute;
  transform: translate(128px, 5%);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 241px;
  padding: 20px;
  z-index: 1;
`;

export const RatingIconWrapper = styled.div`
  display: flex;
`;

export const ButtonFilter = styled.button`
  padding: 14px 30px 16px 30px;
  margin-left: 30px;
  font-size: 17px;
  line-height: 140%;
  border-radius: 16px;
  color: var(--txt-dark, #001731);
  border: 1px solid var(--battn-border, #004c62);
  background: var(--wiht, #fff);
  &:hover {
    background: var(
      --hover,
      linear-gradient(254deg, #0095c0 17.54%, rgba(3, 182, 42, 0.6) 90.58%)
    );
    color: var(--txt-light-blue, #f4ffff);

    &:active {
      background: var(--green, #03b62a);
      color: var(--txt-light-blue, #f4ffff);
    }
  }
`;

export const IndustrySelect = styled.select`
  appearance: none;
  padding-right: 70px;
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='Icons/chevron-right21'%3E%3Cpath id='Vector' d='M9 18L15 12L9 6' stroke='%23001731' strokeWidth='3' strokeLinecap='round' strokeLinejoin='round'/%3E%3C/g%3E%3C/svg%3E")
    calc(100%) center no-repeat;
  background-size: 30px;
`;

export const SearchInput = styled.input`
  font-size: 17px;
  line-height: 130%;
  border-radius: 16px;
  border: 1px solid #ebebeb;
  background: #fff;
  color: var(--txt-dark, #001731);
  padding: 14px 20px 16px 50px;
  &::placeholder {
    font-size: 17px;
    line-height: 140%;
    color: var(--txt-grey-light, #717880);
  }
`;
// for other component
export const SearchInputIndustries = styled.input`
  width: 100%;
  font-size: 17px;
  line-height: 130%;
  border-radius: 16px;
  background: #fff;
  color: var(--txt-dark, #001731);
  &::placeholder {
    font-size: 17px;
    line-height: 140%;
    color: var(--txt-grey-light, #717880);
  }
`;

export const SearchInputCountryList = styled.input`
  font-size: 17px;
  line-height: 130%;
  border-radius: 16px;
  background: #fff;
  color: var(--txt-dark, #001731);
  padding: 14px 10px 16px 50px;
  &::placeholder {
    font-size: 17px;
    line-height: 140%;
    color: var(--txt-grey-light, #717880);
  }
`;

// for other component
export const IndustryList = styled.ul`
  max-height: 260px;
  overflow-y: scroll;

  padding-right: 40px;

  scrollbar-width: thin;
  scrollbar-color: transparent transparent;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: linear-gradient(#0095c0, #0095c0);
    border-radius: 5px;
  }

  &::-webkit-scrollbar-track {
    background-color: #fafafa;
    border-radius: 5px;
  }
`;
// for other component
export const IndustryItem = styled.li`
  padding: 8px 0px;

  font-size: 17px;
  line-height: 140%;

  color: var(--txt-dak-light, #143255);
  cursor: pointer;
  &:hover {
    color: var(--txt-blue, #0095c0);
  }
`;

export const CountryList = styled.ul`
  max-height: 200px;
  overflow-y: scroll;
  padding-right: 40px;
`;

export const CountryItem = styled.li`
  padding: 8px 0px;

  font-size: 17px;
  line-height: 140%;

  color: var(--txt-dak-light, #143255);
  cursor: pointer;
  &:hover {
    color: var(--txt-blue, #0095c0);
  }
`;

export const CountryInputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
  position: relative;
`;

export const InputWrapper = styled.div`
  display: flex;
`;
export const ButtonWrapper = styled.div`
  /* display: flex; */
  margin: 20px 0 20px 30px;
`;
export const CountrySearchWrapper = styled.div`
  position: absolute;
  z-index: 2;

  max-width: 290px;
  width: 100%;
  padding: 0 14px 20px;
  border-radius: 16px;
  border: 1px solid var(--batton, #0095c0);
  background: var(--wiht, #fff);
  transition: all 0.3s ease;
`;

export const IndustrySearchWrapper = styled.div`
  position: absolute;
  z-index: 2;

  max-width: 270px;

  width: 100%;

  padding: 0 14px 20px;
  border-radius: 16px;
  border: 1px solid var(--batton, #0095c0);
  background: var(--wiht, #fff);
  transition: all 0.3s ease;
`;

export const SearchButton = styled.button`
  position: absolute;
  left: 5px;
  bottom: 10px;
`;

export const SearchIcon = styled.button`
  position: absolute;
`;
// for other component
export const SearchIconInput = styled.button`
  position: absolute;
  /* left: 15px;
  top: 25px; */
`;

export const SearchInputWrapper = styled.div`
  position: relative;
  /* margin-left: 30px; */
  max-width: 368px;
  width: 100%;
`;

export const ChevronDown = styled.button`
  position: absolute;
  right: 0px;
`;

export const ChevronRightWrapper = styled.div`
  position: absolute;
  right: 10px;
  bottom: 15px;
`;

export const SearchWrapper = styled.div`
  padding: 0 14px 20px;
  border-radius: 16px;
  border: 1px solid var(--border, #bdbdbd);
  background: var(--wiht, #fff);
  transition: all 0.3s ease;
`;

export const InputSearchWrapper = styled.div`
  display: flex;
  align-items: center;

  gap: 14px;
  padding: 14px 0px 14px 20px;
`;

export const List = styled.ul`
  max-height: 200px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: linear-gradient(#0095c0, #0095c0);
    border-radius: 5px;
  }

  &::-webkit-scrollbar-track {
    background-color: #fafafa;
    border-radius: 5px;
  }
`;

export const Item = styled.li`
  padding: 8px 0px;

  font-size: 17px;
  line-height: 140%;

  color: var(--txt-dak-light, #143255);
  cursor: pointer;
  &:hover {
    color: var(--txt-blue, #0095c0);
  }
`;

export const ItemFail = styled.li`
  text-align: center;
  font-size: 17px;
  line-height: 120%;

  color: gray;
  cursor: not-allowed;
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  margin-bottom: 25px;
  align-items: center;
  gap: 30px;
`;
