import React from "react";
import { FilterSwitchBlock, IconWrapper } from "./style";
import VectorIcon from "../../assets/icons/VectorIcon";
import StarIcon from "../../assets/icons/StarIcon";

interface FilterSwitchProps {
  label?: string;
  isActive: boolean;
  onToggle: () => void;
  isRating?: boolean;
}

const FilterSwitch: React.FC<FilterSwitchProps> = ({
  label = "",
  isActive,
  onToggle,
  isRating,
}) => {
  return (
    <div style={{ display: "flex", alignItems: 'center'}}>
      <FilterSwitchBlock onClick={onToggle} $back ={isActive?"var(--batton, #0095C0)" : "transparent"}>
        <IconWrapper fill={isActive?"var(--batton, #0095C0)" : "transparent"}>
          <VectorIcon />
        </IconWrapper>
      </FilterSwitchBlock>
      {isRating ? (
        <div>
          {Array.from({ length: parseInt(label, 10) }, (_, i) => (
            <StarIcon key={i} />
          ))}
        </div>
      ) : (
        <span style={{ marginLeft: "5px" }}>{label}</span>
      )}
    </div>
  );
};

export default FilterSwitch;
