import React from "react";

interface ChevronUpIconProps {
  onClick?: () => void;
}

const ChevronUpIcon: React.FC<ChevronUpIconProps> = ({ onClick }) => {
  return (
    <button
      type="button"
      onClick={onClick}
      style={{ background: "none", border: "none", cursor: "pointer", padding: '0', display: 'flex' }}
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Icons/chevron-up07">
          <path
            id="Vector"
            d="M18 15L12 9L6 15"
            stroke="#001731"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
      
    </button>
  );
};

export default ChevronUpIcon;
