import styled from "styled-components";

export const Container = styled.div``;

export const Tittle = styled.p`
font-size: 38px;
font-weight: 500;
line-height: 45.6px;
text-align: left;

`;

export const Subtitle = styled.p`
  color: var(--txt-dak-light, #143255);
  font-size: 24px;
  font-weight: 500;
  line-height: 140%;
  margin-top: 30px;
  margin-bottom: 30px;
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
`;

export const HeaderWrapper= styled.div`
display: flex;
align-items: center;
gap: 20px;
margin-bottom: 20px;
`;