import styled from "styled-components";

export const CompanyPageContainer = styled.div`
  width: 100%;
  background-color: #fdfdfd;
  display: flex;
  margin-bottom: 30px;
`;

export const CompanyInfoContainer = styled.div`
  width: 50%;
`;

export const PhotosWrapper = styled.div`
  max-width: 765px;
`;

export const AvatarNameContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 20px;
`;

export const AvatarContainer = styled.div`
  margin-left: 20px;
`;

export const Avatar = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;

`;

export const NameContainer = styled.div`
  justify-content: flex-start;
`;

export const NameHeader = styled.p`
  font-size: 24px;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: 0%;
  text-align: left;
  color: #001731;
  margin-left: 10px;
`;

export const StarAndNameContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const AvailabilityContainer = styled.div`
  width: 181px;
  height: 42px;
  border: 1px solid #03b62a;
  border-radius: 12px;
  background-color: #eaffef;
  align-items: center;
  padding: 8px 20px 10px 20px;
  display: flex;
  flex-direction: row;
`;

export const AvailabilityHeader = styled.p`
  color: #001731;
  margin-right: 5px;
`;

export const AvailabilityContent = styled.p`
  color: #03b62a;
`;

export const InfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

export const InfoElement = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  align-items: center;
`;

export const BlueDot = styled.div`
  width: 6px;
  height: 6px;
  margin: 0px 6px;
  background-color: #0095c0;
  border-radius: 50%;
`;

export const InfoHeader = styled.p`
  font-family: Montserrat;
  font-size: 17px;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: 0%;
  text-align: left;
  margin-right: 10px;
  white-space: nowrap;
`;

export const InfoContent = styled.p`
  font-family: Montserrat;
  font-size: 17px;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: 0%;
  text-align: left;
`;

export const KeywordsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-top: 10px;
`;

export const KeywordElement = styled.div`
  padding: 8px 16px 10px 16px;
  border: 1px solid #e8e8e8;
  border-radius: 12px;
  background: #fafafa;
  color: #001731;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: 0%;
  margin-right: 15px;
  margin-bottom: 10px;
`;

export const AboutContainer = styled.div`
  color: #143255;
  font-family: Montserrat;
  font-size: 17px;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: 0%;
  text-align: left;
`;

export const LogoContainer = styled.div``;

export const LogoHeader = styled.p`
  font-family: Montserrat;
  font-size: 17px;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: 0%;
  text-align: left;
  margin-right: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
`;

export const LogoWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
`;

export const LogoElement = styled.img`
  width: 160px;
  height: 48px;
  margin-bottom: 20px;
  margin-right: 10px;
`;

export const InfoContainerPlus = styled.div`
  display: flex;
  flex-direction: row;
`;

export const InfoContainerPlusWrapper = styled.div``;

export const LinksContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap:20px;
`;

export const LinkElement = styled.div`
  &:hover {
    cursor: pointer;
  }
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const ButtonReturn = styled.div`
  width: 210px;
  height: 54px;
  font-size: 17px;
  font-weight: 400;
  line-height: 140%;
  border-radius: 16px;
  text-align: center;
  background-color: #ffffff;
  color: #001731;
  border: 1px solid #004c62;
  margin-right: 20px;
  padding: 14px 30px 16px 30px;

  &:hover {
    cursor: pointer;
  }
`;

export const ButtonSaveWrapper = styled.div`
 max-width: 210px;
 width: 100%;
`

export const LeftRightArrowsWrapper = styled.div`
  position: absolute;
  width: 765px;
  height: 470px;
  z-index: 3;
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  top: 47%;
`;

export const ProductWrapper = styled.div`
  max-width: 765px;
  display: flex;
  gap: 30px;
`;

export const ImagesContainer = styled.div`
  max-width: 765px;
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-left: 30px;
`;

export const ButtonWrapper = styled.div`
  max-width: 302px;
  margin: 0 auto 20px;
`;

export const RightArrowContainer = styled.div`
  width: 40px;
  height: 40px;
  &:hover {
    cursor: pointer;
  }
`;
