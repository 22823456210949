import styled from "styled-components";

interface FormFieldProps {
  $isEmpty: boolean;
  $isSaveClicked: boolean;
}

export const Container = styled.div`
`;


export const VerifyInput = styled.input<FormFieldProps>`
  padding: 6px 0px 5px 10px;
  box-sizing: border-box;
  border: 1px solid ${({ $isEmpty, $isSaveClicked }) =>
      $isEmpty && $isSaveClicked ? "rgb(255, 0, 0)" : "rgb(232, 232, 232)"};;
  border-radius: 12px;
  margin-bottom: 18px;
  width: 100%;
  height: 38px;

  &::placeholder {
    margin-bottom: 4px;
    font-size: 17px;
    font-weight: 400;
    line-height: 140%;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--txt-light-blue-dont-active, #a0b7d1);
  }
`;


export const ButtonWrapper= styled.div`
display: flex;
align-items: center;
margin-left: 30px;
margin-bottom: 30px;
`;

export const SaveWrapper= styled.div`
display: flex;
align-items: center;
margin-left: 30px;
margin-bottom: 30px;
gap: 20px;
`;

export const TittleHeaderWrapper=styled.div`
margin-left: 30px;
`;

export const Wrapper=styled.div`
display: inline-flex;
padding: 30px;
flex-direction: column;
gap: 30px;
border-radius: 16px;
border: 1px solid var(--border, #E8E8E8);
background: var(--wiht, #FFF);
max-width: 898px;
width: 100%;
`;

export const Subtitle=styled.p`
color: var(--txt-light-blue-dont-active, #A0B7D1);
font-size: 17px;
font-style: normal;
font-weight: 400;
line-height: 140%; 
`;

export const Verify = styled.h6`
  font-size: 14px;
  line-height: 140%;

  color: var(--txt-red, #f00);
`;

export const VerifyContainer = styled.div`
  display: flex;
  gap: 5px;
  margin-bottom:10px;
  position: relative;
  align-items: center;
`;

export const Input = styled.input`
  padding: 6px 0px 5px 10px;
  box-sizing: border-box;
  border: 1px solid var(--border, #E8E8E8);
  border-radius: 12px;
  margin-bottom: 18px;
  width: 100%;
  height: 38px;

  &::placeholder {
    margin-bottom: 4px;
    font-size: 17px;
    font-weight: 400;
    line-height: 140%;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--txt-light-blue-dont-active, #a0b7d1);
  }
`;

export const TooltipWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
  cursor: pointer;
`;

export const TooltipPhoto = styled.h5`
  position: absolute;
  left: 40px;
  bottom: 0px;

  width: 319px;


  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;

  padding: 20px;

  border-radius: 16px;
  color: var(--txt-dark-light, #143255);
  border: 1px solid var(--border, #bdbdbd);
  background: var(--wiht, #fff);
  line-height: 140%;
  white-space: wrap;
  transform: translate(128px, 5%);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
`;

export const TooltipKeyword = styled.h5`
  position: absolute;
  left: 0px;
  bottom: 0px;

  max-width: 515px;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;

  padding: 20px;

  border-radius: 16px;
  color: var(--txt-dark-light, #143255);
  border: 1px solid var(--border, #bdbdbd);
  background: var(--wiht, #fff);
  line-height: 140%;
  white-space: wrap;
  transform: translate(128px, 5%);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
`;

export const SectionWrapper = styled.div`
  margin-bottom: 4px;
`;

export const ChipsList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 4px;
`;

export const Chips = styled.li`
  background-color: #fafafa;
  padding: 8px 16px 10px 16px;
  margin: 5px 5px 5px 0;
  border-radius: 12px;
  font-size: 14px;
  border: 1px solid var(--border, #E8E8E8);
  display:flex;
  align-items: center;
  gap: 3px;
`;


export const CrossWrapper=styled.div`
cursor: pointer;
`;

export const Description=styled.p`
color: #001731;
font-size: 24px;
font-weight: 500;
line-height: 140%; 
`;


export const TopWrapper=styled.div`
border-bottom: 1px solid var(--border, #E8E8E8);
`;

export const BottomWrapper=styled.div`
display: flex;
padding-bottom: 10px;
border-bottom: 1px solid var(--border, #E8E8E8);
`;


export const MediaUploadWrapper = styled.div`
  display: flex;
`;

export const UploadDialogWrapper = styled.div`
 
`;

export const List = styled.ul`
position: relative;
  display: flex;
  flex-direction: column;
`;

export const ProductItem = styled.li`
  display: flex;
  align-items: center;

  margin-bottom: 20px;

  font-size: 18px;
  line-height: 140%;

  color: var(--txt-dak-light, #143255);
`;


export const VectorWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 18px;
  padding: 5px 4px;
  margin-right: 8px;

  border-radius: 16px;
  background-color: var(--green, #03b62a);
`;

export const CloseUploadWrapper = styled.div`
  display: flex;
  margin-left: 8px;
  align-items: center;
  cursor: pointer;
`;

export const UploadWrapper = styled.div`
display: flex;
justify-content: space-between;
margin-bottom: 10px;
`;


export const ButtonContainer = styled.div`
  display: flex;
  margin-left: auto;
  justify-content: flex-end;
  align-items: flex-end;
`;

export const ImageTooltip = styled.img`
  width: 30px;
  height: 30px;
  margin: 0 5px;
  cursor: pointer;
`;

export const ImageHoverWrapper = styled.div`
  position: absolute;
  top:0;
  left: 860px;
  width: 300px;
  height: 250px;
  border-radius: 16px;
  cursor: pointer;
`;

export const ImageHover = styled.img`
  width: 100%;
  height: 100%;
  object-fit: scale-down;
`;


export const TextareaDescription = styled.textarea`
  width: 100%;

  margin-bottom: 18px;
  padding: 6px 10px 5px;
  box-sizing: border-box;

  resize: none;
  overflow: hidden;

  border: 1px solid var(--border, #e8e8e8);
  border-radius: 12px;

  &::placeholder {
    margin-bottom: 4px;
    font-size: 17px;
    font-weight: 400;
    line-height: 140%;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--txt-light-blue-dont-active, #a0b7d1);
  }
`;