import React, { useState } from "react";
import styled from "styled-components";
import UserLogo from "../components/UserLogo/UserLogo";
import CompanyDetails from "../components/CompanyDetails/CompanyDetails";
import CompanyAddress from "../components/CompanyAddress/CompanyAddress";
import CompanyDocuments from "../components/CompanyDocuments/CompanyDocuments";
import Button from "../components/Button/Button";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { CompanyData, CompanyType, DocumentEnum } from "../utils/types";
import { updateGeneralCompanyInfo } from "../store/slices/userSlice";
import ModalLostChanges from "../components/ModalWindows/ModalLostChanges/ModalLostChanges";
import { useBlocker, useLocation, useNavigate } from "react-router-dom";

const DefaultAvatar = require("../assets/img/default-avatar.png");

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  max-width: 765px;
  margin-bottom: 30px;
  padding: 30px;

  border-radius: 16px;
  border: 1px solid var(--border, #bdbdbd);
  background: var(--wiht, #fff);
`;

const ButtonContainer = styled.div`
  margin-left: auto;
`;

const ButtonFooterContainer = styled.div`
  max-width: 765px;
  display: flex;
  justify-content: end;
`;

const ButtonFooterWrapper = styled.div`
  max-width: 230px;
  display: flex;
  justify-content: center;
`;

const CompanyInformation: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const companyInfo: CompanyData = useAppSelector((store) => store.user.company!);
  const userAccount = useAppSelector((store) => store.user.userAccount!);
  const [updateCompany, setUpdateCompany] = useState<CompanyData>();

  const [showModal, setShowModal] = useState<boolean>(false);
  const [nextLocation, setNextLocation] = useState<string>("");

  const handleSaveClick = () => {
    updateCompany &&
      dispatch(
        updateGeneralCompanyInfo({
          ...updateCompany,
          companyType: userAccount.companyType.toLowerCase() as CompanyType,
        }) as any
      );
    setUpdateCompany({});
  };

  useBlocker(({ nextLocation }) => {
    if (updateCompany && Object.keys(updateCompany).length > 0) {
      setShowModal(true);
      setNextLocation(nextLocation.pathname);
      return true;
    }
    return false;
  });

  const handleDiscardChanges = async () => { /// not good solution async await
    setShowModal(!showModal);
    await setUpdateCompany({});
    // await setUpdateDocument({});
    navigate(nextLocation);
  };

  const handlEditing = () => {
    setShowModal(!showModal);
  };

  return (
    <>
      <Wrapper>
        <UserLogo
          avatar={companyInfo?.avatar || DefaultAvatar}
          changeAvatar={setUpdateCompany}
          newAvatar={updateCompany?.avatar}
        />
        <CompanyDetails
          companyDetails={companyInfo}
          updateCompany={updateCompany}
          onGenralChange={setUpdateCompany}
        />
        <CompanyAddress
          companyDetails={companyInfo}
          onAddressChange={setUpdateCompany}
          updateCompany={updateCompany}
        />
        <ButtonContainer>
          <Button
            text={"Update"}
            onClick={handleSaveClick}
            disabled={!updateCompany || Object.keys(updateCompany).length === 0}
          />
        </ButtonContainer>
      </Wrapper>
      <Wrapper>
        <CompanyDocuments />
      </Wrapper>
      <ButtonFooterContainer>
        <ButtonFooterWrapper>
          <Button
            text={"Request verification"}
          disabled={!updateCompany}
          />
        </ButtonFooterWrapper>
      </ButtonFooterContainer>
      {showModal && (
        <ModalLostChanges
          text='The changes you made to  this page will be lost'
          buttonText="Keep editing"
          buttonWhitetext="Discard"
          handleWhiteButtonClick={handleDiscardChanges}
          handleButtonClick={handlEditing}
        />
      )}
    </>
  );
};

export default CompanyInformation;
