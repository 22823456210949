import styled from "styled-components";


export const Container = styled.div`

`;

export const URLWrapper = styled.iframe`
  width: 100%;
  min-height: 1000px;
  ::-webkit-scrollbar {
    width: 12px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #0095c0;
    border-radius: 6px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #0095c0;
  }
`;

export const Subtitle = styled.p`
  color: var(--txt-dak-light, #143255);
  font-size: 38px;
  font-weight: 500;
  line-height: 140%;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
`;

export const Notification = styled.div`
  background-color: var(--wiht, #fff);
  padding: 10px;
  border: 1px solid var(--batton, #0095c0);
  border-radius: 16px;
  margin-bottom: 15px;
  text-align: center;
  p {
    margin: 0;
  }
  button {
    margin-top: 15px;
  }
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  max-width: 150px;
  margin: 0 auto;
`;

export const Paragraph = styled.p`
  color: var(--txt-dak-light, #143255);
  font-size: 22px;
  font-weight: 400;
  line-height: 140%;
`;

export const LinkText = styled.a`
  color: var(--batton, #0095c0);
  text-decoration: none;
  transition: color 0.3s ease;

  &:hover {
    color: #0056b3;
  }
`;

export const ButtonConnectWrapper = styled.div``;

export const FixedButtonWrapper = styled.div`
  position: fixed;
  bottom: 50px;
  right: 50px;

  padding: 25px 30px;
  text-align: center;

  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.4);
  background: var(--batton, #0095c0);
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: var(--green, #03b62a);
  }
`;

export const ContactFactory = styled.h3`
  font-size: 22px;
  font-weight: 400;
  line-height: 140%;
  color: var(--wiht, #fff);
`;

export const OuterContainer = styled.div`
  width: 1000px;
  height: 1000px;
  overflow: hidden;
`;

export const FbContainer = styled.div`
  width: 100%;
  height: 100%;
  
  .fb-page {
    width: 100% !important; 
    height: 100% !important; 

    /* Пример целевого селектора */
    div[style*="min-width"] {
      min-width: 100% !important; 
      width: 100% !important;
    }
  }
`;