import React, { useState } from "react";
import Title from "../Title/Title";
import {
  Container,
  Input,
  InputWrapper,
  Label,
  TitleWrapper,
  MainTitleWrapper,
  RedStar,
  CheckBox,
  CheckBoxLabel,
  CheckBoxWrapper,
} from "./styles";
import { CompanyAddressProps } from "../../utils/types";

const CompanyAddress: React.FC<CompanyAddressProps> = ({
  companyDetails,
  onAddressChange,
  updateCompany,
}) => {
  const [sameAsRegistered, setSameAsRegistered] = useState(false);

  const handleSameAddressChange = () => {
    setSameAsRegistered((prevState) => !prevState);
    !sameAsRegistered
      ? onAddressChange({
          ...updateCompany,
          operationInfo: {
            ...companyDetails.registrationInfo,
            ...updateCompany?.registrationInfo,
          },
        })
      : onAddressChange({
          ...updateCompany,
          operationInfo: {
            address: companyDetails.operationInfo?.address,
            city: companyDetails.operationInfo?.city,
            country: companyDetails.operationInfo?.country,
            postalCode: companyDetails.operationInfo?.postalCode,
          },
        });
  };  
  return (
    <>
      <MainTitleWrapper >
        <Title text={"Address"} />
      </MainTitleWrapper>
      <Container>
        <InputWrapper >
          <Label>Registered address</Label>
          <Input
            placeholder="Enter address"
            value={
              updateCompany?.registrationInfo?.address ??
              companyDetails?.registrationInfo?.address
            }
            
            onChange={(e) =>
              onAddressChange({
                ...updateCompany,
                registrationInfo: {
                  ...companyDetails.registrationInfo,
                  ...updateCompany?.registrationInfo,
                  address: e.target.value,
                },
              })
            }
          />
          <TitleWrapper>
            <Label>City</Label>
            <RedStar>*</RedStar>
          </TitleWrapper>
          <Input
            placeholder="Enter your City"
            value={
              updateCompany?.registrationInfo?.city ??
              companyDetails?.registrationInfo?.city
            }
            onChange={(e) =>
              onAddressChange({
                ...updateCompany,
                registrationInfo: {
                  ...companyDetails.registrationInfo,
                  ...updateCompany?.registrationInfo,
                  city: e.target.value,
                },
              })
            }
          />
          <TitleWrapper>
            <Label>Country</Label>
            <RedStar>*</RedStar>
          </TitleWrapper>
          <Input
            placeholder="Enter your Country"
            value={
              updateCompany?.registrationInfo?.country ??
              companyDetails?.registrationInfo?.country
            }
            onChange={(e) =>
              onAddressChange({
                ...updateCompany,
                registrationInfo: {
                  ...companyDetails.registrationInfo,
                  ...updateCompany?.registrationInfo,
                  country: e.target.value,
                },
              })
            }
          />
          <TitleWrapper>
            <Label>Postal code</Label>
            <RedStar>*</RedStar>
          </TitleWrapper>
          <Input
            placeholder="Enter your Postal code"
            type="number"
            value={
              updateCompany?.registrationInfo?.postalCode ??
              companyDetails?.registrationInfo?.postalCode 
            }
            onChange={(e) =>
              onAddressChange({
                ...updateCompany,
                registrationInfo: {
                  ...companyDetails.registrationInfo,
                  ...updateCompany?.registrationInfo,
                  postalCode: e.target.value,
                },
              })
            }
          />
        </InputWrapper>

        <InputWrapper>
          <Label>Operational address</Label>
          <Input
            placeholder="Enter address"
            value={
              updateCompany?.operationInfo?.address ??
              companyDetails?.operationInfo?.address
            }
            disabled={sameAsRegistered}
            onChange={(e) =>
              onAddressChange({
                ...updateCompany,
                operationInfo: {
                  ...companyDetails.operationInfo,
                  ...updateCompany?.operationInfo,
                  address: e.target.value,
                },
              })
            }
          />
          <TitleWrapper>
            <Label>City</Label>
            <RedStar>*</RedStar>
          </TitleWrapper>
          <Input
            placeholder="Enter your City"
            value={
              updateCompany?.operationInfo?.city ??
              companyDetails?.operationInfo?.city
            }
            disabled={sameAsRegistered}
            onChange={(e) =>
              onAddressChange({
                ...updateCompany,
                operationInfo: {
                  ...companyDetails.operationInfo,
                  ...updateCompany?.operationInfo,
                  city: e.target.value,
                },
              })
            }
          />
          <TitleWrapper>
            <Label>Country</Label>
            <RedStar>*</RedStar>
          </TitleWrapper>
          <Input
            placeholder="Enter your Country"
            value={
              updateCompany?.operationInfo?.country ??
              companyDetails?.operationInfo?.country
            }
            disabled={sameAsRegistered}
            onChange={(e) =>
              onAddressChange({
                ...updateCompany,
                operationInfo: {
                  ...companyDetails.operationInfo,
                  ...updateCompany?.operationInfo,
                  country: e.target.value,
                },
              })
            }
          />
          <TitleWrapper>
            <Label>Postal code</Label>
            <RedStar>*</RedStar>
          </TitleWrapper>
          <Input
            placeholder="Enter your Postal code"
            type="number"
            value={
              updateCompany?.operationInfo?.postalCode ?? companyDetails?.operationInfo?.postalCode 
                
            }
            disabled={sameAsRegistered}
            onChange={(e) =>
              onAddressChange({
                ...updateCompany,
                operationInfo: {
                  ...companyDetails.operationInfo,
                  ...updateCompany?.operationInfo,
                  postalCode: e.target.value,
                },
              })
            }
          />
          <CheckBoxWrapper>
            <CheckBox
              type="checkbox"
              id="sameAsRegisteredCheckbox"
              onChange={handleSameAddressChange}
              checked={sameAsRegistered}
            />
            <CheckBoxLabel htmlFor="sameAsRegisteredCheckbox">
              Same as registered address
            </CheckBoxLabel>
          </CheckBoxWrapper>
        </InputWrapper>
      </Container>
    </>
  );
};

export default CompanyAddress;
