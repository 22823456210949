import styled from "styled-components";

interface IImageProps {
  $isOpen: boolean;
}

interface IProps {
  $isCenter: boolean;
}

export const Container = styled.div`
  display: flex;
  gap: 30px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 368px;
  width: 100%;
  overflow: hidden;
`;

export const AllProductImgWrapper = styled.div<IProps>`
  display: flex;
 justify-content: ${(props) => (props.$isCenter ? "space-between" : "center")};
  gap: 20px;
  padding: 0 20px;
`;

export const AllImg = styled.img<IImageProps>`
  width: 50px;
  height: 50px;
  border-radius: 10px;
  border: ${(props) =>
    props.$isOpen ? "3px solid var(--batton, #0095c0)" : ""};
  cursor: pointer;
`;

export const SelectedImg = styled.img`
  width: 368px;
  height: 350px;
  border-radius: 16px;
  border: 1px solid var(--border, #bdbdbd);
`;

export const NameOfProduct = styled.h3`
  font-size: 24px;
  font-weight: 500;
  line-height: 140%;

  color: var(--txt-dak-light, #143255);
`;

export const Text = styled.h4`
  font-size: 17px;
  font-weight: 500;
  line-height: 140%;

  color: var(--txt-dark, #001731);
`;

export const InfoWrapper = styled.div`
  margin-top: auto;
`;

export const TextWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

export const TextValue = styled.h5`
  font-size: 17px;
  line-height: 140%;

  color: var(--txt-dak-light, #143255);
`;

export const EllipseWrapper = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;

  cursor: pointer;
`;

export const AllImgWrapper = styled.div`
  display: flex;
  gap: 20px;
`;
