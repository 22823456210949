import React from "react";
import { Item } from "../../../utils/types";
import {
  Product,
  ProductsWrapper,
  Image,
  Title,
  Price,
  RatingReviewsWrapper,
  Reviews,
  PreviewProduct,
} from "./styles";
import Button from "../../Button/Button";
import { renderStars } from "../../CompanyItem/CompanyItem";
import { DefaultAvatar } from "../../RequestPage/style";
interface IProviderProducts {
  products: Item[];
}

const CatalogList: React.FC<IProviderProducts> = ({ products }) => {
  const urlString = window.location.href;
  const urlParts = urlString.split("/");  
  return (
    <ProductsWrapper>
      {products.map(
        ({ id, images, name, price, published }) =>
          published && (
            <Product key={id}>
              <PreviewProduct
                to={`/partner_base-provider/${urlParts[4]}/products/${id}`}
              >
                <Image
                  src={
                    (images && images.length > 0
                      ? images[0]?.link
                      : DefaultAvatar) as string
                  }
                  alt={name}
                />
                <Title>{name}</Title>
                <RatingReviewsWrapper>
                  {renderStars(Math.min(5, 5))}
                  <Reviews>(35)</Reviews>
                </RatingReviewsWrapper>
                <Price>{price}$</Price>
              </PreviewProduct>
              <Button text={"Connect"} />
            </Product>
          )
      )}
    </ProductsWrapper>
  );
};

export default CatalogList;
