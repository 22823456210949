import { customFetch } from "../CustomFetch/CustomFetch";

// const PartnerBaseSearchActions = {
// };



// new actions with custom fetch
export async function getPopularPartnerIndustry() {
  return customFetch(`search/popularpartner`);
};

export async function getPartnerBaseFiltersValues() {
  return customFetch(`search/getFilterValuesPartner`);
};



// export default PartnerBaseSearchActions;