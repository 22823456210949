import styled from "styled-components";

export const Container = styled.div`
  margin: 30px auto;
  & > a {
    display: flex;
    max-width: 256px;
    width: 100%;
  }
`;

export const LogoIcon = styled.img`
  margin-right: 20px;

  width: 80px;
  height: 80px;

  border-radius: 50%;
`;

export const LogoName = styled.h2`
  display: flex;
  align-items: center;
  font-size: 38px;
  font-weight: 500;
  line-height: 45.6px;
  color: var(--txt-dark, #001731);
`;
