import { useState, useEffect } from "react";
import { useNavigate, useBlocker, useLocation } from "react-router-dom";
import ChevronDownIcon from "../../assets/icons/ChevronDownIcon";
import ChevronRightIcon from "../../assets/icons/ChevronRightIcon";
import VectorIcon from "../../assets/icons/VectorIcon";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  uploadFilesToServer,
  resetBrief,
  sendDelegateSearch,
} from "../../store/slices/userSlice";
import { paymentTermsFixList } from "../../utils/constants";
import {
  DelegateSearchType,
  DelegateFileEnum,
  CurrencyType,
  UnitMeasurement,
  HowOften,
  AddressInfo,
} from "../../utils/types";
import CircleSwitch from "../CircleSwitch/CircleSwitch";
import {
  CheckBoxWrapper,
  CheckBox,
  CheckBoxLabel,
} from "../CompanyAddress/styles";
import DelegateSwitch from "../DelegateSwitch/DelegateSwitch";
import MediaUpload from "../MediaUpload/MediaUpload";
import ModalLostChanges from "../ModalWindows/ModalLostChanges/ModalLostChanges";
import ModalSuccessfullyChange from "../ModalWindows/ModalSuccessfullyChange/ModalSuccessfullyChange";
import ReturnButton from "../ReturnButton/ReturnButton";
import TitleHeader from "../TitleHeader/TitleHeader";
import CalendarDelegate from "./Calendar/CalendarDelegate";
import {
  Container,
  ButtonWrapper,
  BlockWrapper,
  TitleText,
  SubtitleText,
  TextArea,
  MediaUploadContainer,
  MediaUploadWrapper,
  UploadDialogWrapper,
  List,
  Item,
  VectorWrapper,
  ImageTooltip,
  ItemName,
  CloseUploadWrapper,
  NumberInput,
  InputWrapper,
  Input,
  ChevronRightSettingsWrapper,
  ItemWrapper,
  OrderWrapper,
  Switch,
  DateOrder,
  LeadWrapper,
  DateBlock,
  DateInput,
  IconWrapper,
  HelpDeliveryWrapper,
  DeliverySubtitleText,
  DeliveryWrapper,
  SubSwitchWrapper,
  ShipmentWrapper,
  InputAddress,
  TextAreaStandarts,
  SendWrapper,
} from "./style";
import CalendarIcon from "../../assets/icons/CalendarIcon";
import CrossIcon from "../../assets/icons/CrossIcon";
import Button from "../Button/Button";


interface DelegateSearchProps {
  delegateSearch: DelegateSearchType;
  setDelegateSearch: React.Dispatch<React.SetStateAction<DelegateSearchType>>;
}

const DelegateSearchPage: React.FC<DelegateSearchProps> = ({
  delegateSearch,
  setDelegateSearch,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const registrationInfo: AddressInfo = useAppSelector(
    (store) => store.user.company?.registrationInfo || {}
  );
  const userAccount = useAppSelector((store) => store.user.userAccount!);
  const brief = useAppSelector<DelegateSearchType>((store) => store.user.brief);

  const [filesToUpload, setFilesToUpload] = useState<File[]>([]);
  const [isCurrencyTypeOpen, setIsCurrencyTypeOpen] = useState<boolean>(false);
  const [measurementTypeValue, setMeasurementTypeValue] = useState<string>("");
  const [quantityTypeValue, setQuantityTypeValue] = useState<string>("");
  const [isPaymentTypeOpen, setIsPaymentTypeOpen] = useState<boolean>(false);
  const [isMeasurementTypeOpen, setIsMeasurementTypeOpen] =
    useState<boolean>(false);
  const [isMeasurementOrderTypeOpen, setIsMeasurementOrderTypeOpen] =
    useState<boolean>(false);
  const [sameAsRegistered, setSameAsRegistered] = useState(false);
  const [fileType, setFileType] = useState<DelegateFileEnum>(
    DelegateFileEnum.specifation
  );
  const [isSelectedLeadTimeDate, setIsSelectedLeadTimeDate] =
    useState<boolean>(false);
  const [isSelectedShipmentDate, setIsSelectedShipmentDate] =
    useState<boolean>(false);
  const [nextLocation, setNextLocation] = useState<string>("");
  const [showModal, setShowModal] = useState<boolean>(false);
  const location = useLocation();
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);

  const stateNeedDelivery = delegateSearch?.needDelivery ?? false;
  const handleAttachmentFilesChange = async (files: File[]) => {
    const saveFiles = await dispatch(
      uploadFilesToServer({ files: files }) as any
    );

    const previousValue = delegateSearch ? delegateSearch[fileType] || [] : [];
    const updatedInfo = {
      ...delegateSearch,
      [fileType]: [...previousValue, ...saveFiles.payload],
    };

    setDelegateSearch((prevState) => ({
      ...prevState,
      ...updatedInfo,
    }));
  };

  const handleRemoveClick = (indexToRemove: number, type: DelegateFileEnum) => {
    const previousValue = delegateSearch ? delegateSearch[type] || [] : [];

    const updatedInfo = {
      ...delegateSearch,
      [type]: previousValue.filter((_, index) => index !== indexToRemove),
    };

    setDelegateSearch((prevState) => ({
      ...prevState,
      ...updatedInfo,
    }));
  };

  const handleCurrencyTypeSelect = (type: CurrencyType) => {
    setIsCurrencyTypeOpen(false);
    setDelegateSearch({ ...delegateSearch, currency: type });
  };

  const handleMeasurementTypeSelect = (
    key: UnitMeasurement,
    value: UnitMeasurement
  ) => {
    setIsMeasurementTypeOpen(false);
    setMeasurementTypeValue(value);
    setDelegateSearch({ ...delegateSearch, priceUnit: key });
  };

  const handleQuantityTypeSelect = (
    key: UnitMeasurement,
    value: UnitMeasurement
  ) => {
    setIsMeasurementTypeOpen(false);
    setQuantityTypeValue(value);
    setDelegateSearch({ ...delegateSearch, orderUnit: key });
  };

  const handlePaymentTypeSelect = (type: string) => {
    setIsPaymentTypeOpen(false);
    setDelegateSearch({ ...delegateSearch, paymentTerms: type });
  };

  const handleSameAddressChange = () => {
    setSameAsRegistered(!sameAsRegistered);
    const { postalCode, country, city, address } = registrationInfo || {};
    !sameAsRegistered
      ? setDelegateSearch({
          ...delegateSearch,
          deliveryAddress:
            postalCode + " " + country + " " + city + " " + address,
        })
      : setDelegateSearch({
          ...delegateSearch,
          deliveryAddress: "",
        });
  };

  const handleSendClick = () => {
    if (delegateSearch) {
      const { id, identification, ...filteredDelegateSearch } = delegateSearch;
      dispatch(
        sendDelegateSearch({
          delegateSearch: filteredDelegateSearch,
          token: userAccount.token,
          placeId: location.state as string,
        })
      ).then(() => {
        setDelegateSearch({});
        setMeasurementTypeValue("");
        setQuantityTypeValue("");
        setShowSuccessModal(!showSuccessModal);
      });
    }
  };

  const handleClearFields = () => {
    setDelegateSearch({});
    setMeasurementTypeValue("");
    setQuantityTypeValue("");
    dispatch(resetBrief());
  };

  useBlocker(({ nextLocation }) => {
    if (
      delegateSearch &&
      Object.keys(delegateSearch).length > 0 &&
      Object.values(delegateSearch).some((value) => {
        return (
          (typeof value === "string" && value.trim().length > 0) ||
          (Array.isArray(value) && value.length > 0) ||
          (typeof value === "number" && value !== 0) ||
          typeof value === "boolean"
        );
      })
    ) {
      setShowModal(true);
      setNextLocation(nextLocation.pathname);
      return true;
    }
    return false;
  });

  const handleDiscardChanges = async () => {
    setShowModal(!showModal);
    await setDelegateSearch({});
    navigate(nextLocation);
  };

  const handlEditing = () => {
    setShowModal(!showModal);
  };

  const formatDate = (date?: string): string => {
    if (!date) return "";

    if (brief) {
      const dateObj = new Date(date);
      const day = dateObj.getUTCDate().toString().padStart(2, "0");
      const month = (dateObj.getUTCMonth() + 1).toString().padStart(2, "0");
      const year = dateObj.getUTCFullYear();
      return `${day}.${month}.${year}`;
    } else {
      if (!/^\d{4}-\d{2}-\d{2}$/.test(date)) {
        return "";
      }

      const [year, month, day] = date.split("-");
      return `${day}.${month}.${year}`;
    }
  };

  useEffect(() => {
    return () => {
      dispatch(resetBrief());
    };
  }, []);

  return (
    <>
      <Container>
        <ButtonWrapper>
          <ReturnButton />
          <TitleHeader text={"Delegate Search"} />
        </ButtonWrapper>
        <BlockWrapper>
          <TitleText>1. Product details:</TitleText>
          <SubtitleText>
            Provide a detailed overview of the product you want to manufacture.
          </SubtitleText>
          <TextArea
            cols={30}
            rows={10}
            placeholder="Describe the product..."
            value={delegateSearch?.overview || ""}
            maxLength={1000}
            onChange={(e) =>
              setDelegateSearch({
                ...delegateSearch,
                overview: e.target.value,
              })
            }
          />
          <MediaUploadContainer>
            <MediaUploadWrapper>
              <SubtitleText>
                Attach the product specification that will include specific
                features, dimensions, materials, and any other technical
                details.
              </SubtitleText>
              <UploadDialogWrapper
                onClick={() => setFileType(DelegateFileEnum.specifation)}
              >
                <MediaUpload
                  selectedFiles={filesToUpload}
                  onFilesChange={(files) => handleAttachmentFilesChange(files)}
                />
              </UploadDialogWrapper>
              <List>
                {delegateSearch?.productSpecifications?.map(
                  (attachment, index) => {
                    return (
                      <Item key={index}>
                        <VectorWrapper>
                          <VectorIcon />
                        </VectorWrapper>
                        <ImageTooltip
                          src={attachment.link}
                          alt={attachment.name}
                        />
                        <ItemName>{attachment.name}</ItemName>
                        <CloseUploadWrapper
                          onClick={() =>
                            handleRemoveClick(
                              index,
                              DelegateFileEnum.specifation
                            )
                          }
                        >
                          <CrossIcon />
                        </CloseUploadWrapper>
                      </Item>
                    );
                  }
                )}
              </List>
            </MediaUploadWrapper>
            <MediaUploadWrapper>
              <SubtitleText>
                If possible, attach sketches and / or photos as a reference.
              </SubtitleText>
              <UploadDialogWrapper
                onClick={() => setFileType(DelegateFileEnum.photos)}
              >
                <MediaUpload
                  selectedFiles={filesToUpload}
                  onFilesChange={(files) => handleAttachmentFilesChange(files)}
                />
              </UploadDialogWrapper>
              <List>
                {delegateSearch?.sketchesOrPhotos?.map((attachment, index) => {
                  return (
                    <Item key={index}>
                      <VectorWrapper>
                        <VectorIcon />
                      </VectorWrapper>
                      <ImageTooltip
                        src={attachment.link}
                        alt={attachment.name}
                      />
                      <ItemName>{attachment.name}</ItemName>
                      <CloseUploadWrapper
                        onClick={() =>
                          handleRemoveClick(index, DelegateFileEnum.photos)
                        }
                      >
                        <CrossIcon />
                      </CloseUploadWrapper>
                    </Item>
                  );
                })}
              </List>
            </MediaUploadWrapper>
          </MediaUploadContainer>
        </BlockWrapper>

        <BlockWrapper>
          <TitleText>2. Price:</TitleText>
          <SubtitleText>
            What is your budget or target price per product unit when picked up
            from the supplier's location (EXW)?
          </SubtitleText>
          <NumberInput
            type="number"
            placeholder="$0.00"
            value={delegateSearch?.price || ""}
            onChange={(e) =>
              setDelegateSearch({ ...delegateSearch, price: +e.target.value })
            }
          />
          <InputWrapper>
            <Input
              placeholder="Choose type of currency"
              readOnly
              value={delegateSearch?.currency || ""}
              onClick={() => {
                setIsCurrencyTypeOpen(!isCurrencyTypeOpen);
              }}
            />
            <ChevronRightSettingsWrapper>
              {!isCurrencyTypeOpen ? (
                <ChevronRightIcon
                  onClick={() => {
                    setIsCurrencyTypeOpen(!isCurrencyTypeOpen);
                  }}
                />
              ) : (
                <ChevronDownIcon
                  onClick={() => {
                    setIsCurrencyTypeOpen(!isCurrencyTypeOpen);
                  }}
                />
              )}
            </ChevronRightSettingsWrapper>
            {isCurrencyTypeOpen && (
              <ItemWrapper>
                {Object.values(CurrencyType).map((currency) => (
                  <Item
                    key={currency}
                    onClick={() => handleCurrencyTypeSelect(currency)}
                  >
                    {currency}
                  </Item>
                ))}
              </ItemWrapper>
            )}
          </InputWrapper>
          <InputWrapper>
            <Input
              placeholder="Choose unit of measurement"
              readOnly
              value={
                measurementTypeValue ||
                UnitMeasurement[
                  delegateSearch.priceUnit as keyof typeof UnitMeasurement
                ] ||
                ""
              }
              onClick={() => {
                setIsMeasurementTypeOpen(!isMeasurementTypeOpen);
              }}
            />

            <ChevronRightSettingsWrapper>
              {!isMeasurementTypeOpen ? (
                <ChevronRightIcon
                  onClick={() => {
                    setIsMeasurementTypeOpen(!isMeasurementTypeOpen);
                  }}
                />
              ) : (
                <ChevronDownIcon
                  onClick={() => {
                    setIsMeasurementTypeOpen(!isMeasurementTypeOpen);
                  }}
                />
              )}
            </ChevronRightSettingsWrapper>
            {isMeasurementTypeOpen && (
              <ItemWrapper>
                {Object.entries(UnitMeasurement).map(([key, value]) => (
                  <Item
                    key={key}
                    onClick={() =>
                      handleMeasurementTypeSelect(
                        key as UnitMeasurement,
                        value as UnitMeasurement
                      )
                    }
                  >
                    {value}
                  </Item>
                ))}
              </ItemWrapper>
            )}
          </InputWrapper>
        </BlockWrapper>
        <BlockWrapper>
          <TitleText>3. Payment terms:</TitleText>
          <SubtitleText>What payment terms do you prefer?</SubtitleText>

          <InputWrapper>
            <Input
              placeholder="Choose payment terms"
              readOnly
              value={delegateSearch?.paymentTerms || ""}
              onClick={() => {
                setIsPaymentTypeOpen(!isPaymentTypeOpen);
              }}
            />
            <ChevronRightSettingsWrapper>
              {!isPaymentTypeOpen ? (
                <ChevronRightIcon
                  onClick={() => {
                    setIsPaymentTypeOpen(!isPaymentTypeOpen);
                  }}
                />
              ) : (
                <ChevronDownIcon
                  onClick={() => {
                    setIsPaymentTypeOpen(!isPaymentTypeOpen);
                  }}
                />
              )}
            </ChevronRightSettingsWrapper>
            {isPaymentTypeOpen && (
              <ItemWrapper>
                {paymentTermsFixList.map((payment) => (
                  <Item
                    key={payment}
                    onClick={() => handlePaymentTypeSelect(payment)}
                  >
                    {payment}
                  </Item>
                ))}
              </ItemWrapper>
            )}
          </InputWrapper>
          {delegateSearch?.paymentTerms === "Other" && (
            <TextArea
              placeholder="Specify other payment terms"
              value={delegateSearch?.paymentTermsOther || ""}
              maxLength={300}
              onChange={(e) =>
                setDelegateSearch({
                  ...delegateSearch,
                  paymentTermsOther: e.target.value,
                })
              }
              cols={10}
              rows={5}
            />
          )}
        </BlockWrapper>
        <BlockWrapper>
          <TitleText>4. Order quantity:</TitleText>
          <SubtitleText>
            What is the total number of product units in your order?
          </SubtitleText>
          <NumberInput
            type="number"
            placeholder="100"
            value={delegateSearch?.orderQuantity || ""}
            onChange={(e) =>
              setDelegateSearch({
                ...delegateSearch,
                orderQuantity: +e.target.value,
              })
            }
          />
          <InputWrapper>
            <Input
              placeholder="Choose unit of measurement"
              readOnly
              value={
                quantityTypeValue ||
                measurementTypeValue ||
                UnitMeasurement[
                  delegateSearch.orderUnit as keyof typeof UnitMeasurement
                ] ||
                ""
              }
              onClick={() => {
                setIsMeasurementOrderTypeOpen(!isMeasurementOrderTypeOpen);
              }}
            />
            <ChevronRightSettingsWrapper>
              {!isMeasurementOrderTypeOpen ? (
                <ChevronRightIcon
                  onClick={() => {
                    setIsMeasurementOrderTypeOpen(!isMeasurementOrderTypeOpen);
                  }}
                />
              ) : (
                <ChevronDownIcon
                  onClick={() => {
                    setIsMeasurementOrderTypeOpen(!isMeasurementOrderTypeOpen);
                  }}
                />
              )}
            </ChevronRightSettingsWrapper>
            {isMeasurementOrderTypeOpen && (
              <ItemWrapper>
                {Object.entries(UnitMeasurement).map(([key, value]) => (
                  <Item
                    key={key}
                    onClick={() =>
                      handleQuantityTypeSelect(
                        key as UnitMeasurement,
                        value as UnitMeasurement
                      )
                    }
                  >
                    {value}
                  </Item>
                ))}
              </ItemWrapper>
            )}
          </InputWrapper>
        </BlockWrapper>
        <BlockWrapper>
          <TitleText>5. Order frequency:</TitleText>
          <SubtitleText>
            How often do you plan to place such orders?
          </SubtitleText>
          <OrderWrapper>
            {Object.entries(HowOften).map(([key, value]) => (
              <Switch
                key={key}
                onClick={() =>
                  setDelegateSearch((prevState) => ({
                    ...prevState,
                    orderFrequency: key as HowOften,
                  }))
                }
              >
                <DateOrder>{value}</DateOrder>
                <CircleSwitch
                  isActive={delegateSearch?.orderFrequency === key}
                />
              </Switch>
            ))}
          </OrderWrapper>
        </BlockWrapper>
        <BlockWrapper>
          <TitleText>6. Lead time:</TitleText>
          <SubtitleText>
            When do you need the products to be manufactured (your deadline)?
          </SubtitleText>
          <LeadWrapper>
            <DateBlock
              onClick={() => {
                setIsSelectedLeadTimeDate(!isSelectedLeadTimeDate);
              }}
            >
              <DateInput
                readOnly
                placeholder="DD.MM.YYYY"
                value={formatDate(delegateSearch?.leadTime) || ""}
              />
              <IconWrapper>
                <CalendarIcon />
              </IconWrapper>
            </DateBlock>

            {isSelectedLeadTimeDate && (
              <CalendarDelegate
                setDelegateSearch={(value) =>
                  setDelegateSearch(value as DelegateSearchType)
                }
                fieldToSet="leadTime"
              />
            )}

            <Switch
              onClick={() =>
                setDelegateSearch({
                  ...delegateSearch,
                  leadTimeAsap: !delegateSearch?.leadTimeAsap,
                })
              }
            >
              <DateOrder>ASAP</DateOrder>
              <CircleSwitch isActive={!!delegateSearch?.leadTimeAsap} />
            </Switch>
          </LeadWrapper>
        </BlockWrapper>
        <BlockWrapper>
          <TitleText>7. Shipment:</TitleText>
          <HelpDeliveryWrapper>
            <DeliverySubtitleText>
              Will you need help with the delivery?
            </DeliverySubtitleText>
            <DeliveryWrapper>
              <SubSwitchWrapper>
                <DelegateSwitch
                  isActive={stateNeedDelivery}
                  onToggle={() =>
                    setDelegateSearch((prevDelegateSearch) => ({
                      ...prevDelegateSearch,
                      needDelivery: true,
                    }))
                  }
                />
                <p>Yes</p>
              </SubSwitchWrapper>
              <SubSwitchWrapper>
                <DelegateSwitch
                  isActive={!stateNeedDelivery}
                  onToggle={() =>
                    setDelegateSearch((prevDelegateSearch) => ({
                      ...prevDelegateSearch,
                      needDelivery: false,
                    }))
                  }
                />
                <p>No</p>
              </SubSwitchWrapper>
            </DeliveryWrapper>
          </HelpDeliveryWrapper>
          {stateNeedDelivery && (
            <>
              <SubtitleText>
                When do you need the products to be delivered (your deadline)?
              </SubtitleText>
              <ShipmentWrapper>
                <DateBlock
                  onClick={() => {
                    setIsSelectedShipmentDate(!isSelectedShipmentDate);
                  }}
                >
                  <DateInput
                    readOnly
                    placeholder="DD.MM.YYYY"
                    value={formatDate(delegateSearch?.deliveryDeadline) || ""}
                  />
                  <IconWrapper>
                    <CalendarIcon />
                  </IconWrapper>
                </DateBlock>

                {isSelectedShipmentDate && (
                  <CalendarDelegate
                    setDelegateSearch={(value) =>
                      setDelegateSearch(value as DelegateSearchType)
                    }
                    fieldToSet="deliveryDeadline"
                  />
                )}
                <Switch
                  onClick={() =>
                    setDelegateSearch({
                      ...delegateSearch,
                      deliveryDeadlineAsap:
                        !delegateSearch?.deliveryDeadlineAsap,
                    })
                  }
                >
                  <DateOrder>ASAP</DateOrder>
                  <CircleSwitch
                    isActive={!!delegateSearch?.deliveryDeadlineAsap}
                  />
                </Switch>
              </ShipmentWrapper>
              <SubtitleText>
                Where do you need the products to be delivered?
              </SubtitleText>
              <InputAddress
                placeholder="Your address"
                value={delegateSearch?.deliveryAddress || ""}
                onChange={(e) =>
                  setDelegateSearch({
                    ...delegateSearch,
                    deliveryAddress: e.target.value,
                  })
                }
              />
              <CheckBoxWrapper>
                <CheckBox
                  type="checkbox"
                  id="sameAsRegisteredCheckbox"
                  onChange={handleSameAddressChange}
                  checked={sameAsRegistered}
                />
                <CheckBoxLabel htmlFor="sameAsRegisteredCheckbox">
                  Same as registered address
                </CheckBoxLabel>
              </CheckBoxWrapper>
            </>
          )}
        </BlockWrapper>
        <BlockWrapper>
          <TitleText>8. Quality standards:</TitleText>
          <SubtitleText>
            Are there any quality standards that the product must meet?
          </SubtitleText>
          <TextArea
            rows={5}
            placeholder="Describe the quality standards that the product must meet..."
            value={delegateSearch?.qualityStandards || ""}
            maxLength={300}
            onChange={(e) =>
              setDelegateSearch({
                ...delegateSearch,
                qualityStandards: e.target.value,
              })
            }
          />
          <SubtitleText>Do you need any certificates?</SubtitleText>
          <TextAreaStandarts
            rows={5}
            placeholder="Describe what certificates you need..."
            value={delegateSearch?.certificates || ""}
            maxLength={300}
            onChange={(e) =>
              setDelegateSearch({
                ...delegateSearch,
                certificates: e.target.value,
              })
            }
          />
        </BlockWrapper>
        <BlockWrapper>
          <TitleText>9. Packaging & labeling:</TitleText>
          <SubtitleText>
            Are there any specific packaging requirements?
          </SubtitleText>
          <TextArea
            rows={5}
            placeholder="Describe the packaging requirements..."
            value={delegateSearch?.packagingRequirements || ""}
            maxLength={300}
            onChange={(e) =>
              setDelegateSearch({
                ...delegateSearch,
                packagingRequirements: e.target.value,
              })
            }
          />
          <SubtitleText>
            Are there any specific labeling or branding requirements?
          </SubtitleText>
          <TextAreaStandarts
            rows={5}
            placeholder="Describe the labeling or branding requirements..."
            value={delegateSearch?.labelingRequirements || ""}
            maxLength={300}
            onChange={(e) =>
              setDelegateSearch({
                ...delegateSearch,
                labelingRequirements: e.target.value,
              })
            }
          />
        </BlockWrapper>
        <BlockWrapper>
          <TitleText>10. Additional requirements:</TitleText>
          <SubtitleText>
            Do you have additional requests that you would like to communicate
            (e.g., documents to be provided, samples, consultation, special
            terms, etc.)?
          </SubtitleText>
          <TextArea
            rows={10}
            placeholder="Describe the packaging requirements..."
            value={delegateSearch?.additionalRequests || ""}
            maxLength={1000}
            onChange={(e) =>
              setDelegateSearch({
                ...delegateSearch,
                additionalRequests: e.target.value,
              })
            }
          />
        </BlockWrapper>
        <SendWrapper>
          <Button
            text={"Send"}
            onClick={handleSendClick}
            disabled={!(Object.keys(delegateSearch).length > 0)}
          />
            {Object.keys(brief).length > 0 && (
            <Button text={"Clear fields"} onClick={handleClearFields} />
          )}
        </SendWrapper>
      </Container>
      {showModal && (
        <ModalLostChanges
          text="The changes you made to this page will be lost"
          buttonText="Keep editing"
          buttonWhitetext="Discard"
          handleWhiteButtonClick={handleDiscardChanges}
          handleButtonClick={handlEditing}
        />
      )}
      {showSuccessModal && (
        <ModalSuccessfullyChange
          text="Your brief have been sent successfully"
          handleIsChanged={() => setShowSuccessModal(!showSuccessModal)}
        />
      )}
    </>
  );
};

export default DelegateSearchPage;
