import styled from "styled-components";

export const UserInformationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
`;


export const UserWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const UserAvatar = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 100%;
  margin-right: 15px;
`;


export const TeamWrapper = styled.div`
  display: flex;
  flex-direction: column;

  max-width: 769px;
  margin-bottom: 30px;
  padding: 30px;

  border-radius: 16px;
  border: 1px solid var(--border, #bdbdbd);
  background: var(--wiht, #fff);
`;

export const TitleAdd = styled.h3`
    font-size: 24px;
    font-weight: 500;
`;

export const TitleTeam = styled.h4`
    font-size: 17px;
    font-weight: 500;
`;

export const UserName = styled.h4`
    font-size: 17px;
    font-weight: 500;
`;

export const UserJobTitle = styled.h6`
    margin: 5px 0px;
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 149, 192, 1);
`;

export const UserEmail = styled.h6`
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 149, 192, 1);
`;

export const UserRole = styled.h5`
    font-size: 17px;
    font-weight: 400;
`;

export const InputBlock = styled.div`
  display: flex;
  margin: 24px 0;
  max-width: 769px;
`;

export const Input = styled.input`
  width: 100%;

  padding: 14px 0px 16px 20px;
  border-radius: 16px 0px 0px 16px;
  border: 1px solid var(--border, #bdbdbd);
  border-right: none;
  background: var(--wiht, #fff);
  &::placeholder {
    font-size: 17px;
    color: var(--txt-grey-light, #717880);
  }
`;

export const ButtonBlock = styled.button`
  max-width: 224px;
  width: 100%;
  padding: 12px 10px;
  border-radius: 0px 16px 16px 0px;
  background: var(--batton, #0095c0);
  color: rgb(255, 255, 255);
`;















// Dropdown menu

export const SortButton = styled.button`
  width: 111px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 17px;
  font-weight: 400;
  padding: 0;
`;



export const SortWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const SortActiveTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 17px;
  font-weight: 400;
  cursor: pointer;
`;


export const SortActiveWrapper = styled.div`
  position: absolute;
  right: 40px;
  top: -13px;
  width: 161px;
  border-radius: 16px;
  border: 1px solid var(--batton, #0095c0);
  background: var(--wiht, #fff);
  display: flex;
  padding: 14px 20px 16px 30px;
  flex-direction: column;
`;



export const ChevronRightSortWrapper = styled.div`
  position: relative;
  right: 0;
`;


export const LocationIndustryButton = styled.button`
padding: 0;
margin-left: 10px;
  font-size: 17px;
  font-weight: 400;
  &:hover {
    color: var(--txt-blue, #0095c0);
  }
`;

export const ItemWrapper = styled.div`
    display: flex;
    margin-top: 10px;
`;

export const RedCrossButton = styled.button`
  display: flex;
  align-items: center;
  padding: 0;
  margin-left: 40px;
`;








// ChangeRoleMessage

export const SnackbarWrapper = styled.div`
  border-radius: 16px;
  background: var(--dark, #001731);
  display: flex;
  width: 365px;
  padding: 30px;
  flex-direction: column;
  align-items: flex-start;
  position: fixed;
  top: 30px;
  right: 30px;
  z-index: 10;
`;

export const SnackbarParagraph = styled.p`
  color: var(--txt-light-blue, #f4ffff);
  font-size: 17px;
  font-weight: 400;
  line-height: 140%;
`;

export const CrossWhiteIconWrapper = styled.div`
  position: absolute;
  right: 20px;
  top: 10px;
  cursor: pointer;
`;