import React, { useEffect, useState } from "react";
import CompanyProfileEdit from "./CompanyProfile/CompanyProfileEdit";
import CompanyProfilePreview from "./CompanyProfilePreview/CompanyProfilePreview";
import {  CompanyProduct, CompanyProfile, CompanyType} from "../../utils/types";
import { useBlocker, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { updateGeneralCompanyInfo } from "../../store/slices/userSlice";

export const parseDateString = (dateString:string)=> {
  const date = new Date(dateString);
  const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"];

  const month = monthNames[date.getUTCMonth()];
  const year = date.getUTCFullYear();

  return `${month} ${year}`;
}

export const parseCapitalString = (string:string)=> {
  const replaceString = string.replace(/_/g, ' ');
  const arr = replaceString.toLowerCase().split('');
  arr[0] = arr[0].toUpperCase();
  return arr.join('');
}

export interface CompanyProfileProps {
  avatar: string|null;
  companyName: string;
  founded: number;
  joined: string;
  profileCompletion: number;
  businessType: string;
  address: string;
  city: string;
  country: string;
  postalCode: number;
  numberEmployees: string;
  industry: string[];
  keywords: string[];
  about: string;
  website: string;
  instagram: string;
  facebook: string;
  linkedIn: string;
  experienceMarketplace: string[];
  languages: string[];
  shipping: string;
  paymentTerms: string[];
  availability: string;
  customization: boolean;
  rnd: boolean;
  specialPacking: boolean;
  whiteLabel: boolean;
  certification: boolean;
  sample: boolean;
  fileList: File[];
  companyPhoto: string[];
  companyProducts: CompanyProduct[];
}

enum Mode {
  Edit = "edit",
  Preview = "preview",
}

const Company: React.FC = () => {

  const companyInfo  = useAppSelector((store) => store.user.company);
  const [mode, setMode] = useState<Mode>(Mode.Edit);
  const [companyState, setCompanyState] = useState(companyInfo);
  const [isStateChanged, setIsStateChanged] = useState(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [nextLocation, setNextLocation] = useState<string>("");

  const navigate = useNavigate()
  const dispatch = useAppDispatch();
  const [updateCompany, setUpdateCompany] = useState<CompanyProfile>();
  const userAccount = useAppSelector((store) => store.user.userAccount!);
  // const [filesToUpload, setFilesToUpload] = useState<File[]>([]);

  const setCompanyStateValue = (key: string, value: any) => {
    setIsStateChanged(true);
    if(key==="address"||key==="city"||key==="country"||key==="postalCode"){
      const res = {...companyState!.operationInfo, [key]: value};
      key = "operationInfo";
      value=res;
    }
    if(key === "shippingTo") {
      const res = [value];
      value = res;
    }

    setCompanyState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
    setUpdateCompany(prev=>({...prev,  [key]: value }))
  };
const saveChanges = ()=>{
  updateCompany &&
  dispatch(
    updateGeneralCompanyInfo({
      ...updateCompany,
      companyType: userAccount.companyType.toLowerCase() as CompanyType,
    }) as any
  );
 setUpdateCompany({});
 setIsStateChanged(false);
}

const handelEditing = () => {
  setShowModal(false);
};

const discardChanges = async ()=>{
  setShowModal(false);
  setCompanyState(companyInfo);
  setIsStateChanged(false);
  await setUpdateCompany({});
  navigate(nextLocation);
}

useBlocker(({nextLocation}) => {
  if ( isStateChanged ) {
    setShowModal(true);
    setNextLocation(nextLocation.pathname);
    return true ;
  }
  return false;
});

useEffect(()=>{
  
  if(companyInfo) {
    if(!companyInfo.industry|| companyInfo.industry.length!==3){
      setCompanyState({...companyState, "industry": ["","",""]})
    }

  }
},[companyInfo])

  return (
    <>

      {mode === Mode.Edit &&
       (
        <CompanyProfileEdit
          companyState={companyState!}
          isStateChanged ={isStateChanged}
          showModal ={showModal}
          setCompanyStateValue={setCompanyStateValue}
          updateCompany={updateCompany}
          setUpdateCompany={setUpdateCompany}
          preview={() => setMode(Mode.Preview)}
          save={saveChanges}
          discard={discardChanges}
          editing={handelEditing}
        />
      )}
      {mode === Mode.Preview && (
        <CompanyProfilePreview
          companyState={companyState!}
          edit={() => setMode(Mode.Edit)}
          save={saveChanges}
          showModal={showModal}
          discard={discardChanges}
          editing={handelEditing}
          isStateChanged ={isStateChanged}
        />
      )}
    </>
  );
};

export default Company;
