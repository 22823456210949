import { useNavigate } from "react-router-dom";
import { searchKeywords } from "../../api/internetSearch";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setKeywordsSearch } from "../../store/slices/internetSearchSlice";
import { ButtonPopularRequest } from "./Styles";
import { routes } from "../../routes/routes";
import { useEffect } from "react";

interface IRequestProps {
  name: string;
}

const PopularRequest = ({ name }: IRequestProps) => {
const dispatch = useAppDispatch();
const token = useAppSelector((state) => state.user.userAccount?.token);
const page = 0;

const handleButtonClick = () => {
  dispatch(setKeywordsSearch(name))
  dispatch(searchKeywords(token as string, page as number))
};

useEffect(() => {
  return () => {
    dispatch(setKeywordsSearch('')); 
  };
}, [dispatch]);


  return <ButtonPopularRequest onClick={handleButtonClick}>{name}</ButtonPopularRequest>;
};

export default PopularRequest;
