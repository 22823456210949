import React, { useState, useEffect, useCallback } from "react";
import Globe from "react-globe.gl";
import {
  ChevronDownSortWrapper,
  ChevronRightSortWrapper,
  FilterWrapper,
  IndustryLocationWrapper,
  LocationIndustryButton,
  SortActiveButton,
  SortActiveWrapper,
  SortButton,
  SortContainer,
  SortWrapper,
} from "./style";
import SliderIcon from "../../../assets/icons/SliderIcon";
import { useAppSelector } from "../../../store/hooks";


const WorldMap4 = () => {
  const [countriesData, setCountriesData] = useState([]);
  const [showSortOptions, setShowSortOptions] = useState(false);
  const [selectedSortOption, setSelectedSortOption] = useState("Filter");
  const [selectedFilter, setSelectedFilter] = useState("users");
  const countries = useAppSelector((state) => state.user.dashboard?.detailsMap);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://raw.githubusercontent.com/holtzy/D3-graph-gallery/master/DATA/world.geojson"
        );
        if (!response.ok) {
          throw new Error("Failed to fetch GeoJSON data");
        }
        const data = await response.json();
        setCountriesData(data.features);
      } catch (error) {
        console.error("Error fetching GeoJSON data:", error);
      }
    };

    fetchData();
  }, []);

  const handleSortOptionToggle = useCallback(() => {
    setShowSortOptions(!showSortOptions);
  }, [showSortOptions]);

  const handleSortOptionSelect = (option: string) => {
    setSelectedSortOption(option.charAt(0).toUpperCase() + option.slice(1));
    setSelectedFilter(option.toLowerCase());
    setShowSortOptions(false);

    const filterDataKey =
      option === "conversionRate"
        ? "conversionRate"
        : option === "revenue"
        ? "revenue"
        : option === "users"
        ? "users"
        : "transactions";
  };

  const markersData = countries?.map((country: any) => ({
    lat: country.geometry.coordinates[0],
    lng: country.geometry.coordinates[1],
    name: country.properties.name,
    users: country.properties.totalCustomers,
    transactions: country.properties.transactions,
    revenue: country.properties.revenue,
  }));

  return (
    <>
      <SortContainer>
        <SortWrapper>
          {!showSortOptions ? (
            <>
              <FilterWrapper>
                <SortButton onClick={handleSortOptionToggle}>
                  {selectedSortOption}
                </SortButton>
                <ChevronRightSortWrapper onClick={handleSortOptionToggle}>
                  <SliderIcon />
                </ChevronRightSortWrapper>
              </FilterWrapper>
            </>
          ) : (
            <SortActiveWrapper>
              <SortActiveButton onClick={handleSortOptionToggle}>
                {selectedSortOption}
                <ChevronDownSortWrapper onClick={handleSortOptionToggle}>
                  <SliderIcon />
                </ChevronDownSortWrapper>
              </SortActiveButton>
              <IndustryLocationWrapper>
                <LocationIndustryButton
                  onClick={() => handleSortOptionSelect("users")}
                >
                  Users
                </LocationIndustryButton>
                <LocationIndustryButton
                  onClick={() => handleSortOptionSelect("transactions")}
                >
                  Transactions
                </LocationIndustryButton>
                <LocationIndustryButton
                  onClick={() => handleSortOptionSelect("revenue")}
                >
                  Revenue
                </LocationIndustryButton>
                {/* <LocationIndustryButton
                  onClick={() => handleSortOptionSelect("conversionRate")}
                >
                  Conversion Rate
                </LocationIndustryButton> */}
              </IndustryLocationWrapper>
            </SortActiveWrapper>
          )}
        </SortWrapper>
      </SortContainer>
      <Globe
        globeImageUrl="//unpkg.com/three-globe/example/img/earth-day.jpg"
        backgroundColor="#fdfdfd"
        width={1500}
        height={1500}
        polygonsData={countriesData}
        polygonAltitude={() => 0.01}
        polygonCapColor={(d: any) => {
          const country = countries?.find(
            (c) => c.properties.name === d.properties.name
          );
          if (country) {
            const selectedMetric =
              selectedFilter as keyof typeof country.properties;
            const minColor = "#d0f3ff";
            const maxColor = "#0095c0";
            const numericValues =
              countries
                ?.map((c) => Number(c.properties[selectedMetric]))
                .filter((value) => !isNaN(value)) || [];
            const maxMetricValue = Math.max(...numericValues);
            const metricValue = Number(country.properties[selectedMetric]);

            const scaledValue =
              !isNaN(metricValue) && !isNaN(maxMetricValue)
                ? selectedMetric === "conversionRate"
                  ? metricValue / 100
                  : metricValue / maxMetricValue
                : 0;

            const red = Math.round(
              parseInt(minColor.slice(1, 3), 16) +
                (parseInt(maxColor.slice(1, 3), 16) -
                  parseInt(minColor.slice(1, 3), 16)) *
                  scaledValue
            );
            const green = Math.round(
              parseInt(minColor.slice(3, 5), 16) +
                (parseInt(maxColor.slice(3, 5), 16) -
                  parseInt(minColor.slice(3, 5), 16)) *
                  scaledValue
            );
            const blue = Math.round(
              parseInt(minColor.slice(5, 7), 16) +
                (parseInt(maxColor.slice(5, 7), 16) -
                  parseInt(minColor.slice(5, 7), 16)) *
                  scaledValue
            );

            return `rgb(${red}, ${green}, ${blue})`;
          } else {
            return "#ccc";
          }
        }}
        polygonSideColor={() => "rgba(0, 100, 0, 0.15)"}
        polygonStrokeColor={() => "#111"}
        polygonsTransitionDuration={300}
        pointsData={markersData}
        pointLabel={(d: any) => `
          <div style="font-size: 18px; font-family: Montserrat, sans-serif; color: #333; background-color: rgba(255, 255, 255, 0.8); padding: 5px; border-radius: 5px;">
          <b style="font-weight: 600;">${d.name}</b><br/>
          Users: ${d.users}<br/>
          Transactions: ${d.transactions}<br/>
          Revenue: ${d.revenue}<br/>
          </div>
        `}
        pointLat={(d: any) => d.lat}
        pointLng={(d: any) => d.lng}
        pointColor={() => "red"}
        pointRadius={0.5}
        pointAltitude={0.02}
      />
    </>
  );
};

export default WorldMap4;
