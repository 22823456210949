import React, { useEffect, useState } from "react";
import { Container, HeaderWrapper, Overlay, Tittle } from "./style";
import ReturnButton from "../ReturnButton/ReturnButton";

import OrdersFilter from "./OrdersFilter/OrdersFilter";
import { Order } from "../../utils/types";
import OrdersList from "./OrdersList/OrdersList";
import OrderDetails from "./OrderDetails/OrderDetails";
import { useAppSelector } from "../../store/hooks";

const OrdersPage: React.FC = () => {
  const orders = useAppSelector((state) => state.user.orders);
  const [ordersList, setOrdersList] = useState<Order[]>([]);
  const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);
  const [filteredOrders, setFilteredOrders] = useState<Order[]>([]);

  useEffect(() => {
    if (orders !== null) {
      setOrdersList([...orders]);
    } 
  }, [orders]);

  return (
    <Container>
      <HeaderWrapper>
        <ReturnButton />
        <Tittle>Orders</Tittle>
      </HeaderWrapper>
      <OrdersFilter
        ordersList={ordersList}
        onFilterChange={setFilteredOrders}
      />
      <OrdersList ordersList={filteredOrders} orderOnClick={setSelectedOrder} />
      {selectedOrder && (
        <>
          <Overlay onClick={() => setSelectedOrder(null)} />
          {selectedOrder && (
            <OrderDetails
              order={selectedOrder}
              modalClose={() => setSelectedOrder(null)}
            />
          )}
        </>
      )}
    </Container>
  );
};

export default OrdersPage;
