import {
  CompanyRequst,
  UpdateToken,
  UserInformation,
  updateAvatar,
  CompanyData,
  OrderForCreate,
  DocumentEnum,
  AddProduct,
  OrderStatus,
  DatesDashboard,
  Message,
  MessageToProvider,
  UserAccount,
} from "./../../utils/types";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import authService, {
  addDeleteFavorite,
  changeBusinessRequestsStatus,
  createBusinessRequests,
  getAllCustomersDashboard,
  getAllDelegatedBrief,
  getAllDetailsDashboard,
  getAllOrders,
  getAllOrdersDashboard,
  getAllProductsDashboard,
  getAllProviders,
  getAllRequests,
  getAllRequestsDashboard,
  getCompanyInfo,
  getConversionRateDashboard,
  getCoordinatesDashboard,
  getDetailsForMapDashboard,
  getDetailsOrders,
  getDialogueById,
  getFavoriteProviders,
  searchProviders,
  updateUserInfo,
  changeEmail,
  confirmEmail,
  validatePassword,
  changePassword,
  updateGeneralInfo,
  getAllCompanyUsers,
  sendInvite,
  updateUserRole,
  getProduct,
  getAllProducts,
  getAllMarketplaces,
  getAllLanguages,
  updateProductNew,
  updateProductPublished,
  removeProduct,
  createProduct,
} from "./authService";
import {
  AddAgentToCustomerFavorite,
  AddAgentToProvideravorite,
  addProviderImages,
  AgentDetails,
  Attachment,
  AuthState,
  ConnectionData,
  DelegateSearchType,
  deleteAgentFromCustomerFavorite,
  deleteAgentFromProviderFavorite,
  FavoritesActionType,
  Item,
  PersonalRegister,
  ProviderDetails,
  updateAgentAvatar,
  updateAgentDataAbout,
  updateAgentDataRequest,
  updateCustomerDataAbout,
  updateCustomerDataRequest,
  updateProviderData,
  updateProviderImages,
  UpdateProviderProduct,
  updateUserAvatar,
  uploadFiles,
  uploadFilesResponse,
  UserConfirm,
  UserLogin,
  UserRegister,
  UserResendCode,
  // Dashboard,
} from "../../utils/types";
import { ChangeStatusData } from "../../components/RequestPage/RequestPage";

export const loginUserAsync = createAsyncThunk(
  "auth/loginUser",
  async (credentials: UserLogin, thunkAPI) => {
    try {
      const response = await authService.login(credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const refreshTokenAsync = createAsyncThunk(
  "token/refreshToken",
  async (credentials: UpdateToken, thunkAPI) => {
    try {
      const response: { data: { token: string; refreshToken: string } } =
        await authService.getNewToken(credentials);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const resendCode = createAsyncThunk(
  "auth/resendCode",
  async (credentials: UserResendCode, thunkAPI) => {
    try {
      const response = await authService.resendCode(credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const registerUserAsync = createAsyncThunk(
  "register/UserRegister",
  async (credentials: UserRegister, thunkAPI) => {
    try {
      const response = await authService.register(credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const confirmUserAsync = createAsyncThunk(
  "user/UserConfirm",
  async (credentials: UserConfirm, thunkAPI) => {
    try {
      const response = await authService.confirm(credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const personalRegisterAsync = createAsyncThunk(
  "register/PersonalUserRegister",
  async (credentials: PersonalRegister, thunkAPI) => {
    try {
      const response = await authService.personalRegister(credentials);
      return { userType: credentials.userType, data: response.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const updateCustomer = createAsyncThunk(
  "customer/UpdateCustomerData",
  async (credentials: updateCustomerDataRequest, thunkAPI) => {
    try {
      const response = await authService.updateCustomer(credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const updateProvider = createAsyncThunk(
  "provider/UpdateProviderData",
  async (credentials: updateProviderData, thunkAPI) => {
    try {
      const response = await authService.updateProvider(credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const updateAgent = createAsyncThunk(
  "agent/UpdateAgentData",
  async (credentials: updateAgentDataRequest, thunkAPI) => {
    try {
      const response = await authService.updateAgent(credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateCustomerAbout = createAsyncThunk(
  "customer/UpdateCustomerDataAboutAndAttachments",
  async (credentials: updateCustomerDataAbout, thunkAPI) => {
    try {
      const response = await authService.updateCustomerAbout(credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const updateAgentAbout = createAsyncThunk(
  "agent/UpdateAgenDataAbout",
  async (credentials: updateAgentDataAbout, thunkAPI) => {
    try {
      const response = await authService.updateAgentAbout(credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const updateAgentAvatars = createAsyncThunk(
  "agent/updateAgentAvatar",
  async (credentials: updateAgentAvatar, thunkAPI) => {
    try {
      return await authService.updateAgentAvatar(credentials);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateCustomerAvatars = createAsyncThunk(
  "userAvatar/updateAvatar",
  async (credentials: updateUserAvatar, thunkAPI) => {
    try {
      return await authService.updateUserAvatar(credentials);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const updateCompanyAvatar = createAsyncThunk(
  "companyAvatar/updateAvatar",
  async (credentials: updateAvatar, thunkAPI) => {
    try {
      return await authService.updateAvatar(credentials);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const removeProductFromProvider = createAsyncThunk(
  "providerItems/removeProduct",
  async (credentials: { id: number }, thunkAPI) => {
    try {
      const response = await authService.removeProduct(credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const removePhotosFromProvider = createAsyncThunk(
  "providerPhotos/removePhoto",
  async (credentials: { id: number }, thunkAPI) => {
    try {
      const response = await authService.removePhoto(credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateProviderProduct = createAsyncThunk(
  "providerProducts/UpdateProduct",
  async (credentials: UpdateProviderProduct, thunkAPI) => {
    try {
      const response: { data: Item } = await authService.updateProduct(
        credentials
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const removeDelegatedBrief = createAsyncThunk(
  "DelegatedBrief/delete",
  async (credentials: { id: number; token: string }, thunkAPI) => {
    try {
      const response = await authService.deleteDelegatedBrief(credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const getDelegatedBrif = createAsyncThunk(
  "DelegatedBrif/get",
  async (credentials: { id: number; token: string }, thunkAPI) => {
    try {
      const response = await authService.getDelegatedBrif(credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const downloadDelegatedBrif = createAsyncThunk(
  "DelegatedBrif/download",
  async (
    credentials: { id: number; token: string; nameBrief: string },
    thunkAPI
  ) => {
    try {
      const response = await authService.downloadDelegatedBrif(credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const sendLetterToProvider = createAsyncThunk(
  "internetSearchConact/sendLetter",
  async (credentials: MessageToProvider, thunkAPI) => {
    try {
      const response = await authService.sendLetterToProvider(credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const removeAttachmentCompany = createAsyncThunk(
  "company/removeAttachment",
  async (
    credentials: {
      id: number;
      token: string;
      companyType: string;
      documentType: DocumentEnum;
    },
    thunkAPI
  ) => {
    try {
      const response = await authService.removeAttachmentFromCompany(
        credentials
      );
      return {
        ...response,
        documentType: credentials.documentType,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const addProviderProduct = createAsyncThunk(
  "providerProducts/addProviderProduct",
  async (credentials: UpdateProviderProduct, thunkAPI) => {
    try {
      const response: { data: Item } = await authService.addProduct(
        credentials
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const uploadFilesToServer = createAsyncThunk(
  "files/uploadFiles",
  async (credentials: uploadFiles, thunkAPI) => {
    try {
      const response: { data: uploadFilesResponse } =
        await authService.uploadFiles(credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getFavoriteAgentsAsync = createAsyncThunk(
  "favoriteAgents/get",
  async (credentials, thunkAPI) => {
    try {
      const response = await authService.getFavoriteAgents();
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getAllAgentsAsync = createAsyncThunk(
  "allAgents/get",
  async (credentials: AgentDetails, thunkAPI) => {
    try {
      const response = await authService.getAllAgents(credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const confirmAcceptanceOrderAsync = createAsyncThunk(
  "acceptOrder/put",
  async (credentials: number, thunkAPI) => {
    try {
      const response = await authService.confirmOrderAcceptance(credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createOrderAsync = createAsyncThunk(
  "order/create",
  async (
    { order, token }: { order: OrderForCreate; token: string },
    thunkAPI
  ) => {
    try {
      const response = await authService.createOrder(order, token);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateOrderStatusAsync = createAsyncThunk(
  "orderStatus/update",
  async (
    credentials: {
      processingStatus: OrderStatus;
      orderId: number;
      token: string;
    },
    thunkAPI
  ) => {
    try {
      const response = await authService.updateOrderStatus(credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const logoutFunc = createAsyncThunk(
  "auth/logout",
  async (_, thunkAPI) => {
    try {
      const response = await authService.logOut();
      window.location.href = "/";
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const createDelegateSearchAsync = createAsyncThunk(
  "customer/CreateDelegateSearch",
  async (
    credentials: { delegateSearch: DelegateSearchType; token: string },
    thunkAPI
  ) => {
    try {
      const response: { data: DelegateSearchType } =
        await authService.createDelegateSearch(credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const sendDelegateSearch = createAsyncThunk(
  "customer/sendDelegateSearch",
  async (
    credentials: {
      delegateSearch: DelegateSearchType;
      token: string;
      placeId: string;
    },
    thunkAPI
  ) => {
    try {
      const response: { data: DelegateSearchType } =
        await authService.sendDelegateSearch(credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const updateBrif = createAsyncThunk(
  "customer/updateBrif",
  async (
    credentials: { delegateSearch: DelegateSearchType; token: string },
    thunkAPI
  ) => {
    try {
      const response: { data: DelegateSearchType } =
        await authService.updateBrif(credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const addAgentToCustomerFavoriteAsync = createAsyncThunk(
  "customer/addFavoriteAgent",
  async (credentials: AddAgentToCustomerFavorite, thunkAPI) => {
    try {
      const response: { data: number } = await authService.addAgentToCustomer(
        credentials
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteAgentFromCustomerFavoriteAsync = createAsyncThunk(
  "customer/deleteFavoriteAgent",
  async (credentials: deleteAgentFromCustomerFavorite, thunkAPI) => {
    try {
      const response: { data: number } =
        await authService.deleteAgentFromCustomer(credentials);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const addAgentToProviderFavoriteAsync = createAsyncThunk(
  "provider/addFavoriteAgent",
  async (credentials: AddAgentToProvideravorite, thunkAPI) => {
    try {
      const response: { data: number } = await authService.addAgentToProvider(
        credentials
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const deleteAgentFromProviderFavoriteAsync = createAsyncThunk(
  "provider/deleteFavoriteAgent",
  async (credentials: deleteAgentFromProviderFavorite, thunkAPI) => {
    try {
      const response: { data: number } =
        await authService.deleteAgentFromProvider(credentials);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const removeAttachmentFromCustomerAsync = createAsyncThunk(
  "user/removeAttachment",
  async (credentials: { id: number; token: string }, thunkAPI) => {
    try {
      const response: { data: Attachment[] } =
        await authService.removeAttachmentFromCustomer(credentials);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const removePhotoProductAsync = createAsyncThunk(
  "products/removeProducts",
  async (
    credentials: { id: number; photoId: number; token: string },
    thunkAPI
  ) => {
    try {
      const response: { data: Item[] } = await authService.removePhotoProduct(
        credentials
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const addPhotoToProviderAsync = createAsyncThunk(
  "provider/addPhoto",
  async (
    { credentials, token }: { credentials: addProviderImages; token: string },
    thunkAPI
  ) => {
    try {
      const response = await authService.addPhotoToProvider(credentials, token);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const removePhotoFromProviderAsync = createAsyncThunk(
  "provider/removePhoto",
  async ({ photoId, token }: { photoId: number; token: string }, thunkAPI) => {
    try {
      const response = await authService.removePhotoFromProvider(
        photoId,
        token
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updatePhotoFromProviderAsync = createAsyncThunk(
  "provider/updatePhoto",
  async (credentials: updateProviderImages, thunkAPI) => {
    try {
      const response = await authService.updatePhotoFromProvider(credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getCompanyByIdAsync = createAsyncThunk(
  "company/getById",
  async (credentials: { id: number; token: string }, thunkAPI) => {
    try {
      const response = await authService.getCompanyById(credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getOrderByIdAsync = createAsyncThunk(
  "order/getById",
  async (credentials: { id: number; token: string }, thunkAPI) => {
    try {
      const response = await authService.getOrderById(credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getAgentByIdAsync = createAsyncThunk(
  "agent/getById",
  async (id: number, thunkAPI) => {
    try {
      const response = await authService.getAgentById(id);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const SendMessageAsync = createAsyncThunk(
  "auth/sendMessage",
  async (credentials: Message, thunkAPI) => {
    try {
      await authService.sendMessage(credentials);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// new slices

export const createProductAsync = createAsyncThunk(
  "product/create",
  async (credentials: AddProduct, thunkAPI) => {
    try {
      const response = await createProduct(credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const removeProductAsync = createAsyncThunk(
  "product/delete",
  async (id: number, thunkAPI) => {
    try {
      const response = await removeProduct(id);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateProductPublishedAsync = createAsyncThunk(
  "product/updatepublish",
  async (credentials: { id: number; published: boolean }, thunkAPI) => {
    try {
      const response = await updateProductPublished(credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateProductAsync = createAsyncThunk(
  "product/update",
  async (credentials: AddProduct, thunkAPI) => {
    try {
      const response = await updateProductNew(credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getAllLanguagesAsync = createAsyncThunk(
  "provider/getAllLanguages",
  async (_, thunkAPI) => {
    try {
      const response = await getAllLanguages();
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getAllMarketplacesAsync = createAsyncThunk(
  "provider/getAllMarketplaces",
  async (_, thunkAPI) => {
    try {
      const response = await getAllMarketplaces();
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getAllProductsAsync = createAsyncThunk(
  "allProducts/get",
  async (credentials: { page: number; size: number }, thunkAPI) => {
    try {
      const response = await getAllProducts(credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getAllProduct = createAsyncThunk(
  "getAllProduct/Product",
  async (_, thunkAPI) => {
    try {
      const response = await getProduct();
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateUserRoleAsync = createAsyncThunk(
  "userRole/update",
  async (credentials: { userId: number; role: string }, thunkAPI) => {
    try {
      const response = await updateUserRole(credentials);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const sendInviteAsync = createAsyncThunk(
  "sendInvite",
  async (emailUser: string, thunkAPI) => {
    try {
      const response = await sendInvite(emailUser);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getAllCompanyUsersAsync = createAsyncThunk(
  "allUsers/get",
  async (companyId: number, thunkAPI) => {
    try {
      const response = await getAllCompanyUsers(companyId);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateGeneralCompanyInfo = createAsyncThunk(
  "company/UpdateInfo",
  async (credentials: CompanyData, thunkAPI) => {
    try {
      const response = await updateGeneralInfo(credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const changePasswordAsync = createAsyncThunk(
  "changePassword",
  async (newPassword: string, thunkAPI) => {
    try {
      const response = await changePassword(newPassword);
      return response.status;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const validatePasswordAsync = createAsyncThunk(
  "validatePassword",
  async (password: string, thunkAPI) => {
    try {
      const response = await validatePassword(password);
      return response.status;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const confirmEmailAsync = createAsyncThunk(
  "confirmEmail",
  async (confirmCode: string, thunkAPI) => {
    try {
      const response = await confirmEmail(confirmCode);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const changeEmailAsync = createAsyncThunk(
  "changeEmail",
  async (newEmail: string, thunkAPI) => {
    try {
      const response = await changeEmail(newEmail);
      console.log(response);

      return response.text;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateGeneralUserInfo = createAsyncThunk(
  "user/update",
  async (credentials: UserInformation, thunkAPI) => {
    try {
      const response = await updateUserInfo(credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getDialogueByIdAsync = createAsyncThunk(
  "dialogue/getById",
  async (credentials: { id: number }, thunkAPI) => {
    try {
      const response = await getDialogueById(credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const changeBusinessRequestsStatusAsync = createAsyncThunk(
  "change/status",
  async (credentials: ChangeStatusData, thunkAPI) => {
    try {
      const response = await changeBusinessRequestsStatus(credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createBusinessRequestsAsync = createAsyncThunk(
  "request/create",
  async (credentials: ConnectionData, thunkAPI) => {
    try {
      const response = await createBusinessRequests(credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// export const getCityByCountryAsync = createAsyncThunk(
//   "cities/getCities",
//   async (credentials: string, thunkAPI) => {
//     try {
//       const response = await getCityByCountry(credentials);
//       return response.cities;
//     } catch (error) {
//       return thunkAPI.rejectWithValue(error);
//     }
//   }
// );

export const getCityByCountryAsync = createAsyncThunk(
  "cities/getCities",
  async (credentials: string, thunkAPI) => {
    try {
      const response = await authService.getCityByCountry(credentials);
      return response.cities;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getCompanyInfoAsync = createAsyncThunk(
  "getCompany/CompanyInfo",
  async (credentials: CompanyRequst, thunkAPI) => {
    try {
      const response = await getCompanyInfo(credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getAllRequestsAsync = createAsyncThunk(
  "allRequests/get",
  async (_, thunkAPI) => {
    try {
      const response = await getAllRequests();
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getCoordinatesDashboardAsync = createAsyncThunk(
  "coordinatesDashboard/get",
  async ({ credentials }: { credentials: DatesDashboard }, thunkAPI) => {
    try {
      const response = await getCoordinatesDashboard(credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getDetailsForMapDashboardAsync = createAsyncThunk(
  "detailsMapDashboard/get",
  async ({ credentials }: { credentials: DatesDashboard }, thunkAPI) => {
    try {
      const response = await getDetailsForMapDashboard(credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getDetailsOrdersAsync = createAsyncThunk(
  "detailsOrdersDashboard/get",
  async ({ credentials }: { credentials: DatesDashboard }, thunkAPI) => {
    try {
      const response = await getDetailsOrders(credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getAllRequestsDashboardAsync = createAsyncThunk(
  "allRequestsDashboard/get",
  async ({ credentials }: { credentials: DatesDashboard }, thunkAPI) => {
    try {
      const response = await getAllRequestsDashboard(credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getConversionRateDashboardAsync = createAsyncThunk(
  "ConversionRate/get",
  async (_, thunkAPI) => {
    try {
      const response = await getConversionRateDashboard();
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getAllProductsDashboardAsync = createAsyncThunk(
  "allProductsDashboard/get",
  async ({ credentials }: { credentials: DatesDashboard }, thunkAPI) => {
    try {
      const response = await getAllProductsDashboard(credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getAllDetailsDashboardAsync = createAsyncThunk(
  "allDetailsDashboard/get",
  async ({ credentials }: { credentials: DatesDashboard }, thunkAPI) => {
    try {
      const response = await getAllDetailsDashboard(credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getAllCustomersDashboardAsync = createAsyncThunk(
  "allCustomersDashboard/get",
  async ({ credentials }: { credentials: DatesDashboard }, thunkAPI) => {
    try {
      const response = await getAllCustomersDashboard(credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getAllOrdersDashboardAsync = createAsyncThunk(
  "allOrdersDashboard/get",
  async ({ credentials }: { credentials: DatesDashboard }, thunkAPI) => {
    try {
      const response = await getAllOrdersDashboard(credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getAllOrdersAsync = createAsyncThunk(
  "allOrders/get",
  async (_, thunkAPI) => {
    try {
      const response = await getAllOrders();
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getAllDelegatedBriefAsync = createAsyncThunk(
  "DelegatedBrief/getAllDelegatedBrief",
  async (_, thunkAPI) => {
    try {
      const response = await getAllDelegatedBrief();
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// (not checked)
export const addDeleteFavoriteAgentAsync = createAsyncThunk(
  "customer/addFavoriteAgent",
  async (credentials: FavoritesActionType, thunkAPI) => {
    try {
      const response: { data: number } = await addDeleteFavorite(credentials);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const addDeleteFavoriteAsync = createAsyncThunk(
  "customer/addFavoriteProvider",
  async (credentials: FavoritesActionType, thunkAPI) => {
    try {
      const response: { data: number } = await addDeleteFavorite(credentials);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const searchProvidersAsync = createAsyncThunk(
  "searchProviders/put",
  async (
    {
      credentials,
      searchValue,
    }: { credentials: ProviderDetails; searchValue: string[] },
    thunkAPI
  ) => {
    try {
      const response = await searchProviders(credentials, searchValue);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getFavoriteProvidersAsync = createAsyncThunk(
  "favoriteProviders/get",
  async (_, thunkAPI) => {
    try {
      const response = await getFavoriteProviders();
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getAllProvidersAsync = createAsyncThunk(
  "allProviders/get",
  async ({ credentials }: { credentials: ProviderDetails }, thunkAPI) => {
    try {
      const response = await getAllProviders(credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const fetchCountriesAsync = createAsyncThunk(
  "countries/fetchCountries",
  async () => {
    try {
      const response = await authService.fetchCountries();
      return response.countries;
    } catch (error) {
      return error;
    }
  }
);

const initialState: AuthState = {
  status: "idle",
  userAccount: null,
  allProviders: null,
  allAgents: null,
  requests: null,
  company: null,
  orders: null,
  files: null,
  error: null,
  countries: null,
  dashboard: null,
  cities: null,
  allMarketplaces: null,
  allLanguages: null,
  favoriteProviders: [],
};
const userSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    // logout: (state) => {
    //     Object.assign(state, initialState);
    // },
    saveProducts: (state, action: PayloadAction<Item[]>) => {
      if (state.company && state.company.products) {
        state.company.products.content = action.payload;
      }
    },
    resetBrief: (state) => {
      state.brief = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUserAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(loginUserAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.userAccount = action.payload;
        state.countries = null;
        state.cities = null;
      })
      .addCase(loginUserAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message as any;
      })
      .addCase(refreshTokenAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(refreshTokenAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.userAccount!.token = action.payload.data.token as string;
        state.userAccount!.refreshToken = action.payload.data
          .refreshToken as string;
      })
      .addCase(refreshTokenAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message as any;
      })
      .addCase(registerUserAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(registerUserAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.userAccount = action.payload;
      })
      .addCase(registerUserAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message as any;
      })
      .addCase(confirmUserAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(confirmUserAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.userAccount = action.payload;
      })
      .addCase(confirmUserAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message as any;
      })
      .addCase(personalRegisterAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(personalRegisterAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.company = action.payload.data;
        state.userAccount = action.payload.data.user;
        state.userAccount!.token = action.payload.data.token;
        state.userAccount!.refreshToken = action.payload.data.refreshToken;
      })
      .addCase(personalRegisterAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message as any;
      })
      .addCase(resendCode.pending, (state) => {
        state.status = "loading";
      })
      .addCase(resendCode.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.userAccount = action.payload;
      })
      .addCase(resendCode.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message as any;
      })
      .addCase(updateCustomer.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(updateCustomer.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.company = action.payload;
      })
      .addCase(updateCustomer.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message as any;
      })
      .addCase(updateProvider.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(updateProvider.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.company = action.payload;
      })
      .addCase(updateProvider.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message as any;
      })
      .addCase(updateCustomerAbout.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(updateCustomerAbout.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.company = action.payload;
      })
      .addCase(updateCustomerAbout.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message as any;
      })
      .addCase(updateCustomerAvatars.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(updateCustomerAvatars.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.userAccount!.avatar = action.payload;
      })
      .addCase(updateCustomerAvatars.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message as any;
      })
      .addCase(updateAgentAvatars.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(updateAgentAvatars.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.company!.avatar = action.payload;
      })
      .addCase(updateAgentAvatars.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message as any;
      })
      .addCase(updateCompanyAvatar.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(updateCompanyAvatar.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.company!.avatar = action.payload;
      })
      .addCase(updateCompanyAvatar.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message as any;
      })
      .addCase(removeProductFromProvider.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(removeProductFromProvider.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.company!.products = action.payload;
      })
      .addCase(removeProductFromProvider.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message as any;
      })
      .addCase(removePhotosFromProvider.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(removePhotosFromProvider.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.company!.photos = action.payload;
      })
      .addCase(removePhotosFromProvider.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message as any;
      })
      .addCase(uploadFilesToServer.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(uploadFilesToServer.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.files = action.payload;
      })
      .addCase(uploadFilesToServer.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message as any;
      })
      .addCase(getAllProvidersAsync.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getAllProvidersAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.allProviders = action.payload;
      })
      .addCase(getAllProvidersAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message as any;
      })
      .addCase(getAllAgentsAsync.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getAllAgentsAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.allAgents = action.payload;
      })
      .addCase(getAllAgentsAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message as any;
      })
      .addCase(logoutFunc.fulfilled, (state, action) => {
        state.status = "succeeded";
        Object.assign(state, initialState);
      })
      .addCase(createDelegateSearchAsync.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(createDelegateSearchAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        // state.delegateSearchBrif = action.payload as any
      })
      .addCase(createDelegateSearchAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message as any;
      })
      .addCase(sendDelegateSearch.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(sendDelegateSearch.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(sendDelegateSearch.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message as any;
      })
      .addCase(updateBrif.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(updateBrif.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(updateBrif.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message as any;
      })
      .addCase(updateProviderProduct.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(updateProviderProduct.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.company!.products = action.payload as any;
      })
      .addCase(updateProviderProduct.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message as any;
      })
      .addCase(getCompanyInfoAsync.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getCompanyInfoAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.company = action.payload as any;
      })
      .addCase(getCompanyInfoAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message as any;
      })
      .addCase(getAllProduct.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getAllProduct.fulfilled, (state, action) => {
        state.status = "succeeded";
        if (state.company) {
          state.company.products = action.payload as any;
        }
      })
      .addCase(getAllProduct.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message as any;
      })
      .addCase(getAllDelegatedBriefAsync.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getAllDelegatedBriefAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.delegateBrief = action.payload as any;
      })
      .addCase(getAllDelegatedBriefAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message as any;
      })
      .addCase(removeDelegatedBrief.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(removeDelegatedBrief.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(removeDelegatedBrief.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message as any;
      })
      .addCase(getDelegatedBrif.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getDelegatedBrif.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.brief = action.payload as any;
      })
      .addCase(getDelegatedBrif.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message as any;
      })
      .addCase(sendLetterToProvider.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(sendLetterToProvider.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(sendLetterToProvider.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message as any;
      })

      // .addCase(getProductByIdAsync.pending, (state, action) => {
      //   state.status = "loading";
      // })
      // .addCase(getProductByIdAsync.fulfilled, (state, action) => {
      //   state.status = "succeeded";
      //   state.company!.productById= action.payload as any;
      // })
      // .addCase(getProductByIdAsync.rejected, (state, action) => {
      //   state.status = "failed";
      //   state.error = action.error.message as any;
      // })
      .addCase(updateGeneralCompanyInfo.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(updateGeneralCompanyInfo.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.company = action.payload;
      })
      .addCase(updateGeneralCompanyInfo.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message as any;
      })
      .addCase(removeAttachmentCompany.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(removeAttachmentCompany.fulfilled, (state, action) => {
        state.status = "succeeded";
        const { id, documentType } = action.payload;
        const company = state.company;
        // Check if the documentType property exists in the company and if it is an array.
        if (company && Array.isArray(company[documentType])) {
          // Remove an element from the array by id.
          company[documentType] = company[documentType]!.filter(
            (doc) => doc.id !== id
          );
        } else {
          console.error(
            `documentType ${documentType} is not an array or does not exist in company.`
          );
        }
      })
      .addCase(removeAttachmentCompany.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message as any;
      })
      .addCase(updateGeneralUserInfo.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(updateGeneralUserInfo.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.userAccount = action.payload;
      })
      .addCase(updateGeneralUserInfo.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message as any;
      })
      .addCase(addProviderProduct.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(addProviderProduct.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.company!.products = action.payload as any;
      })
      .addCase(addProviderProduct.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message as any;
      })
      .addCase(addAgentToCustomerFavoriteAsync.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(addAgentToCustomerFavoriteAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.company!.favoriteAgents = action.payload.data as any;
      })
      .addCase(addAgentToCustomerFavoriteAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message as any;
      })
      .addCase(
        deleteAgentFromCustomerFavoriteAsync.pending,
        (state, action) => {
          state.status = "loading";
        }
      )
      .addCase(
        deleteAgentFromCustomerFavoriteAsync.fulfilled,
        (state, action) => {
          state.status = "succeeded";
          state.company!.favoriteAgents = action.payload.data as any;
        }
      )
      .addCase(
        deleteAgentFromCustomerFavoriteAsync.rejected,
        (state, action) => {
          state.status = "failed";
          state.error = action.error.message as any;
        }
      )
      .addCase(addAgentToProviderFavoriteAsync.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(addAgentToProviderFavoriteAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.company!.favoriteAgents = action.payload.data as any;
      })
      .addCase(addAgentToProviderFavoriteAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message as any;
      })
      .addCase(
        deleteAgentFromProviderFavoriteAsync.pending,
        (state, action) => {
          state.status = "loading";
        }
      )
      .addCase(
        deleteAgentFromProviderFavoriteAsync.fulfilled,
        (state, action) => {
          state.status = "succeeded";
          state.company!.favoriteAgents = action.payload.data as any;
        }
      )
      .addCase(
        deleteAgentFromProviderFavoriteAsync.rejected,
        (state, action) => {
          state.status = "failed";
          state.error = action.error.message as any;
        }
      )
      .addCase(updateAgent.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(updateAgent.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.company = action.payload;
      })
      .addCase(updateAgent.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message as any;
      })
      .addCase(addDeleteFavoriteAsync.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(addDeleteFavoriteAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.favoriteProviders = action.payload.data as any;
      })
      .addCase(addDeleteFavoriteAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message as any;
      })
      .addCase(removeAttachmentFromCustomerAsync.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(removeAttachmentFromCustomerAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.userAccount!.attachments = action.payload.data as any;
      })
      .addCase(removeAttachmentFromCustomerAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message as any;
      })
      .addCase(removePhotoProductAsync.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(removePhotoProductAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(removePhotoProductAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message as any;
      })
      .addCase(removeProductAsync.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(removeProductAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(removeProductAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message as any;
      })
      .addCase(getAllProductsAsync.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getAllProductsAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.company!.products = action.payload;
        saveProducts(action.payload.content);
      })
      .addCase(getAllProductsAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message as any;
      })
      .addCase(addPhotoToProviderAsync.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(addPhotoToProviderAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.company!.photos = action.payload as any;
      })
      .addCase(addPhotoToProviderAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message as any;
      })
      .addCase(removePhotoFromProviderAsync.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(removePhotoFromProviderAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.company!.photos = action.payload as any;
      })
      .addCase(removePhotoFromProviderAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message as any;
      })

      .addCase(getAllMarketplacesAsync.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getAllMarketplacesAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.allMarketplaces = action.payload as any;
      })
      .addCase(getAllMarketplacesAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message as any;
      })

      .addCase(getAllLanguagesAsync.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getAllLanguagesAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.allLanguages = action.payload as any;
      })
      .addCase(getAllLanguagesAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message as any;
      })

      .addCase(updatePhotoFromProviderAsync.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(updatePhotoFromProviderAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.providerCompany!.photos = action.payload as any;
      })
      .addCase(updatePhotoFromProviderAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message as any;
      })
      .addCase(getAllRequestsAsync.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getAllRequestsAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.requests = action.payload as any;
      })
      .addCase(getAllRequestsAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message as any;
      })

      .addCase(getAllOrdersAsync.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getAllOrdersAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.orders = action.payload.content as any;
      })
      .addCase(getAllOrdersAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message as any;
      })

      .addCase(getAllRequestsDashboardAsync.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getAllRequestsDashboardAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.dashboard = {
          ...state.dashboard,
          requests: action.payload,
        };
      })

      .addCase(getAllRequestsDashboardAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message as any;
      })

      .addCase(getAllOrdersDashboardAsync.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getAllOrdersDashboardAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.dashboard = {
          ...state.dashboard,
          orders: action.payload,
        };
      })
      .addCase(getAllOrdersDashboardAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message as any;
      })
      .addCase(getAllCustomersDashboardAsync.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getAllCustomersDashboardAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.dashboard = {
          ...state.dashboard,
          customers: action.payload,
        };
      })
      .addCase(getAllCustomersDashboardAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message as any;
      })
      .addCase(getAllProductsDashboardAsync.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getAllProductsDashboardAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.dashboard = {
          ...state.dashboard,
          products: action.payload,
        };
      })
      .addCase(getAllDetailsDashboardAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message as any;
      })
      .addCase(getAllDetailsDashboardAsync.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getAllDetailsDashboardAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.dashboard = {
          ...state.dashboard,
          details: action.payload,
        };
      })
      .addCase(getConversionRateDashboardAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message as any;
      })
      .addCase(getConversionRateDashboardAsync.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getConversionRateDashboardAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.dashboard = {
          ...state.dashboard,
          conversionRateArray: action.payload,
        };
      })
      .addCase(getCoordinatesDashboardAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message as any;
      })
      .addCase(getCoordinatesDashboardAsync.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getCoordinatesDashboardAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.dashboard = {
          ...state.dashboard,
          coordinates: action.payload,
        };
      })
      .addCase(getDetailsForMapDashboardAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message as any;
      })
      .addCase(getDetailsForMapDashboardAsync.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getDetailsForMapDashboardAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.dashboard = {
          ...state.dashboard,
          detailsMap: action.payload,
        };
      })
      .addCase(getDetailsOrdersAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message as any;
      })
      .addCase(getDetailsOrdersAsync.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getDetailsOrdersAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.dashboard = {
          ...state.dashboard,
          orderDetails: action.payload,
        };
      })
      .addCase(getAllProductsDashboardAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message as any;
      })
      .addCase(confirmAcceptanceOrderAsync.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(confirmAcceptanceOrderAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.orders = action.payload.content as any;
      })
      .addCase(confirmAcceptanceOrderAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message as any;
      })

      .addCase(getFavoriteProvidersAsync.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getFavoriteProvidersAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.favoriteProviders = action.payload as any;
      })
      .addCase(getFavoriteProvidersAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message as any;
      })

      .addCase(getFavoriteAgentsAsync.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getFavoriteAgentsAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.customerCompany!.favoriteProviders = action.payload as any;
      })
      .addCase(getFavoriteAgentsAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message as any;
      })

      .addCase(fetchCountriesAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCountriesAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.countries = action.payload;
      })
      .addCase(fetchCountriesAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message as any;
      })
      .addCase(getCompanyByIdAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getCompanyByIdAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.providerById = action.payload;
      })
      .addCase(getCompanyByIdAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message as any;
      })
      .addCase(getOrderByIdAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getOrderByIdAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.orderById = action.payload;
      })
      .addCase(getOrderByIdAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message as any;
      })
      .addCase(getAgentByIdAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAgentByIdAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.agentById = action.payload;
      })
      .addCase(getAgentByIdAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message as any;
      })
      .addCase(getDialogueByIdAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getDialogueByIdAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.dialogueById = action.payload;
      })
      .addCase(getDialogueByIdAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message as any;
      })
      .addCase(getCityByCountryAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getCityByCountryAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.cities = action.payload;
      })
      .addCase(getCityByCountryAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message as any;
      })
      .addCase(createBusinessRequestsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createBusinessRequestsAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(createBusinessRequestsAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message as any;
      })
      .addCase(createOrderAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createOrderAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(createOrderAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message as any;
      })
      .addCase(createProductAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createProductAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(createProductAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message as any;
      })
      .addCase(updateProductAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateProductAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(updateProductAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message as any;
      })
      .addCase(updateOrderStatusAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateOrderStatusAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(updateOrderStatusAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message as any;
      })
      .addCase(updateProductPublishedAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateProductPublishedAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(updateProductPublishedAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message as any;
      })
      .addCase(getAllCompanyUsersAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAllCompanyUsersAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.company!.allUsers = action.payload;
      })
      .addCase(getAllCompanyUsersAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message as any;
      })
      .addCase(updateUserRoleAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateUserRoleAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        const { userId, role } = action.payload;
        // find the user by identifier
        const userToUpdate = state.company!.allUsers!.find(
          (user) => user.id === userId
        );
        if (userToUpdate) {
          userToUpdate.roles![0].name = role;
        }
      })
      .addCase(updateUserRoleAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message as any;
      })

      .addCase(searchProvidersAsync.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(searchProvidersAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.allProviders = action.payload as any;
      })
      .addCase(searchProvidersAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message as any;
      })

      .addCase(confirmEmailAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(confirmEmailAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.userAccount!.email = action.payload.email;
        state.userAccount!.token = action.payload.token;
        state.userAccount!.refreshToken = action.payload.refreshToken;
      })
      .addCase(confirmEmailAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message as any;
      });
  },
});

export const { saveProducts, resetBrief } = userSlice.actions;
export default userSlice.reducer;
