import styled from "styled-components";

export const Wrapper = styled.div`
    display: flex;
    max-width: 2000px;
    width: 100%;
    height: 100%;
`;
export const WrapperLeft = styled.div`
    max-width: 1100px;
    width: 100%;
    height: 80vh;
    margin: 30px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    box-sizing: border-box;
    border: 1px solid rgb(216, 216, 216);
    border-radius: 16px 0 0 16px;
    padding: 20px 75px;
    
`;

export const SubTitle = styled.p`
    color: rgb(0, 23, 49);
    font-size: 17px;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0;
    text-align: center;
`;
export const TagsBlock = styled.div`
    position: relative;
    width: 843px;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
`;
export const Tag = styled.div`
    position: relative;
    width: calc(50% - 10px); 
    height: 64px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;
    border: 1px solid rgb(216, 216, 216);
    border-radius: 16px;
    box-sizing: border-box;
    cursor: pointer;
    &&:hover {
        border-radius: 12px;
        background: rgb(0, 149, 192);
        color: rgb(244, 255, 255);
    }
    &&:active {
        scale: 0.99;
    }
`;

export const ChatBlock = styled.div`
    /* position: static; */
    width: 843px;
    height: 114px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    margin: 150px 0;
`;


export const AvatarBlock = styled.p`
    position: relative;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 38px; 
    margin-bottom: 30px;
`;

export const MessageWrapperSide = styled.div<{ role: string }>`
  display: flex;
  margin-bottom: 1px;
  justify-content: ${({ role }) => (role === "user" ? "end" : "start")};
`;

export const NameSide = styled.h4<{ role: string }>`
    font-size: 17px;
    font-weight: 500;
    margin: 20px 0 10px;
    text-align: ${({ role }) => (role === "user" ? "end" : "start")};
`;

export const MessagesWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-y: auto;
    &::-webkit-scrollbar {
        width: 0;
    }
`;

export const TextMessageStyle = styled.p`
    font-size: 18px;
    font-weight: 400;
    border: 1px solid var(--batton, #0095c0);
    border-radius: 16px;
    padding: 15px;
    word-wrap: break-word;
    line-height: 1.2;
    white-space: pre-line
`;

export const SearchHelper = styled.textarea<{ disabled?: boolean }>`
    width: 843px;
    height: 80px;
     padding: 20px;
    margin: 20px 0 0;
    border: 1px solid rgb(216, 216, 216);
    border-radius: 16px;
    color: black;
    font-size: 17px;
    font-weight: 400;
    resize: none;
  
    &:disabled {
      background-color: #f0f0f0;
       cursor: not-allowed;
    }
`;

export const SearchWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: end;

`;

export const SendButton = styled.button<{ disabled?: boolean }>`
    min-width: 55px;
    min-height: 55px;
    background: ${(props) =>
        props.disabled ? "#f0f0f0" : "var(--batton, #0095c0)"};
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    cursor: pointer;

    &:disabled {
      background: #f0f0f0;
      cursor: not-allowed;
    }
`;

export const AddFileButton = styled.button<{ disabled?: boolean }>`
    min-width: 55px;
    min-height: 55px;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    cursor: pointer;
    border: 1px dashed rgb(3, 182, 42);

    &:disabled {
      background: #f0f0f0;
      cursor: not-allowed;
    }
`;


export const MessageDate = styled.h3<{ role: string }>`
    margin-top: 5px;
    color: rgb(20, 50, 85);
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
    text-align: ${({ role }) => (role === "user" ? "end" : "start")};
`;

export const ModalWrapper = styled.div`
    z-index: 10;
`;


export const MessageBlockWrapper = styled.div`
    max-width: 60%;
`;



// Save mesasage

export const SaveWrapper = styled.div`
  border-radius: 16px;
  background: var(--dark, #001731);
  display: flex;
  width: 365px;
  padding: 30px;
  flex-direction: column;
  align-items: flex-start;
  position: fixed;
  top: 30px;
  right: 30px;
  z-index: 10;
`;

export const SaveParagraph = styled.p`
  color: var(--txt-light-blue, #f4ffff);
  font-size: 17px;
  font-weight: 400;
  line-height: 140%;
`;

export const CrossWhiteIconWrapper = styled.div`
  position: absolute;
  right: 20px;
  top: 10px;
  cursor: pointer;
`;