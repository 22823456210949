import styled from "styled-components";

const Wrapper = styled.div`
  margin-bottom: 31px;
  padding-bottom: 166px;

  border-radius: 16px;
  background: linear-gradient(
    46deg,
    rgba(5, 119, 152, 0.8) 8.94%,
    rgba(80, 58, 214, 0.7) 46.63%,
    rgba(3, 173, 112, 0.7) 88.48%
  );
`;

const BlockTitle = styled.div`
  padding-top: 130px;
  margin: 0 0 30px 30px;
`;

const Title = styled.p`
  color: var(--txt-light-blue, #f4ffff);
  font-size: 24px;
  font-weight: 500;
  line-height: 140%;
`;

const InputBlock = styled.div`
  display: flex;
  margin: 0 30px 30px;
`;

const Input = styled.input`
  width: 100%;
  padding: 14px 0px 16px 20px;
  max-width: 1276px;
  border-radius: 16px 0px 0px 16px;
  border: 1px solid var(--border, #bdbdbd);
  border-right: none;
  background: var(--wiht, #fff);
  &::placeholder {
    font-size: 17px;
    color: var(--txt-grey-light, #717880);
  }
`;

const ButtonBlock = styled.div`
  max-height: 52px;
  height: 100%;
  max-width: 224px;
  width: 100%;
  padding: 12px 100px;
  border-radius: 0px 16px 16px 0px;
  background: var(--batton, #0095c0);

  cursor: pointer;
`;

const RequestBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  margin-left: 30px;
`;

export const IndustryList = styled.ul`
  max-height: 260px;
  overflow-y: scroll;

  padding-right: 40px;

  scrollbar-width: thin;
  scrollbar-color: transparent transparent;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: linear-gradient(#0095c0, #0095c0);
    border-radius: 5px;
  }

  &::-webkit-scrollbar-track {
    background-color: #fafafa;
    border-radius: 5px;
  }
`;

export const SearchInputWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  margin-left: 30px;
  max-width: 1489px;
  width: 100%;
`;

export const IndustrySearchWrapper = styled.div`
  position: relative;
  max-width: 1265px;
  width: 100%;
  padding: 0 14px 20px;
  border-radius: 16px 0px 16px 16px;
  border: 1px solid var(--batton, #0095c0);
  background: var(--wiht, #fff);
  transition: all 0.3s ease;
  margin-left: 30px;
`;

export const CountryInputWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 14px 0px 14px;
`;

export const ChevronDown = styled.button`
  position: absolute;
  right: 0px;
  top: 15px;
`;

export {
  Wrapper,
  BlockTitle,
  Title,
  Input,
  ButtonBlock,
  InputBlock,
  RequestBlock,
};
