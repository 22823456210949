import styled from "styled-components";

export const ChevronDown = styled.button`
  position: absolute;
  right: 15px;
  top: 10px;
`;

export const SearchIcon = styled.button`
  position: absolute;
  left: 10px;
  bottom: 243px;
`;

export const SearchIconInput = styled.button`
  position: relative;
  left: 0px;
  top: 8px;
`;

export const SearchButton = styled.button`
  position: absolute;
  left: 5px;
  bottom: 10px;
`;

export const ChevronRightWrapper = styled.div`
  position: absolute;
  right: 30px;
  bottom: 14px;
`;

export const InputWrapper = styled.div`
  position: relative;
  margin-bottom: 20px;
  margin-right: 30px;
`;

export const InputBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid #ebebeb;
  background: #fff;
  margin: 0 0 30px 30px;
  position: relative;
`;

export const IndustryKeqwordsP = styled.p`
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  color: var(--txt-dark, #001731);
  margin: 0 0 10px 15px;
`;

export const SearchInputWrapper = styled.div`
  position: relative;
  margin-left: 15px;
  max-width: 368px;
  width: 100%;
`;

export const CountrySearchWrapper = styled.div`
  position: relative;
  max-width: 304px;
  width: 100%;
  padding: 0 14px 20px;
  border-radius: 16px;
  border: 1px solid var(--batton, #0095c0);
  background: var(--wiht, #fff);
  transition: all 0.3s ease;
  margin-left: 15px;
`;

export const IndustrySearchWrapper = styled.div`
  position: relative;
  max-width: 304px;
  width: 100%;
  padding: 0 14px 20px;
  border-radius: 16px;
  border: 1px solid var(--batton, #0095c0);
  background: var(--wiht, #fff);
  transition: all 0.3s ease;
  margin-left: 15px;
  margin-right: 5px;
`;

export const SearchInputIndustries = styled.input`
  width: 100%;
  font-size: 17px;
  line-height: 130%;
  border-radius: 16px;
  background: #fff;
  color: var(--txt-dark, #001731);
  padding: 14px 0px 14px 5px;
  max-width: 217px;
  width: 100%;
  &::placeholder {
    font-size: 17px;
    line-height: 140%;
    color: var(--txt-grey-light, #dadada);
  }
`;

export const SearchInputCountryCityList = styled.input`
font-size: 17px;
  line-height: 130%;
  border-radius: 16px;
  background: #fff;
  color: var(--txt-dark, #001731);
  padding: 14px 0px 14px 10px;
  &::placeholder {
    font-size: 17px;
    line-height: 140%;
    color: var(--txt-grey-light, #dadada);
  }
`;

export const CountryInputWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const SearchIconCountry = styled.button`
  position: relative;
  left: 0px;
  top: 2px;
`;

export const ItemFail = styled.li`
  text-align: center;
  font-size: 17px;
  line-height: 120%;

  color: gray;
  cursor: not-allowed;
`;

export const SearchButtonCountry = styled.button`
  position: absolute;
  left: 5px;
  bottom: 10px;
`;

export const SearchIconCity = styled.button`
  position: relative;
  left: 0px;
  bottom: 0px;
`;

export const ChevronDownCity = styled.button`
  position: absolute;
  right: 15px;
  top: 25px;
`;