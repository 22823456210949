import React, { useEffect, useRef, useState } from "react";
import {
  Avatar,
  AvatarInput,
  AvatarLabel,
  AvatarWrapper,
  BusinessInfoWrapper,
  ButtonContainer,
  ButtonInputWrapper,
  ButtonWrapper,
  ChangeButton,
  ChangeEditWrapper,
  ConfirmField,
  ConfrmButton,
  EmailField,
  Field,
  GenderSubtitle,
  LeftWrapper,
  ProfileLogoContainer,
  RadioContainer,
  RadioWrapper,
  RequiredField,
  RightFieldWrapper,
  RightWrapper,
  Subtitle,
  Text,
  Verify,
  VerifyContainer,
  Wrapper,
} from "./style";
import PlusIcon from "../../../assets/icons/PlusIcon";
import { GenderType, UserInformation } from "../../../utils/types";
import { useDispatch, useSelector } from "react-redux";
import {
  updateCustomerAvatars,
  updateGeneralUserInfo,
  uploadFilesToServer,
} from "../../../store/slices/userSlice";
import Button from "../../Button/Button";
import ModalImageErrorMessage from "../../ModalImageErrorMessage/ModalImageErrorMessage";
import CircleSwitch from "../../CircleSwitch/CircleSwitch";
import { RootState } from "../../../store/store";
import { useAppSelector } from "../../../store/hooks";
import Documents from "../ProofDocuments/Documents";
import ModalChangePassword from "../../ModalWindows/ModalChangePassword/ModalChangePassword";
import ModalConfirmation from "../../ModalWindows/ModalConfirmation/ModalConfirmation";
import ModalChangeField from "../../ModalWindows/ModalChangeEmail/ModalChangeField";
import ModalLostChanges from "../../ModalWindows/ModalLostChanges/ModalLostChanges";
import { useBlocker, useNavigate } from "react-router-dom";

const DefaultAvatar = require("../../../assets/img/default-avatar.png");

type EditingState = {
  password: boolean;
  confirmationEmail: boolean;
  confirmationPhone: boolean;
  email: boolean;
  phone: boolean;
};

const Information: React.FC<
  UserInformation & { isGeneralEditing: boolean }
> = ({}) => {
  const initialEditingState: EditingState = {
    password: false,
    confirmationEmail: false,
    confirmationPhone: false,
    email: false,
    phone: false,
  };

  const navigate = useNavigate();
  const avatarInputRef = useRef<HTMLInputElement | null>(null);
  const dispatch = useDispatch();
  const [fileList, setFileList] = useState<File[]>([]);
  const [isDiscard, setIsDiscard] = useState(false);
  useState<boolean>(false);
  const [imageTypeError, setImageTypeError] = useState<string | null>(null);
  const [isSaveClicked, setIsSaveClicked] = useState(false);
  const [editingState, setEditingState] = useState(initialEditingState);
  const [tempData, setTempData] = useState<UserInformation | null>(null);
  const userInfo = useSelector((state: RootState) => state.user.userAccount);
  const [generalData, setGeneralData] = useState<UserInformation>(userInfo!);
  const token = useAppSelector((store) => store.user.userAccount?.token!);
  const [updateUser, setUpdateUser] = useState<UserInformation | undefined>({
    gender: userInfo?.gender,
    firstName: userInfo?.firstName,
    lastName: userInfo?.lastName,
    jobTitle: userInfo?.jobTitle,
  });
  const [nextLocation, setNextLocation] = useState<string>("");
  const [updateDocument, setUpdateDocument] = useState<UserInformation>();

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFile = e.target.files?.[0];
    if (newFile) {
      const allowedFormats = ["image/jpeg", "image/jpg", "image/png"];
      const uploadAvatarAllowedSize = 3000000;
      if (newFile.size > uploadAvatarAllowedSize) {
        setImageTypeError(
          "Image size is too large. Please upload image less then 3MB."
        );
        return;
      }
      if (allowedFormats.includes(newFile.type)) {
        try {
          const newFileList = [...fileList, newFile];
          setFileList(newFileList);
          const uploadedAvatars = await dispatch(
            uploadFilesToServer({ files: newFileList }) as any
          );
          dispatch(
            updateCustomerAvatars({
              avatar_link: uploadedAvatars.payload[0].link,
              token,
            }) as any
          );
          setUpdateUser({ avatar: uploadedAvatars.payload[0].link } as any);
          setFileList([]);
        } catch (error) {
          console.error("Error uploading file:", error);
        }
      } else {
        setImageTypeError(
          "Invalid image format. Please use valid format / .jpg, .jpeg, .png /"
        );
      }
    }
  };

  const handleModalCLose = () => {
    setImageTypeError(null);
    if (avatarInputRef.current !== null) {
      avatarInputRef.current.value = "";
    }
  };

  const handleinitialEditingClick = (field: keyof EditingState) => {
    setEditingState((prevState) => ({
      ...initialEditingState,
      [field]: !prevState[field],
    }));
  };

  const handleFirstDiscardClick = () => {
    setIsDiscard(!isDiscard);
  };

  const handleDiscardChanges = async () => {
    if (tempData) {
      setGeneralData(tempData);
      setTempData(null);
    }
    setIsDiscard(false);
    setIsSaveClicked(false);
    setGeneralData((prevGeneralData) => ({
      ...prevGeneralData,
      gender: updateUser?.gender ?? prevGeneralData.gender,
      firstName: updateUser?.firstName ?? prevGeneralData.firstName,
      lastName: updateUser?.lastName ?? prevGeneralData.lastName,
      jobTitle: updateUser?.jobTitle ?? prevGeneralData.jobTitle,
    }));
    setUpdateUser({
      gender: updateUser?.gender,
      firstName: updateUser?.firstName,
      lastName: updateUser?.lastName,
      jobTitle: updateUser?.jobTitle,
    });


    // navigate(nextLocation);
  };

  const handleSaveClick = () => {
    setIsSaveClicked(true);
    if (!updateUser?.firstName || !updateUser?.lastName) {
      return;
    }
    dispatch(
      updateGeneralUserInfo({
        ...updateUser,
      }) as any
    );
    setIsDiscard(false);
    setGeneralData((prevGeneralData) => ({
      ...prevGeneralData,
      gender: updateUser?.gender ?? prevGeneralData.gender,
      firstName: updateUser?.firstName ?? prevGeneralData.firstName,
      lastName: updateUser?.lastName ?? prevGeneralData.lastName,
      jobTitle: updateUser?.jobTitle ?? prevGeneralData.jobTitle,
    }));
    setUpdateUser({
      gender: updateUser?.gender,
      firstName: updateUser?.firstName,
      lastName: updateUser?.lastName,
      jobTitle: updateUser?.jobTitle,
    });

    // navigate(nextLocation);
  };

  useBlocker(({ nextLocation }) => {
    if (
      (updateDocument && Object.keys(updateDocument).length > 0) ||
      // (updateUser && Object.keys(updateUser).length > 1)
      (updateUser?.firstName !== generalData.firstName ||
        updateUser?.lastName !== generalData.lastName ||
        updateUser?.gender !== generalData.gender ||
        updateUser?.jobTitle !== generalData.jobTitle
      )
    ) {
      handleFirstDiscardClick();
      setNextLocation(nextLocation.pathname);
      return true;
    }
    return false;
  });

  useEffect(() => {
    if (isDiscard === false && nextLocation) {
      navigate(nextLocation);
    }
  }, [isDiscard, nextLocation, navigate]);

  return (
    <>
      <ProfileLogoContainer>
        <AvatarWrapper>
          <Avatar src={userInfo?.avatar || DefaultAvatar} alt="logo" />
          <AvatarInput
            type="file"
            ref={avatarInputRef}
            id="input_file"
            accept="image/*"
            onChange={handleFileChange}
          />

          <AvatarLabel htmlFor="input_file">
            <PlusIcon />
          </AvatarLabel>
        </AvatarWrapper>
        <Wrapper>
          <LeftWrapper>
            <VerifyContainer>
              <Subtitle>First name</Subtitle>
              <Verify>*</Verify>
            </VerifyContainer>
            <RequiredField
              placeholder="Enter your first name"
              type="string"
              value={updateUser?.firstName}
              onChange={(e) =>
                setUpdateUser({
                  ...updateUser,
                  firstName: e.target.value,
                })
              }
              isEmpty={!updateUser?.firstName}
              isSaveClicked={isSaveClicked}
            />
            <VerifyContainer>
              <Subtitle>Last name</Subtitle>
              <Verify>*</Verify>
            </VerifyContainer>
            <RequiredField
              placeholder="Enter your last name"
              type="string"
              value={updateUser?.lastName}
              onChange={(e) =>
                setUpdateUser({
                  ...updateUser,
                  lastName: e.target.value,
                })
              }
              isEmpty={!updateUser?.lastName}
              isSaveClicked={isSaveClicked}
            />
            <BusinessInfoWrapper>
              <GenderSubtitle>Gender:</GenderSubtitle>
              <RadioContainer>
                <RadioWrapper>
                  <CircleSwitch
                    isActive={updateUser?.gender === GenderType.MALE}
                    onToggle={() =>
                      setUpdateUser((prevUpdateUser) => ({
                        ...prevUpdateUser,
                        gender: GenderType.MALE,
                      }))
                    }
                  />
                  <Text>Male</Text>
                </RadioWrapper>
                <RadioWrapper>
                  <CircleSwitch
                    isActive={updateUser?.gender === GenderType.FEMALE}
                    onToggle={() =>
                      setUpdateUser((prevUpdateUser) => ({
                        ...prevUpdateUser,
                        gender: GenderType.FEMALE,
                      }))
                    }
                  />
                  <Text>Female</Text>
                </RadioWrapper>
              </RadioContainer>
            </BusinessInfoWrapper>

            <Subtitle>Job title</Subtitle>
            <Field
              placeholder="Enter your job tittle"
              type="string"
              value={updateUser?.jobTitle ?? userInfo?.jobTitle}
              onChange={(e) =>
                setUpdateUser({
                  ...updateUser,
                  jobTitle: e.target.value,
                })
              }
            />
          </LeftWrapper>
          <RightWrapper>
            <RightFieldWrapper>
              <VerifyContainer>
                <Subtitle>Email:</Subtitle>
                <Verify>*</Verify>
              </VerifyContainer>
              <ButtonInputWrapper>
                <EmailField
                  placeholder="Enter your email"
                  type="string"
                  value={updateUser?.email ?? userInfo?.email}
                  onChange={(e) =>
                    setUpdateUser({
                      ...updateUser,
                      email: e.target.value,
                    })
                  }
                  isEmpty={!generalData.email}
                  isSaveClicked={isSaveClicked}
                  readOnly
                />
                <ChangeButton
                  onClick={() => handleinitialEditingClick("email")}
                >
                  Change
                </ChangeButton>
              </ButtonInputWrapper>
              <Subtitle>Mobile</Subtitle>
              <ButtonInputWrapper>
                <ConfirmField
                  placeholder="Enter your phone number"
                  type="string"
                  value={updateUser?.phoneNumber ?? userInfo?.phoneNumber}
                  onChange={(e) =>
                    setUpdateUser({
                      ...updateUser,
                      phoneNumber: e.target.value,
                    })
                  }
                  readOnly
                />

                <>
                  <ConfrmButton
                    onClick={() =>
                      handleinitialEditingClick("confirmationPhone")
                    }
                  >
                    Confirm
                  </ConfrmButton>
                  <ChangeButton
                    onClick={() => handleinitialEditingClick("phone")}
                  >
                    Change
                  </ChangeButton>
                </>
              </ButtonInputWrapper>
              <Subtitle>Password</Subtitle>
              <ButtonInputWrapper>
                <ConfirmField type="string" value="********" readOnly />

                <>
                  <ChangeButton
                    onClick={() => handleinitialEditingClick("password")}
                  >
                    Change
                  </ChangeButton>
                </>
              </ButtonInputWrapper>
            </RightFieldWrapper>

            <ChangeEditWrapper>
              <ButtonContainer>
                <ButtonWrapper>
                  {/* <ButtonWhite
                    text={"Discard"}
                    onClick={handleFirstDiscardClick}
                  /> */}
                  <Button text={"Update"} onClick={handleSaveClick} />
                </ButtonWrapper>
              </ButtonContainer>
              {imageTypeError && (
                <ModalImageErrorMessage
                  errorMessage={imageTypeError}
                  onClose={handleModalCLose}
                />
              )}
            </ChangeEditWrapper>
          </RightWrapper>
        </Wrapper>

        {editingState.password && (
          <ModalChangePassword
            handlePasswordClick={() => handleinitialEditingClick("password")}
          />
        )}
        {editingState.confirmationEmail && (
          <ModalConfirmation
            handleConfirmationClick={() =>
              handleinitialEditingClick("confirmationEmail")
            }
            replacementField="email"
          />
        )}
        {editingState.confirmationPhone && (
          <ModalConfirmation
            handleConfirmationClick={() =>
              handleinitialEditingClick("confirmationPhone")
            }
            replacementField="phone"
          />
        )}
        {editingState.email && (
          <ModalChangeField
            handleFieldClick={() => handleinitialEditingClick("email")}
            replacementField="email"
            oldValueState={userInfo!.email}
          />
        )}
        {editingState.phone && (
          <ModalChangeField
            handleFieldClick={() => handleinitialEditingClick("phone")}
            replacementField="phone"
            oldValueState={userInfo!.phoneNumber}
          />
        )}
        {isDiscard && (
          <ModalLostChanges
            text="The changes you made to  this page will be lost"
            buttonText="Keep editing"
            buttonWhitetext="Discard"
            handleWhiteButtonClick={handleDiscardChanges}
            handleButtonClick={handleSaveClick}
          />
        )}
      </ProfileLogoContainer>
      <Documents
        attachments={generalData?.attachments ?? []}
        onSaveClick={handleSaveClick}
        updateDocuments={updateDocument}
        onFormChange={setUpdateUser}
        handleDiscardClick={handleDiscardChanges}
      />
    </>
  );
};

export default Information;
