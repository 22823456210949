import React from "react";
import { ReturnBlock } from "./styles";
import { useNavigate } from "react-router-dom";
import ReturnIcon from "../../assets/icons/ReturnIcon";

const ReturnButton: React.FC = () => {
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1);
  };
  return (
    <ReturnBlock
      onClick={() => {
        handleBack();
      }}
    >
      <ReturnIcon />
    </ReturnBlock>
  );
};

export default ReturnButton;
