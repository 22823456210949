import React from "react";
import { MessageToProvider } from "../../utils/types";
import { TextArea } from "./style";


interface TextAreaProps {
  message: MessageToProvider;
  setMessage: React.Dispatch<React.SetStateAction<MessageToProvider>>;
}

const ContactToProviderInput: React.FC<TextAreaProps> = ({message, setMessage}) => {
  return (
    <TextArea
      cols={30}
      rows={10}
      placeholder="Your letter to the manufacturer"
      value={message?.text || ""}
      onChange={(e) =>
        setMessage({
          ...message,
          text: e.target.value,
        })
      }
    />
  );
};

export default ContactToProviderInput;
