import React from "react";

const LoginLine: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="397"
      height="6"
      viewBox="0 0 397 6"
      fill="none"
    >
      <path
        d="M0.333333 3C0.333333 4.47276 1.52724 5.66667 3 5.66667C4.47276 5.66667 5.66667 4.47276 5.66667 3C5.66667 1.52724 4.47276 0.333333 3 0.333333C1.52724 0.333333 0.333333 1.52724 0.333333 3ZM391.333 3C391.333 4.47276 392.527 5.66667 394 5.66667C395.473 5.66667 396.667 4.47276 396.667 3C396.667 1.52724 395.473 0.333333 394 0.333333C392.527 0.333333 391.333 1.52724 391.333 3ZM3 3.5H394V2.5H3V3.5Z"
        fill="#bdbdbd"
      />
    </svg>
  );
};

export default LoginLine;
