import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { uploadFilesToServer } from "../../store/slices/userSlice";
import { Attachment } from "../../utils/types";
import Button from "../Button/Button";
import ReturnButton from "../ReturnButton/ReturnButton";
import UploadContact from "./UploadContact/UploadContact";
import {
  AttachmentsWrapper,
  ButtonWrapper,
  Container,
  MailParagraph,
  Paragraph,
  RedStar,
  SubTitle,
  TextDetails,
  TextTitle,
  Title,
  TitleWrapper,
} from "./style";

interface DataForSend {
  title: string;
  description: string;
  attachments: Attachment[];
}

const ContactUs = () => {
  const dispatch = useDispatch();
  const [uploading, setUploading] = useState(false);
  const [formData, setFormData] = useState<DataForSend>({
    title: "",
    description: "",
    attachments: [],
  });
  const [filesToUpload, setFilesToUpload] = useState<File[]>([]);
  const handleAttachmentFilesChange = async (files: File[]) => {
    setUploading(true);
    setFilesToUpload(files);
    const saveFiles = await dispatch(uploadFilesToServer({ files: files }) as any);
    if (saveFiles.payload.message === '400') {
      saveFiles.payload = [];
    }
    setFormData({ ...formData, attachments: saveFiles.payload } as any);
    setUploading(false);
  };

  const handleTitleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setFormData({ ...formData, title: event.target.value });
  };

  const handleDescrChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setFormData({ ...formData, description: event.target.value });
  };

  const handleClickButton = () => {
    if (formData.title.trim() === "" || formData.description.trim() === "") {
      alert("Please fill in all required fields");
    } else {
      alert("Message sent successfully");
      setFormData({
        title: "",
        description: "",
        attachments: []
      });
      setFilesToUpload([]);
    }
  }

  return (
    <>
      <Container>
        <ReturnButton />
        <Title>Contact us</Title>
        <SubTitle>Selfman</SubTitle>
        <Paragraph>Israel, Tel-Aviv</Paragraph>
        <Paragraph>Dizengof street 41/4</Paragraph>
        <Paragraph>Index: 342729</Paragraph>
        <Paragraph>Tel: +(972) 74-53-82-761</Paragraph>
        <MailParagraph>salfmanpavel@salf.com</MailParagraph>
        <Title>Submit a request</Title>
        <TitleWrapper>
          <SubTitle>Title</SubTitle>
          <RedStar>*</RedStar>
        </TitleWrapper>
        <TextTitle
          value={formData.title}
          name="title"
          id="title"
          cols={30}
          rows={10}
          placeholder="Title..."
          onChange={handleTitleChange}
        />
        <TitleWrapper>
          <SubTitle>Description</SubTitle>
          <RedStar>*</RedStar>
        </TitleWrapper>
        <TextDetails
          value={formData.description}
          name="description"
          id="description"
          cols={30}
          rows={10}
          placeholder="Please enter the details of your request. Our staff member will contact you as soon as possible and provide detailed answers to your questions."
          onChange={handleDescrChange}
        />
        <AttachmentsWrapper>
          <SubTitle>Attachments</SubTitle>
          {uploading ?
            <div>Loading files...</div> :
            <UploadContact
              selectedFiles={filesToUpload}
              onFilesChange={handleAttachmentFilesChange}
            />}
        </AttachmentsWrapper>
        <ButtonWrapper>
          <Button text={"Send"} onClick={handleClickButton} />
        </ButtonWrapper>
      </Container>
    </>
  );
};

export default ContactUs;
