import React, {useState} from "react";
import {Container} from "./style";
import {RootState} from "../../store/store";
import {useDispatch, useSelector} from "react-redux";
import Information from "./Information/Information";
import {logoutFunc} from "../../store/slices/userSlice";

const PersonalInformation: React.FC = () => {
  const store = useSelector((state: RootState) => state.user.userAccount);
  const [isGeneralEditing, setIsGeneralEditing] = useState(false);



  const dispatch = useDispatch();

  const handleLogOut = () => {
    dispatch(logoutFunc() as any);
  };

  return (
      <>
        <Container>
          <Information
              userAvatar={store?.avatar}
              firstName={store?.firstName ?? ""}
              lastName={store?.lastName ?? ""}
              email={store?.email ?? ""}
              gender={store?.gender ?? ""}
              phoneNumber={store?.phoneNumber ?? ""}
              jobTitle={store?.jobTitle ?? ""}
              isGeneralEditing={isGeneralEditing}
          />
          {/* <Documents
          attachments={store?.attachments ?? []}
        /> */}
          {/*<ButtonWrapper onClick={handleLogOut}>*/}
          {/*  <LogOutIcon />*/}
          {/*  <Button>Log out</Button>*/}
          {/*</ButtonWrapper>*/}
        </Container>
      </>
  );
};


export default PersonalInformation;