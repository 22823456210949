import styled from "styled-components";

export const StyledSwitch = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 30px;
  padding: 6px;
  gap: 14px;

  border-radius: 20px;
  border: 1px solid var(--border, #bdbdbd);
  background: var(--wiht);
  cursor: pointer;
`;
