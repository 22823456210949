import Footer from "../components/Footer/Footer";
import LogInForm from "../components/LogInForm/LogInForm";
import Logo from "../components/Logo/Logo";
import styled from "styled-components";
import { getUserStatus } from "../store/selectors/userSelector";
import TitleHeader from "../components/TitleHeader/TitleHeader";
import { useAppSelector } from "../store/hooks";
import { Loading } from "../components/Loading/Loading";

const Wrapper = styled.div`
  max-width: 1256px;
  width: 100%;
  margin: 30px auto;
  padding: 0 20px;
`;

const FooterWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LogIn: React.FC = () => {
  const status = useAppSelector(getUserStatus);

  if (status === "loading") {
    return <Loading />;
  }
  if (status === "error") {
    return (
      <TitleHeader text={"We have some Problems. See u later 😒"}></TitleHeader>
    );
  }
  return (
    <Wrapper>
      <Logo/>
      <LogInForm></LogInForm>
      <FooterWrapper>
        <Footer />
      </FooterWrapper>
    </Wrapper>
  );
};

export default LogIn;
