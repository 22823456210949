import styled from "styled-components";
import { Link } from "react-router-dom";

export const ProductsWrapper = styled.ul`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
`;

export const Product = styled.li`
  position: relative;
  max-width: 367px;
  display: flex;
  flex-direction: column;
  padding: 30px;

  border-radius: 16px;
  border: 1px solid var(--border, #e8e8e8);
  background: var(--wiht, #fff);
`;

export const Image = styled.img`
  width: 307px;
  height: 270px;
  margin-bottom: 14px;
  border-radius: 16px;
  object-fit: scale-down;
`;

export const Title = styled.h3`
  margin-bottom: 12px;

  font-size: 20px;
  line-height: 140%;

  color: var(--txt-dark, #001731);
`;

export const Price = styled.h3`
  margin-top: auto;
  margin-bottom: 12px;

  font-size: 24px;
  font-weight: 600;
  line-height: 140%;

  color: var(--txt-dark, #001731);
`;

export const RatingReviewsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
`;

export const Reviews = styled.h5`
  font-size: 17px;
  line-height: 140%;

  color: var(--txt-dak-light, #143255);
`;


export const PreviewProduct = styled(Link)`

`;