import styled from "styled-components";

export const ReturnBlock = styled.button`
  width: 100%;
  max-width: 70px;
  height: 20px;

  display: inline-flex;
  padding: 10px 20px;
  align-items: center;

  border-radius: 16px;
  border: 1px solid var(--border, #bdbdbd);
  background: var(--wiht, #fff);
`;
