import { StyledTitle } from "./styles";

interface ITitleProps {
  text: string;
}

const Title = ({ text }: ITitleProps) => {
  return <StyledTitle>{text}</StyledTitle>;
};

export default Title;
