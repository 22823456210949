import { StyledTextAuth } from "./styles";

interface ItextProps {
  text: string;
}

const TextAuth = ({ text }: ItextProps) => {
  return <StyledTextAuth>{text}</StyledTextAuth>;
};

export default TextAuth;
