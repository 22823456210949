import React, { useEffect } from "react";
import styled from "styled-components";
import { Title } from "../components/AgentProviderDelegateSearchPage/style";
import { RootState } from "../store/store";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../store/hooks";
import { routes } from "../routes/routes";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
`;

const NotFound: React.FC = () => {
  const user = useAppSelector((state: RootState) => state.user);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user.userAccount) {
      navigate(routes.HOME);
    }
  }, [user, navigate]);

  return (
    <Container>
      <Title>Oooops🙈 Page was NOT Found</Title>
    </Container>
  );
};
export default NotFound;
