import { useAppSelector } from "../../../store/hooks";
import { RootState } from "../../../store/store";
import {
  CompanyName,
  UserLogo,
  UserName,
  UserWrapper,
  NamesWrapper,
} from "../styles";
import defaultAvatar from "../../../assets/img/default-avatar.png";
import { DialoueData } from "../../../utils/types";


interface Props {
  side: string;
  userInfo: DialoueData;
}

const CompanionsNames = ({ side, userInfo }: Props) => {
  const user = useAppSelector((state: RootState) => state.user);

  return (
    <>
      {side === "right" && (
        <NamesWrapper side={side}>
          <UserLogo src={user.userAccount!.avatar || defaultAvatar} />
          <UserWrapper>
            <UserName>
              {user.userAccount!.firstName} {user.userAccount!.lastName}
            </UserName>
            <CompanyName>{user.company!.companyName}</CompanyName>
          </UserWrapper>
        </NamesWrapper>
      )}

      {side === "left" && (
        <NamesWrapper side={side}>
          <UserLogo src={userInfo?.avatar || defaultAvatar} />
          <UserName>{userInfo?.name}</UserName>
        </NamesWrapper>
      )}
    </>
  );
};

export default CompanionsNames;
