import styled from "styled-components";

interface IsEditingProps {
  disabled?: boolean;
}

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 898px;
  margin-bottom: 30px;
  padding: 30px;
  border-radius: 16px;
  border: 1px solid var(--border, #bdbdbd);
  background: var(--wiht, #fff);
`;

export const ButtonContainer = styled.div`
  margin-left: auto;
  display: flex;
  gap: 20px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 20px;
`;

export const Avatar = styled.img`
  display: flex;
  position: absolute;
  width: 100px;
  height: 100px;
  border-radius: 100px;
  object-fit: cover;
`;

export const AvatarWrapper = styled.div`
  position: relative;
  display: flex;
  margin-bottom: 20px;
`;

export const AvatarInput = styled.div`
  position: absolute;
  left: 69px;
  top: 71px;
  cursor: pointer;
`;

export const TitleProfileWrapper = styled.div`
  position: absolute;
  left: 130px;
  margin-bottom: 10px;
  padding-top: 15px;
`;

export const TitleProfile = styled.div`
  font-size: 17px;
`;

export const TitleJoined = styled.div`
  font-size: 17px;
  margin-top: 10px;
`;

export const ProfileCompletionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`;

export const TooltipWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 5px;
  cursor: pointer;
  position: relative;
`;

export const TitleAndToolTip = styled.div`
  display: flex;
`;

export const TooltipProfileCompletion = styled.h5`
  font-size: 14px;
  position: absolute;
  left: 186px;
  bottom: 74px;
  width: 500px;
  padding: 20px;
  border-radius: 16px;
  color: var(--txt-dark-light, #143255);
  border: 1px solid var(--border, #bdbdbd);
  background: var(--wiht, #fff);
  line-height: 140%;
  white-space: wrap;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
`;

export const Label = styled.label`
  margin-bottom: 2px;
  font-size: 17px;
  line-height: 140%;
  color: var(--txt-light-blue-dont-active, #a0b7d1);
`;
export const LabelYesNo = styled.p`
  width: 30%;
  margin-bottom: 2px;
  font-size: 17px;
  line-height: 140%;
  color: var(--txt-light-blue-dont-active, #a0b7d1);
`;

export const RedStar = styled.p`
  font-size: 17px;
  font-weight: 400;
  line-height: 140%;
  color: var(--txt-red, #f00);
`;

export const Input = styled.input`
  width: 100%;
  max-width: 828px;
  padding: 6px 0px 6px 10px;
  margin-bottom: 18px;
  font-size: 17px;
  line-height: 140%;
  color: var(--txt-dak-light, #143255);
  border: 1px solid var(--border, #bdbdbd);
  border-radius: 12px;
  &:focus {
    border: 1px solid var(--batton, #0095c0);
  }
  &::placeholder {
    color: var(--txt-grey-light, #717880);
    font-size: 17px;
    font-weight: 400;
    line-height: 140%;
  }
`;

export const TitleYesNoWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin: 10px 0;
`;

export const TitleWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 4px;
`;

export const TitleIndustryWrapper = styled.div`
  display: flex;
  margin-bottom: 10px;
  padding-top: 5px;
  position: relative;
`;

export const TooltipIndustry = styled.h5`
  font-size: 14px;
  position: absolute;
  left: 135px;
  bottom: 22px;
  width: 600px;
  padding: 20px;
  border-radius: 16px;
  color: var(--txt-dark-light, #143255);
  border: 1px solid var(--border, #bdbdbd);
  background: var(--wiht, #fff);
  line-height: 140%;
  white-space: wrap;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
`;

export const SectionWrapper = styled.div`
  margin-bottom: 4px;
`;

export const KeywordLabel = styled.div`
  font-size: 17px;
  color: var(--txt-light-blue-dont-active, #a0b7d1);
  margin-bottom: 4px;
`;

export const ChipsList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 4px;
`;

export const StyledCloseIcon = styled.div`
  display: none;
  margin-left: 8px;
`;

export const Chips = styled.li`
  background-color: #fafafa;
  padding: 8px 16px 10px 16px;
  margin: 5px 5px 5px 0;
  border-radius: 12px;
  font-size: 14px;
  height: 38px;
  border: 1px solid var(--border, #e8e8e8);
  cursor: pointer;
  display: inline-flex;
  align-items: center;

  &:hover {
    border: 1px solid #004c62;

    ${StyledCloseIcon} {
      display: inline-block;
    }
  }
`;

export const SectionWithDropdownWrapper = styled.div`
  position: relative;
  margin-bottom: 4px;
`;

export const DropdownMenu = styled.div`
  position: absolute;
  top: 27px;
  z-index: 1;
`;

export const InputWithDropdown = styled.input`
  width: 100%;
  width: 828px;
  padding: 6px 30px 6px 10px;
  margin: 4px 0;
  font-size: 17px;
  line-height: 140%;
  color: var(--txt-dak-light, #143255);
  border: 1px solid var(--border, #bdbdbd);
  border-radius: 12px;
  &:focus {
    border: 1px solid #0095c0;
  }
  &::placeholder {
    color: #d0d0d0;
    font-size: 17px;
    font-weight: 400;
    line-height: 140%;
  }
`;

export const InputDropdownWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

export const ChevronDownIconWrapper = styled.div`
  position: absolute;
  right: 12px;
`;

export const LabelAbout = styled.div`
  margin: 15px 0;
`;

export const AboutTextArea = styled.textarea`
  border-radius: 16px;
  border: 1px solid var(--border, #e8e8e8);
  background: var(--wiht, #fff);
  font-size: 17px;
  line-height: 140%;
  resize: none;
  width: 798px;
  height: 430px;
  padding: 20px;
  margin-bottom: 15px;
`;

export const SliderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
`;

export const TooltipSlider = styled.h5`
  font-size: 14px;
  position: absolute;
  width: 575px;
  left: 233px;
  bottom: 18px;
  padding: 20px;
  border-radius: 16px;
  color: var(--txt-dark-light, #143255);
  border: 1px solid var(--border, #bdbdbd);
  background: var(--wiht, #fff);
  line-height: 140%;
  white-space: wrap;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
`;

export const MediaUploadWrapper = styled.div<IsEditingProps>`
  display: block;
  max-width: 153px;
  width: 100%;
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
`;

export const UploadDialogWrapper = styled.div``;

export const SliderTitleAndToolTip = styled.div`
  display: flex;
  position: relative;
`;

export const SliderBigWrapper = styled.div`
  margin: 20px 0 30px;
  padding: 10px 0 10px 10px;
  position: relative;
`;

//////////////////////

export const RadioWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-bottom: 5px;
`;
export const Checkmark = styled.div`
  position: relative;
  display: inline-block;
  height: 16px;
  width: 16px;
  margin-right: 8px;

  border: 1px solid var(--dark, #001731);
  border-radius: 20px;

  &:after {
    content: "";
    position: absolute;
    display: none;
  }

  input:checked + & {
    background-color: var(--batton, #0095c0);
    border: 1px solid var(--batton, #0095c0);
  }

  input:checked + &:after {
    display: block;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #fdfdfd;
  }
`;
export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
`;

export const IndustryWrapper = styled.div`
  display: flex;
`;

export const IndustryChangeButton = styled.button`
  margin-left: 20px;
  padding: 0 20px;
  height: 38px;
  border-radius: 8px;
  border: 1px solid var(--batton, #0095c0);
  color: var(--txt-blue, #0095c0);
  font-family: Montserrat;
  font-size: 17px;
`;

//////////////////////

export const List = styled.ul<IsEditingProps>`
  display: flex;
  flex-direction: column;
  /* align-items: flex-end; */
`;

export const Item = styled.li`
  display: flex;
  align-items: center;

  margin-bottom: 20px;
  padding: 3px;

  font-size: 18px;
  line-height: 140%;

  color: var(--txt-dak-light, #143255);

  border-radius: 16px;
  cursor: move;
`;

export const VectorWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 18px;
  padding: 5px 4px;
  margin-right: 8px;

  border-radius: 16px;
  background-color: var(--green, #03b62a);
`;

export const CloseUploadWrapper = styled.div<IsEditingProps>`
  display: ${(props) => (props.disabled ? "none" : "flex")};
  margin-left: 8px;
  align-items: center;
  cursor: pointer;
`;

export const FileName = styled.h5`
  font-size: 17px;
  line-height: 140%;

  color: var(--txt-dak-light, #143255);
`;

export const ImageTooltip = styled.img`
  width: 30px;
  height: 30px;
  margin: 0 5px;
  cursor: pointer;
`;

export const ImageHoverWrapper = styled.div`
  position: absolute;
  top: 20px;
  right: 880px;
  width: 300px;
  height: 250px;
  border-radius: 16px;
  cursor: pointer;
`;

export const ImageHover = styled.img`
  width: 100%;
  height: 100%;
  object-fit: scale-down;
`;
