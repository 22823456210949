import { ButtonPopularRequest } from "./Styles";

interface IRequestProps {
  name: string;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
}

const PartnerBasePopularRequest = ({ name, setSearchValue }: IRequestProps) => {

const handleButtonClick = () => {
  setSearchValue(name);
};

  return <ButtonPopularRequest onClick={handleButtonClick}>{name}</ButtonPopularRequest>;
};

export default PartnerBasePopularRequest;
