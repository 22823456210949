import { useEffect } from "react";
import {
  Tittle,
  Input,
  ButtonBlock,
} from "../../ListPage/styles";
import SearchIcon from "../../../assets/icons/SearchIcon";
// import PopularRequest from "../../PopularRequest/PopularRequest";
import { Container, InputBlock, RequestBlock } from "./style";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getPopularPartnerIndustryAsync } from "../../../store/slices/PartnerBaseSearch/partnerBaseSearchSlice";
import { routes } from "../../../routes/routes";
import PartnerBasePopularRequest from "../PartnerBasePopularRequest/PartnerBasePopularRequest";
import { getAllProvidersAsync, searchProvidersAsync } from "../../../store/slices/userSlice";
import { ProviderDetails } from "../../../utils/types";
import { RootState } from "../../../store/store";

interface Props {
  searchValue: string;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
  filterValues: ProviderDetails;
}

const PartnerBasePage = ({ searchValue, setSearchValue, filterValues }: Props) => {
  const dispatch = useAppDispatch();
  const popularIndustry = useAppSelector((state) => state.partnerBaseSearch.popularPartnerIndustry);
  const user = useAppSelector((state: RootState) => state.user);
  const currentPage = `/${window.location.pathname.split('/').pop()}`;
  

  useEffect(() => {
    dispatch(getPopularPartnerIndustryAsync());
  }, []);

  const handleSearchProviders = () => {
    if (searchValue !== '') {
      dispatch(searchProvidersAsync({credentials: filterValues, searchValue: [searchValue]}))
        .then((result: any) => {
          // if(result.payload.content && result.payload.content.length === 0){
          //   setResOfSearch('No search results');
          // }
        })
        .catch((error: any) => {
          console.error("error", error);
        });
    } else {
      dispatch(getAllProvidersAsync({credentials: filterValues}));
    }
  };

  return (
    <Container>
      <InputBlock>
        <Input
          value={searchValue}
          type="text"
          placeholder="Search by product, industry or keywords"
          onChange={(e) => setSearchValue(e.target.value)}
        />
        <ButtonBlock onClick={handleSearchProviders} >
          <SearchIcon />
        </ButtonBlock>
      </InputBlock>
      <RequestBlock>
        <Tittle>Popular:</Tittle>
        {currentPage === routes.PARTNERBASE && popularIndustry && popularIndustry.slice(0, 5).map((industry, key) => {
          return <PartnerBasePopularRequest key={key} name={industry} setSearchValue={setSearchValue} />
        })}
      </RequestBlock>
    </Container>
  );
};

export default PartnerBasePage;
