import { Outlet } from "react-router-dom";
import { FooterWrapper, StyledMainTemplate, Wrapper } from "./styles";
import SideBar from "../SideBar/SideBar";
import Footer from "../Footer/Footer";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { useEffect } from "react";
import {
  fetchCountriesAsync,
  getAllOrdersDashboardAsync,
  getAllDelegatedBriefAsync,
  getAllProduct,
  getAllProvidersAsync,
  getAllRequestsAsync,
  getAllRequestsDashboardAsync,
  getCompanyInfoAsync,
  logoutFunc,
  refreshTokenAsync,
  getAllOrdersAsync,
} from "../../store/slices/userSlice";
import { TIME_FOR_UPDATE_JWT } from "../../utils/constants";
import { CompanyType } from "../../utils/types";
import {
  getAllFavorite,
  getAllFavoriteWithoutPagination,
  searchKeywords,
  searchTopKeywords,
} from "../../api/internetSearch";
const MainTemplate = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((store) => store.user.userAccount!);
  const roles = user?.roles.map((role) => role.name) || [];
  const page = 0;



  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     dispatch(refreshTokenAsync({ refreshToken: user.refreshToken })).then(
  //       (result: any) => {
  //         console.log(result);
          
  //         if (result.payload.message === "403") {
  //           dispatch(logoutFunc() as any);
  //           return;
  //         }
  //       }
  //     );
  //   }, TIME_FOR_UPDATE_JWT);

  //   return () => clearInterval(interval);
  // }, [dispatch, user]);

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(
        getAllProvidersAsync({
          credentials: {}
        }) as any
      );
      await dispatch(
        getCompanyInfoAsync({
          companyType: user?.companyType && user?.companyType.toLowerCase() as CompanyType,
        })
      );
      roles?.includes("ROLE_PROVIDER") &&
      dispatch(
        getAllProduct());
    };
    dispatch(searchKeywords(user?.token as string, page));
    dispatch(fetchCountriesAsync() as any);
    fetchData();
    dispatch(getAllDelegatedBriefAsync())
    dispatch(getAllOrdersAsync());
  }, []);

  useEffect(() => {
    dispatch(getAllRequestsAsync() as any);
    dispatch(getAllFavorite(user?.token as string, page));
    dispatch(searchTopKeywords(user?.token  as string));
    dispatch(getAllFavoriteWithoutPagination(user?.token as string));
  }, [dispatch]);


  return (
    <>
      <StyledMainTemplate>
        <SideBar />
        <Wrapper>
          <Outlet />
        </Wrapper>
      </StyledMainTemplate>
      <FooterWrapper>
        <Footer />
      </FooterWrapper>
    </>
  );
};

export default MainTemplate;