import React, { useEffect } from "react";
import { RootState } from "../store/store";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../store/hooks";
import { routes } from "../routes/routes";
import FactorySettings from "../components/FactorySettings/FactorySettings";

const ProviderSettings: React.FC = () => {
  const user = useAppSelector((state: RootState) => state.user);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user.agent?.roles && !user.provider?.roles && !user.customer?.roles) {
      navigate(routes.HOME);
    }
  }, [user, navigate]);

  return <FactorySettings />;
};

export default ProviderSettings;
