import styled from "styled-components";

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const CompanyNameStyle = styled.div`
    font-size: 46px;
    font-weight: 600;
    line-height: 120%;
    max-width: 75%;
    text-align: center;
    margin-bottom: 20px;
    color: var(--txt-dark, #001731);

    &:hover {
        color: blue;
        cursor: pointer;
    }
`;

export const WebsiteLinkStyle = styled.div`
    cursor: pointer;
    font-size: 20px;
    font-weight: 500;
    text-decoration: underline;
 
`;


export const BlockWrapperStyle = styled.div`
    display: flex;
    align-items: center;
    margin-top: 20px;
`;

export const BlockDescriptionStyle = styled.div`
    font-size: 20px;
    font-weight: 400;
    margin-right: 10px;
`;

export const AdressStyle = styled.div`
    font-size: 22px;
    font-weight: 500;
`;

export const ContactsWrapper = styled.div`
    margin: 30px;
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const PhonesEmailsWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
`;

export const PhoneEmailLinkStyle = styled.a`
     font-size: 18px;
     font-weight: 400;
     color: black;
     margin: 10px 0;
     letter-spacing: 0.2mm;

    &:hover{
        text-decoration: underline;
        color: blue;
    }
`;

export const PhonesEmailsTitle = styled.div`
    font-size: 18px;
    font-weight: 400;
    text-decoration: underline;
`;

export const PhonesEmailsBlockWrapper = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const PhonesEmailsListStyle = styled.div`
    display: flex;
    flex-direction: column;
    margin: 20px;
    align-items: center;
`;