import React, { useEffect, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  Container,
  ErrorBlock,
  InputWrapper,
  ButtonWrapper,
  ButtonSignUp,
  SignUpWrapper,
  ForgotWrapper,
  TogglePasswordButton,
  CloseWrapper,
  TitleWrapper,
  TextAuthWrapper,
  SubtitleError,
  SignUpText,
} from "./styles";
import Button from "../Button/Button";
import { Link, useNavigate } from "react-router-dom";
import LoginLine from "../../assets/icons/LineIcon";
import EyeIcon from "../../assets/icons/EyeIcon";
import EyeOffIcon from "../../assets/icons/EyeOffIcon";
import { routes } from "../../routes/routes";
import Close from "../Close/Close";
import Title from "../Title/Title";
import { UserLogin } from "../../utils/types";
import {
  getAllAgentsAsync,
  loginUserAsync,
} from "../../store/slices/userSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getLocation, searchByLocation } from "../../api/internetSearch";
import TextAuth from "../TextAuth/TextAuth";

const LoginForm: React.FC = () => {
  const dispatch = useAppDispatch();
  const { status } = useAppSelector((state) => state.user);
  const [errorCode, setErrorCode] = useState<string>("");
  const [showPassword, setShowPassword] = useState(false);
  const token = useAppSelector((state) => state.user.userAccount?.token);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Incorrect email entered*")
      .required("Login is obligatory field"),
    password: Yup.string()
      .required("Incorrect password entered*")
      .min(8, "Password is too short - should be 8 chars minimum."),
  });

  const initialValues: UserLogin = {
    email: "",
    password: "",
  };

  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  // const handleSignUpClick = () => {
  //   navigate(routes.SIGN_UP);
  // };

  const handleSubmit = async (values: UserLogin) => {
    await dispatch(
      loginUserAsync({
        email: values.email.toLowerCase(),
        password: values.password,
      }) as UserLogin | any
    )
      .then((result: any) => {
        if (result.payload.message === "401") {
          setErrorCode("Invalid email or password. Please try again.");
          return;
        } else if (result.payload.message === "404") {
          setErrorCode(
            "User not found. Please try again or sign up for a new account."
          );
          return;
        } else if (result.payload.message === "403") {
          setErrorCode("Access denied.");
          return;
        } 
        else {
          dispatch(getAllAgentsAsync({}) as any);
          navigate(`/account${routes.PERSONALINFORMATION}`);
        }
      })
      .catch((error: any) => {
        if (error.response) {
          if (error.response.status === 501) {
            console.error("Server error: Not Implemented");
          } else if (error.response.status === 500) {
            console.error("Server error: Internal Server Error");
            return;
          }
        }
        console.error("Login failed:", error);
      });
  };

  useEffect(() => {
    const fetchLocation = async () => {
      try {
        await dispatch(getLocation());
      } catch (e) {}
    };
    fetchLocation();
  }, []);

  return (
    <Container>
      <CloseWrapper onClick={handleBack}>
        <Close />
      </CloseWrapper>
      <TitleWrapper>
        <Title text={"Log in to SelfMan"} />
      </TitleWrapper>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched }) => (
          <Form>
            <ErrorBlock>
              <ErrorMessage
                name="email"
                component="p"
                className="error-message"
              />
              <ErrorMessage
                name="password"
                component="p"
                className="error-message"
              />
              {errorCode && <SubtitleError>{errorCode}</SubtitleError>}
            </ErrorBlock>

            <InputWrapper $hasError={!!errors.email && touched.email}>
              <Field type="email" name="email" placeholder="Email" />
            </InputWrapper>

            <InputWrapper $hasError={!!errors.password && touched.password}>
              <Field
                type={showPassword ? "text" : "password"}
                name="password"
                placeholder="Password"
              />
              <TogglePasswordButton
                type="button"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <EyeIcon /> : <EyeOffIcon />}
              </TogglePasswordButton>
            </InputWrapper>
            <ButtonWrapper>
              <Button
                disabled={status === "loading"}
                text={status === "loading" ? "loading..." : "Log in"}
                onClick={() => dispatch(searchByLocation(token as string))}
              />
            </ButtonWrapper>
          </Form>
        )}
      </Formik>
      {/* Расскоментить потом */}
      {/* <ForgotWrapper>
        <Link to={routes.SIGN_UP}>
          Do you need help? Forgot your login or password?
        </Link>
      </ForgotWrapper> */}
      <LoginLine />
      <TextAuthWrapper>
        <TextAuth text={"Don't have a SelfMan account?"} />
      </TextAuthWrapper>
      <SignUpWrapper>
        {/* <ButtonSignUp onClick={handleSignUpClick}>Sign up</ButtonSignUp> */}
        <SignUpText>
          Open registration is not currently available. Write to
          pavel@selfman.io and we will give you access
        </SignUpText>
      </SignUpWrapper>
    </Container>
  );
};

export default LoginForm;
