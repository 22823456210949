import React, { useEffect, useState } from 'react';
import CrossCircleIcon from '../../assets/icons/CrossCircleIcon';
import TitleText from '../TitleText/TitleText';
import {
    CloseIconWrapper,
    ErrorMessage,
    ErrorNotification,
    ModalContainer,
    NotificationHeader
} from './Styles';

interface ModalImageError {
    errorMessage: string;
    onClose: () => void;
}

const ModalImageErrorMessage: React.FC<ModalImageError> = ({ errorMessage, onClose }) => {
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        if(errorMessage){
            setShowModal(true);   
        }
      }, [errorMessage]);
      
    const handleModalState = () => {
        setShowModal(false);
        onClose();
    }
    return (
        <>
        {showModal && (
            <ModalContainer>
                <ErrorNotification>
                    <NotificationHeader>
                        <TitleText text={"Error Notification"} />
                        <CloseIconWrapper>
                            <CrossCircleIcon onClick={handleModalState} />
                        </CloseIconWrapper>
                    </NotificationHeader>
                    <ErrorMessage>
                        {errorMessage}
                    </ErrorMessage>
                </ErrorNotification>
            </ModalContainer>
        )}
        </>
    )
}

export default ModalImageErrorMessage