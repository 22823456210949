import { customFetch } from "../CustomFetch/CustomFetch";

const AssistantsActions = {

  async createInstructions(user_input: string) {
    try {
      const response = await fetch(`https://selfman.onrender.com/chat`, {
        method: "post",
        body: JSON.stringify({ user_input: user_input }),
        headers: {
          "Content-Type": "application/json",
          // Authorization: `Bearer ${credentials.token}`,
        },
        credentials: "include",
      });
      if (response.ok) {
        const data = await response.json();
        return { data };
      } else {
        throw new Error(response.status.toString());
      }
    } catch (error: string | any) {
      throw { message: error.message };
    }
  },

};



// new actions with custom fetch


export async function deleteChat(chatId: number) {
  return customFetch(`assistant/hideChatAssistantSpecification/${chatId}`, {
    method: "PUT",
  });
};

export async function changeChatName(chatId: number, newChatName: string) {
  return customFetch(`assistant/renameChatAssistantSpecification`, {
    method: "PUT",
    body: JSON.stringify({ id: chatId, newName: newChatName }),
  });
};

export async function createMessageToThreadAndRun(message: string, threadId: string, assistantId: string) {
  return customFetch(`assistant/createMessageAndRunAssistantSpecification/${threadId}/${assistantId}`, {
    method: "POST",
    body: JSON.stringify({ message }),
  });
};

export async function createChat(industryId: string, activityId: string, instructions: string) {
  return customFetch(`assistant/createChatAssistantSpecification`, {
    method: "POST",
    body: JSON.stringify({ industryId, activityId, instructions }),
  });
};

export async function getAllIndustries() {
  return customFetch(`getAllIndustries`);
};

export async function getAllMessagesInThread(threadId: string) {
  return customFetch(`assistant/getAllMessagesThreadAssistantSpecification/${threadId}`);
};

export async function getAllChats() {
  return customFetch(`assistant/getAllChatAssistantSpecification`);
};

export default AssistantsActions;