import React from "react";

const Clip: React.FC = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="none" viewBox="0 0 24 24">
    <path fill="rgb(0, 149, 192)" fillRule="evenodd" d="M9 7a5 5 0 0 1 10 0v8a7 7 0 1 1-14 0V9a1 1 0 0 1 2 0v6a5 5 0 0 0 10 0V7a3 3 0 1 0-6 0v8a1 1 0 1 0 2 0V9a1 1 0 1 1 2 0v6a3 3 0 1 1-6 0z" clipRule="evenodd" stroke="rgb(0, 149, 192)" strokeWidth="0.1">
    </path>
</svg>
    );
};

export default Clip;































