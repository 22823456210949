import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ArrowLeftIcon from "../../../assets/icons/ArrowLeftIccon";
import ArrowRightIcon from "../../../assets/icons/ArrowRightIcon";
import FbIcon from "../../../assets/icons/FbIcon";
import InstIcon from "../../../assets/icons/InstIcon";
import ItemIcon from "../../../assets/icons/ItemIcon";
import LinInIcon from "../../../assets/icons/LinInIcon";
import SiteIcon from "../../../assets/icons/SiteIcon";
import { defaultAvatar } from "../../../assets/img";
import { AgentAccount, AgentBaseAgent, AgentDetails, TypeFee } from "../../../utils/types";
import Button from "../../Button/Button";
import ButtonWhite from "../../ButtonWhite/ButtonWhite";
import {
  Container,
  AboutWrapper,
  HeaderWrapper,
  Avatar,
  Title,
  SocialWrapper,
  AvailabilityWrapper,
  Label,
  Value,
  SocialMedia,
  Wrapper,
  IndustryValue,
  KeywordsWrapper,
  Keyword,
  KeywordText,
  AboutBlockWrapper,
  About,
  ListWrapper,
  List,
  Item,
  Link,
  IconWrapper,
  ListValue,
  ValueRate,
  SuccessWrapper,
  ButtonWrapper,
  AvatarWrapper,
  TitleError,
  DownWrapper,
  LocationText,
  Up,
  FeeValue,
  ConfirmedWrapper,
  VectorWrapper,
  RatingReviewsWrapper,
} from "./styles";
import { getAgentByIdAsync } from "../../../store/slices/userSlice";
import { RootState } from "../../../store/store";
import { allAgentsArray } from "../../../utils/constants";
import UnionIcon from "../../../assets/icons/UnionIcon";
import VectorYellowIcon from "../../../assets/icons/VectorYellowIcon";
import { renderStars } from "../../CompanyItem/CompanyItem";

const AgentToConnect: React.FC = () => {
  // const store = useSelector((state: RootState) => state.user.agentById);
  const id = window.location.pathname.split('/').pop();
  const store = allAgentsArray.find(agent => agent.id === +id!);
  const dispatch = useDispatch();
  const [agent, setAgent] = useState(store);
  const [errorCode, setErrorCode] = useState<string | null>(null);

  const urlString = window.location.href;
  const urlParts = urlString.split("/");
  const lastPart = +urlParts[urlParts.length - 1];

  useEffect(() => {
    // dispatch(getAgentByIdAsync(lastPart) as any)
    //   .then((result: any) => {
    //     if (result.payload.message === "404") {
    //       setErrorCode("User not found");
    //     }
    //   })
    //   .catch((error: any) => {
    //     console.error("Error fetching agent:", error);
    //   });
  }, [dispatch, lastPart]);

  useEffect(() => {
    setAgent(store);
  }, [store]);

  const formatAvailability = (availability: string) => {
    if (!availability) return "";
    return (
      availability.charAt(0).toUpperCase() +
      availability.slice(1).toLowerCase().split("_").join(" ")
    );
  };

  const textAbout = agent?.about ? agent.about.split("\n") : [];

  return (
    <>
      <Container>
        {errorCode && <TitleError>{errorCode}</TitleError>}
        {!errorCode && (
          <>
            <AboutWrapper>
              <HeaderWrapper>
                <AvatarWrapper>
                  <Avatar src={agent?.avatar || defaultAvatar} alt="logo" />
                  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }} >
                    <div style={{display: 'flex', alignItems: 'center'}}>
                      <Title>{agent?.firstName} {agent?.lastName}</Title>
                      {agent?.confirmed && (
                        <ConfirmedWrapper>
                          <UnionIcon />
                          <VectorWrapper>
                            <VectorYellowIcon />
                          </VectorWrapper>
                        </ConfirmedWrapper>
                      )}
                    </div>
                    
                    <RatingReviewsWrapper>
                      {renderStars(Math.min(agent?.rating!, 5))}({agent?.reviews})
                    </RatingReviewsWrapper>
                  </div>
                </AvatarWrapper>
                <ButtonWrapper>
                  <Button text={"Connect"}></Button>
                  <ButtonWhite text={"Save"}></ButtonWhite>
                </ButtonWrapper>
              </HeaderWrapper>
              <SocialWrapper>
                <AvailabilityWrapper>
                  <Label>Availability:</Label>
                  <Value>{formatAvailability(agent?.availability || "")}</Value>
                </AvailabilityWrapper>
                <SuccessWrapper>
                  <Label>Success rate:</Label>
                  <ValueRate>{agent?.successRate}% <Up>& Up</Up></ValueRate>
                </SuccessWrapper>
                <SocialMedia>
                  {/* {agent?.socialMedia.map(
                    (social, index) =>
                      social.link && (
                        <Link target="_blank" key={index} href={social.link}>
                          {social.name === "Web site" && <SiteIcon />}
                          {social.name === "Instagram" && <InstIcon />}
                          {social.name === "Facebook" && <FbIcon />}
                          {social.name === "LinkedIn" && <LinInIcon />}
                        </Link>
                      )
                  )} */}
                  {agent?.socialMedia.map(
                    (social, index) =>

                      <Link target="_blank" key={index}>
                        {social.name === "web site" && <SiteIcon />}
                        {social.name === "instagram" && <InstIcon />}
                        {social.name === "facebook" && <FbIcon />}
                        {social.name === "linkedin" && <LinInIcon />}
                      </Link>
                  )}
                </SocialMedia>
              </SocialWrapper>
              {agent && (
                <Wrapper>
                  <Label>Fee:</Label>
                  <Wrapper>
                    <FeeValue>
                      {agent.fee === TypeFee.HOUR ?
                        `${agent.priceHour}$/${TypeFee.HOUR.toLowerCase()}` :
                        `${agent.priceHour}$`}
                    </FeeValue>
                  </Wrapper>
                </Wrapper>
              )}
              <Wrapper>
                <Label>Category:</Label>
                <Wrapper>
                  {agent?.category.map((item, index) => (
                    <IndustryValue key={index}>{item}</IndustryValue>
                  ))}
                </Wrapper>
              </Wrapper>
              <Label>Keywords:</Label>
              <KeywordsWrapper>
                {agent?.keywords.map((keyword, index) => (
                  <Keyword key={index}>
                    <KeywordText>{keyword}</KeywordText>
                  </Keyword>
                ))}
              </KeywordsWrapper>
              <AboutBlockWrapper>
                {textAbout.map((line, index) =>
                  line ? <About key={index}>{line}</About> : <br key={index} />
                )}
              </AboutBlockWrapper>
              <ListWrapper>
                <List>
                  <DownWrapper>
                    <ItemIcon />
                    <LocationText>
                      Location: {agent?.agentContactInfo.country},{" "}
                      {agent?.agentContactInfo.city}
                    </LocationText>
                  </DownWrapper>
                  <DownWrapper>
                    <ItemIcon />
                    <LocationText>Languages: {agent?.languages.join(', ')}</LocationText>
                  </DownWrapper>
                </List>
              </ListWrapper>
            </AboutWrapper>
          </>
        )}
      </Container>
    </>
  );
};

export default AgentToConnect;
