import styled from "styled-components";
import Calendar from "react-calendar";

export const StyledCalendar = styled(Calendar)`
  /* border: 1px solid var(--batton, #0095c0); */
  /* border-radius: 12px; */
  border: none !important;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 10px;



  .react-calendar__month-view__weekdays__weekday abbr {
    font-weight: 500;
  }

  .react-calendar__tile--active {
    background-color: var(--batton, #0095c0);
    color: white;
  }

  .react-calendar__tile {
    &:hover {
      background-color: var(--batton, #0095c0);
      color: white;
    }
  }

  .react-calendar__tile--now {
    background-color: var(--batton, #0095c0);
    color: white;
    &:hover {
      background-color: var(--batton, #0095c0);
      color: white;
    }
  }

  /* .selected-date {
    background-color: var(--txt-light-blue, #f4ffff);
    color: black;
  } */

  .selected-start-date,
  .selected-date {
    background-color: var(--batton, #0095c0);
    color: white;
  }

  .selected-end-date {
    background-color: var(--batton, #0095c0);
    color: white;

    &.react-calendar__tile--range-end {
      background-color: var(--batton, #0095c0);
      color: white;
    }
  }
`;

export const Container = styled.div``;

export const BottomWrapper = styled.div`
margin-left: 10px;
`;

export const DateParagraph = styled.p`
  color: var(--txt-dak-light, #143255);
  font-size: 17px;
  font-weight: 500;
  line-height: 140%;
  margin-bottom: 5px;
`;

export const ButtonWrapper=styled.div`
/* display: flex;
align-items: center;
justify-content: center;
gap: 10px; */
display: grid;
grid-template-columns: 1fr 1fr;
gap: 10px;
`;

export const ButtonContainer = styled.div`
  width: 100%;

`;