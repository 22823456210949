import styled, { css } from "styled-components";


export const WrapperRight = styled.div<{ disabled?: boolean }>`
    max-width: 1100px;
    height: 80vh;
    width: 30%;
    margin: 30px 0;
    flex-direction: column;
    border-bottom: 1px solid rgb(216, 216, 216);
    border-right: 1px solid rgb(216, 216, 216);
    border-top: 1px solid rgb(216, 216, 216);
    border-radius: 0 16px 16px 0;
     overflow-y: auto;
    &::-webkit-scrollbar {
        width: 0;
    }
    position: relative;

    ${props =>
        props.disabled && css`
            background: #f0f0f0;
            cursor: not-allowed;
        `}
`;

export const RequestCreate = styled.div<{ disabled?: boolean }>`
    position: sticky;
    top: 0;
    background-color: white;
    height: 80px;
    border-bottom: 1px solid rgb(216, 216, 216);
    z-index: 10;
    color: rgb(0, 23, 49);
    font-size: 28px;
    font-weight: 500;
    line-height: 140%;
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 10px 20px 10px 20px;
    cursor: pointer;

     ${props => !props.disabled && css`
        &&:active {
            scale: 0.99;
        }
    `}

    ${props =>
        props.disabled && css`
            background: #f0f0f0;
            cursor: not-allowed;
        `}
`;

export const CreateText = styled.p`
    margin-left: 20px;
    margin-right: 10px;
    position: relative;
    color: rgb(0, 23, 49);
    font-size: 28px;
    font-weight: 500;
    line-height: 140%;
    text-align: left;
`;

export const IconWrapper = styled.div`
    display: flex;
    position: relative;
    cursor: pointer;
`;

export const Request = styled.div<{ disabled?: boolean }>`
    margin: 10px 20px 0;
    font-size: 19px;
    font-weight: 500;
    line-height: 150%;

    ${props =>
        props.disabled && css`
            background: #f0f0f0;
            cursor: not-allowed;
        `}
`;

export const DateRequest = styled.div<{ disabled?: boolean }>`
    display: flex;
    position: relative;
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0;
    text-align: left;
    padding: 0 0 0 20px;
`;

export const DateUpdateRequest = styled.div`
    margin-top: 5px;
    display: flex;
    position: relative;
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0;
    text-align: left;
    padding: 0 0 0 20px;
`;

export const ChatWrapper = styled.div<{ $isActive: boolean, disabled?: boolean, $isRename?: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 150px;
    border: ${({ $isActive }) => ($isActive ? "1px dashed rgb(3, 182, 42)" : "none")};
    border-radius: ${({ $isActive }) => ($isActive ? "12px" : 0)};
    color: ${({ $isActive }) => ($isActive ? "rgb(0, 149, 192)" : 'rgb(20, 50, 85)')};
    padding: 20px 0 15px;
    cursor: pointer;

    ${props =>
        props.$isRename && css`
            scale: 1;
        `}

        
    ${props =>
        (props.disabled) && css`
            background: #f0f0f0;
            cursor: not-allowed;
        `}

        ${props =>
        props.$isRename && css`
             scale: 0.99;
        `}

        ${props => !props.disabled && css`
        &&:hover {
            border-radius: 12px;
            background: rgb(0, 149, 192);
            color: rgb(244, 255, 255);
        }

        &&:active {
            scale: 0.99;
        }
    `}
`;

export const NameAndIconsWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const IconsWrapper = styled.div`
    min-width: 13%;
    display: flex;
    margin-right: 10px;
    justify-content: space-between;
`;

export const Field = styled.input`
    padding: 6px 0px 5px 10px;
    box-sizing: border-box;
    border: 1px solid rgb(0, 149, 192);
    border-radius: 8px;
    height: 38px;
    background-color: white;
    width: 60%;

    &::placeholder {
        font-size: 17px;
        font-weight: 400;
        line-height: 140%;
        overflow: hidden;
        text-overflow: ellipsis;
        color: var(--txt-light-blue-dont-active, #a0b7d1);
    }
`;

export const ChangeButton = styled.button`
    padding: 6px 20px 8px 20px;
    border: 1px solid rgb(0, 149, 192);
    border-radius: 8px;
    color: rgb(0, 149, 192);
    font-size: 17px;
    font-weight: 400;
    background-color: white;
`;


export const InputAndButtonWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 18px 0;
`;



// Save mesasage

export const SaveWrapper = styled.div`
  border-radius: 16px;
  background: var(--dark, #001731);
  display: flex;
  width: 365px;
  padding: 30px;
  flex-direction: column;
  align-items: flex-start;
  position: fixed;
  top: 30px;
  right: 30px;
  z-index: 10;
`;

export const SaveParagraph = styled.p`
  color: var(--txt-light-blue, #f4ffff);
  font-size: 17px;
  font-weight: 400;
  line-height: 140%;
`;

export const CrossWhiteIconWrapper = styled.div`
  position: absolute;
  right: 20px;
  top: 10px;
  cursor: pointer;
`;